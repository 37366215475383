import React, { useState, useRef, useLayoutEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as Yup from 'yup';
import gsap from 'gsap';

import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { ReactComponent as Check } from 'assets/images/check.svg';
import { getOtpSent, getResetPasswordContact } from 'common/helpers';
import { selectPending } from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import CustomInput from 'components/CustomInput';

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  password: string;
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('')
    .matches(/\d/, '')
    .matches(/[a-z]/, '')
    .matches(/[A-Z]/, '')
    .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
    .min(8),
});

const OptimizedForgotPasswordConfirm = () => {
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPending);
  const [password, setPassword] = useState('');
  const contact = getResetPasswordContact();
  const code = getOtpSent();
  const [passVisible, setPassVisible] = useState(false);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit } = useForm<FormValues>(formOptions);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const payload = {
      ...values,
      contact,
      code,
    };
    dispatch({ type: ACTIONS.RESET_PASSWORD_V2, payload });
  };

  return (
    <section
      ref={component}
      className="fadein relative m-auto mt-[25px] flex min-h-[calc(100vh-65px)] w-full max-w-[80vw] flex-col items-center md:mt-[100px] md:max-w-[500px]"
    >
      <h2 className="font-primary mb-[20px] text-center text-[20px] font-[900] uppercase text-primary md:text-[40px]">
        reset password
      </h2>
      <p className="mb-[25px] text-center text-[16px] md:mb-[40px] md:text-[18px]">
        Enter your new password&nbsp;below.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full grow flex-col place-items-center items-center md:grow-0"
      >
        <div className="flex w-full grow gap-5 md:grow-0">
          <div className="form-control mb-[70px] flex-1">
            <CustomInput title="New Password">
              <input
                type={passVisible ? 'text' : 'password'}
                placeholder="new password"
                className={`peer input input-bordered w-full pr-[50px] focus:outline-none`}
                {...register('password')}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setPassVisible(!passVisible);
                }}
                className="pointer-events-auto absolute right-1 top-[50%] grid h-[90%] w-[40px] translate-y-[-50%] cursor-pointer place-items-center rounded-lg  bg-transparent"
              >
                {passVisible ? (
                  <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
                ) : (
                  <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
                )}
              </span>
            </CustomInput>
            <div className="my-[24px] text-[12px]">
              <div className="flex flex-col gap-[5px] pl-[5px]">
                <p
                  className={classNames('', {
                    '!text-[#D2D2D2]': password.length < 8,
                  })}
                >
                  <Check
                    className={classNames(
                      'mr-[15px] inline fill-primary pb-[1px]',
                      {
                        '!fill-[#D2D2D2]': password.length < 8,
                      },
                    )}
                  />
                  <span className="">Must be at least 8 characters</span>
                </p>
                <p
                  className={classNames('', {
                    '!text-[#D2D2D2]': !password.match(/\d/),
                  })}
                >
                  <Check
                    className={classNames(
                      'mr-[15px] inline fill-primary pb-[1px]',
                      {
                        '!fill-[#D2D2D2]': !password.match(/\d/),
                      },
                    )}
                  />
                  <span className="">Must contain at least 1 number</span>
                </p>
                <p
                  className={classNames('', {
                    '!text-[#D2D2D2]': !(
                      password.match(/[a-z]/) &&
                      password.match(/[A-Z]/) &&
                      password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/)
                    ),
                  })}
                >
                  <Check
                    className={classNames(
                      'mr-[15px] inline fill-primary pb-[1px]',
                      {
                        '!fill-[#D2D2D2]': !(
                          password.match(/[a-z]/) &&
                          password.match(/[A-Z]/) &&
                          password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/)
                        ),
                      },
                    )}
                  />
                  <span className="">
                    Must contain lowercase, uppercase, and a
                    special&nbsp;character
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className={classNames(
            'btn btn-primary max-h-[50px] w-full rounded-full border-none text-[16px] capitalize md:max-h-[60px]',
            {
              loading: isPending,
              'pointer-events-none bg-zinc-200':
                password.length < 8 ||
                !password.match(
                  /\d/ ||
                    !(
                      password.match(/[a-z]/) &&
                      password.match(/[A-Z]/) &&
                      password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/)
                    ),
                ),
            },
          )}
        >
          reset password
        </button>
      </form>
    </section>
  );
};

export default OptimizedForgotPasswordConfirm;
