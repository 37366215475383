import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PublicPageLayout = ({ children }: Props) => {
  return <main className="py-[30px] md:py-[110px] flex items-center flex-col  self-center">{children}</main>;
};

export default PublicPageLayout;
