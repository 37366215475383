// import { toast } from 'react-toastify';
import axios, { AxiosInstance } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getToken, removeToken, setToken } from 'common/helpers';
import { API_URL } from 'common/constants';
// import useAppDispatch from 'hooks/useAppDispatch';
// import { setIsTokenExpired } from 'modules/auth/slice';
import { browserHistory } from './history';

const caseConverterOptions = {
  preservedKeys: ['phoneCode', 'phoneNumber', '_method'],
};

const refreshTokenLogic = (failedRequest: any) => {
  return axios
    .post(`${API_URL}/auth/refresh-token`, { token: getToken() })
    .then((response) => {
      setToken(response.data.token);
      failedRequest.response.config.headers.Authorization =
        'Bearer ' + response.data.token;
      return Promise.resolve();
    })
    .catch((err) => {
      // console.log('refresh error:',err.response.status)
      if (err.response.status === 401 || err.response.status === 403) {
        handleExpiredToken();
      }
    });
};

const api = applyCaseMiddleware(
  axios.create({
    baseURL: API_URL,
  }),
);



const handleExpiredToken = () => {
  // const dispatch = useAppDispatch()
  // console.log('expiring')
  removeToken();
  browserHistory.push('/');
  // console.log('finish expiring')
  // dispatch(setIsTokenExpired(true));

  // toast.error("You don't have permission to access this document.");
};

// const handleExpiredToken = () => {
//   console.log('handling')
//   // const dispatch = useAppDispatch();
//   removeToken();
//   const { push } = useHistory();
//   push('/');
//   console.log('finished')
//   // dispatch(setIsTokenExpired(true));

//   // toast.error("You don't have permission to access this document.");
// };

export const authenticatedClient = (): AxiosInstance => {
  const api = applyCaseMiddleware(
    axios.create({
      baseURL: API_URL,
      timeout: 60000,
    }),
    caseConverterOptions,
  );

  api.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      return config;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        const { status, data } = error.response;
        const isTokenExpired =
          data?.error?.message === 'Token has expired' ||
          data?.error?.message === 'Token Signature could not be verified.' ||
          data?.error?.message === 'The token has been blacklisted';
        
        // if(isTokenExpired){
        //   handleExpiredToken()
        // }
        if (status === 403 || status === 401) {
          // if (isTokenExpired) {
          //   handleExpiredToken();
          // }
          createAuthRefreshInterceptor(api, refreshTokenLogic, {
            statusCodes: [401, 403],
          });
        } else if (status === 500) {
          // console.log('data msg:', data);
          // console.log('final error msg:', isTokenExpired);
          //   handleExpiredToken();
                    
          if (isTokenExpired) {
            handleExpiredToken();
          }
        } else if (status === 404) {
          // browserHistory.push('/404');
        }
      } else {
        // toast.error('Something went wrong.');
      }

      return Promise.reject(error);
    },
  );

  createAuthRefreshInterceptor(api, refreshTokenLogic, {
    statusCodes: [401, 403],
  });

  return api;
};

export function createUploadClient(): AxiosInstance {
  const uploadApi = applyCaseMiddleware(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    }),
  );

  uploadApi.interceptors.response.use(undefined, (err) => {
    return Promise.reject(err);
  });

  /**
   *
   * Handle refreshing expired token
   */
  createAuthRefreshInterceptor(uploadApi, refreshTokenLogic, {
    statusCodes: [401],
  });

  return uploadApi;
}

export function createDownloadClient(): AxiosInstance {
  const downloadApi = applyCaseMiddleware(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    }),
  );

  downloadApi.interceptors.response.use(undefined, (err) => {
    return Promise.reject(err);
  });

  /**
   *
   * Handle refreshing expired token
   */
  createAuthRefreshInterceptor(downloadApi, refreshTokenLogic, {
    statusCodes: [401],
  });

  return downloadApi;
}

export default api;
