import React from 'react';
import { included } from './constants';

const Included = () => {
  return (
    <article className="mt-20 max-w-[850px] border-t-[1px] pt-14">
      <h2 className="mb-[50px] text-4xl font-bold ">What&apos;s included?</h2>
      <div className="mb-10 flex justify-between">
        <span className="max-h-[50%] min-h-[50%] w-3/12 bg-zinc-200" />
        <div className="w-8/12 ">
          {included.map((item: string, i: number) => {
            return (
              <p
                key={i}
                className={`${
                  i % 2 === 0 && i < 6 ? 'mb-3 text-lg font-bold' : ''
                } 
                ${i % 2 === 1 && i < 6 ? 'mb-5 text-sm ' : ''}
                ${i > 5 ? 'text-sm' : ''}
                `}
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
      <p className="text-[10px]">
        *Hourly and daily rates quoted are based on economy cars like the suzuki
        Swift. Tolls are not included in the rates. There is 0.25c admin fee for
        processing of each toll.
        <br></br>Reduce excess to $0 with Optional Reduced Damage Excess Cover
        for $2/hour or $14/day on standard vehicles. Damage cover excess for
        drivers aged under 25 is $3000. Excess can be reduced on standard
        vehicles to $1000 for $2.hour or $14/day. Damage cover excess on
        Commercial and Prestige vehicles is $3000. There is no excess reduction
        available on Commercial or Prestige vehicles. For full terms and
        conditions please{' '}
        <span className="cursor-pointer font-bold underline">click here</span>.
      </p>
    </article>
  );
};

export default Included;
