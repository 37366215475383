import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useClickAway } from 'react-use';
import classNames from 'classnames';

import iconClose from 'assets/images/close_modal.svg';
import { ReactComponent as FailedIcon } from 'assets/images/booking-failed.svg';
import { IMAGE_URL } from 'common/constants';

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  width?: string;
  maxWidth?: string;
  enableClickAway?: boolean;
  hasCloseButton?: boolean;
  px?: string;
  mx?: string;
  padding?: string;
}

const HasUnpaidModal = ({
  isModalOpen,
  handleClose,
  width = '800px',
  maxWidth = '800px',
  enableClickAway = true,
  hasCloseButton = true,
  px = '120px',
  mx = '0px',
  padding = 'p-[60px]',
}: Props) => {
  const { push } = useHistory();
  const modalRef = useRef(null);
  const { pathname } = useLocation();

  useClickAway(modalRef, () => {
    if (enableClickAway) handleClose();
  });

  return (
    <section
      className={classNames(`modal z-[10000]`, {
        'modal-open': isModalOpen,
      })}
    >
      <div
        className={`scrollbar modal-box relative flex flex-col overflow-auto rounded-[10px] md:px-[120px]
      [&>.title]:mb-[20px] [&>.title]:text-center [&>.title]:text-[20px] 
      [&>.title]:font-[700] [&>.title]:md:text-[34px] [&>.title]:md:leading-[42px] [&>.button]:mt-[40px] [&>.button]:h-[60px] [&>.button]:rounded-full [&>.button]:border-none [&>.button]:bg-flexi-green-1 [&>.button]:font-[700] [&>.button]:text-white [&>.button]:md:text-[16px] [&>.icon]:mb-[40px] [&>.icon]:self-center [&>.button:hover]:bg-flexi-green-2 mx-[${mx}] ${px} w-full !w-[${width}] ${padding}`}
        style={{
          maxWidth,
        }}
        ref={modalRef}
      >
        {hasCloseButton && (
          <span
            className="absolute right-[25px] top-[30px] grid place-items-center md:right-[33px] md:top-[40px]"
            onClick={handleClose}
            role="button"
          >
            <span
              className="h-[20px] w-[20px] bg-center bg-no-repeat md:h-[25px] md:w-[25px]"
              style={{
                background: `url('${IMAGE_URL}${iconClose}')`,
                backgroundSize: '100%',
              }}
            />
          </span>
        )}
        <FailedIcon className="icon" />
        <h1 className="mb-[20px] text-center text-[20px] font-bold lg:text-[34px]">
          Payment Failed
        </h1>
        {pathname.includes('book') ? (
          <>
            <p className="mb-[20px] text-center text-[14px] md:mb-[40px] lg:text-[18px]">
              Booking is temporarily unavailable. Settle your outstanding
              balance or update your payment method to proceed.
            </p>
          </>
        ) : (
          <div className='text-center'>
            <p className="text-[14px] lg:text-[18px]">
              Unfortunately we are unable to process your payment. Please check:
            </p>
            <p className="text-[14px] lg:text-[18px]">
              - That you have entered your card details correctly.
            </p>
            <p className="text-[14px] lg:text-[18px]">
              - That you have sufficient funds on your card.
            </p>
            <p className="my-[20px] text-[14px] md:my-[30px] lg:text-[18px]">
              Please try again to ensure that your booking is successful.
            </p>
          </div>
        )}
        <button
          className="lg:!h-[60px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold text-white outline-none hover:bg-flexi-green-1"
          onClick={() => push('/app/account/payment-method')}
        >
          Update Payment Method
        </button>
        <p
          onClick={() => push('/app/account/invoices')}
          className={"mt-[20px] cursor-pointer text-center text-[14px] font-bold capitalize text-primary md:mt-[30px] md:text-[16px]"}
        >
          Go to Invoices
        </p>
      </div>
    </section>
  );
};

export default HasUnpaidModal;
