import { useLocation, useHistory } from 'react-router-dom';

export interface IQuery<Params> {
  params: Params;
  patchQuery: (key: string, value: string | number) => void;
  clearQuery: () => void;
}

const parseParams = <Params extends { [K in keyof Params]?: string }>(
  querystring: string,
): Params => {
  const params = new URLSearchParams(querystring);

  const obj: { [K: string]: string } = {};

  params.forEach((value: string, key: string) => {
    obj[key] = value;
  });

  return obj as Params;
};

const useQuery = <
  Params extends { [K in keyof Params]?: string },
>(): IQuery<Params> => {
  const { search, pathname } = useLocation();
  const searchURL = search.replace('&amp;', '&');

  const { push } = useHistory();

  const clearQuery = () => {
    push({
      pathname,
      search: '',
    });
  };

  const patchQuery = (key: string, value: string | number) => {
    const params = new URLSearchParams(searchURL);

    params.set(key, String(value));

    push({
      pathname,
      search: `?${params.toString()}`,
    });
  };

  return {
    params: parseParams<Params>(searchURL),
    patchQuery,
    clearQuery,
  };
};

export default useQuery;
