import React, { useEffect, useState } from 'react';
import { InvalidText } from 'components/Typography';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import {
  selectAbrLookup,
  selectAbrLookupStatus,
  selectEmailVerified,
  selectError,
  selectPendingAbr,
  selectPendingVerifyEmail,
  selectRegisterStatus,
  setAbrLookup,
  setError,
} from 'modules/auth/slice';
import useAppSelector from 'hooks/useAppSelector';
import { Link } from 'react-router-dom';
import COUNTRIES from 'common/countries';
import { isEmpty } from 'lodash';
import { FcCheckmark } from 'react-icons/fc';
import classNames from 'classnames';
import CustomInput from 'components/CustomInput';
import CustomCheckBox from 'components/CustomCheckbox';
import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { ReactComponent as IconSearch } from 'assets/images/search2.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { ICountry, ICountryCode, RequestStatusEnum } from 'common/types';
import {
  isSupportedCountry,
  getCountryCallingCode,
  isValidPhoneNumber
} from 'react-phone-number-input';
import ReactCountryFlag from 'react-country-flag';
import { ReactComponent as IconPhone } from 'assets/images/faq_call.svg';
import Input from // isValidPhoneNumber
  'react-phone-number-input/input';
import { State } from 'country-state-city';
import {
  browserName,
  deviceType,
  osName,
  osVersion,
  isBrowser,
  isMobile,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  accountType: number;
}

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  country: string;
  businessCode: string;
  businessName: string;
  businessAddress: string;
  businessCity: string;
  businessZip: number;
  businessCountry: string;
  businessState: string;
  businessBillingAddress: string;
  businessBillingCity: string;
  businessBillingPostcode: number;
  businessBillingCountry: string;
  businessBillingState: string;
  isMailSubscriber: boolean;
};

const Form = ({ accountType }: Props) => {
  const dispatch = useAppDispatch();
  const registerStatus = useAppSelector(selectRegisterStatus);
  const isPendingVerifyEmail = useAppSelector(selectPendingVerifyEmail);
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const isPendingAbr = useAppSelector(selectPendingAbr);
  const abrLookup = useAppSelector(selectAbrLookup);
  const [isBillingChecked, setBillingChecked] = useState(false);
  const error = useAppSelector(selectError);
  const [passLVisible, setPassLVisible] = useState(false);
  const [passRVisible, setPassRVisible] = useState(false);
  const [password, setPassword] = useState('');
  const abrLookUpStatus = useAppSelector(selectAbrLookupStatus);
  // const [cityList, setCityList] = useState<any>()

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('This field is required'),
    lastname: Yup.string().required('This field is required'),
    email: Yup.string()
      .required('This field is required')
      .email('Invalid email format'),
    password: Yup.string()
      .required('')
      .matches(/\d/, '')
      .matches(/[a-z]/, '')
      .matches(/[A-Z]/, '')
      .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
      .min(8),
    passwordConfirmation: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    contact: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    businessCode: Yup.string().required('This field is required'),
    businessName: Yup.string().required('This field is required'),
    businessAddress: Yup.string().required('This field is required'),
    businessCity: Yup.string().required('This field is required'),
    businessZip: Yup.string().required('This field is required'),
    businessCountry: Yup.string().required('This field is required'),
    businessState: Yup.string().required('This field is required'),
    businessBillingAddress: Yup.lazy(() => {
      if (isBillingChecked) {
        return Yup.string().required('This field is required');
      }
      return Yup.string();
    }),
    businessBillingCity: Yup.lazy(() => {
      if (isBillingChecked) {
        return Yup.string().required('This field is required');
      }
      return Yup.string();
    }),
    businessBillingPostcode: Yup.lazy(() => {
      if (isBillingChecked) {
        return Yup.string().required('This field is required');
      }
      return Yup.string();
    }),
    businessBillingCountry: Yup.lazy(() => {
      if (isBillingChecked) {
        return Yup.string().required('This field is required');
      }
      return Yup.string();
    }),
    businessBillingState: Yup.lazy(() => {
      if (isBillingChecked) {
        return Yup.string().required('This field is required');
      }
      return Yup.string();
    }),
    isMailSubscriber: Yup.boolean(),
  });

  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
    defaultValues: {
      country: 'au',
      businessCountry: 'au',
      businessBillingCountry: 'au',
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    // setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm<FormValues>(formOptions);

  useEffect(() => {
    clearErrors();
    dispatch(setError(null));
  }, [accountType]);

  const businessCode = useWatch({ control, name: 'businessCode' });
  const [businessNameError, setBusinessNameError] = useState(false)

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (getValues('businessName') !== 'none') {
      setBusinessNameError(false)
      if (registerStatus !== RequestStatusEnum.PENDING) {
        const deviceId = uuidv4();
        let deviceName = '';

        if (isBrowser) {
          deviceName = osName + ' ' + osVersion + ', ' + browserName;
        } else if (isMobile) {
          deviceName = mobileVendor + ' ' + mobileModel;
        } else {
          deviceName = 'Unknown Device';
        }
        
        const payload = {
          ...values,
          type: accountType,
          region: values.country,
          isMailSubscriber: values.isMailSubscriber ? 1 : 0,
          isSameBilling: !isBillingChecked ? 1 : 0,
          service: 'flexicar',
          businessCode: values.businessCode.replaceAll(' ', ''),
          businessBillingAddress: isBillingChecked
            ? values.businessBillingAddress
            : undefined,
          businessBillingCity: isBillingChecked
            ? values.businessBillingCity
            : undefined,
          businessBillingCountry: isBillingChecked
            ? values.businessBillingCountry
            : undefined,
          businessBillingPostcode: isBillingChecked
            ? values.businessBillingPostcode
            : undefined,
          businessBillingZip: isBillingChecked
            ? values.businessBillingPostcode
            : undefined,
          businessBillingState: isBillingChecked
            ? values.businessBillingState
            : undefined,
          contactCountry: phoneCode,
          device_name: deviceName,
          device_id: deviceId,
          device_type: deviceType,
        };
        if (abrLookUpStatus === RequestStatusEnum.SUCCESS)
          dispatch({ type: ACTIONS.REGISTER_ACCOUNT, payload });
      }

    }
    else setBusinessNameError(true)
    
  };

  const handleContinue = () => {
    dispatch({
      type: ACTIONS.GET_ABR_LOOKUP,
      payload: { code: businessCode.replaceAll(' ', '') },
    });
  };

  const isErrorEmail =
    errors?.email || error?.email || error === 'email_invalid';

  const [businessCountryInput, setBusinessCountryInput] = useState('AU');
  const [difCountryInput, setDifCountryInput] = useState('AU');

  const [phone, setPhone] = useState<any>('');
  const [phoneCode, setPhoneCode] = useState<any>('AU');
  // const [phoneError, setPhoneError] = useState<String | null>(null);

  const [isInternational, setIsInternational] = useState(false);
  const [statesList, setStatesList] = useState<any>([]);
  const [difStatesList, setDifStatesList] = useState<any>([]);

  // const [locationAddress, setLocationAddress] = useState('');

  useEffect(() => {
    const state = State.getStatesOfCountry(
      businessCountryInput.toLocaleUpperCase(),
    );
    setStatesList(state);
  }, [businessCountryInput]);

  useEffect(() => {
    const state = State.getStatesOfCountry(difCountryInput.toLocaleUpperCase());
    setDifStatesList(state);
  }, [difCountryInput]);

  const handleMobileSelectChange = (e: any) => {
    if (e.target.value !== 'international') {
      setPhoneCode(e.target.value);
      setPhone('');
      setIsInternational(false);
    } else {
      setIsInternational(true);
    }
  };

  useEffect(() => {
    setValue('contact', phone);
  }, [phone]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="mb-[20px] text-[16px] font-bold sm:text-[21px]">
        Business Information
      </h3>
      <div className="form-control mb-[20px] w-full flex-1">
        <CustomInput title="Country">
          <select
            className={classNames('select-bordered select w-full font-normal', {
              'border-red-500': errors?.country,
            })}
            {...register('country')}
            onChange={(e) => setBusinessCountryInput(e.target.value)}
          >
            {COUNTRIES.map((c) => (
              <option value={c.code.toLowerCase()} key={c.code}>
                {c.name}
              </option>
            ))}
          </select>

          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
          >
            <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
          </span>
        </CustomInput>
        {!isEmpty(errors.country) && (
          <InvalidText>{errors.country?.message}</InvalidText>
        )}
      </div>
      <div className="form-control mb-[20px] w-full flex-1">
        <CustomInput title="ABN or ACN" error={errors.businessCode}>
          <input
            type="text"
            placeholder="abn or acn"
            className={classNames(
              'peer input-bordered input w-full pr-[50px]',
              {
                'border-red-500': errors?.businessCode,
              },
            )}
            {...register('businessCode', {
              onBlur: async (e) => {
                if (!e.target.value) {
                  dispatch(setAbrLookup(null));
                } else handleContinue();
              },
            })}
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="absolute right-1  grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg "
          >
            {isPendingAbr ? (
              <span className="min-h-[20px] min-w-[20px] animate-spin rounded-full border-[2px] border-flexi-black-4 border-t-transparent" />
            ) : (
              <IconSearch />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.businessCode) && (
          <InvalidText>{errors.businessCode?.message}</InvalidText>
        )}
        {abrLookUpStatus === RequestStatusEnum.FAILED && (
          <InvalidText>ABN/ACN not found.</InvalidText>
        )}
      </div>

      {/* {abrLookup ? ( */}
      <>
        <div className="form-control mb-[20px] w-full flex-1">
          <CustomInput
            title="Business Name"
            // handleActive={() => setBusinessNameActive(true)}
            error={
              (errors?.businessName ||
                error?.businessName ||
                businessNameError) &&
              abrLookup
            }
            disabled={!abrLookup}
          >
            <>
              <select
                placeholder="business name"
                className={classNames(
                  'peer select-bordered select w-full font-normal',
                  {
                    'border-red-500':
                      errors?.businessName ||
                      error?.businessName ||
                      businessNameError,
                    '!pointer-events-none border-flexi-black-7': !abrLookup,
                  },
                )}
                // {...register('businessName')}
                // disabled={!abrLookup}
                {...register('businessName', {
                  onBlur: async (e) => {
                    const result = await trigger('businessName');
                    if (result) {
                      dispatch({
                        type: ACTIONS.VERIFY_BUSINESS_NAME,
                        payload: {
                          businessName: e.target.value,
                          type: accountType,
                        },
                      });
                    }
                  },
                })}
                tabIndex={-1}
                defaultValue={abrLookup ? 0 : 'none'}
              >
                {abrLookup?.businessName.length !== undefined &&
                abrLookup?.businessName.length > 0 ? (
                  <>
                    <option value={'none'} disabled></option>
                    {abrLookup?.businessName.map((i, index) => (
                      <option value={i} key={index}>
                        {i}
                      </option>
                    ))}
                  </>
                ) : (
                  <>
                    <option value={'none'} disabled></option>
                    <option value={abrLookup?.entityName}>
                      {abrLookup?.entityName}
                    </option>
                  </>
                )}
              </select>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
              >
                <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
              </span>
            </>
          </CustomInput>
          {businessNameError && (
            <InvalidText>Business name is required</InvalidText>
          )}
          {error?.businessName && (
            <InvalidText>
              The business name has already been taken.{' '}
              <span className="whitespace-nowrap">
                Please contact your manager for invitation.
              </span>
            </InvalidText>
          )}
        </div>

        <div className="form-control mb-[20px] w-full flex-1">
          <CustomInput
            title="Business Address"
            error={errors.businessAddress && abrLookup}
            disabled={!abrLookup}
          >
            <input
              type="text"
              placeholder="business address"
              className={classNames('peer input-bordered input w-full', {
                'border-red-500': errors?.businessAddress && abrLookup,
              })}
              {...register('businessAddress')}
            />
          </CustomInput>
          {!isEmpty(errors.businessAddress) && (
            <InvalidText>{errors.businessAddress?.message}</InvalidText>
          )}
        </div>
        <div className="flex justify-between gap-[10px] sm:gap-[20px]">
          <div className="form-control w-auto">
            <CustomInput
              title="City/Suburb"
              error={errors.businessCity && abrLookup}
              disabled={!abrLookup}
            >
              <input
                type="text"
                placeholder="city"
                className={classNames(
                  'peer input-bordered input w-[115px] sm:w-[242px]',
                  {
                    'border-red-500': errors?.businessCity,
                  },
                )}
                {...register('businessCity')}
                disabled={!abrLookup}
              />
            </CustomInput>
            {!isEmpty(errors.businessCity) && abrLookup && (
              <InvalidText>{errors.businessCity?.message}</InvalidText>
            )}
          </div>
          <div className="form-control w-[100px] sm:w-full sm:flex-1 ">
            <CustomInput
              title="Postcode"
              error={errors.businessZip && abrLookup}
              disabled={!abrLookup}
            >
              <input
                type="number"
                placeholder="postcode"
                className={classNames(
                  'peer input-bordered input w-[100px] sm:w-full',
                  {
                    'border-red-500': errors?.businessZip,
                  },
                )}
                {...register('businessZip')}
                disabled={!abrLookup}
              />
            </CustomInput>
            {!isEmpty(errors.businessZip) && abrLookup && (
              <InvalidText>{errors.businessZip?.message}</InvalidText>
            )}
          </div>

          <div className="form-control w-[70px] sm:w-auto">
            <CustomInput
              title="State"
              error={errors.businessState && abrLookup}
              disabled={!abrLookup}
              extraClass="w-[70px] sm:w-[110px]"
            >
              {statesList.length > 0 ? (
                <>
                  <select
                    placeholder="state"
                    className={classNames(
                      'peer select-bordered select w-[70px] truncate font-normal sm:w-full',
                      {
                        'border-red-500': errors?.businessState,
                        '!pointer-events-none border-flexi-black-7': !abrLookup,
                      },
                    )}
                    {...register('businessState')}
                    // disabled={!abrLookup}
                    tabIndex={-1}
                    defaultValue={0}
                    disabled={!abrLookup}
                  >
                    <option className="hidden"></option>
                    {statesList &&
                      statesList.map((i: ICountry, index: number) => (
                        <option value={i.name} key={index}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
                  >
                    <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
                  </span>
                </>
              ) : (
                <input
                  type="text"
                  placeholder="state"
                  className={classNames(
                    'peer input-bordered input w-[70px] sm:w-full',
                    {
                      'border-red-500': errors?.businessState,
                      'border-flexi-black-7': !abrLookup,
                    },
                  )}
                  {...register('businessState')}
                  disabled={!abrLookup}
                />
              )}
            </CustomInput>
            {!isEmpty(errors.businessState) && abrLookup && (
              <InvalidText>{errors.businessState?.message}</InvalidText>
            )}
          </div>
        </div>

        <label className="my-[25px] flex w-full cursor-pointer flex-row items-center sm:my-[50px]">
          <CustomCheckBox
            titleElement={
              <p className="text-[12px] sm:text-[14px]">
                My billing address is different from my business&nbsp;address
              </p>
            }
          >
            <input
              type="checkbox"
              className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
              // @ts-ignore
              style={{ '--chkfg': '0', '--chkbg': '0' }}
              checked={isBillingChecked}
              onChange={() => setBillingChecked(!isBillingChecked)}
            />
          </CustomCheckBox>
        </label>

        {isBillingChecked && (
          <>
            <div className="form-control mb-[20px] w-full flex-1">
              <CustomInput
                title="Billing Address"
                error={errors.businessBillingAddress && abrLookup}
                disabled={!abrLookup}
              >
                <input
                  type="text"
                  placeholder="address"
                  className={classNames('peer input-bordered input w-full', {
                    'border-red-500': errors?.businessBillingAddress,
                  })}
                  {...register('businessBillingAddress')}
                  disabled={!abrLookup}
                />
              </CustomInput>

              {!isEmpty(errors.businessBillingAddress) && abrLookup && (
                <InvalidText>
                  {errors.businessBillingAddress?.message}
                </InvalidText>
              )}
            </div>
            <div className="flex gap-[20px]">
              <div className="form-control mb-[20px] w-full flex-1">
                <CustomInput
                  title="City/Suburb"
                  error={errors.businessBillingCity && abrLookup}
                  disabled={!abrLookup}
                >
                  <input
                    type="text"
                    placeholder="city"
                    className={classNames('peer input-bordered input w-full', {
                      'border-red-500': errors?.businessBillingCity,
                    })}
                    {...register('businessBillingCity')}
                    disabled={!abrLookup}
                  />
                </CustomInput>
                {!isEmpty(errors.businessBillingCity) && abrLookup && (
                  <InvalidText>
                    {errors.businessBillingCity?.message}
                  </InvalidText>
                )}
              </div>
              <div className="form-control mb-[20px] w-full flex-1">
                <CustomInput
                  title="Postcode"
                  error={errors.businessBillingPostcode && abrLookup}
                  disabled={!abrLookup}
                >
                  <input
                    type="number"
                    placeholder="postcode"
                    className={classNames('peer input-bordered input w-full', {
                      'border-red-500': errors?.businessBillingPostcode,
                    })}
                    {...register('businessBillingPostcode')}
                    disabled={!abrLookup}
                  />
                </CustomInput>
                {!isEmpty(errors.businessBillingPostcode) && abrLookup && (
                  <InvalidText>
                    {errors.businessBillingPostcode?.message}
                  </InvalidText>
                )}
              </div>
            </div>
            <div className="flex gap-[20px]">
              <div className="form-control mb-[20px] w-full flex-1">
                <CustomInput
                  title="Country"
                  error={errors.businessBillingCountry && abrLookup}
                  disabled={!abrLookup}
                >
                  <select
                    placeholder="country"
                    className={classNames(
                      'peer select-bordered select w-full',
                      {
                        'border-red-500': errors?.businessBillingCountry,
                        'border-flexi-black-7': !abrLookup,
                      },
                    )}
                    {...register('businessBillingCountry')}
                    onChange={(e) => setDifCountryInput(e.target.value)}
                  >
                    {COUNTRIES.map((c) => (
                      <option value={c.code.toLowerCase()} key={c.code}>
                        {c.name}
                      </option>
                    ))}
                  </select>

                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="pointer-events-none absolute right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
                  >
                    <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
                  </span>
                </CustomInput>
                {!isEmpty(errors.businessBillingCountry) && abrLookup && (
                  <InvalidText>
                    {errors.businessBillingCountry?.message}
                  </InvalidText>
                )}
              </div>
              <div className="form-control mb-[20px] w-full flex-1">
                <CustomInput
                  title="State"
                  error={errors.businessBillingState && abrLookup}
                  disabled={!abrLookup}
                >
                  {difStatesList.length > 0 ? (
                    <>
                      <select
                        placeholder="state"
                        className={classNames(
                          'peer input-bordered input w-full',
                          {
                            'border-red-500': errors?.businessBillingState,
                            '!pointer-events-none border-flexi-black-7':
                              !abrLookup,
                          },
                        )}
                        {...register('businessBillingState')}
                        // disabled={!abrLookup}
                        tabIndex={-1}
                        defaultValue={0}
                        disabled={!abrLookup}
                      >
                        <option className="hidden"></option>
                        {difStatesList.map((i: ICountry, index: number) => (
                          <option value={i.name} key={index}>
                            {i.name}
                          </option>
                        ))}
                      </select>

                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
                      >
                        <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
                      </span>
                    </>
                  ) : (
                    <input
                      type="text"
                      className={classNames('input-bordered input w-full', {
                        'border-red-500': errors?.businessBillingState,
                      })}
                      {...register('businessBillingState')}
                      disabled={!abrLookup}
                    />
                  )}
                </CustomInput>
                {!isEmpty(errors.businessBillingState) && abrLookup && (
                  <InvalidText>
                    {errors.businessBillingState?.message}
                  </InvalidText>
                )}
              </div>
            </div>
          </>
        )}

        <h3 className="mb-[20px] mt-[30px]  text-[16px] font-bold sm:text-[21px]">
          Who will be managing this account?
        </h3>

        <div className="form-control mb-[20px] w-full flex-1">
          <CustomInput title="Email Address" error={errors?.email}>
            <input
              type="text"
              placeholder="email"
              className={`peer input-bordered input w-full ${
                isErrorEmail && 'border-red-500'
              }`}
              {...register('email', {
                onBlur: async (e) => {
                  const result = await trigger('email');
                  if (result) {
                    dispatch({
                      type: ACTIONS.VERIFY_EMAIL,
                      payload: { email: e.target.value, type: accountType },
                    });
                  }
                },
              })}
            />

            {isPendingVerifyEmail && (
              <span className="absolute  right-[15px] h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
            )}

            {isEmailVerified && (
              <FcCheckmark className="absolute right-[15px]" />
            )}
          </CustomInput>
          {!isEmpty(errors.email) && (
            <InvalidText>{errors.email?.message}</InvalidText>
          )}
          {error?.email && <InvalidText>{error?.email[0]}</InvalidText>}
        </div>

        <CustomInput
          title="Mobile"
          error={errors?.contact}
          positionTitle="translate-x-[50px]"
          margin={20}
        >
          <div
            className={classNames(
              'pointer-events-none absolute bottom-0 left-0  flex h-full w-[65px] items-center justify-center gap-[5px] rounded-l-lg border-b-[1px] border-l-[1px] border-t-[1px] border-flexi-black-7 bg-white',
              {
                '!border-red-500': errors?.contact,
              },
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {isInternational && (
              <IconPhone className="before absolute left-[12px] h-[25px] w-[25px] bg-white fill-flexi-black-3" />
            )}
            <ReactCountryFlag
              className=" min-h-[25px] min-w-[25px]  rounded-full object-cover"
              countryCode={phoneCode}
              svg
            />
            <IconArrow />
          </div>
          <select
            onChange={handleMobileSelectChange}
            onClick={(e) => e.stopPropagation()}
            defaultValue="AU"
            placeholder="mobile"
            className="peer h-full w-[65px] cursor-pointer bg-transparent "
          >
            <option value="international">International</option>
            {COUNTRIES.map((country: ICountryCode, i: number) => {
              return (
                isSupportedCountry(country.code) && (
                  <option
                    key={i}
                    value={country.code}
                    className="!mb-[5px] !pb-[5px] text-[14px]"
                  >
                    {country.name} &#91;+{getCountryCallingCode(country.code)}
                    &#93;
                  </option>
                )
              );
            })}
          </select>
          <Input
            country={phoneCode}
            international
            withCountryCallingCode={!isInternational}
            value={phone}
            onChange={setPhone}
            placeholder="mobile"
            className={classNames(
              'peer h-[40px] w-full rounded-br-lg border-b-[1px] border-r-[1px] border-flexi-black-7',
              {
                '!border-red-500': errors?.contact,
              },
            )}
            maxLength={
              isValidPhoneNumber(phone || `+61`, phoneCode) ? phone.length : 17
            }
          />
        </CustomInput>

        {!isEmpty(errors?.contact) && (
          <InvalidText>Mobile number is required</InvalidText>
        )}
        {/* {!isEmpty(phoneError) && <InvalidText>{phoneError}</InvalidText>} */}

        <div className="flex flex-col">
          <div className="form-control my-[20px] w-full flex-1">
            <CustomInput title="First Name" error={errors.firstname}>
              <input
                type="text"
                placeholder="first name"
                className={`peer input-bordered input w-full ${
                  errors?.firstname && 'border-red-500'
                }`}
                {...register('firstname')}
              />
            </CustomInput>
            {!isEmpty(errors.firstname) && (
              <InvalidText>{errors.firstname?.message}</InvalidText>
            )}
          </div>

          <div className="form-control mb-[20px] w-full flex-1">
            <CustomInput title="Last Name" error={errors.lastname}>
              <input
                type="text"
                placeholder="last name"
                className={`peer input-bordered input w-full ${
                  errors?.lastname && 'border-red-500'
                }`}
                {...register('lastname')}
              />
            </CustomInput>
            {!isEmpty(errors.lastname) && (
              <InvalidText>{errors.lastname?.message}</InvalidText>
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="form-control mb-[20px] w-full flex-1">
            <CustomInput title="Password" error={errors.password}>
              <input
                placeholder="password"
                type={passLVisible ? 'text' : 'password'}
                className={`peer input-bordered input w-full pr-[50px]
                ${errors?.password && 'border-red-500'}`}
                {...register('password')}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              />

              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setPassLVisible(!passLVisible);
                }}
                className="pointer-events-auto absolute  right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
              >
                {passLVisible ? (
                  <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
                ) : (
                  <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
                )}
              </span>
            </CustomInput>
            {!isEmpty(errors.password) && (
              <div className=" my-2 text-[12px] text-red-500">
                {password ? (
                  <ul className="relative ml-5 list-disc">
                    <li className={`${password.length >= 8 && 'hidden'} `}>
                      <span className="absolute left-[-5px]">
                        Must be at least 8 characters
                      </span>
                    </li>
                    <li className={`${password.match(/\d/) && 'hidden'}`}>
                      <span className="absolute left-[-5px]">
                        Must contain at least 1 number
                      </span>
                    </li>
                    <li
                      className={`${
                        password.match(/[a-z]/) &&
                        password.match(/[a-z]/) &&
                        password.match(
                          /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
                        ) &&
                        'hidden'
                      }`}
                    >
                      <span className="absolute left-[-5px]">
                        Must contain lowercase, uppercase, and a special
                        character
                      </span>
                    </li>
                  </ul>
                ) : (
                  <p>This field is required</p>
                )}
              </div>
            )}
          </div>

          <div className="form-control w-full flex-1">
            <CustomInput
              title="Confirm Password"
              error={errors.passwordConfirmation}
            >
              <input
                type={passRVisible ? 'text' : 'password'}
                placeholder="confirm password"
                className={`peer input-bordered input w-full pr-[50px] ${
                  errors?.passwordConfirmation && 'border-red-500'
                }`}
                {...register('passwordConfirmation')}
              />

              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setPassRVisible(!passRVisible);
                }}
                className="pointer-events-auto absolute  right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
              >
                {passRVisible ? (
                  <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
                ) : (
                  <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
                )}
              </span>
            </CustomInput>
            {!isEmpty(errors.passwordConfirmation) && (
              <InvalidText>{errors.passwordConfirmation?.message}</InvalidText>
            )}
          </div>
        </div>

        <div className="mt-[25px] flex flex-col gap-4 sm:mt-[50px]">
          <CustomCheckBox
            size={10}
            title="Sign me up to receive special offers and updates."
            titleClass="text-[12px] sm:text-[14px]"
          >
            <input
              type="checkbox"
              className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
              {...register('isMailSubscriber')}
              // @ts-ignore
              style={{ '--chkfg': '0', '--chkbg': '0' }}
              defaultChecked
            />
          </CustomCheckBox>
          <p className="text-[11px] sm:text-[14px]">
            By clicking &apos;Create my account&apos;, you confirm that you have
            read and accepted Flexicar&apos;s{' '}
            <Link to="/terms-and-conditions" className="font-bold text-primary">
              Terms and Conditions
            </Link>{' '}
            and{' '}
            <Link to="/privacy-policy-page" className="font-bold text-primary">
              Privacy Policy
            </Link>
            .
          </p>
        </div>

        <div className="mt-[25px] flex w-full justify-center sm:mt-[50px]">
          <button
            type="submit"
            className={classNames(
              'btn-primary btn h-[60px] w-full rounded-full capitalize',
              {
                loading: registerStatus === RequestStatusEnum.PENDING,
              },
            )}
          >
            Create my account
          </button>
        </div>
      </>
    </form>
  );
};

export default Form;