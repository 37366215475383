/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconMonthZero } from 'assets/images/plan_monthly_zero.svg';
import { ReactComponent as IconMonth } from 'assets/images/plan_monthly.svg';
import { ReactComponent as IconHour } from 'assets/images/plan_hourly.svg';
import { ReactComponent as IconDay } from 'assets/images/plan_daily.svg';
import { IRegisterPlan } from 'common/types';
import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';

interface Props {
  plan: IRegisterPlan | null;
}

const Card = ({ plan }: Props) => {
  const user = useAppSelector(selectUser);
  return (
    <article className="flex flex-col items-start">
      <h3 className="mb-[20px] mt-0 hidden text-center text-[26px] font-[700] capitalize md:block">
        Flexi{' '}
        {plan?.name.toLocaleLowerCase().split(' ').includes('default')
          ? user?.type.name
          : plan?.name}
      </h3>
      <p className="mx-auto max-w-[600px] text-center text-[16px] md:mx-0 md:text-left">
        {plan?.description}
      </p>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        {plan?.name.toLocaleLowerCase() === 'sometimes' ? (
          <IconMonthZero className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        ) : (
          <IconMonth className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        )}
        <div>
          <p className="mb-[2px] text-[16px] font-bold md:mb-[10px] md:text-[18px]">
            {plan?.name.toLocaleLowerCase() === 'sometimes'
              ? 'No Commitment'
              : 'Monthly Commitment'}
          </p>
          <p className="text-[14px] md:text-[16px]">{plan?.commitment}</p>
        </div>
      </div>
      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconDay className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        <div>
          <p className="mb-[2px] text-[16px] font-bold md:mb-[10px] md:text-[18px]">
            Daily Cost
          </p>
          <p className="text-[14px] md:text-[16px]">{plan?.dailyNotes}</p>
        </div>
      </div>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconHour className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        <div>
          <p className="mb-[2px] text-[16px] font-bold md:mb-[10px] md:text-[18px]">
            Hourly Cost
          </p>
          <p className="text-[14px] md:text-[16px]">{plan?.hourlyNotes}</p>
        </div>
      </div>

      <p className="my-[30px] w-full rounded-[10px] bg-white px-[18px] py-[15px] text-[10px] tracking-[.2px] shadow-[0_2px_9px] shadow-flexi-white-2 md:px-[30px] md:py-[28px] md:text-[12px]">
        {plan?.disclaimer}
        <Link
          className="ml-[3px] font-bold text-flexi-orange-1"
          to="/terms-and-conditions"
          target="_blank"
        >
          click here
        </Link>
        .
      </p>
    </article>
  );
};

export default Card;
