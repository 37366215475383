import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import {
  IUserInfo,
  IPaymentCard,
  IUser,
  ICampus,
  IUserPlan,
  IExpiredCredits,
  IRewardPartner,
  RequestStatusEnum,
  IErrors,
  IRegisterPlan,
  ILoggedInDevicesResponse,
  IOcrAttempt,
  IUserAvailableCredits,
} from 'common/types';

type InitialState = {
  errors: IErrors;
  pending: boolean;
  pendingUpdate: boolean;
  pendingPaymentCards: boolean;
  pendingUserInfo: boolean;
  pendingCampus: boolean;
  pendingChangePassword: boolean;
  pendingRewardPartners: boolean;
  pendingExpiredCredits: boolean;
  user: IUser | null;
  paymentCards: IPaymentCard[] | [];
  defaultPaymentCard: IPaymentCard | null;
  userInfo: IUserInfo | null;
  campusList: ICampus[] | null;
  userPlan: IUserPlan | null;
  rewardPartners: IRewardPartner[] | null;
  expiredCredits: IExpiredCredits[] | null;
  outstandingBalance: IUserPlan | null;
  pendingCancelMembership: boolean;
  cancelMembershipStatus: RequestStatusEnum | null;
  pendingChangeExcess: boolean;
  changeExcessStatus: RequestStatusEnum | null;
  pendingChangePartner: boolean;
  changePartnerStatus: RequestStatusEnum | null;
  changeVffStatus: RequestStatusEnum | null;
  updateUserPlanStatus: RequestStatusEnum | null;
  deletePaymentCardStatus: RequestStatusEnum | null;
  pendingDeletePaymentCard: boolean;
  changePasswordStatus: RequestStatusEnum | null;
  storeContactStatus: RequestStatusEnum | null;
  updateLicenceAddressStatus: RequestStatusEnum | null;
  uploadPhotoStatus: RequestStatusEnum | null;
  userPhoto: string | null | undefined;
  getPlansStatus: RequestStatusEnum | null;
  plans: IRegisterPlan[];
  switchAccountStatus: RequestStatusEnum | null;
  userStatus: RequestStatusEnum | null;
  firstLoginStatus: RequestStatusEnum | null;
  registrationVFF: string | null;
  wallet: any;
  walletStatus: RequestStatusEnum | null;
  removeVffStatus: RequestStatusEnum | null;
  watchUserStatus: RequestStatusEnum | null;
  verifyReferrerStatus: RequestStatusEnum | null;
  userPlanStatus: RequestStatusEnum | null;
  referrerError: any;
  onlineQueryStatus: RequestStatusEnum | null;
  loggedInDevices: ILoggedInDevicesResponse | null;
  isSenior: number | null;
  ocrId: string | null;
  ocrAttempt: IOcrAttempt | null;
  ocrRetryStatus: RequestStatusEnum | null;
  ocrAttemptStatus: RequestStatusEnum | null;
  userAvailableCredits: IUserAvailableCredits | null;
  isAnnualFeeNotPaid: boolean;
  changeCourseEndDateStatus: RequestStatusEnum | null;
  changeCourseEndDateError: any;
  changeCourseEndDateMessage: string | null;
};

const initialState: InitialState = {
  errors: {},
  pending: false,
  pendingUpdate: false,
  pendingPaymentCards: false,
  pendingUserInfo: false,
  pendingChangePassword: false,
  pendingRewardPartners: false,
  pendingExpiredCredits: false,
  pendingCampus: false,
  user: null,
  paymentCards: [],
  defaultPaymentCard: null,
  userInfo: null,
  campusList: null,
  userPlan: null,
  rewardPartners: null,
  expiredCredits: [],
  outstandingBalance: null,
  pendingCancelMembership: false,
  cancelMembershipStatus: null,
  pendingChangeExcess: false,
  changeExcessStatus: null,
  pendingChangePartner: false,
  changePartnerStatus: null,
  changeVffStatus: null,
  updateUserPlanStatus: null,
  deletePaymentCardStatus: null,
  pendingDeletePaymentCard: false,
  changePasswordStatus: null,
  storeContactStatus: null,
  updateLicenceAddressStatus: null,
  uploadPhotoStatus: null,
  userPhoto: null,
  getPlansStatus: null,
  plans: [],
  switchAccountStatus: null,
  userStatus: null,
  firstLoginStatus: null,
  registrationVFF: null,
  wallet: null,
  walletStatus: null,
  removeVffStatus: null,
  watchUserStatus: null,
  verifyReferrerStatus: null,
  userPlanStatus: null,
  referrerError: null,
  onlineQueryStatus: null,
  loggedInDevices: null,
  isSenior: null,
  ocrId: null,
  ocrAttempt: null,
  ocrRetryStatus: null,
  ocrAttemptStatus: null,
  userAvailableCredits: null,
  isAnnualFeeNotPaid: false,
  changeCourseEndDateStatus: null,
  changeCourseEndDateError: null,
  changeCourseEndDateMessage: null,
};

export const slice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    reset: () => initialState,
    setErrors: (state, action: PayloadAction<IErrors>) => {
      state.errors = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setPendingUpdate: (state, action: PayloadAction<boolean>) => {
      state.pendingUpdate = action.payload;
    },
    setPendingPaymentCards: (state, action: PayloadAction<boolean>) => {
      state.pendingPaymentCards = action.payload;
    },
    setPendingUserInfo: (state, action: PayloadAction<boolean>) => {
      state.pendingUserInfo = action.payload;
    },
    setPendingCampus: (state, action: PayloadAction<boolean>) => {
      state.pendingCampus = action.payload;
    },
    setPendingRewardPartners: (state, action: PayloadAction<boolean>) => {
      state.pendingRewardPartners = action.payload;
    },
    setPendingChangePassword: (state, action: PayloadAction<boolean>) => {
      state.pendingChangePassword = action.payload;
    },
    setPendingExpiredCredits: (state, action: PayloadAction<boolean>) => {
      state.pendingExpiredCredits = action.payload;
    },
    setCampusList: (state, action: PayloadAction<ICampus[]>) => {
      state.campusList = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
      state.userInfo = action.payload;
    },
    setPaymentCards: (state, action: PayloadAction<IPaymentCard[]>) => {
      state.paymentCards = action.payload;
    },
    setDefaultPaymentCard: (
      state,
      action: PayloadAction<IPaymentCard | null>,
    ) => {
      state.defaultPaymentCard = action.payload;
    },
    setUserPlan: (state, action: PayloadAction<IUserPlan | null>) => {
      state.userPlan = action.payload;
    },
    setRewardPartners: (
      state,
      action: PayloadAction<IRewardPartner[] | null>,
    ) => {
      state.rewardPartners = action.payload;
    },
    setExpiredCredits: (
      state,
      action: PayloadAction<IExpiredCredits[] | null>,
    ) => {
      state.expiredCredits = action.payload;
    },
    setOutstandingBalance: (state, action: PayloadAction<IUserPlan | null>) => {
      state.outstandingBalance = action.payload;
    },
    setPendingCancelMembership: (state, action: PayloadAction<boolean>) => {
      state.pendingCancelMembership = action.payload;
    },
    setCancelMembershipStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.cancelMembershipStatus = action.payload;
    },
    setPendingChangeExcess: (state, action: PayloadAction<boolean>) => {
      state.pendingChangeExcess = action.payload;
    },
    setChangeExcessStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.changeExcessStatus = action.payload;
    },
    setPendingChangePartner: (state, action: PayloadAction<boolean>) => {
      state.pendingChangePartner = action.payload;
    },
    setChangePartnerStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.changePartnerStatus = action.payload;
    },
    setChangeVffStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.changeVffStatus = action.payload;
    },
    setUpdateUserPlanStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.updateUserPlanStatus = action.payload;
    },
    setDeletePaymentCardStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.deletePaymentCardStatus = action.payload;
    },
    setPendingDeletePaymentCard: (state, action: PayloadAction<boolean>) => {
      state.pendingDeletePaymentCard = action.payload;
    },
    setChangePasswordStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.changePasswordStatus = action.payload;
    },
    setStoreContactStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.storeContactStatus = action.payload;
    },
    setUpdateLicenseAddressStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.updateLicenceAddressStatus = action.payload;
    },
    setUploadPhotoStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.uploadPhotoStatus = action.payload;
    },
    setUserPhoto: (state, action: PayloadAction<string | null | undefined>) => {
      state.userPhoto = action.payload;
    },
    setPlansStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.getPlansStatus = action.payload;
    },
    setPlans: (state, action: PayloadAction<IRegisterPlan[]>) => {
      state.plans = action.payload;
    },
    setSwitchAccountStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.switchAccountStatus = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<RequestStatusEnum | null>) => {
      state.userStatus = action.payload;
    },
    setFirstLoginStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.firstLoginStatus = action.payload;
    },
    setRegistrationVFF: (state, action: PayloadAction<string | null>) => {
      state.registrationVFF = action.payload;
    },
    setWallet: (state, action: PayloadAction<any>) => {
      state.wallet = action.payload;
    },
    setWalletStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.walletStatus = action.payload;
    },
    setRemoveVffStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.removeVffStatus = action.payload;
    },
    setWatchUserStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.watchUserStatus = action.payload;
    },
    setReferrerError: (state, action: PayloadAction<null>) => {
      state.referrerError = action.payload;
    },
    setVerifyReferrerStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.verifyReferrerStatus = action.payload;
    },
    setUserPlanStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.userPlanStatus = action.payload;
    },
    setOnlineQueryStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.onlineQueryStatus = action.payload;
    },
    setLoggedInDevices: (
      state,
      action: PayloadAction<ILoggedInDevicesResponse | null>,
    ) => {
      state.loggedInDevices = action.payload;
    },
    setIsSenior: (state, action: PayloadAction<number | null>) => {
      state.isSenior = action.payload;
    },
    setOcrId: (state, action: PayloadAction<string | null>) => {
      state.ocrId = action.payload;
    },
    setOcrAttempt: (state, action: PayloadAction<IOcrAttempt | null>) => {
      state.ocrAttempt = action.payload;
    },
    setOcrRetryStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.ocrRetryStatus = action.payload;
    },
    setOcrAttemptStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.ocrAttemptStatus = action.payload;
    },
    setUserAvailableCredits: (
      state,
      action: PayloadAction<IUserAvailableCredits | null>,
    ) => {
      state.userAvailableCredits = action.payload;
    },
    setIsAnnualFeeNotPaid: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isAnnualFeeNotPaid = action.payload;
    },
    setChangeCourseEndDateStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.changeCourseEndDateStatus = action.payload;
    },
    setChangeCourseEndDateError: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.changeCourseEndDateError = action.payload;
    },
    setChangeCourseEndDateMessage: (state, action: PayloadAction<string | null>) => {
      state.changeCourseEndDateMessage = action.payload;
    },
  },
});

export const {
  reset,
  setErrors,
  setPending,
  setPendingUpdate,
  setPendingPaymentCards,
  setPendingUserInfo,
  setPendingCampus,
  setPendingChangePassword,
  setPendingRewardPartners,
  setPendingExpiredCredits,
  setUser,
  setUserInfo,
  setPaymentCards,
  setDefaultPaymentCard,
  setCampusList,
  setUserPlan,
  setRewardPartners,
  setExpiredCredits,
  setOutstandingBalance,
  setPendingCancelMembership,
  setCancelMembershipStatus,
  setPendingChangeExcess,
  setChangeExcessStatus,
  setPendingChangePartner,
  setChangePartnerStatus,
  setChangeVffStatus,
  setUpdateUserPlanStatus,
  setDeletePaymentCardStatus,
  setPendingDeletePaymentCard,
  setChangePasswordStatus,
  setStoreContactStatus,
  setUpdateLicenseAddressStatus,
  setUploadPhotoStatus,
  setUserPhoto,
  setPlansStatus,
  setPlans,
  setSwitchAccountStatus,
  setUserStatus,
  setFirstLoginStatus,
  setRegistrationVFF,
  setWallet,
  setWalletStatus,
  setRemoveVffStatus,
  setWatchUserStatus,
  setReferrerError,
  setVerifyReferrerStatus,
  setUserPlanStatus,
  setOnlineQueryStatus,
  setLoggedInDevices,
  setIsSenior,
  setOcrId,
  setOcrAttempt,
  setOcrRetryStatus,
  setOcrAttemptStatus,
  setUserAvailableCredits,
  setIsAnnualFeeNotPaid,
  setChangeCourseEndDateStatus,
  setChangeCourseEndDateError,
  setChangeCourseEndDateMessage,
} = slice.actions;

export const selectErrors = (state: RootState): IErrors => state.me.errors;
export const selectPending = (state: RootState): boolean => state.me.pending;
export const selectPendingUpdate = (state: RootState): boolean =>
  state.me.pendingUpdate;
export const selectPendingPaymentCards = (state: RootState): boolean =>
  state.me.pendingPaymentCards;
export const selectPendingUserInfo = (state: RootState): boolean =>
  state.me.pendingUserInfo;
export const selectPendingCampus = (state: RootState): boolean =>
  state.me.pendingCampus;
export const selectUser = (state: RootState): IUser | null => state.me.user;
export const selectUserInfo = (state: RootState): IUserInfo | null =>
  state.me.userInfo;
export const selectPaymentCards = (state: RootState): IPaymentCard[] | [] =>
  state.me.paymentCards;
export const selectDefaultPaymentCard = (
  state: RootState,
): IPaymentCard | null => state.me.defaultPaymentCard;
export const selectCampusList = (state: RootState): ICampus[] | null =>
  state.me.campusList;
export const selectUserPlan = (state: RootState): IUserPlan | null =>
  state.me.userPlan;
export const selectRewardPartners = (
  state: RootState,
): IRewardPartner[] | null => state.me.rewardPartners;
export const selectPendingRewardPartners = (state: RootState): boolean =>
  state.me.pendingRewardPartners;
export const selectPendingExpiredCredits = (state: RootState): boolean =>
  state.me.pendingExpiredCredits;
export const selectExpiredCredits = (
  state: RootState,
): IExpiredCredits[] | null => state.me.expiredCredits;
export const selectOutstandingBalance = (state: RootState): IUserPlan | null =>
  state.me.outstandingBalance;
export const selectPendingCancelMembership = (state: RootState): boolean =>
  state.me.pendingCancelMembership;
export const selectCancelMembershipStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.cancelMembershipStatus;
export const selectPendingChangeExcess = (state: RootState): boolean =>
  state.me.pendingChangeExcess;
export const selectChangeExcessStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.changeExcessStatus;
export const selectPendingChangePartner = (state: RootState): boolean =>
  state.me.pendingChangePartner;
export const selectChangePartnerStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.changePartnerStatus;
export const selectChangeVffStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.changeVffStatus;
export const selectUpdateUserPlanStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.updateUserPlanStatus;
export const selectDeletePaymentCardStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.deletePaymentCardStatus;
export const selectPendingDeletePaymentCard = (state: RootState): boolean =>
  state.me.pendingDeletePaymentCard;
export const selectChangePasswordStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.changePasswordStatus;
export const selectStoreContactStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.storeContactStatus;
export const selectUpdateLicenseAddressStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.updateLicenceAddressStatus;
export const selectUploadPhotoStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.uploadPhotoStatus;
export const selectUserPhoto = (state: RootState): string | null | undefined =>
  state.me.userPhoto;
export const selectPlansStatus = (state: RootState): RequestStatusEnum | null =>
  state.me.getPlansStatus;
export const selectPlans = (state: RootState): IRegisterPlan[] =>
  state.me.plans;
export const selectSwitchAccountStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.switchAccountStatus;
export const selectUserStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.userStatus;
export const selectFirstLoginStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.firstLoginStatus;
export const selectRegistrationVFF = (
  state: RootState,
): string | null => state.me.registrationVFF;
export const selectWallet = (
  state: RootState,
): any => state.me.wallet;
export const selectWalletStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.walletStatus;
export const selectRemoveVffStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.removeVffStatus;
export const selectWatchUserStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.watchUserStatus;
export const selectVerifyReferrerStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.verifyReferrerStatus;
export const selectUserPlanStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.userPlanStatus;
export const selectReferrerError = (
  state: RootState,
): null => state.me.referrerError;
export const selectOnlineQueryStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.onlineQueryStatus;
export const selectLoggedInDevices = (
  state: RootState,
): ILoggedInDevicesResponse | null => state.me.loggedInDevices;
export const selectIsSenior = (
  state: RootState,
): number | null => state.me.isSenior;
export const selectOcrId = (state: RootState): string | null => state.me.ocrId;
export const selectOcrAttempt = (state: RootState): IOcrAttempt | null =>
  state.me.ocrAttempt;
export const selectOcrRetryStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.ocrRetryStatus;
export const selectOcrAttemptStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.ocrAttemptStatus;
export const selectUserAvailableCredits = (
  state: RootState,
): IUserAvailableCredits | null => state.me.userAvailableCredits; 
export const selectIsAnnualFeeNotPaid = (
  state: RootState,
): boolean => state.me.isAnnualFeeNotPaid; 
export const selectChangeCourseEndDateStatus = (
  state: RootState,
): RequestStatusEnum | null => state.me.changeCourseEndDateStatus;
export const selectChangeCourseEndDateError = (
  state: RootState,
): any => state.me.changeCourseEndDateError;
export const selectChangeCourseEndDateMessage = (state: RootState): string | null => state.me.changeCourseEndDateMessage;

export default slice.reducer;