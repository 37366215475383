import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import SignupNav from 'components/SignupStepper';
import { ReactComponent as IconLine1 } from 'assets/images/splash_line1.svg';
import { ReactComponent as IconLine2 } from 'assets/images/splash_line2.svg';
import BgCar from 'assets/images/splash_car_2.png';
import { IMAGE_URL } from 'common/constants';
import classNames from 'classnames';
import { AuthenticatedTopNav, SignupTopNav } from 'components/TopNav';

interface Props {
  children: ReactNode;
}
const SignUpLayout = ({ children }: Props) => {
  const { pathname } = useLocation();

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return isMobile ? (
    <section
      className={classNames(
        'relative m-auto mt-[-70px] min-h-[100vh] w-full bg-[#F2F2F2] md:mt-0',
      )}
    >
      <div
        className={classNames('', {
          hidden:
            pathname === '/register-v2/' || pathname === '/register-business',
        })}
      >
        <SignupNav />
      </div>
      <div className="mx-[25px] flex min-h-[calc(100vh-200px)] flex-auto items-start justify-center pb-[25px] pt-[45px] md:absolute md:mx-0 md:justify-start md:pb-[100px] md:pt-[100px]">
        {children}
      </div>
    </section>
  ) : (
    <div className="relative w-full">
      <div className="absolute z-[9999999999] w-full">
        {isMobile ? (
          <></>
        ) : pathname === '/register-v2' ||
          pathname === '/register-business' ||
          pathname === '/register-v2/id-verification' ||
          pathname === '/register-v2/form' ? (
          <SignupTopNav />
        ) : (
          <AuthenticatedTopNav />
        )}
      </div>
      <section className="min-h-[calc(100vh)] w-full bg-flexi-orange-1 !bg-fixed py-[78px]">
        <div></div>
        <img
          src={`${IMAGE_URL}${BgCar}`}
          className="absolute left-[2%] top-[50vh] z-[1] hidden h-auto w-[380px] bg-center bg-no-repeat xl:left-[9%] xl:block"
        />
        <p className="font-primary absolute left-[5%] top-[35vh] z-[2] hidden text-center text-[50px] font-[900] uppercase leading-[60px] text-white xl:left-[10%] xl:block">
          get ready
          <br />
          to drive!
        </p>
        <div className="absolute left-[-20px] top-[45px] z-[1] hidden w-[60%] min-w-[745px] xl:left-[-5px] xl:block">
          <IconLine1 width="100%" height="auto" />
        </div>
        <div className="absolute left-[calc(14vw+250px)] top-[45vh] hidden w-[30%] min-w-[300px] xl:block">
          <IconLine2 width="100%" height="auto" />
        </div>
        <aside className="relative z-[60] mx-auto ml-auto mt-[110px] w-[675px] rounded-[20px] bg-[#F2F2F2] px-[80px] pb-[80px] pt-[42px] xl:mr-[145px]">
          <div
            className={classNames(
              '-translate-x-1-2 absolute left-[1/2] top-[40px] w-[515px]',
              {
                hidden:
                  pathname === '/register-v2' ||
                  pathname === '/register-business' ||
                  pathname === '/register-v2/done' ||
                  pathname === '/register-v2/id-verification' ||
                  pathname === '/register-v2/form',
              },
            )}
          >
            <SignupNav />
          </div>
          {children}
        </aside>
      </section>
    </div>
  );
};

export default SignUpLayout;
