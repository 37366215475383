import classNames from 'classnames';
import React from 'react';

interface Props {
  title?: string;
  children: React.ReactNode;
  className?: string;
  error?: any;
  disabled?: any;
  margin?: number;
  positionTitle?: string;
  width?: string;
  extraClass?: string;
}

const CustomInputSignUp = ({
  title,
  children,
  error,
  disabled=false,
  margin = 0,
  width,
  extraClass,
  positionTitle
}: Props) => {
  return (
    <div
      className={classNames(
        `[&>textarea]:!border-t-tranparent [&>input]:!border-t-tranparent relative flex h-[50px] w-full items-center !bg-transparent md:h-[60px]
      [&>.react-tel-input]:pointer-events-auto [&>.react-tel-input]:!self-end [&>input:disabled]:!border-flexi-black-7 [&>input:disabled]:!bg-white [&>input:focus]:!border-flexi-orange-1 md:[&>input:focus]:!border-zinc-300  
      [&>input]:!self-end [&>input]:text-[12px] [&>input]:!outline-none [&>input]:md:text-[14px] [&>select:disabled]:border-flexi-black-7
      [&>select:disabled]:!border-t-transparent [&>select:disabled]:!bg-white [&>select:focus]:!border-flexi-orange-1 md:[&>select:focus]:!border-zinc-300 [&>select]:pointer-events-auto [&>select]:!self-end [&>select]:!outline-none [&>textarea:focus]:!border-flexi-orange-1 md:[&>textarea:focus]:!border-zinc-300 [&>textarea]:pointer-events-auto [&>textarea]:!self-end
      mt-[${
        margin + 0
      }px] w-[${width}px] ${extraClass} [&>*]:placeholder:text-transparent [&>*]:disabled:placeholder:!bg-red-400`,
        {
          'pointer-events-none opacity-50': disabled,
        },
      )}
    >
      {children}
      <p
        className={classNames(
          `pointer-events-none absolute left-0 top-0 h-[20px]  w-full rounded-tl-lg rounded-tr-lg !border-[1px] border-zinc-300 !border-b-transparent bg-white delay-[75ms] peer-focus:border-flexi-orange-1 md:peer-focus:border-zinc-300`,
          {
            '!border-red-500 !border-b-transparent md:peer-focus:!border-x-zinc-300 md:peer-focus:!border-t-zinc-300':
              error,
          },
        )}
      ></p>
      <label
        htmlFor={title}
        className={classNames(
          `pointer-events-none absolute left-[17px] top-[7px] z-[10] text-[9px] text-flexi-black-2 opacity-[.6] duration-200 peer-placeholder-shown:top-[15px] 
          peer-placeholder-shown:text-[14px] peer-focus:top-[7px] peer-focus:text-[9px] md:top-[9px] peer-placeholder-shown:md:top-[18px] peer-placeholder-shown:md:text-[16px] peer-focus:md:top-[9px] peer-focus:md:text-[9px]
          ${positionTitle}`,
        )}
      >
        {title}
      </label>
    </div>
  );
};

export default CustomInputSignUp;
