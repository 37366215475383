import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  loginAccount,
  logoutAccount,
  registerAccount,
  forgotPassword,
  forgotPasswordSms,
  verifyEmail,
  verifyBusinessName,
  verifyLicense,
  verifyOtp,
  sendOtp,
  fetchOtpExpiry,
  resetPassword,
  resetPasswordV2,
  getAbrLookup,
  registerBusinessAccount,
  resetPasswordOtp,
  resetPasswordOtpV2,
  checkTokenInfo,
  verifyOtpAddAccount,
  getResetOtp,
  getDecryptToken,
  logoutAccountApiless,
  registerAccountV2,
  verifyOtpV2,
  verifyLicenseV2,
  registerPlanTypeV2,
  registerPlanTypeSelectedV2,
  registerPlanSelectedV2,
  getUniversitiesV2,
  verifyEmailV2,
  planReset,
  registerBusinessAccountV2,
  registerBusinessDriverAccountV2,
  verifyAddress
} from './sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.LOGIN_ACCOUNT, loginAccount),
  takeLatest(SAGA_ACTIONS.LOGOUT_ACCOUNT, logoutAccount),
  takeLatest(SAGA_ACTIONS.REGISTER_ACCOUNT, registerAccount),
  takeLatest(SAGA_ACTIONS.REGISTER_BUSINESS_ACCOUNT, registerBusinessAccount),
  takeLatest(SAGA_ACTIONS.FORGOT_PASSWORD, forgotPassword),
  takeLatest(SAGA_ACTIONS.FORGOT_PASSWORD_SMS, forgotPasswordSms),
  takeLatest(SAGA_ACTIONS.RESET_PASSWORD, resetPassword),
  takeLatest(SAGA_ACTIONS.RESET_PASSWORD_V2, resetPasswordV2),
  takeLatest(SAGA_ACTIONS.RESET_PASSWORD_OTP, resetPasswordOtp),
  takeLatest(SAGA_ACTIONS.RESET_PASSWORD_OTP_V2, resetPasswordOtpV2),
  takeLatest(SAGA_ACTIONS.VERIFY_EMAIL, verifyEmail),
  takeLatest(SAGA_ACTIONS.VERIFY_BUSINESS_NAME, verifyBusinessName),
  takeLatest(SAGA_ACTIONS.VERIFY_LICENSE, verifyLicense),
  takeLatest(SAGA_ACTIONS.VERIFY_OTP, verifyOtp),
  takeLatest(SAGA_ACTIONS.SEND_OTP, sendOtp),
  takeLatest(SAGA_ACTIONS.FETCH_OTP_EXPIRY, fetchOtpExpiry),
  takeLatest(SAGA_ACTIONS.GET_ABR_LOOKUP, getAbrLookup),
  takeLatest(SAGA_ACTIONS.CHECK_TOKEN_INFO, checkTokenInfo),
  takeLatest(SAGA_ACTIONS.VERIFY_OTP_ADD_ACCOUNT, verifyOtpAddAccount),
  takeLatest(SAGA_ACTIONS.GET_RESET_OTP, getResetOtp),
  takeLatest(SAGA_ACTIONS.GET_DECRYPT_TOKEN, getDecryptToken),
  takeLatest(SAGA_ACTIONS.LOGOUT_ACCOUNT_APILESS, logoutAccountApiless),
  takeLatest(SAGA_ACTIONS.REGISTER_ACCOUNT_V2, registerAccountV2),
  takeLatest(SAGA_ACTIONS.VERIFY_OTP_V2, verifyOtpV2),
  takeLatest(SAGA_ACTIONS.VERIFY_LICENSE_V2, verifyLicenseV2),
  takeLatest(SAGA_ACTIONS.REGISTER_PLAN_TYPE_V2, registerPlanTypeV2),
  takeLatest(SAGA_ACTIONS.REGISTER_PLAN_TYPE_SELECTED_V2, registerPlanTypeSelectedV2),
  takeLatest(SAGA_ACTIONS.REGISTER_PLAN_SELECTED_V2, registerPlanSelectedV2),
  takeLatest(SAGA_ACTIONS.GET_UNIVERSITIES_V2, getUniversitiesV2),
  takeLatest(SAGA_ACTIONS.VERIFY_EMAIL_V2, verifyEmailV2),
  takeLatest(SAGA_ACTIONS.PLAN_RESET, planReset),
  takeLatest(SAGA_ACTIONS.REGISTER_BUSINESS_ACCOUNT_V2, registerBusinessAccountV2),
  takeLatest(SAGA_ACTIONS.REGISTER_BUSINESS_DRIVER_ACCOUNT_V2, registerBusinessDriverAccountV2),
  takeLatest(SAGA_ACTIONS.VERIFY_ADDRESS, verifyAddress),
];

export default actions;
