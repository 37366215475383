import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  isSupportedCountry,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { FcCheckmark } from 'react-icons/fc';
import { useMediaQuery } from 'react-responsive';
import { useJsApiLoader } from '@react-google-maps/api';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'react-phone-number-input/input';
import classNames from 'classnames';
import * as Yup from 'yup';

import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { GOOGLE_API } from 'common/constants';
import { ICountryCode, RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import {
  selectEmailVerified,
  selectError,
  selectPendingVerifyEmail,
  selectRegisterStatus,
  selectSignUpLicense,
  selectTokenInfo,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import COUNTRIES from 'common/countries';
import CustomInput from 'components/CustomInputSignUp';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import CustomCheckBox from 'components/CustomCheckbox';
import PendingLoader from 'components/PendingLoader';

interface Props {
  accountType: number;
  token?: string;
}

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  contactCountry: string;
  country: string;
  licenseNumber: string;
  licenseCardNumber: string;
  isMailSubscriber: boolean;
  vffMembershipId: string;
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  password: Yup.string()
    .required('')
    .matches(/\d/, '')
    .matches(/[a-z]/, '')
    .matches(/[A-Z]/, '')
    .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
    .min(8),
  passwordConfirmation: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  contact: Yup.string().required('Mobile number is required'),
});

const Form = ({ accountType, token }: Props) => {
  const dispatch = useAppDispatch();
  const registerStatus = useAppSelector(selectRegisterStatus);
  const isPendingVerifyEmail = useAppSelector(selectPendingVerifyEmail);
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const error = useAppSelector(selectError);
  const tokenInfo = useAppSelector(selectTokenInfo);
  const [passLVisible, setPassLVisible] = useState(false);
  const [passRVisible, setPassRVisible] = useState(false);
  const [password, setPassword] = useState('');
  const licenseDetails = useAppSelector(selectSignUpLicense);
  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<FormValues>(formOptions);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const isErrorEmail =
    errors?.email || error?.email || error === 'email_invalid';

  const [phone, setPhone] = useState<any>('');
  const [phoneCode, setPhoneCode] = useState<any>('AU');
  const [isInternational, setIsInternational] = useState(false);
  const [libraries] = useState<any>(['places']);
  const [agree, setAgree] = useState(false);

  const handleMobileSelectChange = (e: any) => {
    if (e.target.value !== 'international') {
      setPhoneCode(e.target.value);
      setPhone('');
      setIsInternational(false);
    } else {
      setIsInternational(true);
    }
  };

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API,
    libraries,
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (registerStatus !== RequestStatusEnum.PENDING) {
      if (accountType === 5) {
        const payload = {
          ...values,
          contactCountry: phoneCode,
          country: licenseDetails?.country.toLocaleLowerCase(),
          licenseNumber: licenseDetails?.licenseNumber,
          licenseCardNumber: licenseDetails?.licenseCardNumber
            ? licenseDetails?.licenseCardNumber
            : '',
          isMailSubscriber: values.isMailSubscriber ? 1 : 0,
          token,
        };
        dispatch({
          type: ACTIONS.REGISTER_BUSINESS_DRIVER_ACCOUNT_V2,
          payload,
        });
      } else {
        const payload = {
          ...values,
          contactCountry: phoneCode,
          country: licenseDetails?.country.toLocaleLowerCase(),
          licenseNumber: licenseDetails?.licenseNumber,
          licenseCardNumber: licenseDetails?.licenseCardNumber
            ? licenseDetails?.licenseCardNumber
            : '',
          isMailSubscriber: values.isMailSubscriber ? 1 : 0,
        };
        dispatch({ type: ACTIONS.REGISTER_ACCOUNT_V2, payload });
      }
    }
  };

  useEffect(() => {
    setValue('contact', phone);
  }, [phone]);

  useEffect(() => {
    if (tokenInfo) {
      setValue('email', tokenInfo.email);
    }
  }, [tokenInfo]);

  useEffect(() => {
    if (accountType === 5) {
      dispatch({
        type: ACTIONS.CHECK_TOKEN_INFO,
        payload: {
          token,
          type: 'business-invite',
        },
      });
    }
  }, [accountType]);

  return isMapLoaded ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('grid grid-cols-1 gap-y-[24px]', {
        '!gap-y-[16px]': !isMobile,
      })}
    >
      {isMobile && (
        <p className="mx-[15px] pb-[4px] text-center text-[14px] leading-[20px]">
          Please enter an email that you have access to.
        </p>
      )}

      <div>
        <CustomInput title="Full Name" error={errors.fullName}>
          <input
            type="text"
            placeholder="Full name"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': errors?.fullName,
              },
            )}
            {...register('fullName', {})}
          />
        </CustomInput>
        <p className="ml-[20px] mt-[10px] text-[10px]">
          *Input your name as it appears on your license.
        </p>
        {!isEmpty(errors.fullName) && (
          <InvalidText>{errors.fullName?.message}</InvalidText>
        )}
      </div>

      <div className="relative flex w-full flex-1 flex-col items-center">
        <CustomInput title={'Email'} error={isErrorEmail}>
          <input
            id={'Email'}
            type="text"
            placeholder="email"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': isErrorEmail,
              },
            )}
            {...register('email', {
              onBlur: async (e) => {
                const result = await trigger('email');
                if (result) {
                  dispatch({
                    type: ACTIONS.VERIFY_EMAIL,
                    payload: {
                      email: e.target.value,
                      type: accountType === 5 ? 2 : accountType,
                    },
                  });
                }
              },
            })}
            disabled={accountType === 5}
          />
          <div className="absolute right-[15px] top-[50%] grid  translate-y-[-50%] place-items-center">
            {isPendingVerifyEmail && (
              <span className="h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
            )}
            {isEmailVerified && <FcCheckmark />}
          </div>
        </CustomInput>
        {!isEmpty(errors.email) && (
          <InvalidText>{errors.email?.message}</InvalidText>
        )}
        {error === 'email_invalid' && (
          <InvalidText>Invalid school email</InvalidText>
        )}
        {error?.email && <InvalidText>{error?.email}</InvalidText>}
      </div>

      <div className="flex flex-col">
        <CustomInput title="Password" error={errors.password}>
          <input
            type={passLVisible ? 'text' : 'password'}
            placeholder="password"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': errors?.password,
              },
            )}
            {...register('password', {})}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              setPassLVisible(!passLVisible);
            }}
            className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
          >
            {passLVisible ? (
              <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
            ) : (
              <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.password) && (
          <div className=" my-2 text-[12px] text-red-500">
            {password ? (
              <ul className="relative ml-5 list-disc">
                <li
                  className={classNames('', {
                    hidden: password.length >= 8,
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must be at least 8 characters
                  </span>
                </li>
                <li
                  className={classNames('', {
                    hidden: password.match(/\d/),
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must contain at least 1 number
                  </span>
                </li>
                <li
                  className={classNames('', {
                    hidden:
                      password.match(/[a-z]/) &&
                      password.match(/[a-z]/) &&
                      password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/),
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must contain lowercase, uppercase, and a special character
                  </span>
                </li>
              </ul>
            ) : (
              <p>This field is required</p>
            )}
          </div>
        )}
      </div>
      <div className="form-control mb-3 w-full flex-1">
        <CustomInput
          title="Confirm Password"
          error={errors.passwordConfirmation}
        >
          <input
            type={passRVisible ? 'text' : 'password'}
            placeholder="confirm password"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': errors?.passwordConfirmation,
              },
            )}
            {...register('passwordConfirmation', {})}
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              setPassRVisible(!passRVisible);
            }}
            className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg  bg-transparent"
          >
            {passRVisible ? (
              <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
            ) : (
              <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.passwordConfirmation) && (
          <InvalidText>{errors.passwordConfirmation?.message}</InvalidText>
        )}
      </div>

      <div className="h-[1px] w-full bg-zinc-300 md:my-[14px]"></div>

      <div className="mx-[15px] pb-[4px] pt-[8px] text-center text-[14px] leading-[20px] md:text-left">
        <p className="mb-[16px] text-[16px] font-bold">Mobile Number</p>
        <p>
          Please enter your contact details. You need to use a mobile number
          that you have access to. We will send your access code through text
          message.
        </p>
      </div>

      <CustomInput title="Country Code" error={errors.contactCountry}>
        <select
          onChange={handleMobileSelectChange}
          onClick={(e) => e.stopPropagation()}
          className="peer input input-bordered w-full pr-[50px] pt-[10px] !text-[14px]"
          defaultValue={'AU'}
        >
          {COUNTRIES.map((country: ICountryCode, i: number) => {
            return (
              isSupportedCountry(country.code) && (
                <option
                  key={i}
                  value={country.code}
                  className="!mb-[5px] !pb-[5px] text-[14px]"
                >
                  {country.name} &#91;+{getCountryCallingCode(country.code)}
                  &#93;
                </option>
              )
            );
          })}
        </select>
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
        >
          <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
        </span>
      </CustomInput>
      {!isEmpty(errors.contactCountry) && (
        <InvalidText>{errors.contactCountry?.message}</InvalidText>
      )}
      <div>
        <CustomInput
          title="Mobile Number"
          error={errors.contact || error?.contact}
        >
          <Input
            country={phoneCode}
            international
            withCountryCallingCode={!isInternational}
            value={phone}
            onChange={setPhone}
            placeholder="mobile"
            className={classNames(
              'peer h-[40px] w-full rounded-b-lg border-[1px] !border-t-0 border-b-[1px] border-flexi-black-7 !border-t-blue-500 pl-[15px]',
              {
                '!border-red-500': errors?.contact || error?.contact,
              },
            )}
            maxLength={
              phoneCode.toLocaleLowerCase() === 'nz'
                ? 15
                : isValidPhoneNumber(phone || `+61`, phoneCode)
                ? phone.length
                : 17
            }
          />
        </CustomInput>
        {!isEmpty(errors?.contact) && (
          <InvalidText>Mobile number is required</InvalidText>
        )}
        {!isEmpty(error?.contact) && <InvalidText>{error.contact}</InvalidText>}
      </div>

      {accountType === 5 && (
        <>
          <div className="h-[1px] w-full bg-zinc-300 md:my-[24px]"></div>
          <CustomInput title="Partner Promotions">
            <input
              type="text"
              placeholder="Partner Promotions"
              className={classNames(
                'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              )}
              {...register('vffMembershipId')}
            />
          </CustomInput>
        </>
      )}

      <div className="mt-[25px] flex flex-col gap-[15px] sm:gap-[25px]">
        <CustomCheckBox
          fill="black"
          size={10}
          titleClass="text-[12px] sm:text-[14px]"
          title="Sign me up to receive special offers and updates."
        >
          <input
            type="checkbox"
            className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
            {...register('isMailSubscriber')}
            // @ts-ignore
            style={{ '--chkfg': '0', '--chkbg': '0' }}
            defaultChecked
          />
        </CustomCheckBox>
        <CustomCheckBox
          fill="black"
          size={10}
          titleClass="text-[12px] sm:text-[14px] max-w-[80%]"
          title={`<p">
          By clicking &apos;Create my account&apos;, you confirm that you have
          read and accepted Flexicar&apos;s
          <a
            href="/terms-and-conditions"
            target="_blank"
            class="font-bold text-primary"
          >
            Terms &#38; Conditions
          </a>and
          <a
            href="/privacy-policy-page"
            target="_blank"
            class="font-bold text-primary"
          >
            Privacy Policy
          </a>
          .
        </p>`}
        >
          <input
            type="checkbox"
            className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
            {...register('isMailSubscriber')}
            // @ts-ignore
            style={{ '--chkfg': '0', '--chkbg': '0' }}
            onChange={(e: any) => setAgree(e.target.checked)}
          />
        </CustomCheckBox>
      </div>

      <button
        type="submit"
        className={classNames(
          'btn btn-primary mt-[25px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px] md:h-[60px]',
          {
            'loading bg-flexi-green-2':
              registerStatus === RequestStatusEnum.PENDING,
          },
        )}
        disabled={isPendingVerifyEmail || !agree}
      >
        Create my account
      </button>
    </form>
  ) : (
    <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
      <PendingLoader size={100} />
    </section>
  );
};

export default Form;
