import React, { useState } from "react";
import Slider from 'react-slick';
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

const Onboarding = () => {
  const [page, setPage] = useState(1);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return(
    <div className="onboarding text-center flex flex-col justify-center h-full w-full">
      <Slider 
        {...settings}
        afterChange={(index) => setPage(index + 1)}
      >
        <Page1 page={page}/>
        <Page2 page={page}/>
        <Page3 page={page}/>
      </Slider>
      <div className="mt-auto">
        { page !== 3 ?
          <a href="/app/booking"
            className="text-[12px] font-bold text-flexi-orange-1"
            >Skip</a>
          :
          <a href="/app/booking" className='btn btn-primary mt-[30px] h-[40px] w-full rounded-full w-full !capitalize'>Let&apos;s Go!</a>

        }
      </div>
    </div>
  )
}

export default Onboarding;