import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';

import gsap from 'gsap';
import Lottie from 'react-lottie';

import lottieBusiness from 'assets/json/icon-business.json';
import { IRegisterPlan, RequestStatusEnum } from 'common/types';
import { getToken } from 'common/helpers';
import { selectPlans, selectPlansStatus, selectUser } from 'modules/me/slice';
import { setErrorPromo } from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import ListAndRates from './ListAndRates';
import Inclusions from './Inclusions';
import Card from './Card';

const SignUpBusinessPlan = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const plans = useAppSelector(selectPlans);
  const plansStatus = useAppSelector(selectPlansStatus);
  const token = getToken();
  const [planSelected, setPlanSelected] = useState<IRegisterPlan | null>(null);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const businessIcon = {
    loop: false,
    autoplay: true,
    animationData: lottieBusiness,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSelectPlan = (plan: number) => {
    const payload = { planId: plan };
    dispatch({ type: ACTIONS.REGISTER_PLAN_SELECTED_V2, payload });
  };

  useEffect(() => {
    if (user && token) {
      dispatch({
        type: ACTIONS.FETCH_VEHICLE_LIST_V2,
        payload: {
          isSenior: 0,
        },
      });
    }
  }, [user, token]);

  useEffectOnce(() => {
    if (token) {
      dispatch({
        type: ACTIONS.GET_PLANS_V2,
      });
    }
    dispatch(setErrorPromo(null));
  });

  useUpdateEffect(() => {
    setPlanSelected(plans[0]);
  }, [plans]);

  return (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-388px)] w-full max-w-[600px] flex-col pt-[40px]"
    >
      {planSelected && plansStatus !== RequestStatusEnum.PENDING ? (
        <>
          <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[50px] text-flexi-orange-1 md:text-[28px]">
            Flexi-{planSelected?.name}
          </h2>

          <div className="flex flex-col items-center">
            <Lottie options={businessIcon} height={200} width={200} />
            <div className="w-full">
              <Card plan={planSelected} key={planSelected?.name} />
            </div>

            <ListAndRates />
            <Inclusions inclusions={planSelected?.inclusions} />
          </div>
          <button
            onClick={() => handleSelectPlan(planSelected!.id)}
            className="btn btn-primary mt-[70px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px] lg:h-[60px]"
          >
            Select Plan
          </button>
        </>
      ) : (
        <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
          <PendingLoader size={100} />
        </section>
      )}
    </section>
  );
};

export default SignUpBusinessPlan;
