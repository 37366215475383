import React from 'react';

import PlacesAutocomplete from 'react-places-autocomplete';
import classNames from 'classnames';

interface Props {
  autoLocation: string;
  onChange: (e: any) => void;
  handleSelectAutocomplete: (e: any) => void;
}

function FieldAutoComplete({
  autoLocation,
  handleSelectAutocomplete,
  onChange,
}: Props) {
  return (
    <PlacesAutocomplete
      value={autoLocation}
      onChange={onChange}
      onSelect={handleSelectAutocomplete}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative">
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input input input-bordered truncate',
            })}
          />
          <div className="autocomplete-dropdown-container scrollbar absolute max-h-[250px] w-[350px] overflow-y-auto">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={i}
                  className={classNames('border-[1px] border-t-0 p-[10px]', {
                    '!border-t-[1px]': i === 0,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default FieldAutoComplete;
