import React from 'react';
import { ReactComponent as IconCheck } from 'assets/images/check.svg';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  title?: string;
  titleElement?: React.ReactNode;
  size?: number;
  isCapitalize?: boolean;
  titleClass?: string;
  fill?: string;
}

function CustomCheckBox({
  children,
  title,
  size = 10,
  titleElement,
  isCapitalize,
  titleClass,
  fill = 'fill-flexi-orange-1',
}: Props) {
  return (
    <div>
      <label
        className={classNames(
          'flex cursor-pointer select-none items-center gap-x-[15px]',
          {
            capitalize: isCapitalize,
          },
        )}
      >
        <div
          className={`checkbox__container relative flex items-center justify-center self-start overflow-hidden `}
        >
          {children}
          <IconCheck
            className={`svg__container absolute translate-y-[150%] duration-200 ease-in-out ${fill} w-[${size}px] h-[${size}px]`}
          />
        </div>
        <div className={`${titleClass}`}>
        {title ? <span dangerouslySetInnerHTML={{ __html: title }} /> : titleElement}
        </div>
        {/* <span className="font-400 text-[14px] tracking-[0.2px]">{title}</span> */}
      </label>
    </div>
  );
}

export default CustomCheckBox;
