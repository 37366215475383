import React, { useState } from 'react';
import CustomInput from 'components/CustomInput';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { selectOnlineQueryStatus, selectUser, setOnlineQueryStatus } from 'modules/me/slice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RequestStatusEnum } from 'common/types';
import Modal from 'components/Modal';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
// import { ReactComponent as IconError } from 'assets/images/error.svg';
import PendingLoader from 'components/PendingLoader';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import classNames from 'classnames';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

type FormValues = {
  subject: string;
  message: string;
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Inquiry is required'),
});

const ContactForm = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const onlineQueryStatus = useAppSelector(selectOnlineQueryStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const subjectOptions = [
    'Member Queries',
    'Booking Queries',
    'Invoice Queries',
    'Fines & infringement queries',
    'Other'
  ];
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, resetField, control } =
    useForm<FormValues>(formOptions);

  const subject = useWatch({ control, name: 'subject' });
  const message = useWatch({ control, name: 'message' });
  const isDisabled = !subject || !message;

  const onSubmit: SubmitHandler<FormValues> = ({ subject, message }) => {
    dispatch({
      type: ACTIONS.SEND_SUPPORT_EMAIL,
      payload: { subject, message },
    });

    setIsModalOpen(true);
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const clearFormFields = () => {
    resetField('subject');
    resetField('message');
    setIsModalOpen(false);
    dispatch(setOnlineQueryStatus(null))
  };

  const renderModalContent = () => {
    if(onlineQueryStatus === RequestStatusEnum.PENDING){
      return <PendingLoader />;
    }
    if (onlineQueryStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <IconSuccess className='icon' />
          <h1 className="title form-control mt-[44.4px] text-center text-[20px] md:text-4xl font-bold md:leading-[42px]">
            Your inquiry has been<br/>successfully sent!
          </h1>
          <p className="mt-[20px] text-center text-[16px] md:text-[16px]">
            We received your inquiry. We&apos;ll get back to you as soon as we
            can.
          </p>
          <button
            className={`!h-[50px] md:!h-[60px] btn mt-[40px] w-full rounded-full border-none bg-flexi-green-1 capitalize`}
            onClick={() => clearFormFields()}
          >
            Ok
          </button>
        </>
      );
    }
    if (onlineQueryStatus === RequestStatusEnum.FAILED) {
      return (
        <>
          <LottieAnimation lottieFile={errorLottie} isStopped={!isModalOpen} />
          <h1 className="title form-control mt-[44.4px] text-center text-[20px] font-bold md:text-4xl md:leading-[42px]">
            Failed to send!
          </h1>
          <p className="mt-[20px] text-center text-[16px] md:text-[16px]">
            Unable to send inquiry.
          </p>
          <button
            className={`btn mt-[40px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
            onClick={() => clearFormFields()}
          >
            Ok
          </button>
        </>
      );
    }
  };

  return (
    <section className="mt-[40px] w-full text-[16px] md:mt-[50px] md:text-[18px]">
      <p>
        To contact us about membership and billing information, sales, marketing
        and media inquiries, place send an inquiry using the form below.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        <div className="form-control mb-[20px] mt-[30px] grid grid-cols-2 gap-[20px]">
          <CustomInput title="Name">
            <input
              type="text"
              value={user?.fullname}
              className={classNames(
                'peer input-bordered input w-full !text-[14px] md:!text-[16px]',
              )}
              disabled
            />
          </CustomInput>
          <CustomInput title="Email Address">
            <input
              type="text"
              value={user?.email}
              className={classNames(
                'peer input-bordered input w-full !text-[14px] md:!text-[16px]',
              )}
              disabled
            />
          </CustomInput>
          <CustomInput title="Subject">
            <select
              className={classNames(
                `peer input-bordered input w-full pr-[50px] !text-[14px] md:!text-[16px]`,
                {
                  'text-[#9D9D9D]': subject === undefined,
                },
              )}
              {...register('subject')}
            >
              <optgroup className="border-transparent">
                <option className="hidden">Please select a subject</option>
                {subjectOptions.map((option, index) => {
                  return (
                    <option
                      key={index}
                      value={option}
                      className="!p-[45px] text-black"
                    >
                      {option}
                    </option>
                  );
                })}
              </optgroup>
            </select>
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
            >
              <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
            </span>
          </CustomInput>
          <CustomInput title="Phone Number">
            {' '}
            <input
              type="text"
              value={user?.contact}
              className={classNames(
                'peer input-bordered input w-full pr-[50px] !text-[14px] md:!text-[16px]',
              )}
              disabled
            />
          </CustomInput>
        </div>
        <div className="h-fit">
          <CustomInput
            title="Inquiry/Message"
            margin={20}
            extraClass="h-[100px] md:h-[100px] relative"
          >
            <textarea
              className="scrollbar peer textarea-bordered textarea absolute top-[19px] w-full resize-none rounded-t-none border-t-transparent pt-[30px] font-medium leading-[1.429] focus:outline-0"
              rows={8}
              {...register('message')}
            ></textarea>
          </CustomInput>
        </div>

        <div className="mt-[180px]">
          <button
            type="submit"
            className={`btn h-[60px] max-h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 disabled:text-white md:max-h-[60px]`}
            disabled={isDisabled}
          >
            Submit
          </button>
        </div>
      </form>

      <Modal
        isModalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          if (onlineQueryStatus === RequestStatusEnum.SUCCESS) {
            clearFormFields();
          }
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        {renderModalContent()}
      </Modal>
    </section>
  );
};

export default ContactForm;
