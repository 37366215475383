import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { IMAGE_URL } from 'common/constants';
import {
  IBookingSummaryPaymentMethod,
  IVehicleSummaryBreakdowns,
  RequestStatusEnum,
} from 'common/types';
import { selectBookingSummaryModifyStatus } from 'modules/booking/slice';
import { selectDefaultPaymentCard, selectUser } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import LoaderText from 'components/LoaderText';

interface Props {
  actualAmount: number | null | undefined;
  breakdowns: IVehicleSummaryBreakdowns | undefined;
  paymentMethod: IBookingSummaryPaymentMethod | undefined;
}

const SummaryInvoice = ({ actualAmount, breakdowns, paymentMethod }: Props) => {
  const dispatch = useAppDispatch();
  const { isBusinessAccount } = useBusiness();
  const user = useAppSelector(selectUser);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const detailsPending = useAppSelector(selectBookingSummaryModifyStatus);
  const isDetailsPending = detailsPending === RequestStatusEnum.PENDING;
  const [imageName, setNewImage] = useState<string>('');
  const [isImageExist, setIsImageExist] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      dispatch({
        type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD,
      });
    }
  }, []);

  useEffect(() => {
    if (defaultPaymentCard)
      try {
        setNewImage(
          require(`assets/images/card-${defaultPaymentCard?.type}.svg`),
        );
      } catch (ex) {
        setIsImageExist(false);
      }
  }, [defaultPaymentCard]);

  return (
    <section>
      <div className="mt-[0px] w-full place-items-center rounded-[10px] border-[1px] bg-flexi-white-1 p-[30px] md:mt-[100px] md:w-[400px] md:p-[60px]">
        <h5 className="col-span-2 mb-[40px] text-center text-[18px] font-bold md:text-[26px]">
          Cost Summary
        </h5>
        <ul className="flex flex-col gap-[20px] text-[16px]">
          <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
            <small className="block text-[16px] md:text-[16px]">
              {(!actualAmount || actualAmount === null) && 'Estimated'} Cost
            </small>

            {isDetailsPending ? (
              <LoaderText
                height="25"
                width="75px"
                otherClass="justify-self-end rounded-none !bg-black/5"
                parentClass=" justify-end"
              />
            ) : (
              <span className=" text-[16px] md:text-[16px]">
                $
                {actualAmount && actualAmount !== null
                  ? actualAmount.toFixed(2)
                  : breakdowns?.estimateTotal.toFixed(2)}
              </span>
            )}
          </li>
          {breakdowns?.addons && (
            <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
              <div>
                <p className="block text-[16px] md:text-[16px]">Add-ons</p>
                <p className="text-[12px] font-bold">
                  {breakdowns.addons.map((addon) => addon.name).join(', ')}
                </p>
              </div>
              <span className="text-[16px] md:text-[16px]">
                $
                {breakdowns.addons
                  .reduce((sum, a) => sum + a.value, 0)
                  .toFixed(2)}
              </span>
            </li>
          )}
          {breakdowns?.promo && (
            <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
              <div>
                <p className="block text-[16px] md:text-[16px]">Promotions</p>
                <p className="text-[12px] font-bold">
                  {breakdowns?.promo?.name}
                </p>
              </div>
              <span className="text-[16px] md:text-[16px]">
                -${breakdowns.promo.actualValue?.toFixed(2)}
              </span>
            </li>
          )}
          {breakdowns && breakdowns?.creditsUsed > 0 && (
            <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
              <small className="block text-[16px]">Credits</small>
              <span className="text-[16px]">${breakdowns?.creditsUsed.toFixed(2)}</span>
            </li>
          )}
          {breakdowns && breakdowns?.amountPaid > 0 && (
            <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
              <small className="block text-[16px]">Payment Method</small>
              <span className="text-[16px]">
                ${(breakdowns?.amountPaid).toFixed(2)}
              </span>
            </li>
          )}
        </ul>
        <div className="mt-[22px] flex w-full justify-between">
          <div>
            <p className="text-[20px] font-bold md:text-[22px]">Total</p>
            <p className="text-[10px]">GST Included</p>
          </div>

          {isDetailsPending ? (
            <LoaderText
              height="40"
              width="100px"
              otherClass="justify-self-end rounded-none !bg-black/5"
              parentClass=" justify-end"
            />
          ) : (
            <p className="self-center text-[24px] font-bold md:text-[26px]">
              $
              {actualAmount && actualAmount !== null
                ? actualAmount.toFixed(2)
                : breakdowns?.total?.toFixed(2)}
            </p>
          )}
        </div>
        {paymentMethod?.memberId !== user?.id && isBusinessAccount ? (
          <></>
        ) : (
          <div>
            <p className="mb-[20px] mt-[30px] text-[16px] md:text-[16px]">
              Payment method
            </p>
            {isDetailsPending ? (
              <div className="flex items-center gap-3 text-[14px] w-full">
                <LoaderText
                  height="40"
                  width="65px"
                  otherClass="justify-self-end rounded-none !bg-black/5"
                  parentClass=" justify-end"
                />
                <div className="flex flex-col gap-2">
                  <LoaderText
                    height="18"
                    width="200px"
                    otherClass="justify-self-end rounded-none !bg-black/5"
                    parentClass=" justify-start"
                  />
                  <LoaderText
                    height="18"
                    width="100px"
                    otherClass="justify-self-end rounded-none !bg-black/5"
                    parentClass=" justify-start"
                  />
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-3 text-[14px]">
                <p
                  style={{
                    backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                    backgroundSize: '100%',
                  }}
                  className={classNames(
                    'grid h-[40px] w-[55px] place-items-center bg-[center_left] bg-no-repeat md:w-[65px]',
                    {
                      'border-[1px]': !isImageExist,
                    },
                  )}
                >
                  {!isImageExist && 'N/A'}
                </p>
                <div className="flex flex-col">
                  <p className="w-[200px] text-[14px] md:text-[16px]">
                    <span>●●●● ●●●● ●●●● </span>
                    {paymentMethod
                      ? paymentMethod.cardNumber.substring(
                          paymentMethod.cardNumber.length - 4,
                        )
                      : defaultPaymentCard?.cardNumber}
                  </p>

                  <p className="text-[12px] md:text-[16px]">
                    Expires on{' '}
                    {paymentMethod
                      ? paymentMethod.expiryMonth
                      : defaultPaymentCard?.expiryMonth}
                    /
                    {paymentMethod
                      ? paymentMethod.expiryYear
                      : defaultPaymentCard?.expiryYear}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default SummaryInvoice;
