import React from 'react';

import PlanSelection from 'features/MyPlanDetails/PlanSelection';

const MyPlanSelectionMobile = () => {
  return (
    <section className="px-[30px]">
      <h2 className="font-primary text-center text-[20px] font-[900] uppercase leading-[50px] text-flexi-orange-1 md:mb-0 md:text-[28px]">
        Plan Selection
      </h2>
      <PlanSelection />
    </section>
  );
};

export default MyPlanSelectionMobile;
