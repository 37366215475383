import React from 'react';
import { useEffectOnce } from 'react-use';

import { Helmet } from 'react-helmet';

import { CenteredPageTitle, PageTitleBanner } from 'components/Typography';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PublicContent from 'components/PublicContent';
import ContactForm from './ContactForm';

const ContactUs = () => {
  const dispatch = useAppDispatch();
  const dynamicContent = useAppSelector(selectDynamicBlockContent);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: ['support'],
      },
    });
  });

  return (
    <section>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content={`Help & Support - Contact Us`} />
      </Helmet>
      <PageTitleBanner>contact us</PageTitleBanner>
      <div className="mx-auto min-h-[100vh] px-[40px] py-[120px] md:px-[120px]">
        <div className="mx-auto max-w-[1100px]">
          <div className="child:answer-center mx-auto mb-[100px]">
            <CenteredPageTitle>What can we help with?</CenteredPageTitle>
          </div>
          <div className="flex flex-col items-center gap-[100px] md:flex-row">
            <div className="grow">
              <p className="mb-[40px] text-[18px]">
                To contact us about membership and billing information, sales,
                marketing and media inquiries, place send an inquiry using the
                form below.
              </p>
              <ContactForm />
            </div>
            {dynamicContent ? <PublicContent data={dynamicContent} /> : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
