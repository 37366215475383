import React, { useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import gsap from 'gsap';

import { ReactComponent as IconVerified } from 'assets/images/success.svg';
import { selectPending } from 'modules/auth/slice';
import useAppSelector from 'hooks/useAppSelector';

const OptimizedForgotPasswordOTPSuccess = () => {
  const isPending = useAppSelector(selectPending);
  const { push } = useHistory();
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative m-auto mt-[85px] flex min-h-[calc(100vh-125px)] w-full max-w-[80vw] flex-col items-center md:mt-[100px] md:max-w-[500px]"
    >
      <IconVerified className="mb-[70px] h-[120px] w-[120px] md:mb-[50px]" />
      <h2 className="font-primary mb-[15px] text-left text-[30px] font-[900] uppercase text-primary md:mb-[18px]  md:text-[40px]">
        Verified!
      </h2>
      <p className="text-center text-[16px] md:text-[18px]">
        You can now proceed in creating your new&nbsp;password.
      </p>
      <button
        className={`btn btn-primary mt-auto max-h-[50px] w-[calc(100vw-100px)] rounded-full text-[16px] capitalize md:mt-[70px] md:max-h-[60px] md:w-[500px] ${
          isPending ? 'loading' : ''
        }`}
        onClick={() => push('/auth-v2/forgot-password-confirm')}
      >
        proceed
      </button>
    </section>
  );
};

export default OptimizedForgotPasswordOTPSuccess;
