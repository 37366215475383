import React, { useEffect, useState } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { doc, getFirestore } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import classNames from 'classnames';
import moment from 'moment';
import * as _ from 'lodash';

import closed from 'assets/images/unavailable-icon.svg';
import { FIRESTORE_DRIVERS_COLLECTION_NAME, IMAGE_URL } from 'common/constants';
import { IPodAll, IVehicleBookings, IVehicleSummaryMin } from 'common/types';
import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import useBusiness from 'hooks/useBusiness';
import firebaseApp from 'app/firebaseApp';

interface Props {
  vehicleId: number;
  startDate: Date | null;
  timeZone: string;
  firestoreData: IVehicleSummaryMin[] | null;
  firstVehicleOnList: number;
  isPodOpen: boolean | undefined;
}

const PerVehicleTimeline = ({
  vehicleId,
  startDate,
  timeZone,
  firestoreData,
  firstVehicleOnList,
  isPodOpen,
}: Props) => {
  const user = useAppSelector(selectUser);
  const { podsAll, endDate } = useBooking({});
  const { isBusinessAccount } = useBusiness();
  const startDayInYear = moment(
    moment(startDate).format('MM/DD/YYYY'),
  ).dayOfYear();
  const endDayInYear = moment(moment(endDate).format('MM/DD/YYYY')).dayOfYear();
  const dayDifference = endDayInYear - startDayInYear + 1;
  const startRender =
    startDate &&
    new Date(
      moment(startDate.toLocaleString('en-US', { timeZone: timeZone })).format(
        'YYYY/MM/DD 00:00:00',
      ),
    );
  const endRender =
    endDate &&
    new Date(
      moment(endDate.toLocaleString('en-US', { timeZone: timeZone })).format(
        'YYYY/MM/DD 23:59:00',
      ),
    );

  const viewedDays = Array.from({ length: dayDifference + 2 }, (_, index) => {
    return {
      day: moment(startRender)
        .add(index - 1, 'days')
        .format('dddd')
        .toLocaleLowerCase(),
      date: moment(startRender)
        .add(index - 1, 'days')
        .format('MMM DD'),
    };
  });

  const specificVehicleAvailability = firestoreData?.find((i) => {
    if (i.vehicleId === vehicleId) {
      return i;
    } else {
      return undefined;
    }
  });
  const pod = podsAll?.find(
    (pod: IPodAll) => pod.id === specificVehicleAvailability?.podId,
  );

  // GET DRIVER INFORMATION
  const [selectedDriver, setSelectedDriver] = useState<string>();
  const [driverData, setDriverData] = useState({
    id: null,
    first_name: null,
    last_name: null,
    photo: null,
  });

  const [value] = useDocument(
    selectedDriver
      ? doc(
          getFirestore(firebaseApp),
          FIRESTORE_DRIVERS_COLLECTION_NAME,
          selectedDriver,
        )
      : null,
    { snapshotListenOptions: { includeMetadataChanges: true } },
  );

  useEffect(() => {
    if (value && JSON.stringify(value.data())) {
      setDriverData(JSON.parse(JSON.stringify(value.data())));
    }
  }, [value, selectedDriver]);

  useEffect(() => {
    const elements = document.getElementsByClassName(
      `hover__${firstVehicleOnList}`,
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i]?.classList.replace('bottom-[40px]', 'bottom-[-42.5px]');
      elements[i]?.classList.replace('after:border-8', 'before:border-8');
    }
  }, []);

  return specificVehicleAvailability ? (
    <div className="vehicle-timeline-here relative z-[6] flex !h-[85px] w-full flex-row items-center justify-start pr-[7.5px] text-[8px]">
      {!isPodOpen &&
        _.map(viewedDays, (day, i) => {
          const operation = pod?.operations.find((o) => o.day === day.day);
          let operationBefore = null;
          if (viewedDays[i - 1] !== undefined)
            operationBefore =
              pod?.operations.find((o) => o.day === viewedDays[i - 1].day) ||
              null;
          let operationAfter = null;
          if (viewedDays[i + 1] !== undefined)
            operationAfter = pod?.operations.find(
              (o) => o.day === viewedDays[i + 1].day,
            );
          const zeroTime = moment(new Date(`1970-01-01T00:00:00`));
          const openTime = moment(new Date(`1970-01-01T${operation?.opensAt}`));
          const closeTime = moment(
            new Date(`1970-01-01T${operation?.closesAt}`),
          );
          const endTime = moment(new Date(`1970-01-01T23:59:00`));

          const diffClose1 = moment.duration(openTime.diff(zeroTime)).asHours();
          const diffOpen = moment.duration(closeTime.diff(openTime)).asHours();
          const diffClose2 = moment.duration(endTime.diff(closeTime)).asHours();
          if (i === 0) {
            if (operation?.isClosed) {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={classNames(
                    `group relative left-0 h-[85px] w-[27px] bg-[#CECECE]`,
                    {
                      'rounded-r-[10px]':
                        (operationAfter?.isClosed === 0 &&
                          !operationAfter.opensAt) ||
                        operationAfter?.opensAt === '00:00:00',
                      'rounded-r-[5px]':
                        dayDifference > 3 &&
                        ((operationAfter?.isClosed === 0 &&
                          !operationAfter.opensAt) ||
                          operationAfter?.opensAt === '00:00:00'),
                    },
                  )}
                >
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] z-[9999] min-h-[50px] min-w-[165px] translate-x-[10%] rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {day.date}, 12AM - {day.date}, 11:59PM
                    </p>
                  </div>
                </div>
              );
            }
            if (operation && operation?.closesAt !== '23:59:00') {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={classNames(
                    `group relative left-0 h-[85px] w-[27px] rounded-none bg-[#CECECE]`,
                    {
                      'rounded-r-[10px]':
                        (operationAfter?.isClosed === 0 &&
                          !operationAfter.opensAt) ||
                        operationAfter?.opensAt === '00:00:00',
                      'rounded-r-[5px]':
                        dayDifference > 3 &&
                        ((operationAfter?.isClosed === 0 &&
                          !operationAfter.opensAt) ||
                          operationAfter?.opensAt === '00:00:00'),
                    },
                  )}
                >
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] z-[9999] min-h-[50px] min-w-[165px] -translate-x-[100%] rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {day.date},{' '}
                      {parseInt((operation?.closesAt).slice(0, 2)) % 12 === 0
                        ? 12
                        : parseInt((operation?.closesAt).slice(0, 2)) % 12}
                      {(operation?.closesAt).slice(2, 5) !== ':00' &&
                        (operation?.closesAt).slice(2, 5)}
                      {parseInt((operation?.closesAt).slice(0, 2)) - 11 < 0
                        ? 'AM'
                        : 'PM'}{' '}
                      - {day.date}, 11:59PM
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={`group relative left-0 h-[85px] w-[27px] rounded-none bg-[#CECECE]`}
                ></div>
              );
            }
          } else if (i === viewedDays.length - 1) {
            if (operation?.isClosed) {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={classNames(
                    `group relative left-0 h-[85px] w-[27px] rounded-none bg-[#CECECE]`,
                    {
                      'rounded-l-[10px]':
                        (operationBefore?.isClosed === 0 &&
                          !operationBefore.opensAt) ||
                        operationBefore?.opensAt === '00:00:00',
                      'rounded-l-[5px]':
                        dayDifference > 3 &&
                        ((operationBefore?.isClosed === 0 &&
                          !operationBefore.opensAt) ||
                          operationBefore?.opensAt === '00:00:00'),
                    },
                  )}
                >
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] z-[9999] min-h-[50px] min-w-[165px] -translate-x-[100%] rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {day.date}, 12AM - {day.date}, 11:59PM
                    </p>
                  </div>
                </div>
              );
            }
            if (operation && operation?.opensAt !== '00:00:00') {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={`group relative left-0 h-[85px] w-[27px] rounded-none bg-[#CECECE]`}
                >
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] z-[9999] min-h-[50px] min-w-[165px] -translate-x-[100%] rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {day.date}, 12AM - {day.date},{' '}
                      {parseInt((operation?.opensAt).slice(0, 2)) % 12 === 0
                        ? 12
                        : parseInt((operation?.opensAt).slice(0, 2)) % 12}
                      {(operation?.opensAt).slice(2, 5) !== ':00' &&
                        (operation?.opensAt).slice(2, 5)}
                      {parseInt((operation?.opensAt).slice(0, 2)) - 11 < 0
                        ? 'AM'
                        : 'PM'}
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={`group relative left-0 h-[85px] w-[27px] rounded-none bg-[#CECECE]`}
                ></div>
              );
            }
          } else {
            if (operation?.isClosed) {
              return (
                <div
                  key={`${i}_${vehicleId}_${day}`}
                  className={`group relative h-[85px] rounded-none bg-[#CECECE]`}
                  style={{
                    width: `${(window.innerWidth - 520) / dayDifference}px`,
                    left: `${(window.innerWidth - 520) / dayDifference * ( i - 1 )}px`,
                  }}
                >
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {day.date}, 12AM - {day.date}, 11:59PM
                    </p>
                  </div>
                </div>
              );
            }
            if (
              operation &&
              (operation?.opensAt !== '00:00:00' ||
                operation?.closesAt !== '23:59:00')
            ) {
              return (
                <div className="flex">
                  <div
                    key={`${i}_${vehicleId}_${day}-1`}
                    className={`group relative left-0 h-[85px] rounded-none bg-[#CECECE]`}
                    style={{
                      width: `${
                        ((diffClose1 / 24) * (window.innerWidth - 520)) /
                        dayDifference
                      }px`,
                    }}
                  >
                    <div
                      className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                    >
                      <span>
                        <img
                          className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                          src={`${IMAGE_URL}${closed}`}
                          alt="closed"
                        />
                      </span>
                      Unavailable
                      <p className="text-[7px] font-bold">
                        {day.date}, 12AM - {day.date},{' '}
                        {parseInt((operation?.opensAt).slice(0, 2)) % 12 === 0
                          ? 12
                          : parseInt((operation?.opensAt).slice(0, 2)) % 12}
                        {(operation?.opensAt).slice(2, 5) !== ':00' &&
                          (operation?.opensAt).slice(2, 5)}
                        {parseInt((operation?.opensAt).slice(0, 2)) - 11 < 0
                          ? 'AM'
                          : 'PM'}
                      </p>
                    </div>
                  </div>
                  <div
                    key={`${i}_${vehicleId}_${day}-2`}
                    className={`group relative left-0 h-[85px] rounded-none transparent`}
                    style={{
                      width: `${
                        ((diffOpen / 24) * (window.innerWidth - 520)) /
                        dayDifference
                      }px`,
                    }}
                  />
                  <div
                    key={`${i}_${vehicleId}_${day}-3`}
                    className={`group relative left-0 h-[85px] rounded-none bg-[#CECECE]`}
                    style={{
                      width: `${
                        ((diffClose2 / 24) * (window.innerWidth - 520)) /
                        dayDifference
                      }px`,
                    }}
                  >
                    <div
                      className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                    >
                      <span>
                        <img
                          className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                          src={`${IMAGE_URL}${closed}`}
                          alt="closed"
                        />
                      </span>
                      Unavailable
                      <p className="text-[7px] font-bold">
                        {day.date},{' '}
                        {parseInt((operation?.closesAt).slice(0, 2)) % 12 === 0
                          ? 12
                          : parseInt((operation?.closesAt).slice(0, 2)) % 12}
                        {(operation?.closesAt).slice(2, 5) !== ':00' &&
                          (operation?.closesAt).slice(2, 5)}
                        {parseInt((operation?.closesAt).slice(0, 2)) - 11 < 0
                          ? 'AM'
                          : 'PM'}{' '}
                        - {day.date}, 11:59PM
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          }
          return (
            <div
              key={`${i}_${vehicleId}_${day}`}
              className={`group relative left-0 h-[85px] rounded-none bg-transparent`}
              style={{
                width: `${(window.innerWidth - 520) / dayDifference}px`,
              }}
            />
          );
        })}

      {_.map(
        specificVehicleAvailability?.availability.bookings,
        (booking: IVehicleBookings) => {
          const offsetStart = new Date(
            moment(
              new Date(booking.startsAt).toLocaleString('en-US', {
                timeZone: timeZone,
              }),
            ).format('YYYY/MM/DD HH:mm:ss'),
          );
          const offsetEnd = new Date(
            moment(
              new Date(booking.endsAt).toLocaleString('en-US', {
                timeZone: timeZone,
              }),
            ).format('YYYY/MM/DD HH:mm:ss'),
          );
          const start = moment(offsetStart).diff(startRender, 'minutes') / 60;
          const duration = moment(offsetEnd).diff(offsetStart, 'minutes') / 60;
          if (
            // BOTH
            moment(startRender).isBetween(offsetStart, offsetEnd) &&
            moment(endRender).isBetween(offsetStart, offsetEnd)
          ) {
            const duration =
              moment(endRender).diff(startRender, 'minutes') / 60;
            return (
              <div
                key={booking.bookingId}
                className={classNames(
                  'group relative flex h-[85px] items-center justify-start bg-[#CECECE]',
                  {
                    '!bg-[#FF931E]':
                      isBusinessAccount &&
                      user?.organisation?.id === booking.organisationId,
                    '!bg-[#CECECE]': booking.isMaintenance,
                  },
                )}
                style={{
                  width: `${
                    ((window.innerWidth - 520) / dayDifference) *
                      (duration / 24) +
                    40
                  }px`,
                }}
                onMouseOver={() => {
                  if (selectedDriver !== booking.driverId?.toString()) {
                    setDriverData({
                      id: null,
                      first_name: null,
                      last_name: null,
                      photo: null,
                    });
                    setSelectedDriver(booking.driverId?.toString());
                  }
                }}
              >
                {booking.organisationId === user?.organisation?.id ? (
                  <>
                    {driverData.first_name === null ||
                    driverData.id !== booking.driverId ? (
                      <div
                        className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                      >
                        <AiOutlineLoading3Quarters className="m-auto animate-spin fill-flexi-orange-1" />
                      </div>
                    ) : (
                      <div
                        className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                      >
                        {driverData.photo ? (
                          <img
                            src={driverData.photo}
                            alt={`${driverData.first_name} ${driverData.last_name}`}
                            className="mx-auto h-[36px] w-[36px] rounded-full"
                          />
                        ) : (
                          <div className="mx-auto h-[36px] w-[36px] content-center rounded-full bg-flexi-green-1 pt-[5px] text-[18px] text-white">
                            {driverData.first_name && driverData.first_name[0]}
                          </div>
                        )}
                        {driverData.first_name} {driverData.last_name}
                        <p className="text-[7px] font-bold">
                          {booking.startsAt &&
                            moment(
                              new Date(booking.startsAt).toLocaleString(
                                'en-US',
                                { timeZone: timeZone },
                              ),
                            ).format('MMM DD, h:mm A')}{' '}
                          -{' '}
                          {booking.endsAt &&
                            moment(
                              new Date(booking.endsAt).toLocaleString('en-US', {
                                timeZone: timeZone,
                              }),
                            ).format('MMM DD, h:mm A')}
                        </p>
                      </div>
                    )}
                  </>
                ) : booking.isMaintenance === 1 ? (
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {booking.startsAt &&
                        moment(
                          new Date(booking.startsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}{' '}
                      -{' '}
                      {booking.endsAt &&
                        moment(
                          new Date(booking.endsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}
                    </p>
                  </div>
                ) : (
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {booking.startsAt &&
                        moment(
                          new Date(booking.startsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}{' '}
                      -{' '}
                      {booking.endsAt &&
                        moment(
                          new Date(booking.endsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}
                    </p>
                  </div>
                )}
              </div>
            );
          } else if (
            // START
            moment(startRender).isBetween(offsetStart, offsetEnd)
          ) {
            const duration =
              moment(offsetEnd).diff(startRender, 'minutes') / 60;
            return (
              <div
                key={booking.bookingId}
                className={classNames(
                  'group relative left-[-2px] flex h-[85px] items-center justify-start rounded-r-[10px] bg-[#CECECE]',
                  {
                    '!bg-[#FF931E]':
                      isBusinessAccount &&
                      user?.organisation?.id === booking.organisationId,
                    '!bg-[#CECECE]': booking.isMaintenance,
                    'rounded-r-[5px]': dayDifference > 3,
                  },
                )}
                style={{
                  width: `${
                    ((window.innerWidth - 520) / dayDifference) *
                      (duration / 24) +
                    27
                  }px`,
                }}
                onMouseOver={() => {
                  if (selectedDriver !== booking.driverId?.toString()) {
                    setDriverData({
                      id: null,
                      first_name: null,
                      last_name: null,
                      photo: null,
                    });
                    setSelectedDriver(booking.driverId?.toString());
                  }
                }}
              >
                {booking.organisationId === user?.organisation?.id ? (
                  <>
                    {driverData.first_name === null ||
                    driverData.id !== booking.driverId ? (
                      <div
                        className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                      >
                        <AiOutlineLoading3Quarters className="m-auto animate-spin fill-flexi-orange-1" />
                      </div>
                    ) : (
                      <div
                        className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                      >
                        {driverData.photo ? (
                          <img
                            src={driverData.photo}
                            alt={`${driverData.first_name} ${driverData.last_name}`}
                            className="mx-auto h-[36px] w-[36px] rounded-full"
                          />
                        ) : (
                          <div className="mx-auto h-[36px] w-[36px] content-center rounded-full bg-flexi-green-1 pt-[5px] text-[18px] text-white">
                            {driverData.first_name && driverData.first_name[0]}
                          </div>
                        )}
                        {driverData.first_name} {driverData.last_name}
                        <p className="text-[7px] font-bold">
                          {booking.startsAt &&
                            moment(
                              new Date(booking.startsAt).toLocaleString(
                                'en-US',
                                { timeZone: timeZone },
                              ),
                            ).format('MMM DD, h:mm A')}{' '}
                          -{' '}
                          {booking.endsAt &&
                            moment(
                              new Date(booking.endsAt).toLocaleString('en-US', {
                                timeZone: timeZone,
                              }),
                            ).format('MMM DD, h:mm A')}
                        </p>
                      </div>
                    )}
                  </>
                ) : booking.isMaintenance === 1 ? (
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {booking.startsAt &&
                        moment(
                          new Date(booking.startsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}{' '}
                      -{' '}
                      {booking.endsAt &&
                        moment(
                          new Date(booking.endsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}
                    </p>
                  </div>
                ) : (
                  <div
                    className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                  >
                    <span>
                      <img
                        className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                        src={`${IMAGE_URL}${closed}`}
                        alt="closed"
                      />
                    </span>
                    Unavailable
                    <p className="text-[7px] font-bold">
                      {booking.startsAt &&
                        moment(
                          new Date(booking.startsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}{' '}
                      -{' '}
                      {booking.endsAt &&
                        moment(
                          new Date(booking.endsAt).toLocaleString('en-US', {
                            timeZone: timeZone,
                          }),
                        ).format('MMM DD, h:mm A')}
                    </p>
                  </div>
                )}
              </div>
            );
          } else if (
            // END
            moment(endRender).isBetween(offsetStart, offsetEnd)
          ) {
            const duration =
              moment(endRender).diff(offsetStart, 'minutes') / 60;
            return (
              <div
                key={booking.bookingId}
                className={classNames(
                  'absolute left-0 flex h-[85px] items-center justify-center rounded-l-[10px] bg-[#CECECE]',
                  {
                    '!bg-[#FF931E]':
                      isBusinessAccount &&
                      user?.organisation?.id === booking.organisationId,
                    '!bg-[#CECECE]': booking.isMaintenance,
                    'rounded-l-[5px]': dayDifference > 3,
                  },
                )}
                style={{
                  left: `${
                    1 +
                    (start * (window.innerWidth - 520)) / dayDifference / 24 +
                    17
                  }px`,
                  width: `${
                    ((window.innerWidth - 520) / dayDifference) *
                      (duration / 24) +
                    47
                  }px`,
                }}
              >
                <div
                  className="group relative h-full w-full"
                  onMouseOver={() => {
                    if (selectedDriver !== booking.driverId?.toString()) {
                      setDriverData({
                        id: null,
                        first_name: null,
                        last_name: null,
                        photo: null,
                      });
                      setSelectedDriver(booking.driverId?.toString());
                    }
                  }}
                >
                  {booking.organisationId === user?.organisation?.id ? (
                    <>
                      {driverData.first_name === null ||
                      driverData.id !== booking.driverId ? (
                        <div
                          className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                        >
                          <AiOutlineLoading3Quarters className="m-auto animate-spin fill-flexi-orange-1" />
                        </div>
                      ) : (
                        <div
                          className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                        >
                          {driverData.photo ? (
                            <img
                              src={driverData.photo}
                              alt={`${driverData.first_name} ${driverData.last_name}`}
                              className="mx-auto h-[36px] w-[36px] rounded-full"
                            />
                          ) : (
                            <div className="mx-auto h-[36px] w-[36px] content-center rounded-full bg-flexi-green-1 pt-[5px] text-[18px] text-white">
                              {driverData.first_name &&
                                driverData.first_name[0]}
                            </div>
                          )}
                          {driverData.first_name} {driverData.last_name}
                          <p className="text-[7px] font-bold">
                            {booking.startsAt &&
                              moment(
                                new Date(booking.startsAt).toLocaleString(
                                  'en-US',
                                  { timeZone: timeZone },
                                ),
                              ).format('MMM DD, h:mm A')}{' '}
                            -{' '}
                            {booking.endsAt &&
                              moment(
                                new Date(booking.endsAt).toLocaleString(
                                  'en-US',
                                  { timeZone: timeZone },
                                ),
                              ).format('MMM DD, h:mm A')}
                          </p>
                        </div>
                      )}
                    </>
                  ) : booking.isMaintenance === 1 ? (
                    <div
                      className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                    >
                      <span>
                        <img
                          className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                          src={`${IMAGE_URL}${closed}`}
                          alt="closed"
                        />
                      </span>
                      Unavailable
                      <p className="text-[7px] font-bold">
                        {booking.startsAt &&
                          moment(
                            new Date(booking.startsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}{' '}
                        -{' '}
                        {booking.endsAt &&
                          moment(
                            new Date(booking.endsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                    >
                      <span>
                        <img
                          className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                          src={`${IMAGE_URL}${closed}`}
                          alt="closed"
                        />
                      </span>
                      Unavailable
                      <p className="text-[7px] font-bold">
                        {booking.startsAt &&
                          moment(
                            new Date(booking.startsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}{' '}
                        -{' '}
                        {booking.endsAt &&
                          moment(
                            new Date(booking.endsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            );
          } else if (
            // BETWEEN
            moment(offsetStart).isBetween(startRender, endRender) &&
            moment(offsetEnd).isBetween(startRender, endRender)
          ) {
            return (
              <div
                key={booking.bookingId}
                className={classNames(
                  'group relative flex h-[85px] items-center justify-start rounded-[10px] bg-[#CECECE]',
                  {
                    '!bg-[#FF931E]':
                      isBusinessAccount &&
                      user?.organisation?.id === booking.organisationId,
                    '!bg-[#CECECE]': booking.isMaintenance,
                    'rounded-[5px]': dayDifference > 3,
                  },
                )}
                style={{
                  left: `${
                    1 +
                    (start * (window.innerWidth - 520)) / dayDifference / 24 +
                    17
                  }px`,
                  width: `${
                    ((window.innerWidth - 520) / dayDifference) *
                    (duration / 24)
                  }px`,
                }}
              >
                <div
                  className="group relative h-full w-full"
                  onMouseOver={() => {
                    if (selectedDriver !== booking.driverId?.toString()) {
                      setDriverData({
                        id: null,
                        first_name: null,
                        last_name: null,
                        photo: null,
                      });
                      setSelectedDriver(booking.driverId?.toString());
                    }
                  }}
                >
                  {booking.organisationId === user?.organisation?.id ? (
                    <>
                      {driverData.first_name === null ||
                      driverData.id !== booking.driverId ? (
                        <div
                          className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                        >
                          <AiOutlineLoading3Quarters className="m-auto animate-spin fill-flexi-orange-1" />
                        </div>
                      ) : (
                        <div
                          className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                        >
                          {driverData.photo ? (
                            <img
                              src={driverData.photo}
                              alt={`${driverData.first_name} ${driverData.last_name}`}
                              className="mx-auto h-[36px] w-[36px] rounded-full"
                            />
                          ) : (
                            <div className="mx-auto h-[36px] w-[36px] content-center rounded-full bg-flexi-green-1 pt-[5px] text-[18px] text-white">
                              {driverData.first_name &&
                                driverData.first_name[0]}
                            </div>
                          )}
                          {driverData.first_name} {driverData.last_name}
                          <p className="text-[7px] font-bold">
                            {booking.startsAt &&
                              moment(
                                new Date(booking.startsAt).toLocaleString(
                                  'en-US',
                                  { timeZone: timeZone },
                                ),
                              ).format('MMM DD, h:mm A')}{' '}
                            -{' '}
                            {booking.endsAt &&
                              moment(
                                new Date(booking.endsAt).toLocaleString(
                                  'en-US',
                                  { timeZone: timeZone },
                                ),
                              ).format('MMM DD, h:mm A')}
                          </p>
                        </div>
                      )}
                    </>
                  ) : booking.isMaintenance === 1 ? (
                    <div
                      className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                    >
                      <span>
                        <img
                          className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                          src={`${IMAGE_URL}${closed}`}
                          alt="closed"
                        />
                      </span>
                      Unavailable
                      <p className="text-[7px] font-bold">
                        {booking.startsAt &&
                          moment(
                            new Date(booking.startsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}{' '}
                        -{' '}
                        {booking.endsAt &&
                          moment(
                            new Date(booking.endsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`hover__${specificVehicleAvailability.vehicleId} pointer-events-none absolute bottom-[40px] left-1/2 z-[9999] min-h-[50px] min-w-[165px] -translate-x-1/2 rounded-md bg-white p-[19px] text-center text-[10px] text-[#282828] opacity-0 drop-shadow-lg transition-opacity delay-500 group-hover:opacity-100`}
                    >
                      <span>
                        <img
                          className="mr-[5px] inline-block h-[9px] w-[9px] fill-[#FF7800]"
                          src={`${IMAGE_URL}${closed}`}
                          alt="closed"
                        />
                      </span>
                      Unavailable
                      <p className="text-[7px] font-bold">
                        {booking.startsAt &&
                          moment(
                            new Date(booking.startsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}{' '}
                        -{' '}
                        {booking.endsAt &&
                          moment(
                            new Date(booking.endsAt).toLocaleString('en-US', {
                              timeZone: timeZone,
                            }),
                          ).format('MMM DD, h:mm A')}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            );
          } else {
            return null;
          }
        },
      )}
    </div>
  ) : (
    <></>
  );
};

export default PerVehicleTimeline;
