import React from 'react';
import PageLayout from 'components/layouts/PageLayout';
// import { AuthenticatedTopNav, SignupTopNav } from 'components/TopNav';
import { Route, Switch, useLocation } from 'react-router-dom';
import SignUpLayout from 'components/layouts/SignUpLayout';
import { Helmet } from 'react-helmet';
// import SignUpSplash from 'features/SignUpSplash';
import SignUpForm from 'features/SignUpForm';
import SignUpOTP from 'features/SignupOTP';
import SignUpIDVerification from 'features/SignUpIDVerification';
import SignUpVerificationFailed from 'features/SignUpVerificationFailed';
import SignUpPlan from 'features/SignUpPlan';
import SignUpPlanStudent from 'features/SignUpPlanStudent';
import SignUpPlanPersonal from 'features/SignUpPlanPersonal';
import SignUpPayment from 'features/SignUpPayment';
import SignUpDone from 'features/SignUpDone';
import SignUpOCR from 'features/SignUpOCR';
import { useEffectOnce } from 'react-use';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import SignUpOTPVerified from 'features/SignUpOTPVerified';
import SignUpSplash from 'features/SignUpSplash';
import SignUpAddressVerification from 'features/SignUpAddressVerification';
 
 const SignUp = () => {
   const dispatch = useAppDispatch();
   const { pathname } = useLocation();

   useEffectOnce(() => {
     if (
       !(
         pathname === '/register-v2/' ||
         pathname === '/register-v2/id-verification' ||
         pathname === '/register-v2/form'
       )
     ) {
       dispatch({ type: ACTIONS.FETCH_ME });
     }
   });
 
   return (
     <PageLayout>
       <Helmet>
         <title>Register</title>
         <meta name="description" content="My Flexicar Registration" />
       </Helmet>
       <article className="mt-[70px] md:mt-0">
         <Switch>
           <Route exact path="/register-v2">
              <SignUpLayout>
                <SignUpSplash />
              </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/id-verification">
             <SignUpLayout>
               <SignUpIDVerification />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/form">
             <SignUpLayout>
               <SignUpForm />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/otp">
             <SignUpLayout>
               <SignUpOTP />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/otp-verified">
             <SignUpLayout>
              <SignUpOTPVerified />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/ocr">
             <SignUpLayout>
               <SignUpOCR />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/verify-failed">
             <SignUpLayout>
               <SignUpVerificationFailed />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/address-verification">
             <SignUpLayout>
               <SignUpAddressVerification />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/plan">
             <SignUpLayout>
               <SignUpPlan />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/plan-student">
             <SignUpLayout>
               <SignUpPlanStudent />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/plan-personal">
             <SignUpLayout>
               <SignUpPlanPersonal />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/payment">
             <SignUpLayout>
               <SignUpPayment />
             </SignUpLayout>
           </Route>
           <Route exact path="/register-v2/done">
             <SignUpLayout>
               <SignUpDone />
             </SignUpLayout>
           </Route>
         </Switch>
       </article>
     </PageLayout>
   );
 };
 
 export default SignUp;