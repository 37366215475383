import classNames from 'classnames';
import Modal from 'components/Modal';
import PendingLoader from 'components/PendingLoader';
import { InvalidText } from 'components/Typography';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectCancelMembershipStatus,
  selectPendingCancelMembership,
  selectUser,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { useState } from 'react';
import { CANCELLATION_REASONS } from './constants';
// import { ReactComponent as IconError } from 'assets/images/error.svg';
import { ReactComponent as IconCancelMembership } from 'assets/images/cancel_membership.svg';
import { RequestStatusEnum } from 'common/types';
import { useUpdateEffect } from 'react-use';
// import useLogout from 'hooks/useLogout';
import { useMediaQuery } from 'react-responsive';
import useLogout from 'hooks/useLogout';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

const Cancellation = () => {
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [isReasonOther, setReasonOther] = useState(false);
  const isPending = useAppSelector(selectPendingCancelMembership);
  const cancelMembershipStatus = useAppSelector(selectCancelMembershipStatus);
  const { logoutApiless } = useLogout();
  const user = useAppSelector(selectUser);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleCancelMembership = () => {
    if (reason === '') {
      setError(true);
    } else {
      dispatch({ type: ACTIONS.CANCEL_MEMBERSHIP, payload: { reason } });
      setError(false);
    }
  };

  const handleReasonChange = (value: string) => {
    if (value === 'Other (please specify below)') {
      setReasonOther(true);
      setError(false);
      setReason('');
    } else {
      setReasonOther(false);
      setReason(value);
      setError(false);
    }
  };

  useUpdateEffect(() => {
    if (cancelMembershipStatus === RequestStatusEnum.SUCCESS) {
      setTimeout(() => {
        logoutApiless();
      }, 5000);
    }
  }, [cancelMembershipStatus]);

  const renderModalContent = () => {
    if (isPending) {
      return (
        <>
          <PendingLoader />
          <h4 className="mt-[30px] text-center text-lg">
            Processing your membership cancellation...
          </h4>
        </>
      );
    }
    if (cancelMembershipStatus === RequestStatusEnum.FAILED) {
      return (
        <>
          <LottieAnimation
            lottieFile={errorLottie}
            isStopped={!isModalOpen}
          />
          <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
            You can&apos;t cancel your account yet.
          </h1>
          <p className="text-center text-[16px] md:text-[16px]">
            Make sure all bookings have been cancelled, ended, and paid before
            cancelling your {user?.type.name}&nbsp;account.
          </p>
          <button
            className={`button btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
            onClick={() => setModalOpen(false)}
          >
            Ok
          </button>
        </>
      );
    }

    if (cancelMembershipStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <LottieAnimation lottieFile={errorLottie} isStopped={!isModalOpen} />
          <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
            Your cancellation request has been submitted. We will be in touch
            shortly.
          </h1>
          <button
            className={`button btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
            onClick={() => setModalOpen(false)}
          >
            Ok
          </button>
        </>
      );
    }
    return (
      <>
        <IconCancelMembership className="icon" />
        <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
          Are you sure you want to cancel your membership?
        </h1>
        <p className="mb-[30px] text-center text-[16px] md:text-[18px]">
          By clicking the confirm button below, your membership will be
          cancelled. You will be automatically logged out, you won&apos;t be
          able to login again, and you will no longer be able to use the
          service. If it&apos;s time to say goodbye, we understand. However, if
          you need to use Flexicar again simply re-join through either the
          Flexicar web or mobile&nbsp;app.
        </p>
        <div className="mb-[30px] w-full flex-col">
          <select
            className={classNames('select select-bordered w-full', {
              'border-red-500': isError && !isReasonOther,
            })}
            onChange={(e) => {
              handleReasonChange(e.target.value);
            }}
            defaultValue={0}
          >
            <option disabled value={0}>
              Your reason for cancelling
            </option>
            {CANCELLATION_REASONS?.map((i) => {
              return (
                <option key={i.id} value={i.message}>
                  {i.message}
                </option>
              );
            })}
          </select>
          {isReasonOther && (
            <textarea
              className={classNames(
                'textarea textarea-bordered mt-[10px] w-full resize-none leading-[1.429]',
                {
                  'border-red-500': isError,
                },
              )}
              placeholder="Enter your cancellation reason..."
              onChange={(e) => setReason(e.target.value)}
            />
          )}
          {isError ? <InvalidText>This field is required.</InvalidText> : null}
        </div>

        <div className="button flex w-full justify-center">
          <button
            className={`btn h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2 md:!h-[60px]`}
            onClick={handleCancelMembership}
          >
            Confirm
          </button>
        </div>
      </>
    );
  };

  return (
    <article className="mb-[100px] mt-[40px] flex flex-col md:mt-[50px]">
      <div className="flex text-[12px]">
        <p className="mx-auto md:mx-0">
          Don&apos;t need Flexicar right now?{' '}
          <span
            className="cursor-pointer font-bold capitalize underline"
            onClick={() => setModalOpen(true)}
          >
            cancel membership
          </span>
        </p>
      </div>

      <Modal
        isModalOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        {renderModalContent()}
      </Modal>
    </article>
  );
};

export default Cancellation;
