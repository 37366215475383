import React, { useEffect, useState } from 'react';
import { InvalidText } from 'components/Typography';
import {
  // Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import {
  selectEmailVerified,
  selectError,
  selectLicenseVerified,
  selectPendingVerifyEmail,
  selectPendingVerifyLicense,
  selectRegisterStatus,
  selectTokenInfo,
  setEmailVerified,
  setError,
  setLicenseVerified,
} from 'modules/auth/slice';
import useAppSelector from 'hooks/useAppSelector';
import { Link } from 'react-router-dom';
// import PhoneInput from 'react-phone-input-2';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import COUNTRIES from 'common/countries';
import { isEmpty } from 'lodash';
import { FcCheckmark } from 'react-icons/fc';
import CustomInput from 'components/CustomInput';
import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';
import CustomCheckBox from 'components/CustomCheckbox';
// import { countryCodes, ICountryCode } from './constant';
import Input from 'react-phone-number-input/input';
import {
  isSupportedCountry,
  getCountryCallingCode,
  isValidPhoneNumber
} from 'react-phone-number-input';
import { ReactComponent as IconPhone } from 'assets/images/faq_call.svg';
import { ICountryCode, RequestStatusEnum } from 'common/types';
import {
  browserName,
  deviceType,
  osName,
  osVersion,
  isBrowser,
  isMobile,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  accountType: number;
  token?: string;
}

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  licenseNumber: string;
  licenseCardNumber: string;
  contact: string;
  country: string;
  isMailSubscriber: number;
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  password: Yup.string()
    .required('')
    .matches(/\d/, '')
    .matches(/[a-z]/, '')
    .matches(/[A-Z]/, '')
    .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
    .min(8),
  passwordConfirmation: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  licenseNumber: Yup.string()
    .required('License number is required')
    .matches(/^\w+/, 'Must contain number and letters only'),
  contact: Yup.string().required('Mobile number is required'),
  country: Yup.string().required('Country is required'),
});

const Form = ({ accountType, token }: Props) => {
  const dispatch = useAppDispatch();
  const registerStatus = useAppSelector(selectRegisterStatus);
  const isPendingVerifyLicense = useAppSelector(selectPendingVerifyLicense);
  const isPendingVerifyEmail = useAppSelector(selectPendingVerifyEmail);
  const isLicenseVerified = useAppSelector(selectLicenseVerified);
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const error = useAppSelector(selectError);
  const tokenInfo = useAppSelector(selectTokenInfo);
  const [passLVisible, setPassLVisible] = useState(false);
  const [passRVisible, setPassRVisible] = useState(false);
  const [password, setPassword] = useState('');
  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
    defaultValues: { country: 'au' },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    // control,
    trigger,
    setValue,
    getValues,
    clearErrors,
    reset,
    
  } = useForm<FormValues>(formOptions);

  // useEffect(()=>{
  //   console.log('auth errors:',error)
  // },[error])

  const isErrorEmail =
    errors?.email || error?.email || error === 'email_invalid';
  const isErrorLicense = errors?.licenseNumber || error?.licenseNumber;
  const isErrorLicenseCardNumber =
    errors?.licenseCardNumber || error?.licenseCardNumber;

  const [countryCode, setCountryCode] = useState('au');

  const [phone, setPhone] = useState<any>('');
  const [phoneCode, setPhoneCode] = useState<any>('AU');
  // const [phoneError, setPhoneError] = useState<String | null>(null);

  const [isInternational, setIsInternational] = useState(false);

  const handleMobileSelectChange = (e: any) => {
    if (e.target.value !== 'international') {
      setPhoneCode(e.target.value);
      setPhone('');
      setIsInternational(false);
    } else {
      setIsInternational(true);
    }
  };
  useEffect(() => {
    reset();
    clearErrors();
    setPhone('');
    dispatch(setLicenseVerified(false));
    dispatch(setEmailVerified(false));
    dispatch(setError(null));
  }, [accountType]);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (registerStatus !== RequestStatusEnum.PENDING) {
      const deviceId = uuidv4();
      let deviceName = '';

      if (isBrowser) {
        deviceName = osName + ' ' + osVersion + ', ' + browserName;
      } else if (isMobile) {
        deviceName = mobileVendor + ' ' + mobileModel;
      } else {
        deviceName = 'Unknown Device';
      }
      
      const payload = {
        ...values,
        type: accountType,
        region: values.country,
        isMailSubscriber: values.isMailSubscriber ? 1 : 0,
        service: 'flexicar',
        licenseNumber: values.licenseNumber.replace(/[^a-zA-Z0-9]/g, ''),
        licenseCardNumber: values.licenseCardNumber.replace(/[^a-zA-Z0-9]/g, ''),
        contactCountry: phoneCode,
        token,
        device_name: deviceName,
        device_id: deviceId,
        device_type: deviceType
      };
      dispatch({ type: ACTIONS.REGISTER_ACCOUNT, payload });
    }
  };

  useEffect(() => {
    setValue('contact', phone);
  }, [phone]);

  useEffect(() => {
    if (tokenInfo) {
      setValue('email', tokenInfo.email);
    }
  }, [tokenInfo]);

  useEffect(() => {
    if (accountType === 5) {
      dispatch({
        type: ACTIONS.CHECK_TOKEN_INFO,
        payload: {
          token,
          type: 'business-invite',
        },
      });
    }
  }, [accountType]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="relative flex place-items-center gap-[20px]">
        <div className="relative flex w-full flex-1 items-center">
          <CustomInput
            title="License Number"
            error={isErrorLicense || error?.license}
            positionTitle="translate-x-[50px]"
          >
            <input
              type="text"
              placeholder="license"
              className={classNames(
                'peer input input-bordered w-full indent-[50px]',
                {
                  'border-red-500': isErrorLicense || error?.license,
                },
              )}
              {...register('licenseNumber', {
                onBlur: async (e) => {
                  const result = await trigger('licenseNumber');
                  if (result) {
                    const parsedLicenseNumber = e.target.value.replace(
                      /[^a-zA-Z0-9]/g,
                      '',
                    );
                    dispatch({
                      type: ACTIONS.VERIFY_LICENSE,
                      payload: { licenseNumber: parsedLicenseNumber },
                    });
                  }
                },
              })}
              autoComplete="new-password"
            />
          </CustomInput>
          {isPendingVerifyLicense && (
            <span className="absolute right-[15px] h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
          )}
          {isLicenseVerified && (
            <FcCheckmark className="absolute right-[15px]" />
          )}
        </div>

        <div className="absolute left-[4px] top-0 z-[10] mb-3 h-[50px] w-[60px] flex-1 items-center justify-center md:top-[5px] ">
          <div className="pointer-events-none absolute left-[-10px] top-[0] z-10 ml-[10px] flex h-full w-full items-center justify-center gap-[5px] bg-white  bg-transparent [&>img]:!h-[25px] [&>img]:!w-[25px]">
            <ReactCountryFlag
              className=" w-full  rounded-full object-cover"
              countryCode={countryCode}
              svg
            />
            <IconArrow />
          </div>
          <select
            tabIndex={-1}
            className={classNames(
              'select select-bordered absolute !top-0 left-[-2px] !w-[100%] !select-none appearance-none border-transparent !bg-transparent bg-none focus:outline-none',
              {
                'border-red-500': errors?.country,
              },
            )}
            {...register('country')}
            onChange={(e) => setCountryCode(e.target.value)}
          >
            {COUNTRIES.map((c) => {
              return (
                <option value={c.code.toLowerCase()} key={c.code}>
                  {c.name}
                </option>
              );
            })}
          </select>
          {!isEmpty(errors.country) && (
            <InvalidText>{errors.country?.message}</InvalidText>
          )}
        </div>
      </div>
      {!isEmpty(errors.licenseNumber) && (
        <InvalidText>{errors.licenseNumber?.message}</InvalidText>
      )}
      {error?.licenseNumber && (
        <InvalidText>{error?.licenseNumber[0]}</InvalidText>
      )}
      {error?.license && (
        <InvalidText>{error?.license}</InvalidText>
      )}
      <div className="relative flex place-items-center gap-[20px]">
        <div className="relative flex w-full flex-1 items-center">
          <CustomInput
            margin={20}
            title="License Card Number"
            error={isErrorLicenseCardNumber}
          >
            <input
              type="text"
              placeholder="license card number"
              className={classNames('peer input input-bordered w-full', {
                'border-red-500': isErrorLicenseCardNumber,
              })}
              {...register('licenseCardNumber', {
                onBlur: async () => {
                  const result = await trigger('licenseCardNumber');
                  if (result) {
                    // const parsedLicenseNumber = e.target.value.replace(
                    //   /[^a-zA-Z0-9]/g,
                    //   '',
                    // );
                    // dispatch({
                    //   type: ACTIONS.VERIFY_LICENSE,
                    //   payload: { licenseNumber: parsedLicenseNumber },
                    // });
                  }
                },
              })}
            />
          </CustomInput>
          {/* {isPendingVerifyLicense && (
            <span className="absolute right-[15px] h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
          )}
          {isLicenseVerified && (
            <FcCheckmark className="absolute right-[15px]" />
          )} */}
        </div>
      </div>


      {accountType === 5 ? (
        <CustomInput margin={20} title="Email">
          <p className="input input-bordered flex h-[48px] w-full items-center !self-end">
            {getValues('email')}
          </p>
        </CustomInput>
      ) : (
        <div className="relative flex w-full flex-1 flex-col items-center">
          <CustomInput
            title={accountType === 3 ? 'Student Email' : 'Email'}
            error={isErrorEmail}
            margin={20}
          >
            <input
              id={accountType === 3 ? 'Student Email' : 'Email'}
              type="text"
              placeholder="aaa"
              className={classNames('peer input input-bordered w-full  ', {
                'border-red-500': isErrorEmail,
              })}
              {...register('email', {
                onBlur: async (e) => {
                  const result = await trigger('email');
                  if (result) {
                    dispatch({
                      type: ACTIONS.VERIFY_EMAIL,
                      payload: { email: e.target.value, type: accountType },
                    });
                  }
                },
              })}
              disabled={accountType === 5}
            />
            <div className="absolute top-[50%] right-[15px] grid  translate-y-[-50%] place-items-center">
              {isPendingVerifyEmail && (
                <span className="h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
              )}
              {isEmailVerified && <FcCheckmark />}
            </div>
          </CustomInput>
          {/* </div> */}
          {!isEmpty(errors.email) && (
            <InvalidText>{errors.email?.message}</InvalidText>
          )}
          {/* {error?.email[0] && <InvalidText>{error?.email}</InvalidText>} */}
          {error === 'email_invalid' && (
            <InvalidText>Invalid school email</InvalidText>
          )}
          
          {error?.email && (
            <InvalidText>{error?.email}</InvalidText>
          )}
        </div>
      )}

      <CustomInput
        title="Mobile"
        error={errors?.contact}
        positionTitle="translate-x-[50px]"
        margin={20}
      >
        <div
          className={classNames(
            'pointer-events-none absolute left-0 bottom-0  flex h-full w-[65px] items-center justify-center gap-[5px] rounded-l-lg border-l-[1px] border-b-[1px] border-t-[1px] border-flexi-black-7 bg-white',
            {
              '!border-red-500': errors?.contact,
            },
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {isInternational && (
            <IconPhone className="before absolute left-[12px] h-[25px] w-[25px] bg-white fill-flexi-black-3" />
          )}
          <ReactCountryFlag
            className=" min-h-[25px] min-w-[25px]  rounded-full object-cover"
            countryCode={phoneCode}
            svg
          />
          <IconArrow />
        </div>
        <select
          onChange={handleMobileSelectChange}
          onClick={(e) => e.stopPropagation()}
          defaultValue="AU"
          className="h-full w-[65px] cursor-pointer bg-transparent"
        >
          <option value="international">International</option>
          {COUNTRIES.map((country: ICountryCode, i: number) => {
            return (
              isSupportedCountry(country.code) && (
                <option
                  key={i}
                  value={country.code}
                  className="!mb-[5px] !pb-[5px] text-[14px]"
                >
                  {country.name} &#91;+{getCountryCallingCode(country.code)}
                  &#93;
                </option>
              )
            );
          })}
        </select>
        <Input
          country={phoneCode}
          international
          withCountryCallingCode={!isInternational}
          value={phone}
          onChange={setPhone}
          placeholder="mobile"
          className={classNames(
            'peer h-[40px] w-full rounded-br-lg border-r-[1px] border-b-[1px] border-flexi-black-7',
            {
              '!border-red-500': errors?.contact,
            },
          )}
          maxLength={isValidPhoneNumber(phone || `+61`, phoneCode) ? phone.length : 17}
        />
      </CustomInput>

      {!isEmpty(errors?.contact) && (
        <InvalidText>Mobile number is required</InvalidText>
      )}
      {/* {!isEmpty(phoneError) && (
        <InvalidText>{phoneError}</InvalidText>
      )} */}
      <div className="flex flex-col ">
        <p className='font-bold text-[14px] pl-[5px] pt-[20px] mb-[-15px]'>Input your name as it appears on your license.</p>
        <CustomInput title="First Name" error={errors.firstname} margin={20}>
          <input
            type="text"
            placeholder="first name"
            className={classNames('peer input input-bordered w-full', {
              'border-red-500': errors?.firstname,
            })}
            {...register('firstname', {})}
          />
        </CustomInput>
        {!isEmpty(errors.firstname) && (
          <InvalidText>{errors.firstname?.message}</InvalidText>
        )}
        <CustomInput title="Last Name" error={errors.lastname} margin={20}>
          <input
            type="text"
            placeholder="last name"
            className={classNames('peer input input-bordered w-full', {
              'border-red-500': errors?.lastname,
            })}
            {...register('lastname', {})}
          />
        </CustomInput>
        {!isEmpty(errors.lastname) && (
          <InvalidText>{errors.lastname?.message}</InvalidText>
        )}
      </div>

      <div className="flex flex-col">
        <CustomInput title="Password" margin={20} error={errors.password}>
          <input
            type={passLVisible ? 'text' : 'password'}
            placeholder="password"
            className={classNames(
              'peer input input-bordered w-full pr-[50px]',
              {
                'border-red-500': errors?.password,
              },
            )}
            {...register('password', {})}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
            // onBlur={(e) => !e.target.value && setIsInputPasswordActive(false)}
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              setPassLVisible(!passLVisible);
            }}
            className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
          >
            {passLVisible ? (
              <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
            ) : (
              <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.password) && (
          <div className=" my-2 text-[12px] text-red-500">
            {password ? (
              <ul className="relative ml-5 list-disc">
                <li
                  className={classNames('', {
                    hidden: password.length >= 8,
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must be at least 8 characters
                  </span>
                </li>
                <li
                  className={classNames('', {
                    hidden: password.match(/\d/),
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must contain at least 1 number
                  </span>
                </li>
                <li
                  className={classNames('', {
                    hidden:
                      password.match(/[a-z]/) &&
                      password.match(/[a-z]/) &&
                      password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/),
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must contain lowercase, uppercase, and a special character
                  </span>
                </li>
              </ul>
            ) : (
              <p>This field is required</p>
            )}
          </div>
        )}
      </div>
      <div className="form-control mb-3 w-full flex-1">
        <CustomInput
          title="Confirm Password"
          margin={20}
          error={errors.passwordConfirmation}
        >
          <input
            type={passRVisible ? 'text' : 'password'}
            placeholder="confirm password"
            className={classNames(
              'peer input input-bordered w-full pr-[50px]',
              {
                'border-red-500': errors?.passwordConfirmation,
              },
            )}
            {...register('passwordConfirmation', {})}
            // onBlur={(e) =>
            //   !e.target.value && setIsInputPasswordConfirmActive(false)
            // }
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              setPassRVisible(!passRVisible);
            }}
            className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg  bg-transparent"
          >
            {passRVisible ? (
              <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
            ) : (
              <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
            )}
            {/* <HidePassword
              fill={passRVisible ? '#FF7A00' : `#E4E4E7`}
              className="translate-y-[-7px] scale-[1.7]"
            /> */}
          </span>
        </CustomInput>
        {!isEmpty(errors.passwordConfirmation) && (
          <InvalidText>{errors.passwordConfirmation?.message}</InvalidText>
        )}
      </div>
      {accountType === 2 && (
        <div className="form-control mb-3 w-full flex-1">
          <label className="label">
            <span className="label-text">
              {accountType === 2 ? 'Country' : 'License Country of Issue'}
            </span>
          </label>
          <select
            className={classNames('select select-bordered w-full', {
              'border-red-500': errors?.country,
            })}
            {...register('country')}
          >
            {COUNTRIES.map((c) => (
              <option value={c.code.toLowerCase()} key={c.code}>
                {c.name}
              </option>
            ))}
          </select>
          {!isEmpty(errors.country) && (
            <InvalidText>{errors.country?.message}</InvalidText>
          )}
        </div>
      )}

      <div className="mt-[25px] flex flex-col gap-[15px] sm:mt-[50px] sm:gap-[25px]">
        <CustomCheckBox
          size={10}
          titleClass="text-[12px] sm:text-[16px]"
          title="Send emails regarding updates, promotions and&nbsp;discounts."
        >
          <input
            type="checkbox"
            className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
            {...register('isMailSubscriber')}
            // @ts-ignore
            style={{ '--chkfg': '0', '--chkbg': '0' }}
            defaultChecked
          />
        </CustomCheckBox>
        <p className=" text-[11px] sm:text-[14px]">
          By clicking &apos;Create my account&apos;, you confirm that you have
          read and accepted Flexicar&apos;s{' '}
          <Link
            to="/terms-and-conditions"
            target="_blank"
            className="font-bold text-primary"
          >
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link
            to="/privacy-policy-page"
            target="_blank"
            className="font-bold text-primary"
          >
            Privacy Policy
          </Link>
          .
        </p>
      </div>
      {/* <Link className="font-bold text-flexi-orange-1" to="/terms-and-conditions" target="_blank">Terms and Conditions</Link>. */}

      <button
        type="submit"
        className={classNames(
          'btn btn-primary mt-[25px] h-[60px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px]',
          {
            'loading bg-flexi-green-2': registerStatus === RequestStatusEnum.PENDING,
          },
        )}
        disabled={isPendingVerifyLicense || isPendingVerifyEmail}
      >
        Create my account
      </button>
    </form>
  );
};

export default Form;
