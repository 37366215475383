import React, { Dispatch, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { InvalidText } from 'components/Typography';
// import useAppDispatch from 'hooks/useAppDispatch';
// import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import { useDropzone } from 'react-dropzone';
import arrowIcon from 'assets/images/arrow-down.svg';
import { API_URL, IMAGE_URL } from 'common/constants';
import { BiUpload } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import classNames from 'classnames';
import axios from 'axios';
import { getToken } from 'common/helpers';
import { useHistory } from 'react-router-dom';

type FormValues = {
  experienceYears: number;
  hasSuspended: number;
  hasSeniorCard: number;
  hasConvicted: number;
  hasClaimsInsurance: number;
};

const validationSchema = Yup.object().shape({
  experienceYears: Yup.number()
    .required('This field is required.')
    .typeError('This field is required.'),
  hasSuspended: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
  hasSeniorCard: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
  hasConvicted: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
  hasClaimsInsurance: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
});

const DrivingExperience = () => {
  // const dispatch = useAppDispatch();
  const [hasConviction, setHasConviction] = useState('');
  const [hasSeniorCard, setHasSeniorCard] = useState('');
  const [hasSuspension, setHasSuspension] = useState('');
  const [hasClaimInsurance, setHasClaimInsurance] = useState('');
  // const [convictionFiles, setConvictionFiles] = useState(null)
  const [imageSuspensionPreview, setImageSuspensionPreview] = useState<File[]>(
    [],
  );
  const [imageSeniorCardPreview, setImageSeniorCardPreview] = useState<File[]>(
    [],
  );
  const [imageConvictionPreview, setImageConvictionPreview] = useState<File[]>(
    [],
  );
  const [imageClaimsPreview, setImageClaimsPreview] = useState<File[]>([]);

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);
  const user = useAppSelector(selectUser);
  // const isPendingUpdate = useAppSelector(selectPendingUpdate);
  const baseUrl = API_URL;
  const {push} = useHistory()

  const [drivingYears, setDrivingYears] = useState('1');
  const [suspensionExplanation, setSuspensionExplanation] = useState('');
  const [convictionExplanation, setConvictionExplanation] = useState('');
  const [claimInsuranceExplanation, setClaimsInsuranceExplanation] =
    useState('');
  const [pendingSubmit, setPendingSubmit] = useState(false)

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    setPendingSubmit(true)
    const formData: any = new FormData();
    formData.append(
      'member_type',
      user?.type?.id ? user.type.id.toString() : '',
    );

    formData.append('experience_years', values.experienceYears);
    formData.append('has_convicted', imageConvictionPreview[0] ? 1 : 0);
    formData.append('has_senior_card', imageSeniorCardPreview[0] ? 1 : 0);
    formData.append('has_suspended', imageSuspensionPreview[0] ? 1 : 0);
    formData.append('has_claims_insurance', imageClaimsPreview[0] ? 1 : 0);

    formData.append('convicted_explanation', convictionExplanation);
    formData.append('suspended_explanation', suspensionExplanation);
    formData.append('claims_insurance_explanation', claimInsuranceExplanation);

    formData.append('has_convicted_file', imageConvictionPreview[0] ? 1 : 0);
    formData.append('has_senior_card_file', imageSeniorCardPreview[0] ? 1 : 0);
    formData.append('has_suspended_file', imageSuspensionPreview[0] ? 1 : 0);
    formData.append('has_claims_insurance_file', imageClaimsPreview[0] ? 1 : 0);

    imageSuspensionPreview.forEach((file) => {
      formData.append('suspended_docs[]', file);
    });
    imageSeniorCardPreview.forEach((file) => {
      formData.append('senior_card_docs[]', file);
    });
    imageConvictionPreview.forEach((file) => {
      formData.append('conviction_docs[]', file);
    });
    imageClaimsPreview.forEach((file) => {
      formData.append('claims_insurance_docs[]', file);
    });
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios
      .post(`${baseUrl}/register/information`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => push('/register/plan'))
      .finally(()=>setPendingSubmit(false))
  };
  const suspendDropZone = useDropzone({
    onDrop: (acceptedFiles) => {
      setImageSuspensionPreview(acceptedFiles);
    },
  });

  const seniorCardDropZone = useDropzone({
    onDrop: (acceptedFiles) => {
      setImageSeniorCardPreview(acceptedFiles);
      console.log('dropping')
    },
  });

  const convictDropZone = useDropzone({
    onDrop: (acceptedFiles) => {
      setImageConvictionPreview(acceptedFiles);
    },
  });
  const claimsDropZone = useDropzone({
    onDrop: (acceptedFiles) => {
      setImageClaimsPreview(acceptedFiles);
    },
  });

  const handleRemove = (setState: Dispatch<any>, state: any, index: number) => {
    setState(state.filter((_: any, i: number) => i !== index));
  };

  return (
    <article>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="mb-[30px] text-center text-[20px] font-bold md:text-left md:text-2xl">
          Tell us about your driving experience
        </h3>

        <div className="flex flex-col gap-y-[30px] text-[16px] font-bold md:text-[16px]">
          <div className="flex items-center justify-between">
            <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
              How many years of independent driving experience do I have?
            </p>
            <div className="flex flex-col items-end">
              <input
                type="number"
                min={1}
                className={classNames(
                  'input input-bordered h-[60px] w-[90px] flex-none text-center text-[16px] font-bold',
                  {
                    'border-red-500': errors?.experienceYears,
                  },
                )}
                {...register('experienceYears')}
                value={drivingYears}
                onChange={(e) => setDrivingYears(e.target.value)}
                style={{
                  appearance: 'textfield',
                  MozAppearance: 'textfield',
                  WebkitAppearance: 'textfield',
                }}
              />
              {errors.experienceYears && (
                <InvalidText>{errors.experienceYears?.message}</InvalidText>
              )}
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
                Have I had any license suspensions in the last 3 years?
              </p>

              <div className="dropdown-hover dropdown relative flex flex-col items-end">
                <label
                  tabIndex={0}
                  className="btn relative mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-2 transition-none hover:border-zinc-300  hover:bg-white active:!scale-[1]"
                >
                  <p
                    className={classNames('w-[50%] font-normal capitalize', {
                      'text-transparent': hasSuspension === '',
                    })}
                  >
                    {hasSuspension === 'true' ? 'Yes' : 'No'}
                  </p>
                  <span
                    style={{
                      maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      maskRepeat: 'no-repeat',
                      WebkitMaskRepeat: 'no-repeat',
                      WebkitMaskPosition: 'center',
                    }}
                    className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                  />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu absolute right-0 w-[90px] translate-y-[50%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-2"
                >
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={0}
                      onClick={() => setHasSuspension('false')}
                      {...register('hasSuspended')}
                    />
                    <span>No</span>
                  </label>
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={1}
                      onClick={() => setHasSuspension('true')}
                      {...register('hasSuspended')}
                    />
                    <span>Yes</span>
                  </label>
                </ul>

                {errors.hasSuspended && (
                  <InvalidText>{errors.hasSuspended?.message}</InvalidText>
                )}
              </div>
            </div>
            {hasSuspension === 'true' && (
              <div className="mt-[20px] flex h-full w-full flex-col overflow-clip  overflow-x-visible transition-all duration-500">
                <p></p>
                <textarea
                  name=""
                  id=""
                  rows={5}
                  className="textarea textarea-bordered mb-5 h-[120px] leading-[1.429] focus:outline-none"
                  placeholder="Briefly explain the incident &amp; upload any supporting document."
                  onChange={(e) => setSuspensionExplanation(e.target.value)}
                ></textarea>
                {imageSuspensionPreview?.map((image: any, i: number) => {
                  const imageSize = image.size / 1000;
                  return (
                    <div
                      key={i}
                      className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_2px_9px] shadow-flexi-black-6"
                    >
                      <div className="flex items-center gap-3">
                        <img
                          className="ml-3 w-[35px]"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                        <p className="text-sm ">{image.name}</p>
                        <p className="text-xs text-flexi-black-4">
                          {imageSize} kb
                        </p>
                      </div>
                      <IoMdClose
                        className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                        onClick={() =>
                          handleRemove(
                            setImageSuspensionPreview,
                            imageSuspensionPreview,
                            i,
                          )
                        }
                      />
                    </div>
                  );
                })}
                <div
                  {...suspendDropZone.getRootProps()}
                  // onMouseOver={() => setDropSelector('suspension')}
                  className="relative mb-2 grid h-auto min-h-[130px] cursor-pointer overflow-hidden rounded-lg p-1 shadow-[0_2px_9px] shadow-flexi-black-6"
                >
                  <input
                    id="suspensionFile"
                    {...suspendDropZone.getInputProps()}
                  />

                  {suspendDropZone.isDragActive ? (
                    <p className="flex h-full items-center justify-center gap-1">
                      drop here...
                    </p>
                  ) : (
                    <div className="h-ful flex flex-col items-center justify-center text-sm">
                      <BiUpload className="text-3xl" />
                      <p className="m-0 pt-[10px] pb-[5px] font-normal">
                        Upload File
                      </p>
                      <p className="mx-[20px] text-center text-[12px]">
                        FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
                Are you a senior card holder?
              </p>

              <div className="dropdown-hover dropdown relative flex flex-col items-end">
                <label
                  tabIndex={0}
                  className="btn relative mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-2 transition-none hover:border-zinc-300  hover:bg-white active:!scale-[1]"
                >
                  <p
                    className={classNames('w-[50%] font-normal capitalize', {
                      'text-transparent': hasSeniorCard === '',
                    })}
                  >
                    {hasSeniorCard === 'true' ? 'Yes' : 'No'}
                  </p>
                  <span
                    style={{
                      maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      maskRepeat: 'no-repeat',
                      WebkitMaskRepeat: 'no-repeat',
                      WebkitMaskPosition: 'center',
                    }}
                    className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                  />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu absolute right-0 w-[90px] translate-y-[50%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-2"
                >
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={0}
                      onClick={() => setHasSeniorCard('false')}
                      {...register('hasSeniorCard')}
                    />
                    <span>No</span>
                  </label>
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={1}
                      onClick={() => setHasSeniorCard('true')}
                      {...register('hasSeniorCard')}
                    />
                    <span>Yes</span>
                  </label>
                </ul>

                {errors.hasSeniorCard && (
                  <InvalidText>{errors.hasSeniorCard?.message}</InvalidText>
                )}
              </div>
            </div>
            {hasSeniorCard === 'true' && (
              <div className="mt-[20px] flex h-full w-full flex-col overflow-clip  overflow-x-visible transition-all duration-500">
                <p></p>
                {imageSeniorCardPreview?.map((image: any, i: number) => {
                  const imageSize = image.size / 1000;
                  return (
                    <div
                      key={i}
                      className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_2px_9px] shadow-flexi-black-6"
                    >
                      <div className="flex items-center gap-3">
                        <img
                          className="ml-3 w-[35px]"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                        <p className="text-sm ">{image.name}</p>
                        <p className="text-xs text-flexi-black-4">
                          {imageSize} kb
                        </p>
                      </div>
                      <IoMdClose
                        className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                        onClick={() =>
                          handleRemove(
                            setImageSeniorCardPreview,
                            imageSeniorCardPreview,
                            i,
                          )
                        }
                      />
                    </div>
                  );
                })}
                <div
                  {...seniorCardDropZone.getRootProps()}
                  // onMouseOver={() => setDropSelector('suspension')}
                  className="relative mb-2 grid h-auto min-h-[130px] cursor-pointer overflow-hidden rounded-lg p-1 shadow-[0_2px_9px] shadow-flexi-black-6"
                >
                  <input
                    id="seniorCardFile"
                    {...seniorCardDropZone.getInputProps()}
                  />

                  {seniorCardDropZone.isDragActive ? (
                    <p className="flex h-full items-center justify-center gap-1">
                      drop here...
                    </p>
                  ) : (
                    <div className="h-ful flex flex-col items-center justify-center text-sm">
                      <BiUpload className="text-3xl" />
                      <p className="m-0 pt-[10px] pb-[5px] font-normal">
                        Upload File
                      </p>
                      <p className="mx-[20px] text-center text-[12px]">
                        FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
                Have I had any criminal convictions in the last 3 years?
              </p>
              <div className="dropdown-hover dropdown relative flex flex-col items-end">
                <label
                  tabIndex={0}
                  className="btn relative mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-2 hover:border-zinc-300  hover:bg-white active:!scale-[1]"
                >
                  <p
                    className={classNames('w-[50%] font-normal capitalize', {
                      'text-transparent': hasConviction === '',
                    })}
                  >
                    {hasConviction === 'true' ? 'Yes' : 'No'}
                  </p>
                  <span
                    style={{
                      maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      maskRepeat: 'no-repeat',
                      WebkitMaskRepeat: 'no-repeat',
                      WebkitMaskPosition: 'center',
                    }}
                    className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                  />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu absolute right-0 w-[90px] translate-y-[50%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-2"
                >
                  <label className="label flex-1 cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={0}
                      onClick={() => setHasConviction('false')}
                      {...register('hasConvicted')}
                    />
                    <span>No</span>
                  </label>
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={1}
                      onClick={() => setHasConviction('true')}
                      {...register('hasConvicted')}
                    />
                    <span>Yes</span>
                  </label>
                </ul>

                {errors.hasConvicted && (
                  <InvalidText>{errors.hasConvicted?.message}</InvalidText>
                )}
              </div>
            </div>
            {hasConviction === 'true' && (
              <div className="mt-[20px] flex h-full w-full flex-col overflow-clip  overflow-x-visible transition-all duration-500">
                <textarea
                  name=""
                  id=""
                  rows={5}
                  className="textarea textarea-bordered mb-5 h-[120px] leading-[1.429] focus:outline-none"
                  placeholder="Briefly explain the incident &amp; upload any supporting document."
                  onChange={(e) => setConvictionExplanation(e.target.value)}
                ></textarea>

                {imageConvictionPreview?.map((image: any, i: number) => {
                  const imageSize = image.size / 1000;
                  return (
                    <div
                      key={i}
                      className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_2px_9px] shadow-flexi-black-6"
                    >
                      <div className="flex items-center gap-3">
                        <img
                          className="ml-3 w-[35px]"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                        <p className="text-sm ">{image.name}</p>
                        <p className="text-xs text-flexi-black-4">
                          {imageSize} kb
                        </p>
                      </div>
                      <IoMdClose
                        className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                        onClick={() =>
                          handleRemove(
                            setImageConvictionPreview,
                            imageConvictionPreview,
                            i,
                          )
                        }
                      />
                    </div>
                  );
                })}
                <div
                  {...convictDropZone.getRootProps()}
                  className="relative mb-2 grid h-auto min-h-[130px]  cursor-pointer  overflow-hidden rounded-lg p-1 shadow-[0_2px_9px] shadow-flexi-black-6"
                >
                  <input {...convictDropZone.getInputProps()} />

                  {convictDropZone.isDragActive ? (
                    <p className="flex h-full items-center justify-center gap-1">
                      drop here...
                    </p>
                  ) : (
                    <div className="h-ful flex flex-col items-center justify-center text-sm">
                      <BiUpload className="text-3xl" />
                      <p className="m-0 pt-[10px] pb-[5px] font-normal">
                        Upload File
                      </p>
                      <p className="mx-[20px] text-center text-[12px]">
                        FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="flex items-center justify-between">
              <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
                Have I made any claims on insurance for damage to a vehicle in
                the last 3 years?
              </p>
              <div className="dropdown-hover dropdown relative flex flex-col items-end">
                <div>
                  <label
                    tabIndex={0}
                    className="btn relative mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-2 hover:border-zinc-300  hover:bg-white active:!scale-[1]"
                  >
                    <p
                      className={classNames('w-[50%] font-normal capitalize', {
                        'text-transparent': hasClaimInsurance === '',
                      })}
                    >
                      {hasClaimInsurance === 'true' ? 'Yes' : 'No'}
                    </p>
                    <span
                      style={{
                        maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                        WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                        maskRepeat: 'no-repeat',
                        WebkitMaskRepeat: 'no-repeat',
                        WebkitMaskPosition: 'center',
                      }}
                      className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                    />
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu absolute right-0 w-[90px] translate-y-[0%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-2"
                  >
                    <label className="label flex-1 cursor-pointer justify-start">
                      <input
                        type="radio"
                        className="appearance-none"
                        value={0}
                        onClick={() => setHasClaimInsurance('false')}
                        {...register('hasClaimsInsurance')}
                      />
                      <span>No</span>
                    </label>
                    <label className="label cursor-pointer justify-start">
                      <input
                        type="radio"
                        className="appearance-none"
                        value={1}
                        onClick={() => setHasClaimInsurance('true')}
                        {...register('hasClaimsInsurance')}
                      />
                      <span>Yes</span>
                    </label>
                  </ul>
                </div>
                {errors.hasClaimsInsurance && (
                  <InvalidText>
                    <p className="whitespace-nowrap text-right">
                      {errors.hasClaimsInsurance?.message}
                    </p>
                  </InvalidText>
                )}
              </div>
            </div>
            {hasClaimInsurance === 'true' && (
              <div className="mt-[20px] flex h-full w-full flex-col overflow-clip  overflow-x-visible transition-all duration-500">
                <textarea
                  name=""
                  id=""
                  rows={5}
                  className="textarea textarea-bordered mb-5 h-[120px] leading-[1.429] focus:outline-none"
                  placeholder="Briefly explain the incident &amp; upload any supporting document."
                  onChange={(e) =>
                    setClaimsInsuranceExplanation(e.target.value)
                  }
                ></textarea>
                {imageClaimsPreview?.map((image: any, i: number) => {
                  const imageSize = image.size / 1000;
                  return (
                    <div
                      key={i}
                      className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_2px_9px] shadow-flexi-black-6"
                    >
                      <div className="flex items-center gap-3">
                        <img
                          className="ml-3 w-[35px]"
                          src={URL.createObjectURL(image)}
                          alt=""
                        />
                        <p className="text-sm ">{image.name}</p>
                        <p className="text-xs text-flexi-black-4">
                          {imageSize} kb
                        </p>
                      </div>
                      <IoMdClose
                        className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                        onClick={() =>
                          handleRemove(
                            setImageClaimsPreview,
                            imageClaimsPreview,
                            i,
                          )
                        }
                      />
                    </div>
                  );
                })}
                <div
                  {...claimsDropZone.getRootProps()}
                  className="relative mb-2 grid h-auto min-h-[130px]  cursor-pointer  overflow-hidden rounded-lg p-1 shadow-[0_2px_9px] shadow-flexi-black-6"
                >
                  <input {...claimsDropZone.getInputProps()} />

                  {claimsDropZone.isDragActive ? (
                    <p className="flex h-full items-center justify-center gap-1">
                      drop here...
                    </p>
                  ) : (
                    <div className="h-ful flex flex-col items-center justify-center text-sm">
                      <BiUpload className="text-3xl" />
                      <p className="m-0 pt-[10px] pb-[5px] font-normal">
                        Upload File
                      </p>
                      <p className="mx-[20px] text-center text-[12px]">
                        FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-[70px]">
          <button
            type="submit"
            className={classNames(
              'btn btn-primary !h-[50px] w-full rounded-full px-10 text-[16px] normal-case !text-white hover:bg-flexi-green-2 md:!h-[60px]',
              {
                loading: pendingSubmit,
                'bg-flexi-green-2': pendingSubmit,
                '': !suspensionExplanation,
              },
            )}
            disabled={
              hasSuspension === '' ||
              hasSeniorCard === '' ||
              hasConviction === '' ||
              hasClaimInsurance === '' ||
              drivingYears === '0' ||
              (hasSuspension === 'true' && !suspensionExplanation) ||
              (hasSeniorCard === 'true' && imageSeniorCardPreview.length===0) ||
              (hasConviction === 'true' && !convictionExplanation) ||
              (hasClaimInsurance === 'true' && !claimInsuranceExplanation)
            }
          >
            Choose a Plan
          </button>
        </div>
      </form>
    </article>
  );
};

export default DrivingExperience;
