import { PayloadAction } from '@reduxjs/toolkit';
import { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import { RequestStatusEnum } from 'common/types';
import { call, put, cancelled } from 'redux-saga/effects';
import {
  setFavoriteLocationList,
  setUpdateFavoriteLocationStatus,
  setFavoriteLocationListStatus,
} from './slice';

export const SAGA_ACTIONS = {
  FETCH_FAVORITE_LOCATIONS: 'FETCH_FAVORITE_LOCATIONS',
  ADD_FAVORITE_LOCATION: 'ADD_FAVORITE_LOCATION',
  REMOVE_FAVORITE_LOCATION: 'REMOVE_FAVORITE_LOCATION',
};


export function* fetchFavoriteLocations():any {
  const cancelTokenSource = axios.CancelToken.source();
  const api = authenticatedClient();
  try {
    yield put(setFavoriteLocationListStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get('/me/favorites/pod', {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setFavoriteLocationList(data));
    yield put(setFavoriteLocationListStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setFavoriteLocationListStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type FavoriteLocationPayload = {
  favorite: number;
};

export function* addFavoriteLocation(
  action: PayloadAction<FavoriteLocationPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setUpdateFavoriteLocationStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/me/favorites/pod', action.payload));
    yield put(setUpdateFavoriteLocationStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    yield put(setUpdateFavoriteLocationStatus(RequestStatusEnum.FAILED));
  }
  } 
  finally {
    yield(fetchFavoriteLocations());
    // yield put(setUpdateFavoriteLocationStatus(false));
  }
}

export function* removeFavoriteLocation(
  action: PayloadAction<FavoriteLocationPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setUpdateFavoriteLocationStatus(RequestStatusEnum.PENDING));
    yield call(() => api.delete('/me/favorites/pod', { data: action.payload }));
    yield put(setUpdateFavoriteLocationStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
    yield put(setUpdateFavoriteLocationStatus(RequestStatusEnum.FAILED));
    handleAPIError(err.response);
    }
  } finally {
    yield(fetchFavoriteLocations());
  }
}
