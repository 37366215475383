import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchOcrTransaction,
  fetchQrCode,
  sendSms,
  fetchOcrTransactionSuccess,
} from './sagas';

const { FETCH_OCR_TRANSACTION, FETCH_QR_CODE, SEND_SMS, FETCH_OCR_TRANSACTION_SUCCESS } = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_OCR_TRANSACTION, fetchOcrTransaction),
  takeLatest(FETCH_QR_CODE, fetchQrCode),
  takeLatest(SEND_SMS, sendSms),
  takeLatest(FETCH_OCR_TRANSACTION_SUCCESS, fetchOcrTransactionSuccess),
];

export default actions;
