import React, { useRef, useLayoutEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { IndicatorSeparatorProps } from 'react-select';
import classNames from 'classnames';
import * as Yup from 'yup';
import gsap from 'gsap';
import _ from 'lodash';

import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import {
  selectError,
  selectPendingVerifyLicense,
  selectRegisterStatus,
  setError,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import countries from 'common/countries';
import CustomInput from 'components/CustomInputSignUp';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

type FormValues = {
  licenseNumber: string;
};

const validationSchema = Yup.object().shape({
  licenseNumber: Yup.string()
    .required('License number is required')
    .matches(/^\w+/, 'Must contain number and letters only'),
});

const VerificationForm = () => {
  const dispatch = useAppDispatch();
  const registerStatus = useAppSelector(selectRegisterStatus);
  const isPendingVerifyLicense = useAppSelector(selectPendingVerifyLicense);
  const error = useAppSelector(selectError);
  const component = useRef<HTMLDivElement>(null);
  const [country, setCountry] = useState('');

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
  } = useForm<FormValues>(formOptions);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (registerStatus !== RequestStatusEnum.PENDING) {
      const payload = {
        ...values,
        country
      };
      dispatch(setError(null));
      dispatch({ type: ACTIONS.VERIFY_LICENSE_V2, payload });
    }
  };

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<any>) => {
    return (
      <span
        style={{
          backgroundColor: 'none',
        }}
        {...innerProps}
      />
    );
  };

  const DropdownIndicator = () => {
    return (
      <IconArrow className="absolute right-[15px] h-[14px] w-[14px] fill-flexi-orange-1" />
    );
  };

  return (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-115px)] w-full max-w-[600px] flex-col md:min-h-full"
    >
      <p className="text-center text-[14px] md:text-left md:text-[18px]">
        Please enter your license details as seen{' '}
        <br className="block md:hidden" />
        on your card.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-[8px] flex grow flex-col gap-y-[4px]"
      >
        <Select
          components={{ IndicatorSeparator, DropdownIndicator }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: '#d2d2d2',
            },
          })}
          styles={{
            control: (base: any, state: any) => ({
              ...base,
              border: '1px #d2d2d2 solid',
              outline: state.isFocused && '0px',
              padding: isMobile ? '5px' : '10px',
              borderRadius: '8px',
              fontSize: isMobile ? '14px' : '16px',
            }),
            option: (base: any) => ({
              ...base,
              outline: 'none !important',
            }),
          }}
          isClearable={false}
          defaultMenuIsOpen={isMobile}
          defaultInputValue={isMobile ? 'Australia' : ''}
          placeholder={'Country of Issue'}
          options={countries.map((country) => {
            return {
              value: country.code,
              label: country.name,
            };
          })}
          className="z-40"
          onChange={(e) => setCountry(e.value)}
        />
        {error?.country && <InvalidText>{error?.country}</InvalidText>}
        <CustomInput
          margin={20}
          title="License Number"
          error={error?.licenseNumber}
        >
          <input
            type="text"
            placeholder="license number"
            className={classNames('peer input input-bordered w-full', {
              'border-red-500': error?.licenseNumber,
            })}
            {...register('licenseNumber')}
          />
        </CustomInput>
        {_.map(error, (err, i) => {
          return <InvalidText key={i}>{err}</InvalidText>;
        })}

        <div className="mt-[40px] hidden text-center md:block">
          <div className="flex items-center justify-center gap-[5px]">
            <div className="h-[1px] w-[61px] bg-[#979797]" />
            <p className="text-[12px]">or</p>
            <div className="h-[1px] w-[61px] bg-[#979797]" />
          </div>
          <p className="mt-[32px] text-[14px] font-bold">
            <a href="/register-business" className="text-primary">
              Click here
            </a>{' '}
            to register for a business account.
          </p>
        </div>

        <button
          type="submit"
          className={classNames(
            'btn btn-primary mt-auto h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]',
            {
              'loading bg-flexi-green-2':
                registerStatus === RequestStatusEnum.PENDING,
            },
          )}
          disabled={isPendingVerifyLicense}
        >
          Proceed
        </button>
      </form>
    </section>
  );
};

export default VerificationForm;
