import { all } from 'redux-saga/effects';
import authWatcher from 'modules/auth/watcher';
import meWatcher from 'modules/me/watcher';
import ocrWatcher from 'modules/ocr/watcher';
import vehicleWatcher from 'modules/vehicle/watcher';
import transactionWatcher from 'modules/transaction/watcher';
import bookingWatcher from 'modules/booking/watcher';
import favoritesWatcher from 'modules/favorites/watcher';
import podsWatcher from 'modules/pods/watcher';
import invoiceWatcher from 'modules/invoice/watcher';
import businessWatcher from 'modules/business/watcher';
import announcementWatcher from 'modules/announcement/watcher';
import publicWatcher from 'modules/public/watcher';
import frontendWatcher from 'modules/frontend/watcher';

export default function* rootSaga() {
  yield all([...authWatcher]);
  yield all([...meWatcher]);
  yield all([...ocrWatcher]);
  yield all([...vehicleWatcher]);
  yield all([...transactionWatcher]);
  yield all([...bookingWatcher]);
  yield all([...favoritesWatcher]);
  yield all([...podsWatcher]);
  yield all([...invoiceWatcher]);
  yield all([...businessWatcher]);
  yield all([...announcementWatcher]);
  yield all([...publicWatcher]);
  yield all([...frontendWatcher]);
}
