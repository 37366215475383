import React from 'react';
import { CenteredPageTitle, PageTitleBanner } from 'components/Typography';

const HowTo = () => {
  const seeder = {
    question: 'How to use the Flexicar App',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id faucibus purus.<br/>Vestibulum eget volutpat velit. Nulla at consequat neque. Etiam velit sem, commodo quis nisl at, facilisis molestie nunc. Duis fermentum molestie egestas. Suspendisse potenti. Phasellus in vestibulum magna. Fusce convallis ornare libero. Quisque non augue nec risus pretium consectetur. Maecenas maximus leo ut tempor tincidunt. Mauris pharetra eleifend elit, id cursus mauris mollis id.',
    callToAction: null,
    image: null,
    embedVideoUrl: 'https://www.youtube.com/embed/U9bfmBYGzGY',
    isTopFive: false,
  };

  return (
    <section>
      <PageTitleBanner>help & support</PageTitleBanner>
      <div className="mx-auto min-h-[100vh] px-[40px] py-[120px] md:px-[120px]">
        <div className="mx-auto max-w-[1000px]">
          <div className="child:answer-center mx-auto mb-[60px]">
            <CenteredPageTitle>{seeder.question}</CenteredPageTitle>
          </div>
          <p className="text-center">
            <span dangerouslySetInnerHTML={{ __html: seeder.answer }} />
          </p>
          {seeder.image && (
            <div className="mt-[50px] w-full px-[50px]">
              <img
                src={seeder.image}
                alt={seeder.question}
                className="w-full"
              />
            </div>
          )}
          {seeder.embedVideoUrl && (
            <div className="mt-[50px] w-full px-[50px]">
              <iframe
                src={seeder.embedVideoUrl}
                allow="accelerometer; autoplay; encrypted-media;"
                className="aspect-video h-auto w-[100%]"
              ></iframe>
            </div>
          )}
          {seeder.callToAction && (
            <button
              // onClick=""
              className="btn mt-[50px] h-[30px] min-w-[300px] max-w-[350px] rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2"
            >
              {seeder.callToAction}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default HowTo;
