import { IVehicleFilter, RequestStatusEnum } from 'common/types';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectSelectedVehicleFilters,
  selectVehicleFilterList,
  setSelectedVehicleFilters,
} from 'modules/vehicle/slice';
import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';
import CustomCheckBox from 'components/CustomCheckbox';
import classNames from 'classnames';
import {
  selectBookingSettings,
  selectSettingsStatus,
} from 'modules/business/slice';
import useBusiness from 'hooks/useBusiness';
import ACTIONS from 'modules/rootActions';
import PendingLoader from 'components/PendingLoader';
import { selectUser, selectUserStatus } from 'modules/me/slice';
// import useBooking from 'hooks/useBooking';

const VehicleField = () => {
  const dispatch = useAppDispatch();
  const [isDropdownActive, setDropdownActive] = useState<boolean>(false);
  const vehicleFilters = useAppSelector(selectVehicleFilterList);
  const selectedVehicleFilters = useAppSelector(selectSelectedVehicleFilters);
  const dropdownRef = useRef(null);
  const businessSettings = useAppSelector(selectBookingSettings);
  const settingsStatus = useAppSelector(selectSettingsStatus);
  const { isBusinessDriverAccount, isBusinessManagerAccount } = useBusiness();
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus);
  const [checkVehicleFilter, setCheckVehicleFilter] = useState(selectedVehicleFilters);

  useEffect(() => {
    setCheckVehicleFilter(selectedVehicleFilters.filter((i) => {
      if (i.type === 'vehicle') return true;
      return false;
    }))
  }, [selectedVehicleFilters])

  useEffect(() => {
    if (isBusinessDriverAccount)
      dispatch({ type: ACTIONS.FETCH_BOOKING_SETTINGS });
  }, [user]);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    vehicleFilter: IVehicleFilter,
  ) => {
    if (e.currentTarget.checked) {
      dispatch(
        setSelectedVehicleFilters([...selectedVehicleFilters, vehicleFilter]),
      );
    } else {
      const selectedFilters = selectedVehicleFilters.filter((i) => {
        if (i.slug === e.target.id) return false;
        return true;
      });
      dispatch(setSelectedVehicleFilters([...selectedFilters]));
    }
    setCheckVehicleFilter(selectedVehicleFilters.filter((i) => {
      if (i.type === 'vehicle') return true;
      return false;
    }))
  };

  useEffect(() => {
    if (checkVehicleFilter.length === 0){
      const selectedFilters = selectedVehicleFilters.filter((i) => {
        if (i.type !== 'vehicle') return false;
        return true;
      });
      dispatch(setSelectedVehicleFilters([...selectedFilters]));
    }
  }, [checkVehicleFilter.length]);

  const handleSelectAll = () => {
    if (businessSettings && !isBusinessManagerAccount) {
      const settings: IVehicleFilter[] = businessSettings.vehicleTypes.filter(
        (type) => type.status === true && type,
      );
      dispatch(setSelectedVehicleFilters(settings || []));
    } else {
      dispatch(setSelectedVehicleFilters(vehicleFilters || []));
    }
  };

  const handleDefaultSelectAll = () => {
    if (businessSettings && !isBusinessManagerAccount) {
      const settings: IVehicleFilter[] = businessSettings.vehicleTypes.filter(
        (type) =>
          type.status === true &&
          type.slug !== 'pet-friendly' &&
          type.slug !== 'mobility' &&
          type.slug !== 'wyndham' &&
          type,
      );
      dispatch(setSelectedVehicleFilters(settings || []));
    } else {
      const ignoreSlug = vehicleFilters?.filter(
        (filter) =>
          filter.slug !== 'pet-friendly' && filter.slug !== 'mobility' && filter.slug !== 'wyndham',
      );
      dispatch(setSelectedVehicleFilters(ignoreSlug || []));
    }
  };

  const handleDeselectAll = () => {
    dispatch(setSelectedVehicleFilters([]));
  };

  const handleCheckboxValue = (slug: string) => {
    if (selectedVehicleFilters.find((i) => i.slug === slug)) {
      return true;
    }
    return false;
  };

  useClickAway(dropdownRef, () => {
    setDropdownActive(false);
  });

  useEffect(() => {
    if ((vehicleFilters && user) || window.location.pathname === '/' || window.location.pathname === '/map')
      handleDefaultSelectAll();
  }, [vehicleFilters, businessSettings, user]);

  return (
    <div
      className="flex w-full cursor-pointer items-center justify-between border-b-2 border-zinc-200 border-solid lg:w-[75px] lg:justify-start"
      onClick={() => setDropdownActive(true)}
    >
      <input
        type="text"
        className="input pointer-events-none w-[60px] bg-transparent px-0 !pl-2 font-[500] focus:mx-0 focus:px-0 focus:outline-transparent [&::placeholder]:text-flexi-black-1 text-[0.75rem] lg:text-[0.875rem]"
        placeholder="Filter"
        onChange={() => { }}
      />
      <ArrowDown className="z-[1000] h-[10px] w-[10px] fill-flexi-orange-1" />

      {isDropdownActive && (
        <div
          ref={dropdownRef}
          className="absolute top-[50px] lg:top-[80px] left-[50%] flex lg:w-[65%] w-[85%] z-[99999] translate-x-[-50%] flex-col items-center bg-white py-[30px] lg:py-[50px] shadow-[0_2px_9px_rgba(0,0,0,0.1)]"
        >
          <div className="mb-[15px] flex w-full flex-col justify-between px-0 lg:flex-row lg:px-[150px]">
            <p className="text-center text-[18px] font-bold">Filter By</p>
            <div className="flex justify-center gap-[50px] lg:justify-start">
              <button
                className={classNames(
                  'btn-link p-0 font-bold text-flexi-orange-1 text-[14px] lg:text-[16px]',
                  {
                    '!text-flexi-black-4':
                      selectedVehicleFilters.length === vehicleFilters?.length,
                  },
                )}
                onClick={handleSelectAll}
              >
                Select all
              </button>
              <button
                className={classNames(
                  'btn-link p-0 font-bold text-flexi-orange-1 text-[14px] lg:text-[16px]',
                  {
                    '!text-flexi-black-4': selectedVehicleFilters.length === 0,
                  },
                )}
                onClick={handleDeselectAll}
              >
                Deselect all
              </button>
            </div>
          </div>
          {settingsStatus === RequestStatusEnum.PENDING ||
            userStatus === RequestStatusEnum.PENDING ? (
            <PendingLoader />
          ) : businessSettings && isBusinessDriverAccount ? (
            <div className="relative grid h-auto w-[100%] grid-cols-2 gap-y-[15px] py-2 pl-[10px] lg:grid-cols-3 lg:pl-[150px] text-[14px] lg:text-[16px]">
              {businessSettings?.vehicleTypes.map((i: IVehicleFilter) => {
                const isChecked = handleCheckboxValue(i.slug);

                return (
                  i.status === true && (
                    <CustomCheckBox
                      key={i.slug}
                      title={i.name}
                      size={10}
                      isCapitalize={true}
                    >
                      <input
                        type="checkbox"
                        id={`${i.slug}`}
                        name={`${i.slug}`}
                        className={classNames(
                          'checkbox checkbox-xs h-[20px] w-[20px] rounded-md',
                          {
                            '!border-black !border-opacity-[80%] !bg-black !bg-opacity-[20%]': (i.type !== 'vehicle' && checkVehicleFilter.length < 1),
                          },
                        )}
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e, i)}
                        style={{
                          // @ts-ignore
                          '--chkfg': '0',
                          '--chkbg': '0',
                          backgroundColor: 'white',
                        }}
                        disabled={i.type !== 'vehicle' && checkVehicleFilter.length < 1}
                      />
                    </CustomCheckBox>
                  )
                );
              })}
            </div>
          ) : (
            <div className="relative grid h-auto w-[100%] grid-cols-2 gap-y-[15px] py-2 pl-[10px] lg:grid-cols-3 lg:pl-[150px] text-[14px] lg:text-[16px]">
              {vehicleFilters?.map((i: IVehicleFilter) => {
                const isChecked = handleCheckboxValue(i.slug);
                return (
                  <CustomCheckBox
                    key={i.slug}
                    title={i.name}
                    size={10}
                    isCapitalize={true}
                  >
                    <input
                      type="checkbox"
                      id={`${i.slug}`}
                      name={`${i.slug}`}
                      className={classNames(
                        'checkbox checkbox-xs h-[20px] w-[20px] rounded-md',
                        {
                          '!border-black !border-opacity-[80%] !bg-black !bg-opacity-[20%]': (i.type !== 'vehicle' && checkVehicleFilter.length < 1),
                        },
                      )}
                      checked={isChecked}
                      onChange={(e) => handleCheckboxChange(e, i)}
                      style={{
                        // @ts-ignore
                        '--chkfg': '0',
                        '--chkbg': '0',
                        backgroundColor: 'white',
                      }}
                      disabled={i.type !== 'vehicle' && checkVehicleFilter.length < 1}
                    />
                  </CustomCheckBox>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VehicleField;
