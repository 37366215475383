import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import gsap from 'gsap';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';
import { getResetPasswordContact } from 'common/helpers';
import {
  selectError,
  selectOtpError,
  selectPendingOtp,
  setError,
  setOtpError,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useQuery from 'hooks/useQuery';

const OptimizedForgotPasswordOTP = () => {
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPendingOtp);
  const otpError = useAppSelector(selectOtpError);
  const error = useAppSelector(selectError);
  const contact = getResetPasswordContact();
  const [otpCode, setOtpCode] = useState('');
  const [seconds, setSeconds] = useState(0);
  const [isOtpError, setIsOtpError] = useState(false);
  const [resendTries, setResendTries] = useState(0);
  const { params } = useQuery<{ token?: string }>();
  const component = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffectOnce(() => {
    if (params.token) {
      const payload = {
        token: params.token,
      };
      dispatch({
        type: ACTIONS.GET_DECRYPT_TOKEN,
        payload,
      });
    }
  });

  useEffect(() => {
    if (otpCode.length >= 6) {
      const payload = {
        contact: contact,
        code: otpCode,
      };
      dispatch({ type: ACTIONS.RESET_PASSWORD_OTP_V2, payload });
    }
  }, [otpCode]);

  const handleResend = () => {
    dispatch(setError(null));
    dispatch(setOtpError(false));
    if (seconds <= 1) {
      dispatch({
        type: ACTIONS.FORGOT_PASSWORD_SMS,
        payload: {
          contact,
          retries: resendTries,
        },
      });
      setResendTries(resendTries + 1);
    }
    setSeconds(20);
  };

  useEffect(() => {
    const mySecondInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(mySecondInterval);
    };
  });

  useEffect(() => {
    if (otpError) {
      setIsOtpError(true);
      setOtpCode('');
    } else {
      setIsOtpError(false);
    }
  }, [otpError]);

  return (
    <section
      ref={component}
      className="fadein relative m-auto mt-[25px] flex min-h-[calc(100vh-65px)] w-full max-w-[80vw] flex-col items-center md:mt-[100px] md:max-w-[500px]"
    >
      {isMobile && (
        <div className="absolute left-0 top-0 flex h-[25px] w-[25px] items-center justify-center rounded-full bg-flexi-orange-1">
          <a href="/">
            <ArrowIcon className="rotate-180 fill-white" />
          </a>
        </div>
      )}
      <h2 className="font-primary mb-[20px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]">
        verify
      </h2>
      <p className="text-center text-[16px] md:text-left">
        Enter the 6-digit code sent to <br className="block md:hidden" />
        {contact || 'your number.'}
      </p>
      <div className="relative mt-[24px] flex gap-5 md:mt-[50px]">
        <div className=" flex flex-row gap-5">
          <OtpInput
            value={otpCode}
            onChange={(otp: any) => {
              setOtpCode(otp);
              setIsOtpError(false);
            }}
            numInputs={6}
            isInputNum
            inputStyle="input input-bordered select-none text-[20px] md:text-[30px] font-[300] text-[#454954] !w-[40px] px-0 md:!w-[55px] md:!h-[60px] mx-[5px] md:mx-[10px]"
            shouldAutoFocus
          />
        </div>
        {isPending && (
          <span className="absolute right-[-35px] h-[25px] w-[25px] animate-spin self-center rounded-full border-[2px] border-flexi-black-3 border-l-transparent border-t-transparent" />
        )}
      </div>
      {isOtpError && (
        <p className="mt-[25px] self-center text-[12px] text-[#B71616]">
          {error &&
            error.data.errorCode.message &&
            error.data.errorCode.message}
        </p>
      )}
      {seconds > 0 ? (
        <div className=" mt-auto flex w-full max-w-[420px] flex-col text-center text-[14px] md:mt-[50px] md:flex-row lg:mt-[70px]">
          {seconds > 0 &&
            `Please wait 0${
              seconds > 60 ? Math.ceil((seconds - 59) / 60) : '0'
            }:${seconds < 10 || (seconds > 60 && seconds < 70) ? 0 : ''}${
              seconds > 60 ? seconds - 60 : seconds
            }s
          `}
          <p
            className={classNames(
              'pointer-events-auto text-[16px] font-bold text-flexi-orange-1',
              {
                '!pointer-events-none !text-flexi-black-4': seconds > 0,
                'mt-[8px]': isMobile,
                'ml-auto': !isMobile,
              },
            )}
            onClick={handleResend}
            role="button"
          >
            Resend Code
          </p>
        </div>
      ) : resendTries >= 3 ? (
        <div className=" mt-auto flex w-full max-w-[420px] flex-col text-center text-[14px] md:mt-[50px] md:flex-row lg:mt-[70px]">
          <p>Still having problems?</p>
          <p
            // href="tel:1300363780"
            className={classNames(
              'pointer-events-auto text-[16px] font-bold text-flexi-orange-1',
              {
                '!pointer-events-none !text-flexi-black-4': seconds > 0,
                'mt-[8px]': isMobile,
                'ml-auto': !isMobile,
              },
            )}
          >
            Contact Us
          </p>
        </div>
      ) : (
        <div className=" mt-auto flex w-full max-w-[420px] flex-col text-center text-[14px] md:mt-[50px] md:flex-row lg:mt-[70px]">
          <p>Didn&apos;t receive your code?</p>
          <p
            className={classNames(
              'pointer-events-auto text-[16px] font-bold text-flexi-orange-1',
              {
                '!pointer-events-none !text-flexi-black-4': seconds > 0,
                'mt-[8px]': isMobile,
                'ml-auto': !isMobile,
              },
            )}
            onClick={handleResend}
            role="button"
          >
            Resend Code
          </p>{' '}
        </div>
      )}
    </section>
  );
};

export default OptimizedForgotPasswordOTP;
