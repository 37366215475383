import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import {
  IBookingSettings,
  IBusinessDrivers,
  IBusinessInviteesPaginated,
  INotifications,
  RequestStatusEnum,
} from 'common/types';

type InitialState = {
  errors: any;
  inviteDriversStatus: RequestStatusEnum | null;
  removeDriversStatus: RequestStatusEnum | null;
  fetchBusinessDriversStatus: RequestStatusEnum | null;
  businessDrivers: IBusinessDrivers[];
  fetchBusinessInviteesStatus: RequestStatusEnum | null;
  businessInvitees: IBusinessInviteesPaginated | null;
  bookingSettings: IBookingSettings | null;
  notificationSettings: INotifications | null;
  settingsStatus: RequestStatusEnum | null;
  saveSettingsStatus: RequestStatusEnum | null;
  createPersonalAccountDetailsStatus: RequestStatusEnum | null;
  createPersonalAccountStatus: RequestStatusEnum | null;
  createPersonalAccountCompleteStatus: RequestStatusEnum | null;
  createPersonalAccountAddCard: RequestStatusEnum | null;
  fetchBusinessDriverPhotoStatus: RequestStatusEnum | null;
  createPersonalAccountUpdateEmailStatus: RequestStatusEnum | null;
  businessDriverPhoto: any;
  verifyInviteTokenStatus: RequestStatusEnum | null;
};

const initialState: InitialState = {
  errors: {},
  inviteDriversStatus: null,
  removeDriversStatus: null,
  fetchBusinessDriversStatus: null,
  businessDrivers: [],
  fetchBusinessInviteesStatus: null,
  businessInvitees: null,
  bookingSettings: null,
  notificationSettings: null,
  settingsStatus: null,
  saveSettingsStatus: null,
  createPersonalAccountDetailsStatus: null,
  createPersonalAccountStatus: null,
  createPersonalAccountCompleteStatus: null,
  createPersonalAccountAddCard: null,
  fetchBusinessDriverPhotoStatus: null,
  businessDriverPhoto: null,
  createPersonalAccountUpdateEmailStatus: null,
  verifyInviteTokenStatus: null,
};

export const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    reset: () => initialState,
    setErrors: (state, action: PayloadAction<any>) => {
      state.errors = action.payload;
    },
    setInviteDriverStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.inviteDriversStatus = action.payload;
    },
    setRemoveDriverStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.removeDriversStatus = action.payload;
    },
    setFetchBusinessDriverStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.fetchBusinessDriversStatus = action.payload;
    },
    setBusinessDrivers: (state, action: PayloadAction<IBusinessDrivers[]>) => {
      state.businessDrivers = action.payload;
    },
    setFetchBusinessInviteesStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.fetchBusinessInviteesStatus = action.payload;
    },
    setBusinessInvitees: (
      state,
      action: PayloadAction<IBusinessInviteesPaginated>,
    ) => {
      state.businessInvitees = action.payload;
    },
    setBookingSettings: (
      state,
      action: PayloadAction<IBookingSettings | null>,
    ) => {
      state.bookingSettings = action.payload;
    },
    setNotificationSettings: (
      state,
      action: PayloadAction<INotifications | null>,
    ) => {
      state.notificationSettings = action.payload;
    },
    setSettingsStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.settingsStatus = action.payload;
    },
    setSaveSettingsStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.saveSettingsStatus = action.payload;
    },
    setCreatePersonalAccountDetailsStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.createPersonalAccountDetailsStatus = action.payload;
    },
    setCreatePersonalAccountStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.createPersonalAccountStatus = action.payload;
    },
    setCreatePersonalAccountAddCard: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.createPersonalAccountAddCard = action.payload;
    },
    setCreatePersonalAccountCompleteStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.createPersonalAccountCompleteStatus = action.payload;
    },
    setFetchBusinessDriverPhotoStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.fetchBusinessDriverPhotoStatus = action.payload;
    },
    setBusinessDriverPhoto: (state, action: PayloadAction<any>) => {
      state.businessDriverPhoto = action.payload;
    },
    setCreatePersonalAccountUpdateEmailStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.createPersonalAccountUpdateEmailStatus = action.payload;
    },
    setVerifyInviteTokenStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.verifyInviteTokenStatus = action.payload;
    },
  },
});

export const {
  reset,
  setErrors,
  setInviteDriverStatus,
  setRemoveDriverStatus,
  setFetchBusinessDriverStatus,
  setBusinessDrivers,
  setFetchBusinessInviteesStatus,
  setBusinessInvitees,
  setBookingSettings,
  setNotificationSettings,
  setSaveSettingsStatus,
  setSettingsStatus,
  setCreatePersonalAccountDetailsStatus,
  setCreatePersonalAccountStatus,
  setCreatePersonalAccountAddCard,
  setCreatePersonalAccountCompleteStatus,
  setBusinessDriverPhoto,
  setFetchBusinessDriverPhotoStatus,
  setCreatePersonalAccountUpdateEmailStatus,
  setVerifyInviteTokenStatus,
} = slice.actions;

export const selectErrors = (state: RootState): any =>
  state.business.errors;
export const selectInviteDriverStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.inviteDriversStatus;
export const selectFetchDriverStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.fetchBusinessDriversStatus;
export const selectBusinessDrivers = (
  state: RootState,
): IBusinessDrivers[] | [] => state.business.businessDrivers;
export const selectFetchInviteesStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.fetchBusinessInviteesStatus;
export const selectBusinessInvitees = (state: RootState): IBusinessInviteesPaginated | null =>
  state.business.businessInvitees;
export const selectRemoveDriversStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.removeDriversStatus;
export const selectBookingSettings = (
  state: RootState,
): IBookingSettings | null => state.business.bookingSettings;
export const selectNotificationSettings = (
  state: RootState,
): INotifications | null => state.business.notificationSettings;
export const selectSettingsStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.settingsStatus;
export const selectSaveSettingsStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.saveSettingsStatus;
export const selectCreatePersonalAccountDetailsStatus = (
  state: RootState,
): RequestStatusEnum | null =>
  state.business.createPersonalAccountDetailsStatus;
export const selectCreatePersonalAccountStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.createPersonalAccountStatus;
export const selectCreatePersonalAccountAddCard = (
  state: RootState,
): RequestStatusEnum | null => state.business.createPersonalAccountAddCard;
export const selectCreatePersonalAccountCompleteStatus = (
  state: RootState,
): RequestStatusEnum | null =>
  state.business.createPersonalAccountCompleteStatus;
export const selectFetchBusinessDriverPhotoStatus = (
  state: RootState,
): RequestStatusEnum | null =>
  state.business.fetchBusinessDriverPhotoStatus;
export const selectBusinessDriverPhoto = (
  state: RootState,
): any =>
  state.business.businessDriverPhoto;
export const selectCreatePersonalAccountUpdateEmailStatus = (
  state: RootState,
): RequestStatusEnum | null =>
  state.business.createPersonalAccountUpdateEmailStatus;
export const selectVerifyInviteTokenStatus = (
  state: RootState,
): RequestStatusEnum | null => state.business.verifyInviteTokenStatus;
export default slice.reducer;
