import React, { useRef, useLayoutEffect } from 'react';
import Included from 'features/RegisterSchoolPromotion/Included';
import gsap from 'gsap';

const RegisterSchoolPromotion = () => {
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);
  return (
    <section ref={component} className="fadein m-auto flex w-full max-w-[1000px] flex-col items-center">
      <div className="flex w-full flex-col items-center">
        <h2 className="mb-[50px] text-center text-4xl font-bold">
          Congratulations!
        </h2>
        <p className="text-[20px] font-bold">
          You&apos;re eligible for the Deakin* University student rates
        </p>
        <p className="mt-5 text-sm font-bold">
          You&apos;re annual membership fee will be $1 only instead of $35.
        </p>
      </div>
      <Included />
    </section>
  );
};

export default RegisterSchoolPromotion;
