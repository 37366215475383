import React, { useEffect, useRef } from 'react';
import { MdOutlinePersonalInjury } from 'react-icons/md';
import { IoIosBicycle, IoIosBusiness } from 'react-icons/io';
import { FaGasPump } from 'react-icons/fa';
import { BsSpeedometer } from 'react-icons/bs';
import { HiShieldCheck } from 'react-icons/hi';
import PageLayout from 'components/layouts/PageLayout';
import { useHistory } from 'react-router-dom';
// import { GuestTopNav } from 'components/TopNav';
// import { createPortal } from 'react-dom';

const AccountTypesPage = () => {
  const { push } = useHistory();

  const studentRef = useRef<null | HTMLDivElement>(null);
  const businessRef = useRef<null | HTMLDivElement>(null);
  const personalRef = useRef<null | HTMLDivElement>(null);
  // const topNav = document.getElementById('topnav');

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    setTimeout(() => {
      if (hash === 'business') businessRef.current?.scrollIntoView();
      else if (hash === 'student') studentRef.current?.scrollIntoView();
      else if (hash === 'personal') personalRef.current?.scrollIntoView();
      window.scrollBy(0, -100);
    }, 0);
  }, []);

  return (
    <PageLayout>
      {/* {topNav ?
        createPortal(
          <GuestTopNav/>,
          topNav)
      :<></>} */}
      <section className="m-auto flex w-full flex-col items-center overflow-x-hidden py-[100px]">
        <div className="flex max-w-[80%] flex-col items-center justify-items-center pb-[5rem] sm:max-w-[750px] sm:pb-[10rem]">
          <h2 className="mb-[50px] text-center text-2xl font-bold sm:text-4xl">
            No matter what your life looks like, we have a Flexicar plan perfect
            for your driving needs.
          </h2>
          <ul className="mb-10 flex gap-10 md:gap-20">
            <li className="grid place-items-center">
              <MdOutlinePersonalInjury className="flex-[0_0_40px] text-4xl sm:text-[150px]" />
              Personal
            </li>
            <li className="grid place-items-center">
              <IoIosBusiness className="flex-[0_0_40px] text-4xl sm:text-[150px]" />
              Business
            </li>
            <li className="grid place-items-center">
              <IoIosBicycle className="flex-[0_0_40px] text-4xl sm:text-[150px]" />
              Student
            </li>
          </ul>
          <p className="mt-5 mb-10 sm:mt-20">
            Scroll down to know about the different accounts
          </p>
          <p
            className='relative grid h-[65px] w-[3px] place-items-center bg-zinc-500
            before:absolute before:bottom-[3px] before:h-[35px] before:w-[35px] before:rotate-[45deg]
            before:border-[3px] before:border-zinc-500 before:border-t-transparent before:border-l-transparent 
            before:content-[""]
            '
          />
        </div>

        {/* PERSONAL */}
        <div
          ref={personalRef}
          id="personal"
          className="personal relative flex w-[80%] flex-col  items-center  pt-20 pb-20  sm:w-[750px]"
        >
          <span className="absolute top-0 z-[-1] h-full w-[1000%] bg-zinc-100"></span>
          <h2 className="mb-[50px] text-center text-2xl font-bold sm:text-4xl">
            PERSONAL
          </h2>
          <div className="mb-10 grid grid-cols-2 gap-5 text-sm sm:text-base md:gap-10">
            <span
              className='before:content=[""] after:content=[""] relative grid h-[200px] w-full place-items-center overflow-hidden bg-zinc-200 p-5 text-center
                font-bold before:absolute before:h-[500%] before:w-[1px] before:rotate-[35deg] before:bg-zinc-400 before:opacity-30 after:absolute
                after:h-[500%] after:w-[1px] after:rotate-[-35deg] after:bg-zinc-400 after:opacity-30 sm:before:rotate-[-60deg] sm:after:rotate-[60deg] md:p-10
                '
            >
              Infographic / Image explaining the copy on the side
            </span>
            <p className="flex flex-col">
              <span className="mb-2 text-lg font-bold sm:text-2xl">
                $49 Annual Fee
              </span>
              The annual fee will be returned as driving credit
            </p>
            <p className="flex flex-col">
              <span className="mb-2 text-lg font-bold sm:text-2xl">
                3 Plans to choose from
              </span>
              You can choose a plan depending on your needs. Whether you&apos;re
              a first time car sharer or needs a second car for a regular basis,
              errands or for fun.
            </p>
            <span
              className='before:content=[""] after:content=[""] relative grid h-[200px] w-full place-items-center overflow-hidden bg-zinc-200 p-5 text-center
                font-bold before:absolute before:h-[500%] before:w-[1px] before:rotate-[35deg] before:bg-zinc-400 before:opacity-30 after:absolute
                after:h-[500%] after:w-[1px] after:rotate-[-35deg] after:bg-zinc-400 after:opacity-30 sm:before:rotate-[-60deg] sm:after:rotate-[60deg] md:p-10
                '
            >
              Infographic / Image explaining the copy on the side
            </span>
          </div>
          <div className="mt-[15px] flex justify-center sm:mt-[50px]">
            <button
              type="submit"
              className={`btn rounded-full px-10 normal-case sm:btn-lg `}
              onClick={() => push('/register?accountType=1')}
            >
              Create a Personal Account
            </button>
          </div>
        </div>

        {/* BUSINESS */}
        <div
          ref={businessRef}
          id="business"
          className="relative  flex w-[80%] flex-col  items-center  pt-20 pb-20  sm:w-[750px] "
        >
          <h2 className="mb-[50px] text-center text-2xl font-bold sm:text-4xl">
            BUSINESS
          </h2>
          <div className="mb-10 grid grid-cols-2  gap-5 text-sm sm:text-base md:gap-10">
            <span
              className='before:content=[""] after:content=[""] relative grid h-[200px] w-full place-items-center overflow-hidden bg-zinc-200 p-5 text-center
                font-bold before:absolute before:h-[500%] before:w-[1px] before:rotate-[35deg] before:bg-zinc-400 before:opacity-30 after:absolute
                after:h-[500%] after:w-[1px] after:rotate-[-35deg] after:bg-zinc-400 after:opacity-30 sm:before:rotate-[-60deg] sm:after:rotate-[60deg] md:p-10
                '
            >
              Infographic / Image explaining the copy on the side
            </span>
            <p className="flex flex-col">
              <span className="mb-2 text-lg font-bold sm:text-2xl">
                $0 Annual Fee
              </span>
              Best value money can buy! Only account that offers $0 fee even
              without any promo or discount.
            </p>
            <p className="flex flex-col">
              <span className="mb-2 text-lg font-bold sm:text-2xl">
                Unlimited Drivers
              </span>
              You can add as many drivers as you want into your business
              account.
            </p>
            <span
              className='before:content=[""] after:content=[""] relative grid h-[200px] w-full place-items-center overflow-hidden bg-zinc-200 p-5 text-center
                font-bold before:absolute before:h-[500%] before:w-[1px] before:rotate-[35deg] before:bg-zinc-400 before:opacity-30 after:absolute
                after:h-[500%] after:w-[1px] after:rotate-[-35deg] after:bg-zinc-400 after:opacity-30 sm:before:rotate-[-60deg] sm:after:rotate-[60deg] md:p-10
                '
            >
              Infographic / Image explaining the copy on the side
            </span>
          </div>

          <div>
            <p className="mb-10 mt-10 grid place-items-center text-lg font-bold sm:text-2xl">
              Businesses that trust Flexicar
            </p>
            <ul className="grid grid-cols-2 gap-x-20 gap-y-10 md:grid-cols-3">
              <li className="font-serif sm:text-2xl">Logoipsum</li>
              <li className="font-serif sm:text-2xl">Logoipsum</li>
              <li className="font-serif sm:text-2xl">Logoipsum</li>
              <li className="font-serif sm:text-2xl">Logoipsum</li>
              <li className="font-serif sm:text-2xl">Logoipsum</li>
              <li className="font-serif sm:text-2xl">Logoipsum</li>
            </ul>
          </div>

          <div className="mt-[50px] flex justify-center sm:mt-[75px]">
            <button
              type="submit"
              className={`btn rounded-full px-10 normal-case sm:btn-lg `}
              onClick={() => push('/register?accountType=2')}
            >
              Get your Business on Flexicar
            </button>
          </div>
        </div>

        {/* STUDENT */}
        <div
          ref={studentRef}
          id="student"
          className="relative flex w-[80%] flex-col items-center  pt-20 pb-20  sm:w-[750px]"
        >
          <span className="absolute top-0 z-[-1] h-full w-[1000%] bg-zinc-100"></span>
          <h2 className="mb-[50px] text-center text-2xl font-bold sm:text-4xl">
            STUDENT
          </h2>
          <div className="mb-10 grid grid-cols-2 gap-5 text-sm sm:text-base md:gap-10">
            <span
              className='before:content=[""] after:content=[""] relative grid h-[200px] w-full place-items-center overflow-hidden bg-zinc-200 p-5 text-center
                font-bold before:absolute before:h-[500%] before:w-[1px] before:rotate-[35deg] before:bg-zinc-400 before:opacity-30 after:absolute
                after:h-[500%] after:w-[1px] after:rotate-[-35deg] after:bg-zinc-400 after:opacity-30 sm:before:rotate-[-60deg] sm:after:rotate-[60deg] md:p-10
                '
            >
              Infographic / Image explaining the copy on the side
            </span>
            <p className="flex flex-col">
              <span className="mb-2 text-lg font-bold sm:text-2xl">
                $0 Annual Fee
              </span>
              Best value money can buy! Only account that offers $0 fee even
              without any promo or discount.
            </p>
            <p className="flex flex-col">
              <span className="mb-2 text-lg font-bold sm:text-2xl">
                Unlimited Drivers
              </span>
              You can add as many drivers as you want into your business
              account.
            </p>
            <span
              className='before:content=[""] after:content=[""] relative grid h-[200px] w-full place-items-center overflow-hidden bg-zinc-200 p-5 text-center
                font-bold before:absolute before:h-[500%] before:w-[1px] before:rotate-[35deg] before:bg-zinc-400 before:opacity-30 after:absolute
                after:h-[500%] after:w-[1px] after:rotate-[-35deg] after:bg-zinc-400 after:opacity-30 sm:before:rotate-[-60deg] sm:after:rotate-[60deg] md:p-10
                '
            >
              Infographic / Image explaining the copy on the side
            </span>
          </div>
          <div className="flex flex-col  items-center">
            <p className="mb-10 mt-10 grid w-auto place-items-center text-lg font-bold sm:text-2xl md:mb-14">
              Flexicar School Partners
            </p>
            <ul className="block w-[120%] flex-grow flex-wrap justify-center gap-10 gap-y-[50px]  self-center sm:w-[150%] md:flex">
              <li className="block justify-center md:flex md:w-[30%]">
                <p className="text-left text-base sm:text-2xl">
                  Australian National University
                </p>
              </li>
              <li className="block justify-center md:flex  md:w-[30%]">
                <p className=" text-left text-base sm:text-2xl ">
                  Deakin University
                </p>
              </li>
              <li className="block justify-center md:flex md:w-[30%]">
                <p className="text-left text-base sm:text-2xl">
                  Monash University
                </p>
              </li>
              <li className="block justify-center md:flex md:w-[30%]">
                <p className="text-left text-base sm:text-2xl ">
                  The University Of QueensLand Australia
                </p>
              </li>
              <li className="block justify-center md:flex md:w-[30%]">
                <p className="text-left text-base sm:text-2xl">
                  University Tasmania
                </p>
              </li>
            </ul>
          </div>
          <div className="mt-[50px] flex justify-center sm:mt-[75px]">
            <button
              type="submit"
              className={`btn rounded-full px-10 normal-case sm:btn-lg `}
              onClick={() => push('/register?accountType=3')}
            >
              Use Flexicar for school
            </button>
          </div>
        </div>

        {/* INCLUSIONS */}
        <div className="relative flex w-[80%] flex-col  items-center pt-28  pb-16 sm:max-w-[900px]">
          <h2 className="mb-[75px] text-center text-2xl font-bold sm:text-4xl">
            Inclusions for all account types
          </h2>

          <div className="flex flex-col items-center gap-20 text-center text-sm sm:text-base md:gap-10 lg:flex-row lg:items-start">
            <div className="flex w-full max-w-[400px] flex-col items-center gap-5 align-middle lg:w-[33.33%]">
              <p className="flex h-[115px] w-[115px] items-center justify-center rounded-full bg-zinc-100 align-middle">
                <FaGasPump className="flex-[0_0_40px] text-[35px] sm:text-[75px]" />
              </p>
              <p className="text-center text-lg font-bold sm:text-2xl">
                Free Fuel
              </p>
              <p>
                Petrol cards are located in your Flexicar, please see the
                Re:Member Manual kept in the driver-side door pocket for more
                information on using the petrol cards to fill up the car.
              </p>
            </div>

            <div className="flex w-full max-w-[400px] flex-col items-center gap-5 align-middle lg:w-[33.33%]">
              <p className="flex h-[115px] w-[115px] items-center justify-center rounded-full bg-zinc-100 align-middle">
                <HiShieldCheck className="flex-[0_0_40px] text-[35px] sm:text-[75px]" />
              </p>
              <p className="text-center text-lg font-bold sm:text-2xl">
                Insurance
              </p>
              <p>
                Insurance is included in your booking with a Standard Damage
                Cover of $2000 for standard vehicles / $3000 for Prestige and
                Commercial vehicles.
              </p>
            </div>

            <div className="flex w-full max-w-[400px] flex-col items-center gap-5 align-middle lg:w-[33.33%]">
              <p className="flex h-[115px] w-[115px] items-center justify-center rounded-full bg-zinc-100 align-middle">
                <BsSpeedometer className="flex-[0_0_40px] text-[35px] sm:text-[75px]" />
              </p>

              <p className="text-center text-lg font-bold sm:text-2xl">
                Unlimited Kilometer
              </p>

              <p>
                You can drive a Flexicar as far as you like, every hourly
                booking includes unlimited kms and daily bookings include at
                least 1000kms depending on your rate plan
              </p>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default AccountTypesPage;
