import React from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import * as _ from 'lodash';

import { CenteredPageTitle } from 'components/Typography';
import {
  selectPagesContents,
  selectPagesContentsCategories,
} from 'modules/public/slice';
import useAppSelector from 'hooks/useAppSelector';

const FAQs = () => {
  const pagesContentsCategories = useAppSelector(selectPagesContentsCategories);
  const pagesContents = useAppSelector(selectPagesContents);

  return (
    <div className="mx-[-40px] bg-[#F2F2F2] px-[40px] py-[80px] md:mx-[-120px] md:px-[90px] md:py-[120px] xl:px-[160px]">
      <CenteredPageTitle extraClass="mb-[80px]">FAQs</CenteredPageTitle>
      {pagesContents && pagesContentsCategories ? (
        <div className="grid w-full grid-cols-1 justify-start gap-[25px] md:grid-cols-2 lg:grid-cols-3 lg:gap-[45px]">
          {pagesContentsCategories?.map((category) => {
            return (
              <div
                key={category.id}
                className="flex basis-[25%] flex-col rounded-[20px] border-[1px] border-flexi-black-7 bg-white p-[25px] md:py-[60px] lg:px-[45px]"
              >
                <p className="font-primary mb-[30px] text-center text-[20px] font-bold md:mb-0 md:h-[80px] lg:h-[120px] lg:text-[28px] xl:text-[32px]">
                  {category.title}
                </p>
                <div className="mb-[50px] flex flex-col gap-[15px]">
                  {_.map(
                    pagesContents?.filter(
                      (content) => content.categoryId === category.id,
                    ),
                    (content, index) => {
                      if (index < 5) {
                        return (
                          <a
                            key={index}
                            href={`/faq/category?cid=${content.categoryId}&ref=content-${content.contentId}`}
                            className="group text-[16px] text-flexi-black-1 transition duration-300 hover:text-flexi-orange-1 lg:text-[20px] xl:text-[24px]"
                          >
                            {content.question}
                            <span className="block h-0.5 max-w-0 bg-flexi-orange-1 transition-all duration-500 group-hover:max-w-full"></span>
                          </a>
                        );
                      } else {
                        return null;
                      }
                    },
                  )}
                </div>
                <a
                  href={`category?cid=${category.id}`}
                  className="btn-primary btn mx-auto mt-auto w-[75%] rounded-full bg-flexi-green-1 py-[10px] text-center text-[12px] font-bold text-white md:w-full lg:text-[14px] xl:w-[85%]"
                >
                  See all questions
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <AiOutlineLoading3Quarters
          size="48px"
          className="mx-auto mt-[100px] w-[50px] animate-spin fill-flexi-orange-1"
        />
      )}
    </div>
  );
};

export default FAQs;
