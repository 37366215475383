import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import SignUpLayout from 'components/layouts/SignUpLayout';
import PageLayout from 'components/layouts/PageLayout';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce } from 'react-use';
import { Helmet } from 'react-helmet';
import SignUpBusinessForm from 'features/SignUpBusinessForm';
import SignUpBusinessOTP from 'features/SignupBusinessOTP';
import SignUpBusinessPlan from 'features/SignUpBusinessPlan';

const SignUpBusiness = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffectOnce(() => {
    if (!(pathname === '/register-business/')) {
      dispatch({ type: ACTIONS.FETCH_ME });
    }
  });

  return (
    <PageLayout>
      <Helmet>
        <title>Register</title>
        <meta name="description" content="My Flexicar Registration" />
      </Helmet>
      <article className="mt-[70px] md:mt-0">
        <Switch>
          <Route exact path="/register-business">
            <SignUpLayout>
              <SignUpBusinessForm />
            </SignUpLayout>
          </Route>
          <Route exact path="/register-business/otp">
            <SignUpLayout>
              <SignUpBusinessOTP />
            </SignUpLayout>
          </Route>
          <Route exact path="/register-business/plan">
            <SignUpLayout>
              <SignUpBusinessPlan />
            </SignUpLayout>
          </Route>
        </Switch>
      </article>
    </PageLayout>
  );
};

export default SignUpBusiness;
