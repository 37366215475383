import React, { useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import gsap from 'gsap';
import Lottie from 'react-lottie';

import otpVerifiedJson from 'assets/json/icon-otp-verified.json';
import { useRedirectToSignUp } from 'hooks/useRedirect';
import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';
 
 const SignUpOTPVerified = () => {
  const { push } = useHistory();
  const user = useAppSelector(selectUser);
  const component = useRef<HTMLDivElement>(null);
 
  useRedirectToSignUp();
 
  useLayoutEffect(() => {
    gsap.to(component.current, { 
      alpha: 1,
      duration: 0.5,
    });
  }, []);
   
  const iconJsonOptions = {
    loop: false,
    autoplay: true,
    animationData: otpVerifiedJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
 
  return (
    <section className="relative m-auto mt-[-70px] min-h-[calc(100vh)] w-full max-w-[1000px] md:mt-0 md:flex md:min-h-[calc(100vh-388px)]">
      <div className="mx-[25px] flex min-h-[calc(100vh)] flex-auto items-start justify-center pb-[100px] pt-[45px] md:mx-0 md:min-h-full md:justify-start md:pb-0">
        <section
          ref={component}
          className="fadein relative mx-auto flex min-h-[calc(100vh-90px)] w-full max-w-[700px] flex-col md:min-h-full"
        >
          <div className="mx-auto pb-[73px] pt-[105px] md:py-[10px]">
            <Lottie options={iconJsonOptions} height={200} width={200} />
          </div>
          <h2 className="font-primary mx-auto mb-[15px] max-w-[550px] text-center text-[28px] font-[900] uppercase leading-[32px] text-flexi-orange-1 md:mb-[18px] md:text-[28px] md:leading-[50px]">
            mobile verified!
          </h2>
          <p className="grow text-center text-[16px] md:grow-0">
            You can now proceed in <br className="block md:hidden" />
            providing your license details.
          </p>
          <button
            className="btn btn-primary mx-auto mt-auto h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px] md:w-[500px]"
            onClick={() => {
              if (user?.redirectToV2 === "plan-v2") {
                push('/register-v2/plan')
              } else {
                push('/register-v2/ocr');
              }
            }}
          >
            Proceed
          </button>
        </section>
      </div>
    </section>
  );
 };
 
 export default SignUpOTPVerified;