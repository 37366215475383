import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IBroadcastSystem, RequestStatusEnum } from "common/types";

type InitialState = {
  announcement: IBroadcastSystem | null;
  announcementStatus: RequestStatusEnum | null;
}

const initialState: InitialState = {
  announcement: null,
  announcementStatus: null,
}

export const slice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    reset: () => initialState,
    setAnnouncement: (state, action: PayloadAction<IBroadcastSystem | null>) => {
      state.announcement = action.payload;
    },
    setAnnouncementStatus: (state, action: PayloadAction<RequestStatusEnum | null>) => {
      state.announcementStatus = action.payload;
    },
  }
})

export const {
  reset,
  setAnnouncement,
  setAnnouncementStatus,
} = slice.actions

export const selectAnnouncement = (state: RootState): IBroadcastSystem | null =>
  state.announcement.announcement
export const selectAnnouncementStatus = (state: RootState): RequestStatusEnum | null =>
  state.announcement.announcementStatus
export default slice.reducer;