import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  browserName,
  deviceType,
  osName,
  osVersion,
  isBrowser,
  isMobile,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import * as Yup from 'yup';

import flexicarLogo from 'assets/images/flexicar_logo.svg';
import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { IMAGE_URL } from 'common/constants';
import { getErrorValue, removeToken, setDeviceId } from 'common/helpers';
import { InvalidText } from 'components/Typography';
import {
  selectForOnboarding,
  selectLoginError,
  selectPending,
  setForOnboarding,
  setLoginError,
  setLoginModalOpen,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import CustomInput from 'components/CustomInput';
import Modal from 'components/Modal';

type FormValues = {
  email: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  password: Yup.string().required('Password is required'),
});

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectLoginError);
  const isPending = useAppSelector(selectPending);
  const forOnboarding = useAppSelector(selectForOnboarding);
  const [passLVisible, SetPassLVisible] = useState(false);

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  const history = useHistory();
  const deviceId = uuidv4();
  let deviceName = '';

  if (isBrowser) {
    deviceName = osName + ' ' + osVersion + ', ' + browserName;
  } else if (isMobile) {
    deviceName = mobileVendor + ' ' + mobileModel;
  } else {
    deviceName = 'Unknown Device';
  }

  const onSubmit: SubmitHandler<FormValues> = ({ email, password }) => {
    dispatch({
      type: ACTIONS.LOGIN_ACCOUNT,
      payload: {
        email,
        password,
        device_name: deviceName,
        device_id: deviceId,
        device_type: deviceType === 'mobile' ? 'mobile_browser' : deviceType,
      },
    });
    setDeviceId(deviceId);
  };

  const onForgotPassClicked = (e: any) => {
    e.preventDefault();

    removeToken();

    setTimeout(() => {
      history.push({
        pathname: '/auth-v2/forgot-password',
        search: '?reload=true',
      });
      dispatch(setLoginModalOpen(false));
    }, 200);
  };

  const handlePasswordVisible = (e: any) => {
    e.stopPropagation();
    SetPassLVisible(!passLVisible);
  };

  const handleCloseAction = (e?: any) => {
    if (e && (forOnboarding || error)) {
      e.stopPropagation();
      dispatch(setLoginError(null));
      dispatch(setForOnboarding(false));
    }
  };

  const handleMailTo = () => {
    window.location.href = 'mailto:info@flexicar.com.au';
  };

  return (
    <article className="grid place-items-center">
      <div className="flex w-full flex-col">
        <Link
          to="/?reload=true"
          className="mb-[60px] grid w-[200px] place-items-center self-center"
        >
          <img
            src={`${IMAGE_URL}${flexicarLogo}`}
            alt="logo"
            className="object-contain"
          />
        </Link>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col items-center self-center md:w-[350px]"
        >
          <CustomInput title="Email Address" error={errors?.email}>
            <input
              type="text"
              placeholder="email address"
              className={classNames('peer input input-bordered w-full', {
                'border-red-500': errors?.email,
              })}
              {...register('email')}
            />
          </CustomInput>
          <InvalidText>{errors.email?.message}</InvalidText>

          <CustomInput title="Password" error={errors?.password} margin={20}>
            <input
              placeholder="password"
              type={passLVisible ? 'text' : 'password'}
              className={classNames(
                'peer input input-bordered w-full pr-[50px]',
                {
                  'border-red-500': errors?.password,
                },
              )}
              {...register('password')}
            />
            <span
              onClick={handlePasswordVisible}
              className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
            >
              {passLVisible ? (
                <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
              ) : (
                <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
              )}
            </span>
          </CustomInput>
          <InvalidText>{errors.password?.message}</InvalidText>
          {error?.email && <InvalidText>{getErrorValue(error)}</InvalidText>}
          {typeof error === 'string' && !error.includes('inactive') && (
            <InvalidText>{error}</InvalidText>
          )}
          <a
            href="/"
            className="mt-[25px] block text-center text-[12px] font-bold md:hidden"
            onClick={onForgotPassClicked}
          >
            Forgot Password?
          </a>

          <button
            type="submit"
            className={classNames(
              'btn btn-primary mt-[30px] h-[40px] w-full rounded-full capitalize disabled:text-white md:btn-lg md:h-[60px] md:w-[350px]',
              {
                loading: isPending,
              },
            )}
            disabled={isPending}
          >
            Sign in
          </button>
          <div className="mt-[25px] flex h-full items-center justify-center gap-[13px] text-[12px] font-semibold md:mt-[50px] md:text-[14px]">
            <a
              href="/"
              className="hidden text-center md:block"
              onClick={onForgotPassClicked}
            >
              Forgot Password?
            </a>
            <span className="hidden h-[30px] w-[1px] bg-zinc-200 md:block"></span>

            <p className="text-center ">
              New to Flexicar?{' '}
              <Link
                to="/register-v2?reload=true"
                className="font-bold text-primary"
                onClick={() => dispatch(setLoginModalOpen(false))}
              >
                Join Now
              </Link>
            </p>
          </div>
        </form>
      </div>

      <Modal
        isModalOpen={error?.includes('inactive')}
        handleClose={handleCloseAction}
        width="750px"
        isErrorModal={true}
        hasCloseButton={false}
      >
        <h1 className="title mb-[40px] text-center text-[20px] font-bold md:text-[34px]">
          Action required
        </h1>
        <p className="text-left">
          Account is inactive, please contact Flexicar for reactivation
          <br />
          <br />
          <a href="tel:1300363780" className="font-bold">
            t: 1300 36 37 80
          </a>
          <br />
          <span onClick={handleMailTo} className="cursor-pointer font-bold">
            e: info@flexicar.com.au
          </span>
        </p>

        <p
          className="button btn rounded-full border-none"
          onClick={handleCloseAction}
        >
          Ok
        </p>
      </Modal>
      <Modal
        isModalOpen={forOnboarding}
        handleClose={handleCloseAction}
        width="750px"
        isErrorModal={true}
        hasCloseButton={false}
      >
        <h1 className="title mb-[40px] text-center text-[20px] font-bold md:text-[34px]">
          Action required
        </h1>
        <p className="text-center">
          It looks like we are missing some information on your Flexicar
          profile. Please check your email for further&nbsp;instructions.
        </p>
        <p
          className="button btn rounded-full border-none"
          onClick={handleCloseAction}
        >
          Ok
        </p>
      </Modal>
    </article>
  );
};

export default LoginForm;
