import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IFavoriteLocation, RequestStatusEnum } from 'common/types';

type InitialState = {
  pendingFavoriteLocationList: boolean;
  pendingUpdateFavoriteLocation: boolean;
  favoriteLocationList: IFavoriteLocation[] | [];
  updateFavoriteLocationStatus: RequestStatusEnum | null;
  favoriteLocationListStatus: RequestStatusEnum | null;
  updateId: string | null;
};

const initialState: InitialState = {
  pendingFavoriteLocationList: false,
  pendingUpdateFavoriteLocation: false,
  favoriteLocationList: [],
  updateFavoriteLocationStatus: null,
  favoriteLocationListStatus: null,
  updateId: null,
};

export const slice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    reset: () => initialState,
    setPendingFavoriteLocationList: (state, action: PayloadAction<boolean>) => {
      state.pendingFavoriteLocationList = action.payload;
    },
    setPendingUpdateFavoriteLocation: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.pendingUpdateFavoriteLocation = action.payload;
    },
    setFavoriteLocationList: (
      state,
      action: PayloadAction<IFavoriteLocation[]>,
    ) => {
      state.favoriteLocationList = action.payload;
    },
    setUpdateFavoriteLocationStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.updateFavoriteLocationStatus = action.payload;
    },
    setFavoriteLocationListStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.favoriteLocationListStatus = action.payload;
    },
    setUpdateId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.updateId = action.payload;
    },
  },
});

export const {
  reset,
  setPendingFavoriteLocationList,
  setPendingUpdateFavoriteLocation,
  setFavoriteLocationList,
  setUpdateFavoriteLocationStatus,
  setFavoriteLocationListStatus,
  setUpdateId,
} = slice.actions;

export const selectPendingFavoriteLocationList = (state: RootState): boolean =>
  state.favorites.pendingFavoriteLocationList;
export const selectPendingUpdateLocationFavorites = (
  state: RootState,
): boolean => state.favorites.pendingUpdateFavoriteLocation;
export const selectFavoriteLocationList = (
  state: RootState,
): IFavoriteLocation[] | [] => state.favorites.favoriteLocationList;
export const selectUpdateFavoriteLocationStatus = (
  state: RootState,
): RequestStatusEnum | null => state.favorites.updateFavoriteLocationStatus;
export const selectFavoriteLocationListStatus = (
  state: RootState,
): RequestStatusEnum | null => state.favorites.favoriteLocationListStatus;
export const selectUpdateId = (
  state: RootState,
): string | null => state.favorites.updateId;

export default slice.reducer;
