import React, { useState, useRef, useLayoutEffect } from 'react';
import { useEffectOnce, useUnmount } from 'react-use';
import { Link } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import gsap from 'gsap';

import { RequestStatusEnum } from 'common/types';
import {
  selectPaymentCards,
  selectUser,
  selectUserPlan,
  selectUserPlanStatus,
  selectUserStatus,
} from 'modules/me/slice';
import { selectBilling, setApplyPromoStatus } from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import LoaderText from 'components/LoaderText';
import CustomInput from 'components/CustomInputSignUp';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import PaymentInfo from './PaymentInfo';
import PaymentMethod from './PaymentMethod';
import PaymentPromotions from './PaymentPromotions';
import PayAndFinish from './PayAndFinish';
import CustomCheckBox from 'components/CustomCheckbox';

const SignUpPayment = () => {
  const { isBusinessAccount, isBusinessManagerAccount } = useBusiness();
  const [referralEmail] = useState<string | undefined>('');
  const [vffMembershipId, setVffMembershipId] = useState<string | undefined>(
    '',
  );
  const [partnerMembershipId, setPartnerMembershipId] = useState<
    string | undefined
  >('');
  const [accessCode, setAccessCode] = useState<string | undefined>('');
  const [partner, setPartner] = useState<string | undefined>('');
  const [hasReduceExcessDamageCover, setHasReduceExcessDamageCover] = useState<
    string | undefined
  >(undefined);
  const [referralEmailError] = useState<string | null>(
    null,
  );
  const [partnerMembershipError, setPartnerMembershipError] = useState<
    string | null
  >(null);
  const dispatch = useAppDispatch();
  const billing = useAppSelector(selectBilling);
  const paymentCards = useAppSelector(selectPaymentCards);
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus);
  const isPersonalAccount = user?.type.name.toLocaleLowerCase() === 'personal';
  const userPlanStatus = useAppSelector(selectUserPlanStatus);
  const allStatusPending =
    userStatus === RequestStatusEnum.PENDING ||
    userPlanStatus === RequestStatusEnum.PENDING;

  const handleCheckBox = (e: any) => {
    setHasReduceExcessDamageCover(e.target.checked ? 'on' : '');
  };
  const userPlan = useAppSelector(selectUserPlan);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_BILLING });
    dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
    dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
    dispatch({ type: ACTIONS.FETCH_REWARD_PARTNERS });
    dispatch({ type: ACTIONS.FETCH_USER_PLAN });
  });

  useUnmount(() => {
    dispatch(setApplyPromoStatus(null));
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex w-full max-w-[700px] flex-col items-center pt-0 text-[#282828] md:pt-[43px]"
    >
      <h2 className="font-primary mb-[30px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:mb-[40px] md:text-[28px]">
        payment set up
      </h2>

      <div className="mb-[20px] flex w-full items-center justify-between pb-[10px] md:mb-[40px] md:border-b-[1px] md:border-b-[#CCCCCC] md:pb-[40px]">
        {allStatusPending ? (
          <LoaderText height="90" width="50%" />
        ) : !isMobile && isBusinessManagerAccount ? (
          <div className="flex w-full">
            <div className="my-auto">
              <p className={classNames('text-[26px] font-bold capitalize')}>
                Flexi {user?.type.name}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex w-full rounded-[10px] bg-flexi-orange-1 px-[20px] text-white md:p-[40px]">
            <div className="my-auto">
              <p className={classNames('text-[16px] font-bold capitalize')}>
                {user?.type.name}
                <br />
                {isPersonalAccount
                  ? `Flexi ${user?.plan?.name
                      .toLocaleLowerCase()
                      .replaceAll(`${user.type.name}`, '')}`
                  : `Flexi ${user?.type.name}`}
              </p>
              {isPersonalAccount &&
                user?.type.name.toLocaleLowerCase() !== 'student' && (
                  <Link
                    to="/register-v2/plan-personal"
                    className="italize text-[12px] font-bold text-[#171717] md:text-[16px]"
                  >
                    Change plan
                  </Link>
                )}
            </div>
            <div className="ml-auto">
              <p className="text-center text-[70px] font-light md:text-[40px] md:font-[500]">
                ${userPlan?.plan.annualFee || userPlan?.plan.fee.toFixed()}
              </p>
              <p className="hidden text-center text-[10px] md:block">*Yearly</p>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full gap-14">
        <div className="flex w-full flex-col">
          <div className="mb-[30px] flex flex-col items-start">
            <p className="mb-[25px] text-[16px] font-bold md:mb-[20px] md:text-[22px] md:font-normal">
              Add-ons
            </p>
            <CustomCheckBox
              size={10}
              titleElement={
                <p className="mt-[-10px] max-w-[70vw] text-[14px] md:mt-0">
                  Reduce the excess on your damage cover &#40;
                  <a
                    href="/terms-and-conditions"
                    target="__blank"
                    className="font-bold text-flexi-orange-1"
                  >
                    T&C&apos;s
                  </a>{' '}
                  and additional fees apply&#41;.
                </p>
              }
            >
              <input
                type="checkbox"
                className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px] !bg-white"
                // @ts-ignore
                style={{ '--chkfg': '0', '--chkbg': '0' }}
                onChange={(e) => handleCheckBox(e)}
              />
            </CustomCheckBox>
          </div>
          {allStatusPending ? (
            <LoaderText height="30px" width="100px" />
          ) : (
            !isBusinessAccount && (
              <>
                <PaymentPromotions
                  vffMembershipId={vffMembershipId}
                  setVffMembershipId={setVffMembershipId}
                  partnerMembershipId={partnerMembershipId}
                  setPartnerMembershipId={setPartnerMembershipId}
                  accessCode={accessCode}
                  setAccessCode={setAccessCode}
                  partner={partner}
                  billing={billing}
                  setPartner={setPartner}
                  partnerMembershipError={partnerMembershipError}
                  setPartnerMembershipError={setPartnerMembershipError}
                  />
                <div className="mb-8"></div> {/* Add space between components */}
                <PaymentInfo billing={billing} />
              </>
            )
          )}

          {isBusinessAccount && (
            <div className="form-control mb-[30px] w-full gap-[15px] md:gap-[20px]">
              <label className="text-[20px] font-bold md:text-[22px] md:font-normal">
                Access Code
              </label>
              <CustomInput title="Access Code">
                <input
                  placeholder="velocity number"
                  type="text"
                  className={classNames(
                    'peer input input-bordered w-full focus:outline-none',
                  )}
                  value={accessCode}
                  onChange={(e) => setAccessCode(e.target.value)}
                  autoComplete="off"
                />
              </CustomInput>
            </div>
          )}
          <PaymentMethod paymentCards={paymentCards} />

          <PayAndFinish
            referralEmail={referralEmail}
            vffMembershipId={vffMembershipId}
            accessCode={accessCode}
            partner={partner}
            partnerMembershipId={partnerMembershipId}
            hasReduceExcessDamageCover={hasReduceExcessDamageCover}
            referralEmailError={referralEmailError}
            setPartnerMembershipError={setPartnerMembershipError}
          />
        </div>
      </div>
    </section>
  );
};

export default SignUpPayment;
