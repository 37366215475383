import React, { ReactNode } from 'react';
// import SwitchAccountNav from 'components/SwitchAccountNav';
import { ReactComponent as IconLine1 } from 'assets/images/splash_line1.svg';
import { ReactComponent as IconLine2 } from 'assets/images/splash_line2.svg';
import BgCar from 'assets/images/splash_car.png';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { IMAGE_URL } from 'common/constants';

interface Props {
  children: ReactNode;
}
const AddAccountLayout = ({ children }: Props) => {

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return isMobile ? (
    <section
      className={classNames(
        'relative m-auto min-h-[100vh] w-full bg-[#F2F2F2]',
      )}
    >
      <div className="mx-[25px] flex min-h-[calc(100vh-200px)] flex-auto items-start justify-center pb-[25px] pt-[0px]">
        {children}
      </div>
    </section>
  ) : (
    <div className="relative w-full">
      <section className="min-h-[calc(100vh)] w-full bg-flexi-orange-1 !bg-fixed py-[5px]">
        <div></div>
        <div
          className="absolute left-[2%] top-[50vh] z-[1] hidden h-[200px] w-[380px] bg-center bg-no-repeat xl:block xl:left-[9%]"
          style={{
            backgroundImage: `url(${IMAGE_URL}${BgCar})`,
            backgroundSize: '100%',
          }}
        />
        <p className="font-primary absolute left-[5%] top-[35vh] z-[2] hidden text-center text-[50px] font-[900] uppercase leading-[60px] text-white xl:block xl:left-[10%]">
          get ready
          <br />
          to drive!
        </p>
        <div className="absolute left-[-20px] top-[45px] z-[1] hidden w-[60%] min-w-[745px] xl:block xl:left-[-5px]">
          <IconLine1 width="100%" height="auto" />
        </div>
        <div className="absolute left-[calc(14vw+250px)] top-[45vh] hidden w-[30%] min-w-[300px] xl:block">
          <IconLine2 width="100%" height="auto" />
        </div>
        <aside className="mx-auto relative z-[60] ml-auto lg:mr-[145px] mt-[110px] w-[675px] rounded-[20px] bg-[#F2F2F2] px-[80px] pb-[80px] pt-[42px]">
          <div
            className={classNames(
              '-translate-x-1-2 absolute left-[1/2] top-[40px] w-[515px]',
              {
              },
            )}
          >
          </div>
          {children}
        </aside>
      </section>
    </div>
  );
};

export default AddAccountLayout;
