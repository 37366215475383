import React, { useState, useRef } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useClickAway } from 'react-use';
import { BiCurrentLocation } from 'react-icons/bi';
import {
  selectLocationAddress,
  setLocationAddress,
  setMapCenter,
  setMapView
} from 'modules/booking/slice';
import useFavorites from 'hooks/useFavorites';
import { ReactComponent as Location } from 'assets/images/location.svg';
import useBooking from 'hooks/useBooking';
import { ReactComponent as Star } from 'assets/images/star.svg';
import classNames from 'classnames';
import {
  getStorageRecentSearches,
  setStorageRecentSearches,
} from 'common/helpers';
import { ReactComponent as Recent } from 'assets/images/recent.svg';
import { IFavoriteLocation, IRecentSearch } from 'common/types';
import {
  setPodTimezone,
  setVehiclesInPod } from 'modules/pods/slice';
import tzlookup from 'tz-lookup';

type HandleRecentSearchesProps = {
  address: string;
  latlng: any;
};

function LocationField() {
  const dispatch = useAppDispatch();
  const { getCurrentLocation } = useBooking({});
  const locationAddress = useAppSelector(selectLocationAddress);
  const [isDropdownActive, setDropdownActive] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const { favorites } = useFavorites();
  const recentSearches = getStorageRecentSearches();

  const handleRecentSearches = ({
    address,
    latlng,
  }: HandleRecentSearchesProps) => {
    const recentSearchValueExist = recentSearches.find(
      (i) => i.formattedAddress === address,
    );
    const recentSearchValueExistIndex = recentSearches.findIndex(
      (i) => i.formattedAddress === address,
    );

    if (!recentSearchValueExist) {
      setStorageRecentSearches(
        JSON.stringify([
          {
            latitude: latlng.lat,
            longitude: latlng.lng,
            formattedAddress: address,
          },
          ...recentSearches,
        ]),
      );
    } else {
      recentSearches.splice(recentSearchValueExistIndex, 1);
      setStorageRecentSearches(
        JSON.stringify([
          {
            latitude: latlng.lat,
            longitude: latlng.lng,
            formattedAddress: address,
          },
          ...recentSearches,
        ]),
      );
    }
  };

  const handleSelectAutocomplete = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latlng = await getLatLng(results[0]);

    setDropdownActive(false);
    dispatch(setMapCenter({ lat: latlng.lat, lng: latlng.lng }));
    dispatch(setLocationAddress(value));
    handleRecentSearches({ address: value, latlng });
    dispatch(setVehiclesInPod([]));
    dispatch(setMapView(true))
    dispatch(
      setPodTimezone(
        tzlookup(Number(latlng.lat), Number(latlng.lng)),
      ),
    );
    // dispatch(setCurrentZoom(11));
  };

  const handleSelectRecentSearch = (recentSearch: IRecentSearch) => {
    dispatch(
      setMapCenter({
        lat: recentSearch.latitude,
        lng: recentSearch.longitude,
      }),
    );
    dispatch(
      setPodTimezone(
        tzlookup(Number(recentSearch.latitude), Number(recentSearch.longitude)),
      ),
    );
    dispatch(setLocationAddress(recentSearch.formattedAddress));
    handleRecentSearches({
      address: recentSearch.formattedAddress,
      latlng: { lat: recentSearch.latitude, lng: recentSearch.longitude },
    });
    setDropdownActive(false);
    dispatch(setVehiclesInPod([]));
    dispatch(setMapView(true))
    // dispatch(setCurrentZoom(11));
  };

  const handleSelectFavorite = (favorite: IFavoriteLocation) => {
    dispatch(
      setMapCenter({
        lat: Number(favorite.latitude),
        lng: Number(favorite.longitude),
      }),
    );
    dispatch(
      setPodTimezone(
        tzlookup(Number(favorite.latitude), Number(favorite.longitude)),
      ),
    );
    dispatch(setLocationAddress(favorite.name));
    setDropdownActive(false);
    dispatch(setVehiclesInPod([]));
    dispatch(setMapView(true))
    // dispatch(setCurrentZoom(11));
  };

  useClickAway(dropdownRef, () => {
    setDropdownActive(false);
  });

  return (
    <div className="flex w-full flex-auto items-center border-b-2 border-solid border-zinc-200 lg:w-auto">
      <Location />
      <PlacesAutocomplete
        value={locationAddress || ''}
        onChange={(e) => dispatch(setLocationAddress(e))}
        onSelect={handleSelectAutocomplete}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className="flex w-full flex-col">
              <input
                {...getInputProps({
                  placeholder: 'Street, Suburb or Place',
                })}
                className={`input bg-transparent px-0 !pl-2 text-[0.75rem] lg:text-[0.875rem] font-[500] focus:mx-0 focus:px-0 focus:outline-transparent`}
                onClick={() => setDropdownActive(true)}
              />
              {isDropdownActive && (
                <div
                  ref={dropdownRef}
                  className="absolute left-[50%] top-[80px] lg:top-[80px] z-10 flex w-full translate-x-[-50%] flex-col items-start justify-around bg-white p-5 shadow-lg lg:w-[65%] lg:flex-row lg:p-[50px]"
                >
                  <div
                    className="z-100 flex w-fit cursor-pointer items-center gap-2"
                    onClick={() => {
                      getCurrentLocation();
                      setDropdownActive(false);
                      dispatch(setVehiclesInPod([]));
                    }}
                  >
                    <BiCurrentLocation fill="#F2841C" />
                    <p className="text-[14px] font-bold">
                      Use my current location
                    </p>
                  </div>
                  {favorites.length > 0 && suggestions.length === 0 ? (
                    <div className="flex flex-col gap-[20px]">
                      <strong className="block text-[14px] font-bold">
                        Select from favorite locations
                      </strong>
                      <div className="flex flex-col gap-[10px]">
                        {favorites.map((favorite) => (
                          <div
                            key={favorite.id}
                            className="flex max-w-[325px] items-center gap-[10px]"
                          >
                            <Star
                              fill="#F2841C"
                              className="min-h-[14px] min-w-[14px]"
                            />
                            <p
                              className="truncate text-[14px]"
                              role="button"
                              onClick={() => handleSelectFavorite(favorite)}
                            >
                              {favorite.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {recentSearches.length > 0 && suggestions.length === 0 ? (
                    <div className="flex flex-col gap-[20px]">
                      <strong className="block text-[14px] font-bold">
                        Recent Searches
                      </strong>
                      <div className="flex flex-col gap-[10px]">
                        {recentSearches
                          .slice(0, 5)
                          .map((recentSearch, index) => (
                            <div
                              key={index}
                              className="flex max-w-[325px] items-center gap-[10px]"
                            >
                              <Recent
                                fill="#F2841C"
                                className="min-h-[14px] min-w-[14px]"
                              />
                              <p
                                className=" truncate text-[14px]"
                                role="button"
                                onClick={() =>
                                  handleSelectRecentSearch(recentSearch)
                                }
                              >
                                {recentSearch.formattedAddress}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : null}
                  {loading && <p className="w-full pt-3">Loading...</p>}
                  <div className="flex flex-col">
                    {suggestions.map((suggestion, i: number) => {
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion)}
                          style={{ width: '100%' }}
                          key={i}
                          className="mb-2 flex w-full flex-col"
                        >
                          <p
                            onClick={handleSelectAutocomplete}
                            className={classNames(
                              'w-full cursor-pointer text-[14px] hover:underline',
                              {
                                underline: suggestion.active,
                              },
                            )}
                            role="button"
                          >
                            {suggestion.description}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    </div>
  );
}

export default LocationField;
