import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { useRedirectToRegistration } from 'hooks/useRedirect';
import useAppSelector from 'hooks/useAppSelector';
import { selectPending as selectPendingOcr } from 'modules/ocr/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import VerificationContent from './VerificationContent';
// import VerificationLoader from './VerificationLoader';
import PendingLoader from './PendingLoader';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce } from 'react-use';
// import useBusiness from 'hooks/useBusiness';
import { selectUser, selectUserStatus } from 'modules/me/slice';
import { RequestStatusEnum } from 'common/types';
import gsap from 'gsap';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import { useHistory } from 'react-router-dom';

const RegisterVerification = () => {
  const dispatch = useAppDispatch();
  const isPendingOcr = useAppSelector(selectPendingOcr);
  // const { isBusinessAccount } = useBusiness();
  const userStatus = useAppSelector(selectUserStatus);
  const user = useAppSelector(selectUser);
  const { push } = useHistory();
  const component = useRef<HTMLDivElement>(null);
  // const [ocrStatus, setOcrStatus] = useState<String | null>(null);

  useRedirectToRegistration();

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const [value] = useDocument(
    user?.ocrId ? doc(getFirestore(firebaseApp), 'ocr', user.ocrId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    /* eslint-disable */
    console.log('val data:', value);
    console.log('ocr id:', user?.ocrId);
    if (value) {
    console.log('ocr meta:', value.data());
      // console.log('ocr meta redirect:', value.data()?.redirectTo);
      // console.log('ocr firebase:', JSON.stringify(value.data()));
    }
    /* eslint-enable */

    if (
      user &&
      user.redirectTo!=='verification' &&
      value && // value exist
      JSON.stringify(value.data()) // value !== undefined
    ) {
      // const redirectFull: any = Object.values(
      //   JSON.parse(JSON.stringify(value.data())),
      // );
      // const redirect: any = Object.values(
      //   JSON.parse(JSON.stringify(value.data())),
      // )[0];
      const redirectTo = value.data()?.redirectTo;
      const status = value.data()?.event;
      // console.log('ocr status:', status)
      // if (status) setOcrStatus(status);
      // console.log('redirect object:', redirectFull)
      // console.log('redirecting to.. ', redirect)
      if (redirectTo.toLocaleLowerCase().includes('information'))
        push(`/register/${redirectTo}`);
      if (status === 'COMPLETED_PASS' || status === 'COMPLETED_FLAGGED')
        push(`/register/information`);
      // else if(redirectTo.toLocaleLowerCase() === 'app')
      //   push(`/app/booking`);
    }
  }, [value, user]);

  useEffectOnce(() => {
    // if (!isBusinessAccount) {
      dispatch({ type: ACTIONS.FETCH_QR_CODE });
    // }
  });

  // remove checking if status is flagged. should redirect to getting/checking driver
  const renderVerificationContent = () => {
    // if (ocrStatus?.toLocaleLowerCase() === 'completed_flagged') {
    //   return (
    //     <section
    //       ref={component}
    //       className="fadein flex w-full max-w-[600px] flex-col"
    //     >
    //       {userStatus === RequestStatusEnum.PENDING ? (
    //         <span className="h-[50px] max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] animate-spin rounded-full border-[3px] border-t-transparent" />
    //       ) : (
    //         <>
    //           <h2 className="font-primary mb-[36px] text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]">
    //             Your Application is&nbsp;Pending
    //           </h2>
    //           <p className="mb-[16px] text-[18px]">
    //             Feel free to contact us at t: 1300 36 37 80 or
    //             info@flexicar.com.au for any&nbsp;concerns.
    //           </p>
    //           {/* <button className="button btn mt-[25px] rounded-full border-none bg-flexi-green-1 hover:bg-flexi-green-2">
    //             ok
    //           </button> */}
    //           {/* {isPendingOcr ? (
    //         // <PendingLoader />
    //       ) : (
    //         // ocrTransaction?.status === OcrStatusEnum.INPROGRESS ? (
    //         //   <VerificationLoader />
    //         // ) :
    //         // <VerificationContent />
    //       )} */}
    //         </>
    //       )}
    //     </section>
    //   );
    // }
    return (
      <section
        ref={component}
        className="fadein flex w-full max-w-[600px] flex-col"
      >
        {userStatus === RequestStatusEnum.PENDING ? (
          <span className="h-[50px] max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] animate-spin rounded-full border-[3px] border-t-transparent" />
        ) : (
          <>
            <h2 className="font-primary mb-[36px] text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]">
              verify your id
            </h2>
            {isPendingOcr ? (
              <PendingLoader />
            ) : (
              // ocrTransaction?.status === OcrStatusEnum.INPROGRESS ? (
              //   <VerificationLoader />
              // ) :
              <VerificationContent />
            )}
          </>
        )}
      </section>
    );
  };

  return (
    <>{renderVerificationContent()}</>

    // <section
    //   ref={component}
    //   className="fadein flex w-full max-w-[600px] flex-col"
    // >
    //   {userStatus === RequestStatusEnum.PENDING ? (
    //     <span className="h-[50px] max-h-[50px] min-h-[50px] min-w-[50px] max-w-[50px] animate-spin rounded-full border-[3px] border-t-transparent" />
    //   ) : (
    //     <>
    //       <h2 className="font-primary mb-[36px] text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]">
    //         verify your id
    //       </h2>
    //       {isPendingOcr ? (
    //         <PendingLoader />
    //       ) : (
    //         // ocrTransaction?.status === OcrStatusEnum.INPROGRESS ? (
    //         //   <VerificationLoader />
    //         // ) :
    //         <VerificationContent />
    //       )}
    //     </>
    //   )}
    // </section>
  );
};

export default RegisterVerification;
