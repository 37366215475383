import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

import {
  isSupportedCountry,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { FcCheckmark } from 'react-icons/fc';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';
import { useJsApiLoader } from '@react-google-maps/api';
import Input from 'react-phone-number-input/input';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';
import * as Yup from 'yup';

import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { ReactComponent as IconSearch } from 'assets/images/search2.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { GOOGLE_API } from 'common/constants';
import { ICountryCode, RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import {
  selectAbrLookup,
  selectAbrLookupStatus,
  selectEmailVerified,
  selectError,
  selectPendingAbr,
  selectPendingVerifyEmail,
  selectRegisterStatus,
  setAbrLookup,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import COUNTRIES from 'common/countries';
import CustomCheckBox from 'components/CustomCheckbox';
import CustomInput from 'components/CustomInputSignUp';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import BusinessAddressComplete from './BusinessAddress';
import BillingAddressAutoComplete from './BillingAddress';

interface Props {
  accountType: number;
}

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  businessCode: string;
  businessName: string;
  businessAddress: string;
  businessCountry: string;
  businessBillingAddress: string;
  businessBillingCountry: string;
  email: string;
  contact: string;
  contactCountry: string;
  firstname: string;
  lastname: string;
  password: string;
  passwordConfirmation: string;
  isSameBilling: number;
  isMailSubscriber: boolean;
};

const validationSchema = Yup.object().shape({
  businessCode: Yup.string().required('ABN or ACN is required'),
  businessName: Yup.string().required('Business name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  password: Yup.string()
    .required('')
    .matches(/\d/, '')
    .matches(/[a-z]/, '')
    .matches(/[A-Z]/, '')
    .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
    .min(8),
  passwordConfirmation: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  contact: Yup.string().required('Mobile number is required'),
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
});

const Form = ({ accountType }: Props) => {
  const dispatch = useAppDispatch();
  const registerStatus = useAppSelector(selectRegisterStatus);
  const isPendingVerifyEmail = useAppSelector(selectPendingVerifyEmail);
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const abrLookUpStatus = useAppSelector(selectAbrLookupStatus);
  const isPendingAbr = useAppSelector(selectPendingAbr);
  const abrLookup = useAppSelector(selectAbrLookup);
  const error = useAppSelector(selectError);
  const [businessCountryInput, setBusinessCountryInput] = useState('au');
  const [businessNameError, setBusinessNameError] = useState(false);
  const [passLVisible, setPassLVisible] = useState(false);
  const [passRVisible, setPassRVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [phone, setPhone] = useState<any>('');
  const [phoneCode, setPhoneCode] = useState<any>('AU');
  const [isInternational, setIsInternational] = useState(false);
  const [libraries] = useState<any>(['places']);
  const [permanentAddressAutoLocation, setPermanentAddressAutoLocation] =
    useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessAddressCountry, setBusinessAddressCountry] = useState('');
  const [isBillingChecked, setBillingChecked] = useState(false);
  const [localAddressAutoLocation, setLocalAddressAutoLocation] = useState('');
  const [validLocalAddress, setValidLocalAddress] = useState('');
  const [validLocalAddressCountry, setValidLocalAddressCountry] = useState('');

  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    setValue,
    getValues,
  } = useForm<FormValues>(formOptions);

  const businessCode = useWatch({ control, name: 'businessCode' });

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const isErrorEmail =
    errors?.email || error?.email || error === 'email_invalid';

  const handleMobileSelectChange = (e: any) => {
    if (e.target.value !== 'international') {
      setPhoneCode(e.target.value);
      setPhone('');
      setIsInternational(false);
    } else {
      setIsInternational(true);
    }
  };

  const handleContinue = () => {
    dispatch({
      type: ACTIONS.GET_ABR_LOOKUP,
      payload: { code: businessCode.replaceAll(' ', '') },
    });
  };

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API,
    libraries,
  });

  const handleBusinessAddressAutocomplete = async (value: any) => {
    setPermanentAddressAutoLocation(value);
    setBusinessAddress(value);
    const n = value.lastIndexOf(',');
    const country = value.substring(n + 1).trim();
    setBusinessAddressCountry(country);
  };
  const handleBusinessAddressChange = (e: any) => {
    setPermanentAddressAutoLocation(e);
  };
  const handleAustraliaAddressAutocomplete = async (value: any) => {
    const n = value.lastIndexOf(',');
    setLocalAddressAutoLocation(value);
    setValidLocalAddress(value);
    setValidLocalAddressCountry(value.substring(n + 1).trim());
  };
  const handleAustraliaAddressChange = (e: any) => {
    setLocalAddressAutoLocation(e);
  };

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const payload = {
      ...values,
      contactCountry: phoneCode,
      businessAddress,
      businessCountry: businessAddressCountry,
      businessBillingAddress: validLocalAddress,
      businessBillingCountry: validLocalAddressCountry,
      isSameBilling: isBillingChecked ? 0 : 1,
      isMailSubscriber: values.isMailSubscriber ? 1 : 0,
    };
    if (getValues('businessName') !== 'none') {
      setBusinessNameError(false);
      if (registerStatus !== RequestStatusEnum.PENDING) {
        dispatch({ type: ACTIONS.REGISTER_BUSINESS_ACCOUNT_V2, payload });
      }
    } else {
      setBusinessNameError(true);
    }
  };

  useEffect(() => {
    setValue('contact', phone);
  }, [phone]);

  return isMapLoaded ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames('grid grid-cols-1 gap-y-[24px]', {
        '!gap-y-[16px]': !isMobile,
      })}
    >
      <div>
        <CustomInput title="Country" positionTitle="pl-[58px]">
          <select
            className={classNames(
              'select select-bordered w-full pl-[75px] font-normal',
              {
                'border-red-500': errors?.businessCountry,
              },
            )}
            {...register('businessCountry')}
            defaultValue={businessCountryInput}
            onChange={(e) => setBusinessCountryInput(e.target.value)}
          >
            {COUNTRIES.map((c) => (
              <option value={c.code.toLowerCase()} key={c.code}>
                {c.name}
              </option>
            ))}
          </select>

          <div className="pointer-events-none absolute left-[10px] top-[0] z-10 ml-[10px] flex h-full w-[25px] items-center justify-center gap-[5px] bg-transparent [&>img]:!h-[25px] [&>img]:!w-[25px]">
            <ReactCountryFlag
              className=" w-full  rounded-full object-cover"
              countryCode={businessCountryInput}
              svg
            />
          </div>

          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="pointer-events-none absolute left-[40px] z-[10] grid h-[90%] w-[40px] place-items-center"
          >
            <IconArrow className="w-[10px]" />
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="pointer-events-none absolute right-1 z-[10] grid h-[90%] w-[40px] place-items-center bg-white"
          ></span>
        </CustomInput>
        {!isEmpty(errors.businessCountry) && (
          <InvalidText>{errors.businessCountry?.message}</InvalidText>
        )}
      </div>

      <div className="form-control w-full flex-1">
        <CustomInput title="ABN or ACN" error={errors.businessCode}>
          <input
            type="text"
            placeholder="abn or acn"
            className={classNames(
              'peer input input-bordered w-full pr-[50px]',
              {
                'border-red-500': errors?.businessCode,
              },
            )}
            {...register('businessCode', {
              onBlur: async (e) => {
                if (!e.target.value) {
                  dispatch(setAbrLookup(null));
                } else handleContinue();
              },
            })}
          />
          <span
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="pointer-events-none absolute right-1  grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg "
          >
            {isPendingAbr ? (
              <span className="min-h-[20px] min-w-[20px] animate-spin rounded-full border-[2px] border-flexi-black-4 border-t-transparent" />
            ) : (
              <IconSearch
                onClick={() => {
                  handleContinue();
                }}
                className="pointer-events-auto"
              />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.businessCode) && (
          <InvalidText>{errors.businessCode?.message}</InvalidText>
        )}
        {abrLookUpStatus === RequestStatusEnum.FAILED && (
          <InvalidText>ABN/ACN not found.</InvalidText>
        )}
      </div>

      <div className="form-control w-full flex-1">
        <CustomInput
          title="Business Name"
          // handleActive={() => setBusinessNameActive(true)}
          error={errors.businessName || businessNameError}
          disabled={!abrLookup}
        >
          <>
            <select
              placeholder="business name"
              className={classNames(
                'peer select select-bordered w-full font-normal',
                {
                  'border-red-500': errors?.businessName || businessNameError,
                  '!pointer-events-none border-flexi-black-7': !abrLookup,
                },
              )}
              {...register('businessName')}
              // disabled={!abrLookup}
              tabIndex={-1}
              defaultValue={abrLookup ? 0 : 'none'}
            >
              {abrLookup?.businessName.length !== undefined &&
              abrLookup?.businessName.length > 0 ? (
                <>
                  <option value={'none'} disabled></option>
                  {abrLookup?.businessName.map((i, index) => (
                    <option value={i} key={index}>
                      {i}
                    </option>
                  ))}
                </>
              ) : (
                <>
                  <option value={'none'} disabled></option>
                  <option value={abrLookup?.entityName}>
                    {abrLookup?.entityName}
                  </option>
                </>
              )}
            </select>
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
            >
              <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
            </span>
          </>
        </CustomInput>

        {!isEmpty(errors.businessName) && (
          <InvalidText>{errors.businessName?.message}</InvalidText>
        )}
        {businessNameError && (
          <InvalidText>Business name is required</InvalidText>
        )}
      </div>

      <div>
        <CustomInput
          title="Business Address"
          error={businessAddress === '' && isSubmitButtonClicked}
          disabled={!abrLookup}
        >
          <input
            type="text"
            className="hidden"
            value={businessAddress}
            {...register('businessAddress')}
          />
          <BusinessAddressComplete
            autoLocation={permanentAddressAutoLocation}
            handleSelectAutocomplete={handleBusinessAddressAutocomplete}
            onChange={handleBusinessAddressChange}
            error={businessAddress === '' && isSubmitButtonClicked}
            country={businessCountryInput}
          />
        </CustomInput>
        {businessAddress === '' && isSubmitButtonClicked && (
          <InvalidText>Valid business address is required</InvalidText>
        )}
      </div>

      <label className="mt-[24px] flex w-full cursor-pointer flex-row items-center">
        <CustomCheckBox
          titleElement={
            <p className="text-[12px] sm:text-[14px]">
              My billing address is different from my business&nbsp;address
            </p>
          }
        >
          <input
            type="checkbox"
            className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
            // @ts-ignore
            style={{ '--chkfg': '0', '--chkbg': '0' }}
            checked={isBillingChecked}
            onChange={() => setBillingChecked(!isBillingChecked)}
          />
        </CustomCheckBox>
      </label>

      {isBillingChecked && (
        <div
          className="
          "
          // ml-[-25px] w-[calc(100%+50px)] bg-white px-[25px] py-[40px]
        >
          <CustomInput
            title="Billing Address"
            error={errors.businessBillingAddress}
            disabled={!abrLookup}
          >
            <BillingAddressAutoComplete
              autoLocation={localAddressAutoLocation}
              handleSelectAutocomplete={handleAustraliaAddressAutocomplete}
              onChange={handleAustraliaAddressChange}
            />
          </CustomInput>
        </div>
      )}

      <h3 className="mb-[8px] mt-[24px]  text-[16px] font-bold sm:text-[21px]">
        Who will be managing this account?
      </h3>

      <div className="relative flex w-full flex-1 flex-col items-center">
        <CustomInput title={'Email Address'} error={isErrorEmail}>
          <input
            id={'Email'}
            type="text"
            placeholder="email address"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': isErrorEmail,
              },
            )}
            {...register('email', {
              onBlur: async (e) => {
                const result = await trigger('email');
                if (result) {
                  dispatch({
                    type: ACTIONS.VERIFY_EMAIL,
                    payload: { email: e.target.value, type: accountType },
                  });
                }
              },
            })}
            disabled={accountType === 5}
          />
          <div className="absolute right-[15px] top-[50%] grid translate-y-[-50%] place-items-center">
            {isPendingVerifyEmail && (
              <span className="h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
            )}
            {isEmailVerified && <FcCheckmark />}
          </div>
        </CustomInput>
        {/* </div> */}
        {!isEmpty(errors.email) && (
          <InvalidText>{errors.email?.message}</InvalidText>
        )}
        {error === 'email_invalid' && (
          <InvalidText>Invalid school email</InvalidText>
        )}
        {error?.email && <InvalidText>{error?.email}</InvalidText>}
      </div>

      <CustomInput title="Country Code" error={errors.contactCountry}>
        <select
          onChange={handleMobileSelectChange}
          onClick={(e) => e.stopPropagation()}
          className="peer input input-bordered w-full pr-[50px] pt-[10px] !text-[14px]"
          defaultValue={'AU'}
        >
          {COUNTRIES.map((country: ICountryCode, i: number) => {
            return (
              isSupportedCountry(country.code) && (
                <option
                  key={i}
                  value={country.code}
                  className="!mb-[5px] !pb-[5px] text-[14px]"
                >
                  {country.name} &#91;+{getCountryCallingCode(country.code)}
                  &#93;
                </option>
              )
            );
          })}
        </select>
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="pointer-events-none absolute right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
        >
          <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
        </span>
      </CustomInput>
      {!isEmpty(errors.contactCountry) && (
        <InvalidText>{errors.contactCountry?.message}</InvalidText>
      )}
      <div>
        <CustomInput title="Mobile Number" error={errors.contact}>
          <Input
            country={phoneCode}
            international
            withCountryCallingCode={!isInternational}
            value={phone}
            onChange={setPhone}
            placeholder="mobile"
            className={classNames(
              'peer h-[40px] w-full rounded-b-lg border-[1px] border-b-[1px] border-flexi-black-7 border-t-transparent pl-[15px]',
              {
                '!border-red-500 !border-t-transparent': errors?.contact,
              },
            )}
            maxLength={
              phoneCode.toLocaleLowerCase() === 'nz'
                ? 15
                : isValidPhoneNumber(phone || `+61`, phoneCode)
                ? phone.length
                : 17
            }
          />
        </CustomInput>
        {!isEmpty(errors?.contact) && (
          <InvalidText>Mobile number is required</InvalidText>
        )}
        {!isEmpty(error?.contact) && (
          <InvalidText>{error.contact[0]}</InvalidText>
        )}
      </div>

      <div className="form-control w-full">
        <CustomInput title="First Name" error={errors.firstname}>
          <input
            type="text"
            placeholder="first name"
            className={`peer input input-bordered w-full ${
              errors?.firstname && 'border-red-500'
            }`}
            {...register('firstname')}
          />
        </CustomInput>
        {!isEmpty(errors.firstname) && (
          <InvalidText>{errors.firstname?.message}</InvalidText>
        )}
      </div>

      <div className="form-control w-full">
        <CustomInput title="Last Name" error={errors.lastname}>
          <input
            type="text"
            placeholder="last name"
            className={`peer input input-bordered w-full ${
              errors?.lastname && 'border-red-500'
            }`}
            {...register('lastname')}
          />
        </CustomInput>
        {!isEmpty(errors.lastname) && (
          <InvalidText>{errors.lastname?.message}</InvalidText>
        )}
      </div>

      <p className="mb-[16px] text-[14px]">
        *Input your name as same as your Credit Card details
      </p>

      <div className="flex flex-col">
        <CustomInput title="Password" error={errors.password}>
          <input
            type={passLVisible ? 'text' : 'password'}
            placeholder="password"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': errors.password,
              },
            )}
            {...register('password', {})}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
            // onBlur={(e) => !e.target.value && setIsInputPasswordActive(false)}
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              setPassLVisible(!passLVisible);
            }}
            className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
          >
            {passLVisible ? (
              <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
            ) : (
              <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.password) && (
          <div className=" my-2 text-[12px] text-red-500">
            {password ? (
              <ul className="relative ml-5 list-disc">
                <li
                  className={classNames('', {
                    hidden: password.length >= 8,
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must be at least 8 characters
                  </span>
                </li>
                <li
                  className={classNames('', {
                    hidden: password.match(/\d/),
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must contain at least 1 number
                  </span>
                </li>
                <li
                  className={classNames('', {
                    hidden:
                      password.match(/[a-z]/) &&
                      password.match(/[a-z]/) &&
                      password.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/),
                  })}
                >
                  <span className="absolute left-[-5px]">
                    Must contain lowercase, uppercase, and a special character
                  </span>
                </li>
              </ul>
            ) : (
              <p>This field is required</p>
            )}
          </div>
        )}
      </div>
      <div className="form-control mb-3 w-full flex-1">
        <CustomInput
          title="Confirm Password"
          error={errors.passwordConfirmation}
        >
          <input
            type={passRVisible ? 'text' : 'password'}
            placeholder="confirm password"
            className={classNames(
              'peer input input-bordered w-full pr-[50px] pt-[10px] text-[14px]',
              {
                'border-red-500': errors?.passwordConfirmation,
              },
            )}
            {...register('passwordConfirmation', {})}
          />
          <span
            onClick={(e) => {
              e.stopPropagation();
              setPassRVisible(!passRVisible);
            }}
            className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg  bg-transparent"
          >
            {passRVisible ? (
              <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
            ) : (
              <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
            )}
          </span>
        </CustomInput>
        {!isEmpty(errors.passwordConfirmation) && (
          <InvalidText>{errors.passwordConfirmation?.message}</InvalidText>
        )}
      </div>

      <div className="mt-[8px] flex flex-col gap-[15px] sm:gap-[25px]">
        <CustomCheckBox
          size={10}
          titleClass="text-[12px] sm:text-[14px]"
          title="Sign me up to receive special offers and updates."
        >
          <input
            type="checkbox"
            className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px] !bg-white"
            {...register('isMailSubscriber')}
            // @ts-ignore
            style={{ '--chkfg': '0', '--chkbg': '0' }}
            defaultChecked
          />
        </CustomCheckBox>
        <p className="text-[14px]">
          By clicking &apos;Create my account&apos;, you confirm that you have
          read and accepted Flexicar&apos;s&nbsp;
          <a
            href="/terms-and-conditions"
            target="_blank"
            className="font-bold text-primary"
          >
            Terms &#38; Conditions
          </a>
          &nbsp;and&nbsp;
          <a
            href="/privacy-policy-page"
            target="_blank"
            className="font-bold text-primary"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>

      <button
        type="submit"
        onClick={() => setIsSubmitButtonClicked(true)}
        className={classNames(
          'btn btn-primary mt-[25px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white lg:mt-[40px] lg:h-[60px]',
          {
            'loading bg-flexi-green-2':
              registerStatus === RequestStatusEnum.PENDING,
          },
        )}
        disabled={isPendingVerifyEmail}
      >
        Create my account
      </button>
    </form>
  ) : (
    <PendingLoader />
  );
};

export default Form;
