import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useClickAway } from 'react-use';
import classNames from 'classnames';

import iconClose from 'assets/images/close_modal.svg';
import { ReactComponent as ErrorTriangle } from 'assets/images/error.svg';
import { ReactComponent as ErrorCircle } from 'assets/images/error_general.svg';
import { ReactComponent as Sometimes } from 'assets/images/sometimes.svg';
import { IMAGE_URL } from 'common/constants';
import { setIsCourseDueNotified } from 'common/helpers';
import { selectPendingUpdate, selectUser } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  width?: string;
  maxWidth?: string;
  enableClickAway?: boolean;
  hasCloseButton?: boolean;
  px?: string;
  mx?: string;
  padding?: string;
}

const CourseEndDateModal = ({
  isModalOpen,
  handleClose,
  width = '800px',
  maxWidth = '800px',
  enableClickAway = true,
  hasCloseButton = true,
  px = '120px',
  mx = '0px',
  padding = 'p-[60px]',
}: Props) => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const user = useAppSelector(selectUser);
  const isPlanUpdatePending = useAppSelector(selectPendingUpdate);

  useClickAway(modalRef, () => {
    if (enableClickAway) handleClose();
  });

  return (
    <section
      className={classNames(`modal z-[10000]`, {
        'modal-open': isModalOpen,
      })}
    >
      <div
        className={`scrollbar modal-box relative flex flex-col overflow-auto rounded-[10px] md:px-[120px]
      [&>.title]:mb-[20px] [&>.title]:text-center [&>.title]:text-[20px] 
      [&>.title]:font-[700] [&>.title]:md:text-[34px] [&>.title]:md:leading-[42px] [&>.button]:mt-[40px] [&>.button]:h-[60px] [&>.button]:rounded-full [&>.button]:border-none [&>.button]:bg-flexi-green-1 [&>.button]:font-[700] [&>.button]:text-white [&>.button]:md:text-[16px] [&>.icon]:mb-[40px] [&>.icon]:self-center [&>.button:hover]:bg-flexi-green-2 mx-[${mx}] ${px} w-full !w-[${width}] ${padding}`}
        style={{
          maxWidth,
        }}
        ref={modalRef}
      >
        {hasCloseButton && (
          <span
            className="absolute right-[25px] top-[30px] grid place-items-center md:right-[33px] md:top-[40px]"
            onClick={handleClose}
            role="button"
          >
            <span
              className="h-[20px] w-[20px] bg-center bg-no-repeat md:h-[25px] md:w-[25px]"
              style={{
                background: `url('${IMAGE_URL}${iconClose}')`,
                backgroundSize: '100%',
              }}
            />
          </span>
        )}

        {user?.plan?.isStudentSwitchedToPersonal ? (
          <Sometimes className="icon h-[120px] w-[120px]" />
        ) : user?.course?.isCourseEndDateExpired ? (
          <ErrorCircle className="icon" />
        ) : (
          <ErrorTriangle className="icon" />
        )}
        <>
          <h1 className="mb-[20px] text-center text-[20px] font-bold lg:text-[34px]">
            {user?.plan?.isStudentSwitchedToPersonal
              ? 'Flexicar Account Updated'
              : 'Update Your Info'}
          </h1>
          <p className="mb-[40px] text-center text-[14px] lg:text-[18px]">
            {user?.plan?.isStudentSwitchedToPersonal
              ? user?.plan?.message
              : user?.course?.message}
          </p>
          <button
            className={classNames(
              'btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold !capitalize text-white outline-none hover:bg-flexi-green-2 lg:!h-[60px] lg:text-[16px]',
              {
                loading: isPlanUpdatePending,
              },
            )}
            onClick={() => {
              if (
                user?.plan?.isStudentSwitchedToPersonal &&
                !user?.plan?.isAccepted
              ) {
                dispatch({ type: ACTIONS.ACCEPT_STUDENT_SWITCH_PERSONAL });
              } else {
                setIsCourseDueNotified();
                push('/app/account/personal-details');
              }
            }}
          >
            {user?.plan?.isStudentSwitchedToPersonal
              ? 'Go To My Account'
              : 'Update Course Details'}
          </button>
        </>
      </div>
    </section>
  );
};

export default CourseEndDateModal;
