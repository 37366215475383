import React from 'react';

import classNames from 'classnames';

import vehiclePlaceholder from 'assets/images/vehicle-placeholder.svg';
import { ReactComponent as Vtprestige } from 'assets/images/prestige.svg';
import { IVehicleSummary } from 'common/types';
import { IMAGE_URL } from 'common/constants';
import { formatDate } from 'common/helpers';
import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';

interface MyComponentProps {
  vehicle: IVehicleSummary;
}

interface Props {
  vehicleSummary: IVehicleSummary | null;
  startDate: Date;
  endDate: Date;
}

const BookingInfo = ({ vehicleSummary, startDate, endDate }: Props) => {
  const getFuel = () => vehicleSummary?.fuelType.replaceAll('_', ' ');
  const getType = () => vehicleSummary?.group.category?.name;
  const getFuelPin = () => vehicleSummary?.fuelCardPincode;
  const getVehicleCapacity = () => vehicleSummary?.seats;

  const user = useAppSelector(selectUser);

  const VehicleImage: React.FC<MyComponentProps> = ({ vehicle }) => {
    const [vehicleImageError, setVehicleImageError] = React.useState(false);
    const [groupImageError, setGroupImageError] = React.useState(false);

    return (
      <div className="ml-auto h-[170px] w-[180px] md:w-[255px]">
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': !vehicleImageError,
          })}
          src={vehicle.vehicleImageUrl !== null ? vehicle.vehicleImageUrl : ''}
          alt={vehicle.name}
          onError={() => setVehicleImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && !groupImageError,
          })}
          src={
            vehicle.group.vehicleImage !== null
              ? vehicle.group.vehicleImage
              : ''
          }
          alt={vehicle.group.make}
          onError={() => setGroupImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && groupImageError,
          })}
          src={`${IMAGE_URL}${vehiclePlaceholder}`}
          alt="vehicle default image"
        />
      </div>
    );
  };

  return (
    <section className="flex w-full flex-col items-start">
      <div className="mb-[30px] flex w-full justify-between  ">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            <div className="flex items-center gap-[10px]">
              {vehicleSummary?.isPrestige && (
                <Vtprestige className="h-[20px] w-[20px]" />
              )}
              <p className="mr-[20px]  text-left text-[22px] font-bold sm:text-[26px]">
                {vehicleSummary?.name}
              </p>
            </div>
            <p className="background-red flex items-center gap-2 whitespace-nowrap fill-zinc-700 text-[16px] sm:text-[16px]">
              {vehicleSummary?.group.make}, {vehicleSummary?.group.model}
            </p>

            <p className="background-red flex items-center gap-2 whitespace-nowrap fill-zinc-700 text-[16px] sm:text-[16px]">
              {vehicleSummary?.group.model}
            </p>
          </div>
        </div>

        <div className="h-full w-full object-contain">
          {vehicleSummary && <VehicleImage vehicle={vehicleSummary} />}
        </div>
      </div>
      <div className="mb-[50px] w-full">
        <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[20px] sm:text-[22px]">
          Car information
        </p>

        <div className=" grid grid-cols-2 gap-y-[20px] text-[16px] sm:text-[16px] [&>p]:border-b-[1px] [&>p]:border-dotted">
          <p>Number of passengers</p>
          <p className="text-end font-bold capitalize">
            {getVehicleCapacity()}
          </p>
          <p>Fuel</p>
          <p className="text-end font-bold capitalize">{getFuel()}</p>
          <p>Type</p>
          <p className="text-end font-bold capitalize">{getType()}</p>
          <p>Fuel Pin</p>
          <p className="text-end font-bold capitalize">{getFuelPin()}</p>
        </div>
      </div>

      <div
        className={classNames('flex w-full flex-col gap-5', {
          'border-b-[1px] border-[#282828] pb-[50px]':
            user?.plan?.name !== 'business',
        })}
      >
        <div className="flex flex-col">
          <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[20px] sm:text-[22px]">
            Booking Details
          </p>
          <div className="grid grid-cols-2 gap-y-[20px] text-[16px] sm:text-[16px] [&>p]:border-b-[1px] [&>p]:border-dotted">
            <p>Pick-up</p>
            <p className="text-end font-bold">
              {formatDate(startDate.toISOString(), 'MMMM DD | h:mm A')}
            </p>
            <p>Drop-off</p>
            <p className="text-end font-bold">
              {formatDate(endDate.toISOString(), 'MMMM DD | h:mm A')}
            </p>
            <p className="border-b-transparent">Car location</p>
            <p className="border-b-transparent text-end font-bold">
              {vehicleSummary?.podName}
            </p>
            <p className="col-span-2 border-b-transparent text-[14px] sm:text-[14px]">
              {vehicleSummary?.podDescription}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingInfo;
