import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import {
  IBooking,
  IBookingSummary,
  ICancellationFees,
  IInvoice,
  IMapBounds,
  IMapCenter,
  IVehicleSummary,
  IReportBookingUrl,
  IVehicleCategories,
  RequestStatusEnum,
  IInvoiceList,
  IRescheduleDetails,
  IBookingListPaginated,
} from 'common/types';

type InitialState = {
  pendingBooking: boolean;
  BookingPastStatus: RequestStatusEnum | null;
  BookingOngoingStatus: RequestStatusEnum | null;
  BookingUpcomingStatus: RequestStatusEnum | null;
  pendingInvoiceList: boolean;
  pendingInvoiceDetails: boolean;
  pendingCreateBooking: boolean;
  pendingVehicleCategories: boolean;
  pendingUpdateBooking: boolean;
  bookingList: IBooking[];
  upcomingBookingList: IBooking[];
  pastBookingList: IBooking[];
  vehicleEstimation: [];
  invoiceList: IInvoiceList | null;
  invoiceDetails: IInvoice | null;
  vehicleCategories: IVehicleCategories[] | [];
  startDate: Date | null;
  endDate: Date | null;
  mapCenter: IMapCenter | null;
  userCenter: IMapCenter | null;
  selectedLocationCenter: IMapCenter | null;
  mapBounds: IMapBounds | null;
  locationAddress: string;
  isMapView: boolean;
  pendingLocationFormattedAddress: boolean;
  pendingMap: boolean;
  pendingCancelBooking: boolean;
  cancelBookingStatus: RequestStatusEnum | null;
  createBookingStatus: RequestStatusEnum | null;
  modifyBookingId: number | undefined;
  bookingSummaryModify: IBookingSummary | null;
  updateBookingStatus: RequestStatusEnum | null;
  updateBookingEstimateAmount: number | null;
  ongoingBookingList: IBooking[];
  reportBookingUrl: IReportBookingUrl | null;
  reportBookingUrlStatus: RequestStatusEnum | null;
  reportBookingStatus: RequestStatusEnum | null;
  upcomingBookingStatus: RequestStatusEnum | null;
  cancellationFees: ICancellationFees | null;
  cancellationFeesStatus: RequestStatusEnum | null;
  errors: any;
  bookingEstimateStatus: RequestStatusEnum | null;
  loadingBookingsUpcoming: number[];
  loadingBookingsOngoing: number[];
  bookingSummaryModifyStatus: RequestStatusEnum | null;
  bookingSummaryModifySecondStatus: RequestStatusEnum | null;
  openModalBookingSummary: boolean;
  isDatePickerOpen: boolean;
  tentativeStartDate: Date | null;
  tentativeEndDate: Date | null;
  tentativeMapCenter: IMapCenter | null;
  requestVehicleSearch: boolean;
  fetchAvailableVehicles: IVehicleSummary[] | null;
  fetchAvailableVehiclesStatus: RequestStatusEnum | null;
  rescheduleData: IRescheduleDetails | null;
  rescheduleDataStatus: RequestStatusEnum | null;
  ongoingBookingListPaginated: IBookingListPaginated | null;
  upcomingBookingListPaginated: IBookingListPaginated | null;
  pastBookingListPaginated: IBookingListPaginated | null;
};

const initialState: InitialState = {
  pendingBooking: false,
  BookingPastStatus: null,
  BookingOngoingStatus: null,
  BookingUpcomingStatus: null,
  pendingInvoiceList: false,
  pendingInvoiceDetails: false,
  pendingCreateBooking: false,
  pendingVehicleCategories: false,
  pendingUpdateBooking: false,
  bookingList: [],
  upcomingBookingList: [],
  pastBookingList: [],
  vehicleEstimation: [],
  invoiceList: null,
  invoiceDetails: null,
  vehicleCategories: [],
  startDate: null,
  endDate: null,
  mapCenter: null,
  userCenter: null,
  selectedLocationCenter: null,
  mapBounds: null,
  locationAddress: '',
  isMapView: true,
  pendingLocationFormattedAddress: false,
  pendingMap: true,
  pendingCancelBooking: false,
  cancelBookingStatus: null,
  createBookingStatus: null,
  modifyBookingId: undefined,
  bookingSummaryModify: null,
  updateBookingStatus: null,
  updateBookingEstimateAmount: null,
  ongoingBookingList: [],
  reportBookingUrl: null,
  reportBookingUrlStatus: null,
  reportBookingStatus: null,
  upcomingBookingStatus: null,
  cancellationFees: null,
  cancellationFeesStatus: null,
  bookingEstimateStatus: null,
  errors: null,
  loadingBookingsUpcoming: [],
  loadingBookingsOngoing: [],
  bookingSummaryModifyStatus: null,
  bookingSummaryModifySecondStatus: null,
  openModalBookingSummary: false,
  isDatePickerOpen: false,
  tentativeStartDate: null,
  tentativeEndDate: null,
  tentativeMapCenter: null,
  requestVehicleSearch: false,
  fetchAvailableVehicles: null,
  fetchAvailableVehiclesStatus: null,
  rescheduleData: null,
  rescheduleDataStatus: null,
  ongoingBookingListPaginated: null,
  upcomingBookingListPaginated: null,
  pastBookingListPaginated: null,
};

export const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    reset: () => initialState,
    setPendingBooking: (state, action: PayloadAction<boolean>) => {
      state.pendingBooking = action.payload;
    },
    setBookingPastStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.BookingPastStatus = action.payload;
    },
    setBookingOngoingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.BookingOngoingStatus = action.payload;
    },
    setBookingUpcomingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.BookingUpcomingStatus = action.payload;
    },
    setPendingInvoiceList: (state, action: PayloadAction<boolean>) => {
      state.pendingInvoiceList = action.payload;
    },
    setPendingInvoiceDetails: (state, action: PayloadAction<boolean>) => {
      state.pendingInvoiceDetails = action.payload;
    },
    setPendingCreateBooking: (state, action: PayloadAction<boolean>) => {
      state.pendingCreateBooking = action.payload;
    },
    setPendingVehicleCategories: (state, action: PayloadAction<boolean>) => {
      state.pendingVehicleCategories = action.payload;
    },
    setPendingUpdateBooking: (state, action: PayloadAction<boolean>) => {
      state.pendingUpdateBooking = action.payload;
    },
    setBookingList: (state, action: PayloadAction<IBooking[] | []>) => {
      state.bookingList = action.payload;
    },
    setUpcomingBookingList: (state, action: PayloadAction<IBooking[] | []>) => {
      state.upcomingBookingList = action.payload;
    },
    setPastBookingList: (state, action: PayloadAction<IBooking[] | []>) => {
      state.pastBookingList = action.payload;
    },
    setVehicleEstimation: (state, action: PayloadAction<[]>) => {
      state.vehicleEstimation = action.payload;
    },
    setInvoiceList: (state, action: PayloadAction<IInvoiceList | null>) => {
      state.invoiceList = action.payload;
    },
    setInvoiceDetails: (state, action: PayloadAction<IInvoice>) => {
      state.invoiceDetails = action.payload;
    },
    setVehicleCategories: (
      state,
      action: PayloadAction<IVehicleCategories[]>,
    ) => {
      state.vehicleCategories = action.payload;
    },
    setStartDate: (state, action: PayloadAction<Date | null>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<Date | null>) => {
      state.endDate = action.payload;
    },
    setMapCenter: (state, action: PayloadAction<IMapCenter>) => {
      state.mapCenter = action.payload;
    },
    setUserCenter: (state, action: PayloadAction<IMapCenter>) => {
      state.userCenter = action.payload;
    },
    setSelectedLocationCenter: (state, action: PayloadAction<IMapCenter>) => {
      state.selectedLocationCenter = action.payload;
    },
    setMapBounds: (state, action: PayloadAction<IMapBounds>) => {
      state.mapBounds = action.payload;
    },
    setLocationAddress: (state, action: PayloadAction<string>) => {
      state.locationAddress = action.payload;
    },
    setMapView: (state, action: PayloadAction<boolean>) => {
      state.isMapView = action.payload;
    },
    setPendingLocationFormattedAddress: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.pendingLocationFormattedAddress = action.payload;
    },
    setPendingMap: (state, action: PayloadAction<boolean>) => {
      state.pendingMap = action.payload;
    },
    setPendingCancelBooking: (state, action: PayloadAction<boolean>) => {
      state.pendingCancelBooking = action.payload;
    },
    setCancelBookingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.cancelBookingStatus = action.payload;
    },
    setCreateBookingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.createBookingStatus = action.payload;
    },
    setModifyBookingId: (state, action: PayloadAction<number | undefined>) => {
      state.modifyBookingId = action.payload;
    },
    setBookingSummaryModify: (
      state,
      action: PayloadAction<IBookingSummary | null>,
    ) => {
      state.bookingSummaryModify = action.payload;
    },
    setUpdateBookingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.updateBookingStatus = action.payload;
    },
    setUpdateBookingEstimateAmount: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.updateBookingEstimateAmount = action.payload;
    },

    setOngoingBookingList: (state, action: PayloadAction<IBooking[] | []>) => {
      state.ongoingBookingList = action.payload;
    },
    setReportBookingUrl: (state, action: PayloadAction<IReportBookingUrl>) => {
      state.reportBookingUrl = action.payload;
    },
    setReportBookingUrlStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.reportBookingUrlStatus = action.payload;
    },
    setReportBookingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.reportBookingStatus = action.payload;
    },
    setUpcomingBookingStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.upcomingBookingStatus = action.payload;
    },
    setCancellationFees: (
      state,
      action: PayloadAction<ICancellationFees | null>,
    ) => {
      state.cancellationFees = action.payload;
    },

    setCancellationFeesStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.cancellationFeesStatus = action.payload;
    },
    setBookingEstimateStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.bookingEstimateStatus = action.payload;
    },
    setErrors: (state, action: PayloadAction<any>) => {
      state.errors = action.payload;
    },
    setLoadingBookingsOngoing: (state, action: PayloadAction<number[]>) => {
      state.loadingBookingsOngoing = action.payload;
    },
    setLoadingBookingsUpcoming: (state, action: PayloadAction<number[]>) => {
      state.loadingBookingsUpcoming = action.payload;
    },
    setBookingSummaryModifyStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.bookingSummaryModifyStatus = action.payload;
    },
    setBookingSummaryModifySecondStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.bookingSummaryModifySecondStatus = action.payload;
    },
    setOpenModalBookingSummary: (state, action: PayloadAction<boolean>) => {
      state.openModalBookingSummary = action.payload;
    },
    setIsDatePickerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDatePickerOpen = action.payload;
    },
    setTentativeStartDate: (state, action: PayloadAction<Date | null>) => {
      state.tentativeStartDate = action.payload;
    },
    setTentativeEndDate: (state, action: PayloadAction<Date | null>) => {
      state.tentativeEndDate = action.payload;
    },
    setTentativeMapCenter: (
      state,
      action: PayloadAction<IMapCenter | null>,
    ) => {
      state.tentativeMapCenter = action.payload;
    },
    setRequestVehicleSearch: (state, action: PayloadAction<boolean>) => {
      state.requestVehicleSearch = action.payload;
    },
    setFetchAvailableVehicles: (
      state,
      action: PayloadAction<IVehicleSummary[] | null>,
    ) => {
      state.fetchAvailableVehicles = action.payload;
    },
    setFetchAvailableVehiclesStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.fetchAvailableVehiclesStatus = action.payload;
    },
    setRescheduleData: (
      state,
      action: PayloadAction<IRescheduleDetails | null>,
    ) => {
      state.rescheduleData = action.payload;
    },
    setRescheduleDataStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.rescheduleDataStatus = action.payload;
    },
    setOngoingBookingListPaginated: (
      state,
      action: PayloadAction<IBookingListPaginated | null>,
    ) => {
      state.ongoingBookingListPaginated = action.payload;
    },
    setUpcomingBookingListPaginated: (
      state,
      action: PayloadAction<IBookingListPaginated | null>,
    ) => {
      state.upcomingBookingListPaginated = action.payload;
    },
    setPastBookingListPaginated: (
      state,
      action: PayloadAction<IBookingListPaginated | null>,
    ) => {
      state.pastBookingListPaginated = action.payload;
    },
  },
});

export const {
  reset,
  setPendingBooking,
  setBookingOngoingStatus,
  setBookingPastStatus,
  setBookingUpcomingStatus,
  setPendingInvoiceList,
  setPendingInvoiceDetails,
  setPendingCreateBooking,
  setPendingVehicleCategories,
  setBookingList,
  setUpcomingBookingList,
  setPastBookingList,
  setVehicleEstimation,
  setInvoiceList,
  setInvoiceDetails,
  setVehicleCategories,
  setStartDate,
  setEndDate,
  setMapCenter,
  setUserCenter,
  setSelectedLocationCenter,
  setMapBounds,
  setLocationAddress,
  setMapView,
  setPendingLocationFormattedAddress,
  setPendingMap,
  setPendingCancelBooking,
  setCancelBookingStatus,
  setCreateBookingStatus,
  setModifyBookingId,
  setBookingSummaryModify,
  setUpdateBookingStatus,
  setUpdateBookingEstimateAmount,
  setOngoingBookingList,
  setReportBookingUrl,
  setReportBookingUrlStatus,
  setReportBookingStatus,
  setPendingUpdateBooking,
  setUpcomingBookingStatus,
  setCancellationFees,
  setCancellationFeesStatus,
  setErrors,
  setBookingEstimateStatus,
  setLoadingBookingsOngoing,
  setLoadingBookingsUpcoming,
  setBookingSummaryModifyStatus,
  setBookingSummaryModifySecondStatus,
  setOpenModalBookingSummary,
  setIsDatePickerOpen,
  setTentativeStartDate,
  setTentativeEndDate,
  setTentativeMapCenter,
  setRequestVehicleSearch,
  setFetchAvailableVehicles,
  setFetchAvailableVehiclesStatus,
  setRescheduleData,
  setRescheduleDataStatus,
  setOngoingBookingListPaginated,
  setUpcomingBookingListPaginated,
  setPastBookingListPaginated,
} = slice.actions;

export const selectPendingBooking = (state: RootState): boolean =>
  state.booking.pendingBooking;
export const selectBookingPastStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.BookingPastStatus;
export const selectBookingOngoingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.BookingOngoingStatus;
export const selectBookingUpcomingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.BookingUpcomingStatus;
export const selectPendingInvoiceList = (state: RootState): boolean =>
  state.booking.pendingInvoiceList;
export const selectPendingInvoiceDetails = (state: RootState): boolean =>
  state.booking.pendingInvoiceDetails;
export const selectPendingCreateBooking = (state: RootState): boolean =>
  state.booking.pendingCreateBooking;
export const selectPendingVehicleCategories = (state: RootState): boolean =>
  state.booking.pendingVehicleCategories;
export const selectPendingUpdateBooking = (state: RootState): boolean =>
  state.booking.pendingUpdateBooking;
export const selectBookingList = (state: RootState): IBooking[] | [] =>
  state.booking.bookingList;
export const selectUpcomingBookingList = (state: RootState): IBooking[] | [] =>
  state.booking.upcomingBookingList;
export const selectPastBookingList = (state: RootState): IBooking[] | [] =>
  state.booking.pastBookingList;
export const selectOngoingBookingList = (state: RootState): IBooking[] | [] =>
  state.booking.ongoingBookingList;
export const selectVehicleEstimation = (state: RootState): [] =>
  state.booking.vehicleEstimation;
export const selectInvoiceList = (state: RootState): IInvoiceList | null =>
  state.booking.invoiceList;
export const selectInvoiceDetails = (state: RootState): IInvoice | null =>
  state.booking.invoiceDetails;
export const selectVehicleCategories = (
  state: RootState,
): IVehicleCategories[] | [] => state.booking.vehicleCategories;
export const selectStartDate = (state: RootState): Date | null =>
  state.booking.startDate;
export const selectEndDate = (state: RootState): Date | null =>
  state.booking.endDate;
export const selectMapCenter = (state: RootState): IMapCenter | null =>
  state.booking.mapCenter;
export const selectUserCenter = (state: RootState): IMapCenter | null =>
  state.booking.userCenter;
export const selectSelectedLocationCenter = (state: RootState): IMapCenter | null =>
  state.booking.selectedLocationCenter;
export const selectMapBounds = (state: RootState): IMapBounds | null =>
  state.booking.mapBounds;
export const selectLocationAddress = (state: RootState): string =>
  state.booking.locationAddress;
export const selectMapView = (state: RootState): boolean =>
  state.booking.isMapView;
export const selectPendingLocationFormattedAddress = (
  state: RootState,
): boolean => state.booking.pendingLocationFormattedAddress;
export const selectPendingMap = (state: RootState): boolean =>
  state.booking.pendingMap;
export const selectPendingCancelBooking = (state: RootState): boolean =>
  state.booking.pendingCancelBooking;
export const selectCancelBookingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.cancelBookingStatus;
export const selectCreateBookingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.createBookingStatus;
export const selectModifyBookingId = (state: RootState): number | undefined =>
  state.booking.modifyBookingId;
export const selectBookingSummaryModify = (
  state: RootState,
): IBookingSummary | null => state.booking.bookingSummaryModify;
export const selectUpdateBookingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.updateBookingStatus;
export const selectUpdateBookingEstimateAmount = (
  state: RootState,
): number | null => state.booking.updateBookingEstimateAmount;
export const selectReportBookingUrlStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.reportBookingUrlStatus;
export const selectReportBookingUrl = (
  state: RootState,
): IReportBookingUrl | null => state.booking.reportBookingUrl;
export const selectReportBookingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.reportBookingStatus;
export const selectUpcomingBookingStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.upcomingBookingStatus;
export const selectCancellationFees = (
  state: RootState,
): ICancellationFees | null => state.booking.cancellationFees;
export const selectCancellationFeesStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.cancellationFeesStatus;
export const selectBookingEstimateStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.bookingEstimateStatus;
export const selectErrors = (
  state: RootState,
): any => state.booking.errors;
export const selectLoadingBookingsOngoing = (
  state: RootState,
): number[] => state.booking.loadingBookingsOngoing;
export const selectLoadingBookingsUpcoming = (
  state: RootState,
): number[] => state.booking.loadingBookingsUpcoming;
export const selectBookingSummaryModifyStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.bookingSummaryModifyStatus;
export const selectBookingSummaryModifySecondStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.bookingSummaryModifySecondStatus;
export const selectOpenModalBookingSummary = (
  state: RootState,
): boolean => state.booking.openModalBookingSummary;
export const selectIsDatePickerOpen = (state: RootState): boolean =>
  state.booking.isDatePickerOpen;
export const selectTentativeStartDate = (state: RootState): Date | null =>
  state.booking.tentativeStartDate;
export const selectTentativeEndDate = (state: RootState): Date | null =>
  state.booking.tentativeEndDate;
export const selectTentativeMapCenter = (state: RootState): IMapCenter | null =>
  state.booking.tentativeMapCenter;
export const selectRequestVehicleSearch = (state: RootState): boolean =>
  state.booking.requestVehicleSearch;
export const selectFetchAvailableVehicles = (state: RootState): IVehicleSummary[] | null =>
  state.booking.fetchAvailableVehicles;
export const selectFetchAvailableVehiclesStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.fetchAvailableVehiclesStatus;
export const selectrescheduleData = (state: RootState): IRescheduleDetails | null =>
  state.booking.rescheduleData;
export const selectRescheduleDataStatus = (
  state: RootState,
): RequestStatusEnum | null => state.booking.rescheduleDataStatus;
export const selectOngoingBookingListPaginated = (
  state: RootState,
): IBookingListPaginated | null => state.booking.ongoingBookingListPaginated;
export const selectUpcomingBookingListPaginated = (
  state: RootState,
): IBookingListPaginated | null => state.booking.upcomingBookingListPaginated;
export const selectPastBookingListPaginated = (
  state: RootState,
): IBookingListPaginated | null => state.booking.pastBookingListPaginated;

export default slice.reducer;
