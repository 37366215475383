import React from 'react';
import { useEffectOnce } from 'react-use';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as IconLine1 } from 'assets/images/splash_line1.svg';
import { ReactComponent as IconLine2 } from 'assets/images/splash_line2.svg';
import { IMAGE_URL } from 'common/constants';
import { AccountTypeEnum } from 'common/types';
import { AuthenticatedTopNav, SignupTopNav } from 'components/TopNav';
import { selectUser } from 'modules/me/slice';
import BgCar from 'assets/images/splash_car.png';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PageLayout from 'components/layouts/PageLayout';
import RegisterLayout from 'components/layouts/RegisterLayout';
import RegisterOTP from 'features/RegisterOTP';
import RegisterForm from 'features/RegisterForm';
import RegisterPlans from 'features/RegisterPlans';
import RegisterPayment from 'features/RegisterPayment';
import RegisterThankYou from 'features/RegisterThankYou';
import RegisterDeclined from 'features/RegisterDeclined';
import RegisterUpdateEmail from 'features/RegisterUpdateEmail';
import RegisterVerification from 'features/RegisterVerification';
import RegisterCreateAccount from 'features/RegisterCreateAccount';
import RegisterSchoolPromotion from 'features/RegisterSchoolPromotion';
import RegisterProofOfIdentity from 'features/RegisterProofOfIdentity';
import RegisterInformationDriver from 'features/RegisterInformationDriver';
import RegisterInformationStudent from 'features/RegisterInformationStudent';
import RegisterInformationPersonal from 'features/RegisterInformationPersonal';

const Register = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { pathname } = useLocation();
  
   const isMobile = useMediaQuery({
     query: '(max-width: 767px)',
   });

  const renderInformationPage = () => {
    if (
      user?.type.name.toLocaleLowerCase() ===
      AccountTypeEnum.PERSONAL.toLocaleLowerCase()
    ) {
      return (
        <RegisterLayout>
          <RegisterInformationPersonal />
        </RegisterLayout>
      );
    }
    if (
      user?.type.name.toLocaleLowerCase() ===
      AccountTypeEnum.STUDENT.toLocaleLowerCase()
    ) {
      return (
        <RegisterLayout>
          <RegisterInformationStudent />
        </RegisterLayout>
      );
    }
    if (
      user?.type.name.toLocaleLowerCase() ===
        AccountTypeEnum.BUSINESS.toLocaleLowerCase() &&
      user?.isDriver
    ) {
      return (
        <RegisterLayout>
          <RegisterInformationDriver />
        </RegisterLayout>
      );
    }
  };

  useEffectOnce(() => {
    if (pathname !== '/register') {
      dispatch({ type: ACTIONS.FETCH_ME });
    }
  });
  
  return (
    <PageLayout>
      <Helmet>
        <title>Register</title>
        <meta name="description" content="My Flexicar Registration" />
      </Helmet>
      <article className="mt-[70px] md:mt-0">
        {!isMobile && pathname === '/register' ? (
          <SignupTopNav />
        ) : (
          !isMobile && <AuthenticatedTopNav />
        )}
        <Switch>
          <Route exact path="/register">
            <section className="relative bg-primary bg-fixed px-[15px] py-[50px] lg:px-0 lg:py-[100px]">
              <div className="fixed top-[110px] left-0 flex h-full w-full [&>*]:hidden [&>*]:sm:flex">
                <p className="font-primary absolute top-[40%] left-[17%] hidden text-center text-[50px] font-[900] uppercase leading-[60px] text-white sm:flex">
                  get ready
                  <br />
                  to drive!
                </p>
                <p
                  className="absolute top-[60%]  left-[14%] z-10 h-[200px] w-[380px] bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${IMAGE_URL}${BgCar})`,
                    backgroundSize: '100%',
                  }}
                ></p>
                <div className="absolute top-[5px]  left-[-70px] w-[60%] min-w-[745px]">
                  {/* <img src={IconLine1} alt="" width="100%" height="auto"/> */}
                  <IconLine1 width="100%" height="auto" />
                </div>
                <div className="absolute left-[calc(14vw+300px)]  top-[47%] w-[30%] min-w-[300px]">
                  {/* <img src={IconLine2} alt="" width="100%" height="auto" /> */}
                  <IconLine2 width="100%" height="auto" />
                </div>
              </div>
              <RegisterForm />
            </section>
          </Route>
          <Route exact path="/register/otp">
            <RegisterLayout>
              <RegisterOTP />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/verification">
            <RegisterLayout>
              <RegisterVerification />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/declined">
            <RegisterLayout>
              <RegisterDeclined />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/proof-of-identity">
            <RegisterLayout>
              <RegisterProofOfIdentity />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/school-promotion">
            <RegisterLayout>
              <RegisterSchoolPromotion />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/information">
            {renderInformationPage()}
          </Route>
          <Route exact path="/register/plan">
            <RegisterLayout>
              <RegisterPlans />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/payment">
            <RegisterLayout>
              <RegisterPayment />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/done">
            <RegisterLayout>
              <RegisterThankYou />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/create-account">
            <RegisterLayout>
              <RegisterCreateAccount />
            </RegisterLayout>
          </Route>
          <Route exact path="/register/update-email">
            <RegisterLayout>
              <RegisterUpdateEmail />
            </RegisterLayout>
          </Route>
        </Switch>
      </article>
    </PageLayout>
  );
};

export default Register;
