import React, { useEffect, useState } from 'react';
import LocationField from './LocationField';
import VehicleField from './VehicleField';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectIsDatePickerOpen,
  setRequestVehicleSearch
} from 'modules/booking/slice';
import classNames from 'classnames';
import { selectMapAutoZoomEnd } from 'modules/pods/slice';
import { selectUser } from 'modules/me/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import CalendarField from './CalendarField';

interface Props {
  isMapLoaded: boolean;
}

const BookingFields = ({ isMapLoaded }: Props) => {
  const dispatch = useAppDispatch()
  
  const [isToastVisible, setIsToastVisible] = useState(false);
  const user = useAppSelector(selectUser);
  const isAutoZoomDone = useAppSelector(selectMapAutoZoomEnd);
  const isDatePickerOpen = useAppSelector(selectIsDatePickerOpen);

  useEffect(() => {
    if (isToastVisible) {
      const timeout = setTimeout(() => {
        setIsToastVisible(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isToastVisible]);

  return (
    <aside
      className={classNames(
        'relative z-50 z-[9] w-full bg-[#F2F2F2] pb-[30px]',
        {
          'pt-[30px]': user === null,
        },
      )}
    >
      <div
        className={classNames(
          'm-auto flex flex-col items-center justify-center gap-[10px] px-[7.5vw] lg:flex-row lg:gap-[30px] lg:gap-[40px] lg:px-[90px] xl:px-[210px]',
          {
            'pointer-events-none': !isAutoZoomDone,
          },
        )}
      >
        {isMapLoaded && <LocationField />}
        <div className="w-[85vw] lg:w-auto">
          <CalendarField />
        </div>
        <VehicleField />

        <button
          className="btn-primary btn mt-[10px] h-[45px] min-h-[45px] w-full rounded-full px-7 normal-case disabled:text-white lg:mt-0 lg:flex lg:w-auto border-0"
          disabled={isDatePickerOpen}
          onClick={() => {
            dispatch(setRequestVehicleSearch(true));
          }}
        >
          Show Vehicles
        </button>
      </div>
    </aside>
  );
};

export default BookingFields;
