import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import {
  IContent,
  IPagesContentsCategories,
  IVideo,
  IVideoCategory,
  RequestStatusEnum,
} from 'common/types';

type InitialState = {
  publicPending: RequestStatusEnum | null;
  pagesContentsCategories: IPagesContentsCategories[] | null;
  pagesContents: IContent[] | null;
  pagesVideosCategories: IVideoCategory[] | null;
  pagesVideos: IVideo[] | null;
  envType: string | null;
  sendEnquiryPending: RequestStatusEnum | null;
  unsubscribeResponseMessage: string | null;
  isUnsubscribeButtonEnabled: boolean;
};

const initialState: InitialState = {
  publicPending: null,
  pagesContentsCategories: null,
  pagesContents: null,
  pagesVideosCategories: null,
  pagesVideos: null,
  envType: null,
  sendEnquiryPending: null,
  unsubscribeResponseMessage: null,
  isUnsubscribeButtonEnabled: true,
};

export const slice = createSlice({
  name: 'publicData',
  initialState,
  reducers: {
    reset: () => initialState,
    setPublicPending: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.publicPending = action.payload;
    },
    setPagesContentsCategories: (
      state,
      action: PayloadAction<IPagesContentsCategories[] | null>,
    ) => {
      state.pagesContentsCategories = action.payload;
    },
    setPagesContents: (state, action: PayloadAction<IContent[] | null>) => {
      state.pagesContents = action.payload;
    },
    setPagesVideosCategories: (
      state,
      action: PayloadAction<IVideoCategory[] | null>,
    ) => {
      state.pagesVideosCategories = action.payload;
    },
    setPagesVideos: (state, action: PayloadAction<IVideo[] | null>) => {
      state.pagesVideos = action.payload;
    },
    setEnvType: (state, action: PayloadAction<string | null>) => {
      state.envType = action.payload;
    },
    setSendEnquiryPending: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.sendEnquiryPending = action.payload;
    },
    setUnsubscribeResponseMessage: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.unsubscribeResponseMessage = action.payload;
    },
    setIsUnsubscribeButtonEnabled: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isUnsubscribeButtonEnabled = action.payload;
    },
  },
});

export const {
  reset,
  setPublicPending,
  setPagesContentsCategories,
  setPagesContents,
  setPagesVideosCategories,
  setPagesVideos,
  setEnvType,
  setSendEnquiryPending,
  setUnsubscribeResponseMessage,
  setIsUnsubscribeButtonEnabled,
} = slice.actions;

export const selectPublicPending = (
  state: RootState,
): RequestStatusEnum | null => state.publicData.publicPending;
export const selectPagesContentsCategories = (
  state: RootState,
): IPagesContentsCategories[] | null => state.publicData.pagesContentsCategories;
export const selectPagesContents = (state: RootState): IContent[] | null =>
  state.publicData.pagesContents;
export const selectPagesVideosCategories = (
  state: RootState,
): IVideoCategory[] | null => state.publicData.pagesVideosCategories;
export const selectPagesVideos = (state: RootState): IVideo[] | null =>
  state.publicData.pagesVideos;
export const selectEnvType = (state: RootState): string | null =>
  state.publicData.envType;
export const selectSendEnquiryPending = (
  state: RootState,
): RequestStatusEnum | null => state.publicData.sendEnquiryPending;
export const selectUnsubscribeResponseMessage = (
  state: RootState,
): string | null => state.publicData.unsubscribeResponseMessage;
export const selectIsUnsubscribeButtonEnabled = (state: RootState): boolean =>
  state.publicData.isUnsubscribeButtonEnabled;
export default slice.reducer;
