import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { RequestStatusEnum } from 'common/types';
import { selectReportBookingStatus } from 'modules/booking/slice';
import useAppSelector from 'hooks/useAppSelector';
import FormComplete from './components/FormComplete';
import FormEntries from './components/FormEntries';

const ReportIssues = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const offboarding = params.get("mode") === "post"
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const reportStatus = useAppSelector(selectReportBookingStatus);
  
  useEffect(() => {
    if (reportStatus === RequestStatusEnum.SUCCESS) setIsSubmitted(true);
    else if (reportStatus === null) setIsSubmitted(false);
  }, [reportStatus]);

  return (
    <>
      {isSubmitted ? (
        <FormComplete offboarding={offboarding} />
      ) : (
        <FormEntries offboarding={offboarding} />
      )}
    </>
  );
};

export default ReportIssues;
