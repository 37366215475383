import classNames from 'classnames';
import { RequestStatusEnum } from 'common/types';
import CustomCheckBox from 'components/CustomCheckbox';
import Modal from 'components/Modal';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectChangeExcessStatus,
  selectPendingChangeExcess,
  selectUser,
  setChangeExcessStatus,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { useState } from 'react';
// import { ReactComponent as IconDamageCover } from 'assets/images/damage_cover.svg';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import useBusiness from 'hooks/useBusiness';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import LottieAnimation from 'components/LottieAnimation';
import damageCoverLottie from 'assets/json/icon-damage-cover.json';

const AddOns = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [isModalOpen, setModalOpen] = useState(false);
  const hasReduceExcessDamageCover = user?.hasReduceExcessDamageCover || false;
  // const hasReduceExcessDamageCove2 = useAppSelector();
  const isPending = useAppSelector(selectPendingChangeExcess);
  const changeExcessStatus = useAppSelector(selectChangeExcessStatus);
  const { isBusinessAccount } = useBusiness();

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleChangeExcess = () => {
    dispatch({ type: ACTIONS.CHANGE_EXCESS });
  };

  const handleClose = () => {
    setModalOpen(false);
    dispatch(setChangeExcessStatus(null));
  };

  const renderPending = ({ width }: { width: string }) => {
    return (
      <div className="flex-1 animate-pulse">
        <div className={`h-[42px] ${width} rounded bg-slate-100`} />
      </div>
    );
  };

  const renderModalContent = () => {
    if (changeExcessStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <IconSuccess className="icon" />
          {hasReduceExcessDamageCover ? (
            <>
              <h1 className="title form-control text-center text-4xl font-bold">
                Successfully Added
              </h1>

              <p className="text-center text-lg ">
                You have added our Reduced Excess option to your plan. Charges
                will automatically be applied towards your upcoming and future
                bookings.
              </p>
            </>
          ) : (
            <>
              <h1 className="title form-control text-center text-4xl font-bold">
                Successfully Cancelled
              </h1>

              <p className="text-center text-lg ">
                You have cancelled our Reduced Excess option to your plan.
              </p>
            </>
          )}

          <label
            htmlFor="reduce-excess-modal"
            className="button btn w-full text-[16px] cursor-pointer self-center rounded-full border-none bg-flexi-green-1 font-bold capitalize"
            onClick={handleClose}
          >
            Ok
          </label>
        </>
      );
    }
    return (
      <>
        <LottieAnimation
          lottieFile={damageCoverLottie}
          isStopped={!isModalOpen}
        />
        <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
          Damage Cover Terms &amp; Conditions
        </h1>
        <p className="text-center text-[16px] md:text-[18px]">
          Reduce your excess to $0 &#40;for over 25s&#41; with Optional Reduced
          Damage Excess Cover for $3.50/hour or $25/day on
          standard&nbsp;vehicles.
          <br />
          <br />
          Standard damage cover excess for drivers aged under 25 is $3000.
          Excess can be reduced on standard vehicles to $1000 for $3.50/hour
          or&nbsp;$25/day.
          <br />
          <br />
          Damage cover excess on Commercial and Prestige vehicles is $4000.
          Excess can be reduced to $500 for over 25s and reduce to $2000 for
          under 25s for $5.00/hour or $40/day.
          <br />
          <br />
          <Link
            className="font-bold text-flexi-orange-1"
            to="/terms-and-conditions"
            target="_blank"
          >
            See Full Terms and Conditions
          </Link>
        </p>

        <button
          className={classNames(
            'button btn mx-auto mt-[25px] mb-5 !h-[50px] w-full rounded-full border-none bg-flexi-green-1 px-10 text-[16px] capitalize md:!h-[60px]',
            { loading: isPending },
          )}
          onClick={() => handleChangeExcess()}
        >
          Confirm
        </button>
      </>
    );
  };

  return (
    <article
      className={classNames(
        'mt-[40px] md:mt-[50px] flex flex-col border-t-[1px] pt-[40px] md:pt-[50px]',
        {
          '!border-none !pt-0': isBusinessAccount,
        },
      )}
    >
      <p
        className={classNames('mb-[20px] text-[22px] md:text-[26px] font-bold', {
          '!mb-[40px] border-b-[1px] pb-[10px] !text-[22px] !font-normal':
            isBusinessAccount,
        })}
      >
        Add-ons and Discounts
      </p>
      <div className="flex flex-col gap-4">
        {user ? (
          <div
            className=" flex w-full md:w-[450px] cursor-pointer flex-col gap-[5px]"
            onClick={() => setModalOpen(true)}
          >
            <CustomCheckBox
              title="Reduce the excess on your damage cover."
              titleClass='text-[16px] md:text-[16px]'
              size={10}
            >
              <input
                type="checkbox"
                id="excess"
                name="excess"
                className="checkbox checkbox-xs pointer-events-none h-[20px] w-[20px] rounded-md"
                checked={hasReduceExcessDamageCover}
                onChange={() => {
                  return 0;
                }}
                style={{
                  // @ts-ignore
                  '--chkfg': '0',
                  '--chkbg': '0',
                  backgroundColor: 'white',
                }}
              />
            </CustomCheckBox>
            <p className="ml-[35px] text-[10px] underline">
              &#40;T&amp;C&apos;s and additional fees apply&#41;
            </p>
          </div>
        ) : (
          renderPending({ width: '300px' })
        )}
      </div>

      {!isBusinessAccount && (
        <p className="mt-[40px] md:mt-[50px] rounded-[10px_10px_10px_10px] p-[30px] text-[12px] leading-[20px]  tracking-[.2px] shadow-[0_2px_9px] shadow-flexi-black-5">
          *A negative balance indicates a possible failed payment for an invoice
          or an account issue. You will be unable to book until this is cleared
          despite credit being available. If you do not see an outstanding
          balance, your account payments are up to date.
        </p>
      )}

      <Modal
        isModalOpen={isModalOpen}
        handleClose={handleClose}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        {renderModalContent()}
      </Modal>
    </article>
  );
};

export default AddOns;
