import React, { useState, Dispatch } from 'react';
import { InvalidText } from 'components/Typography';
import useAppSelector from 'hooks/useAppSelector';
import { selectPendingUpdate } from 'modules/me/slice';
import { useDropzone } from 'react-dropzone';
import { FieldError, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormValues } from './index';
import classNames from 'classnames';
import arrowIcon from 'assets/images/arrow-down.svg';
import { IMAGE_URL } from 'common/constants';
import { IoMdClose } from 'react-icons/io';
import { BiUpload } from 'react-icons/bi';

interface Props {
  register: UseFormRegister<FormValues>;
  errors: {
    experienceYears?: FieldError | undefined;
    hasSuspended?: FieldError | undefined;
    hasConvicted?: FieldError | undefined;
    hasClaimsInsurance?: FieldError | undefined;
  };
  setValue: UseFormSetValue<FormValues>;
  enrollmentDetails: {
    inputUniversity: string;
    inputCampus: string;
    inputCourseDate: string;
  };
}

const StudentExperience = ({ register, errors, setValue, enrollmentDetails, }: Props) => {
  const [hasConviction, setHasConviction] = useState('');
  const [hasSuspension, setHasSuspension] = useState('');
  const [hasClaimInsurance, setHasClaimInsurance] = useState('');

  const [imageSuspensionPreview, setImageSuspensionPreview] =
    useState<any>(null);
  const [imageConvictionPreview, setImageConvictionPreview] =
    useState<any>(null);
  const [imageClaimsPreview, setImageClaimsPreview] = useState<any>(null);
  const isPendingUpdate = useAppSelector(selectPendingUpdate);

  const [dropSelector, setDropSelector] = useState('');
  const [drivingYears, setDrivingYears] = useState('1');
  const [hasSuspensionExplanation, setHasSuspensionExplanation] = useState('');
  const [hasConvictionExplanation, setHasConvictionExplanation] = useState('');
  const [hasClaimInsuranceExplanation, setHasClaimInsuranceExplanation] =
    useState('');

  const onDrop = (acceptedFiles: any) => {
    const convertedFiles = acceptedFiles.map((file: any) => {
      return new File([file], file.name, {
        type: file.type,
      });
    });
    if (dropSelector === 'suspension') {
      setValue('hasSuspendedFile', 1);
      setValue('suspendedDocs', convertedFiles);
      setImageSuspensionPreview(convertedFiles);
    } else if (dropSelector === 'conviction') {
      setValue('hasConvictedFile', 1);
      setValue('convictionDocs', convertedFiles);
      setImageConvictionPreview(convertedFiles);
    } else if (dropSelector === 'claims') {
      setValue('hasClaimsInsuranceFile', 1);
      setValue('claimsInsuranceDocs', convertedFiles);
      setImageClaimsPreview(convertedFiles);
    }
  };

  const handleRemove = (setState: Dispatch<any>, state: any, index: number) => {
    setState(state.filter((_: any, i: number) => i !== index));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <article>
      <h3 className="mb-[30px] text-center text-[20px] font-bold md:text-left  md:text-2xl">
        Tell us about your driving experience
      </h3>

      <div className="flex flex-col gap-y-[30px] text-[16px] font-bold md:text-[16px]">
        <div className="flex items-center justify-between">
          <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
            How many years of independent driving experience do I have?
          </p>
          <div className="flex flex-col items-end">
            <input
              type="number"
              min={0}
              className={classNames(
                'input input-bordered h-[60px] w-[90px] flex-none text-center text-[16px] font-bold',
                {
                  'border-red-500': errors?.experienceYears,
                },
              )}
              {...register('experienceYears')}
              value={drivingYears}
              onChange={(e) => setDrivingYears(e.target.value)}
              style={{
                appearance: 'textfield',
                MozAppearance: 'textfield',
                WebkitAppearance: 'textfield',
              }}
            />
            {errors.experienceYears && (
              <InvalidText>{errors.experienceYears?.message}</InvalidText>
            )}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
              Have I had any license suspensions in the last 3 years?
            </p>

            <div className="dropdown-hover dropdown relative flex flex-col items-end">
              <label
                tabIndex={0}
                className="btn relative m-1 mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-3 hover:border-zinc-300  hover:bg-white active:!scale-[1]"
              >
                <p
                  className={classNames('w-[50%] font-normal capitalize', {
                    'text-transparent': hasSuspension === '',
                  })}
                >
                  {hasSuspension === 'true' ? 'Yes' : 'No'}
                </p>
                <span
                  style={{
                    maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                    WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                    maskRepeat: 'no-repeat',
                    WebkitMaskRepeat: 'no-repeat',
                    WebkitMaskPosition: 'center',
                  }}
                  className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                />
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu absolute right-0 w-[90px] translate-y-[50%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-3"
              >
                <label className="label cursor-pointer justify-start">
                  <input
                    type="radio"
                    className="appearance-none"
                    value={0}
                    onClick={() => setHasSuspension('false')}
                    {...register('hasSuspended')}
                  />
                  <span>No</span>
                </label>
                <label className="label cursor-pointer justify-start">
                  <input
                    type="radio"
                    className="appearance-none"
                    value={1}
                    onClick={() => setHasSuspension('true')}
                    {...register('hasSuspended')}
                  />
                  <span>Yes</span>
                </label>
              </ul>

              {errors.hasSuspended && (
                <InvalidText>{errors.hasSuspended?.message}</InvalidText>
              )}
            </div>
          </div>
          {hasSuspension === 'true' && (
            <div className="mt-2 flex h-full w-full  flex-col overflow-clip  overflow-x-visible transition-all duration-500">
              <p></p>
              <textarea
                {...register('suspendedExplanation')}
                name="suspendedExplanation"
                id="suspendedExplanation"
                rows={5}
                className="textarea textarea-bordered mt-5 mb-5 leading-[1.429]"
                placeholder="Briefly explain the incident &amp; upload any supporting document."
                value={hasSuspensionExplanation}
                onChange={(e) => setHasSuspensionExplanation(e.target.value)}
              />
              {imageSuspensionPreview?.map((image: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_0_5px] shadow-zinc-300"
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="ml-3 w-[35px]"
                        src={URL.createObjectURL(image)}
                        alt=""
                      />
                      <p className="text-sm ">{image.name}</p>
                      <p className="text-xs text-flexi-black-4">
                        {image.size} kb
                      </p>
                    </div>
                    <IoMdClose
                      className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                      onClick={() =>
                        handleRemove(
                          setImageSuspensionPreview,
                          imageSuspensionPreview,
                          i,
                        )
                      }
                    />
                  </div>
                );
              })}
              <div
                {...getRootProps()}
                onMouseOver={() => setDropSelector('suspension')}
                className="relative mb-2 grid h-auto min-h-[150px]  cursor-pointer  overflow-hidden rounded-lg p-1 shadow-[0_0_5px] shadow-zinc-300"
              >
                <input {...getInputProps()} />

                {isDragActive ? (
                  <p className="flex h-full items-center justify-center gap-1">
                    drop here...
                  </p>
                ) : (
                  <div className="h-ful flex flex-col items-center justify-center text-sm">
                    <BiUpload className="text-3xl" />
                    <p className="m-0 pt-[10px] pb-[5px] font-normal">
                      Upload File
                    </p>
                    <p className="mx-[20px] text-center text-[12px]">
                      FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="flex items-center justify-between">
            <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
              Have I had any criminal convictions in the last 3 years?
            </p>
            <div className="dropdown-hover dropdown relative flex flex-col items-end">
              <label
                tabIndex={0}
                className="btn relative m-1 mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-3 hover:border-zinc-300  hover:bg-white active:!scale-[1]"
              >
                <p
                  className={classNames('w-[50%] font-normal capitalize', {
                    'text-transparent': hasConviction === '',
                  })}
                >
                  {hasConviction === 'true' ? 'Yes' : 'No'}
                </p>
                <span
                  style={{
                    maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                    WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                    maskRepeat: 'no-repeat',
                    WebkitMaskRepeat: 'no-repeat',
                    WebkitMaskPosition: 'center',
                  }}
                  className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                />
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu absolute right-0 w-[90px] translate-y-[50%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-3"
              >
                <label className="label flex-1 cursor-pointer justify-start">
                  <input
                    type="radio"
                    className="appearance-none"
                    value={0}
                    onClick={() => setHasConviction('false')}
                    {...register('hasConvicted')}
                  />
                  <span>No</span>
                </label>
                <label className="label cursor-pointer justify-start">
                  <input
                    type="radio"
                    className="appearance-none"
                    value={1}
                    onClick={() => setHasConviction('true')}
                    {...register('hasConvicted')}
                  />
                  <span>Yes</span>
                </label>
              </ul>

              {errors.hasConvicted && (
                <InvalidText>{errors.hasConvicted?.message}</InvalidText>
              )}
            </div>
          </div>
          {hasConviction === 'true' && (
            <div className="mt-2 flex h-full w-full  flex-col overflow-clip  overflow-x-visible transition-all duration-500">
              <textarea
                {...register('convictedExplanation')}
                name="convictedExplanation"
                id="convictedExplanation"
                rows={5}
                className="textarea textarea-bordered mt-5 mb-5 leading-[1.429]"
                placeholder="Briefly explain the incident &amp; upload any supporting document."
                value={hasConvictionExplanation}
                onChange={(e) => setHasConvictionExplanation(e.target.value)}
              ></textarea>

              {imageConvictionPreview?.map((image: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_0_5px] shadow-zinc-300"
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="ml-3 w-[35px]"
                        src={URL.createObjectURL(image)}
                        alt=""
                      />
                      <p className="text-sm ">{image.name}</p>
                      <p className="text-xs text-flexi-black-4">
                        {image.size} kb
                      </p>
                    </div>
                    <IoMdClose
                      className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                      onClick={() =>
                        handleRemove(
                          setImageConvictionPreview,
                          imageConvictionPreview,
                          i,
                        )
                      }
                    />
                  </div>
                );
              })}
              <div
                {...getRootProps()}
                onMouseOver={() => setDropSelector('conviction')}
                className="relative mb-2 grid h-auto min-h-[150px]  cursor-pointer  overflow-hidden rounded-lg p-1 shadow-[0_0_5px] shadow-zinc-300"
              >
                <input {...getInputProps()} />

                {isDragActive ? (
                  <p className="flex h-full items-center justify-center gap-1">
                    drop here...
                  </p>
                ) : (
                  <div className="h-ful flex flex-col items-center justify-center text-sm">
                    <BiUpload className="text-3xl" />
                    <p className="m-0 pt-[10px] pb-[5px] font-normal">
                      Upload File
                    </p>
                    <p className="mx-[20px] text-center text-[12px]">
                      FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="flex items-center justify-between">
            <p className="pr-[30px] md:pr-[60px] lg:pr-[100px]">
              Have I made any claims on insurance for damage to a vehicle in the
              last 3 years?
            </p>
            <div className="dropdown-hover dropdown relative flex flex-col items-end">
              <div>
                <label
                  tabIndex={0}
                  className="btn relative m-1 mr-0 flex h-[60px] w-[90px] animate-none flex-col gap-5 rounded-md border-zinc-300 bg-white text-flexi-black-3 hover:border-zinc-300  hover:bg-white active:!scale-[1]"
                >
                  <p
                    className={classNames('w-[50%] font-normal capitalize', {
                      'text-transparent': hasClaimInsurance === '',
                    })}
                  >
                    {hasClaimInsurance === 'true' ? 'Yes' : 'No'}
                  </p>
                  <span
                    style={{
                      maskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
                      maskRepeat: 'no-repeat',
                      WebkitMaskRepeat: 'no-repeat',
                      WebkitMaskPosition: 'center',
                    }}
                    className="h-[25px] w-[25px] translate-x-[-15px] bg-flexi-orange-1"
                  />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu absolute right-0 w-[90px] translate-y-[0%] cursor-pointer self-center rounded-md rounded-tr-none rounded-tl-none border-[1px] border-zinc-300 border-t-transparent bg-white px-[18px] py-2 text-sm font-bold text-flexi-black-3"
                >
                  <label className="label flex-1 cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={0}
                      onClick={() => setHasClaimInsurance('false')}
                      {...register('hasClaimsInsurance')}
                    />
                    <span>No</span>
                  </label>
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="radio"
                      className="appearance-none"
                      value={1}
                      onClick={() => setHasClaimInsurance('true')}
                      {...register('hasClaimsInsurance')}
                    />
                    <span>Yes</span>
                  </label>
                </ul>
              </div>
              {errors.hasClaimsInsurance && (
                <InvalidText>
                  <p className="whitespace-nowrap text-right">
                    {errors.hasClaimsInsurance?.message}
                  </p>
                </InvalidText>
              )}
            </div>
          </div>
          {hasClaimInsurance === 'true' && (
            <div className="mt-2 flex h-full w-full  flex-col overflow-clip  overflow-x-visible transition-all duration-500">
              <textarea
                {...register('claimsInsuranceExplanation', {
                  // onChange: async(e)=>{
                  //   setHasClaimInsuranceExplanation(e.target.value)
                  // }
                })}
                name="claimsInsuranceExplanation"
                id="claimsInsuranceExplanation"
                rows={5}
                className="textarea textarea-bordered mt-5 mb-5 leading-[1.429]"
                placeholder="Briefly explain the incident &amp; upload any supporting document."
                value={hasClaimInsuranceExplanation}
                onChange={(e) =>
                  setHasClaimInsuranceExplanation(e.target.value)
                }
              ></textarea>
              {imageClaimsPreview?.map((image: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="mb-5  flex h-[75px] w-full items-center justify-between rounded-lg object-contain p-1 font-bold shadow-[0_0_5px] shadow-zinc-300"
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="ml-3 w-[35px]"
                        src={URL.createObjectURL(image)}
                        alt=""
                      />
                      <p className="text-sm ">{image.name}</p>
                      <p className="text-xs text-flexi-black-4">
                        {image.size} kb
                      </p>
                    </div>
                    <IoMdClose
                      className="mr-3 h-[25px] w-[25px] cursor-pointer fill-flexi-orange-1"
                      onClick={() =>
                        handleRemove(
                          setImageClaimsPreview,
                          imageClaimsPreview,
                          i,
                        )
                      }
                    />
                  </div>
                );
              })}
              <div
                {...getRootProps()}
                onMouseOver={() => setDropSelector('claims')}
                className="relative mb-2 grid h-auto min-h-[150px]  cursor-pointer  overflow-hidden rounded-lg p-1 shadow-[0_0_5px] shadow-zinc-300"
              >
                <input {...getInputProps()} />

                {isDragActive ? (
                  <p className="flex h-full items-center justify-center gap-1">
                    drop here...
                  </p>
                ) : (
                  <div className="h-ful flex flex-col items-center justify-center text-sm">
                    <BiUpload className="text-3xl" />
                    <p className="m-0 pt-[10px] pb-[5px] font-normal">
                      Upload File
                    </p>
                    <p className="mx-[20px] text-center text-[12px]">
                      FileTypes: Jpeg, PNG, PDF &#40;max size: 5mb&#41;
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-[50px]">
        <button
          type="submit"
          className={classNames(
            'btn btn-primary !h-[50px] w-full rounded-full px-10 text-[16px] normal-case !text-white hover:bg-flexi-green-2 md:!h-[60px]',
            {
              loading: isPendingUpdate,
            },
          )}
          disabled={
            enrollmentDetails.inputUniversity === '' ||
            enrollmentDetails.inputCampus === '' ||
            enrollmentDetails.inputCourseDate === '' ||
            hasSuspension === '' ||
            hasConviction === '' ||
            hasClaimInsurance === '' ||
            drivingYears === '0' ||
            (hasSuspension === 'true' && !hasSuspensionExplanation) ||
            (hasConviction === 'true' && !hasConvictionExplanation) ||
            (hasClaimInsurance === 'true' && !hasClaimInsuranceExplanation)
          }
        >
          Choose a Plan
        </button>
      </div>
    </article>
  );
};

export default StudentExperience;
