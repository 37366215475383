import React, { Fragment, useState } from 'react';
import { RequestStatusEnum, TAvailableCredits } from 'common/types';
import moment from 'moment';
import Modal from 'components/Modal';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectExpiredCredits,
  selectUser,
  selectUserPlanStatus,
} from 'modules/me/slice';
import { useMediaQuery } from 'react-responsive';
import LoaderText from 'components/LoaderText';

interface Props {
  credits: TAvailableCredits | undefined;
}

const Credits = ({ credits }: Props) => {
  const user = useAppSelector(selectUser);
  const userPlanStatus = useAppSelector(selectUserPlanStatus);
  const expiredCredits = useAppSelector(selectExpiredCredits);
  const planStatusPending = userPlanStatus === RequestStatusEnum.PENDING;
  const expiredRollOverCredits = expiredCredits?.filter(
    (i) => i.description === 'Rollover Credits' && i?.lastCredits,
    );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const renderExpiry = (expiry: string | null | undefined) => {
    if (expiry) {
      return `Expire on ${moment(expiry).format('DD MMMM YYYY')}`;
    }
    return '';
  };

  return (
    <article className="mt-[40px] flex flex-col border-t-[1px] pt-[40px] md:mt-[50px] md:pt-[50px]">
      <div className="mb-[20px] flex items-center justify-between gap-5 font-bold">
        <p className="text-[22px] md:text-[26px]">Available Credits</p>
        {!planStatusPending && (
          <button
            className="cursor-pointer text-right text-[14px] text-primary md:text-[16px]"
            onClick={() => setIsModalOpen(true)}
          >
            See Expired Credits
          </button>
        )}
      </div>
      {planStatusPending ? (
        <LoaderText height="35" width="100%" />
      ) : (
        <div className="flex grid-cols-3 flex-col gap-y-[20px]">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-[2px]">
              <p className="text-[16px] font-bold md:text-[16px] md:font-normal">
                Complimentary Credit
              </p>
              {/* <p className="text-xs text-zinc-400">
              {credits?.complimentaryCredits?.expiry &&
                renderExpiry(credits?.complimentaryCredits?.expiry)}
            </p> */}
            </div>
            <p className=" text-[22px] font-bold">
              ${(credits?.complimentaryCredits?.amount || 0).toFixed(2)}
            </p>
          </div>
          {user?.type.name.toLocaleLowerCase() !== 'student' && (
            <>
              {credits?.driving?.breakdown?.length ? (
                credits.driving.breakdown.map((breakdown, i) => {
                  return breakdown.nowCredits ? (
                    <div key={i} className="flex items-center justify-between">
                      <div className="flex flex-col gap-[2px]">
                        <p className="text-[16px] font-bold md:text-[16px] md:font-normal">
                          Monthly Prepaid Credit
                        </p>
                        <p className="text-xs text-zinc-400">
                          {breakdown.expiresAt &&
                            renderExpiry(breakdown.expiresAt)}
                        </p>
                      </div>
                      <p className=" text-[22px] font-bold">
                        ${(breakdown.nowCredits || 0).toFixed(2)}
                      </p>
                    </div>
                  ) : (
                    <Fragment key={i} />
                  );
                })
              ) : (
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-[2px]">
                    <p className="text-[16px] font-bold md:text-[16px] md:font-normal">
                      Monthly Prepaid Credit
                    </p>
                    <p className="text-xs text-zinc-400">
                      {credits?.driving?.expiry &&
                        renderExpiry(credits?.driving?.expiry)}
                    </p>
                  </div>
                  <p className=" text-[22px] font-bold">
                    ${(credits?.driving?.amount || 0).toFixed(2)}
                  </p>
                </div>
              )}

              {credits?.rollover?.breakdown?.map((breakdown, i) => {
                return breakdown.nowCredits ? (
                  <div key={i} className="flex items-center justify-between">
                    <div className="flex flex-col gap-[2px]">
                      <p className="text-[16px] font-bold md:text-[16px] md:font-normal">
                        Rollover Credit
                      </p>
                      <p className="text-xs text-zinc-400">
                        {breakdown.expiresAt &&
                          renderExpiry(breakdown.expiresAt)}
                      </p>
                    </div>
                    <p className="text-[22px] font-bold">
                      ${breakdown.nowCredits.toFixed(2)}
                    </p>
                  </div>
                ) : (
                  <Fragment key={i} />
                );
              })}
            </>
          )}
        </div>
      )}

      {/* Modal below */}
      <Modal
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <div className="flex w-full flex-col self-center capitalize text-black">
          <h1 className="form-control mb-[50px] text-center text-[20px] font-bold md:text-4xl">
            Expired Credits
          </h1>
          {expiredRollOverCredits && expiredRollOverCredits?.length > 0 ? (
            <>
              <div className="hidden grid-cols-[235px_215px_90px] md:grid  [&>:nth-child(6n+7)]:bg-flexi-black-5 [&>:nth-child(6n+8)]:bg-flexi-black-5 [&>:nth-child(6n+9)]:bg-flexi-black-5">
                <p className="flex h-[60px] items-center border-b-[1px] !bg-flexi-orange-1 px-[20px] text-white">
                  Description
                </p>
                <p className="flex items-center border-b-[1px] !bg-flexi-orange-1  text-white">
                  Expiry Date
                </p>
                <p className="flex items-center border-b-[1px]  !bg-flexi-orange-1 text-white">
                  Amount
                </p>
                {expiredRollOverCredits?.map((item, i) => (
                  <Fragment key={i}>
                    <p className="flex h-[60px] items-center px-[20px]">
                      {item?.description}
                    </p>
                    <p className="flex h-[60px] items-center">
                      {moment(item?.expiresAt).format('DD MMMM YYYY')}
                    </p>
                    <p className="flex h-[60px] items-center">
                      ${item?.lastCredits?.toFixed(2)}
                    </p>
                  </Fragment>
                ))}
              </div>
              {expiredRollOverCredits?.map((item, i) => (
                <Fragment key={i}>
                  <div className="flex border-b-[1px] py-[10px] md:hidden">
                    <div className="flex flex-col">
                      <p className="mb-[2px] text-left text-[14px]">
                        {item?.description}
                      </p>
                      <p className="text-left text-[10px]">
                        {moment(item?.expiresAt).format('DD MMMM YYYY')}
                      </p>
                    </div>
                    <div className="my-auto ml-auto">
                      <p className="text-[14px] font-bold">
                        ${(item?.lastCredits || 0).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </Fragment>
              ))}
            </>
          ) : (
            <p className="text-center text-[16px] md:text-[16px]">
              No expired credits available.
            </p>
          )}
        </div>
      </Modal>
    </article>
  );
};

export default Credits;
