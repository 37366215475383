import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import classNames from 'classnames';

import checkIcon from 'assets/images/wing.svg';
import { IMAGE_URL } from 'common/constants';
import { selectIsSenior, selectUser } from 'modules/me/slice';
import {
  selectVehicleListHourly,
  selectVehicleListDaily,
} from 'modules/vehicle/slice';
import useAppSelector from 'hooks/useAppSelector';
import Modal from 'components/Modal';

const ListAndRates = () => {
  const [listSwitch, setListSwitch] = useState(false);
  const vehicleList = useAppSelector<any>(
    listSwitch ? selectVehicleListDaily : selectVehicleListHourly,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredVehicleList, setFilteredVehicleList] = useState<any>([]);
  const user = useAppSelector(selectUser);
  const isSenior = useAppSelector(selectIsSenior);

  useUpdateEffect(() => {
    if (vehicleList) {
      const newArray = vehicleList.filter(
        (list: any) => list.rates.length > 0 && list,
      );
      setFilteredVehicleList(newArray);
    }
  }, [vehicleList]);

  return (
    <article className=" mb-[50px] grid place-items-center self-center">
      <div className="self-center">
        <label
          className="cursor-pointer font-bold text-flexi-orange-1"
          onClick={() => setIsModalOpen(true)}
        >
          View full vehicle list and usage rates
        </label>
      </div>

      <Modal
        width="1200px"
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <h2 className="title mb-[25px] text-center text-[20px] font-bold md:mb-[60px] md:text-[34px]">
          <span className="capitalize">
            {isSenior ? 'Senior' : 'Personal'} Plans
          </span>
          <br />
          Vehicle Lists and Rates
        </h2>
        <div className="mb-[20px] flex justify-center gap-[25px] text-[12px] md:mb-[40px] md:gap-[60px] md:text-[16px]">
          <button
            onClick={() => setListSwitch(false)}
            className={classNames(
              'flex cursor-pointer gap-2 border-b-[5px] border-transparent pb-[10px] font-bold uppercase outline-none',
              {
                '!border-flexi-orange-1 text-flexi-orange-1': !listSwitch,
              },
            )}
          >
            Hourly
          </button>
          <button
            onClick={() => setListSwitch(true)}
            className={classNames(
              'flex cursor-pointer gap-2 border-b-[5px] border-transparent pb-[10px] font-bold uppercase outline-none',
              {
                '!border-flexi-orange-1 text-flexi-orange-1': listSwitch,
              },
            )}
          >
            Daily
          </button>
        </div>

        {/* VEHICLE LIST */}
        {filteredVehicleList && vehicleList && (
          <div
            className={`scrollbar grid min-h-[350px] w-[calc(100vw-150px)] self-center overflow-auto border-[1px] border-flexi-black-5 bg-zinc-100 md:h-auto md:w-auto`}
            style={{
              gridTemplateColumns: `repeat(${
                filteredVehicleList.length + 1
              },1fr)`,
            }}
          >
            <>
              <div className="sticky  top-0 z-[1] h-[40px] bg-flexi-orange-1 md:h-[55px]"></div>
              {filteredVehicleList.map((list: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="sticky top-0 z-[1] flex h-[40px] items-center gap-1 bg-flexi-orange-1 px-[5px] pl-[5px] font-bold capitalize text-white md:h-[55px] md:px-5"
                  >
                    <span
                      style={{
                        maskImage: `url(${IMAGE_URL}${checkIcon})`,
                        WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
                        maskRepeat: 'no-repeat',
                        WebkitMaskRepeat: 'no-repeat',
                        WebkitMaskPosition: 'center left',
                      }}
                      className="hidden h-[25px] w-[30px] scale-75 bg-white md:flex"
                    />
                    <p className="w-[55px] overflow-hidden text-[12px] capitalize md:w-[100%] md:text-[16px] ">
                      {list.plan !== 'senior' && 'flexi'}
                      {list.plan
                        .toLocaleLowerCase()
                        .replace(`${user?.type?.name}`, '')}
                      {list.plan === 'senior' && ' rates'}
                    </p>
                  </div>
                );
              })}
              <div>
                {filteredVehicleList[0]?.rates.map((rate: any, i: number) => {
                  return (
                    <p
                      className={`flex h-[40px] items-center pl-[10px] text-[12px] font-bold capitalize md:h-[55px] md:pl-[20px] md:text-[16px] ${
                        i % 2 === 0 ? 'bg-white' : 'bg-zinc-100'
                      }`}
                      key={i}
                    >
                      {rate.category}
                    </p>
                  );
                })}
              </div>
              {filteredVehicleList.map((list: any, i: number) => {
                return (
                  <div key={i}>
                    {list.rates.map((rate: any, i2: number) => {
                      return (
                        <div
                          className={`flex h-[40px] items-center pl-2 text-[10px] md:h-[55px] md:text-sm ${
                            i2 % 2 === 0 ? 'bg-white' : 'bg-zinc-100'
                          }`}
                          key={i2}
                        >
                          <p className="translate-x-[5px] md:translate-x-[35px]">
                            ${rate.fees}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          </div>
        )}

        <div className="mt-[20px] flex w-full flex-col place-items-center justify-center gap-y-5 self-center rounded-[10px_10px_10px_10px] p-[10px] text-[8px] shadow-[0_2px_9px] shadow-flexi-black-5 md:mt-[40px] md:w-full md:p-[20px] md:text-[14px]">
          <p>
            Senior rate plans are available to those who held a current Senior
            Card and a copy must be provided to the Member Care Team for
            approval to join on this plan. Please contact info@flexicar.com.au
            for more information on how to join on this plan.?
          </p>
          <p>
            * All annual fees returned as driving credit &#40;currently annual
            fee is not credited back to members as driving credit&#41;. **No
            excess reduction available on Prestige or Commercial vehicles. New
            rates only applicable to new members and any existing switch plans.
            KM limit apply to daily rates.
          </p>
        </div>
      </Modal>
    </article>
  );
};

export default ListAndRates;
