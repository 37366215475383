import React from 'react';

import { ReactComponent as IconSearch } from 'assets/images/search2.svg';

interface Props {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchField = ({ handleSearch }: Props) => {
  return (
    <>
      <input
        type="text"
        placeholder="search"
        onChange={handleSearch}
        className={`peer input input-bordered w-full`}
      />
      <span
        onClick={(e) => e.stopPropagation()}
        className="absolute right-0 grid h-[100%] w-[50px] place-items-center rounded-lg "
      >
        <IconSearch className="fill-flexi-orange-1" />
      </span>
    </>
  );
};

export default SearchField;
