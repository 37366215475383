import React, { Dispatch } from 'react';
import { IPlan } from 'common/constants';
import useAppSelector from 'hooks/useAppSelector';
import { selectUserPlan } from 'modules/me/slice';

interface Props {
  plan: IPlan;
  setIsConfirmChangePlanModal: Dispatch<boolean>;
  setPlanSelected: Dispatch<number>;
}

const Card = ({
  plan,
  setIsConfirmChangePlanModal,
  setPlanSelected,
}: Props) => {
  const userPlan = useAppSelector(selectUserPlan);
  const isCurrentPlan = userPlan?.plan.id === plan.id;

  const handleSelectPlan = (plan: IPlan) => {
    setIsConfirmChangePlanModal(false);
    setPlanSelected(plan.id);
  };

  return (
    <article className="flex max-w-[250px] flex-1 flex-col rounded-xl bg-gray-100 p-8">
      <div className="m-auto h-[100px] w-[100px] rounded-full bg-gray-200" />
      <h3 className="my-5 text-center text-lg font-semibold">{plan.title}</h3>
      <p className="mb-5 text-[12px]">{plan.desc}</p>
      {plan.items.map((i) => (
        <p key={i} className="mb-3 text-xs font-bold">
          {i}
        </p>
      ))}
      {isCurrentPlan ? (
        <button
          onClick={() => handleSelectPlan(plan)}
          className="btn btn-outline btn-secondary mx-auto mt-5 block w-[150px] rounded-full"
          disabled
        >
          Selected
        </button>
      ) : (
        <label
          htmlFor=""
          onMouseUp={() => handleSelectPlan(plan)}
          className="btn btn-outline btn-secondary mx-auto mt-5 flex w-[150px] items-center justify-center rounded-full"
        >
          Select Plan
        </label>
      )}
    </article>
  );
};

export default Card;
