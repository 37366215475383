import flattenDeep from 'lodash/flattenDeep';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { IMapCenter, IRecentSearch } from './types';

export function setDeviceId(deviceId: string): void {
  window.localStorage.setItem('device-id', deviceId);
}

export function removeDeviceId(): void {
  window.localStorage.removeItem('device-id');
}

export function getDeviceId(): string | null {
  return window.localStorage.getItem('device-id');
}

export function setToken(token: string): void {
  window.localStorage.setItem('auth-token', token);
}

export function removeToken(): void {
  window.localStorage.removeItem('auth-token');
}

export function getToken(): string | null {
  return window.localStorage.getItem('auth-token');
}

export function setRefreshToken(token: string): void {
  window.localStorage.setItem('refresh-token', token);
}

export function removeRefreshToken(): void {
  window.localStorage.removeItem('refresh-token');
}

export function getRefreshToken(): void {
  window.localStorage.getItem('refresh-token');
}

export const hasToken = (): boolean => {
  const token = window.localStorage.getItem('auth-token');

  return compact(token).length > 0;
};

export function setResetPasswordEmail(email: string): void {
  window.localStorage.setItem('reset-email', email);
}
export function getResetPasswordEmail(): string | null {
  return window.localStorage.getItem('reset-email');
}
export function removeResetPasswordEmail(): void {
  window.localStorage.removeItem('reset-email');
}
export function setResetPasswordContact(email: string): void {
  window.localStorage.setItem('reset-contact', email);
}
export function getResetPasswordContact(): string | null {
  return window.localStorage.getItem('reset-contact');
}
export function removeResetPasswordContact(): void {
  window.localStorage.removeItem('reset-contact');
}
export function setOtpSent(sent: string): void {
  window.localStorage.setItem('otp-sent', sent);
}
export function getOtpSent(): string | null {
  return window.localStorage.getItem('otp-sent');
}
export function removeOtpSent(): void {
  window.localStorage.removeItem('otp-sent');
}
export function setIsNotified(): void {
  window.localStorage.setItem('login', 'notified');
}
export function getIsNotified(): string | null {
  return window.localStorage.getItem('login');
}
export function removeIsNotified(): void {
  window.localStorage.removeItem('login');
}

export function setIsCourseDueNotified(): void {
  window.localStorage.setItem('course-due', 'notified');
}
export function getIsCourseDueNotified(): string | null {
  return window.localStorage.getItem('course-due');
}
export function removeIsCourseDueNotified(): void {
  window.localStorage.removeItem('course-due');
}

export function setIsIdVerificationNotified(): void {
  window.localStorage.setItem('id-verificatiion', 'notified');
}
export function getIsIdVerificationNotified(): string | null {
  return window.localStorage.getItem('id-verificatiion');
}
export function removeIsIdVerificationNotified(): void {
  window.localStorage.removeItem('id-verificatiion');
}

export function setPreviousUrl(url: string): void {
  window.localStorage.setItem('previousUrl', url);
}
export function getPreviousUrl(): string | null {
  return window.localStorage.getItem('previousUrl');
}
export function removePreviousUrl(): void {
  window.localStorage.removeItem('previousUrl');
}

export const getTimezone = () => {
  // eslint-disable-next-line no-useless-escape
  const timezone = new Date().toString().match(/([-\+][0-9]+)\s/)![1];
  return timezone;
};

export function setStorageDefaultLocation(value: string): void {
  window.localStorage.setItem('default-location', value);
}

export function getStorageDefaultLocation(): IMapCenter | null {
  const location = window.localStorage.getItem('default-location');
  const defaultLocation = location ? JSON.parse(location) : null;
  return defaultLocation;
}

export const hasStorageDefaultLocation = (): boolean => {
  const defaultLocation = window.localStorage.getItem('default-location');
  return compact(defaultLocation).length > 0;
};

export function setStorageRecentSearches(value: string): void {
  window.localStorage.setItem('recent-searches', value);
}

export function getStorageRecentSearches(): IRecentSearch[] | [] {
  const recentSearches = window.localStorage.getItem('recent-searches');
  const recentSearchesParsed = recentSearches ? JSON.parse(recentSearches) : [];
  return recentSearchesParsed;
}

export const hasStorageRecentSearches = (): boolean => {
  const recentSearches = window.localStorage.getItem('default-location');

  return compact(recentSearches).length > 0;
};

export function setInvoiceBlob(blob: string): void {
  window.localStorage.setItem('invoice-blob', blob);
}

export function removeInvoiceBlob(): void {
  window.localStorage.removeItem('invoice-blob');
}

export function getInvoiceBlob(): string | null {
  return window.localStorage.getItem('invoice-blob');
}

export function setCompanyList (company: string[]) {
  window.localStorage.setItem('company',JSON.stringify(company));
}

export function getCompanyList(): any {
  return window.localStorage.getItem('company');
}

export function removeCompanyList(): void {
  window.localStorage.removeItem('company');
}

export function setLoginRedirect(url: string): any {
  return window.sessionStorage.setItem('login_redirect', url);
}

export function getLoginRedirect(): any {
  return window.sessionStorage.getItem('login_redirect');
}

export function removeLoginRedirect(): void {
  window.sessionStorage.removeItem('login_redirect');
}

export function getInvoiceNo(): any {
  return window.sessionStorage.getItem('invoice_no');
}

export function removeInvoiceNo(): void {
  window.sessionStorage.removeItem('invoice_no');
}

export const isEmpty = (value: string | null | undefined): boolean => {
  return isNil(value) || trim(value) === '';
};

export const compact = (values: string | null): string[] => {
  return filter(flattenDeep(values), (value) => !isEmpty(value));
};

export const formatDate = (date = '', format = 'DD/MM/YYYY'): string => {
  const parsedDate = new Date(date);

  if (moment(parsedDate).isValid()) return moment(parsedDate).format(format);

  return '';
};

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const httpConfig = {
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
};

export const toISOStringWithTimezone = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(tzOffset / 60) +
    ':' +
    pad(tzOffset % 60)
  );
};

export const leadingZero = (num: number, size: number) => {
  const s = '000000000' + num;
  return s.substring(s.length - size);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const handleDateChange = (date: Date | null) => {
  if(date){
    const isoDateTime = new Date(date).toISOString()
    return isoDateTime.replace('.000Z','');
    // const [m, d, y] = date.toLocaleDateString().split(/[/]/g);
    // const time = date.toTimeString().split(' ')[0];
    // const utcDateTime = new Date(`${y}-${m}-${d} ${time}`).toUTCString()
    // const isoDateTime = new Date(`${y}-${m}-${d} ${time}`).toISOString()
    // console.log('not utc:', `${y}-${m}-${d}T${time}`)
    // console.log('utc    :',utcDateTime)
    // console.log('iso    :',isoDateTime)
    // console.log('timezone    :',getTimezone())
    // return `${y}-${m}-${d}T${time}`;
    
    // console.log('date iso:',date.toISOString())
    // console.log('conv iso:',new Date(`${y}-${m}-${d} ${time}`).toISOString())
    // console.log('iso    :',isoDateTime)
  }
};

export const handleDateChangeISO = (date: Date | null) => {
  if(date){
    const isoDateTime = new Date(date).toISOString()
    return isoDateTime.replace('.000Z','');
    // const [m, d, y] = date.toLocaleDateString().split(/[/]/g);
    // const time = date.toTimeString().split(' ')[0];
    // console.log('iso    :',isoDateTime)
    
    // console.log('date iso:',date.toISOString())
    // console.log('conv iso:',new Date(`${y}-${m}-${d} ${time}`).toISOString())
  }
};

export const roundToQuarter = (minute: number) => {
  return (Math.floor(minute / 15) * 15) % 60;
}

export const getErrorValue = (error:any) => {
  if(!isEmpty(error))
  return error[Object.keys(error)[0]] ? error[Object.keys(error)[0]][0] : null;
};

export const reloadPageOnce = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const reload = queryParameters.get("reload")
  if(reload==='true'){
    const url = new URL(window.location.href);
    url.searchParams.delete('reload');
    window.location.href = url.toString();
  }
};
