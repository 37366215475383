import React, {useEffect} from 'react';
import Booking from 'features/BookingOptimized';
import { Route, Switch } from 'react-router-dom';
import {
  setLoginModalOpen,
} from 'modules/auth/slice';
import { getToken } from 'common/helpers';
import { useMediaQuery } from 'react-responsive';
import useAppDispatch from 'hooks/useAppDispatch';
import MobileHomePage from 'features/MobileHomePage';
import BookingSummary from 'features/BookingSummaryOptimized';

const BookingPageOptimized = () => {

  const token = getToken();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      dispatch(setLoginModalOpen(false));
    }
  }, [token]);

  const isMobile = useMediaQuery({
    query: '(max-width: 770px)',
  });

  return (
    <Switch>
      <Route exact path="/app/booking">
        {
          isMobile ?
            <section className='mt-[15vh]'>
              <MobileHomePage />
            </section>
            :
            <Booking />
        }
      </Route>
      <Route exact path="/app/booking/summary">
          <BookingSummary />
      </Route>
    </Switch>
  );
};

export default BookingPageOptimized;
