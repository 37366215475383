import classNames from 'classnames';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useRedirectToRegistration } from 'hooks/useRedirect';
// import { useRedirectToRegistration } from 'hooks/useRedirect';
import {
  selectOtpError,
  selectOtpExpiry,
  selectPendingOtp,
  selectPendingOtpExpiry,
  setOtpError,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import moment from 'moment';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import OtpInput from 'react-otp-input';
import gsap from 'gsap';

const RegisterOTP = () => {
  const dispatch = useAppDispatch();
  const [otpCode, setOtpCode] = useState('');
  const isPending = useAppSelector(selectPendingOtp);
  const isPendingExpiry = useAppSelector(selectPendingOtpExpiry);
  const otpExpiry = useAppSelector(selectOtpExpiry);
  const [seconds, setSeconds] = useState(0);
  useRedirectToRegistration();
  const otpError = useAppSelector(selectOtpError);
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  useEffect(() => {
    if (!isPending) {
      dispatch({ type: ACTIONS.FETCH_OTP_EXPIRY });
    }
  }, [isPending]);

  useEffect(() => {
    if (otpExpiry) {
      const date = moment().add(28, 'minutes');
      const millisecondsLeft =
        moment(otpExpiry.expiredAt).valueOf() - date.valueOf();
      const secondsLimit = moment(millisecondsLeft).seconds();
      const minutesLimit = Math.floor(millisecondsLeft / 1000 / 60);
      setSeconds(secondsLimit * (minutesLimit + 1));
    }
  }, [otpExpiry]);

  const handleResend = () => {
    dispatch({ type: ACTIONS.SEND_OTP });
  };

  useEffect(() => {
    if (otpCode.length >= 6) {
      const payload = { code: Number(otpCode) };
      dispatch({ type: ACTIONS.VERIFY_OTP, payload });
    }
  }, [otpCode]);

  useEffect(() => {
    const mySecondInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      // else {
      //   setSeconds(59);
      //   setMinutes(minutes - 1);
      // }
    }, 1000);
    return () => {
      clearInterval(mySecondInterval);
    };
  });

  const [isOtpError, setIsOtpError] = useState(false);

  useEffect(() => {
    if (otpError) {
      setIsOtpError(true);
      dispatch(setOtpError(false));
      setOtpCode('');
    }
  }, [otpError]);

  return (
    <section ref={component} className="fadein flex w-full max-w-[600px] flex-col items-center lg:items-start">
      <h2 className="font-primary mb-[20px] text-center text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]">
        email verification
      </h2>
      <p className="text-[16px]">
        Enter the 6-digit code sent to your email.
      </p>
      <div
        className="
        mt-[30px] flex lg:translate-x-[-7px]
        md:mt-[50px]"
      >
        <OtpInput
          value={otpCode}
          onChange={(otp: any) => setOtpCode(otp)}
          numInputs={6}
          isInputNum
          inputStyle="
            input select-none input-bordered !font-[300]
            md:text-[36px] text-[30px]
            mr-[20px] md:px-[1rem] px-[0]
            border-flexi-black-7 text-flexi-black-3/60
            md:!w-[55px] md:!h-[60px] md:!mx-2
            !w-[45px] !h-[60px] !mx-[5px]"
          shouldAutoFocus
          isDisabled={isPending}
        />
        {isPending && (
          <span className="h-[25px] w-[25px] animate-spin self-center rounded-full border-[2px] border-flexi-black-3 border-t-transparent border-l-transparent" />
        )}
      </div>
      {isOtpError && (
        <p className="mt-[25px] text-[12px] text-[#B71616]">Invalid code</p>
      )}
      {!isPendingExpiry && (
        <p className="mt-[30px] md:mt-[50px] lg:mt-[70px]">
          <span
            className={classNames(
              'pointer-events-auto text-[16px] font-bold text-flexi-orange-1 sm:text-[16px]',
              {
                '  !pointer-events-none !text-flexi-black-4': seconds > 1,
              },
            )}
            onClick={handleResend}
            role="button"
          >
            Resend Code
          </span>{' '}
          {seconds > 0 && `in ${seconds} second${seconds > 1 ? 's' : ''}`}
        </p>
      )}
    </section>
  );
};

export default RegisterOTP;
