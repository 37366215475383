import React from 'react';

interface Props {
  width?: string;
  height?: string;
  otherClass?: string;
  parentClass?: string;
}

const LoaderText = ({ width = '200px', height = '25', otherClass, parentClass }: Props) => {

  return (
    <div className={`flex ${parentClass} `}
    >
      <div
        className={`animate-pulse rounded bg-slate-100 ${otherClass}`}
        style={{
          height: `${height}px`,
          minHeight: `${height}px`,
          width,
          minWidth: width,
        }}
      />
    </div>
  );
};

export default LoaderText;
