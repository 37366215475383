import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';

import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  isSupportedCountry,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import classNames from 'classnames';
import gsap from 'gsap';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { setResetPasswordContact } from 'common/helpers';
import { ICountryCode, RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import { selectUserStatus } from 'modules/me/slice';
import { selectError } from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import COUNTRIES from 'common/countries';
import CustomInput from 'components/CustomInput';

type FormValues = {
  contact: string;
  contactCountry: string;
};

const OptimizedForgotPasswordForm = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);
  const [phone, setPhone] = useState<any>('');
  const [phoneCode, setPhoneCode] = useState<any>('AU');
  const [isInternational, setIsInternational] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>();
  const pending = useAppSelector(selectUserStatus);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const payload = {
      ...values,
    };
    setResetPasswordContact(payload.contact);
    dispatch({
      type: ACTIONS.FORGOT_PASSWORD_SMS,
      payload,
    });
  };

  useEffect(() => {
    setValue('contact', phone);
  }, [phone]);

  const handleMobileSelectChange = (e: any) => {
    if (e.target.value !== 'international') {
      setPhoneCode(e.target.value);
      setPhone('');
      setIsInternational(false);
    } else {
      setIsInternational(true);
    }
  };

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative m-auto mt-[25px] flex min-h-[calc(100vh-65px)] w-full max-w-[80vw] flex-col items-center md:mt-[100px] md:max-w-[500px]"
    >
      {isMobile && (
        <div className="absolute left-0 top-0 flex h-[25px] w-[25px] items-center justify-center rounded-full bg-flexi-orange-1">
          <a href="/">
            <ArrowIcon className="rotate-180 fill-white" />
          </a>
        </div>
      )}
      <h2 className="font-primary mb-[20px] text-center text-[20px] font-[900] uppercase text-primary md:text-[40px]">
        Forgot Password
      </h2>
      <p className="mb-[40px] text-center text-[16px] leading-[1.444] md:text-[18px]">
        Enter the mobile number you signed up with and we will send you
        instructions on how to reset your password.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full grow md:grow-0"
      >
        <div className="form-control w-full grow md:grow-0">
          <CustomInput title="Country Code" error={errors.contactCountry}>
            <select
              onChange={handleMobileSelectChange}
              onClick={(e) => e.stopPropagation()}
              className="peer input input-bordered w-full pr-[50px] pt-[10px] !text-[14px]"
              defaultValue={'AU'}
            >
              {COUNTRIES.map((country: ICountryCode, i: number) => {
                return (
                  isSupportedCountry(country.code) && (
                    <option
                      key={i}
                      value={country.code}
                      className="!mb-[5px] !pb-[5px] text-[14px]"
                    >
                      {country.name} &#91;+{getCountryCallingCode(country.code)}
                      &#93;
                    </option>
                  )
                );
              })}
            </select>
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white"
            >
              <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
            </span>
          </CustomInput>
          {!isEmpty(errors.contactCountry) && (
            <InvalidText>{errors.contactCountry?.message}</InvalidText>
          )}
          <div className="mb-auto mt-[24px]">
            <CustomInput title="Mobile Number" error={errors.contact}>
              <Input
                country={phoneCode}
                international
                withCountryCallingCode={!isInternational}
                value={phone}
                onChange={setPhone}
                placeholder="mobile"
                className={classNames(
                  'peer h-[40px] w-full rounded-b-lg border-[1px] border-b-[1px] border-flexi-black-7 border-t-transparent pl-[15px]',
                  {
                    '!border-red-500 !border-t-transparent': errors?.contact,
                  },
                )}
                maxLength={
                  phoneCode.toLocaleLowerCase() === 'nz' ? 15 : isValidPhoneNumber(phone || `+61`, phoneCode)
                    ? phone.length
                    : 17
                }
              />
            </CustomInput>
            {!isEmpty(errors?.contact) && (
              <InvalidText>Mobile number is required</InvalidText>
            )}
          </div>
          {error && error.data.errorCode.message && (
            <p className="mt-[25px] self-center text-[12px] text-[#B71616]">
              {error.data.errorCode.message}
            </p>
          )}
          <div className="mt-auto md:mt-[70px]">
            <button
              type="submit"
              className={classNames(
                'btn mt-auto h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] normal-case hover:bg-flexi-green-2 disabled:text-white',
                {
                  loading: pending === RequestStatusEnum.PENDING,
                  'pointer-events-none bg-zinc-200': errors.contact,
                },
              )}
              disabled={pending === RequestStatusEnum.PENDING || !phone}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default OptimizedForgotPasswordForm;
