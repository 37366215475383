import React from 'react';
import PublicPageLayout from 'components/layouts/PublicPageLayout';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce } from 'react-use';

const PrivacyPolicyPage = () => {
  const dispatch = useAppDispatch();
  const dynamicContent = useAppSelector(selectDynamicBlockContent);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: ['privacy-policy'],
      },
    });
  });

  return (
    <PublicPageLayout>
      <section className="py-[24px] m-auto flex w-[80%] flex-col text-sm sm:w-[650px] sm:text-base">
        <h2 className="mb-10 text-center text-[24px] md:text-4xl font-bold">Privacy Policy</h2>
        {dynamicContent &&
          dynamicContent.value.map((content, index) => {
            return (
              <div
                key={`c-${index}`}
                className="dynamic-content-container-public flex flex-col gap-y-[20px] text-[14px] md:text-[18px]"
                dangerouslySetInnerHTML={{
                  __html: content.description,
                }}
              />
            );
          })}
      </section>
    </PublicPageLayout>
  );
};

export default PrivacyPolicyPage;
