import React, { useEffect, useState } from 'react';
import { CenteredPageTitle, PageTitleBanner } from 'components/Typography';
import { useMediaQuery } from 'react-responsive';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectEnvType,
  selectPagesVideos,
  selectPagesVideosCategories,
  setPagesVideos,
  setPagesVideosCategories,
  setPublicPending,
} from 'modules/public/slice';
import { IVideo, IVideoCategory, RequestStatusEnum } from 'common/types';
import Slider from 'react-slick';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const HelpfulVideosPage = () => {
  const dispatch = useAppDispatch();
  const videosCategories = useAppSelector(selectPagesVideosCategories);
  const videos = useAppSelector(selectPagesVideos);
  const envType = useAppSelector(selectEnvType);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<IVideo | null>(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const sliderSettings = {
    dots: false,
    slidesToShow: isMobile ? 1 : 3.05,
    infinite: true,
  };

  const getVideosCategories = async () => {
    const db = getFirestore(firebaseApp);
    const collectionName =
      envType === 'staging'
        ? 'pagesContentsVideoCategoriesStaging'
        : 'pagesContentsVideoCategories';
    const collectionRef = collection(db, collectionName);

    const querySnapshot = await getDocs(collectionRef);

    const data: IVideoCategory[] = [];

    querySnapshot.forEach(function (doc) {
      const parsedData = JSON.parse(JSON.stringify(doc.data()));
      data.push({
        id: Number(doc.id),
        icon: parsedData.icon,
        title: parsedData.title,
      });
    });

    if (data.length > 0) {
      dispatch(setPagesVideosCategories(data));
    } else {
      dispatch(setPagesVideosCategories(null));
    }
  };

  const getVideos = async () => {
    const db = getFirestore(firebaseApp);
    const collectionName =
      envType === 'staging'
        ? 'pagesContentsVideosStaging'
        : 'pagesContentsVideos';
    try {
      dispatch(setPublicPending(RequestStatusEnum.PENDING));
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      const data: IVideo[] = [];

      querySnapshot.forEach(function (doc) {
        const parsedData = JSON.parse(JSON.stringify(doc.data()));
        data.push({
          id: Number(doc.id),
          categoryId: parsedData.category_id,
          title: parsedData.title,
          youtubeId: parsedData.youtube_id,
        });
      });

      if (data.length > 0) {
        dispatch(setPagesVideos(data));
      } else {
        dispatch(setPagesVideos(null));
      }
    } catch (error) {
      dispatch(setPublicPending(RequestStatusEnum.FAILED));
    } finally {
      dispatch(setPublicPending(RequestStatusEnum.SUCCESS));
    }
  };

  useEffect(() => {
    if (videosCategories === null) {
      getVideosCategories();
    }
    if (videos === null) {
      getVideos();
    }
  }, [envType]);

  return (
    <section>
      <Helmet>
        <title>Helpful Videos</title>
        <meta name="description" content={`Help & Support - Helpful Videos`} />
      </Helmet>
      <PageTitleBanner>help & support</PageTitleBanner>
      <div className="mx-auto min-h-[100vh] px-[70px] py-[120px] md:px-[120px] lg:px-[180px]">
        <div className="child:text-center mx-auto mb-[100px]">
          <CenteredPageTitle>Helpful Videos</CenteredPageTitle>
        </div>
        {videosCategories && videos ? (
          <div>
            {videosCategories.map((category) => {
              if (
                videos.filter((video) => video.categoryId === category.id)
                  .length > 0
              ) {
                return (
                  <div
                    key={`video-category-${category.id}`}
                    className="mb-[90px]"
                  >
                    <p className="font-primary font-900 tracking-0 mb-[36px] text-[22px] md:text-[30px]">
                      {category.title}
                    </p>
                    {videos.filter((video) => video.categoryId === category.id)
                      .length > 3 || isMobile ? (
                      <Slider
                        {...sliderSettings}
                        className="helpful-videos-slider w-full md:w-[73.75vw]"
                      >
                        {videos
                          .filter((video) => video.categoryId === category.id)
                          .map((video) => {
                            return (
                              <div
                                key={`video-${video.id}`}
                                className="md:px-[20px]"
                              >
                                <img
                                  src={`https://img.youtube.com/vi/${video.youtubeId}/0.jpg`}
                                  alt={video.title}
                                  onClick={() => {
                                    setIsVideoOpen(true);
                                    setSelectedVideo(video);
                                  }}
                                  className="aspect-video object-cover"
                                />
                                <p className="mt-[30px] text-[16px] md:text-[22px]">
                                  {video.title}
                                </p>
                              </div>
                            );
                          })}
                      </Slider>
                    ) : (
                      <div className="flex w-fit">
                        <Slider
                          {...sliderSettings}
                          draggable={false}
                          swipe={false}
                          className="w-full md:w-[73.75vw]"
                        >
                          {videos
                            .filter((video) => video.categoryId === category.id)
                            .map((video) => {
                              return (
                                <div
                                  key={`video-${video.id}`}
                                  className="md:px-[20px]"
                                >
                                  {video.youtubeId ? (
                                    <img
                                      src={`https://img.youtube.com/vi/${video.youtubeId}/1.jpg`}
                                      alt={video.title}
                                      onClick={() => {
                                        setIsVideoOpen(true);
                                        setSelectedVideo(video);
                                      }}
                                      className="aspect-video w-full object-cover md:w-[21vw]"
                                    />
                                  ) : (
                                    <div
                                      onClick={() => {
                                        setIsVideoOpen(true);
                                        setSelectedVideo(video);
                                      }}
                                      className="flex aspect-video w-full items-center justify-center bg-flexi-black-7 md:w-[21vw]"
                                    >
                                      <iframe
                                        src={`https://www.youtube.com/embed/${video.youtubeId}`}
                                        title={video.title}
                                        className="pointer-events-none h-full w-full"
                                      ></iframe>
                                    </div>
                                  )}
                                  <p className="ml-[5px] mt-[30px] text-[16px] md:text-[22px]">
                                    {video.title}
                                  </p>
                                </div>
                              );
                            })}
                          {[
                            ...new Array(
                              4 -
                                videos.filter(
                                  (video) => video.categoryId === category.id,
                                ).length,
                            ),
                          ].map((index) => {
                            return (
                              <div
                                key={`placeholder-${index}`}
                                className="md:px-[20px]"
                              ></div>
                            );
                          })}
                        </Slider>
                      </div>
                    )}
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        ) : (
          <AiOutlineLoading3Quarters
            size="48px"
            className="mx-auto mt-[120px] w-[50px] animate-spin fill-flexi-orange-1"
          />
        )}
      </div>
      {isVideoOpen && selectedVideo && (
        <div
          className="fixed left-0 top-0 h-[100vh] w-[100vw] bg-[#ffffffbb]"
          onClick={() => {
            setIsVideoOpen(false);
            setSelectedVideo(null);
          }}
        >
          <iframe
            src={`https://www.youtube.com/embed/${selectedVideo.youtubeId}`}
            title={selectedVideo.title}
            allow="accelerometer; autoplay; encrypted-media;"
            className="absolute left-[50%] top-[50%] aspect-video h-auto w-[80%] -translate-x-1/2 -translate-y-1/2 lg:w-[70%]"
          ></iframe>
        </div>
      )}
    </section>
  );
};

export default HelpfulVideosPage;
