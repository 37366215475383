import React from 'react';

const PendingLoader = () => {
  return (
    <div className="my-10 grid place-items-center">
      <span className="h-[200px] w-[200px] animate-spin rounded-full border-[10px] border-zinc-300 border-t-transparent"></span>
    </div>
  );
};

export default PendingLoader;
