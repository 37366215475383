import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';

function MyAccountNav({title}: {title:string}) {
  const user = useAppSelector(selectUser)
  return (
    <div className="text-[12px] md:text-[14px] absolute top-[-40px] md:top-[-60px] flex items-center gap-[20px] md:gap-[27px]">
      <NavLink to={`/app/account`}>
        <p>{user?.type.name.toLocaleLowerCase()==='business' ? 'My Business':'Account'}</p>
      </NavLink>
      <IconArrow className="rotate-[-90deg] fill-flexi-black-3 opacity-20" />
      <p className="font-bold capitalize">{title}</p>
    </div>
  )
}

export default MyAccountNav