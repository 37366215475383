import React from 'react';

import classNames from 'classnames';

import { IRegisterPlanInclusions } from 'common/types';

interface Props {
  inclusions: IRegisterPlanInclusions[] | undefined;
}

const Inclusions = ({ inclusions }: Props) => {
  return (
    <article>
      <h2 className="mb-[30px] text-center text-[20px] font-bold md:text-left md:text-3xl">
        What&apos;s included?
      </h2>
      <div className="flex flex-col justify-between">
        {inclusions?.map((inclusion, i) => {
          return (
            <div
              key={inclusion.id}
              tabIndex={0}
              className={classNames(
                'collapse-arrow collapse border-base-300 bg-transparent pr-0 !outline-none',
                {
                  'mb-[30px]': i !== inclusions.length - 1,
                },
              )}
            >
              <input type="checkbox" className="peer absolute" />
              <div className="collapse-title !min-h-0 border-b-[1px] pl-0 pt-0 text-[16px] font-bold after:!right-[0.5rem] after:!top-[10px] after:!shadow-[2px_2px] after:!shadow-flexi-orange-1 md:text-[16px]">
                {inclusion.title}
              </div>
              <div className="collapse-content bg-white px-[20px] md:bg-flexi-black-8">
                <div
                  className="pt-[20px] text-[14px]"
                  dangerouslySetInnerHTML={{ __html: inclusion.description }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </article>
  );
};

export default Inclusions;
