import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import api, { authenticatedClient } from 'app/apiClient';
import { handleAPIError } from 'common/errorHandler';
import { setLoginRedirect } from 'common/helpers';
import { RequestStatusEnum } from 'common/types';
import { setPending } from 'modules/me/slice';
import {
  setEnvType,
  setIsUnsubscribeButtonEnabled,
  setSendEnquiryPending,
} from './slice';

export const SAGA_ACTIONS = {
  MAIL_UNSUBSCRIBE: 'MAIL_UNSUBSCRIBE',
  SEND_HELP_CENTER_MAIL: 'SEND_HELP_CENTER_MAIL',
  ENV_TYPE: 'ENV_TYPE',
};

type MailUnsubscribePayload = {
  subscriptioncode: string;
  reason?: string;
};

export function* mailUnsubscribe(
  action: PayloadAction<MailUnsubscribePayload>,
): any {
  const authApi = authenticatedClient();
  yield put(setPending(true));
  try {
    if (action.payload.subscriptioncode) {
      const { data } = yield call(() =>
        api.post(`/mail-unsubscribe`, action.payload),
      );
      if (action.payload.reason)
        toast.success(data.message, {
          closeButton: true,
          autoClose: false,
        });
    } else {
      if (action.payload.reason) {
        const payload = {
          reason: action.payload.reason,
        };
        const { data } = yield call(() =>
          authApi.post(`/mail-unsubscribe`, payload),
        );
        toast.success(data.message, {
          closeButton: true,
          autoClose: false,
        });
      } else {
        yield call(() => authApi.post(`/mail-unsubscribe`));
      }
    }
    yield put(setPending(false));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.status === 405 || err.response.status === 500) {
        handleAPIError(err.response, true, false);
        setLoginRedirect('/unsubscribe');
        const timeout = setTimeout(() => {
          window.location.replace('/?reload=true');
        }, 2000);
        return () => clearTimeout(timeout);
      } else {
        yield put(setPending(false));
        yield put(setIsUnsubscribeButtonEnabled(false));
        handleAPIError(err.response, true, false);
      }
    }
  }
}

export function* envType(): any {
  yield put(setPending(true));
  try {
    const { data } = yield call(() => api.get(`/setting/env-type`));
    yield put(setEnvType(data));
    yield put(setPending(false));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPending(false));
    }
  }
}

type SendHelpCenterMailPayload = {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};

export function* sendHelpCenterMail(
  action: PayloadAction<SendHelpCenterMailPayload>,
) {
  try {
    yield put(setSendEnquiryPending(RequestStatusEnum.PENDING));
    yield call(() =>
      api.post(
        `/helpcenter/send-help-center-email?name=${
          action.payload.name
        }&email=${encodeURIComponent(
          action.payload.email,
        )}&phone=${encodeURIComponent(action.payload.phone)}&subject=${
          action.payload.subject
        }&message=${encodeURIComponent(action.payload.message)}`,
      ),
    );
    yield put(setSendEnquiryPending(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setSendEnquiryPending(RequestStatusEnum.FAILED));
    }
  }
}
