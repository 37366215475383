import React, {useRef, useLayoutEffect} from 'react';
import { ReactComponent as AddAccountIcon } from 'assets/images/invite_modal.svg';
import AddAccountLayout from 'components/layouts/AddAccountLayout';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import { selectUser } from 'modules/me/slice';
import { useHistory } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import gsap from 'gsap';

function AddAccountCreate() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { push } = useHistory();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  useUpdateEffect(() => {
    if (user?.organisation?.redirectTo === 'payment') {
      push('/app/add-account/payment');
    }
  }, [user]);

  const handleClick = () => {
    dispatch({ type: ACTIONS.CREATE_PERSONAL_ACCOUNT });
  };

  return (
    <AddAccountLayout>
      <div
        ref={component}
        className="fadein relative w-full pt-[40px] md:max-w-[600px]"
      >
        <div className="flex min-h-[calc(100vh-115px)] flex-col items-center md:min-h-[calc(100vh-500px)]">
          <AddAccountIcon className="h-auto w-[120px]" />
          <h1 className="font-primary mt-[30px] mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-[40px] md:leading-[50px]">
            You&apos;re about to create a personal account
          </h1>
          <p className="text-center text-[14px] md:text-[16px]">
            To create a personal account you will need to add your credit card.
          </p>
          <button
            className="btn btn-primary mt-auto h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]"
            onClick={handleClick}
          >
            ok
          </button>
        </div>
      </div>
    </AddAccountLayout>
  );
}

export default AddAccountCreate;
