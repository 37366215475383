export const included = [
  'Free first year annual fee!',
  '$49 a year returned in driving credits to your account',
  'Insurance',
  'Insurance is included in your booking with a Standard Damage Cover of $2000 for standard vehicles / $3000 for Prestige commercial vehicles.<br></br> Option to redice excess to $0 for $2/hour - $14/day. <br></br>Additional charges apply for drivers under 25. Please refer to the fine print',
  'A great selection of cars to choose from!',
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos et neque necessitatibus perspiciatis quam repellendus molestiae asperiores officiis, aperiam magnam.',
  'Senior rate plans are available. Please contact info@flexicar.com.au for more information.',
  'Click here to view the Senior plan full vehicle list and usage rates.',
];
