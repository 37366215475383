import React from 'react';
const Included = () => {
  return (
    <article className="mt-20 border-t-[1px] pt-14 ">
      <h2 className="mb-[50px] text-4xl font-bold ">What&apos;s included?</h2>
      <div className="mb-20 flex justify-between">
        <span className="max-h-[50%] min-h-[50%] w-3/12 bg-zinc-200" />
        <div className="w-8/12 ">
          <p className="mb-3 text-2xl font-bold">Free first year annual fee!</p>
          <p className="mb-7">
            $35 a year returned in driving credit to your account.
          </p>
          <p className="mb-3 text-2xl font-bold">Insurance</p>
          <p className="mb-7">
            Insurance is included in your booking with a Standard Damage Cover
            of $2000 for standard vehicles / $3000 for Prestige commercial
            vehicles.<br></br> Option to redice excess to $0 for $2/hour -
            $14/day. <br></br>Additional charges apply for drivers under 25.
            Please refer to the fine print
          </p>
          <p className="mb-3 text-2xl font-bold">
            A great selection of cars to choose from!
          </p>
          <p className="mb-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
            accusamus unde nam veniam, magni nisi minima excepturi incidunt
            voluptatem similique earum omnis fugit. Expedita officia aut
            impedit, ullam quam inventore laudantium consequatur voluptates ex
            et? Voluptatibus, tempora sapiente dicta laborum sunt totam saepe
            sequi blanditiis, earum maxime ea sed atque?
          </p>
        </div>
      </div>
      <h6 className="text-2xs">
        *Hourly and daily rates quoted are based on economy cars like the suzuki
        Swift. Tolls are not included in the rates. There is 0.25c admin fee for
        processing of each toll.
        <br></br>Reduce excess to $0 with Optional Reduced Damage Excess Cover
        for $2/hour or $14/day on standard vehicles. Damage cover excess for
        drivers aged under 25 is $3000. Excess can be reduced on standard
        vehicles to $1000 for $2.hour or $14/day. Damage cover excess on
        Commercial and Prestige vehicles is $3000. There is no excess reduction
        available on Commercial or Prestige vehicles. For full terms and
        conditions please{' '}
        <span className="cursor-pointer font-bold underline">click here</span>.
      </h6>
    </article>
  );
};

export default Included;
