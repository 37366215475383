import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AddAccountCreate from 'features/AddAccountCreate';
// import AddAccountUpdateEmail from 'features/AddAccountUpdateEmail';
import AddAccountPayment from 'features/AddAccountPayment';
import AddAccountDone from 'features/AddAccountDone';
// import AddAccountOTP from 'features/AddAccountOTP';
// import AddAccountSelectPlan from 'features/AddAccountSelectPlan';

const AddAccountPage = () => {
 
  return (
    <Switch>
      {/* <Route
        exact
        path="/app/add-account/email"
        component={AddAccountUpdateEmail}
      /> */}
      {/* <Route
        exact
        path="/app/add-account/plan"
        component={AddAccountSelectPlan}
      /> */}
      <Route
        exact
        path="/app/add-account/payment"
        component={AddAccountPayment}
      />
      {/* <Route exact path="/app/add-account/otp" component={AddAccountOTP} /> */}
      <Route exact path="/app/add-account/done" component={AddAccountDone} />
      <Route path="/app/add-account" component={AddAccountCreate} />
    </Switch>
  );
};

export default AddAccountPage;
