import React, { useRef, useLayoutEffect } from 'react';

import gsap from 'gsap';

import DefaultForm from './DefaultForm';
import Guidelines from './Guidelines';

const SignUpBusinessForm = () => {
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex w-full flex-col pt-[40px] md:max-w-[600px]"
    >
      <div className="relative m-auto min-h-[calc(100vh)] w-full max-w-[1000px]">
        <h2 className="font-primary mb-[40px] text-center text-[30px] font-[900] uppercase leading-[36px] text-flexi-orange-1 sm:text-[40px] sm:leading-[70px] md:mb-[50px] md:text-left md:text-[60px]">
          Sign Up to Flexicar Business
        </h2>
        <Guidelines />
        <p className="pb-[24px] pt-[40px] text-[26px] font-bold">
          Business Information
        </p>
        <DefaultForm accountType={1} />
        <div className="mt-[40px] text-center">
          <div className="flex items-center justify-center gap-[5px]">
            <div className="h-[1px] w-[61px] bg-[#979797]" />
            <p className="text-[12px]">or</p>
            <div className="h-[1px] w-[61px] bg-[#979797]" />
          </div>
          <p className="mt-[32px] text-[14px] font-bold">
            <a href="/register-v2" className="text-primary">
              Click here
            </a>{' '}
            to register for a personal account.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SignUpBusinessForm;
