import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import { doc, getFirestore } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import firebaseApp from 'app/firebaseApp';
import classNames from 'classnames';
import * as _ from 'lodash';
import gsap from 'gsap';

import { ReactComponent as IconMonthZero } from 'assets/images/plan_monthly_zero.svg';
import { ReactComponent as IconMonth } from 'assets/images/plan_monthly.svg';
import { ReactComponent as IconHour } from 'assets/images/plan_hourly.svg';
import { ReactComponent as IconDay } from 'assets/images/plan_daily.svg';
import { IPlanTypeDetails, RequestStatusEnum } from 'common/types';
import {
  selectPlanType,
  selectPlanTypes,
  selectRegisterStatus,
  setPlanType,
  setRegisterStatus,
} from 'modules/auth/slice';
import { selectUser, setIsSenior } from 'modules/me/slice';
import { selectPending } from 'modules/vehicle/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';

const SignUpPlan = () => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector(selectPlanTypes);
  const user = useAppSelector(selectUser);
  const isPending = useAppSelector(selectPending);
  const isPendingPlanSelection = useAppSelector(selectRegisterStatus);
  const selectedPlan = useAppSelector(selectPlanType);
  const [redirectTo, setRedirectTo] = useState('');
  const [status, setStatus] = useState('');
  const component = useRef<HTMLDivElement>(null);
  const { push } = useHistory();

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [value] = useDocument(
    user?.ocrId ? doc(getFirestore(firebaseApp), 'ocr', user.ocrId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffectOnce(() => {
    if (user && user?.type.id !== 2) {
      dispatch({ type: ACTIONS.PLAN_RESET });
    }
  });

  useEffect(() => {
    if (user && value && JSON.stringify(value.data())) {
      const redirectData = value.data()?.redirectTo;
      const statusData = value.data()?.event;
      setRedirectTo(redirectData);
      setStatus(statusData);
    }
  }, [value, user]);

  const handlePlanSelection = () => {
    if (selectedPlan?.name === 'student') {
      push('/register-v2/plan-student');
    } else {
      if (selectedPlan) {
        dispatch(setIsSenior(selectedPlan?.isSenior));
        dispatch({
          type: ACTIONS.REGISTER_PLAN_TYPE_SELECTED_V2,
          payload: {
            typeId: selectedPlan?.id,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!plans) {
      if (
        redirectTo.toLocaleLowerCase().includes('declined-v2') ||
        user?.status === 'declined-v2'
      ) {
        push('/register-v2/verify-failed');
      } else if (
        user?.type.id === 2 &&
        user?.role === 'driver' &&
        redirectTo === 'verify-done'
      ) {
        push(`/register-v2/done`);
      } else {
        if (user?.type.id !== 2) {
          dispatch({ type: ACTIONS.REGISTER_PLAN_TYPE_V2 });
        }
      }
    }
  }, [status, redirectTo, user]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_ME });
    dispatch(setRegisterStatus(RequestStatusEnum.CANCELLED));
  });

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, [isPending, redirectTo, status, plans]);

  return (
    <>
      {isPending || plans === null ? (
        <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
          <PendingLoader size={100} />
        </section>
      ) : (
        <section
          ref={component}
          className="fadein relative flex min-h-[calc(100vh-90px)] w-full max-w-[700px] flex-col pt-[43px] md:min-h-[calc(100vh-388px)]"
        >
          {isMobile ? (
            <h2 className="font-primary mb-[40px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:text-left md:text-[40px]">
              choose the plan
              <br />
              that&apos;s right for you
            </h2>
          ) : (
            <div className="mb-[40px]">
              <h2 className="font-primary mb-[40px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:text-[28px]">
                plan selection
              </h2>
              <p className="text-[18px] font-bold">
                Choose the plan that&apos;s right for you
              </p>
            </div>
          )}
          <div className="grid grid-cols-1 gap-[16px]">
            {_.map(
              _.get(plans, 'planTypes'),
              (plan: IPlanTypeDetails, i: number) => {
                return (
                  <div
                    key={i}
                    className={classNames(
                      'h-fit grid w-full grid-cols-1 rounded-[10px] bg-white shadow-md duration-500',
                      {
                        '!bg-flexi-orange-1 !text-white':
                          selectedPlan?.name === plan.name,
                      },
                    )}
                    onClick={() => {
                      if (selectedPlan?.name !== plan.name) {
                        dispatch(setPlanType(plan));
                      } else {
                        dispatch(setPlanType(null));
                      }
                    }}
                  >
                    <div
                      className={classNames(
                        'flex h-[104px] w-full items-center justify-between  ',
                      )}
                    >
                      <div className="ml-[39px] font-bold capitalize">
                        Flexi {plan.name}
                      </div>
                      <div className="mr-[25px]">
                        <p
                          className={classNames(
                            'h-[50px] text-center text-[40px] tracking-[-2px] text-flexi-orange-1',
                            {
                              '!text-white': selectedPlan?.name === plan.name,
                            },
                          )}
                        >
                          ${plan.fees}
                        </p>
                        <p className="text-center text-[10px]">*Yearly</p>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'mx-[25px] grid grid-cols-1 border-t-[1px] border-[#F2F2F2] pb-[25px]',
                        {
                          hidden: selectedPlan?.name !== plan.name,
                        },
                      )}
                    >
                      <div className="mt-[30px] flex items-center gap-x-[20px]">
                        {plan?.name.toLocaleLowerCase() === 'sometimes' ? (
                          <IconMonthZero className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
                        ) : (
                          <IconMonth className="min-h-[30px] min-w-[30px] fill-white md:min-h-[40px] md:min-w-[40px]" />
                        )}
                        <div>
                          <p className="mb-[2px] text-[16px] font-bold md:mb-[10px] md:text-[18px]">
                            {plan?.name.toLocaleLowerCase() === 'sometimes'
                              ? 'No Commitment'
                              : 'Monthly Commitment'}
                          </p>
                          <p className="text-[14px] md:text-[16px]">
                            {plan?.commitment}
                          </p>
                        </div>
                      </div>
                      <div className="mt-[30px] flex items-center gap-x-[20px]">
                        <IconDay className="min-h-[30px] min-w-[30px] fill-white md:min-h-[40px] md:min-w-[40px]" />
                        <div>
                          <p className="mb-[2px] text-[16px] font-bold md:mb-[10px] md:text-[18px]">
                            Daily Cost
                          </p>
                          <p className="text-[14px] md:text-[16px]">
                            {plan?.dailyNotes}
                          </p>
                        </div>
                      </div>

                      <div className="mt-[30px] flex items-center gap-x-[20px]">
                        <IconHour className="min-h-[30px] min-w-[30px] fill-white md:min-h-[40px] md:min-w-[40px]" />
                        <div>
                          <p className="mb-[2px] text-[16px] font-bold md:mb-[10px] md:text-[18px]">
                            Hourly Cost
                          </p>
                          <p className="text-[14px] md:text-[16px]">
                            {plan?.hourlyNotes}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
          <p className="mb-[24px] mt-auto block pt-[24px] text-center text-[10px] md:hidden">
            *Annual fee returned as driving credits
          </p>
          <div className="mt-auto w-full pt-[63px]">
            <button
              className="btn-primary btn h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:mt-0 md:h-[60px]"
              onClick={() => handlePlanSelection()}
              disabled={
                selectedPlan === null || isPendingPlanSelection === 'pending'
              }
            >
              Select Plan
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default SignUpPlan;
