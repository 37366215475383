import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SignupTopNav } from 'components/TopNav';
import { reloadPageOnce } from 'common/helpers';
import { Helmet } from 'react-helmet';
import OptimizedForgotPasswordForm from 'features/OptimizedForgotPasswordForm';
import OptimizedForgotPasswordOTP from 'features/OptimizedForgotPasswordOTP';
import OptimizedForgotPasswordOTPSuccess from 'features/OptimizedForgotPasswordOTPSuccess';
import OptimizedForgotPasswordConfirmSuccess from 'features/OptimizedForgotPasswordConfirmSuccess';
import OptimizedForgotPasswordConfirm from 'features/OptimizedForgotPasswordConfirm';

const AuthPageOptimized = () => {
  reloadPageOnce()


  return (
    <div className="absolute grid w-full bg-[#F2F2F2] md:bg-white min-h-[100vh]">
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="Flexicar Forgot Passwords" />
      </Helmet>
      {!window.location.href.includes('/login') && <SignupTopNav />}
      <Switch>
        <Route
          exact
          path="/auth-v2/forgot-password"
          component={OptimizedForgotPasswordForm}
        />
        <Route
          exact
          path="/auth-v2/forgot-password-otp"
          component={OptimizedForgotPasswordOTP}
        />
        <Route
          exact
          path="/auth-v2/forgot-password-otp-success"
          component={OptimizedForgotPasswordOTPSuccess}
        />
        <Route
          exact
          path="/auth-v2/forgot-password-confirm"
          component={OptimizedForgotPasswordConfirm}
        />
        <Route
          exact
          path="/auth-v2/forgot-password-confirm-success"
          component={OptimizedForgotPasswordConfirmSuccess}
        />
      </Switch>
    </div>
  );
};

export default AuthPageOptimized;
