import React, { Dispatch } from 'react';
// import useAppSelector from 'hooks/useAppSelector';
// import { selectCampusList } from 'modules/me/slice';
// import { useDropzone } from 'react-dropzone';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { FormValues } from './index';
import { InvalidText } from 'components/Typography';
import { ReactComponent as IconCalendar } from 'assets/images/calendar.svg';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import { selectUserInfo } from 'modules/me/slice';
// import { IoMdClose } from 'react-icons/io';
// import { BiUpload } from 'react-icons/bi';

interface Props {
  register: UseFormRegister<FormValues>;
  errors: {
    university?: FieldError | undefined;
    campus?: FieldError | undefined;
    courseEndDate?: FieldError | undefined;
  };
  hasUniversity: boolean;
  enrollmentDetails: {
    inputUniversity: string;
    inputCampus: string;
    inputCourseDate: string;
  };
  enrollmentSet: {
    setInputUniversity: Dispatch<string>;
    setInputCampus: Dispatch<string>;
    setInputCourseDate: Dispatch<string>;
  };
}

const StudentEnrollment = ({
  register,
  errors,
  hasUniversity,
  enrollmentDetails,
  enrollmentSet,
}: Props) => {
  const userInfo = useAppSelector(selectUserInfo);

  return (
    <article className="mb-10 flex flex-col gap-4 border-b-[1px] border-t-[1px] border-[#DDD] py-12 text-[16px] md:text-[16px]">
      <input type="checkbox" id="edit-address-modal" className="modal-toggle" />
      <div className="flex-col gap-[20px] md:flex">
        <div className="flex w-full justify-between">
          <label className="label place-self-start text-[18px]">
            University
          </label>
          <div>
            {hasUniversity && userInfo ? (
              <p className="input input-bordered flex w-[450px] items-center">
                {/* {getValues('university')} */}
                {userInfo.university}
              </p>
            ) : (
              <input
                type="text"
                className={classNames('input input-bordered w-[450px]', {
                  'border-red-500': errors?.university,
                })}
                {...register('university',{
                  onChange: async(e)=>{
                    enrollmentSet.setInputUniversity(e.target.value)
                  }
                })}
                value={enrollmentDetails.inputUniversity}
              />
            )}
            {errors.university && (
              <InvalidText>{errors.university?.message}</InvalidText>
            )}
          </div>
        </div>

        <div className="flex w-full justify-between">
          <label className="label place-self-start text-[18px]">Campus</label>
          <div>
            {/* {hasUniversity ? (
              <select
                className={classNames('select input-bordered w-[450px]', {
                  'border-red-500': errors?.campus,
                })}
                {...register('campus')}
                defaultValue=""
              >
                <option disabled value="">
                  Select your campus
                </option>
                {campuses?.map((campus) => (
                  <option value={campus.name} key={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            ) : ( */}
            <input
              type="text"
              className={classNames('input input-bordered w-[450px]', {
                // 'border-red-500': errors?.campus,
              })}
              value={enrollmentDetails.inputCampus}
              {...register('campus',{
                onChange: async(e)=>{
                  enrollmentSet.setInputCampus(e.target.value)
                }
              })}
            />
            {/* // )} */}

            {errors.campus && (
              <InvalidText>{errors.campus?.message}</InvalidText>
            )}
          </div>
        </div>
        <div className="flex w-full justify-between">
          <label className="label place-self-start text-[18px]">
            Course End Date
          </label>
          <div>
            <div className="relative flex items-center justify-center">
              <input
                type="text"
                placeholder="mm/dd/yyyy"
                id="inputStartDate"
                className={classNames(
                  'input input-bordered w-[450px] cursor-default bg-transparent',
                  {
                    'border-red-500': errors?.courseEndDate,
                  },
                )}
                value={enrollmentDetails.inputCourseDate}
                {...register('courseEndDate',{
                  onChange: async(e)=>{
                    enrollmentSet.setInputCourseDate(e.target.value)
                  }
                })}
                onBlur={(e) => {
                  !e.target.value && (e.target.type = 'text');
                }}
                onFocus={(e) => {
                  e.target.type = 'date';
                }}
              />
              <span className="absolute right-0 top-0 z-[-1] grid h-[100%] w-[40px]  place-items-center rounded-lg">
                <IconCalendar className=" scale-[1.5]  fill-flexi-orange-1" />
              </span>
            </div>
            {errors.courseEndDate && (
              <InvalidText>{errors.courseEndDate?.message}</InvalidText>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default StudentEnrollment;
