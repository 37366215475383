import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectPendingUpdate,
  selectPlans,
  selectPlansStatus,
  selectUser,
} from 'modules/me/slice';
import PendingLoader from 'components/PendingLoader';
import Card from './Card';
import Inclusions from './Inclusions';
import { IMAGE_URL } from 'common/constants';
import ListAndRates from './ListAndRates';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import classNames from 'classnames';
import iconSometimes from 'assets/images/personal_sometimes.svg';
import iconOften from 'assets/images/personal_often.svg';
import iconFrequent from 'assets/images/personal_frequent.svg';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { IRegisterPlan, RequestStatusEnum } from 'common/types';
import { setErrorPromo } from 'modules/transaction/slice';
import { useRedirectToRegistration } from 'hooks/useRedirect';
import { getToken } from 'common/helpers';
import gsap from 'gsap';

const RegisterPlans = () => {
  const [planSelected, setPlanSelected] = useState<IRegisterPlan | null>(null);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPendingUpdate);
  const plans = useAppSelector(selectPlans);
  const plansStatus = useAppSelector(selectPlansStatus);
  const isPersonalAccount = user?.type.name.toLocaleLowerCase() === 'personal';
  const token = getToken();
  useRedirectToRegistration();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  const handleSelectPlan = (plan: number) => {
    const payload = { planId: plan };
    dispatch({ type: ACTIONS.CHOOSE_USER_PLAN, payload });
  };

  useEffect(() => {
    if (user && token) {
      dispatch({ type: ACTIONS.FETCH_VEHICLE_LIST });
    }
  }, [user]);

  useEffectOnce(() => {
    if (token) dispatch({ type: ACTIONS.GET_PLANS });
    dispatch(setErrorPromo(null));
  });

  useUpdateEffect(() => {
    if (plans && user?.plan) setPlanSelected(plans[user?.plan?.id - 1]);
    else setPlanSelected(plans[0]);
  }, [plans]);

  return (
    <section ref={component} className="fadein flex w-full max-w-[700px] flex-col items-center lg:items-start">
      <h2
        className={classNames(
          'font-primary text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]',
          {
            'mb-[40px]': !isPersonalAccount,
          },
        )}
      >
        {isPersonalAccount ? 'Select a plan' : `flexi ${user?.type.name}`}
      </h2>

      {user && plansStatus !== RequestStatusEnum.PENDING ? (
        <div className="flex flex-col items-start">
          {isPersonalAccount && (
            <ul className="my-[30px] flex w-full justify-center gap-[40px] pt-0 sm:gap-[40px] md:my-[50px] md:w-auto md:justify-between md:gap-[60px]">
              {plans.map((plan: IRegisterPlan) => {
                return (
                  <li
                    key={plan.id}
                    className={classNames(
                      'flex flex-col items-center justify-center gap-x-[3px] border-b-[5px] border-transparent pb-0 text-[16px] font-bold uppercase sm:gap-x-[10px] sm:text-[16px] md:pb-[14px] lg:flex-row',
                      {
                        '!border-flexi-orange-1 text-flexi-orange-1':
                          planSelected?.id === plan.id,
                        'opacity-30': planSelected?.id !== plan.id,
                      },
                    )}
                    onClick={() => setPlanSelected(plan)}
                    role="button"
                  >
                    <img
                      src={`${IMAGE_URL}${
                        plan.name === 'sometimes'
                          ? iconSometimes
                          : plan.name === 'often'
                          ? iconOften
                          : iconFrequent
                      }`}
                      alt={plan.name}
                      className="w-[30px] sm:w-max"
                    />
                    <p className="md:self-end text-[14px] md:text-[16px]">
                      {plan?.name.toLocaleLowerCase().replace(user?.type.name, '').replace('default','').replace(' ', '')}
                    </p>
                  </li>
                );
              })}
            </ul>
          )}

          <div className="flex justify-center">
            <Card
              plan={planSelected}
              key={planSelected?.name}
              isPersonalAccount={isPersonalAccount}
            />
          </div>

          <ListAndRates 
              isPersonalAccount={isPersonalAccount}
          />
          <Inclusions inclusions={planSelected?.inclusions} />

          <button
            onClick={() => handleSelectPlan(planSelected!.id)}
            className={classNames(
              'btn btn-primary mt-[70px] !h-[50px] w-full rounded-full text-[16px] capitalize hover:bg-flexi-green-2 md:!h-[60px]',
              {
                'loading bg-flexi-green-2': isPending,
              },
            )}
          >
            {isPersonalAccount ? 'select plan' : 'proceed'}
          </button>
        </div>
      ) : (
        <PendingLoader />
      )}
    </section>
  );
};

export default RegisterPlans;
