import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import gsap from 'gsap';

import { ReactComponent as IconError } from 'assets/images/error.svg';
import { ReactComponent as IconSearch } from 'assets/images/search2.svg';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import { ReactComponent as IconCalendar } from 'assets/images/calendar.svg';
import { ReactComponent as IconDownload } from 'assets/images/download.svg';
import { getInvoiceNo } from 'common/helpers';
import { IInvoice, RequestStatusEnum } from 'common/types';
import { PageTitle } from 'components/Typography';
import {
  selectInvoiceList,
  selectPendingInvoiceList,
} from 'modules/booking/slice';
import {
  selectOutstandingBalanceStatus,
  selectOutstandingBalances,
  selectPaySingleInvoiceStatus,
  selectPendingPaySingleInvoice,
  setPaySingleInvoiceStatus,
} from 'modules/invoice/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';
import MyAccountNav from 'components/MyAccountNav';
import CustomInput from 'components/CustomInput';
import Modal from 'components/Modal';
import InvoicesTable from './InvoicesTable';
import Pagination from './Pagination';

const MyAccountInvoices = () => {
  const dispatch = useAppDispatch();
  const downloadInvoiceNo = getInvoiceNo() || null;
  const invoicesList = useAppSelector(selectInvoiceList);
  const isPendingInvoice = useAppSelector(selectPendingInvoiceList);
  const fetchStatus = useAppSelector(selectOutstandingBalanceStatus);
  const paymentStatus = useAppSelector(selectPendingPaySingleInvoice);
  const outstandingBalances = useAppSelector(selectOutstandingBalances);
  const paySingleInvoiceStatus = useAppSelector(selectPaySingleInvoiceStatus);
  const [selectedOBInvoices, setSelectedOBInvoices] = useState<IInvoice[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<IInvoice[]>([]);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [burgerOBActive, setBurgerOBActive] = useState<number>(-1);
  const [burgerActive, setBurgerActive] = useState<number>(-1);
  const [itemsPerPageOB, setItemsPerPageOB] = useState(5);
  const [currentPageOB, setCurrentPageOB] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [amountPaid, setAmountPaid] = useState(0);
  const [total, setTotal] = useState(0);
  const { push } = useHistory();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffect(() => {
    if (downloadInvoiceNo) {
      dispatch({
        type: ACTIONS.DOWNLOAD_INVOICE,
        payload: { ids: [downloadInvoiceNo] },
      });
    }
  }, [downloadInvoiceNo])

  useEffect(() => {
    handleRequestInvoices(itemsPerPage, currentPage);
    handleRequestOBInvoices(itemsPerPageOB, currentPageOB);
  }, [startDate, endDate, searchString]);

  const handleRequestInvoices = (limit: number, page: number) => {
    const payload = {
      page: page || 1,
      limit: limit,
      search: searchString,
      startDate,
      endDate,
    };
    dispatch({ type: ACTIONS.FETCH_INVOICE_LIST, payload });
  };

  const handleRequestOBInvoices = (limit: number, page: number) => {
    const payload = {
      page: page || 1,
      limit: limit,
      search: searchString,
      startDate,
      endDate,
    };
    dispatch({ type: ACTIONS.FETCH_OUSTANDING_BALANCES, payload });
  };

  const handleCheckBoxSingle = (e: any, data: IInvoice) => {
    if (e.target.checked) {
      const newSelected: any = [...selectedInvoices];
      newSelected.push(data);
      setSelectedInvoices(newSelected);
    } else {
      const newSelected: any = [...selectedInvoices];
      const removeIds = newSelected.filter(
        (newSelected: IInvoice) => newSelected !== data,
      );
      setSelectedInvoices(removeIds);
    }
  };

  const handleCheckBoxSingleOB = (e: any, data: IInvoice) => {
    if (e.target.checked) {
      const newSelected: any = [...selectedOBInvoices];
      newSelected.push(data);
      setSelectedOBInvoices(newSelected);
    } else {
      const newSelected: any = [...selectedOBInvoices];
      const removeIds = newSelected.filter(
        (newSelected: IInvoice) => newSelected !== data,
      );
      setSelectedOBInvoices(removeIds);
    }
  };

  const paySelectedInvoices = () => {
    setAmountPaid(
      selectedOBInvoices.reduce((sum, invoice) => {
        return sum + invoice.amount;
      }, 0),
    );
    const invoicesIdArray = selectedOBInvoices.map((invoice: IInvoice) => {
      return invoice.id;
    });
    const payload = {
      ids: invoicesIdArray,
    };
    dispatch({ type: ACTIONS.PAY_SELECTED_INVOICE, payload });
  };

  const paySingleInvoice = (invoice: IInvoice) => {
    setAmountPaid(invoice.amount);
    dispatch({
      type: ACTIONS.PAY_SINGLE_INVOICE,
      payload: { invoiceId: invoice.id },
    });
  };

  const handleDownloadInvoices = () => {
    const selectedIds = selectedInvoices.map((invoice) => invoice.id);
    dispatch({
      type: ACTIONS.DOWNLOAD_INVOICE,
      payload: { ids: selectedIds },
    });
  };

  const fetchInvoices = () => {
    const payload = {
      page: 1,
      limit: 5,
    };
    dispatch({ type: ACTIONS.FETCH_INVOICE_LIST, payload });
    dispatch({ type: ACTIONS.FETCH_OUSTANDING_BALANCES, payload });
  };

  useEffectOnce(() => {
    fetchInvoices();
  });

  useEffect(() => {
    if (paySingleInvoiceStatus === RequestStatusEnum.FAILED) {
      dispatch(setPaySingleInvoiceStatus(null));
    }
  }, [paySingleInvoiceStatus]);

  useEffect(() => {
    if (selectedOBInvoices.length > 0) {
      let sum = 0;
      selectedOBInvoices.forEach(function (o) {
        sum = sum + o.amount;
      });
      setTotal(sum);
    } else {
      setTotal(0);
    }
  }, [selectedOBInvoices]);

  useEffect(() => {
    setSelectedInvoices([]);
    setSelectedOBInvoices([]);
  }, [invoicesList, outstandingBalances]);

  return (
    <article
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] min-h-[50vh] pb-[100px] md:mx-0 md:mt-[100px]"
      onClick={() => {
        setBurgerActive(-1);
        setBurgerOBActive(-1);
      }}
    >
      <Helmet>
        <title>My Account - Invoices</title>
        <meta name="description" content="My Flexicar Account - Invoices" />
      </Helmet>

      <MyAccountNav title="invoices" />

      <div className="mt-[40px] w-full md:mt-[55px]">
        <div className="md:flex md:gap-[20px]">
          <div className="w-full flex-col">
            <CustomInput title="Search" margin={20}>
              <input
                type="text"
                onChange={(e) => setSearchString(e.target.value)}
                placeholder=" "
                className="peer input input-bordered w-full pr-[50px] lg:min-w-[480px]"
              />
              <span className="pointer-events-none absolute right-1 top-[50%] grid h-[90%] w-[40px] translate-y-[-50%] place-items-center rounded-lg !bg-white">
                <IconSearch className="fill-flexi-orange-1" />
              </span>
            </CustomInput>
          </div>
          <div className="gap-[5%] sm:flex md:gap-[20px]">
            <div className="w-full flex-col sm:w-[47.5%] md:w-[240px]">
              <CustomInput title="Start Date" margin={20}>
                <input
                  type="text"
                  placeholder=" "
                  id="inputStartDate"
                  className="peer input input-bordered w-full cursor-default bg-transparent md:w-[240px]"
                  onChange={(e) => setStartDate(e.currentTarget.value)}
                  onBlur={(e) => {
                    !e.target.value && (e.target.type = 'text');
                  }}
                  onFocus={(e) => {
                    e.target.type = 'date';
                  }}
                />
                <span className="absolute right-1 top-[50%] z-[-1] grid h-[90%] w-[40px] translate-y-[-50%] place-items-center rounded-lg">
                  <IconCalendar className="top-[50%] scale-[1.5] self-center fill-flexi-orange-1" />
                </span>
              </CustomInput>
            </div>
            <div className="w-full flex-col sm:w-[47.5%] md:w-[240px]">
              <CustomInput title="End Date" margin={20}>
                <input
                  type="text"
                  placeholder=" "
                  id="inputEndDate"
                  className="peer input input-bordered w-full cursor-default bg-transparent md:w-[240px]"
                  onChange={(e) => setEndDate(e.currentTarget.value)}
                  onBlur={(e) => {
                    !e.target.value && (e.target.type = 'text');
                  }}
                  onFocus={(e) => {
                    e.target.type = 'date';
                  }}
                />
                <span className="absolute right-1 top-[50%] z-[-1] grid h-[90%] w-[40px] translate-y-[-50%] place-items-center rounded-lg">
                  <IconCalendar className="top-[50%] scale-[1.5] self-center fill-flexi-orange-1" />
                </span>
              </CustomInput>
            </div>
          </div>
        </div>
      </div>

      <button
        className={classNames(
          'btn mt-[15px] mb-[30px] flex h-[40px] w-full items-center justify-center gap-[10px] rounded-none border-none !bg-flexi-black-5 px-7 capitalize md:h-[50px]',
          {
            'opacity-50': selectedInvoices.length === 0,
          },
        )}
        onClick={handleDownloadInvoices}
        disabled={selectedInvoices.length === 0}
      >
        <IconDownload />
        <p className="text-[16px] font-bold text-primary">Download</p>
      </button>

      <PageTitle>outstanding balances</PageTitle>

      {fetchStatus === RequestStatusEnum.PENDING ? (
        <div className="w-full py-[100px]">
          <AiOutlineLoading3Quarters className="m-auto h-[50px] w-[50px] animate-spin fill-flexi-orange-1" />
        </div>
      ) : outstandingBalances?.data.length === 0 ? (
        <div className="my-[40px] rounded-[8px] border-[1px] border-[#96969650] bg-[#F7F7F7] py-[40px] md:my-[48px]">
          <p className="text-center text-[14px]">No outstanding balance</p>
        </div>
      ) : (
        <div className="my-[40px] md:my-[48px]">
          <>
            {outstandingBalances?.data && (
              <>
                <InvoicesTable
                  invoices={outstandingBalances?.data}
                  handleCheckBoxMulti={setSelectedOBInvoices}
                  handleCheckBoxSingle={handleCheckBoxSingleOB}
                  selectedInvoices={selectedOBInvoices}
                  setBurgerActive={setBurgerOBActive}
                  burgerActive={burgerOBActive}
                  paySingleInvoice={paySingleInvoice}
                />
                <div className="flex w-full bg-[#F2F2F2] px-[24px] py-[20px] text-[16px] font-bold md:text-[18px]">
                  <div className="flex gap-[42.5px]">
                    <p className="">Total</p>
                    <p className="">${total.toFixed(2)}</p>
                  </div>
                  <button
                    className={classNames(
                      'ml-auto text-[#FF7800] disabled:text-[#FEC796]',
                      {
                        loading: paymentStatus,
                      },
                    )}
                    disabled={selectedOBInvoices?.length === 0}
                    onClick={paySelectedInvoices}
                  >
                    Pay Now
                  </button>
                </div>
                <Pagination
                  invoices={outstandingBalances.data}
                  status={isPendingInvoice}
                  from={outstandingBalances.meta.from}
                  to={outstandingBalances.meta.to}
                  totalItems={outstandingBalances.meta.total}
                  totalPages={outstandingBalances.meta.lastPage}
                  currentPage={outstandingBalances.meta.currentPage}
                  itemsPerPage={itemsPerPageOB}
                  setCurrentPage={setCurrentPageOB}
                  setItemsPerPage={setItemsPerPageOB}
                  onPageChange={(limit: number, page: number) =>
                    handleRequestOBInvoices(limit, page)
                  }
                />
              </>
            )}
          </>
        </div>
      )}

      <PageTitle>invoices</PageTitle>

      {isPendingInvoice && !invoicesList ? (
        <div className="w-full py-[100px]">
          <AiOutlineLoading3Quarters className="m-auto h-[50px] w-[50px] animate-spin fill-flexi-orange-1" />
        </div>
      ) : !isPendingInvoice && invoicesList?.data.length === 0 ? (
        <div className="my-[40px] rounded-[8px] border-[1px] border-[#96969650] bg-[#F7F7F7] py-[40px] md:my-[48px]">
          <p className="text-center text-[14px]">No invoices to display</p>
        </div>
      ) : (
        <>
          {invoicesList?.data && (
            <>
              <InvoicesTable
                invoices={invoicesList?.data}
                handleCheckBoxMulti={setSelectedInvoices}
                handleCheckBoxSingle={handleCheckBoxSingle}
                selectedInvoices={selectedInvoices}
                setBurgerActive={setBurgerActive}
                burgerActive={burgerActive}
                paySingleInvoice={paySingleInvoice}
              />

              <Pagination
                invoices={invoicesList.data}
                status={isPendingInvoice}
                from={invoicesList.meta.from}
                to={invoicesList.meta.to}
                totalItems={invoicesList.meta.total}
                totalPages={invoicesList.meta.lastPage}
                currentPage={invoicesList.meta.currentPage}
                itemsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
                onPageChange={(limit: number, page: number) =>
                  handleRequestInvoices(limit, page)
                }
              />
            </>
          )}
        </>
      )}

      <Modal
        isModalOpen={paySingleInvoiceStatus === RequestStatusEnum.PENDING}
        handleClose={() => {}}
        hasCloseButton={false}
      >
        <div>
          <PendingLoader size={100} my={50} />
          <p className="mt-[20px] text-center text-[18px]">
            Processing your payment...
          </p>
        </div>
      </Modal>

      <Modal
        isModalOpen={paySingleInvoiceStatus === RequestStatusEnum.SUCCESS}
        handleClose={() => dispatch(setPaySingleInvoiceStatus(null))}
      >
        <div>
          <IconSuccess className="icon mx-auto mb-[30px]" />
          <h1 className="title mb-[20px] text-center text-[20px] font-bold md:text-[34px]">
            Payment Successful
          </h1>
          <p className="text-center text-[16px] md:text-[18px]">
            You have successfully paid an amount of ${amountPaid.toFixed(2)} of
            your <span className="whitespace-nowrap">outstanding balance.</span>
          </p>
          <button
            className="btn btn-primary mt-[50px] h-[60px] w-full rounded-full capitalize"
            onClick={() => {
              dispatch(setPaySingleInvoiceStatus(null));
              fetchInvoices();
            }}
          >
            ok
          </button>
        </div>
      </Modal>

      <Modal
         isModalOpen={paySingleInvoiceStatus === RequestStatusEnum.FAILED}
         handleClose={() => dispatch(setPaySingleInvoiceStatus(null))}
       >
         <div>
           <IconError className="icon mx-auto mb-[30px]" />
           <h1 className="title mb-[20px] text-center text-[20px] font-bold md:text-[34px]">
             Payment Failed
           </h1>
           <p className="text-center text-[16px] md:text-[18px]">
             We are unable to process your payment. Please contact your card
             issuer for details or use a different{' '}
             <span className="whitespace-nowrap">payment method.</span>
           </p>
           <button
             className="btn btn-primary mt-[50px] h-[60px] w-full rounded-full capitalize"
             onClick={() => {
               push('/app/account/payment-method');
               dispatch(setPaySingleInvoiceStatus(null));
             }}
           >
             Change Payment Method
           </button>
         </div>
       </Modal>
    </article>
  );
};

export default MyAccountInvoices;
