import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { BiTargetLock } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import classNames from 'classnames';

// import { ReactComponent as IconError } from 'assets/images/error.svg';
import { selectBookingExpired, setBookingExpired } from 'modules/vehicle/slice';
import { selectCurrentZoom, selectFirstVisible, setSelectedPod2, setSelectedPodVehicle2 } from 'modules/pods/slice';
import { setMapView } from 'modules/booking/slice';
import { selectUser } from 'modules/me/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import Modal from 'components/Modal';
import ModalPendingAccount from './ModalPendingAccount';
import VehicleList from './VehicleList';
import Map from './Map';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

interface Props {
  isMapLoaded: boolean;
  mapLoadError: Error | undefined;
}

const BookingMap = ({ isMapLoaded, mapLoadError }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const {
    getCurrentLocation,
    isMapView
  } =
    useBooking({});
  const bookingExpired = useAppSelector(selectBookingExpired);
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(false);
  const firstVisible = useAppSelector(selectFirstVisible);
  const currentZoom = useAppSelector(selectCurrentZoom);
  const [isGroupedPodsPending, setIsGroupedPodsPending] = useState(false);
  
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useEffect(() => {
    if (bookingExpired) {
      setIsExpiredModalOpen(true);
    }
  }, [bookingExpired]);

  useEffect(() => {
    
  }, [isMapView])
  
  useEffectOnce(() => {
    dispatch(setSelectedPod2(null));
    dispatch(setSelectedPodVehicle2(null));
  })

  const [isPendingAccount, setIsPendingAccount] = useState(false);
  return (
    <div
      className={classNames(
        'relative h-[calc(100vh-110px)] w-full overflow-hidden lg:h-[calc(100vh-190px)]',
        {
          '!h-[calc(100vh-108px)]': user === null,
        },
      )}
    >
      <>
        {(isGroupedPodsPending && currentZoom && currentZoom < 14) && (
          <aside
            className={classNames(
              'absolute left-[50%] top-[47%] z-[7] flex h-[40px] w-[140px] translate-x-[-50%] items-center justify-center gap-[10px] rounded-[10px] bg-white shadow-lg !duration-500 lg:translate-x-0',
              {
                'lg:left-[calc(50%+180px)]':
                  firstVisible && currentZoom && currentZoom >= 14,
              },
            )}
          >
            <span className="text-[12px] font-[500]">Searching Pods</span>
            <AiOutlineLoading3Quarters className="animate-spin fill-flexi-orange-1" />
          </aside>
        )}
        <button
          className="btn absolute bottom-[110px] right-[10px] z-[7] h-[40px] min-h-[40px] w-[40px] rounded-[3px] border-none bg-white px-0 shadow-md hover:bg-white"
          onClick={getCurrentLocation}
        >
          <BiTargetLock className="h-[23px] w-[23px] fill-flexi-black-3" />
        </button>
        {currentZoom && currentZoom >= 14 && (
          <VehicleList setIsPendingAccount={setIsPendingAccount} />
        )}
        <div
          className={classNames(
            'opacity-1 absolute left-0 top-0 z-[6] hidden h-[100vh] w-[100vw] bg-[#00000077]',
            {
              'pointer-events-none opacity-0': isMapView,
            },
          )}
          onClick={() => dispatch(setMapView(true))}
        />
        <Map
          isMapLoaded={isMapLoaded}
          mapLoadError={mapLoadError}
          setIsPendingAccount={setIsPendingAccount}
          setIsGroupedPodsPending={setIsGroupedPodsPending}
          isGroupedPodsPending={isGroupedPodsPending}
        />
      </>
      <Modal
        isModalOpen={isExpiredModalOpen}
        handleClose={() => {
          setIsExpiredModalOpen(false);
          dispatch(setBookingExpired(false));
        }}
        px={isMobile ? 'px-[25px]' : ''}
        mx={isMobile ? '30px' : '0px'}
      >
        <LottieAnimation
          lottieFile={errorLottie}
          isStopped={!isExpiredModalOpen}
        />
        <h1 className="mb-[40px] text-center text-[20px] font-bold lg:text-[34px]">
          Your booking has expired. Please reselect the date and time.
        </h1>
        <button
          className="btn btn-primary h-[60px] w-full rounded-full text-[16px] capitalize hover:bg-flexi-green-2"
          onClick={() => {
            setIsExpiredModalOpen(false);
            dispatch(setBookingExpired(false));
          }}
        >
          ok
        </button>
      </Modal>

      <ModalPendingAccount
        isModalOpen={isPendingAccount}
        handleClose={() => {
          setIsPendingAccount(false);
        }}
      />
    </div>
  );
};

export default BookingMap;
