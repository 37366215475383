import classNames from 'classnames';
import AddAccountLayout from 'components/layouts/AddAccountLayout';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectDefaultPaymentCard,
  selectPaymentCards,
  selectPendingPaymentCards,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import { selectPendingCardUrl } from 'modules/transaction/slice';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { ReactComponent as IconPlus } from 'assets/images/plus.svg';
import { IMAGE_URL } from 'common/constants';
import PaymentMethodModal from 'components/PaymentMethodModal';
import useWindcave from 'hooks/useWindcave';
import { selectCreatePersonalAccountCompleteStatus } from 'modules/business/slice';
import { RequestStatusEnum } from 'common/types';
import gsap from 'gsap';

function AddAccountPayment() {
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const isPendingPaymentCards = useAppSelector(selectPendingPaymentCards);
  const isPendingCardUrl = useAppSelector(selectPendingCardUrl);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const createPersonalAccountCompleteStatus = useAppSelector(
    selectCreatePersonalAccountCompleteStatus,
  );
  const paymentCards = useAppSelector(selectPaymentCards);
  const cardTotal = paymentCards?.length || 0;
  const [imageName, setNewImage] = useState<string>('');
  const [isImageExist, setIsImageExist] = useState<boolean>(true);
  const { createPersonalAccountAddCard } = useWindcave();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  const handlePayment = () => {
    dispatch({ type: ACTIONS.CREATE_PERSONAL_ACCOUNT_COMPLETE });
  };

  useEffect(() => {
    if (defaultPaymentCard)
      try {
        setNewImage(
          require(`assets/images/card-${defaultPaymentCard?.type}.svg`),
        );
        // do stuff
      } catch (ex) {
        setIsImageExist(false);
      }
  }, [defaultPaymentCard]);

  return (
    <AddAccountLayout>
      <div
        ref={component}
        className="fadein relative w-full pt-[40px] md:max-w-[600px]"
      >
        <div className="flex min-h-[calc(100vh-115px)] flex-col items-center md:min-h-[calc(100vh-500px)]">
          <h1 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-[28px]">
            add payment method
          </h1>
          <p className="mb-[50px] text-[16px] md:text-[16px]">
            This will be used to charge your personal trips.
          </p>
          {isPendingPaymentCards && isPendingCardUrl ? (
            <div className="flex animate-pulse flex-col gap-4">
              <div className="h-[88px] w-full rounded bg-slate-100" />
              <div className="h-[30px] w-full rounded bg-slate-100" />
            </div>
          ) : cardTotal === 0 ? (
            <label
              role="button"
              className="grid h-[80px] w-full place-items-center  rounded-md border-[1px] border-transparent bg-white p-[20px] py-4 text-[35px] text-flexi-orange-1 shadow-[0_2px_9px] shadow-flexi-white-2 duration-200 hover:border-[1px] hover:border-flexi-orange-1"
              onClick={() => {
                createPersonalAccountAddCard();
                setModalOpen(true);
              }}
            >
              <IconPlus className="h-[20px] w-[20px]" />
            </label>
          ) : (
            <div
              className="flex w-full place-items-center justify-between rounded-md border-[1px] bg-white px-2 py-3 md:!p-5 hover:border-primary"
              role="button"
              onClick={() => setModalOpen(true)}
            >
              <div className="flex gap-2 md:gap-3">
                <p
                  style={{
                    backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                    backgroundSize: '100%',
                  }}
                  className={classNames(
                    'grid w-[25px] md:w-[65px] place-items-center bg-[center_left] bg-no-repeat',
                    {
                      'border-[1px]': !isImageExist,
                    },
                  )}
                >
                  {!isImageExist && 'N/A'}
                </p>
                <div className="flex flex-col text-[10px] md:text-[14px]">
                  <p className="w-[130px] md:w-[200px]  capitalize">
                    <span className="font-bold">●●●● ●●●● ●●●● </span>
                    {defaultPaymentCard?.cardNumber}
                  </p>

                  <p>
                    Expires on {defaultPaymentCard?.expiryMonth}/
                    {defaultPaymentCard?.expiryYear}
                  </p>
                </div>
              </div>
              <p className="text-[11px] md:text-[16px] capitalize text-flexi-black-3/30">
                default
              </p>
            </div>
          )}
          <button
            className={classNames(
              'btn btn-primary mt-auto h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]',
              {
                loading:
                  createPersonalAccountCompleteStatus ===
                  RequestStatusEnum.PENDING,
              },
            )}
            onClick={handlePayment}
            disabled={cardTotal === 0}
          >
            Pay &amp; Finish
          </button>

          <PaymentMethodModal
            isSwitchAccount={true}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>
    </AddAccountLayout>
  );
}

export default AddAccountPayment;
