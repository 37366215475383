import { useHistory, useLocation } from 'react-router-dom';
import { handleDateChange, setStorageDefaultLocation } from 'common/helpers';
import {
  selectEndDate,
  selectMapBounds,
  selectMapCenter,
  selectStartDate,
  selectMapView,
  setErrors,
  setMapCenter,
  setUserCenter,
  setTentativeMapCenter,
} from 'modules/booking/slice';
import {
  selectNearestPods,
  selectNearestPodsStatus,
  selectNearestPodsVehicles,
  selectSelectedPod,
  selectSelectedPodVehicle,
  selectShowOnlyAvailableVehicle,
  setNearestPods,
  setNearestPodsVehicles,
  setPendingNearestPods,
  setShowOnlyAvailableVehicle,
  selectPodsAll,
  selectSelectedPodOptimized,
  selectSelectedPodVehicleOptimized,
  selectSelectedPod2,
  selectSelectedPodVehicle2,
  selectVehiclesInPod,
  selectVehiclesInPodStatus,
  selectVisiblePodsId2,
  setPodTimezone,
} from 'modules/pods/slice';
import ACTIONS from 'modules/rootActions';
import { toast } from 'react-toastify';
import useAppDispatch from './useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { IPodGroupRates, IVehicleListRates, IVehicleSummaryMin, RequestStatusEnum } from 'common/types';
import {
  selectSelectedVehicleFilters,
  selectVehicleRates,
  // selectVisibleVehiclesId,
  selectVehicleAvailability,
  setVehicleAvailability,
  setVehicleAvailabilityPending,
  setVehicleAvailabilityStatus, 
  selectVehicleAvailabilityStatus} from 'modules/vehicle/slice';
import { selectUser } from 'modules/me/slice';
import { useGeolocated } from "react-geolocated";
import moment from 'moment';
import { FIRESTORE_AVAILABILITIES_COLLECTION_NAME } from 'common/constants';
import {
  getFirestore,
  collection,
  getDocs,
  // query,
  // where,
} from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import * as _ from 'lodash';
import tzlookup from 'tz-lookup';

interface Props {
  showError?: boolean;
}

const useBooking = ({showError = true}: Props) => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const user = useAppSelector(selectUser);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const mapCenter = useAppSelector(selectMapCenter);
  const mapBounds = useAppSelector(selectMapBounds);
  const nearestPods = useAppSelector(selectNearestPods);
  const nearestVehicles = useAppSelector(selectNearestPodsVehicles);
  const selectedPod = useAppSelector(selectSelectedPod);
  const selectedPodVehicle = useAppSelector(selectSelectedPodVehicle);
  const nearestPodsStatus = useAppSelector(selectNearestPodsStatus);
  const pendingNearestPods = nearestPodsStatus === RequestStatusEnum.PENDING;
  const selectedVehicleFilters = useAppSelector(selectSelectedVehicleFilters);
  const showOnlyAvailableVehicle = useAppSelector(selectShowOnlyAvailableVehicle);
  const isMapView = useAppSelector(selectMapView);
  const podsAll = useAppSelector(selectPodsAll);
  const vehiclesInPod = useAppSelector(selectVehiclesInPod);
  const vehiclesAvailability = useAppSelector(selectVehicleAvailability);
  const selectedPodOptimized = useAppSelector(selectSelectedPodOptimized);
  const selectedPodVehicleOptimized = useAppSelector(selectSelectedPodVehicleOptimized);
  const selectedPod2 = useAppSelector(selectSelectedPod2);
  const selectedPodVehicle2 = useAppSelector(selectSelectedPodVehicle2);
  const visibleVehicleRates = useAppSelector(selectVehicleRates);
  const vehiclesAvailabilityStatus = useAppSelector(selectVehicleAvailabilityStatus);
  const pendingAvailabilityList = vehiclesAvailabilityStatus === RequestStatusEnum.PENDING;
  const vehiclesInPodStatus = useAppSelector(selectVehiclesInPodStatus);
  const pendingVehicleList = vehiclesInPodStatus === RequestStatusEnum.PENDING;
  const visiblePodsId2 = useAppSelector(selectVisiblePodsId2);
  const { pathname } = useLocation();
  const isPublic = pathname !== '/app/booking';

  const { coords, isGeolocationEnabled } =
  useGeolocated({
      positionOptions: {
          enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
  });

  const getCurrentLocation2 = () => {
    if(isGeolocationEnabled && coords){
      const { latitude, longitude } = coords;
      dispatch(
        setMapCenter({
          lat: latitude,
          lng: longitude,
        }),
      );
      dispatch(
        setUserCenter({
          lat: latitude,
          lng: longitude,
        }),
      );
      setStorageDefaultLocation(
        JSON.stringify({ lat: latitude, lng: longitude }),
      );
      dispatch({
        type: ACTIONS.FETCH_LOCATION_FORMATTED_ADDRESS,
        payload: {
          latitude,
          longitude,
        },
      });
    }
    if(!isGeolocationEnabled){
      toast.error(
          'User denied geolocation. Please allow access and try again.',
        );
      dispatch(setPendingNearestPods(false));
    }
  };
    
    
  const getCurrentLocation = () => {
    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
    };
    // dispatch(setPendingNearestPods(true));

    const success = ({ coords }: any) => {
      const { latitude, longitude } = coords;
      dispatch(
        setTentativeMapCenter({
          lat: latitude,
          lng: longitude,
        }),
      );
      dispatch(setPodTimezone(tzlookup(Number(latitude), Number(longitude))));
      dispatch(
        setUserCenter({
          lat: latitude,
          lng: longitude,
        }),
      );
      setStorageDefaultLocation(
        JSON.stringify({ lat: latitude, lng: longitude }),
      );
      dispatch({
        type: ACTIONS.FETCH_LOCATION_FORMATTED_ADDRESS,
        payload: {
          latitude,
          longitude,
        },
      });
      // dispatch(setPendingNearestPods(false));
    };

    const error = () => {
      if (showError) {
        toast.error(
          'User denied geolocation. Please allow access and try again.',
        );
      }
      dispatch(setPendingNearestPods(false));
    };

    navigator?.geolocation.getCurrentPosition(success, error, options, );
  };


  const handleGetNearestPods = () => {
    if(selectedVehicleFilters.length>0){

      if (mapCenter && startDate && endDate) {
        if (isPublic) {
          dispatch({
            type: ACTIONS.GET_NEAREST_PODS_PUBLIC,
            payload: {
              minLatitude: mapBounds?.minLatitude,
              maxLatitude: mapBounds?.maxLatitude,
              minLongitude: mapBounds?.minLongitude,
              maxLongitude: mapBounds?.maxLongitude,
              startsAt: handleDateChange(startDate),
              endsAt: handleDateChange(endDate),
              latitude: mapCenter?.lat,
              longitude: mapCenter?.lng,
            },
          });
        } else {
          dispatch({
            type: ACTIONS.GET_NEAREST_PODS,
            payload: {
              minLatitude: mapBounds?.minLatitude,
              maxLatitude: mapBounds?.maxLatitude,
              minLongitude: mapBounds?.minLongitude,
              maxLongitude: mapBounds?.maxLongitude,
              startsAt: handleDateChange(startDate),
              endsAt: handleDateChange(endDate),
              latitude: mapCenter?.lat,
              longitude: mapCenter?.lng,
            },
          });
        }
      } else {
        dispatch(setErrors('Please enter a location.'))
      }
    }
    else{
      dispatch(setErrors('Please select a vehicle type.'))
      dispatch(setNearestPods(null))
      dispatch(setNearestPodsVehicles(null))
    }
  };

  const isUserUnapproved = () => {
    const status = ['pending', 'incomplete']
    if (user && status.includes(user.status) && user.redirectTo !== 'app') {
      return push('/register/verification');
    }
  };

  const handleShowOnlyAvailableVehicle = (value: boolean) => {
    dispatch(setShowOnlyAvailableVehicle(value));
    handleGetNearestPods();
  };

  const getAllPodDetails = () => {
    dispatch({
      type: ACTIONS.GET_PODS_ALL,
      payload: {
      },
    });
  }

  const searchVisibleVehicles = () => {
    if(visiblePodsId2 && visiblePodsId2.length > 0){
      dispatch({
        type: ACTIONS.GET_PODS_VEHICLE,
        payload: {
          pods: { id: visiblePodsId2 },
        },
      });
    }
  };

  const db = getFirestore(firebaseApp);
  const getVehicleAvailabilityFirestore = async (
    visibleVehiclesId2: number[],
  ) => {
    try {
      const collectionRef = collection(
        db,
        FIRESTORE_AVAILABILITIES_COLLECTION_NAME,
      );
      const snapshot = await getDocs(collectionRef);
      const data: IVehicleSummaryMin[] = [];

      snapshot.forEach(function (doc) {
        const parseData = JSON.parse(JSON.stringify(doc.data()));
        if (visibleVehiclesId2.includes(parseData.vehicle_id)) {
          data.push({
            podId: parseData.pod_id,
            vehicleId: parseData.vehicle_id,
            availability: {
              bookings: _.map(parseData.availability.bookings, (i) => {
                return {
                  bookingId: i.booking_id,
                  startsAt: i.starts_at,
                  endsAt: i.ends_at,
                  driverId: i.driver_id,
                  organisationId: i.organisation_id,
                  isMaintenance: i.is_maintenance,
                };
              }),
            },
          });
        }
      });

      dispatch(setVehicleAvailability(data));
      dispatch(setVehicleAvailabilityPending(false));
      dispatch(setVehicleAvailabilityStatus(RequestStatusEnum.SUCCESS));
    } catch (error) {
      dispatch(setVehicleAvailabilityPending(false));
      dispatch(setVehicleAvailabilityStatus(RequestStatusEnum.FAILED));
    }
  };

  const computedRate = (rates: IVehicleListRates) => {
    if(endDate !== null && startDate !== null){
      const duration = moment(endDate).diff(startDate, 'minutes');
      const quarterRate = rates?.rates[0]?.timeRate;
      const hourlyRate = rates?.rates[1]?.timeRate;
      const dailyRate = rates?.rates[2]?.timeRate;
      
      const wholeDays = Math.floor(duration/(24*60)); // get the whole days
      const wholeHours = Math.floor(((duration/60) - (wholeDays * 24)) / 1); // get the whole hours
      const quarters = (duration - wholeDays * 24 * 60 - wholeHours * 60) / 15; // get how many per 15 minutes

      const hoursFee = wholeHours * hourlyRate // get total hourly rate
      const quartersFee = quarters * quarterRate // get total quarters rate
      
      if ((hoursFee + quartersFee) > dailyRate) {
        return ((wholeDays + 1) * dailyRate);
      } else {
        if (quartersFee > hourlyRate) {
          return ((wholeDays * dailyRate) + (wholeHours + 1) * hourlyRate);
        } else {
          return ((wholeDays * dailyRate) + (wholeHours * hourlyRate) + (quarters * quarterRate));
        }
      }
    } else {
      return null;
    }
  }

  const computedRateV2 = ( rates: IPodGroupRates[]) => {
    if(rates && rates !== undefined && endDate !== null && startDate !== null){
      const duration = moment(endDate).diff(startDate, 'minutes');
      const season = 0;
      const quarterRate = rates[0].vehicleGroups[0]?.costBreaks[0].seasons[season].timeRate;
      const hourlyRate = rates[0].vehicleGroups[0]?.costBreaks[1].seasons[season].timeRate;
      const dailyRate = rates[0].vehicleGroups[0]?.costBreaks[2].seasons[season].timeRate;
      
      const wholeDays = Math.floor(duration/(24*60)); // get the whole days
      const wholeHours = Math.floor(((duration/60) - (wholeDays * 24)) / 1); // get the whole hours
      const quarters = (duration - wholeDays * 24 * 60 - wholeHours * 60) / 15; // get how many per 15 minutes

      const hoursFee = wholeHours * hourlyRate // get total hourly rate
      const quartersFee = quarters * quarterRate // get total quarters rate
      
      if ((hoursFee + quartersFee) > dailyRate) {
        return ((wholeDays + 1) * dailyRate);
      } else {
        if (quartersFee > hourlyRate) {
          return ((wholeDays * dailyRate) + (wholeHours + 1) * hourlyRate);
        } else {
          return ((wholeDays * dailyRate) + (wholeHours * hourlyRate) + (quarters * quarterRate));
        }
      }
    } else {
      return null;
    }
  }

  return {
    nearestPods,
    nearestVehicles,
    getCurrentLocation,
    handleGetNearestPods,
    selectedPod,
    selectedPodVehicle,
    startDate,
    endDate,
    showOnlyAvailableVehicle,
    handleShowOnlyAvailableVehicle,
    pendingNearestPods,
    isPublic,
    isUserUnapproved,
    getCurrentLocation2,
    podsAll,
    vehiclesInPod,
    vehiclesAvailability,
    selectedPodOptimized,
    selectedPodVehicleOptimized,
    selectedPod2,
    selectedPodVehicle2,
    getAllPodDetails,
    searchVisibleVehicles,
    pendingVehicleList,
    pendingAvailabilityList,
    visibleVehicleRates,
    getVehicleAvailabilityFirestore,
    computedRate,
    computedRateV2,
    isMapView
  };
};

export default useBooking;
