// import ErrorModal from 'components/ErrorModal';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { useRedirectToRegistration } from 'hooks/useRedirect';

interface Props {
  children: ReactNode;
}

const AppLayout = ({ children }: Props) => {
  const { pathname } = useLocation();

  useRedirectToRegistration();

  return (
    <section
      className={classNames('flex overflow-x-hidden', {
        'block md:flex': pathname === '/app/my-bookings',
      })}
    >
      <article className="flex-1">{children}</article>
    </section>
  );
};

export default AppLayout;
