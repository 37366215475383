import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import { ReactComponent as FailedIcon } from 'assets/images/booking-failed.svg';
// import { ReactComponent as SuccessIcon } from 'assets/images/booking-success.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';
import { RequestStatusEnum } from 'common/types';
import { selectUser } from 'modules/me/slice';
import {
  selectCreateBookingStatus,
  selectErrors,
  setCreateBookingStatus,
} from 'modules/booking/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import PendingLoader from 'components/PendingLoader';
import Modal from 'components/Modal';
import LottieAnimation from 'components/LottieAnimation';
import successLottie from 'assets/json/icon-booking-success.json';

interface Props {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentModal = ({ isModalOpen, setModalOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  
  const { handleGetNearestPods } = useBooking({});

  const createBookingStatus = useAppSelector(selectCreateBookingStatus);
  const errors = useAppSelector(selectErrors);
  const user = useAppSelector(selectUser);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleClose = () => {
    setModalOpen(false);
    dispatch(setCreateBookingStatus(null));
    if (createBookingStatus === RequestStatusEnum.SUCCESS) {
      push('/app/booking');
    }
  };

  const renderModalContent = () => {
    if (createBookingStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <LottieAnimation lottieFile={successLottie} isStopped={!isModalOpen} />
          <h1 className="title form-control text-center text-[20px] font-bold leading-[42px] md:text-[34px]">
            Your booking is confirmed, {user?.firstname}!
          </h1>
          <p className="text-center text-[16px] leading-[20px] md:text-[18px] md:leading-[28px]">
            By creating this booking, a pre-authorisation of the estimated
            booking cost will be temporarily held 4 hours before your booking
            start time.
            <br />
            <br />
            You will be charged with the actual amount less any available
            driving credit when the booking is completed.
          </p>
          <div className="button flex w-full flex-col justify-center gap-3 text-[16px] uppercase">
            <Link
              to="/app/booking"
              className="btn btn-primary btn-lg rounded-full text-[16px] normal-case"
              onClick={handleGetNearestPods}
            >
              Book Again
            </Link>
          </div>
        </>
      );
    }
    if (createBookingStatus === RequestStatusEnum.FAILED) {
      return (
        <>
          {errors?.response?.data?.message?.toLocaleLowerCase() ===
          'not allowed to overlap booking.' ? (
            <>
              <ErrorIcon className="icon" />
              <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
                Oops! Something went wrong.
              </h1>
              <p className="text-center leading-[20px] md:leading-[28px]">
                Your trip overlaps with another confirmed booking. Please try
                again with a different time or day to ensure your booking is
                successful.
              </p>
              <div className="button flex w-full justify-center">
                <label
                  className={`btn h-[60px] w-full rounded-full border-none border-flexi-green-1 bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2`}
                  onClick={() => {
                    handleClose();
                    push('/app/booking');
                  }}
                >
                  Ok
                </label>
              </div>
            </>
          ) : errors?.toLocaleLowerCase() ===
            'sorry, you are not eligible to book a prestige vehicle at this time. please check other available vehicles.' ? (
            <>
              <ErrorIcon className="icon" />
              <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
                Unable to confirm booking.
              </h1>
              <p className="text-center leading-[20px] md:leading-[28px]">
                Sorry, you are not eligible to book a prestige vehicle at this
                time.
                <br />
                Please check other available vehicles.
              </p>
              <div className="button flex w-full justify-center">
                <label
                  className={`btn h-[60px] w-full rounded-full border-none border-flexi-green-1 bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2`}
                  onClick={() => {
                    handleClose();
                    push('/app/booking');
                  }}
                >
                  Book Again
                </label>
              </div>
            </>
          ) : (
            (errors?.response?.data?.message ||
              errors?.response?.data?.error?.message ||
              errors) && (
              <>
                <FailedIcon className="icon" />
                <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
                  {errors.toLocaleLowerCase().includes('past', 'time')
                    ? 'Booking is not confirmed.'
                    : errors.toLocaleLowerCase().includes('license')
                    ? 'Unable to confirm booking.'
                    : errors
                        .toLocaleLowerCase()
                        .includes('overlap')
                    ? 'Oops! Something went wrong.'
                    : 'Payment Failed'}
                </h1>
                {errors?.toLocaleLowerCase()?.includes('card') ? (
                  <p className="text-center leading-[20px] md:leading-[28px]">
                    Your default card is expired or expiring within 7 days of
                    your booking start date. Please update your card details.
                  </p>
                ) : (
                  <p className="text-center leading-[20px] md:leading-[28px]">
                    {errors?.response?.data?.message ||
                      errors?.response?.data?.error?.message ||
                      errors}
                  </p>
                )}
                {errors.toLocaleLowerCase().includes('failed payment') ? (
                  <div className='mt-[30px]'>
                    <button
                      className="btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold !capitalize text-white outline-none hover:bg-flexi-green-2 lg:!h-[60px] lg:text-[16px]"
                      onClick={() => {
                        push('/app/account/payment-method');
                      }}
                    >
                      Go to Payment Method
                    </button>
                    <p
                      className="mt-[20px] cursor-pointer text-center text-[14px] font-bold text-primary"
                      onClick={() => {
                        push('/app/account/invoices');
                      }}
                    >
                      Go to My Account
                    </p>
                  </div>
                ) : (
                  <div className="button flex w-full justify-center">
                    <label
                      className={`btn h-[60px] w-full rounded-full border-none border-flexi-green-1 bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2`}
                          onClick={() => {
                            if (errors?.toLocaleLowerCase()?.includes('card')) {
                              push('/app/account/payment-method');
                            } else {
                              handleClose();
                        }
                      }}
                    > 
                      {errors?.toLocaleLowerCase()?.includes('card') ? 'Change Payment Method' : 'Ok'}
                    </label>
                  </div>
                )}
              </>
            )
          )}
        </>
      );
    }
    if (createBookingStatus === RequestStatusEnum.ERROR) {
      <div className="flex flex-col items-center text-[16px] md:text-[18px]">
        <ErrorIcon className="icon" />
        <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
          Opps! Something went wrong.
        </h1>
        <p className="mb-[40px]">
          It seems like we encountered a problem with confirming your booking.
        </p>
        <p>Please try again to ensure that your booking is successful.</p>
        <div className="button flex justify-center">
          <label
            className={`btn rounded-full border-flexi-green-1 bg-flexi-green-1 text-[16px] capitalize`}
            onClick={handleClose}
          >
            try again
          </label>
        </div>
      </div>;
    }
    if (createBookingStatus === RequestStatusEnum.PENDING) {
      return (
        <div>
          <PendingLoader />
          <p className="mt-[20px] text-center text-[18px]">
            Processing your booking...
          </p>
        </div>
      );
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      px={isMobile ? 'px-[25px]' : 'px-[120px]'}
      mx={isMobile ? '30px' : '0px'}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default PaymentModal;
