import React, { useState } from 'react';
import { CenteredPageTitle } from 'components/Typography';
import { ReactComponent as Search } from 'assets/images/search2.svg';
import useAppSelector from 'hooks/useAppSelector';
import { selectPagesContents } from 'modules/public/slice';
import classNames from 'classnames';

const SearchQuery = () => {
  const pagesContents = useAppSelector(selectPagesContents);
  const [searchInput, setSearchInput] = useState<string>('');

  return (
    <div className="mx-auto mb-[100px] flex w-full flex-col justify-center gap-[60px]">
      <CenteredPageTitle>
        How can we help <span className="whitespace-nowrap">you today?</span>
      </CenteredPageTitle>
      <label className="relative mx-auto w-full md:w-fit">
        <input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search the help centre"
          className="font-primary placeholder:text-flexi-balck-5 relative mx-auto h-[60px] w-full rounded-[20px] border-[1px] border-flexi-black-4 px-[20px] font-bold outline-1 outline-flexi-black-3 md:w-[600px] md:text-[24px]"
        />
        <Search className="absolute right-[20px] top-[50%] -translate-y-1/2 md:h-[25px] md:w-[25px]" />

        <div
          className={classNames(
            'absolute z-[99999999999999] mx-auto mt-[20px] w-full rounded-[20px] bg-white py-[30px] shadow-lg',
            {
              hidden: searchInput.length === 0,
            },
          )}
        >
          <div className="mx-auto grid w-full grid-cols-1 gap-[5px] px-[30px]">
            {searchInput.length > 0 &&
              pagesContents
                ?.filter(
                  (faq) =>
                    (faq.question
                      .toLocaleLowerCase()
                      .includes(searchInput.toLocaleLowerCase()) ||
                      faq.answer
                        .toLocaleLowerCase()
                        .includes(searchInput.toLocaleLowerCase())) &&
                    faq,
                )
                .map((faq, i) => {
                  return (
                    <a
                      key={`results-${i}`}
                      href={`/faq/category?cid=${faq.categoryId}&ref=content-${faq.contentId}`}
                      className="block w-full truncate text-[18px] text-flexi-black-3 duration-300 hover:bg-flexi-black-6"
                    >
                      {faq.question}
                    </a>
                  );
                })}
          </div>
          {searchInput.length > 0 &&
            pagesContents?.filter(
              (faq) =>
                (faq.question
                  .toLocaleLowerCase()
                  .includes(searchInput.toLocaleLowerCase()) ||
                  faq.answer
                    .toLocaleLowerCase()
                    .includes(searchInput.toLocaleLowerCase())) &&
                faq,
            ).length === 0 && (
              <div className="w-full">
                <p className="text-center text-flexi-black-4">
                  Sorry, we couldn&apos;t find any results.
                </p>
              </div>
            )}
        </div>
      </label>
    </div>
  );
};

export default SearchQuery;
