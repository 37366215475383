import React, { useRef, useLayoutEffect } from 'react';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import gsap from 'gsap';

const ForgotPasswordCheckEmail = () => {
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);
  return (
    <section ref={component} className="fadein m-auto mt-[100px] flex w-full max-w-[80vw] md:max-w-[500px] flex-col place-items-center content-center items-center">
      <IconSuccess className="mb-[50px]" />
      <h2 className="font-primary mb-[20px] text-left text-[30px] md:text-[40px] font-[900] uppercase text-primary">
        check your email
      </h2>
      <p className="mb-[70px] text-center">
        We&apos;ve sent reset password instruction to your email.
      </p>
    </section>
  );
};

export default ForgotPasswordCheckEmail;
