import React, { Fragment, useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import IconClose from 'assets/images/close.svg';
// import { ReactComponent as IconInviteModal } from 'assets/images/invite_modal.svg';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import { IMAGE_URL } from 'common/constants';
import { validateEmail } from 'common/helpers';
import { RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import { selectErrors } from 'modules/business/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from '../../hooks/useBusiness';
import Modal from 'components/Modal';
import LottieAnimation from 'components/LottieAnimation';
import inviteDriverLottie from 'assets/json/icon-invite-driver.json';

interface Props {
  handleClose: () => void;
  isModalOpen: boolean;
}

function ModalInvite({ handleClose, isModalOpen }: Props) {
  const dispatch = useAppDispatch();
  const { inviteDriverStatus } = useBusiness();
  const errors = useAppSelector(selectErrors);
  const [emails, setEmails] = useState([]);
  const [input, setInput] = useState('');

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_BUSINESS_INVITEES });
  }, [inviteDriverStatus]);

  useEffect(() => {
    if(!isModalOpen) setInput('')
  }, [isModalOpen])

  const handleInvite = () => {
    if (emails.length !== 0 || isValidEmail(input)) {
      console.log(isValidEmail(input));
      const includeInput: string[] = [...emails];
      if (input.trim().length > 0) includeInput.push(input.trim());
      const payload = {
        emails: includeInput,
      };
      dispatch({ type: ACTIONS.INVITE_DRIVERS, payload });
    }
  };

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const renderModalContent = () => {
    if (inviteDriverStatus === RequestStatusEnum.SUCCESS) {
      return (
        <Fragment>
          <IconSuccess className="icon" />
          <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
            Sending Invitation!
          </h1>
          <p className=" mt-[20px] self-center text-center text-[16px] md:text-[18px]">
            Your driver invitation is pending.
            <br />
            Link and instructions will be sent to their email to proceed.
          </p>
        </Fragment>
      );
    }

    const handleEnter = (e: any) => {
      if (
        (e.key === 'Enter' || e.key === ' ') &&
        validateEmail(e.target.value)
      ) {
        const newArr: any = [...emails];
        newArr.push(e.target.value);
        setEmails(newArr);
        setInput('');
      }
      if (e.key === 'Enter' && e.target.value === '' && emails.length > 0) {
        handleInvite();
      }
    };

    const handleRemoveEmail = (index: number) => {
      const newArr: any = [...emails];
      newArr.splice(index, 1);
      setEmails(newArr);
    };

    return (
      <Fragment>
        <LottieAnimation lottieFile={inviteDriverLottie} isStopped={!isModalOpen} />
        <h1 className="title form-control text-center text-[20px] md:text-4xl font-bold">
          Invite Driver
        </h1>
        <p className="mt-[30px] mb-[25px] self-center text-center text-[16px] md:text-[18px]">
          Invite your collegues to become a driver on your business account.
          <br />
          <br />
          Their booking and membership costs will be charged to your default
          payment&nbsp;method.
        </p>
        <label
          htmlFor="input_field"
          className="scrollbar flex h-[150px] w-full flex-wrap gap-[10px] overflow-y-auto rounded-md border-[1px] p-[15px]"
        >
          {emails.map((email, i) => {
            return (
              <label
                htmlFor=""
                key={i}
                className="hide-scrollbar relative flex h-fit items-center gap-[5px] overflow-x-auto rounded-full bg-zinc-100 p-[5px] px-[10px] text-[12px]"
              >
                <p>{email}</p>
                <span
                  className="z-[100] max-h-[8px] min-h-[8px] min-w-[8px] max-w-[8px] cursor-pointer bg-center bg-no-repeat fill-zinc-700"
                  style={{ backgroundImage: `url(${IMAGE_URL}${IconClose})` }}
                  onClick={() => handleRemoveEmail(i)}
                />
              </label>
            );
          })}
          <input
            type="text"
            name="input_field"
            id="input_field"
            placeholder="Enter email address"
            onKeyDown={handleEnter}
            value={input}
            onChange={(e) => setInput(e.target.value.replace(' ', ''))}
            className="h-[30px] flex-grow-[1] cursor-default text-[12px] outline-none"
          />
        </label>
        {errors?.message && <InvalidText>{errors?.message}</InvalidText>}
        <button
          className={classNames(
            `btn mt-[50px] flex !h-[50px] w-full justify-center rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 disabled:text-white md:!h-[60px]`,
            {
              'loading bg-flexi-green-2':
                inviteDriverStatus === RequestStatusEnum.PENDING,
            },
          )}
          onClick={handleInvite}
          disabled={emails.length === 0 && !isValidEmail(input)}
        >
          <p>Invite</p>
        </button>
      </Fragment>
    );
  };

  return (
    <Modal
      handleClose={() => {
        handleClose();
        setEmails([]);
      }}
      isModalOpen={isModalOpen}
      px={isMobile ? 'px-[25px]' : 'px-[120px]'}
      mx={isMobile ? '30px' : '0px'}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default ModalInvite;
