import { IRates, IVehicleRates } from 'common/types';
import PageLayout from 'components/layouts/PageLayout';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import ACTIONS from 'modules/rootActions';
import {
  selectVehicleListDaily,
  selectVehicleListHourly,
} from 'modules/vehicle/slice';
import React, { useEffect, useState } from 'react';
const PricingPage = () => {
  const dispatch = useAppDispatch();
  const [listSwitch, setListSwitch] = useState(false);

  const vehicleList = useAppSelector<any>(
    listSwitch ? selectVehicleListDaily : selectVehicleListHourly,
  );

  useEffect(() => {
    const payload = {
      id: 1,
    };
    dispatch({ type: ACTIONS.FETCH_PUBLIC_VEHICLE_LIST, payload });
  }, []);

  return (
    <PageLayout>
      <section className="m-auto flex  w-full flex-col items-center pb-[75px]">
        <div className=" sticky top-0 flex w-full flex-col items-center justify-items-center bg-white pb-[35px] pt-[100px] ">
          <h2 className="mb-[50px] max-w-md text-center text-4xl font-bold sm:mb-[25px]">
            Personal Plans <br className='block md:hidden'/>Vehicle Lists and Rates
          </h2>
          <div className="flex justify-center">
            <button
              onClick={() => setListSwitch(false)}
              className={`btn btn-lg w-[150px]  sm:btn-md sm:w-[125px]
              ${listSwitch ? 'bg-white text-black' : 'bg-zinc-800'} 
              rounded-tr-none rounded-br-none hover:bg-zinc-800 hover:text-white`}
            >
              Hourly
            </button>
            <button
              onClick={() => setListSwitch(true)}
              className={`btn btn-lg w-[150px]  sm:btn-md sm:w-[125px]
              ${!listSwitch ? 'bg-white text-black' : 'bg-zinc-800'}
              rounded-tl-none rounded-bl-none hover:bg-zinc-800 hover:text-white`}
            >
              Daily
            </button>
          </div>
        </div>
        {vehicleList && (
          <div className=" w-[70%] grid-cols-5 justify-center pt-[35px] sm:grid sm:p-[0]">
            {vehicleList.map((list: IVehicleRates, i: number) => {
              return i === 0 ? (
                <div key={i} className="hidden sm:block ">
                  <p className="bg-zinc-800 p-4 text-zinc-800 sm:sticky sm:top-[285px]">hi</p>
                  {list.rates.map((rate: IRates, j: number) => {
                    return (
                      <div
                        key={j}
                        className={`p-4 ${j % 2 === 1 ? 'bg-zinc-100':''} whitespace-nowrap overflow-hidden`}
                      >
                        <p className="font-bold">{rate.category}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null;
            })}
            {vehicleList.map((list: IVehicleRates, i: number) => {
              return (
                <div key={i} className="">
                  <p className="bg-zinc-800 p-4 font-bold capitalize text-white sm:sticky sm:top-[285px]">
                    flexi&nbsp;{list.plan}
                  </p>
                  {list.rates.map((rate: IRates, j: number) => {
                    return (
                      <div
                        key={j}
                        className={`p-4 ${j % 2 === 1 && 'bg-zinc-100'}`}
                      >
                        <p className="font-bold sm:hidden">{rate.category}</p>
                        <p>${rate.fees}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </section>
    </PageLayout>
  );
};

export default PricingPage;