import { AxiosResponse } from 'axios';
// import useAppDispatch from 'hooks/useAppDispatch';
// import { setIsTokenExpired } from 'modules/auth/slice';
import { toast } from 'react-toastify';

export const handleAPIError = (
  errorResponse: AxiosResponse<any>,
  hasCloseButton?: boolean,
  autoClose?: number | false | undefined,
) => {
  const data = errorResponse?.data;
  // const dispatch = useAppDispatch()
  const ignoreData = ['The token has been blacklisted', 'Token has expired'];
  if (data?.error) {
    if (data?.error === 'invalid_credentials') {
      toast.error('Invalid credentials.');
    } else if (data?.error === 'member_ocr_not_verified') {
      toast.error('OCR not verified.');
    } else if (data?.error === 'promo_already_applied') {
      toast.error('Promo already applied.');
    } else if (data?.error === 'promo_not_exists') {
      toast.error('Invalid promo code.');
    } else if (data?.error.message === 'Wrong number of segments') {
      return 0;
      // toast.error('User authentication required.');
    } else if (data?.error.message === 'user authentication required') {
      return 0;
    } else if (ignoreData.includes(data?.error.message)) {
      return 0;
    }
    // else if(data?.error.message === 'Token has expired'){
    //   // dispatch(setIsTokenExpired(true))
    //   return 0;
    // }
    else {
      toast.error(data?.error?.message, {
        closeButton: hasCloseButton || false,
        autoClose,
      });
    }
  }

  if (data?.message) {
    if (data.message === 'School not found.') {
      return 0;
    }
    toast.error(data?.message, {
      closeButton: hasCloseButton || false,
      autoClose,
    });
  }

  if (data?.errors) {
    if (data?.errors?.convictedExplanation) {
      toast.error(data?.errors?.convictedExplanation[0]);
    } else if (data?.errors?.hasConvictedFile) {
      toast.error(data?.errors?.hasConvictedFile[0]);
    } else if (data?.errors?.oldPassword) {
      toast.error(data?.errors?.oldPassword[0]);
    } else if (data?.errors?.email) {
      toast.error(data?.errors?.email[0]);
    } else if (data?.errors?.planId) {
      toast.error(data?.errors?.planId[0]);
    }
  }
};
