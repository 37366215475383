import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconMonthZero } from 'assets/images/plan_monthly_zero.svg';
import { ReactComponent as IconMonth } from 'assets/images/plan_monthly.svg';
import { ReactComponent as IconHour } from 'assets/images/plan_hourly.svg';
import { ReactComponent as IconDay } from 'assets/images/plan_daily.svg';
import { IRegisterPlan } from 'common/types';
import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';

interface Props {
  plan: IRegisterPlan | null;
}

const Card = ({ plan }: Props) => {
  const user = useAppSelector(selectUser)
  return (
    <article className="flex flex-col items-start">
      <h3 className="hidden md:block mb-[20px] mt-0 text-center text-[26px] font-[700] capitalize">
        Flexi {plan?.name.toLocaleLowerCase().split(' ').includes('default') ? user?.type.name : plan?.name}
      </h3>
      <p className="mx-auto md:mx-0 text-center md:text-left text-[16px] max-w-[600px]">{plan?.description}</p>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        {plan?.name.toLocaleLowerCase() === 'sometimes' ? (
          <IconMonthZero className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        ) : (
          <IconMonth className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        )}
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[16px] md:text-[18px] font-bold">
            {plan?.name.toLocaleLowerCase() === 'sometimes'
              ? 'No Commitment'
              : 'Monthly Commitment'}
          </p>
          <p className="text-[14px] md:text-[16px]">{plan?.commitment}</p>
        </div>
      </div>
      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconDay className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[16px] md:text-[18px] font-bold">Daily Cost</p>
          <p className="text-[14px] md:text-[16px]">{plan?.dailyNotes}</p>
        </div>
      </div>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconHour className="min-h-[30px] min-w-[30px] md:min-h-[40px] md:min-w-[40px]" />
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[16px] md:text-[18px] font-bold">Hourly Cost</p>
          <p className="text-[14px] md:text-[16px]">{plan?.hourlyNotes}</p>
        </div>
      </div>

      <p className="bg-white my-[30px] rounded-[10px] px-[18px] md:px-[30px] py-[15px] md:py-[28px] text-[10px] md:text-[12px] tracking-[.2px] shadow-[0_2px_9px] shadow-flexi-white-2 w-full">
        {plan?.disclaimer}
        <Link
          className="ml-[3px] font-bold text-flexi-orange-1"
          to="/terms-and-conditions"
          target="_blank"
        >
          click here
        </Link>
        .
      </p>
    </article>
  );
};

export default Card;
