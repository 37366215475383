import { selectFavoriteLocationList } from './../modules/favorites/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useFavorites = () => {
  const dispatch = useAppDispatch();
  const favorites = useAppSelector(selectFavoriteLocationList);

  const fetchFavorites = () => {
    dispatch({ type: ACTIONS.FETCH_FAVORITE_LOCATIONS });
  };

  return { fetchFavorites, favorites };
};

export default useFavorites;
