import React, { useRef, useLayoutEffect } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { selectPending } from 'modules/auth/slice';
import { ReactComponent as IconVerified } from 'assets/images/success.svg';
import { useHistory } from 'react-router-dom';
import gsap from 'gsap';

const ForgotPasswordOTPSuccess = () => {
  const isPending = useAppSelector(selectPending);
  const { push } = useHistory();
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  return (
    <section ref={component} className="fadein m-auto mt-[100px] flex w-full max-w-[80vw] md:max-w-[500px] flex-col place-items-center content-center items-center">
      <IconVerified className="mb-[50px] h-[120px] w-[120px]" />
      <h2 className="font-primary mb-[18px] text-left text-[30px] md:text-[40px] font-[900] uppercase  text-primary">
        Verified!
      </h2>
      <p className="mb-[70px] text-[16px] md:text-[18px] text-center">
        You can now proceed in creating your new&nbsp;password.
      </p>
      <button
        className={`btn btn-primary max-h-[50px] md:max-h-[60px] w-[calc(100vw-100px)] md:w-[500px] rounded-full text-[16px] capitalize ${
          isPending ? 'loading' : ''
        }`}
        onClick={() => push('/auth/forgot-password-confirm')}
      >
        proceed
      </button>
    </section>
  );
};

export default ForgotPasswordOTPSuccess;
