import React from 'react';
import PageLayout from 'components/layouts/PageLayout';
import { GuestTopNav } from 'components/TopNav';
// import { createPortal } from 'react-dom';

const OcrThankYouPage = () => {
  // const topNav = document.getElementById('topnav');
  return (
    <PageLayout>
      <GuestTopNav />
      {/* {topNav ?
        createPortal(
          <GuestTopNav/>,
          topNav)
      :<></>} */}
      <section className="m-auto w-full max-w-[500px] py-8 px-4 text-center">
        <h2 className="mb-[30px] mt-6 text-4xl font-bold">Thank you!</h2>
        <p className="text-[18px]">You have successfully verified your license through OCRLabs.</p>
      </section>
    </PageLayout>
  );
};

export default OcrThankYouPage;
