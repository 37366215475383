import React, { useState } from 'react';
import {
  selectVehicleListHourly,
  selectVehicleListDaily,
} from 'modules/vehicle/slice';
import useAppSelector from 'hooks/useAppSelector';

const ListAndRates = () => {
  const [listSwitch, setListSwitch] = useState(false);
  const vehicleList = useAppSelector<any>(
    listSwitch ? selectVehicleListDaily : selectVehicleListHourly,
  );

  return (
    <article className="grid place-items-center ">
      <div className="pt-[30px] ">
        <label
          className="cursor-pointer font-bold underline"
          htmlFor="view-rates-modal"
        >
          View full vehicle list and usage rates
        </label>
      </div>

      <input type="checkbox" id="view-rates-modal" className="modal-toggle" />
      <label className="modal  absolute" htmlFor="view-rates-modal">
        <label
          htmlFor=""
          className="modal-box absolute top-[50px] min-w-[800px] px-[50px]  "
        >
          <h2 className="mb-[50px] mt-5 text-center text-4xl font-bold">
            Personal Plans <br className='block md:hidden'/>Vehicle Lists and Rates
          </h2>
          <div className="mb-5 flex justify-center">
            <button
              onClick={() => setListSwitch(false)}
              className={`btn w-20 ${
                listSwitch && 'bg-white text-black'
              } rounded-tr-none rounded-br-none`}
            >
              Hourly
            </button>
            <button
              onClick={() => setListSwitch(true)}
              className={`btn w-20  ${
                !listSwitch && 'bg-white text-black'
              }  rounded-tl-none rounded-bl-none`}
            >
              Daily
            </button>
          </div>

          {/* VEHICLE LIST */}
          {vehicleList && (
            <div
              className={`grid-cols- grid gap-x-[1px] bg-zinc-100`}
              style={{
                gridTemplateColumns: `repeat(${vehicleList.length + 1},1fr)`,
              }}
            >
              <>
                <div className="bg-zinc-800"></div>
                {vehicleList.map((list: any, i: number) => {
                  return (
                    <p
                      key={i}
                      className="bg-zinc-800 py-2 pl-2 font-bold capitalize text-white"
                    >
                      {list.plan}
                    </p>
                  );
                })}
                <div>
                  {vehicleList[0].rates.map((rate: any, i: number) => {
                    return (
                      <p
                        className={`py-2 pl-2 text-sm ${
                          i % 2 === 0 ? 'bg-white' : 'bg-zinc-100'
                        }`}
                        key={i}
                      >
                        {rate.category}
                      </p>
                    );
                  })}
                </div>
                {vehicleList.map((list: any, i: number) => {
                  return (
                    <div key={i}>
                      {list.rates.map((rate: any, i2: number) => {
                        return (
                          <p
                            className={`py-2 pl-2 text-sm ${
                              i2 % 2 === 0 ? 'bg-white' : 'bg-zinc-100'
                            }`}
                            key={i2}
                          >
                            ${rate.fees}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            </div>
          )}

          <div className="flex flex-col w-full md:w-9/12 place-items-center justify-center gap-y-5 text-center text-[10px]">
            <p className="mt-10">
              Senior rate plans are available to those who held a current Senior
              Card and a copy must be provided to the Member Care Team for
              approval to join on this plan. Please contact info@flexicar.com.au
              for more information on how to join on this plan.?
            </p>
            <p className="">
              * All annual fees returned as driving credit &#40;currently annual
              fee is not credited back to members as driving credit&#41;. **No
              excess reduction available on Prestige or Commercial vehicles. New
              rates only applicable to new members and any existing switch
              plans. KM limit apply to daily rates.
            </p>
          </div>
        </label>
      </label>
    </article>
  );
};

export default ListAndRates;
