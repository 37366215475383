import React, { useEffect, ComponentType } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import HomePage from 'pages/HomePage';
import AuthPage from 'pages/AuthPage';
import AppPage from 'pages/AppPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDeviceId, getToken } from 'common/helpers';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import RegisterPage from 'pages/RegisterPage';
import useScrollToTop from 'hooks/useScrollToTop';
import TermsAndConditionsPage from 'pages/PublicPages/TermsAndConditionsPage';
import AccountTypesPage from 'pages/PublicPages/AccountTypesPage';
import PrivacyPolicyPage from 'pages/PublicPages/PrivacyPolicyPage';
import PricingPage from 'pages/PublicPages/PricingPage';
import OcrThankYouPage from 'pages/OcrThankYouPage';
import OcrLoadingPage from 'pages/OcrLoadingPage';
import ReportIssuesPage from 'pages/PublicPages/ReportIssuesPage';
import ExplorePage from 'pages/ExplorePage';
import { BottomSignIn } from 'components/HomeBottomSignIn';
import { createPortal } from 'react-dom';
import MobileOcrPendingPage from 'features/MobileOcrPendingPage';
import OnboardingPage from 'pages/OnboardingPage';
import SignUpPage from 'pages/SignUpPage';
import { GuestTopNav } from 'components/TopNav';
import UnsubscribePage from 'pages/PublicPages/UnsubscribePage';
import useAppSelector from 'hooks/useAppSelector';
import { selectLoggedInDevices } from 'modules/me/slice';
import Booking from 'pages/StandAloneBookingMap';
import HelpAndSupport from 'pages/HelpAndSupportPage';
import SignUpBusiness from 'pages/SignUpBusinessPage';
import AuthPageOptimized from 'pages/AuthPageOptimized';

type PrivateRouteType = {
  component: ComponentType;
} & RouteProps;

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteType) => {
  const token = getToken();
  const dispatch = useAppDispatch();
  const loginDevice = getDeviceId();
  const loggedInDevicesInfo = useAppSelector(selectLoggedInDevices);
  const logins = loggedInDevicesInfo?.logins

  useEffect(() => {
    if (token) {
      dispatch({ type: ACTIONS.FETCH_ME });
      dispatch({ type: ACTIONS.FETCH_DEVICES });
    }
  }, [token]);

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_DEVICES });

    if(token && loginDevice && logins){
      if(logins.find(data => data.deviceId === loginDevice)){
        // do nothing if device id is found else logout the account
      } else {
        dispatch({ type: ACTIONS.LOGOUT_ACCOUNT })
      }
    }
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) return <Component {...props} />;

        return <Redirect to="/?reload=true" />;
      }}
    />
  );
};

const App = () => {
  useScrollToTop();
  const token = getToken();
  const topNav = document.getElementById('topnav');
  const bottomSignIn = document.getElementById('bottom_signin');

  const { pathname } = useLocation();

  return (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/melbourne" component={HomePage} />
        <Route exact path="/sydney" component={HomePage} />
        <Route path="/auth" component={AuthPage} />
        <Route path="/auth-v2" component={AuthPageOptimized} />
        <Route path="/register" component={RegisterPage} />
        <Route path="/register-v2" component={SignUpPage} />
        <Route path="/register-business" component={SignUpBusiness} />
        <Route path="/onboarding" component={OnboardingPage} />
        <Route path="/terms-and-conditions" component={TermsAndConditionsPage} />

        <Route path="/faq" component={HelpAndSupport} />
        <Route path="/account-types" component={AccountTypesPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/pricing-list" component={PricingPage} />
        <Route path="/unsubscribe" component={UnsubscribePage} />
        <Route path="/report-issues" component={ReportIssuesPage} />
        <Route
          path="/ocr-application-pending"
          component={MobileOcrPendingPage}
        />
        <Route exact path="/ocr/loading" component={OcrLoadingPage} />
        <Route exact path="/ocr/done" component={OcrThankYouPage} />
        <Route exact path="/explore" component={ExplorePage} />
        <Route exact path="/map" component={Booking} />
        <PrivateRoute path="/app" component={AppPage} />
      </Switch>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnHover
      />
      {topNav ?
        createPortal(
          <>{token === null && pathname === `/` && <GuestTopNav />}</>,
          topNav,
        )
       : (
        <></>
      )}
      {bottomSignIn ? (
        createPortal(
          <>{token !== null ? <></> : <BottomSignIn />}</>,
          bottomSignIn,
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
