import React, { useState, useRef, useLayoutEffect } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { selectPending } from 'modules/auth/slice';
import { ReactComponent as IconSuccess } from 'assets/images/success_reset_pw.svg';
import Modal from 'components/Modal';
import LoginForm from 'features/LoginForm';
import { useMediaQuery } from 'react-responsive';
import gsap from 'gsap';

const ForgotPasswordConfirmSuccess = () => {
  const isPending = useAppSelector(selectPending);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  return (
    <section ref={component} className="fadein m-auto mt-[100px] flex w-full max-w-[80vw] md:max-w-[500px] flex-col place-items-center content-center items-center">
      <IconSuccess className="mb-[50px]" />
      <h2 className="font-primary mb-[20px] text-left text-[30px] md:text-[40px] font-[900] uppercase text-primary">
        great!
      </h2>
      <p className="mb-[70px] text-center">
        You have successfully reset your password.
        <br />
        Please use your new password when signing in.
      </p>
      <button
        className={`btn btn-primary w-full h-[60px] rounded-full text-[16px] capitalize ${
          isPending ? 'loading' : ''
        }`}
        onClick={()=>setIsModalOpen(true)}
      >
        Return to Sign In
      </button>
      
      <Modal
        width={isMobile ? '' : 'w-[490px]'}
        maxWidth={isMobile ? '' : 'w-[490px]'}
        hasCloseButton={false}
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        bgColor="bg-flexi-white-1"
        px={isMobile ? 'px-[25px]' : '!px-[60px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <LoginForm />
      </Modal>
    </section>
  );
};

export default ForgotPasswordConfirmSuccess;
