import { IRegisterPlanInclusions } from 'common/types';
import React from 'react';
interface Props {
  inclusions: IRegisterPlanInclusions[] | undefined;
}

const Included = ({ inclusions }: Props) => {

  return (
    <article>
      <h2 className="mb-[15px] md:mb-[30px] text-[14px] md:text-[16px] md:text-3xl font-bold">What&apos;s included?</h2>
      <div className="mb-[25px] md:mb-[70px] flex justify-between flex-col">
          {inclusions?.map((inclusion, i) => {
            return (
              <div
                key={i}
                tabIndex={0}
                className="collapse-arrow !outline-none collapse pr-0  bg-base-100 w-[calc(100vw-175px)] md:w-auto"
              >
                <div className="border-b-[1px] border-base-300 collapse-title text-[14px] md:text-[16px] font-bold pl-0 after:!right-[0.5rem] after:!shadow-[2px_2px] after:!shadow-flexi-orange-1">
                  {inclusion.title}
                </div>
                <div className="collapse-content px-[20px] bg-[#F9F9F9]">
                  <p className="pt-[20px] text-[12px] md:text-[14px]">
                    <span dangerouslySetInnerHTML={{ __html: inclusion.description }} />
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </article>
  );
};

export default Included;
