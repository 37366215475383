import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import CustomCheckBox from 'components/CustomCheckbox';
import { InvalidText } from 'components/Typography';
import ImageUpload from './ImageUpload';
import useAppSelector from 'hooks/useAppSelector';
import { selectErrors, selectReportBookingStatus } from 'modules/booking/slice';
import { RequestStatusEnum } from 'common/types';
import { MdClose } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';
interface Props {
  onSave: any;
}

type FormValues = {
  categories: string[];
  // images: any[];
};

const validationSchema = yup.object({
  // .required('Please select at least one item.'),
});

const OnboardingForm = ({ onSave }: Props) => {
  const categories = [
    'Cleanliness',
    'Mechanical',
    'Maintenance',
    'Low Fuel',
    'Missing Cards',
    'Other',
  ];
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
  } = useForm<FormValues>(formOptions);

  const [textValue, setTextValue] = useState<any>({
    cleanliness: '',
    mechanical: '',
    maintenance: '',
    lowFuel: '',
    missingCards: '',
    other: '',
  });

  const [uploads, setUploads] = useState<any>({
    cleanliness: [],
    mechanical: [],
    maintenance: [],
    lowFuel: [],
    missingCards: [],
    other: [],
  });

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const reportStatus = useAppSelector(selectReportBookingStatus);
  const reportErrors = useAppSelector(selectErrors);

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    return onSave({ ...data, categories: selectedCategories, uploads, textValue });
  };

  const handleCheckboxSelected = (category: string, evt: any) => {
    if (evt.target.checked) {
      setSelectedCategories((prevState: string[]) => {
        return [...prevState, ...[category]];
      });
      return;
    }

    setSelectedCategories((prevState: string[]) =>
      prevState.filter((item) => item !== category),
    );
  };

  const toCamelCase = (category:string) => {
    return category
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase(),
      )
      .replace(/\s+/g, '');
  };

  const handleImageRemove = (imageIndex: number, category: string) => {
    const imageFilter = uploads[toCamelCase(category)].filter(
      (_: any, index: number) => index !== imageIndex,
    );

    setUploads({ ...uploads, [toCamelCase(category)]: imageFilter });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col"
    >
      {/* <p>{selectedCategories}</p> */}
      <div>
        {categories &&
          categories.length &&
          categories.map((category: string, index) => {
            return (
              <div key={index} className="my-[20px] border-b-[1px] pb-[20px]">
                <CustomCheckBox 
                  fill="fill-flexi-black-2"
                  title={category}
                  size={14}
                  isCapitalize={false}>
                  <input
                    {...register('categories')}
                    type="checkbox"
                    id={`categories[${index}]`}
                    name="categories"
                    className="checkbox checkbox-xs h-[25px] w-[25px] rounded-md"
                    value={category}
                    onChange={(e) => handleCheckboxSelected(category, e)}
                    style={{
                      // @ts-ignore
                      '--chkfg': '0',
                      '--chkbg': '0',
                      backgroundColor: 'white',
                    }}
                  />
                </CustomCheckBox>

                {selectedCategories.includes(category) && (
                  <section className="my-[20px] rounded-[10px] bg-[#F9F9F9] p-[30px]">
                    <ImageUpload
                      {...{ uploads, setUploads, category }}
                      textValue={textValue}
                      setTextValue={setTextValue}
                    />
                  </section>
                )}
                {selectedCategories.includes(category) &&
                  uploads[toCamelCase(category)].length > 0 && (
                    <div className="rounded-lg bg-white p-[15px] py-[5px] mb-[10px] shadow-[0_2px_9px_0] shadow-[#000000]/5">
                      {uploads[toCamelCase(category)].map(
                        (upload: any, index: number) => {
                          return (
                            <div
                              className={classNames(
                                'flex h-fit py-[15px] max-h-[70px] w-full items-center justify-between',
                                {
                                  // 'border-b-[1px]':
                                  //   index !==
                                  //   uploads[toCamelCase(category)].length - 1,
                                },
                              )}
                              key={index}
                            >
                              <div>
                                <BsImage style={{ fontSize: '30px' }} />
                              </div>
                              <div className="flex flex-1 w-[calc(100vw-200px)] flex-col px-[10px]">
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-[14px] font-bold">
                                  {upload.name}
                                </p>
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-[10px]">
                                  {Math.round(
                                    ((upload.size || 0) / 1024) * 100,
                                  ) / 100}{' '}
                                  kb
                                </p>
                              </div>
                              <div>
                                <MdClose
                                  onClick={() =>
                                    handleImageRemove(index, category)
                                  }
                                />
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
              </div>
            );
          })}

        <InvalidText>{reportErrors?.response?.data?.errors?.reasons[0] ? 'Please select at least one item.' : ''}</InvalidText>
      </div>
      <div className="mt-5 flex w-full flex-1 flex-col-reverse pb-[30px]">
        <button
          type="submit"
          className={classNames(
            'btn btn-primary min-h-[50px] w-full rounded-full text-[16px] capitalize',
            {
              'loading bg-flexi-green-2':
                reportStatus === RequestStatusEnum.PENDING,
            },
          )}
          disabled={false}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default OnboardingForm;
