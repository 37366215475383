import React, { useRef, useLayoutEffect } from 'react';

import Lottie from 'react-lottie';
import gsap from 'gsap';

import thumbsUpJson from 'assets/json/icon-thumbs-up.json';
import Guidelines from './Guidelines';

const SignUpSplash = () => {
  const component = useRef<HTMLDivElement>(null);

  const iconJsonOptions = {
    loop: false,
    autoplay: true,
    animationData: thumbsUpJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex w-full flex-col md:max-w-[600px]"
    >
      <section className="relative m-auto mt-[-70px] min-h-[calc(100vh)] w-full max-w-[1000px] md:mt-0 md:flex md:min-h-0">
        <div className="flex min-h-[calc(100vh)] flex-auto items-start justify-center pb-[100px] pt-[45px] md:mx-0 md:min-h-0 md:justify-start md:pb-0 md:pt-[0px]">
          <div>
            <div className="flex h-[200px] w-full items-center justify-center">
              <Lottie options={iconJsonOptions} height={200} width={200} />
            </div>
            <h2 className="font-primary mb-[36px] text-center text-[30px] font-[900] uppercase leading-[32px] text-flexi-orange-1 md:mb-[80px] md:text-[50px] md:leading-[50px]">
              get the thumbs up to drive with flexicar!
            </h2>
            <Guidelines />
            <div className="mt-[80px]">
              <a href="/register-v2/id-verification">
                <button className="btn btn-primary relative !h-[50px] w-full overflow-hidden rounded-full  capitalize hover:bg-flexi-green-2 disabled:text-white md:!h-[60px]">
                  Let&apos;s Start!
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default SignUpSplash;
