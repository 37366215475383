import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectChangePartnerStatus,
  selectPending,
  selectPendingChangePartner,
  selectRemoveVffStatus,
  selectUser,
  setChangePartnerStatus,
  setRemoveVffStatus,
} from 'modules/me/slice';
import { PageTitle } from 'components/Typography';
import MyAccountNav from 'components/MyAccountNav';
import { ReactComponent as IconPlus } from 'assets/images/plus.svg';
// import { ReactComponent as IconRemoveCard } from 'assets/images/remove_card.svg';
import classNames from 'classnames';
import { RequestStatusEnum } from 'common/types';
import CustomInput from 'components/CustomInput';
import gsap from 'gsap';
import BurgerNav from 'components/BurgerNav';
import Modal from 'components/Modal';
import PendingLoader from 'components/PendingLoader';
import { Helmet } from 'react-helmet';
import MyAccountBlock from 'components/MyAccountBlock';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { useLocation } from 'react-router-dom';
import LottieAnimation from 'components/LottieAnimation';
import removeCardLottie from 'assets/json/icon-remove-card.json';

const MyAccountLoyaltyPoints = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [isRewardPartnerModalOpen, setRewardPartnerModalOpen] = useState(false);
  const [isRemoveRewardPartnerModalOpen, setRemoveRewardPartnerModalOpen] =
    useState(false);
  const [membershipId, setMembershipId] = useState('');
  const [isMemberIdError, setMemberIdError] = useState(false);
  const changePartnerStatus = useAppSelector(selectChangePartnerStatus);
  const isPendingChangePartner = useAppSelector(selectPendingChangePartner);
  const [partner, setPartner] = useState('');
  const userPending = useAppSelector(selectPending);
  const [burgerActive, setBurgerActive] = useState<number>(-1);
  const removeVffStatus = useAppSelector(selectRemoveVffStatus);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const { pathname } = useLocation();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffect(() => {
    if (changePartnerStatus === RequestStatusEnum.SUCCESS) {
      setRewardPartnerModalOpen(false);
      dispatch(setChangePartnerStatus(null));
    }
  }, [changePartnerStatus]);

  useEffect(() => {
    if (user?.partner) {
      setMembershipId(user.partner?.partnerMembershipId);
      setPartner(user.partner?.name);
    }
  }, [user]);

  const saveVFF = () => {
    if (!membershipId && !partner) {
      setMemberIdError(true);
    } else {
      const payload = {
        vff_membership_id: membershipId,
      };
      dispatch({ type: ACTIONS.CHANGE_VFF, payload });
      setRewardPartnerModalOpen(false);
    }
  };

  const handlePartnerChange = (e: any) => {
    setPartner(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_REWARD_PARTNERS });
  }, []);

  const handleClose = () => {
    setRemoveRewardPartnerModalOpen(false);
    if (
      removeVffStatus === RequestStatusEnum.SUCCESS ||
      removeVffStatus === RequestStatusEnum.FAILED
    ) {
      dispatch(setRemoveVffStatus(null));
    }
  };

  const handleRemoveVff = () => {
    dispatch({ type: ACTIONS.REMOVE_VFF });
  };

  const renderRewardPartner = () => {
    if (user?.vff?.vffMembershipId) {
      return (
        <div className="mt-[30px] flex w-full justify-between gap-[10px]">
          <div
            // flex gap-[40px]
            className={classNames(
              'flex flex-col gap-[10px] md:flex-row md:gap-[40px]',
              {
                hidden: isRewardPartnerModalOpen,
              },
            )}
          >
            <p className="font-bold">Current Partner</p>
            <p className="w-[250px] truncate">
              Velocity Frequent Flyer - {user.vff.vffMembershipId}
            </p>
          </div>
          {isRewardPartnerModalOpen ? (
            <div className="relative mt-[30px] flex items-center gap-[15px] overflow-visible">
              <button
                className="text-[16px] font-bold text-primary md:text-[16px]"
                onClick={saveVFF}
                disabled={isPendingChangePartner}
              >
                Save
              </button>
              {isPendingChangePartner && (
                <span className="absolute right-[-50px] h-[20px] w-[20px] animate-spin overflow-visible rounded-full border-[2px] border-flexi-black-4 border-t-transparent border-b-transparent" />
              )}
            </div>
          ) : (
            <BurgerNav
              i={0}
              setBurgerActive={setBurgerActive}
              burgerActive={burgerActive}
              enableClickAway={true}
            >
              <p
                className="w-full p-[15px] px-[0px] text-center hover:bg-flexi-black-7"
                onClick={() => setRewardPartnerModalOpen(true)}
              >
                Edit
              </p>
              <p
                className="w-full p-[15px] px-[0px] text-center hover:bg-flexi-black-7"
                onClick={() => setRemoveRewardPartnerModalOpen(true)}
              >
                Remove
              </p>
            </BurgerNav>
          )}
        </div>
      );
    }
    return (
      <>
        {!isRewardPartnerModalOpen ? (
          <button
            className="flex h-[22px] gap-[10px] font-bold text-primary"
            onClick={() => {
              setRewardPartnerModalOpen(true);
            }}
          >
            <IconPlus className="h-[12px] w-[12px] self-center md:h-[14px] md:w-[14px]" />
            <p className="text-[16px] leading-[22px] md:text-[16px]">
              Add Reward Partner
            </p>
          </button>
        ) : (
          <div className="relative mt-[30px] flex items-center gap-[15px] overflow-visible">
            <button
              className="text-[16px] font-bold text-primary md:text-[16px]"
              onClick={saveVFF}
              disabled={isPendingChangePartner}
            >
              Save
            </button>
            {isPendingChangePartner && (
              <span className="absolute right-[-50px] h-[20px] w-[20px] animate-spin overflow-visible rounded-full border-[2px] border-flexi-black-4 border-t-transparent border-b-transparent" />
            )}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  }, []);
  
  return (
    <article
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] md:mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Account - Loyalty Points and Rewards</title>
        <meta
          name="description"
          content="My Flexicar Account - Loyalty Points and Rewards"
        />
      </Helmet>
      <MyAccountNav title="loyalty points and rewards" />
      <div className="mb-[60px] gap-[100px] lg:flex">
        <section className="flex flex-col lg:min-w-[500px]">
          <PageTitle>
            loyalty points
            <br />
            and rewards
          </PageTitle>

          {userPending ? (
            <div className="mt-[10px]  flex border-t-[1px] pt-[40px] text-[18px] md:mt-[30px] md:pt-[50px]">
              <span className="min-h-[50px] min-w-[50px] animate-spin rounded-full border-[5px] border-zinc-300 border-b-transparent border-t-transparent"></span>
            </div>
          ) : (
            <>
              {user?.vff?.vffMembershipId ? (
                <p className="mb-[30px] mt-[30px] border-t-[1px] pt-[50px] text-[16px] md:text-[18px]">
                  You have succesfully linked your loyalty partner
                  membership&nbsp;number.
                </p>
              ) : (
                <p className="mb-[30px] mt-[30px] border-t-[1px] pt-[50px] text-[16px] md:text-[18px]">
                  You are not currently earning points with any of our loyalty
                  program partners.
                </p>
              )}
              {isRewardPartnerModalOpen && (
                <section>
                  <select
                    className={classNames(
                      'select select-bordered h-[50px] w-full font-normal md:h-[60px]',
                      {
                        'text-flexi-black-4': !partner,
                      },
                    )}
                    onChange={handlePartnerChange}
                    defaultValue={partner}
                  >
                    <option className="text-flexi-black-4">
                      Velocity Frequent Flyer
                    </option>

                    {/* {rewardPartners?.map((i) => {
                      return (
                        <option
                          key={i.slug}
                          value={i.name}
                          id={i.slug}
                          className='text-black'
                          // disabled
                        >
                          {i.name}
                        </option>
                      );
                    })} */}
                  </select>
                  <CustomInput
                    title="Membership ID"
                    error={isMemberIdError}
                    margin={20}
                  >
                    <input
                      type="text"
                      placeholder="membership id"
                      className={classNames(
                        'peer input input-bordered  w-full',
                        {
                          'border-red-500': isMemberIdError,
                        },
                      )}
                      defaultValue={user?.vff?.vffMembershipId}
                      // value={membershipId}
                      onChange={(e) => setMembershipId(e.target.value)}
                    />
                  </CustomInput>
                </section>
              )}
              <div className="flex gap-10 text-[16px] md:text-[16px]">
                {renderRewardPartner()}
              </div>
            </>
          )}
        </section>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>
      <Modal
        isModalOpen={isRemoveRewardPartnerModalOpen}
        handleClose={handleClose}
      >
        {removeVffStatus === RequestStatusEnum.SUCCESS ? (
          <>
            <LottieAnimation
              lottieFile={removeCardLottie}
              isStopped={!isRemoveRewardPartnerModalOpen}
            />
            <p className="title">Removed Successfully!</p>
            <p className="text-center text-[18px]">
              We&apos;ve successfully removed your loyalty points.
            </p>
            <button
              className="button btn border-0 capitalize"
              onClick={handleClose}
            >
              Ok
            </button>
          </>
        ) : removeVffStatus === RequestStatusEnum.PENDING ? (
          <PendingLoader />
        ) : !removeVffStatus ? (
          <>
            <LottieAnimation
              lottieFile={removeCardLottie}
              isStopped={!isRemoveRewardPartnerModalOpen}
            />
            <p className="title">Remove Loyalty Points</p>
            <p className="text-center text-[18px]">
              Are you sure you want to remove your loyalty points membership
              number?
            </p>
            <button
              className="button btn border-0 text-[16px] capitalize"
              onClick={handleRemoveVff}
            >
              remove
            </button>
          </>
        ) : (
          <>
            <p className="title">Error</p>
            <p className="text-center text-[18px]">error</p>
            <button
              className="button btn border-0 text-[16px] capitalize"
              onClick={handleClose}
            >
              error
            </button>
          </>
        )}
      </Modal>
    </article>
  );
};

export default MyAccountLoyaltyPoints;
