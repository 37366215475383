import { useLocation } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'react-use';

const useScrollToTop = () => {
  const { pathname } = useLocation();

  const scrollToTop = () => window.scrollTo(0, 0);

  useMount(() => {
    scrollToTop();
  });

  useUpdateEffect(() => {
    scrollToTop();
  }, [pathname]);
};

export default useScrollToTop;
