import classNames from 'classnames';
import React, { Dispatch, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';
interface Props {
  setBurgerActive: Dispatch<number>;
  i: number;
  burgerActive: number;
  children: React.ReactNode;
  extraClass?: string;
  width?: string;
  enableClickAway?: boolean;
}

function BurgerNav({
  setBurgerActive,
  i,
  burgerActive,
  children,
  extraClass,
  width = '150px',
  // enableClickAway=true,
}: Props) {
  const { pathname } = useLocation();
  const modalRef = useRef(null);
  const handleBurgerClick = (e: any, i: number) => {
    e.stopPropagation();
    setBurgerActive(i);
  };

  useClickAway(modalRef, (e) => {
    if (pathname !== '/app/account/drivers') {
      e.preventDefault()
      setBurgerActive(-1);
    }
  });

  return (
    <div
      className={`relative z-10 flex h-auto  w-[20px] cursor-pointer flex-col items-center gap-[5px] self-center ${extraClass}`}
      onClick={()=> burgerActive===i && setBurgerActive(-1)}
    >
      <label
        onClick={(e) => handleBurgerClick(e, i)}
        className={`modal-button flex w-[25px] cursor-pointer flex-col items-center gap-[3px]`}
      > 
        <span className="pointer-events-none min-h-[3px] min-w-[3px] rounded-full bg-flexi-black-3" />
        <span className="pointer-events-none min-h-[3px] min-w-[3px] rounded-full bg-flexi-black-3" />
        <span className="pointer-events-none min-h-[3px] min-w-[3px] rounded-full bg-flexi-black-3" />
      </label>
      {burgerActive === i && (
        <div
          className={classNames(
            `absolute right-[150%] top-[-20px] hidden h-auto flex-col items-center capitalize w-[${width}] overflow-hidden rounded-[10px_10px_10px_10px] bg-white text-[16px] shadow-[0_0_19px] shadow-[#000000]/10
            [&>*]:border-t-[1px] [&>*]:border-flexi-black-5
          `,
            {
              '!flex': burgerActive === i,
            },
          )}
          ref={modalRef}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default BurgerNav;
