import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { addMinutes } from 'date-fns';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import moment from 'moment';
import gsap from 'gsap';

import { ReactComponent as ErrorIcon } from 'assets/images/failed.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { ReactComponent as IconSuccessModal } from 'assets/images/success.svg';
import { ReactComponent as IconFailed } from 'assets/images/cancel_booking.svg';
import { IMAGE_URL } from 'common/constants';
import { selectError, setError } from 'modules/auth/slice';
import { selectPendingCardUrl } from 'modules/transaction/slice';
import {
  selectBookingSummaryModify,
  selectBookingSummaryModifyStatus,
  selectCancelBookingStatus,
  selectModifyBookingId,
  selectPendingCancelBooking,
  selectUpdateBookingStatus,
} from 'modules/booking/slice';
import { PageTitle } from 'components/Typography';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import usePaymentMethod from 'hooks/usePaymentMethod';
import PaymentMethodModal from 'components/PaymentMethodModal';
import SummaryInvoice from './SummaryInvoice';
import BookingInfo from './BookingInfo';
import ModalCancel from './ModalCancel';
import ModalModify from './ModalModify';
import Modal from 'components/Modal';
import { RequestStatusEnum } from 'common/types';

const BookingDetails = () => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const { paymentCards } = usePaymentMethod();
  const cardError = useAppSelector(selectError);
  const bookingId = useAppSelector(selectModifyBookingId);
  const status = useAppSelector(selectCancelBookingStatus);
  const pendingCardChange = useAppSelector(selectPendingCardUrl);
  const cardChangeStatus = useAppSelector(selectUpdateBookingStatus);
  const pendingCancel = useAppSelector(selectPendingCancelBooking);
  const bookingSummary = useAppSelector(selectBookingSummaryModify);
  const isPending = useAppSelector(selectBookingSummaryModifyStatus);
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
  const [isCantModifyModalOpen, setIsCantModifyModalOpen] = useState(false);
  const [isPaymentCardsShown, setIsPaymentCardsShown] =
    useState<boolean>(false);
  const [isPaymentDeclinedModalOpen, setIsPaymentDeclinedModalOpen] =
    useState<boolean>(false);
  const [burgerActive, setBurgerActive] = useState(-1);

  const component = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const dateNow = moment().tz(bookingSummary?.podTimeZone || 'Australia/Perth');
  const bookingStart = moment(bookingSummary?.startsAt).tz(
    bookingSummary?.podTimeZone || 'Australia/Perth',
  );
  const minutesBeforeBookingStart = bookingStart.diff(dateNow, 'minutes');
  const roundUpDate = () => {
    const date = addMinutes(new Date(), 15);
    const roundUpTo = (roundTo: number) => (x: any) =>
      Math.ceil(x / roundTo) * roundTo;
    const roundUpTo15Minutes = roundUpTo(1000 * 60 * 15);
    const rounded = roundUpTo15Minutes(date);
    if (bookingSummary?.podTimeZone) {
      const options = { timeZone: bookingSummary?.podTimeZone };
      const dateString = date.toLocaleString('en-US', options);
      const podRounded = roundUpTo15Minutes(new Date(dateString));
      return new Date(podRounded);
    }
    return new Date(rounded);
  };

  useEffect(() => {
    const handlePopState = () => {
      push('/app/my-bookings');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, [isPending]);

  const handleModifyBooking = () => {
    dispatch({
      type: ACTIONS.FETCH_VEHICLE_AVAILABLE,
      payload: {
        vehicleId: bookingSummary?.vehicle?.id,
        startsAt: bookingSummary?.startsAt,
      },
    });
    setIsModifyModalOpen(true);
  };

  useEffect(() => {
    if (bookingId) {
      const payload = {
        bookingId,
      };
      dispatch({
        type: ACTIONS.FETCH_BOOKING_SUMMARY_MODIFY,
        payload,
      });
    }
  }, [bookingId]);

  useEffectOnce(() => {
    if (!bookingId && status === null) {
      push('/app/my-bookings');
    }
  });

  useEffect(() => {
    if (bookingSummary) {
      const payload = {
        vehicleId: bookingSummary.vehicleId,
        startsAt: bookingSummary.startsAt,
      };
      dispatch({
        type: ACTIONS.FETCH_VEHICLE_AVAILABLE,
        payload,
      });
    }

    if (
      bookingSummary?.tripStatus.toLocaleLowerCase() === "booked" &&
      bookingSummary?.paymentDeclined?.message &&
      (bookingSummary?.paymentDeclined?.message !== undefined ||
        bookingSummary?.paymentDeclined?.message !== '')
    ) {
      setIsPaymentDeclinedModalOpen(true);
    }
  }, [bookingSummary]);

  const handleBurgerClick = (e: any, i: number) => {
    e.stopPropagation();
    setBurgerActive(i);
  };

  const handleUpdateBookingPaymentCard = (
    bookingId: number,
    paymentAccountId: number,
  ) => {
    const payload = {
      bookingId,
      paymentAccountId,
    };
    dispatch({ type: ACTIONS.UPDATE_BOOKING_PAYMENT_CARD, payload });
  };

  return (
    <article
      ref={component}
      className="fadein m-auto flex flex-col gap-[40px] px-[25px] py-[40px] md:gap-[100px] md:py-[80px] lg:flex-row lg:px-0"
    >
      <Helmet>
        <title>My Booking - Details</title>
        <meta name="description" content="My Flexicar Booking - Details" />
      </Helmet>
      <div className="flex w-full flex-col gap-[40px] md:gap-[50px]">
        <PageTitle>Booking Details</PageTitle>
        <BookingInfo />
      </div>
      <div className="flex w-full flex-col">
        <div className="sticky top-[100px]">
          <div className=" mx-auto w-full md:mx-0">
            <SummaryInvoice
              actualAmount={bookingSummary?.actualAmount}
              breakdowns={bookingSummary?.breakdowns}
              paymentMethod={bookingSummary?.paymentMethod}
            />
          </div>
          {bookingSummary?.status === 2 &&
            moment().isBefore(moment(bookingSummary?.endsAt).local()) && (
              <>
                <div
                  className={classNames(
                    'relative mt-[30px] flex w-full cursor-pointer items-center justify-between border-t-[1px] border-b-[1px] py-[30px] text-[16px] font-bold capitalize text-primary',
                    {
                      'loading pointer-events-none opacity-50': pendingCancel,
                    },
                  )}
                  // onClick={handleCancelBooking}
                  onClick={() => setIsCancelModalOpen(true)}
                >
                  <p className="text-[16px] md:text-[16px]">
                    cancel this booking
                  </p>
                  <IconArrow className="h-[10px] w-[10px] -rotate-90" />
                </div>
                <div
                  className={classNames(
                    'flex w-full cursor-pointer items-center justify-between border-b-[1px] py-[30px] text-[16px] font-bold capitalize text-primary',
                    {
                      // 'text-[#F2F2F2]': minutesBeforeBookingStart < 15,
                    },
                  )}
                  onClick={() => {
                    if (minutesBeforeBookingStart >= 15) {
                      handleModifyBooking();
                    } else {
                      setIsCantModifyModalOpen(true);
                    }
                  }}
                >
                  <p className="text-[16px] md:text-[16px]">modify booking</p>
                  <IconArrow className="h-[10px] w-[10px] -rotate-90" />
                </div>
              </>
            )}
        </div>
      </div>

      <Modal
        isModalOpen={isCantModifyModalOpen}
        handleClose={() => setIsCantModifyModalOpen(false)}
        px={isMobile ? '!px-[50px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <IconFailed className="icon mx-auto mb-[30px]" />
        <p className="title">Unable to modify booking</p>
        <p className="text-center text-[18px]">
          Booking changes are only allowed up until 15 minutes before pickup{' '}
          <br />
          Your booking will start soon. Please rebook using the mobile app.
        </p>
        <p
          className="button btn capitalize"
          onClick={() => setIsCantModifyModalOpen(false)}
        >
          Ok
        </p>
        <div className="flex w-full items-center py-[25px]">
          <span
            className="text-flexi-errorS mx-auto cursor-pointer font-bold"
            onClick={() => {
              setIsCantModifyModalOpen(false);
              setIsCancelModalOpen(true);
            }}
          >
            Cancel Booking
          </span>
        </div>
      </Modal>

      <ModalModify
        bookingId={bookingId}
        isModalOpen={isModifyModalOpen}
        setIsModalOpen={setIsModifyModalOpen}
        roundUpDate={new Date(roundUpDate())}
        setIsCancelModalOpen={setIsCancelModalOpen}
        refreshMode={null}
      />
      <ModalCancel
        bookingId={bookingId}
        // bookingSummary={bookingSummary}
        isModalOpen={isCancelModalOpen}
        handleClose={() => setIsCancelModalOpen(false)}
        setIsModalOpen={setIsCancelModalOpen}
      />

      <Modal
        isModalOpen={isPaymentDeclinedModalOpen}
        hasCloseButton={false}
        enableClickAway={false}
        handleClose={() => {
          if (
            bookingSummary?.paymentDeclined?.message &&
            (bookingSummary?.paymentDeclined?.message !== undefined ||
              bookingSummary?.paymentDeclined?.message !== '')
          )
            setIsPaymentDeclinedModalOpen(false);
          setIsPaymentCardsShown(false);
        }}
        isErrorModal={true}
      >
        {cardChangeStatus === RequestStatusEnum.SUCCESS ? (
          <>
            <IconSuccessModal className="icon" />
            <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
              Card changed successfully.
            </h1>

            <button
              className="button btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2"
              onClick={() => {
                setIsPaymentDeclinedModalOpen(false);
                setIsPaymentCardsShown(false);
              }}
            >
              ok
            </button>
          </>
        ) : isPaymentCardsShown &&
          cardError &&
          cardError.data.errorCode.message ? (
          <>
            <ErrorIcon className="icon" />
            <p className="title">Card Failed</p>
            <p className="text-center text-[18px]">
              {cardError.data.errorCode.message}
            </p>
            <p
              className="button btn capitalize"
              onClick={() => {
                dispatch(setError(null));
                push('/app/my-bookings');
              }}
            >
              Okay
            </p>
          </>
        ) : isPaymentCardsShown && bookingId ? (
          <>
            <div className="mt-[10px] md:mt-[30px]">
              {!paymentCards ? (
                <div className="flex items-center gap-4">
                  <AiOutlineLoading3Quarters className="ml-4 h-[20px] w-[20px] animate-spin fill-flexi-orange-1" />
                  Loading payment cards...
                </div>
              ) : (
                paymentCards?.map((card, i) => {
                  let imageName;
                  let isImageExist = true;
                  try {
                    imageName = require(`assets/images/card-${card.type}.svg`);
                  } catch (ex) {
                    isImageExist = false;
                  }

                  return (
                    <div
                      key={card.id}
                      className={classNames(
                        'flex w-full place-items-center  justify-between  border-t-[1px] border-flexi-black-6  py-[25px]',
                        {
                          'border-b-[1px]': i !== 0,
                        },
                      )}
                    >
                      <div className="mr-2 flex w-full">
                        <span
                          style={{
                            backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                            backgroundSize: '100%',
                          }}
                          className={classNames(
                            'mr-2 grid w-[52.5px] place-items-center bg-[center_left] bg-no-repeat text-center text-[10px] uppercase md:w-[55px]',
                            {
                              'border-[1px]': !isImageExist,
                            },
                          )}
                        >
                          {!isImageExist && card.type}
                        </span>
                        <div className="flex flex-col text-[12px] md:text-[14px]">
                          <p className="w-[130px] capitalize md:w-[200px]">
                            <span className="pr-1 font-bold">
                              ●●●● ●●●● ●●●●
                            </span>
                            {card.cardNumber}
                          </p>

                          {card.isExpired ? (
                            <p className="text-[10px] text-red-600 md:text-[12px]">
                              Expired
                            </p>
                          ) : (
                            <p className="text-[10px] md:text-[12px]">
                              Expires on {card.expiryMonth}/{card.expiryYear}
                            </p>
                          )}
                        </div>
                        <div className="my-auto ml-auto">
                          {card?.isDefault ? (
                            <p className="cursor-default text-[14px] capitalize text-[#282828]/30 md:text-[16px]">
                              Default
                            </p>
                          ) : card?.isExpired ? (
                            <p className="cursor-default text-[14px] capitalize text-[#282828]/30 md:text-[16px]">
                              Invalid
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="relative flex h-auto  w-[20px] cursor-pointer flex-col items-center gap-[5px]">
                        <label
                          onClick={(e) => handleBurgerClick(e, i)}
                          className={classNames(
                            `modal-button flex w-[25px] cursor-pointer flex-col items-center gap-[5px]`,
                            {
                              hidden: card?.isDefault || card.isExpired,
                            },
                          )}
                        >
                          <span className="pointer-events-none h-[5px] w-[5px] rounded-full bg-flexi-black-3" />
                          <span className="pointer-events-none h-[5px] w-[5px] rounded-full bg-flexi-black-3" />
                          <span className="pointer-events-none h-[5px] w-[5px] rounded-full bg-flexi-black-3" />
                        </label>
                        <div
                          className={classNames(
                            `absolute right-[150%] top-0 hidden h-auto w-[150px] flex-col items-center overflow-hidden rounded-[10px_10px_10px_10px] bg-white shadow-[0_2px_9px] shadow-flexi-black-5`,
                            {
                              '!flex': burgerActive === i,
                            },
                          )}
                        >
                          <p
                            className={classNames(
                              'w-full p-[15px] text-center text-[16px] hover:bg-flexi-black-7 md:text-[16px]',
                              { hidden: card.isDefault || card.isExpired },
                            )}
                            onClick={() =>
                              handleUpdateBookingPaymentCard(bookingId, card.id)
                            }
                          >
                            {pendingCardChange && (
                              <AiOutlineLoading3Quarters className="my-auto ml-4 mr-2 inline-block h-[14px] w-[14px] animate-spin fill-flexi-orange-1" />
                            )}
                            Select Card
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </>
        ) : (
          <>
            <ErrorIcon className="icon" />
            <p className="title">Card Failed</p>
            <p className="text-center text-[18px]">
              {bookingSummary?.paymentDeclined?.message}
            </p>
            <p
              className="button btn capitalize"
              onClick={() => {
                dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
                setIsPaymentCardsShown(true);
              }}
            >
              Try other card
            </p>
            <button
              className="mt-[20px] cursor-pointer text-[14px] font-bold capitalize text-primary md:text-[16px]"
              onClick={() => push('/app/account/payment-method')}
            >
              Go to Payment Method
            </button>
          </>
        )}
      </Modal>

      <PaymentMethodModal
        isModalOpen={isAddCardModalOpen}
        setModalOpen={setAddCardModalOpen}
      />
    </article>
  );
};

export default BookingDetails;
