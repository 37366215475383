import React from 'react';

import PlacesAutocomplete from 'react-places-autocomplete';
import classNames from 'classnames';

import { ReactComponent as IconSearch } from 'assets/images/search2.svg';

interface Props {
  autoLocation: string;
  onChange: (e: any) => void;
  handleSelectAutocomplete: (e: any) => void;
  error: boolean;
  country: string;
}

function BusinessAddressAutoComplete({
  autoLocation,
  handleSelectAutocomplete,
  onChange,
  error,
  country,
}: Props) {
  return (
    <PlacesAutocomplete
      value={autoLocation}
      onChange={onChange}
      onSelect={handleSelectAutocomplete}
      searchOptions={{
        componentRestrictions: {
          country,
        },
      }}
      shouldFetchSuggestions
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative w-full">
          <span className="absolute right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-transparent">
            <IconSearch className=" fill-flexi-orange-1" />
          </span>
          <input
            {...getInputProps({
              placeholder: '',
            })}
            className={classNames(
              'location-search-input peer input input-bordered w-full truncate rounded-t-[0px] border-t-transparent pt-[10px] text-[14px]',
              {
                'border-red-500': error,
              },
            )}
          />
          <div className="autocomplete-dropdown-container scrollbar absolute z-[9999] max-h-[250px] w-[350px] overflow-y-auto text-[14px]">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={i}
                  className={classNames('border-[1px] border-t-0 p-[10px]', {
                    '!border-t-[1px]': i === 0,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default BusinessAddressAutoComplete;
