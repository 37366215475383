import React from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { ReactComponent as IconScan } from 'assets/images/scan.svg';
import { selectQrCode } from 'modules/ocr/slice';
import useAppSelector from 'hooks/useAppSelector';

interface Props {
  pending: boolean;
}

const VerificationContent = ({ pending }: Props) => {
  const qrCode = useAppSelector(selectQrCode);

  return (
    <div className="">
      <div className="flex flex-col gap-x-[30px] md:flex-row">
        <figure className="mx-auto mb-10 flex w-[200px] items-center justify-center border-[1px] border-[#DDD] p-7 md:mx-0">
          {pending ? (
            <AiOutlineLoading3Quarters className=" m-auto animate-spin fill-flexi-orange-1" />
          ) : (
            <div id="qr-code" dangerouslySetInnerHTML={{ __html: qrCode }} />
          )}
        </figure>
        <div className="flex w-full flex-col items-center md:w-[370px]">
          <p className="mb-[23px] text-center text-[14px] font-[400] md:text-[16px]">
            Use your mobile phone camera to scan the QR code to begin the
            identity{' '}
            <span className="whitespace-nowrap">verification process.</span>
          </p>
          <IconScan />
        </div>
      </div>
      <p className="mb-5 hidden text-[18px]">
        Or check your email for further instructions.
      </p>
    </div>
  );
};

export default VerificationContent;
