/* eslint-disable @typescript-eslint/no-unused-vars */
import { selectSelectedVehicleFilters } from './../vehicle/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import api, { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { getTimezone } from 'common/helpers';
import { call, put, select, cancelled } from 'redux-saga/effects';
import {
  selectShowOnlyAvailableVehicle,
  setInitialPending,
  setNearestPods,
  setNearestPodsStatus,
  setNearestPodsVehicles,
  setPendingNearestPods,
  setPendingPodsAll,
  setPendingVehiclesInPod,
  setPodOffset,
  setPodTimezone,
  setPodsAll,
  setPodsAllStatus,
  setVehiclesInPod,
  setVehiclesInPodStatus,
} from './slice';
import {
  IPod,
  IPodId,
  IPodVehicle,
  IPodVehicleBasic,
  IVehicleFilter,
  RequestStatusEnum,
} from 'common/types';
import { handleAPIError } from 'common/errorHandler';

export const SAGA_ACTIONS = {
  GET_NEAREST_PODS: 'GET_NEAREST_PODS',
  GET_NEAREST_PODS_PUBLIC: 'GET_NEAREST_PODS_PUBLIC',
  GET_PODS_ALL: 'GET_PODS_ALL',
  GET_PODS_VEHICLE: 'GET_PODS_VEHICLE',
};

type GetNearestPodsPayload = {
  latitude: number;
  longitude: number;
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
  startsAt?: string;
  endsAt?: string;
};

export function* getNearestPods(
  action: PayloadAction<GetNearestPodsPayload>,
): any {
  const api = authenticatedClient();
  const {
    latitude,
    longitude,
    minLatitude,
    maxLatitude,
    minLongitude,
    maxLongitude,
    startsAt,
    endsAt,
  } = action.payload;
  const vehicleFilters: IVehicleFilter[] | [] = yield select(
    selectSelectedVehicleFilters,
  );
  const vehicleTypeFilters = vehicleFilters.filter((i) => i.type === 'vehicle');
  const podTypeFilters = vehicleFilters.filter((i) => i.type === 'pod');
  const vehicleTypeFiltersSlugs = vehicleTypeFilters.map((i) => i.slug);
  const podTypeFiltersSlugs = podTypeFilters.map((i) => i.slug);
  const filterVehicleType =
    vehicleTypeFilters.length > 0
      ? { vehicle: vehicleTypeFiltersSlugs }
      : undefined;
  const filterPodType =
    podTypeFilters.length > 0 ? { pod: podTypeFiltersSlugs } : undefined;

  const filterPayload = {
    categories: {
      ...filterVehicleType,
      ...filterPodType,
    },
  };
  const payload = {
    latitude,
    longitude,
    minLatitude,
    maxLatitude,
    minLongitude,
    maxLongitude,
    startsAt,
    endsAt,
    timezone: getTimezone(),
    ...filterPayload,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    const showOnlyAvailableVehicle: boolean = yield select(
      selectShowOnlyAvailableVehicle,
    );

    yield put(setPendingNearestPods(true));
    yield put(setNearestPodsStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/pods/nearest`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setPodTimezone(data[0]?.timezone));
    yield put(setPodOffset(data[0]?.offSet));
    yield put(setNearestPodsStatus(RequestStatusEnum.SUCCESS));
    yield put(setInitialPending(true));

    if (showOnlyAvailableVehicle) {
      const filteredPods: IPod[] = data.filter((pod: IPod) => {
        if (pod.vehicles.length === 0) {
          return false;
        }
        if (pod.vehicles.length === 1) {
          if (pod.vehicles[0].availability.isAvailable) {
            return true;
          }
          return false;
        }
        if (pod.vehicles.length > 1) {
          if (
            pod.vehicles.every((vehicle) => !vehicle.availability.isAvailable)
          ) {
            return false;
          }
          return true;
        }
        return true;
      });

      const filteredPodsVehicles = filteredPods.map((pod) => {
        const filteredAvailableVehicles = pod.vehicles.filter(
          (vehicle) => vehicle.availability.isAvailable,
        );
        return { ...pod, vehicles: filteredAvailableVehicles };
      });

      yield put(setNearestPods(filteredPodsVehicles));
    } else {
      yield put(setNearestPods(data));
    }

    const nearestVehicles = data?.map((pod: IPod) => {
      const arrayMode = Array.isArray(pod.vehicles)
        ? pod.vehicles
        : Object.values(pod.vehicles);
      const vehicles = arrayMode.map((vehicle) => {
        return (
          typeof vehicle === 'object' && {
            ...vehicle,
            podName: pod.name,
            podId: pod.id,
            podIsFavorite: pod.podIsFavorite,
            latitude: pod.latitude,
            longitude: pod.longitude,
          }
        );
      });

      return vehicles;
    }) as any;

    const mergedVehicles = [].concat.apply(
      [],
      nearestVehicles,
    ) as IPodVehicle[];

    if (showOnlyAvailableVehicle) {
      const onlyAvailableVehicles = mergedVehicles.filter(
        (vehicle) => vehicle.availability.isAvailable,
      );

      yield put(setNearestPodsVehicles(onlyAvailableVehicles));
    } else {
      yield put(setNearestPodsVehicles(mergedVehicles));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setNearestPodsStatus(RequestStatusEnum.FAILED));
      yield put(setInitialPending(true));
    }
  } finally {
    yield put(setPendingNearestPods(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* getNearestPodsPublic(
  action: PayloadAction<GetNearestPodsPayload>,
): any {
  const {
    latitude,
    longitude,
    minLatitude,
    maxLatitude,
    minLongitude,
    maxLongitude,
    startsAt,
    endsAt,
  } = action.payload;
  const vehicleFilters: IVehicleFilter[] | [] = yield select(
    selectSelectedVehicleFilters,
  );
  const vehicleTypeFilters = vehicleFilters.filter((i) => i.type === 'vehicle');
  const podTypeFilters = vehicleFilters.filter((i) => i.type === 'pod');
  const vehicleTypeFiltersSlugs = vehicleTypeFilters.map((i) => i.slug);
  const podTypeFiltersSlugs = podTypeFilters.map((i) => i.slug);
  const filterVehicleType =
    vehicleTypeFilters.length > 0
      ? { vehicle: vehicleTypeFiltersSlugs }
      : undefined;
  const filterPodType =
    podTypeFilters.length > 0 ? { pod: podTypeFiltersSlugs } : undefined;

  const filterPayload = {
    categories: {
      ...filterVehicleType,
      ...filterPodType,
    },
  };
  const payload = {
    latitude,
    longitude,
    minLatitude,
    maxLatitude,
    minLongitude,
    maxLongitude,
    startsAt,
    endsAt,
    timezone: getTimezone(),
    ...filterPayload,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    const showOnlyAvailableVehicle: boolean = yield select(
      selectShowOnlyAvailableVehicle,
    );

    yield put(setPendingNearestPods(true));
    yield put(setNearestPodsStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/nearestPods`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setPodTimezone(data[0]?.timezone));
    yield put(setPodOffset(data[0]?.offSet));
    yield put(setNearestPodsStatus(RequestStatusEnum.SUCCESS));
    yield put(setInitialPending(true));

    if (showOnlyAvailableVehicle) {
      const filteredPods: IPod[] = data.filter((pod: IPod) => {
        if (pod.vehicles.length === 0) {
          return false;
        }
        if (pod.vehicles.length === 1) {
          if (pod.vehicles[0].availability.isAvailable) {
            return true;
          }
          return false;
        }
        if (pod.vehicles.length > 1) {
          if (
            pod.vehicles.every((vehicle) => !vehicle.availability.isAvailable)
          ) {
            return false;
          }
          return true;
        }
        return true;
      });

      const filteredPodsVehicles = filteredPods.map((pod) => {
        const filteredAvailableVehicles = pod.vehicles.filter(
          (vehicle) => vehicle.availability.isAvailable,
        );
        return { ...pod, vehicles: filteredAvailableVehicles };
      });

      yield put(setNearestPods(filteredPodsVehicles));
    } else {
      yield put(setNearestPods(data));
    }

    const nearestVehicles = data?.map((pod: IPod) => {
      const arrayMode = Array.isArray(pod.vehicles)
        ? pod.vehicles
        : Object.values(pod.vehicles);
      const vehicles = arrayMode.map((vehicle) => {
        return (
          typeof vehicle === 'object' && {
            ...vehicle,
            podName: pod.name,
            podId: pod.id,
            podIsFavorite: pod.podIsFavorite,
            latitude: pod.latitude,
            longitude: pod.longitude,
          }
        );
      });
      return vehicles;
    }) as any;

    const mergedVehicles = [].concat.apply(
      [],
      nearestVehicles,
    ) as IPodVehicle[];

    if (showOnlyAvailableVehicle) {
      const onlyAvailableVehicles = mergedVehicles.filter(
        (vehicle) => vehicle.availability.isAvailable,
      );
      yield put(setNearestPodsVehicles(onlyAvailableVehicles));
    } else {
      yield put(setNearestPodsVehicles(mergedVehicles));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setNearestPodsStatus(RequestStatusEnum.FAILED));
      yield put(setInitialPending(true));
    }
  } finally {
    yield put(setPendingNearestPods(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* getAllPods(): any {
  const payload = {};
  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setPendingPodsAll(true));
    yield put(setPodsAllStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/pods/v2/all`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setPodsAllStatus(RequestStatusEnum.SUCCESS));
    yield put(setInitialPending(true));

    yield put(setPodsAll(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPodsAllStatus(RequestStatusEnum.FAILED));
      yield put(setInitialPending(true));
    }
  } finally {
    yield put(setPendingPodsAll(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type GetPodsVehiclePayload = {
  pods: IPodId[];
};

export function* getPodsVehicle(
  action: PayloadAction<GetPodsVehiclePayload>,
): any {
  const { pods } = action.payload;
  const payload = {
    pods,
  };
  const cancelTokenSource = axios.CancelToken.source();
  const vehicleFilters: IVehicleFilter[] | [] = yield select(
    selectSelectedVehicleFilters,
  );
  const vehicleTypeFilters = vehicleFilters.filter((i) => i.type === 'vehicle');
  const podTypeFilters = vehicleFilters.filter((i) => i.type === 'pod');

  try {
    yield put(setPendingVehiclesInPod(true));
    yield put(setVehiclesInPodStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/pods/v2/vehicles-by-pods`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );

    const filteredData = data.filter((vehicle: IPodVehicleBasic) => {
      return vehicleTypeFilters.map((i) => i.id).includes(vehicle.category);
    });

    const filteredPetFriendly = filteredData.filter(
      (vehicle: IPodVehicleBasic) => {
        return vehicle.isPetFriendly === 1;
      },
    );

    const filteredMobility = filteredData.filter(
      (vehicle: IPodVehicleBasic) => {
        return vehicle.isMobility === 1;
      },
    );

    const filteredMobilityAndPetFriendly = filteredData.filter(
      (vehicle: IPodVehicleBasic) => {
        return vehicle.isMobility === 1 && vehicle.isPetFriendly === 1;
      },
    );

    yield put(setVehiclesInPodStatus(RequestStatusEnum.SUCCESS));
    yield put(setInitialPending(true));

    if(podTypeFilters.length === 1 && podTypeFilters[0].slug === 'pet-friendly'){
      yield put(setVehiclesInPod(filteredPetFriendly));
    } else if(podTypeFilters.length === 1 && podTypeFilters[0].slug === 'mobility'){
      yield put(setVehiclesInPod(filteredMobility));
    } else if(podTypeFilters.length === 2){
      yield put(setVehiclesInPod(filteredMobilityAndPetFriendly));
    } else{
      yield put(setVehiclesInPod(filteredData));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setVehiclesInPodStatus(RequestStatusEnum.FAILED));
      yield put(setInitialPending(true));
    }
  } finally {
    yield put(setPendingVehiclesInPod(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}
