import { selectSelectedVehicle, setBookingExpired } from './../vehicle/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import api, { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import {
  getToken,
  removeToken,
  setToken,
  removeResetPasswordEmail,
  setResetPasswordEmail,
  setOtpSent,
  removeOtpSent,
  removeDeviceId,
  getLoginRedirect,
  removeLoginRedirect,
  setResetPasswordContact,
  removeResetPasswordContact,
  removeIsNotified,
  removeIsCourseDueNotified,
  removeIsIdVerificationNotified,
} from 'common/helpers';
import { push } from 'connected-react-router';
import { call, put, select, cancelled } from 'redux-saga/effects';
import { fetchMe } from 'modules/me/sagas';
import {
  reset as resetAuth,
  selectError,
  setEmailVerified,
  setError,
  setLoginError,
  setLicenseVerified,
  setOtpExpiry,
  setPending,
  setPendingLogout,
  setPendingOtp,
  setPendingVerifyEmail,
  setPendingVerifyLicense,
  setPendingOtpExpiry,
  setOtpError,
  setAbrLookup,
  setPendingAbr,
  setAbrLookupStatus,
  setTokenInfo,
  setDecryptToken,
  setVerifyOtpStatus,
  setRegisterStatus,
  setForOnboarding,
  setDecryptTokenError,
  setDecryptTokenStatus,
  setBusinessNameVerified,
  setPendingVerifyBusinessName,
  setPlanTypes,
  setUniversities,
  setStudentEmailUniversityId,
  setSignUpLicense,
} from './slice';
import { reset as resetMe, setFirstLoginStatus, setUserStatus, selectUser } from 'modules/me/slice';
import { reset as resetOcr } from 'modules/ocr/slice';
import { reset as resetTransaction } from 'modules/transaction/slice';
import { reset as resetBooking } from 'modules/booking/slice';
import { reset as resetPods } from 'modules/pods/slice';
import { toast } from 'react-toastify';
import { RequestStatusEnum } from 'common/types';

export const SAGA_ACTIONS = {
  LOGIN_ACCOUNT: 'LOGIN_ACCOUNT',
  LOGOUT_ACCOUNT: 'LOGOUT_ACCOUNT',
  LOGOUT_ACCOUNT_APILESS: 'LOGOUT_ACCOUNT_APILESS',
  REGISTER_ACCOUNT: 'REGISTER_ACCOUNT',
  REGISTER_BUSINESS_ACCOUNT: 'REGISTER_BUSINESS_ACCOUNT',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SMS: 'FORGOT_PASSWORD_SMS',
  RESET_PASSWORD_OTP: 'RESET_PASSWORD_OTP',
  RESET_PASSWORD_OTP_V2: 'RESET_PASSWORD_OTP_V2',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_V2: 'RESET_PASSWORD_V2',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  VERIFY_BUSINESS_NAME: 'VERIFY_BUSINESS_NAME',
  VERIFY_LICENSE: 'VERIFY_LICENSE',
  SEND_OTP: 'SEND_OTP',
  VERIFY_OTP: 'VERIFY_OTP',
  VERIFY_OTP_ADD_ACCOUNT: 'VERIFY_OTP_ADD_ACCOUNT',
  FETCH_OTP_EXPIRY: 'FETCH_OTP_EXPIRY',
  GET_ABR_LOOKUP: 'GET_ABR_LOOKUP',
  CHECK_TOKEN_INFO: 'CHECK_TOKEN_INFO',
  GET_RESET_OTP: 'GET_RESET_OTP',
  GET_DECRYPT_TOKEN: 'GET_DECRYPT_TOKEN',
  REGISTER_ACCOUNT_V2: 'REGISTER_ACCOUNT_V2',
  VERIFY_OTP_V2: 'VERIFY_OTP_V2',
  VERIFY_LICENSE_V2: 'VERIFY_LICENSE_V2',
  REGISTER_PLAN_TYPE_V2: 'REGISTER_PLAN_TYPE_V2',
  REGISTER_PLAN_TYPE_SELECTED_V2: 'REGISTER_PLAN_TYPE_SELECTED_V2',
  REGISTER_PLAN_SELECTED_V2: 'REGISTER_PLAN_SELECTED_V2',
  GET_UNIVERSITIES_V2: 'GET_UNIVERSITIES_V2',
  VERIFY_EMAIL_V2: 'VERIFY_EMAIL_V2',
  PLAN_RESET: 'PLAN_RESET',
  REGISTER_BUSINESS_ACCOUNT_V2: 'REGISTER_BUSINESS_ACCOUNT_V2',
  REGISTER_BUSINESS_DRIVER_ACCOUNT_V2: 'REGISTER_BUSINESS_DRIVER_ACCOUNT_V2',
  VERIFY_ADDRESS: 'VERIFY_ADDRESS',
};

type LoginAccountData = {
  isOcrVerified: boolean;
  ocrTransactionUrl: string;
  redirectTo: string;
  token: string;
  businessName: string;
  forOnboarding: boolean;
  role: number;
  registrationVersion: number;
  redirectToV2: string;
};

type LoginAccountResponse = {
  data: LoginAccountData;
};

type LoginAccountPayload = {
  email: string;
  password: string;
  device_name: string;
  device_id: string;
  device_type: string;
};

export function* loginAccount(action: PayloadAction<LoginAccountPayload>) {
  const loginRedirect = getLoginRedirect() || null;
  try {
    yield put(setPending(true));
    yield put(setLoginError(null));
    const { data }: LoginAccountResponse = yield call(() =>
      api.post(`/auth/login`, action.payload),
    );
    setBookingExpired(false);
    if (data.forOnboarding) {
      yield put(setForOnboarding(true))
    }
    else {
      setToken(data.token);
      yield call(fetchMe);
    }
    yield put(setFirstLoginStatus(null));
    const selectedVehicle: number | null = yield select(selectSelectedVehicle);

    if (loginRedirect) {
      yield put(push(loginRedirect));
    }
    if (selectedVehicle) {
      return;
    }
    if (data.registrationVersion === 2) {
      if (data.role === 2) {
        switch (data.redirectToV2) {
          case 'declined-v2':
            yield put(push('/register-v2/verify-failed?reload=true'));
            break;
          case 'otp-v2':
            yield put(push('/register-business/otp?reload=true'));
            break;
          case 'plan-v2':
            yield put(push('/register-business/plan?reload=true'));
            break;
          case 'payment-v2':
            yield put(push('/register-v2/payment?reload=true'));
            break;
          case 'done-v2':
            yield put(push('/register-v2/done?reload=true'));
            break;
          case 'app':
            yield put(push('/app/booking?reload=true'));
            break;
          default:
            break;
        }
      } else {
        switch (data.redirectToV2) {
          case 'declined-v2':
            yield put(push('/register-v2/verify-failed?reload=true'));
            break;
          case 'otp-v2':
            yield put(push('/register-v2/otp?reload=true'));
            break;
          case 'ocr-verification-v2':
            yield put(push('/register-v2/ocr?reload=true'));
            break;
          case 'app':
            yield put(push('/app/booking?reload=true'));
            break;
          case 'declined':
            yield put(push('/register-v2/verify-failed?reload=true'));
            break;
          case 'address-verification-v2':
            yield put(push('/register-v2/address-verification?reload=true'));
            break;
          case 'plan-v2':
            yield put(push('/register-v2/plan?reload=true'));
            break;
          case 'payment-v2':
            yield put(push('/register-v2/payment?reload=true'));
            break;
          case 'done-v2':
            yield put(push('/register-v2/done?reload=true'));
            break;
          case 'add-card-onboarding':
            yield put(push('/app/add-payment-method?reload=true'));
            break;
          default:
            break;
        }
      }
    } else {
      switch (data.redirectTo) {
        case 'register-personal':
          yield put(push('/register/create-account?reload=true'));
          break;
        case 'otp':
          yield put(push('/register/otp?reload=true'));
          break;
        case 'verification':
          yield put(push('/register/verification?reload=true'));
          break;
        case 'information':
          yield put(push('/register/information?reload=true'));
          break;
        case 'plan':
          yield put(push('/register/plan?reload=true'));
          break;
        case 'payment':
          yield put(push('/register/payment?reload=true'));
          break;
        case 'done':
          yield put(push('/register/done?reload=true'));
          break;
        case 'app':
          yield put(push('/app/booking?reload=true'));
          break;
        case 'declined':
          yield put(push('/register/declined?reload=true'));
          break;
        case 'add-card-onboarding':
          yield put(push('/app/add-payment-method?reload=true'));
          break;
        default:
          break;
      }
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      yield put(setLoginError(err.response.data?.errorCode?.message || err.response.data?.errors || err.response.data?.error?.message));
    }
  } finally {
    yield put(setPending(false));
removeLoginRedirect();
  }
}

export function* logoutAccount(
  action: PayloadAction<{ push?: string }>,
): any {
  const api = authenticatedClient();
  try {
    yield put(setPendingLogout(true));
    yield call(() => api.post(`/auth/logout`, { token: getToken() }));
    removeDeviceId();
    removeToken();
    removeIsNotified();
    removeIsCourseDueNotified();
    removeIsIdVerificationNotified();
    yield put(resetMe());
    yield put(resetOcr());
    yield put(resetTransaction());
    yield put(resetAuth());
    yield put(resetBooking());
    yield put(resetPods());
    yield put(setFirstLoginStatus(null));
    if (action.payload.push) {
      window.location.href = action.payload.push;
    } else {
      window.location.href = '/logout';
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      removeToken();
      removeDeviceId();
      removeIsNotified();
      removeIsCourseDueNotified();
      removeIsIdVerificationNotified();
      yield put(resetMe());
      yield put(resetOcr());
      yield put(resetTransaction());
      yield put(resetAuth());
      yield put(resetBooking());
      yield put(resetPods());
      handleAPIError(err.response);
      window.location.href = '/logout';
    }
  } finally {
    yield put(setPendingLogout(false));
  }
}

export function* logoutAccountApiless() {
  try {
    removeToken();
    removeDeviceId();
    removeIsNotified();
    removeIsCourseDueNotified();
    removeIsIdVerificationNotified();
    yield put(push({
      pathname: '/',
      search: '?reload=true'
    }));
    yield put(resetMe());
    yield put(resetOcr());
    yield put(resetTransaction());
    yield put(resetAuth());
    yield put(resetBooking());
    yield put(resetPods());
    yield put(setFirstLoginStatus(null));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      removeToken();
      removeDeviceId();
      removeIsNotified();
      removeIsCourseDueNotified();
      removeIsIdVerificationNotified();
      yield put(push({
        pathname: '/',
        search: '?reload=true'
      }));
      yield put(resetMe());
      yield put(resetOcr());
      yield put(resetTransaction());
      yield put(resetAuth());
      yield put(resetBooking());
      yield put(resetPods());
      handleAPIError(err.response);
    }
  }
}

type RegisterAccountPayload = {
  type: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  country: string;
  isMailSubscriber: number;
  contactCountry: string;
  device_name: string;
  device_id: string;
  device_type: string;
};

export function* registerAccount(action: PayloadAction<RegisterAccountPayload>): any {
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.post(`/register`, action.payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    setToken(data.token);
    yield put(push('/register/otp'));
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type RegisterBusinessAccountPayload = {
  type: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  country: string;
  isMailSubscriber: number;
  businessCode: string;
  businessName: string;
  businessAddress: string;
  businessCity: string;
  businessPostcode: number;
  businessCountry: string;
  businessState: string;
  businessBillingAddress?: string;
  businessBillingCity?: string;
  businessBillingPostcode?: number;
  businessBillingCountry?: string;
  businessBillingState?: string;
  isSameBilling?: number;
  phoneCountry: string;
  device_name: string;
  device_id: string;
  device_type: string;
};

export function* registerBusinessAccount(
  action: PayloadAction<RegisterBusinessAccountPayload>,
): any {
  const error = yield select(selectError);
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.post(`/register`, action.payload));
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    setToken(data.token);
    yield put(push('/register/otp'));
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  }
}

export function* verifyEmail(
  action: PayloadAction<{ email: string; type: number }>,
): any {
  const error = yield select(selectError);
  try {
    yield put(setEmailVerified(false));
    yield put(setPendingVerifyEmail(true));
    yield call(() => api.post(`/auth/check-email`, action.payload));
    yield put(setError(null));
    yield put(setEmailVerified(true));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    yield put(setPendingVerifyEmail(false));
  }
}

export function* verifyBusinessName(
  action: PayloadAction<{ businessName: string; type: number }>,
): any {
  const error = yield select(selectError);
  try {
    yield put(setBusinessNameVerified(false));
    yield put(setPendingVerifyBusinessName(true));
    yield call(() => api.post(`/auth/check-business-name`, action.payload));
    yield put(setError(null));
    yield put(setBusinessNameVerified(true));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      if (err.response.data) {
        yield put(
          setError({ ...error, businessName: err.response.data?.error.message }),
        );
      }
    }
  } finally {
    yield put(setPendingVerifyBusinessName(false));
  }
}

export function* verifyLicense(
  action: PayloadAction<{ licenseNumber: string }>,
): any {
  const error = yield select(selectError);
  try {
    yield put(setLicenseVerified(false));
    yield put(setPendingVerifyLicense(true));
    yield call(() => api.post(`/auth/check-license`, action.payload));
    yield put(setError(null));
    yield put(setLicenseVerified(true));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      if (err.response.data) {
        yield put(setError({ ...error, license: err.response.data?.message }));
      }
    }
  } finally {
    yield put(setPendingVerifyLicense(false));
  }
}

export function* sendOtp() {
  const api = authenticatedClient();
  try {
    yield put(setPendingOtp(true));
    yield call(() => api.post(`/otp/send`));
    toast.success('New OTP code has been sent.');
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingOtp(false));
  }
}

export function* fetchOtpExpiry() {
  const api = authenticatedClient();
  try {
    yield put(setPendingOtpExpiry(true));
    const { data } = yield call(() => api.get('/otp/info'));
    yield put(setOtpExpiry(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingOtpExpiry(false));
  }
}

type VerifyOtpPayload = {
  code: number;
  id: number;
};

export function* verifyOtp(action: PayloadAction<VerifyOtpPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setPendingOtp(true));
    yield call(() => api.post(`/otp/verify/web`, action.payload));
    yield call(fetchMe);
    yield put(push('/register/verification'));
    yield put(setOtpError(false));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setOtpError(true));
    }
  } finally {
    yield put(setPendingOtp(false));
  }
}

export function* verifyOtpAddAccount(action: PayloadAction<VerifyOtpPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setVerifyOtpStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/otp/verify/web`, action.payload));
    yield put(setOtpError(false));
    yield call(fetchMe);
    yield put(setVerifyOtpStatus(RequestStatusEnum.SUCCESS));
    // yield put(push('/app/add-account/payment'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setOtpError(true));
      yield put(setVerifyOtpStatus(RequestStatusEnum.FAILED));
    }
  }
}

type ForgotPasswordPayload = {
  email: string;
};

export function* forgotPassword(action: PayloadAction<ForgotPasswordPayload>) {
  try {
    yield put(setUserStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/auth/forgot-password`, action.payload));
    // yield call(() => api.get(`/auth/get-reset-otp`,{ params:{ email: action.payload.email } }));
    yield put(push('/auth/forgot-password-check-mail'));
    yield put(setUserStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      toast.error(err.response.status);
      yield put(setUserStatus(RequestStatusEnum.FAILED));
    }
  }
}

type ForgotPasswordSmsPayload = {
  contact: string;
  retries: number;
};

export function* forgotPasswordSms(action: PayloadAction<ForgotPasswordSmsPayload>) {
  try {
    yield put(setError(null));
    yield put(setUserStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/auth/reset-otp-sms`, action.payload ));
    yield put(push('/auth-v2/forgot-password-otp'));
    yield put(setUserStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setError(err.response));
      yield put(setUserStatus(RequestStatusEnum.FAILED));
    }
  } 
}


type GetResetOtpPayload = {
  token: string;
};

export function* getResetOtp(action: PayloadAction<GetResetOtpPayload>) {
  try {
    yield put(setUserStatus(RequestStatusEnum.PENDING));
    yield call(() => api.get(`/auth/get-reset-otp`, { params: { token: action.payload.token } }));
    // yield call(() => api.get(`/auth/get-reset-otp`,{ params:{ token: action.payload.token} });
    yield put(setUserStatus(RequestStatusEnum.SUCCESS));
    setOtpSent('true')
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setUserStatus(RequestStatusEnum.FAILED));
    }
  }
}

type ResetPasswordOtpPayload = {
  email: string;
  code: string;
};

export function* resetPasswordOtp(
  action: PayloadAction<ResetPasswordOtpPayload>,
) {
  try {
    yield put(setPendingOtp(true));
    yield call(() => api.post(`/auth/reset-password/otp`, action.payload));
    // window.localStorage.setItem('reset-email', email);
    setResetPasswordEmail(action.payload.email);

    yield put(push('/auth/forgot-password-otp-success'));
    yield put(setOtpError(false));
    removeOtpSent();
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setOtpError(true));
    }
  } finally {
    yield put(setPendingOtp(false));
  }
}

type ResetPasswordOtpV2Payload = {
  contact: string;
  code: string;
};

export function* resetPasswordOtpV2(
  action: PayloadAction<ResetPasswordOtpV2Payload>,
) {
  try {
    yield put(setPendingOtp(true));
    yield call(() => api.post(`/auth/reset-password/otp`, action.payload));
    setResetPasswordContact(action.payload.contact);
    setOtpSent(action.payload.code);
    yield put(push('/auth-v2/forgot-password-otp-success'));
    yield put(setOtpError(false));
      yield put(setError(null));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setOtpError(true));
      yield put(setError(err.response))
    }
  } finally {
    yield put(setPendingOtp(false));
  }
}

type ResetPasswordPayload = {
  code: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

export function* resetPassword(action: PayloadAction<ResetPasswordPayload>) {
  try {
    yield put(setPending(true));
    yield call(() => api.post(`/auth/reset-password`, action.payload));
    yield put(push('/auth/forgot-password-confirm-success'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
    removeResetPasswordEmail();
    removeResetPasswordContact();
    removeOtpSent();
  }
}

type ResetPasswordV2Payload = {
  code: string;
  contact: string;
  password: string;
  passwordConfirmation: string;
};

export function* resetPasswordV2(action: PayloadAction<ResetPasswordV2Payload>) {
  try {
    yield put(setPending(true));
    yield call(() => api.post(`/auth/reset-password`, action.payload));
    yield put(push('/auth-v2/forgot-password-confirm-success'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
    removeResetPasswordContact();
    removeOtpSent();
  }
}

type GetAbrLookupPayload = {
  code: string;
};

export function* getAbrLookup(action: PayloadAction<GetAbrLookupPayload>) {
  try {
    yield put(setPendingAbr(true));
    yield put(setAbrLookupStatus(null));
    const { data } = yield call(() =>
      api.get(`/abr/search?code=${action.payload.code}`),
    );
    yield put(setAbrLookup(data));
    yield put(setAbrLookupStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setAbrLookup(null));
      yield put(setAbrLookupStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingAbr(false));
  }
}

type CheckTokenInfoPayload = {
  token: string;
  type: string;
};

export function* checkTokenInfo(action: PayloadAction<CheckTokenInfoPayload>) {
  try {
    const { data } = yield call(() =>
      api.post('/auth/check-token', action.payload),
    );
    yield put(setTokenInfo(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

type DecryptTokenPayload = {
  token: string;
};

export function* getDecryptToken(action: PayloadAction<DecryptTokenPayload>) {
  try {
    yield put(setDecryptTokenStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.get('/auth/decrypt-token', { params: action.payload }),
    );
    yield put(setDecryptTokenStatus(RequestStatusEnum.SUCCESS));
    yield put(setDecryptToken(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setDecryptTokenError(err.response.data))
      yield put(setDecryptTokenStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}

type RegisterAccountV2Payload = {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  contactCountry: string;
  country: string;
  licenseNumber: string;
  licenseCardNumber?: string;
  isMailSubscriber: boolean;
};

export function* registerAccountV2(action: PayloadAction<RegisterAccountV2Payload>): any {
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.post(`/registerV2`, action.payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    setToken(data.token);
    yield put(push('/register-v2/otp'));
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type VerifyOtpV2Payload = {
  code: number;
  planType: string;
};

export function* verifyOtpV2(action: PayloadAction<VerifyOtpV2Payload>): any {
  const api = authenticatedClient();
  const payload = { code: action.payload.code };
  const user = yield select(selectUser);
  const isBusinessManagerAccount =
    user?.type.name.toLocaleLowerCase().includes('business') &&
    user?.role === 'manager';
  try {
    yield put(setPendingOtp(true));
    yield call(() => api.post(`/otpV2/verify/web`, payload));
    yield call(fetchMe);
    if (action.payload.planType === 'business' && isBusinessManagerAccount) {
      yield put(push('/register-business/plan'));
    } else {
      yield put(push('/register-v2/otp-verified'));
    }
    yield put(setOtpError(false));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setOtpError(true));
    }
  } finally {
    yield put(setPendingOtp(false));
  }
}

type VerifyLicenseV2Payload = {
  country: string;
  licenseNumber: string;
};

export function* verifyLicenseV2(action: PayloadAction<VerifyLicenseV2Payload>): any {
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const data = yield call(() => api.post(`/auth/check-licenseV2`, action.payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    if (data.data.success === 'license_verified') {
      yield put(setSignUpLicense(action.payload))
      yield put(push(`/register-v2/form`));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errorCode) {
        yield put(
          setError({
            ...error,
            licenseNumber: err.response.data?.errorCode.message,
          }),
        );
      }
      if (err.response.data?.error) {
        yield put(
          setError({
            ...error,
            error: err.response.data?.error.message,
          }),
        );
      }
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* registerPlanTypeV2() {
  const api = authenticatedClient();
  const error = select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.get(`/registerV2/plan-type`, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setPlanTypes(data));
    yield put(setPending(false));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPending(false));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type RegisterPlanTypeSelectedV2Payload = {
  typeId: number;
  campus: string;
  courseEndDate: string;
  universityEmail: string;
  universityId: number;
};

export function* registerPlanTypeSelectedV2(action: PayloadAction<RegisterPlanTypeSelectedV2Payload>): any {
  const api = authenticatedClient();
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  // const plans = yield select(selectPlans);
  const personalPlanTypePayload = {
    typeId: action.payload.typeId,
  };

  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    if (action.payload.universityEmail === undefined) {
      yield call(() =>
        api.post(`/registerV2/plan-type`, personalPlanTypePayload, {
          cancelToken: cancelTokenSource.token,
        }),
      );
      yield put(push(`/register-v2/plan-personal`));
    } else {
      try {
        yield call(() => api.post(`/registerV2/plan-type`, action.payload, {
          cancelToken: cancelTokenSource.token,
        }));
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          handleAPIError(err.response);
          yield put(setRegisterStatus(RequestStatusEnum.FAILED));
          if (err.response.data?.errors) {
            yield put(setError({ ...error, ...err.response.data?.errors }));
          }
        }
      } finally {
        const { data } = yield call(() =>
          api.get(`/registerV2/plans`, { params: action.payload }),
        );
        yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
        yield call(fetchMe);
        yield call(() =>
          api.post(
            `/registerV2/plan`,
            { planId: data[0]?.id  },
            {
              cancelToken: cancelTokenSource.token,
            },
          ),
        );
        yield put(push(`/register-v2/payment`));
      }
    }
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type RegisterPlanSelectedV2Payload = {
  planId: number;
};

export function* registerPlanSelectedV2(action: PayloadAction<RegisterPlanSelectedV2Payload>): any {
  const api = authenticatedClient();
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/registerV2/plan`, action.payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    yield put(push(`/register-v2/payment`));
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* getUniversitiesV2() {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.get(`/registerV2/universities`));
    yield put(setUniversities(data.universities));
    yield put(setPending(false));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPending(false));
    }
  }
}

export function* verifyEmailV2(
  action: PayloadAction<{ email: string; typeId: number }>,
): any {
  const error = yield select(selectError);
  try {
    yield put(setEmailVerified(false));
    yield put(setPendingVerifyEmail(true));
    const { data } = yield call(() => api.post(`/registerV2/check-email`, action.payload));
    yield put(setError(null));
    yield put(setEmailVerified(true));
    yield put(setStudentEmailUniversityId(data.universityId));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    yield put(setPendingVerifyEmail(false));
  }
}

export function* planReset() {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    yield call(() => api.post(`/registerV2/plan/reset`));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPending(false));
    }
  } finally {
    yield put(setPending(false));
  }
}


type RegisterBusinessAccountV2Payload = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  contactCountry: string;
  businessCode: string;
  businessName: string;
  businessAddress: string;
  businessCountry: string;
  businessBillingAddress: string;
  businessBillingCountry: string;
  isSameBilling: number;
  isMailSubscriber: boolean;
};

export function* registerBusinessAccountV2(
  action: PayloadAction<RegisterBusinessAccountV2Payload>,
): any {
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.post(`/registerV2/manager`, action.payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    setToken(data.token);
    yield put(push('/register-business/otp'));
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type RegisterBusinessDriverAccountV2Payload = {
  token: string;
  preferredName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  contact: string;
  contactCountry: string;
  permanentAddress: string;
  permanentAddressCountry: string;
  localAddress: string;
  localAddressCountry: string;
  isMailSubscriber: boolean;
  vffMembershipId: string;
};

export function* registerBusinessDriverAccountV2(
  action: PayloadAction<RegisterBusinessDriverAccountV2Payload>,
): any {
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.post(`/registerV2/business-driver`, action.payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    setToken(data.token);
    yield put(push('/register-v2/otp'));
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type VerifyAddressPayload = {
  address: string;
  country: string;
  postCode: string;
};

export function* verifyAddress(
  action: PayloadAction<VerifyAddressPayload>,
): any {
  const api = authenticatedClient();
  const error = yield select(selectError);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setRegisterStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.post(`/registerV2/verify-address`, action.payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setRegisterStatus(RequestStatusEnum.SUCCESS));
    if (data.redirectTo === 'done-v2') {
      yield put(push('/register-v2/done'));
    } else  if (data.redirectTo === 'payment-v2') {
      yield put(push('/register-v2/payment'));
    } else {
      yield put(push('/register-v2/plan'));
    }
    yield put(setError(null));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRegisterStatus(RequestStatusEnum.FAILED));
      if (err.response.data?.errors) {
        yield put(setError({ ...error, ...err.response.data?.errors }));
      }
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}