import React from 'react';

import checkIcon from 'assets/images/check.svg';
import { IMAGE_URL } from 'common/constants';

const Guidelines = () => {
  return (
    <div>
      <div className="">
        <p className="mb-[16px] text-[14px] font-bold">
          To join Flexicar you will need to:
        </p>
        <ul className="mb-[20px] flex flex-col gap-y-[15px] text-[14px] text-sm">
          <li className="flex translate-x-[-10px] items-center gap-x-[15px]">
            <span
              style={{
                maskImage: `url(${IMAGE_URL}${checkIcon})`,
                WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
                maskRepeat: 'no-repeat',
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskPosition: 'center right',
              }}
              className="h-[25px] w-[25px] bg-flexi-orange-1"
            />
            <p className="max-w-[70vw]">Be over 18 years old</p>
          </li>
          <li className="flex translate-x-[-10px] items-center gap-x-[15px]">
            <span
              style={{
                maskImage: `url(${IMAGE_URL}${checkIcon})`,
                WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
                maskRepeat: 'no-repeat',
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskPosition: 'center right',
              }}
              className="h-[25px] w-[25px] bg-flexi-orange-1"
            />
            <p className="max-w-[70vw]">
              Have valid probationary (excluding learners) license or full
              drivers license.
            </p>
          </li>
        </ul>

        <p className="mb-[16px] text-[14px] font-bold">
          To successfully complete the verification process make sure you:
        </p>
        <ul className="mb-[40px] flex flex-col gap-y-[15px] text-[14px]">
          <li className="flex translate-x-[-10px] items-center gap-x-[15px]">
            <span
              style={{
                maskImage: `url(${IMAGE_URL}${checkIcon})`,
                WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
                maskRepeat: 'no-repeat',
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskPosition: 'center right',
              }}
              className="h-[25px] w-[25px] bg-flexi-orange-1"
            />
            <p className="max-w-[70vw]">Have your drivers license on hand</p>
          </li>
          <li className="flex translate-x-[-10px] items-center gap-x-[15px]">
            <span
              style={{
                maskImage: `url(${IMAGE_URL}${checkIcon})`,
                WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
                maskRepeat: 'no-repeat',
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskPosition: 'center right',
              }}
              className="h-[25px] w-[25px] bg-flexi-orange-1"
            />
            <p className="max-w-[70vw]">Are in a well lit place</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Guidelines;
