import Modal from 'components/Modal';
import React from 'react';
import { ReactComponent as IconWing } from 'assets/images/wing_big.svg';
import { useMediaQuery } from 'react-responsive';

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
}

function ModalPendingAccount({ isModalOpen, handleClose }: Props) {

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  return (
    <Modal isModalOpen={isModalOpen} handleClose={handleClose} px={isMobile ? 'px-[25px]' : ''}
    mx={isMobile ? '30px' : ''}>
      <IconWing className="icon mb-[50px] h-[120px] w-[120px] self-center" />
      <h1 className="title text-center text-[20px] md:text-[34px] font-bold">
        Pending Account
      </h1>
      <p className="mt-[20px] mb-[40px] text-center text-[14px] md:text-[18px]">
        Your Flexicar membership is pending approval and you are unable to book a vehicle yet.<br/><br/>
        You will be notified by email when our team has finished reviewing your application.
      </p>
      <button
        className="h-[50px] md:h-[60px] w-full rounded-full bg-flexi-green-1 font-bold text-white"
        onClick={handleClose}
      >
        Ok
      </button>
    </Modal>
  );
}

export default ModalPendingAccount;
