import React, { Dispatch, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import haversine from 'haversine-distance';
import classNames from 'classnames';
import * as _ from 'lodash';
import moment from 'moment';

import Iconhuman from 'assets/images/human.svg';
import vehiclePlaceholder from 'assets/images/vehicle-placeholder.svg';
import { DEFAULT_LNG_LAT, IMAGE_URL } from 'common/constants';
import { getToken } from 'common/helpers';
import { IPodAll, IPodVehicleBasic, RequestStatusEnum } from 'common/types';
import { setSelectedPod2, setSelectedPodVehicle2 } from 'modules/pods/slice';
import { setLoginModalOpen } from 'modules/auth/slice';
import { selectUser } from 'modules/me/slice';
import {
  selectSelectedLocationCenter,
  selectUserCenter,
  setMapView,
} from 'modules/booking/slice';
import {
  selectVehicleAvailabilityStatus,
  selectVehicleCategoryRates,
  setBookingTimer,
  setSelectedVehicle,
} from 'modules/vehicle/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import useFavorites from 'hooks/useFavorites';
import FavoriteButton from 'components/FavoriteButton';
import PerVehicleTimeline from '../BookingTimeline/PerVehicleTimelineV2';
import { useRedirectToSignUp } from 'hooks/useRedirect';

interface MyComponentProps {
  vehicle: IPodVehicleBasic;
}

interface Props {
  sortedVehicles: IPodVehicleBasic[];
  timeZone: string;
  viewTimeline: boolean;
  setIsPendingAccount: Dispatch<boolean>;
}

const LazyVehicleList = ({
  sortedVehicles,
  timeZone,
  viewTimeline,
}: Props) => {
  const {
    selectedPodVehicle2,
    vehiclesInPod,
    vehiclesAvailability,
    podsAll,
    startDate,
    endDate,
    showOnlyAvailableVehicle,
    isUserUnapproved,
    computedRateV2,
    isMapView,
    pendingAvailabilityList,
  } = useBooking({});
  const { favorites } = useFavorites();
  const dispatch = useAppDispatch();
  const rawCategoryRates = useAppSelector(selectVehicleCategoryRates);
  const userCenter = useAppSelector(selectUserCenter) || DEFAULT_LNG_LAT;
  const selectedLocationCenter = useAppSelector(selectSelectedLocationCenter);
  const vehicleAvailabilityStatus = useAppSelector(
    selectVehicleAvailabilityStatus,
  );
  const user = useAppSelector(selectUser);
  const token = getToken();
  const selectedPodVehicleId2 = selectedPodVehicle2?.id;
  const [firstVehicleOnList, setFirstVehicleOnList] = useState<number>(0);
  const vehicleListRef = useRef<HTMLUListElement>(null);
  const params = new URLSearchParams(location.search);
  const { push } = useHistory();

  const handleVehicleListOnScroll = (e: any) => {
    const parent = e.target.getBoundingClientRect().top;
    const children = e.target.children;
    _.map(children, (child: any) => {
      if (
        parent - child.getBoundingClientRect().top >= 0 &&
        parent - child.getBoundingClientRect().top <= 10
      ) {
        setFirstVehicleOnList(child.id);
      }
    });
  };

  const handleBooking = (id: number) => {
    isUserUnapproved();
    if (!token) {
      if (window.location.pathname === '/map') {
        window.parent.location.href = '/register-v2';
      } else {
        dispatch(setLoginModalOpen(true));
        dispatch(setBookingTimer(null));
      }
    } else {
      dispatch(setSelectedVehicle(id));
      dispatch(setBookingTimer(new Date()));
      if (user?.status === 'approved' || user?.status === 'suspended') {
        dispatch(setSelectedVehicle(id));
        dispatch(setBookingTimer(new Date()));
        if (params.get('id'))
          push({
            pathname: '/summary',
            search: `id=${params.get('id')}`,
          });
        else {
          push('/app/booking/summary');
        }
      } else {
        useRedirectToSignUp();
      }
    }
  };

  const isAvailable = (vehicle: IPodVehicleBasic) => {
    if (
      vehiclesAvailability !== null &&
      vehicleAvailabilityStatus === RequestStatusEnum.SUCCESS
    ) {
      const pod = podsAll?.find((pod: IPodAll) => pod.id === vehicle.podId);
      const startDay = moment(startDate).format('dddd');
      const startTime = moment(startDate).format('HH:mm:ss');
      const endDay = moment(endDate).format('dddd');
      const endTime = moment(endDate).format('HH:mm:ss');

      if (pod !== undefined) {
        for (let i = 0, podStatus = true; i <= 6 && podStatus === true; i++) {
          if (
            startDay.toLowerCase() === pod.operations[i].day &&
            pod.operations[i].isClosed === 1
          ) {
            podStatus = false;
            return false;
          } else if (
            endDay.toLowerCase() === pod.operations[i].day &&
            pod.operations[i].isClosed === 1
          ) {
            podStatus = false;
            return false;
          } else if (
            startDay.toLowerCase() === pod.operations[i].day &&
            startTime > pod.operations[i].opensAt &&
            endTime >= pod.operations[i].closesAt
          ) {
            podStatus = false;
            return false;
          } else if (
            endDay.toLowerCase() === pod.operations[i].day &&
            startTime < pod.operations[i].opensAt &&
            endTime <= pod.operations[i].closesAt
          ) {
            podStatus = false;
            return false;
          } else if (
            startDay.toLowerCase() === pod.operations[i].day &&
            startTime < pod.operations[i].opensAt &&
            startTime.toString() !== '00:00:00'
          ) {
            podStatus = false;
            return false;
          } else if (
            endDay.toLowerCase() === pod.operations[i].day &&
            endTime > pod.operations[i].closesAt &&
            endTime.toString() !== '23:59:00'
          ) {
            podStatus = false;
            return false;
          } else if (endDay.toLowerCase() === pod.operations[i].day && endTime.toString() === '00:00:00'
            && pod.operations[i].opensAt.toString() !== "00:00:00") {
            return false;
          }
        }
      }

      const specificAvailability = vehiclesAvailability?.find((i) => {
        if (i.vehicleId === vehicle.id) {
          return i;
        } else {
          return undefined;
        }
      });

      if (
        vehiclesAvailability &&
        specificAvailability !== undefined &&
        timeZone
      ) {
        for (
          let i = 0;
          i < specificAvailability.availability.bookings.length;
          i++
        ) {
          const bookingStart = moment(
            new Date(
              specificAvailability.availability.bookings[i].startsAt,
            ).toLocaleString('en-US', { timeZone: timeZone }),
          );
          const bookingEnd = moment(
            new Date(
              specificAvailability.availability.bookings[i].endsAt,
            ).toLocaleString('en-US', { timeZone: timeZone }),
          );

          if (moment(bookingStart).isBetween(startDate, endDate)) {
            return false;
          }
          if (moment(bookingEnd).isBetween(startDate, endDate)) {
            return false;
          }
          if (moment(startDate).isBetween(bookingStart, bookingEnd)) {
            return false;
          }
          if (moment(endDate).isBetween(bookingStart, bookingEnd)) {
            return false;
          }
          if (moment(startDate).isSame(bookingStart)) {
            return false;
          }
          if (moment(startDate).isSame(bookingEnd)) {
            return false;
          }
          if (moment(endDate).isSame(bookingStart)) {
            return false;
          }
          if (moment(endDate).isSame(bookingEnd)) {
            return false;
          }
        }
      }

      return true;
    }
  };

  const isPodOpen = (vehicle: IPodVehicleBasic) => {
    const pod = podsAll?.find((pod: IPodAll) => pod.id === vehicle.podId);
    const startDay = moment(startDate).format('dddd');
    const startTime = moment(startDate).format('HH:mm:ss');
    const endDay = moment(endDate).format('dddd');
    const endTime = moment(endDate).format('HH:mm:ss');

    if (pod !== undefined) {
      for (let i = 0, podStatus = true; i <= 6 && podStatus === true; i++) {
        if (
          startDay.toLowerCase() === pod.operations[i].day &&
          pod.operations[i].isClosed === 1
        ) {
          podStatus = false;
          return false;
        } else if (
          endDay.toLowerCase() === pod.operations[i].day &&
          pod.operations[i].isClosed === 1
        ) {
          podStatus = false;
          return false;
        } else if (
          startDay.toLowerCase() === pod.operations[i].day &&
          (startTime <= pod.operations[i].opensAt ||
            startTime >= pod.operations[i].closesAt)
        ) {
          podStatus = false;
          return false;
        } else if (
          endDay.toLowerCase() === pod.operations[i].day &&
          (endTime <= pod.operations[i].opensAt ||
            endTime >= pod.operations[i].closesAt)
        ) {
          podStatus = false;
          return false;
        }
      }
      return true;
    }
  };

  const targetPod = (e: any) => {
    const vid = Number(e.currentTarget.id);
    const selectVehicle2 = vehiclesInPod?.find(
      (e: IPodVehicleBasic) => e.id === vid,
    );
    const clickedPodId2 = selectVehicle2?.podId;
    const clickedPod2 = podsAll?.find((e: IPodAll) => e.id === clickedPodId2);
    if (clickedPod2 !== undefined && selectVehicle2 !== undefined) {
      dispatch(setSelectedPod2(clickedPod2));
      dispatch(setSelectedPodVehicle2(selectVehicle2));
    }
  };

  const renderPending = () => {
    return (
      <div className="mt-2 hidden w-full flex-col items-center justify-between gap-[10px] text-[16px] lg:flex">
        <AiOutlineLoading3Quarters className="animate-spin fill-flexi-orange-1" />
      </div>
    );
  };

  const VehicleImage: React.FC<MyComponentProps> = ({ vehicle }) => {
    const [vehicleImageError, setVehicleImageError] = React.useState(false);
    const [groupImageError, setGroupImageError] = React.useState(false);

    return (
      <div className="ml-auto w-full object-contain">
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': !vehicleImageError,
          })}
          src={vehicle.vehicleImageUrl !== null ? vehicle.vehicleImageUrl : ''}
          alt={vehicle.name}
          onError={() => setVehicleImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && !groupImageError,
          })}
          src={
            vehicle.vehicleGroupImage !== null ? vehicle.vehicleGroupImage : ''
          }
          alt={vehicle.make}
          onError={() => setGroupImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && groupImageError,
          })}
          src={`${IMAGE_URL}${vehiclePlaceholder}`}
          alt="vehicle default image"
        />
      </div>
    );
  };

  return (
    <ul
      id="vehicleListUl"
      ref={vehicleListRef}
      className={classNames(
        'vehicleListScroll scrollbar flex h-full max-h-[calc(100%-85px)] snap-y flex-col overflow-y-auto overflow-x-hidden py-[35px]',
        {
          'pointer-events-none': pendingAvailabilityList && !isMapView,
          'overflow-y-scroll': !isMapView,
        },
      )}
      onScrollCapture={(e: any) => {
        handleVehicleListOnScroll(e);
      }}
    >
      {/* eslint-disable */}
      {sortedVehicles?.map((vehicle: IPodVehicleBasic) => {
        const podTarget: IPodAll | undefined = podsAll?.find(
          (pod: IPodAll) => pod.id === vehicle.podId,
        );

        const rawRates = rawCategoryRates?.podGroups
          .filter((child) => child.podGroupId === podTarget?.podGroupId)
          .map((child) => ({
            ...child,
            vehicleGroups: child.vehicleGroups.filter(
              (grandchild) =>
                grandchild.vehicleGroupId === vehicle.vehicleGroupId,
            ),
          }));

        const vehicleLatLng = {
          lat: Number(podTarget?.latitude),
          lng: Number(podTarget?.longitude),
        };
        const calculatedDistance = haversine(
          selectedLocationCenter === null ||
            selectedLocationCenter === undefined
            ? userCenter
            : selectedLocationCenter,
          vehicleLatLng,
        );
        let hasEllipsis = false;
        const podLength = podTarget?.name?.length;
        if (podLength !== undefined && podLength * 7.5 > 222)
          hasEllipsis = true;

        return (
          <div
            key={vehicle.id}
            id={`${vehicle.id}`}
            className={classNames(`pod-id-${vehicle.podId}`, {
              'selectedVehicle bg-[#EEEEEE]':
                selectedPodVehicleId2 === vehicle.id && isMapView,
              'h-0 opacity-0':
                showOnlyAvailableVehicle && !isAvailable(vehicle),
              'available-counter py-[12.5px] opacity-100':
                (showOnlyAvailableVehicle && isAvailable(vehicle)) ||
                !showOnlyAvailableVehicle,
              'snap-start': !isMapView,
              hidden: rawRates && !computedRateV2(rawRates),
            })}
            onClick={(e) => targetPod(e)}
          >
            <li className="flex flex-row gap-[21px]">
              <div className="relative flex h-[85px] !w-[470px] flex-row items-center px-[15px]">
                <div className="absolute bottom-[-12.5px] left-[471px] h-[1px] w-[100vw] bg-[#5c5c5c]" />
                <div
                  className={classNames(
                    'flex h-full w-[330px] flex-row items-center',
                    {
                      'opacity-50':
                        !isAvailable(vehicle) && !showOnlyAvailableVehicle,
                    },
                  )}
                >
                  <div className="m-auto w-[150px]">
                    <VehicleImage vehicle={vehicle} />
                  </div>
                  {/* information */}
                  <div className="items-left flex h-full w-auto flex-col justify-between px-0 py-0 sm:w-[250px] sm:px-[20px]">
                    <div
                      className={classNames(
                        'flex w-auto flex-col sm:w-[165px]',
                        {
                          'opacity-80':
                            !isAvailable(vehicle) && !showOnlyAvailableVehicle,
                        },
                      )}
                    >
                      <div className="flex gap-[5px]">
                        <strong className="truncate text-[16px]">
                          {vehicle.name}
                        </strong>
                        {token && (
                          <FavoriteButton
                            podId={vehicle.podId}
                            isFavorite={
                              favorites.find((fav) => fav.id === vehicle.podId)
                                ?.id === vehicle.podId
                            }
                            hasText={false}
                          />
                        )}
                      </div>
                      <p className="mb-[2px] truncate text-[12px]">
                        {vehicle?.make} {vehicle?.model}
                      </p>
                    </div>
                    <div
                      className={classNames('', {
                        'w-full text-ellipsis': hasEllipsis,
                      })}
                      data-tip={podTarget?.name}
                      title={podTarget?.name}
                    >
                      <small
                        className={classNames(
                          'mb-[2px] block overflow-hidden text-start text-[14px] leading-4',
                          {
                            'w-full text-ellipsis': hasEllipsis,
                            'opacity-80':
                              !isAvailable(vehicle) &&
                              !showOnlyAvailableVehicle,
                          },
                        )}
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {podTarget?.name}{' '}
                      </small>
                    </div>

                    <div className="flex w-fit items-center gap-x-[3px]">
                      <p
                        className="h-[10px] w-[6px] bg-center bg-no-repeat"
                        style={{
                          backgroundImage: `url(${IMAGE_URL}${Iconhuman})`,
                        }}
                      ></p>
                      <p className="text-[12px]">
                        {calculatedDistance < 1000 ? (
                          <>{calculatedDistance.toFixed(0)} m</>
                        ) : calculatedDistance >= 1000 ? (
                          <>{(calculatedDistance / 1000).toFixed(1)} km</>
                        ) : (
                          <>{'-'}</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                {vehicleAvailabilityStatus === RequestStatusEnum.PENDING ? (
                  renderPending()
                ) : isAvailable(vehicle) ? (
                  <div className="mt-2 hidden flex-col items-center justify-between gap-[10px] text-[16px] lg:flex">
                    <strong>
                      {rawRates !== undefined ? (
                        <>{computedRateV2(rawRates)?.toFixed(2)}</>
                      ) : (
                        <>-</>
                      )}
                    </strong>
                    <button
                      className={classNames(
                        'btn-xs h-[35px] w-[95px] rounded-full bg-flexi-green-1 !py-0 px-[5px] text-[12px] font-bold capitalize text-white hover:bg-flexi-green-2',
                        {
                          // loading: isUserPending,
                        },
                      )}
                      onClick={() => handleBooking(vehicle.id)}
                    >
                      Book
                    </button>
                  </div>
                ) : (
                  <button
                    className={classNames(
                      'hidden w-[90px] items-center pl-1  text-center text-[16px] font-bold text-zinc-700 lg:flex',
                      {
                        'pointer-events-none': !isMapView,
                      },
                    )}
                    onClick={() => {
                      if (!token) {
                        window.parent.location.href = '/register-v2';
                      } else {
                        dispatch(setMapView(false));
                      }
                    }}
                  >
                    See Availability
                  </button>
                )}
              </div>
              {!isMapView && viewTimeline && (
                <div className="flex w-full items-center">
                  <PerVehicleTimeline
                    vehicleId={vehicle.id}
                    timeZone={timeZone}
                    startDate={startDate}
                    isPodOpen={isPodOpen(vehicle)}
                    firestoreData={vehiclesAvailability}
                    firstVehicleOnList={firstVehicleOnList}
                  />
                </div>
              )}
              {/* eslint-enable */}
            </li>
          </div>
        );
      })}
    </ul>
  );
};

export default LazyVehicleList;
