import React from 'react';
import { IDynamicBlock } from 'common/types';


interface MyAccountBlockProps {
  data: IDynamicBlock;
}

const PublicContent = ({ data }: MyAccountBlockProps) => {

  return (
    <section
      className={`myAccountBlock my-[50px] flex h-fit max-w-[400px] flex-col gap-[10px] rounded-[10px_10px_10px_10px] p-[30px] md:min-h-[293px] md:min-w-[400px] md:p-[60px] lg:mt-0 bg-flexi-black-5`}
    >
      {data?.value.map((content, index) => {
        return (
          <div
            className="mb-[10px] break-words border-b-[1px] border-flexi-black-7 pb-[20px] last:mb-[0px] last:border-b-[0px] last:pb-0"
            key={content.title + index}
          >
            {content.icon ? (
              <img
                className="h-[40px] w-[40px]"
                src={content.icon}
                alt="icon"
              />
            ) : null}
            <p className="mb-[10px] mt-[10px] text-[16px] font-bold md:text-[18px]">
              {content.title}
            </p>
            <div
              className="dynamic-content-container"
              dangerouslySetInnerHTML={{
                __html: content.description,
              }}
            />
          </div>
        )})}
    </section>
  );
};

export default PublicContent;
