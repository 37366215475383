import React from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './themes/tailwind.css';
import './themes/global.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { ConnectedRouter } from 'connected-react-router';
import { browserHistory } from 'app/history';
import { HomeLogo } from 'components/TopNav';
import SignInButton from 'components/PortalComponents/SignInButton';
import SignUpButton from 'components/PortalComponents/RegisterButton';
import { SignInLink } from 'components/PortalComponents/SignInLink';
import { BottomSignIn } from 'components/HomeBottomSignIn';

const root = document.getElementById('root');
const homeLogo = document.getElementById('homeLogo');
const signInButton = document.getElementById('signinbutton');
const signInButton2 = document.getElementById('signinbutton2');
const signInLink = document.getElementById('signinlink');
const registerButton = document.getElementById('registerbutton');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <>
        {homeLogo ? createPortal(<HomeLogo />, homeLogo) : <></>}
        {signInButton ? createPortal(<SignInButton />, signInButton) : <></>}
        {signInButton2 ? createPortal(<BottomSignIn />, signInButton2) : <></>}
        {signInLink ? createPortal(<SignInLink />, signInLink) : <></>}
        {registerButton ? (
          createPortal(<SignUpButton />, registerButton)
        ) : (
          <></>
        )}
        <App />
      </>
    </ConnectedRouter>
  </Provider>,
  root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();