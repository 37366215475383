import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchAnnouncement,
} from './sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.FETCH_ANNOUNCEMENT, fetchAnnouncement),
];

export default actions;