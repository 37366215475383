import React from 'react';

import reportSuccessLottie from 'assets/json/icon-report-issue-success.json';
import LottieAnimation from 'components/LottieAnimation';

interface Props {
  offboarding: boolean;
}

const FormComplete = ({ offboarding = true }: Props) => {
  return (
    <section className="flex h-screen w-full flex-col items-center px-[25px] pt-[30px] text-center">
      <LottieAnimation
        lottieFile={reportSuccessLottie}
      />
      <p className="mt-[70px]  text-[20px] font-bold uppercase text-flexi-orange-1">
        your report has been submitted!
      </p>
      {!offboarding && (
        <p className="mt-[20px] text-[14px]">
          We strongly advise not using your phone when driving.
        </p>
      )}
      <p className="mt-[20px] text-[14px]">
        Press the arrow on the top-left to return to app.
      </p>
    </section>
  );
};

export default FormComplete;
