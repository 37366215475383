import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import gsap from 'gsap';

import { IMAGE_URL } from 'common/constants';
import { PageTitle } from 'components/Typography';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import {
  selectDefaultPaymentCard,
  selectErrors,
  selectUser,
  setErrors,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import usePaymentMethod from 'hooks/usePaymentMethod';
import useWindcave from 'hooks/useWindcave';
import PaymentMethodMessageModal from 'components/PaymentMethodMessageModal';
import PaymentMethodModal from 'components/PaymentMethodModal';
import MyAccountBlock from 'components/MyAccountBlock';
import MyAccountNav from 'components/MyAccountNav';
import Modal from 'components/Modal';
import LottieAnimation from 'components/LottieAnimation';
import removeCardLottie from 'assets/json/icon-remove-card.json';

const MyAccountPaymentMethod = () => {
  const dispatch = useAppDispatch();
  const { addCard } = useWindcave();
  const { pathname } = useLocation();
  const {
    isBusinessAccount,
    isBusinessManagerAccount,
    isBusinessDriverAccount,
  } = useBusiness();
  const { paymentCards } = usePaymentMethod();
  const user = useAppSelector(selectUser);
  const errors = useAppSelector(selectErrors);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const [burgerActive, setBurgerActive] = useState(-1);
  const [isMessageModalOpen, setMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
  const [toggleUseCredits, setToggleUseCredits] = useState(user?.isUseCredits);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleUpdateDefaultPaymentCard = (cardId: number) => {
    dispatch({ type: ACTIONS.UPDATE_DEFAULT_PAYMENT_CARD, payload: cardId });
  };

  const handleUpdateUseCredits = (isUseCredits: number) => {
    const payload = {
      isUseCredits: isUseCredits,
    };
    dispatch({ type: ACTIONS.UPDATE_USE_CREDITS, payload});
  };

  useEffect(() => {
    setToggleUseCredits(user?.isUseCredits);
  }, [user]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_WINDCAVE_CARD_URL });
    dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
    dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
  });

  const handleBurgerClick = (e: any, i: number) => {
    e.stopPropagation();
    setBurgerActive(i);
  };
  
  const handleArticleClick = () => {
    setBurgerActive(-1);
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_WINDCAVE_CARD_URL });
    dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
    dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
  }, []);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  }, []);

  useEffect(() => {
    if (errors.paymentCard) {
      setIsErrorModalOpen(true);
    }
  }, [errors]);

  useEffect(() => {
    if (
      user?.status.toLocaleLowerCase() === 'pending' &&
      defaultPaymentCard?.status?.isCardNameMismatch
    ) {
      setMessageModalOpen(true);
    }
  }, [defaultPaymentCard]);

  return (
    <article
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] md:mt-[100px] lg:mx-0"
      onClick={handleArticleClick}
    >
      <Helmet>
        <title>My Account - Payment Method</title>
        <meta
          name="description"
          content="My Flexicar Account - Payment Method"
        />
      </Helmet>
      <MyAccountNav title="Payment Method" />

      <div className="gap-[100px] lg:flex">
        <section className="w-[84vw] lg:min-w-[500px]">
          <PageTitle>Payment Method</PageTitle>
          <div className="mt-[10px] md:mt-[30px]">
            {paymentCards?.map((card, i) => {
              let imageName;
              let isImageExist = true;
              try {
                imageName = require(`assets/images/card-${card.type}.svg`);
                // do stuff
              } catch (ex) {
                isImageExist = false;
              }

              return (
                <div
                  key={card.id}
                  className={classNames(
                    'flex w-full place-items-center  justify-between  border-t-[1px] border-flexi-black-6  py-[25px]',
                    {
                      'border-b-[1px]': i !== 0,
                      'pt-[40px] md:pt-[50px]': i === 0,
                    },
                  )}
                >
                  <div className="mr-2 flex w-full">
                    <span
                      style={{
                        backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                        backgroundSize: '100%',
                      }}
                      className={classNames(
                        'mr-2 grid w-[52.5px] place-items-center bg-[center_left] bg-no-repeat text-center text-[10px] uppercase md:w-[55px]',
                        {
                          'border-[1px]': !isImageExist,
                        },
                      )}
                    >
                      {!isImageExist && card.type}
                    </span>
                    <div className="flex flex-col text-[12px] md:text-[14px]">
                      <p className="w-[130px] capitalize md:w-[200px]">
                        <span className="pr-1 font-bold">●●●● ●●●● ●●●●</span>
                        {card.cardNumber}
                      </p>

                      {card.isExpired ? (
                        <p className="text-[10px] text-red-600 md:text-[12px]">
                          Expired
                        </p>
                      ) : (
                        <p className="text-[10px] md:text-[12px]">
                          Expires on {card.expiryMonth}/{card.expiryYear}
                        </p>
                      )}
                    </div>
                    <div className="my-auto ml-auto">
                      {card.isDefault ? (
                        <p className="cursor-default text-[14px] capitalize text-[#282828]/30 md:text-[16px]">
                          default
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="relative flex h-auto  w-[20px] cursor-pointer flex-col items-center gap-[5px]">
                    <label
                      onClick={(e) => handleBurgerClick(e, i)}
                      className={classNames(
                        `modal-button flex w-[25px] cursor-pointer flex-col items-center gap-[5px]`,
                        {
                          hidden: card.isDefault && !card.isExpired,
                        },
                      )}
                    >
                      <span className="pointer-events-none h-[5px] w-[5px] rounded-full bg-flexi-black-3" />
                      <span className="pointer-events-none h-[5px] w-[5px] rounded-full bg-flexi-black-3" />
                      <span className="pointer-events-none h-[5px] w-[5px] rounded-full bg-flexi-black-3" />
                    </label>
                    <div
                      className={classNames(
                        `absolute right-[150%] top-0 hidden h-auto w-[150px] flex-col items-center overflow-hidden rounded-[10px_10px_10px_10px] bg-white shadow-[0_2px_9px] shadow-flexi-black-5`,
                        {
                          '!flex': burgerActive === i,
                        },
                      )}
                    >
                      <p
                        className={classNames(
                          'w-full p-[15px] text-center text-[16px] hover:bg-flexi-black-7 md:text-[16px]',
                          { hidden: card.isDefault || card.isExpired },
                        )}
                        onClick={() => handleUpdateDefaultPaymentCard(card.id)}
                      >
                        Set as default
                      </p>
                      <p
                        className={classNames(
                          'w-full p-[15px] text-center text-[16px] hover:bg-flexi-black-7 md:text-[16px]',
                          {
                            hidden:
                              !card.isExpired &&
                              card.isUpdateExpiredCard !== '1',
                          },
                        )}
                        onClick={() => {
                          addCard();
                          setAddCardModalOpen(true);
                        }}
                      >
                        Update card
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="mt-[20px] cursor-pointer text-[14px] font-bold capitalize text-primary md:text-[16px]"
            onClick={() => {
              addCard();
              setAddCardModalOpen(true);
            }}
          >
            + add card
          </button>
          {(!isBusinessAccount ||
            (isBusinessManagerAccount &&
              user?.organisation?.billingTo === '1') ||
            (isBusinessDriverAccount &&
              user?.organisation?.billingTo === '0')) && (
            <div className="mt-12 flex justify-between gap-[70px]">
              <div className="flex flex-col gap-[13px]">
                <p>Always use available credits for booking payment</p>
                <p className="text-[12px]">
                  Disabling credits will result in the full booking cost being
                  charged directly to your chosen payment method.
                </p>
              </div>
              <div className="flex justify-between">
                <span
                  className={classNames(
                    `relative flex h-[30px] w-[55px] cursor-pointer items-center rounded-full bg-[#dbdbdb] duration-200 
                    before:absolute before:left-[10%] before:h-[22px] before:w-[22px] before:rounded-full before:bg-[#b2b2b2] before:duration-200`,
                    {
                      '!bg-flexi-orange-2 before:!left-[calc(90%-22px)] before:!bg-flexi-orange-1':
                        toggleUseCredits,
                    },
                  )}
                  onClick={() => {
                    handleUpdateUseCredits(toggleUseCredits ? 0 : 1);
                  }}
                />
              </div>
            </div>
          )}
        </section>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>

      <Modal
        isModalOpen={isErrorModalOpen}
        handleClose={() => {
          setIsErrorModalOpen(false);
          dispatch(setErrors({}));
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <>
          <LottieAnimation
            lottieFile={removeCardLottie}
            isStopped={!isErrorModalOpen}
          />
          <h1 className="title form-control text-center text-[20px] font-bold md:text-4xl">
            Unable to update payment method
          </h1>
          <p className="text-center text-[14px] md:text-[16px]">
            {errors.paymentCard}
          </p>

          <button
            className={`button btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
            onClick={() => {
              setIsErrorModalOpen(false);
              dispatch(setErrors({}));
            }}
          >
            ok
          </button>
        </>
      </Modal>

      <PaymentMethodModal
        isModalOpen={isAddCardModalOpen}
        setModalOpen={setAddCardModalOpen}
      />
      <PaymentMethodMessageModal
        isModalOpen={isMessageModalOpen}
        handleClose={() => {
          setMessageModalOpen(false);
        }}
        setPaymentMethodModalOpen={setAddCardModalOpen}
        enableClickAway={false}
        hasCloseButton={false}
        cardStatus={defaultPaymentCard?.status}
      />
    </article>
  );
};

export default MyAccountPaymentMethod;
