import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';

import { GoogleMap, InfoWindow, OverlayView } from '@react-google-maps/api';
import haversine from 'haversine-distance';
import classNames from 'classnames';
import Slider from 'react-slick';
import * as _ from 'lodash';
import moment from 'moment';
import gsap from 'gsap';

import suburbMarker from 'assets/images/map-marker.svg';
import carIconAvailable from 'assets/images/map-marker-available.svg';
import carIconUnavailable from 'assets/images/map-marker-unavailable.svg';
import bgInfoModal from 'assets/images/bg-info-modal.png';
import { DEFAULT_LNG_LAT, IMAGE_URL } from 'common/constants';
import {
  getPreviousUrl,
  getStorageDefaultLocation,
  getToken,
  hasStorageDefaultLocation,
  removePreviousUrl,
} from 'common/helpers';
import {
  IMapCenter,
  IPodAll,
  IPodVehicleBasic,
  IVehicleSummaryMin,
  RequestStatusEnum,
} from 'common/types';
import { setLoginModalOpen } from 'modules/auth/slice';
import { selectPending, selectUser, selectUserPhoto } from 'modules/me/slice';
import {
  selectVehicleAvailabilityStatus,
  selectVehicleCategoryRates,
  setBookingTimer,
  setSelectedVehicle,
  setVisibleVehiclesId,
} from 'modules/vehicle/slice';
import {
  selectFirstVisible,
  setFirstVisible,
  selectInitialPending,
  setSelectedPod2,
  setSelectedPodVehicle2,
  setVehiclesInPodStatus,
  selectVehiclesInPodStatus,
  setVisiblePodsId2,
  selectShowOnlyAvailableVehicle,
  setPodTimezone,
  selectPodTimezone,
  setMapAutoZoomEnd,
  selectMapAutoZoomEnd,
  setCurrentZoom,
  selectAvailableCount,
  selectVisiblePods,
  selectVisiblePodsId,
  selectVisibleVehiclesId2,
  setVisiblePods,
  setVisiblePodsId,
  setVisibleVehiclesId2,
  setAvailableCount,
} from 'modules/pods/slice';
import {
  selectMapCenter,
  selectUserCenter,
  setMapBounds,
  setMapCenter,
  setMapView,
  setUserCenter,
  selectLocationAddress,
  selectSelectedLocationCenter,
  setSelectedLocationCenter,
  selectRequestVehicleSearch,
  setRequestVehicleSearch,
} from 'modules/booking/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import vehiclePlaceholder from 'assets/images/vehicle-placeholder.svg';
import { mapStyles } from './mapStyles';

interface MyComponentProps {
  vehicle: IPodVehicleBasic;
}

interface Props {
  isMapLoaded: boolean;
  mapLoadError: Error | undefined;
  setIsPendingAccount: Dispatch<boolean>;
  setIsGroupedPodsPending: Dispatch<boolean>;
  isGroupedPodsPending: boolean;
}

function Map({
  isMapLoaded,
  mapLoadError,
  setIsPendingAccount,
  setIsGroupedPodsPending,
  isGroupedPodsPending,
}: Props) {
  const token = getToken();
  const dispatch = useAppDispatch();
  const {
    isUserUnapproved,
    startDate,
    endDate,
    getCurrentLocation,
    selectedPod2,
    selectedPodVehicle2,
    podsAll,
    vehiclesInPod,
    getVehicleAvailabilityFirestore,
    vehiclesAvailability,
    computedRateV2,
    searchVisibleVehicles,
    isMapView,
    getAllPodDetails,
  } = useBooking({});
  const { push } = useHistory();
  
  const params = new URLSearchParams(location.search);
  const requestVehicleSearch = useAppSelector(selectRequestVehicleSearch);
  const previousUrl = getPreviousUrl();
  
  const defaultCenter = getStorageDefaultLocation() || DEFAULT_LNG_LAT;
  const userCenter = useAppSelector(selectUserCenter) || defaultCenter;
  const user = useAppSelector(selectUser);
  const refUserIcon = useRef<HTMLSpanElement>(null);
  const userPhoto = useAppSelector(selectUserPhoto);
  const timeZone = useAppSelector(selectPodTimezone);
  const isUserPending = useAppSelector(selectPending);
  const availableCount = useAppSelector(selectAvailableCount);
  const visiblePods = useAppSelector(selectVisiblePods);
  const visiblePodsId = useAppSelector(selectVisiblePodsId);
  const visibleVehiclesId2 = useAppSelector(selectVisibleVehiclesId2);
  const firstVisible = useAppSelector(selectFirstVisible);
  const autoZoomEnd = useAppSelector(selectMapAutoZoomEnd);
  const locationAddress = useAppSelector(selectLocationAddress);
  const isPendingInitialPods = useAppSelector(selectInitialPending);
  const rawCategoryRates = useAppSelector(selectVehicleCategoryRates);
  const vehiclesInPodStatus = useAppSelector(selectVehiclesInPodStatus);
  const selectedLocationCenter = useAppSelector(selectSelectedLocationCenter);
  const showOnlyAvailableVehicle = useAppSelector(
    selectShowOnlyAvailableVehicle,
  );
  const vehicleAvailabilityStatus = useAppSelector(
    selectVehicleAvailabilityStatus,
  );
  const isPending = vehiclesInPodStatus === RequestStatusEnum.PENDING && !isPendingInitialPods;
  const [map, setMap] = useState<any>(null);
  const [zoom, setZoom] = useState(previousUrl === '/app/booking/summary' ? 17 : 13);
  const [minZoom, setMinZoom] = useState(13);
  const [autoZoom, setAutoZoom] = useState(
    previousUrl === '/app/booking/summary' ? 17 : 13,
  );
  const [markerDimensions, setMarkerDimensions] = useState({
    width: 50,
    height: 56,
    textSize: 18,
  });
  const [staggerTime, setStaggerTime] = useState(0.015);
  const [delaySearch, setDelaySearch] = useState(false);
  const [grouped, setGrouped] = useState<any[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [center, setCenter] = useState<IMapCenter>(defaultCenter);
  const [selectedPodChecker, setSelectedPodChecker] = useState(selectedPod2);

  const sliderSettings = {
    dots: false,
    fade: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotClass: '',
  };

  const mapCenter = useAppSelector(selectMapCenter);
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {},
    gestureHandling:
      !isMapView && visiblePods?.length === 0 ? '' : isMapView ? '' : 'none',
    minZoom: minZoom,
    maxZoom: 24,
  };

  useEffectOnce(() => {
    if (previousUrl === '/app/booking/summary') {
      dispatch(setMapAutoZoomEnd(true));
      setMinZoom(5);      
    }
  })

  useEffect(() => {
    const vehicleListUlElement = document.getElementById('vehicleListUl');

    if (
      vehicleListUlElement &&
      vehicleListUlElement.children.length === 0 &&
      autoZoomEnd && map
    ) {
     debounceMapIdle(handleMapIdle, 500);
    };
  }, [map]);

  useEffect(() => {
    if (requestVehicleSearch && autoZoomEnd) {
      debounceMapIdle(handleMapIdle, 1000);
      dispatch(setRequestVehicleSearch(false));
    }
  }, [requestVehicleSearch]);

  const onMapLoad = useCallback(
    (map: any) => {
      setMap(map);
      if (!mapCenter) {
        dispatch(
          setMapCenter({ lat: map.center.lat(), lng: map.center.lng() }),
        );
        dispatch(
          setUserCenter({ lat: map.center.lat(), lng: map.center.lng() }),
        );
      }
      dispatch({
        type: ACTIONS.FETCH_LOCATION_FORMATTED_ADDRESS,
        payload: {
          latitude: map.center.lat(),
          longitude: map.center.lng(),
        },
      });
      getAllPodDetails();
    },
    [map],
  );

  const closeInfoModal = () => {
    dispatch(setSelectedPod2(null));
    dispatch(setSelectedPodVehicle2(null));
  };

  const handlePodSelect = ({ pod, e }: { pod: IPodAll; e: any }) => {
    const selectedPodCars = vehiclesInPod?.filter(
      (car) => car.podId === pod.id,
    );
    e.stopPropagation();
    setDelaySearch(true);
    dispatch(setSelectedPod2(pod));
    if (selectedPodCars !== undefined) {
      dispatch(setSelectedPodVehicle2(selectedPodCars[0]));
    }
  };

  const getMapBounds = () => {
    const mapBounds = map.getBounds();
    const ne = mapBounds.getNorthEast();
    const sw = mapBounds.getSouthWest();
    return {
      minLatitude: sw.lat(),
      maxLatitude: ne.lat(),
      minLongitude: sw.lng(),
      maxLongitude: ne.lng(),
    };
  };

  let timeout: any;

  const handleMapIdle = () => {
    const mapBounds = getMapBounds();
    if (previousUrl !== '/app/booking/summary' && !delaySearch) {

      setZoom(
        Math.round(
          9 * Math.pow(mapBounds.maxLatitude - mapBounds.minLatitude, -0.15),
        ) / 1,
      );
      dispatch(
        setCurrentZoom(
          Math.round(
            9 * Math.pow(mapBounds.maxLatitude - mapBounds.minLatitude, -0.15),
          ) / 1,
        ),
      );

      if (visiblePods && visiblePods.length === 0) {
        dispatch(setMapView(true));
      }

      dispatch(setMapBounds(mapBounds));
      if (startDate && endDate) {
        searchVisiblePods();
      }
    } else {
      removePreviousUrl();
    }
  };

  const debounceMapIdle = function (func: any, delay: number) {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const searchVisiblePods = () => {
    const mapBounds = getMapBounds();
    if (podsAll !== undefined) {
      const filteredData = podsAll?.filter(
        (i) =>
          i.latitude < mapBounds.maxLatitude &&
          i.latitude > mapBounds.minLatitude &&
          i.longitude < mapBounds.maxLongitude &&
          i.longitude > mapBounds.minLongitude,
      );
      dispatch(setVisiblePodsId(
        filteredData?.map((i) => {
          return i.id;
        }),
      ));
      dispatch(setVisiblePods(
        filteredData?.map((i) => {
          return i;
        }),
      ));
    }
  };

  const handleMapBoundsChanged = () => {
    if (firstVisible) {
      dispatch(setVehiclesInPodStatus(RequestStatusEnum.CANCELLED));
    }
  };

  const handleBooking = (id: number) => {
    isUserUnapproved();
    if (!token) {
      dispatch(setLoginModalOpen(true));
      dispatch(setBookingTimer(null));
    } else {
      dispatch(setSelectedVehicle(id));
      dispatch(setBookingTimer(new Date()));
      if (user?.status === 'approved') {
        dispatch(setSelectedVehicle(id));
        dispatch(setBookingTimer(new Date()));
        if (params.get('id'))
          push({
            pathname: '/summary',
            search: `id=${params.get('id')}`,
          });
        else {
          push('/app/booking/summary');
        }
      } else {
        push('/register/otp');
        setIsPendingAccount(true);
      }
    }
  };

  const handleMapClick = () => {
    closeInfoModal();
    dispatch(setSelectedPod2(null));
    dispatch(setSelectedPodVehicle2(null));
  };

  useUpdateEffect(() => {
    if (mapCenter) {
      setCenter(mapCenter);
    }
  }, [mapCenter, getStorageDefaultLocation()]);

  useEffectOnce(() => {
    if (!hasStorageDefaultLocation()) {
      getCurrentLocation();
    }
    dispatch(setMapView(true));
  });

  useEffect(() => {
    if (refUserIcon.current) {
      const animateIcon = gsap.timeline({ repeat: Infinity });
      animateIcon.fromTo(
        refUserIcon.current,
        {
          scale: 1,
          opacity: 1,
        },
        {
          scale: 3,
          opacity: 0,
          duration: 1.5,
        },
      );
    }
  }, [refUserIcon.current]);

  const sliderRef = useRef<any | null>(null);
  const markerRef = useRef<any | null>(null);
  const selectedPodRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previousUrl !== '/app/booking/summary' && !isPending && vehicleAvailabilityStatus === RequestStatusEnum.SUCCESS) {
      const markerChildren = document.querySelectorAll('.mapMarkerBase');

      if (markerChildren.length > 0) {
        if (isGroupedPodsPending && zoom < 14) setIsGroupedPodsPending(false);
        setStaggerTime(-0.025 * Math.log(markerChildren.length) + 0.115);

        setTimeout(() => {
          markerRef.current = gsap.timeline().to('.mapMarkerBase', {
            scale: 1,
            duration: 0.2,
            stagger: staggerTime,
            ease: 'ease.out(1.7)',
            transformOrigin: 'bottom center',
          });
        }, 150);
      }
    }
  }, [staggerTime, vehicleAvailabilityStatus, vehiclesAvailability]);

  useLayoutEffect(() => {
    if (selectedPod2) {
      if (
        selectedPodRef.current !== null &&
        selectedPod2 !== selectedPodChecker
      ) {
        gsap.fromTo(
          selectedPodRef.current,
          {
            scale: 0,
            alpha: 0,
          },
          {
            scale: 1,
            alpha: 1,
            duration: 0.6,
            ease: 'Power3.easeOut',
            transformOrigin: 'bottom',
          },
        );
        setSelectedPodChecker(selectedPod2);
      }
    }
  }, [
    selectedPodRef.current,
    selectedPodChecker,
    selectedPod2,
    selectedPodVehicle2,
  ]);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.GET_VEHICLE_CATEGORY_RATES,
      payload: {
        planId: user?.plan?.id || 1,
      },
    });
  });

  useEffect(() => {
    if (delaySearch) {
      const timeout = setTimeout(() => {
        setDelaySearch(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [delaySearch]);

  useEffect(() => {
    if (visiblePodsId) dispatch(setVisiblePodsId2(visiblePodsId));
  }, [visiblePodsId]);

  useEffect(() => {
    if (previousUrl !== '/app/booking/summary' && vehiclesInPodStatus === RequestStatusEnum.SUCCESS && vehiclesInPod) {
      setVisibleVehiclesId2([]);
      const newVisibleVehicleIds = vehiclesInPod.map((i) => i.id);
      dispatch(
        setVisibleVehiclesId2([
          ...(visibleVehiclesId2 || []),
          ...newVisibleVehicleIds,
        ]),
      );
    }
  }, [vehiclesInPod, vehiclesInPodStatus]);

  useEffect(() => {
    if (visibleVehiclesId2) dispatch(setVisibleVehiclesId(visibleVehiclesId2));
  }, [visibleVehiclesId2]);

  useEffect(() => {
    if (firstVisible) setDelaySearch(true);
  }, [firstVisible]);

  useEffect(() => {
    if (visiblePods && visiblePods.length > 0) {
      dispatch(setFirstVisible(true));
      if (!delaySearch) searchVisibleVehicles();
    }
  }, [visiblePods]);

  useEffect(() => {
    if (
      visibleVehiclesId2 && visibleVehiclesId2.length > 0 &&
      vehiclesInPodStatus === RequestStatusEnum.SUCCESS
    ) {
      getVehicleAvailabilityFirestore(visibleVehiclesId2);
    }
    if (
      vehiclesInPod !== null &&
      vehiclesInPodStatus === RequestStatusEnum.SUCCESS
    ) {
      const getTimeZone = podsAll?.find(
        (pod) => pod.id === vehiclesInPod[0]?.podId,
      );
      if (getTimeZone !== undefined) {
        dispatch(setPodTimezone(getTimeZone?.timezone));
      }
    }
  }, [vehiclesInPodStatus, vehiclesInPod, visibleVehiclesId2]);

  useEffect(() => {
    if (locationAddress) {
      if (mapCenter) dispatch(setSelectedLocationCenter(mapCenter));
    }
  }, [locationAddress]);

  useEffect(() => {
    if (user || !token) {
      const timeout = setTimeout(() => {
        setAutoZoom(17);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [user, token]);

  useEffect(() => {
    if (autoZoom === 17) {
      const timeout = setTimeout(() => {
        dispatch(setMapAutoZoomEnd(true));
        setMinZoom(5);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [autoZoom]);

  useEffect(() => {
    if (zoom < 10) {
      setMarkerDimensions({ width: 80, height: 86, textSize: 26 });
    } else if (zoom === 10) {
      setMarkerDimensions({ width: 30, height: 36, textSize: 12 });
    } else if (zoom === 11) {
      setMarkerDimensions({ width: 36, height: 42, textSize: 16 });
    } else {
      setMarkerDimensions({ width: 50, height: 56, textSize: 20 });
    }
  }, [zoom]);

  const [sliderIndex, setSliderIndex] = useState(0);

  useEffect(() => {
    if (selectedPodVehicle2 && selectedPod2 && selectedPodRef.current) {
      const currentPodVehicles = vehiclesInPod?.filter(
        (car) => car.podId === selectedPod2.id,
      );
      const index = currentPodVehicles?.findIndex(
        (car) => car.id === selectedPodVehicle2?.id,
      );
      if (index) {
        setSliderIndex(index);
        if (sliderRef.current) sliderRef.current.slickGoTo(index);
      } else {
        setSliderIndex(0);
        if (sliderRef.current) sliderRef.current.slickGoTo(0);
      }
    } else {
      setSliderIndex(0);
      if (sliderRef.current) sliderRef.current.slickGoTo(0);
    }
  }, [selectedPodVehicle2, selectedPod2, sliderIndex, sliderRef]);

  const handleSliderChange = (index: number) => {
    if (selectedPod2) {
      const selectedPodCars = vehiclesInPod?.filter(
        (car) => car.podId === selectedPod2.id,
      );
      if (selectedPodCars !== undefined) {
        const currentViewingVehicle = vehiclesInPod?.find(
          (car) => car.id === selectedPodCars[index].id,
        );
        if (currentViewingVehicle !== undefined) {
          dispatch(setSelectedPodVehicle2(currentViewingVehicle));
        }
      }
    }
  };

  useEffect(() => {
    if (showOnlyAvailableVehicle) {
      const availableList: number[] = [];
      const availableOnly =
        document.getElementsByClassName('available-counter');
      _.map(availableOnly, (elem) => {
        const classList = elem.classList;
        _.map(classList, (c) => {
          if (c.includes('pod-id')) {
            availableList.push(Number(c.slice(7)));
          }
        });
      });
      dispatch(setAvailableCount(availableList));
    } else {
      dispatch(setAvailableCount([]));
    }
  }, [showOnlyAvailableVehicle, vehiclesInPod]);

  useEffect(() => {
    if (zoom > 9 && zoom < 14 && vehiclesInPod) {
      setIsGroupedPodsPending(true);
      const groupedPods: any = _.groupBy(vehiclesInPod, 'suburb');
      setGrouped(groupedPods);
      setGroups(Object.keys(groupedPods));
    } else if (zoom <= 9) {
      setIsGroupedPodsPending(true);
      const groupedPods: any = _.groupBy(vehiclesInPod, 'state');
      setGrouped(groupedPods);
      setGroups(Object.keys(groupedPods));
    }
  }, [zoom, vehiclesInPod]);

  const VehicleImage: React.FC<MyComponentProps> = ({ vehicle }) => {
    if (vehicle.vehicleImageUrl !== null) {
      return (
        <img
          className="h-full w-full object-contain"
          src={vehicle.vehicleImageUrl}
          alt={vehicle.name}
        />
      );
    } else if (vehicle.vehicleGroupImage !== null) {
      return (
        <img
          className="h-full w-full object-contain"
          src={vehicle.vehicleGroupImage}
          alt={vehicle.make}
          onError={(e: any) =>
            (e.target.src = `${IMAGE_URL}${vehiclePlaceholder}`)
          }
        />
      );
    } else {
      return (
        <img
          className="h-full w-full object-contain"
          src={`${IMAGE_URL}${vehiclePlaceholder}`}
          alt={'vehicle default image'}
        />
      );
    }
  };

  const renderMap = () => {
    return (
      <section
        className={classNames('relative h-full duration-500', {
          'pointer-events-none': !autoZoomEnd || !isMapView,
          'pl-[0%] lg:pl-[460px]': firstVisible,
          '!pl-0': zoom < 14,
        })}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={autoZoom}
          center={center}
          options={options}
          onLoad={onMapLoad}
          clickableIcons={false}
          onBoundsChanged={handleMapBoundsChanged}
          onIdle={() => autoZoomEnd && debounceMapIdle(handleMapIdle, 1000)}
          onClick={handleMapClick}
        >
          <div>
            {zoom < 14 && groups.length > 0 && (
              <>
                {' '}
                {_.map(groups, (suburb: any) => {
                  const getOneVehicleInSuburb: IPodVehicleBasic =
                    grouped[suburb][0];
                  const pod = podsAll?.find(
                    (pod) => pod.id === getOneVehicleInSuburb?.podId,
                  );
                  const latlng = {
                    lat: pod ? parseFloat(pod.latitude) : center.lat,
                    lng: pod ? parseFloat(pod.longitude) : center.lng,
                  };

                  const podVehicleCount = grouped[suburb].length;

                  return (
                    <OverlayView
                      key={suburb}
                      position={latlng}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        className="translate-x-[-50%] translate-y-[-100%] cursor-pointer"
                        onClick={() => {
                          setAutoZoom(zoom);
                          dispatch(setMapCenter(latlng));
                          const timeout = setTimeout(() => {
                            setAutoZoom(14);
                          }, 1000);
                          return () => clearTimeout(timeout);
                        }}
                        ref={markerRef}
                      >
                        <div className="mapMarkerBase relative">
                          <img
                            src={`${IMAGE_URL}${suburbMarker}`}
                            width={markerDimensions.width}
                            height={markerDimensions.height}
                          />
                          <span
                            className={`absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-[${markerDimensions.textSize}px] font-bold`}
                          >
                            {podVehicleCount}
                          </span>
                        </div>
                      </div>
                    </OverlayView>
                  );
                })}
              </>
            )}
          </div>
          <div>
            {zoom >= 14 &&
              visiblePods?.map((pod) => {
                const latlng = {
                  lat: parseFloat(pod.latitude),
                  lng: parseFloat(pod.longitude),
                };
                const vehicleListInPod = vehiclesInPod?.filter(
                  (car) => car.podId === pod.id,
                );

                const podVehicleCount = vehicleListInPod?.length;

                const isAvailable = () => {
                  if (
                    vehicleListInPod !== undefined &&
                    podVehicleCount !== undefined
                  ) {
                    const startDay = moment(startDate).format('dddd');
                    const startTime = moment(startDate).format('HH:mm:ss');
                    const endDay = moment(endDate).format('dddd');
                    const endTime = moment(endDate).format('HH:mm:ss');

                    for (
                      let i = 0, podStatus = true;
                      i <= 6 && podStatus === true;
                      i++
                    ) {
                      if (
                        startDay.toLowerCase() === pod.operations[i].day &&
                        pod.operations[i].isClosed === 1
                      ) {
                        podStatus = false;
                        return false;
                      } else if (
                        endDay.toLowerCase() === pod.operations[i].day &&
                        pod.operations[i].isClosed === 1
                      ) {
                        podStatus = false;
                        return false;
                      } else if (
                        startDay.toLowerCase() === pod.operations[i].day &&
                        startTime > pod.operations[i].opensAt &&
                        endTime >= pod.operations[i].closesAt
                      ) {
                        podStatus = false;
                        return false;
                      } else if (
                        endDay.toLowerCase() === pod.operations[i].day &&
                        startTime < pod.operations[i].opensAt &&
                        endTime <= pod.operations[i].closesAt
                      ) {
                        podStatus = false;
                        return false;
                      } else if (
                        startDay.toLowerCase() === pod.operations[i].day &&
                        startTime < pod.operations[i].opensAt &&
                        startTime.toString() !== '00:00:00'
                      ) {
                        podStatus = false;
                        return false;
                      } else if (
                        endDay.toLowerCase() === pod.operations[i].day &&
                        endTime > pod.operations[i].closesAt &&
                        endTime.toString() !== '23:59:00'
                      ) {
                        podStatus = false;
                        return false;
                      } else if (
                        endDay.toLowerCase() === pod.operations[i].day &&
                        endTime.toString() === '00:00:00' &&
                        pod.operations[i].opensAt.toString() !== '00:00:00'
                      ) {
                        return false;
                      }
                    }

                    if (podVehicleCount === 1) {
                      const specificAvailability = vehiclesAvailability?.find(
                        (i) => {
                          if (i.vehicleId === vehicleListInPod[0].id) {
                            return i;
                          } else {
                            return undefined;
                          }
                        },
                      );

                      if (
                        timeZone &&
                        vehiclesAvailability &&
                        specificAvailability !== undefined
                      ) {
                        for (
                          let i = 0;
                          i < specificAvailability.availability.bookings.length;
                          i++
                        ) {
                          const bookingStart = moment(
                            new Date(
                              specificAvailability.availability.bookings[
                                i
                              ].startsAt,
                            ).toLocaleString('en-US', { timeZone: timeZone }),
                          );
                          const bookingEnd = moment(
                            new Date(
                              specificAvailability.availability.bookings[
                                i
                              ].endsAt,
                            ).toLocaleString('en-US', { timeZone: timeZone }),
                          );
                          if (
                            moment(bookingStart).isBetween(startDate, endDate)
                          ) {
                            return false;
                          }
                          if (
                            moment(bookingEnd).isBetween(startDate, endDate)
                          ) {
                            return false;
                          }
                          if (
                            moment(startDate).isBetween(
                              bookingStart,
                              bookingEnd,
                            )
                          ) {
                            return false;
                          }
                          if (
                            moment(endDate).isBetween(bookingStart, bookingEnd)
                          ) {
                            return false;
                          }
                          if (moment(startDate).isSame(bookingStart)) {
                            return false;
                          }
                          if (moment(startDate).isSame(bookingEnd)) {
                            return false;
                          }
                          if (moment(endDate).isSame(bookingStart)) {
                            return false;
                          }
                          if (moment(endDate).isSame(bookingEnd)) {
                            return false;
                          }
                        }
                      }

                      return true;
                    }

                    if (podVehicleCount > 0) {
                      const vehicle = vehiclesAvailability?.filter((i) => {
                        if (i.podId === pod.id) {
                          return i;
                        } else {
                          return undefined;
                        }
                      });
                      if (
                        vehiclesAvailability &&
                        vehicle !== undefined &&
                        timeZone
                      ) {
                        const vehiclesAvailabilityChecker: boolean[] = [];
                        // eslint-disable-next-line
                        for (let o = 0; o < vehicle.length; o++) {
                          const availabilityChecker: boolean[] = [];
                          for (
                            let i = 0;
                            // eslint-disable-next-line
                            i < vehicle[o].availability.bookings.length;
                            i++
                          ) {
                            const bookingStart = moment(
                              new Date(
                                // eslint-disable-next-line
                                vehicle[o].availability.bookings[i].startsAt,
                              ).toLocaleString('en-US', { timeZone: timeZone }),
                            );
                            const bookingEnd = moment(
                              new Date(
                                // eslint-disable-next-line
                                vehicle[o].availability.bookings[i].endsAt,
                              ).toLocaleString('en-US', { timeZone: timeZone }),
                            );

                            if (
                              moment(bookingStart).isBetween(startDate, endDate)
                            ) {
                              availabilityChecker.push(false);
                            } else if (
                              moment(bookingEnd).isBetween(startDate, endDate)
                            ) {
                              availabilityChecker.push(false);
                            } else if (
                              moment(startDate).isBetween(
                                bookingStart,
                                bookingEnd,
                              )
                            ) {
                              availabilityChecker.push(false);
                            } else if (
                              moment(endDate).isBetween(
                                bookingStart,
                                bookingEnd,
                              )
                            ) {
                              availabilityChecker.push(false);
                            } else if (moment(startDate).isSame(bookingStart)) {
                              availabilityChecker.push(false);
                            } else if (moment(startDate).isSame(bookingEnd)) {
                              availabilityChecker.push(false);
                            } else if (moment(endDate).isSame(bookingStart)) {
                              availabilityChecker.push(false);
                            } else if (moment(endDate).isSame(bookingEnd)) {
                              availabilityChecker.push(false);
                            } else {
                              availabilityChecker.push(true);
                            }
                          }

                          if (
                            availabilityChecker.every((value) => value === true)
                          ) {
                            vehiclesAvailabilityChecker.push(true);
                          } else {
                            vehiclesAvailabilityChecker.push(false);
                          }
                        }
                        if (
                          !vehiclesAvailabilityChecker.every(
                            (status) => status === false,
                          )
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    }

                    if (podVehicleCount === 0) {
                      return false;
                    }
                    return true;
                  }
                };

                return (
                  <OverlayView
                    key={pod.id}
                    position={latlng}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div
                      className="translate-x-[-50%] translate-y-[-100%] cursor-pointer"
                      onClick={(e) => handlePodSelect({ pod, e })}
                      ref={markerRef}
                    >
                      {isAvailable() ? (
                        <div className={classNames('mapMarkerBase relative')}>
                          <img
                            src={`${IMAGE_URL}${carIconAvailable}`}
                            width={markerDimensions.width}
                            height={markerDimensions.height}
                          />
                          <span
                            className={classNames(
                              'font-primary absolute right-[-5px] top-[-5px] z-[10] grid h-[25px] w-[25px] place-items-center rounded-full bg-flexi-black-8 font-extrabold',
                              {
                                hidden: zoom < 14,
                                'hidden h-0 w-0':
                                  (podVehicleCount !== undefined &&
                                    podVehicleCount < 2) ||
                                  isAvailable() === null,
                              },
                            )}
                          >
                            {!showOnlyAvailableVehicle
                              ? podVehicleCount
                              : availableCount &&
                                availableCount.filter((v) => v === pod.id)
                                  .length > 0 &&
                                availableCount.filter((v) => v === pod.id)
                                  .length}
                          </span>
                        </div>
                      ) : (
                        <div
                          className={classNames('mapMarkerBase relative', {
                            hidden:
                              (podVehicleCount !== undefined &&
                                podVehicleCount === 0) ||
                              isAvailable() === null,
                          })}
                        >
                          <img
                            src={`${IMAGE_URL}${carIconUnavailable}`}
                            width={markerDimensions.width}
                            height={markerDimensions.height}
                            className={classNames('', {
                              hidden:
                                podVehicleCount === 0 || isAvailable() === null,
                              'origin-bottom scale-0 opacity-0 duration-500 ease-out':
                                showOnlyAvailableVehicle,
                              'scale-1 origin-bottom opacity-100 duration-500 ease-out':
                                !showOnlyAvailableVehicle,
                            })}
                          />
                          <span
                            className={classNames(
                              'font-primary absolute right-[-5px] top-[-5px] z-[10] grid h-[25px] w-[25px] place-items-center rounded-full bg-flexi-black-8 font-extrabold',
                              {
                                hidden: zoom < 14,
                                'hidden h-0 w-0':
                                  (podVehicleCount !== undefined &&
                                    podVehicleCount < 2) ||
                                  isAvailable() === null,
                                'opacity-0 duration-500':
                                  showOnlyAvailableVehicle &&
                                  availableCount &&
                                  availableCount.filter((v) => v === pod.id)
                                    .length === 0,
                                'opacity-100 duration-500':
                                  !showOnlyAvailableVehicle,
                              },
                            )}
                          >
                            {!showOnlyAvailableVehicle
                              ? podVehicleCount
                              : availableCount &&
                                availableCount.filter((v) => v === pod.id)
                                  .length > 0 &&
                                availableCount.filter((v) => v === pod.id)
                                  .length}
                          </span>
                        </div>
                      )}
                    </div>
                  </OverlayView>
                );
              })}
          </div>
          <OverlayView
            position={userCenter}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              className={classNames(
                'relative grid place-items-center rounded-full bg-flexi-black-5 text-[60px] text-flexi-black-4',
                {
                  '': userPhoto,
                },
              )}
            >
              <span
                className="absolute left-0 top-[0px] z-[-1] h-full w-full rounded-full !bg-flexi-orange-5"
                ref={refUserIcon}
              />
              {userPhoto ? (
                <img
                  className="block h-[40px] w-[40px] rounded-full object-cover"
                  src={userPhoto}
                  alt="User's Current Location"
                />
              ) : (
                <span
                  className="grid h-[40px] w-[40px] place-items-center rounded-full  border-flexi-white-1 !bg-[#1f7f74]
                text-[16px] text-white shadow-lg"
                >
                  {user?.fullname[0]}
                </span>
              )}
            </div>
          </OverlayView>
          {selectedPod2 ? (
            <article className="pointer-events-none">
              <InfoWindow
                position={{
                  lat: parseFloat(selectedPod2.latitude),
                  lng: parseFloat(selectedPod2.longitude),
                }}
                onCloseClick={closeInfoModal}
                onUnmount={closeInfoModal}
              >
                <section
                  ref={selectedPodRef}
                  className="selectedPodBase pointer-events-none"
                >
                  {vehiclesInPod?.filter((car) => car.podId === selectedPod2.id)
                    ?.length ? (
                    <div className="vehicle-slider relative !left-[calc(50%-10px)] z-10 flex w-[180px] !translate-x-[-50%] flex-col">
                      <Slider
                        {...sliderSettings}
                        afterChange={(index) => handleSliderChange(index)}
                        className="sliderClass pointer-events-auto pb-[15%]"
                        ref={sliderRef}
                        initialSlide={sliderIndex}
                      >
                        {vehiclesInPod
                          ?.filter((car) => car.podId === selectedPod2.id)
                          .map((vehicle) => {
                            const podTarget = podsAll?.find(
                              // eslint-disable-next-line
                              (pod: IPodAll) => pod.id === vehicle.podId,
                            );

                            const rawRates = rawCategoryRates?.podGroups
                              .filter(
                                (child) =>
                                  child.podGroupId === podTarget?.podGroupId,
                              )
                              .map((child) => ({
                                ...child,
                                vehicleGroups: child.vehicleGroups.filter(
                                  (grandchild) =>
                                    grandchild.vehicleGroupId ===
                                    // eslint-disable-next-line
                                    vehicle.vehicleGroupId,
                                ),
                              }));
                            const vehicleLatLng = {
                              lat: Number(selectedPod2.latitude),
                              lng: Number(selectedPod2.longitude),
                            };
                            const calculatedDistance = haversine(
                              selectedLocationCenter === null ||
                                selectedLocationCenter === undefined
                                ? userCenter
                                : selectedLocationCenter,
                              vehicleLatLng,
                            );

                            const isVehicleAvailable = () => {
                              const specificAvailability: IVehicleSummaryMin[] =
                                [];

                              _.find(vehiclesAvailability, function (i) {
                                // eslint-disable-next-line
                                if (i.vehicleId === vehicle.id) {
                                  specificAvailability.push({
                                    podId: i.podId,
                                    vehicleId: i.vehicleId,
                                    availability: {
                                      bookings: _.map(
                                        i.availability.bookings,
                                        (a) => {
                                          return {
                                            bookingId: a.bookingId,
                                            startsAt: a.startsAt,
                                            endsAt: a.endsAt,
                                            driverId: a.driverId,
                                            organisationId: a.organisationId,
                                            isMaintenance: a.isMaintenance,
                                          };
                                        },
                                      ),
                                    },
                                  });
                                }
                              });

                              if (
                                vehiclesAvailability &&
                                specificAvailability.length > 0
                              ) {
                                for (
                                  let i = 0;
                                  i <
                                  specificAvailability[0].availability.bookings
                                    .length;
                                  i++
                                ) {
                                  const bookingStart = new Date(
                                    specificAvailability[0].availability.bookings[
                                      i
                                    ].startsAt,
                                  );
                                  const bookingEnd = new Date(
                                    specificAvailability[0].availability.bookings[
                                      i
                                    ].endsAt,
                                  );
                                  if (
                                    moment(bookingStart).isBetween(
                                      startDate,
                                      endDate,
                                    )
                                  ) {
                                    return false;
                                  }
                                  if (
                                    moment(bookingEnd).isBetween(
                                      startDate,
                                      endDate,
                                    )
                                  ) {
                                    return false;
                                  }
                                  if (
                                    moment(startDate).isBetween(
                                      bookingStart,
                                      bookingEnd,
                                    )
                                  ) {
                                    return false;
                                  }
                                  if (
                                    moment(endDate).isBetween(
                                      bookingStart,
                                      bookingEnd,
                                    )
                                  ) {
                                    return false;
                                  }
                                  if (moment(startDate).isSame(bookingStart)) {
                                    return false;
                                  }
                                  if (moment(startDate).isSame(bookingEnd)) {
                                    return false;
                                  }
                                  if (moment(endDate).isSame(bookingStart)) {
                                    return false;
                                  }
                                  if (moment(endDate).isSame(bookingEnd)) {
                                    return false;
                                  }
                                }
                              }

                              return true;
                            };

                            const isPodAvailable = () => {
                              const startDay = moment(startDate).format('dddd');
                              const startTime =
                                moment(startDate).format('HH:mm:ss');
                              const endDay = moment(endDate).format('dddd');
                              const endTime =
                                moment(endDate).format('HH:mm:ss');

                              for (
                                let i = 0, podStatus = true;
                                i <= 6 && podStatus === true;
                                i++
                              ) {
                                if (
                                  startDay.toLowerCase() ===
                                    selectedPod2.operations[i].day &&
                                  selectedPod2.operations[i].isClosed === 1
                                ) {
                                  podStatus = false;
                                  return false;
                                } else if (
                                  endDay.toLowerCase() ===
                                    selectedPod2.operations[i].day &&
                                  selectedPod2.operations[i].isClosed === 1
                                ) {
                                  podStatus = false;
                                  return false;
                                } else if (
                                  startDay.toLowerCase() ===
                                    selectedPod2.operations[i].day &&
                                  (startTime <=
                                    selectedPod2.operations[i].opensAt ||
                                    startTime >=
                                      selectedPod2.operations[i].closesAt)
                                ) {
                                  podStatus = false;
                                  return false;
                                } else if (
                                  endDay.toLowerCase() ===
                                    selectedPod2.operations[i].day &&
                                  (endTime <=
                                    selectedPod2.operations[i].opensAt ||
                                    endTime >=
                                      selectedPod2.operations[i].closesAt)
                                ) {
                                  podStatus = false;
                                  return false;
                                }
                              }
                              return true;
                            };

                            return (
                              <section
                                // eslint-disable-next-line
                                key={vehicle.id}
                                // eslint-disable-next-line
                                id={`vid${vehicle.id}`}
                                className="pointer-events-none flex  h-[190px] w-[160px] items-center justify-center pt-[10px] text-center"
                              >
                                <article className="mt-[15px] flex h-full flex-col items-center">
                                  <div className="mb-[10px] w-[100px]">
                                    <VehicleImage vehicle={vehicle} />
                                  </div>
                                  <strong
                                    id="getVehicleName"
                                    className="block max-w-[120px] truncate text-[14px] font-bold md:text-[16px]"
                                  >
                                    {/* eslint-disable-next-line */}
                                    {vehicle.name}
                                  </strong>
                                  <small className="block text-[10px] md:mt-1 ">
                                    {/* eslint-disable-next-line */}
                                    {vehicle.make} {vehicle.model}
                                  </small>
                                  <small className="block text-[10px] md:mt-1 ">
                                    {calculatedDistance < 1000 ? (
                                      <>{calculatedDistance.toFixed(0)} m</>
                                    ) : calculatedDistance >= 1000 ? (
                                      <>
                                        {(calculatedDistance / 1000).toFixed(1)}{' '}
                                        km
                                      </>
                                    ) : (
                                      <>{'-'}</>
                                    )}
                                  </small>
                                  {isPodAvailable() && isVehicleAvailable() && (
                                    <strong className="mb-[3px] mt-[3px] block text-[11px] font-bold text-primary md:hidden">
                                      {rawRates !== undefined ? (
                                        <>
                                          {computedRateV2(rawRates)?.toFixed(2)}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </strong>
                                  )}
                                  <div className="block md:hidden">
                                    {isPodAvailable() &&
                                    isVehicleAvailable() ? (
                                      <button
                                        className={classNames(
                                          'btn btn-primary pointer-events-auto mt-[0px]  !h-[18px] !max-h-[18px] !min-h-[18px] w-[60px] rounded-full text-[10px] capitalize',
                                          {
                                            loading: isUserPending,
                                          },
                                        )}
                                        onClick={() =>
                                          // eslint-disable-next-line
                                          handleBooking(vehicle.id)
                                        }
                                      >
                                        Book
                                      </button>
                                    ) : (
                                      <span className="mt-[10px] block text-[12px] font-[700] lg:mt-[5px]">
                                        Unavailable
                                      </span>
                                    )}
                                  </div>
                                </article>
                              </section>
                            );
                          })}
                      </Slider>
                      <p className="absolute bottom-[-15px] right-0 flex h-[65px] w-full justify-center text-[10px] font-bold md:bottom-0 md:text-[11px]">
                        {`${sliderIndex < 9 ? ' ' : ''}${sliderIndex + 1}`} /{' '}
                        {
                          vehiclesInPod?.filter(
                            (car) => car.podId === selectedPod2.id,
                          )?.length
                        }
                      </p>
                    </div>
                  ) : (
                    <div className="absolute left-[50%] top-[50%] z-[100000] flex translate-x-[calc(-50%-10px)] translate-y-[-50%] items-center justify-center text-center text-[18px] font-bold">
                      <p>No Vehicles Here</p>
                    </div>
                  )}

                  <img
                    src={`${IMAGE_URL}${bgInfoModal}`}
                    className="pointer-events-none absolute left-0 top-0 !h-auto w-[200px] !max-w-[100%] border-none !bg-transparent outline-none"
                  />
                </section>
              </InfoWindow>
            </article>
          ) : (
            <></>
          )}
        </GoogleMap>
      </section>
    );
  };

  if (mapLoadError) {
    return <section>Map cannot be loaded right now, sorry.</section>;
  }

  return isMapLoaded ? renderMap() : <></>;
}

export default Map;
