import { PayloadAction } from '@reduxjs/toolkit';
import api, { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { API_URL } from 'common/constants';
import { handleAPIError } from 'common/errorHandler';
import { getTimezone, getToken } from 'common/helpers';
import { IBooking, IBookVehicle, IReportVehicle, RequestStatusEnum } from 'common/types';
import { call, cancelled, put, select } from 'redux-saga/effects';
import {
  setBookingList,
  setPastBookingList,
  setPendingBooking,
  setUpcomingBookingList,
  setVehicleEstimation,
  setPendingInvoiceList,
  setPendingVehicleCategories,
  setInvoiceList,
  setInvoiceDetails,
  setVehicleCategories,
  setPendingInvoiceDetails,
  setPendingLocationFormattedAddress,
  setLocationAddress,
  setCancelBookingStatus,
  setCreateBookingStatus,
  setModifyBookingId,
  setBookingSummaryModify,
  setUpdateBookingStatus,
  setUpdateBookingEstimateAmount,
  setOngoingBookingList,
  setReportBookingUrl,
  setReportBookingStatus,
  setCancellationFees,
  setCancellationFeesStatus,
  setErrors,
  setBookingPastStatus,
  setBookingOngoingStatus,
  setBookingUpcomingStatus,
  selectUpcomingBookingList,
  selectPastBookingList,
  selectOngoingBookingList,
  setBookingEstimateStatus,
  setLoadingBookingsOngoing,
  setLoadingBookingsUpcoming,
  selectLoadingBookingsOngoing,
  selectLoadingBookingsUpcoming,
  setBookingSummaryModifyStatus,
  setBookingSummaryModifySecondStatus,
  setMapCenter,
  setFetchAvailableVehiclesStatus,
  setFetchAvailableVehicles,
  setRescheduleDataStatus,
  setRescheduleData,
  setOngoingBookingListPaginated,
  setPastBookingListPaginated,
  setUpcomingBookingListPaginated,
} from './slice';
import { setVehiclesInPod } from 'modules/pods/slice';
import { setUser } from 'modules/me/slice';
import { fetchMe } from 'modules/me/sagas';
import { setError } from 'modules/auth/slice';
import { setPendingCardUrl } from 'modules/transaction/slice';

export const SAGA_ACTIONS = {
  FETCH_BOOKINGS: 'FETCH_BOOKINGS',
  FETCH_BOOKINGS_UPCOMING: 'FETCH_BOOKINGS_UPCOMING',
  FETCH_BOOKINGS_PAST: 'FETCH_BOOKINGS_PAST',
  UPDATE_BOOKING_STATUS: 'UPDATE_BOOKING_STATUS',
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  CANCEL_BOOKING_V2: 'CANCEL_BOOKING_V2',
  CREATE_BOOKING: 'CREATE_BOOKING',
  CREATE_BOOKING_BUSINESS: 'CREATE_BOOKING_BUSINESS',
  CREATE_BOOKING_V2: 'CREATE_BOOKING_V2',
  CREATE_BOOKING_BUSINESS_V2: 'CREATE_BOOKING_BUSINESS_V2',
  UPDATE_BOOKING: 'UPDATE_BOOKING',
  UPDATE_BOOKING_V2: 'UPDATE_BOOKING_V2',
  UPDATE_BOOKING_ESTIMATE: 'UPDATE_BOOKING_ESTIMATE',
  FETCH_BOOKING_ESTIMATE: 'FETCH_BOOKING_ESTIMATE',
  FETCH_VEHICLE_ESTIMATION: 'FETCH_VEHICLE_ESTIMATION',
  FETCH_INVOICE_LIST: 'FETCH_INVOICE_LIST',
  FETCH_INVOICE_DETAILS: 'FETCH_INVOICE_DETAILS',
  FETCH_VEHICLE_CATEGORIES: 'FETCH_VEHICLE_CATEGORIES',
  FETCH_LOCATION_FORMATTED_ADDRESS: 'FETCH_LOCATION_FORMATTED_ADDRESS',
  FETCH_BOOKING_SUMMARY_MODIFY: 'FETCH_BOOKING_SUMMARY_MODIFY',
  FETCH_BOOKINGS_ONGOING: 'FETCH_BOOKINGS_ONGOING',
  REPORT_BOOKING: 'REPORT_BOOKING',
  REPORT_BAY_TAKEN_BOOKING: 'REPORT_BAY_TAKEN_BOOKING',
  FETCH_REPORT_BOOKING_URL: 'FETCH_REPORT_BOOKING_URL',
  FETCH_CANCELLATION_FEES: 'FETCH_CANCELLATION_FEES',
  FETCH_CANCELLATION_FEES_V2: 'FETCH_CANCELLATION_FEES_V2',
  FETCH_AVAILABLE_VEHICLES: 'FETCH_AVAILABLE_VEHICLES',
  FETCH_RESCHEDULE_DETAILS: 'FETCH_RESCHEDULE_DETAILS',
  FETCH_BOOKINGS_ONGOING_V2: 'FETCH_BOOKINGS_ONGOING_V2',
  FETCH_BOOKINGS_UPCOMING_V2: 'FETCH_BOOKINGS_UPCOMING_V2',
  FETCH_BOOKINGS_PAST_V2: 'FETCH_BOOKINGS_PAST_V2',
  UPDATE_BOOKING_PAYMENT_CARD: 'UPDATE_BOOKING_PAYMENT_CARD',
};

export function* fetchBookings() {
  const api = authenticatedClient();
  try {
    yield put(setPendingBooking(true));
    const { data } = yield call(() => api.get(`/bookings`));
    yield put(setBookingList(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingBooking(false));
  }
}

export function* fetchBookingsUpcoming():any {
  const api = authenticatedClient();
  const dateToday = new Date().toISOString();
  const timezone = getTimezone();
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const booking: IBooking[] = yield select(selectUpcomingBookingList);
    if(!booking.length)
      yield put(setBookingUpcomingStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(`/bookings/upcoming?starts_at=${dateToday}&timezone=${timezone}`, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setUpcomingBookingList(data));
    yield put(setBookingUpcomingStatus(RequestStatusEnum.SUCCESS));
    yield put(setLoadingBookingsUpcoming([]));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setBookingUpcomingStatus(RequestStatusEnum.FAILED));
    }
  } finally{
    if (yield cancelled()) {
      // if(!booking.length)
        // yield put(setBookingUpcomingStatus(RequestStatusEnum.FAILED));
      cancelTokenSource.cancel();
    }
  }
}

export function* fetchBookingsOngoing ():any {
  const api = authenticatedClient();
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const booking: IBooking[] = yield select(selectOngoingBookingList);
    if(!booking.length)
      yield put(setBookingOngoingStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(`/bookings/ongoing`, {
        cancelToken: cancelTokenSource.token,
      })
    );
    yield put(setOngoingBookingList(data));
    yield put(setBookingOngoingStatus(RequestStatusEnum.SUCCESS));
    yield put(setLoadingBookingsOngoing([]));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setBookingOngoingStatus(RequestStatusEnum.FAILED));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setBookingOngoingStatus(RequestStatusEnum.FAILED));
      cancelTokenSource.cancel();
    }
  }
}

export function* fetchBookingsPast ():any {
  const api = authenticatedClient();
  const cancelTokenSource = axios.CancelToken.source();
  try {    
    const booking: IBooking[] = yield select(selectPastBookingList);
    if(!booking.length)
      yield put(setBookingPastStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get(`/bookings/history`, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setPastBookingList(data));
    yield put(setBookingPastStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setBookingPastStatus(RequestStatusEnum.FAILED));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setBookingPastStatus(RequestStatusEnum.FAILED));
      cancelTokenSource.cancel();
    }
  }
}

type fetchBookingsPayload = {
  page: number | 1;
  search?: string;
  limit : number;

};

export function* fetchBookingsUpcomingV2(action: PayloadAction<fetchBookingsPayload>): any {
  const api = authenticatedClient();
  const dateToday = new Date().toISOString();
  const timezone = getTimezone();
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setBookingUpcomingStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(
        `/bookings/v2/upcoming?starts_at=${dateToday}&timezone=${timezone}&page=${
          action.payload.page
        }&limit=${action.payload.limit || 5}&search=${action.payload.search || ''}`,
        {
          cancelToken: cancelTokenSource.token,
        },
      ),
    );
    yield put(setUpcomingBookingListPaginated(data));
    yield put(setBookingUpcomingStatus(RequestStatusEnum.SUCCESS));
    yield put(setLoadingBookingsUpcoming([]));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setBookingUpcomingStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* fetchBookingsOngoingV2(
  action: PayloadAction<fetchBookingsPayload>,
): any {
  const api = authenticatedClient();
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const booking: IBooking[] = yield select(selectOngoingBookingList);
    if (!booking.length)
      yield put(setBookingOngoingStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(
        `/bookings/v2/ongoing?page=${action.payload.page}&limit=${
          action.payload.limit || 5
        }&search=${action.payload.search || ''}`,
        {
          cancelToken: cancelTokenSource.token,
        },
      ),
    );
    yield put(setOngoingBookingListPaginated(data));
    yield put(setBookingOngoingStatus(RequestStatusEnum.SUCCESS));
    yield put(setLoadingBookingsOngoing([]));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setBookingOngoingStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    if (yield cancelled()) {
      yield put(setBookingOngoingStatus(RequestStatusEnum.FAILED));
      cancelTokenSource.cancel();
    }
  }
}

export function* fetchBookingsPastV2(
  action: PayloadAction<fetchBookingsPayload>,
): any {
  const api = authenticatedClient();
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const booking: IBooking[] = yield select(selectPastBookingList);
    if (!booking.length)
      yield put(setBookingPastStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(
        `/bookings/v2/history?page=${action.payload.page}&limit=${
          action.payload.limit || 5
        }&search=${action.payload.search || ''}`,
        {
          cancelToken: cancelTokenSource.token,
        },
      ),
    );
    yield put(setPastBookingListPaginated(data));
    yield put(setBookingPastStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setBookingPastStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    if (yield cancelled()) {
      yield put(setBookingPastStatus(RequestStatusEnum.FAILED));
      cancelTokenSource.cancel();
    }
  }
}

type BookingSummaryModifyPayload = {
  bookingId: number;
  addons?: string;
  promo?: string;
  noload?: boolean;
};

export function* fetchBookingSummaryModify(
  action: PayloadAction<BookingSummaryModifyPayload>,
) {
  const api = authenticatedClient();
  try {
    if(!action.payload.noload)
      yield put(setBookingSummaryModifyStatus(RequestStatusEnum.PENDING));
    yield put(setBookingSummaryModifySecondStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(`/bookings/${action.payload.bookingId}/summary`),
    );
    yield put(setBookingSummaryModifyStatus(RequestStatusEnum.SUCCESS));
    yield put(setBookingSummaryModifySecondStatus(RequestStatusEnum.SUCCESS));
    yield put(setBookingSummaryModify(data));
  } catch (err) {
    yield put(setBookingSummaryModifySecondStatus(RequestStatusEnum.FAILED));
    yield put(setBookingSummaryModifyStatus(RequestStatusEnum.FAILED));
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingBooking(false));
  }
}

type UpdateBookingStatusPayload = {
  bookingId: number;
  status: number;
};

export function* updateBookingStatus(
  action: PayloadAction<UpdateBookingStatusPayload>,
) {
  const api = authenticatedClient();
  const { bookingId, status } = action.payload;
  try {
    yield put(setUpdateBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.put(`/bookings/${bookingId}`, { status }));
    yield put(setUpdateBookingStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchBookingsOngoing);
    yield call(fetchBookingsUpcoming);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    yield put(setUpdateBookingStatus(RequestStatusEnum.FAILED));
    }
  }
}

type CancelBookingV2Payload = {
  bookingId: number;
  fetchList?: boolean;
};

export function* cancelBookingV2(action: PayloadAction<CancelBookingV2Payload>) {
  const api = authenticatedClient();
  const { bookingId, fetchList } = action.payload;
  try {
    yield put(setCancelBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/bookings/${bookingId}/cancel-booking`));
    yield put(setCancelBookingStatus(RequestStatusEnum.SUCCESS));
    if (fetchList) {
      yield call(fetchBookingsOngoing);
      yield call(fetchBookingsUpcoming);
      yield call(fetchBookingsPast);
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setCancelBookingStatus(RequestStatusEnum.FAILED));
    }
  } 
  // finally {
    // yield call(fetchBookings);
    // yield put(setPendingCancelBooking(false));
  // }
}

type CancelBookingPayload = {
  bookingId: number;
};

export function* cancelBooking(action: PayloadAction<CancelBookingPayload>) {
  const api = authenticatedClient();
  const { bookingId } = action.payload;
  try {
    // yield put(setPendingCancelBooking(true));
    yield put(setCancelBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/bookings/${bookingId}/cancel`));
    yield call(fetchBookingsOngoing);
    yield call(fetchBookingsUpcoming);
    yield call(fetchBookingsPast);
    yield put(setCancelBookingStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setCancelBookingStatus(RequestStatusEnum.FAILED));
    }
  } 
  // finally {
    // yield call(fetchBookings);
    // yield put(setPendingCancelBooking(false));
  // }
}

type CreateBookingPayload = {
  vehicleId: number;
  startsAt: string;
  endsAt: string;
  promoCode?: string;
  bookingReference?: string;
  bookingId?: string;
  costCentre?: string;
  addons: [];
  source: string;
  // timezone: string;
};

export function* createBooking(action: PayloadAction<CreateBookingPayload>):any {
  const api = authenticatedClient();
  const {
    // timezone,
    vehicleId,
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    costCentre,
    addons,
    source
} = action.payload;
  const payload = {
    // timezone,
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    costCentre,
    addons,
    source
  };

  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setCreateBookingStatus(RequestStatusEnum.PENDING))
    yield call(() => api.post(`/vehicles/${vehicleId}/book`, payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setCreateBookingStatus(RequestStatusEnum.SUCCESS));
    yield put(setModifyBookingId(undefined));
  } catch (err) {
    yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      // console.log('error:', { ...err.response.data?.error })
      yield put(setErrors( err.response.data?.message || err.response.data?.error?.message ));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors('Booking creation timed out. Please try again.'));
      cancelTokenSource.cancel();
    }
  }
}

type CreateBookingBusinessPayload = {
  vehicleId: number;
  driverId: number;
  startsAt: string;
  endsAt: string;
  promoCode?: string;
  bookingReference?: string;
  bookingId?: string;
  addons?: string[];
  costCentre: string;
  source: string;
  // timezone: string;
};

export function* createBookingBusiness(
  action: PayloadAction<CreateBookingBusinessPayload>,
):any {
  const api = authenticatedClient();
  const {
    vehicleId,
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    driverId,
    addons,
    costCentre,
    source,
    // timezone,
  } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    // timezone,
    promoCode,
    bookingReference,
    bookingId,
    addons,
    costCentre,
    source
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setCreateBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/vehicles/${vehicleId}/book/${driverId}`, payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setCreateBookingStatus(RequestStatusEnum.SUCCESS));
    yield put(setModifyBookingId(undefined));
  } catch (err) {
    yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      // yield put(setErrors({ ...err, ...err.response.data?.errors }));
      yield put(setErrors( err.response.data?.message || err.response.data?.error?.message ));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors('Booking creation timed out. Please try again.'));
      cancelTokenSource.cancel();
    }
  }
}

type CreateBookingV2Payload = {
  vehicleId: number;
  startsAt: string;
  endsAt: string;
  promoCode?: string;
  bookingReference?: string;
  bookingId?: string;
  costCentre?: string;
  addons: [];
  source: string;
  isUseCredits: number;
  creditsAmount: number | null;
  // timezone: string;
};

export function* createBookingV2(action: PayloadAction<CreateBookingV2Payload>):any {
  const api = authenticatedClient();
  const {
    vehicleId,
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    costCentre,
    addons,
    source,
    isUseCredits,
    creditsAmount,
  } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    costCentre,
    addons,
    source,
    isUseCredits,
    creditsAmount,
  };

  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setCreateBookingStatus(RequestStatusEnum.PENDING))
    yield call(() => api.post(`/vehicles/v2/${vehicleId}/book`, payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setCreateBookingStatus(RequestStatusEnum.SUCCESS));
    yield put(setModifyBookingId(undefined));
  } catch (err) {
    yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
    if (axios.isAxiosError(err) && err.response) {
      yield put(setErrors( err.response.data?.message || err.response.data?.error?.message ));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors('Booking creation timed out. Please try again.'));
      cancelTokenSource.cancel();
    }
    const { data } = yield call(() =>
      api.get('/me', {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setUser(data));
  }
}

type CreateBookingBusinessV2Payload = {
  vehicleId: number;
  driverId: number;
  startsAt: string;
  endsAt: string;
  promoCode?: string;
  bookingReference?: string;
  bookingId?: string;
  addons?: string[];
  costCentre: string;
  source: string;
  isUseCredits: number;
  creditsAmount: number | null;
};

export function* createBookingBusinessV2(
  action: PayloadAction<CreateBookingBusinessV2Payload>,
):any {
  const api = authenticatedClient();
  const {
    vehicleId,
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    driverId,
    addons,
    costCentre,
    source,
    isUseCredits,
    creditsAmount,
  } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    promoCode,
    bookingReference,
    bookingId,
    addons,
    costCentre,
    source,
    isUseCredits,
    creditsAmount,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setCreateBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/vehicles/v2/${vehicleId}/book/${driverId}`, payload, {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setCreateBookingStatus(RequestStatusEnum.SUCCESS));
    yield put(setModifyBookingId(undefined));
  } catch (err) {
    yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
    if (axios.isAxiosError(err) && err.response) {
      yield put(setErrors( err.response.data?.message || err.response.data?.error?.message ));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setCreateBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors('Booking creation timed out. Please try again.'));
      cancelTokenSource.cancel();
    }
    const { data } = yield call(() =>
      api.get('/me', {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setUser(data));
  }
}

export function* fetchVehicleEstimation(action: PayloadAction<IBookVehicle>) {
  const api = authenticatedClient();
  try {
    yield put(setPendingBooking(true));
    const { data } = yield call(() =>
      api.get(
        `/vehicles/${action.payload.vehicleId}/plans/2/estimation?starts_at=${action.payload.startTime}&ends_at=${action.payload.endTime}`,
      ),
    );
    yield put(setVehicleEstimation(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingBooking(false));
  }
}


type FetchInvoiceListPayload = {
  endDate?: string | null;
  search?: string | null;
  limit?: number;
  page?: number;
  startDate?: string | null;
};

export function* fetchInvoiceList(action: PayloadAction<FetchInvoiceListPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setPendingInvoiceList(true));
    const { data } = yield call(() =>
      api.get(
        `/invoices?limit=${action.payload.limit}&page=${action.payload.page}${
          action.payload.search ? `&search=${action.payload.search}` : ''
        }${
          action.payload.startDate
            ? `&start_date=${action.payload.startDate}`
            : ''
        }${
          action.payload.endDate ? `&end_date=${action.payload.endDate}` : ''
        }`,
      ),
    );
    yield put(setInvoiceList(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingInvoiceList(false));
  }
}

export function* fetchInvoiceDetail(action: PayloadAction<number>) {
  const api = authenticatedClient();
  try {
    yield put(setPendingInvoiceDetails(true));
    const { data } = yield call(() => api.get(`/invoices/${action.payload}`));
    yield put(setInvoiceDetails(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingInvoiceDetails(false));
  }
}

export function* fetchVehicleCategories() {
  const api = authenticatedClient();
  try {
    yield put(setPendingVehicleCategories(true));
    const { data } = yield call(() => api.get(`/vehicles/categories`));
    yield put(setVehicleCategories(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingVehicleCategories(false));
  }
}

type FetchLocationFormattedAddressPayload = {
  latitude: number;
  longitude: number;
};

export function* fetchLocationFormattedAddress (action: PayloadAction<FetchLocationFormattedAddressPayload>):any {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setPendingLocationFormattedAddress(true));
    const { data } = yield call(() =>
      api.post(`/maps/getAddressFromCoordinates`, action.payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setLocationAddress(data.formattedAddress));
    yield put(
      setMapCenter({
        lat: data.geometry.location.lat,
        lng: data.geometry.location.lng,
      }),
    );
    yield put(setVehiclesInPod([]));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingLocationFormattedAddress(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type UpdateBookingPayload = {
  bookingId: number;
  startsAt: string;
  endsAt: string;
  timezone: string;
  refreshMode: string;
};

export function* updateBooking(action: PayloadAction<UpdateBookingPayload>) {
  const api = authenticatedClient();
  const { bookingId, startsAt, endsAt, timezone } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    timezone,
  };
  const loadingBookingsOngoing: number[] = yield select(selectLoadingBookingsOngoing);
  const loadingBookingsUpcoming: number[] = yield select(selectLoadingBookingsUpcoming);

  try {
    yield put(setUpdateBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.put(`/bookings/${bookingId}`, payload));
    yield put(setUpdateBookingStatus(RequestStatusEnum.SUCCESS));
    if(action.payload.refreshMode==='upcoming'){
      const addLoad: number[] = [...loadingBookingsUpcoming, bookingId]
      yield put(setLoadingBookingsUpcoming(addLoad))
      yield call(fetchBookingsUpcoming);
    }
    if(action.payload.refreshMode==='ongoing'){
      const addLoad: number[] = [...loadingBookingsOngoing, bookingId]
      yield put(setLoadingBookingsOngoing(addLoad))
      yield call(fetchBookingsOngoing);
    }
      
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setUpdateBookingStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}

type UpdateBookingV2Payload = {
  bookingId: number;
  startsAt: string;
  endsAt: string;
  vehicle: number;
  refreshMode: string;
};

export function* updateBookingV2(action: PayloadAction<UpdateBookingV2Payload>) {
  const api = authenticatedClient();
  const { bookingId, startsAt, endsAt, vehicle } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    vehicle,
  };
  // const loadingBookingsOngoing: number[] = yield select(
  //   selectLoadingBookingsOngoing,
  // );
  // const loadingBookingsUpcoming: number[] = yield select(
  //   selectLoadingBookingsUpcoming,
  // );

  try {
    yield put(setErrors(null));
    yield put(setUpdateBookingStatus(RequestStatusEnum.PENDING));
    yield call(() => api.put(`/bookings/${bookingId}/update-booking`, payload));
    yield put(setUpdateBookingStatus(RequestStatusEnum.SUCCESS));
    // if (refreshMode === 'upcoming') {
    //   const addLoad: number[] = [...loadingBookingsUpcoming, bookingId];
    //   yield put(setLoadingBookingsUpcoming(addLoad));
    //   yield call(fetchBookingsUpcomingV2);
    // }
    // if (refreshMode === 'ongoing') {
    //   const addLoad: number[] = [...loadingBookingsOngoing, bookingId];
    //   yield put(setLoadingBookingsOngoing(addLoad));
    //   yield call(fetchBookingsOngoingV2);
    // }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setUpdateBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors(err.response.data));
    }
  }
}

type UpdateBookingEstimatePayload = {
  time: number;
  bookingId: number;
};

export function* updateBookingEstimate(
  action: PayloadAction<UpdateBookingEstimatePayload>,
) {
  const api = authenticatedClient();

  try {
    yield put(setBookingEstimateStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/bookings/${action.payload.bookingId}/extend/estimates`, {
        time: action.payload.time,
      }),
    );
    yield put(setBookingEstimateStatus(RequestStatusEnum.SUCCESS));
    yield put(setUpdateBookingEstimateAmount(data.amount));
  } catch (err) {
    yield put(setBookingEstimateStatus(RequestStatusEnum.ERROR));
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

type FetchBookingEstimatePayload = {
  startsAt: string,
  endsAt: string,
  timezone: string;
  bookingId: number;
};

export function* fetchBookingEstimate(
  action: PayloadAction<FetchBookingEstimatePayload>,
) {
  const api = authenticatedClient();
  const { bookingId, startsAt, endsAt, timezone } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    timezone,
  };
  try {
    yield put(setBookingEstimateStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/bookings/${bookingId}/estimates`, payload),
    );
    yield put(setBookingEstimateStatus(RequestStatusEnum.SUCCESS));
    yield put(setUpdateBookingEstimateAmount(data.total));
  } catch (err) {
    yield put(setBookingEstimateStatus(RequestStatusEnum.ERROR));
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

type ReportPayload = {
  mode: string;
  bookingId: number;
  signature: string;
  reasons: [];
  uploads: {
    cleanliness: File[],
    lowFuel: File[],
    maintenance: File[],
    mechanical: File[],
    missingCards: File[],
    others: File[],
  };
  additionalInformation: string[];
};

export function* reportBooking(
  action: PayloadAction<ReportPayload>,
) {
  const baseUrl = API_URL;
  const formData: any = new FormData();

  formData.append('mode', action.payload.mode);
  formData.append('signature', action.payload.signature);
  action.payload.reasons.map((reason,i)=>
    formData.append(`reasons[${i}]`, reason)
  )
  const reasons:any = action.payload.reasons.map((reason:string)=>reason.toLocaleLowerCase().replaceAll(' ', ''))
  Object.entries(action.payload.additionalInformation).forEach((info)=>{
    const indexReason = reasons.indexOf(info[0].toLocaleLowerCase())
    if(info[1])
      formData.append(`description[${indexReason}]`, info[1])
  })
  let uploadId = 0
  Object.entries(action.payload.uploads).forEach((upload)=>{
      upload[1].forEach((file, i) => {
        formData.append(`uploads[${uploadId}][file]`, file)
        if(i===0 && file){
          uploadId += 1
        }
      })
  })
  
  try {
    yield put(setReportBookingStatus(RequestStatusEnum.PENDING));
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    yield call(() =>
      axios.post(`${baseUrl}/bookings/${action.payload.bookingId}/report`,formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        }
      })
    );
    yield put(setReportBookingStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setReportBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors({ ...err, ...err.response.data?.errors }));
    }
  }
}


type ReportBayTakenPayload = {
  mode: string;
  bookingId: number;
  signature: string;
  reasons: [];
  uploads: {
    bayIsTaken: File[],
    cleanliness: File[],
    lowFuel: File[],
    maintenance: File[],
    mechanical: File[],
    missingCards: File[],
    others: File[],
  };
  additionalInformation: string[];
  vehicle: IReportVehicle;
};

export function* reportBayTakenBooking(
  action: PayloadAction<ReportBayTakenPayload>,
) {
  const baseUrl = API_URL;
  const formData: any = new FormData();
  // const keyUnderlined = (info:string) => info.replace( /([A-Z])/g, " $1" ).split(' ').join('_').toLowerCase();

  formData.append('mode', action.payload.mode);
  formData.append('signature', action.payload.signature);
  action.payload.reasons.map((reason,i)=>
    formData.append(`reasons[${i}]`, reason)
  )
    // formData.append(`reasons[]`, action.payload.reasons)
  
  Object.entries(action.payload.vehicle).forEach((data)=>{
    if(data[1]){
      const dashed = data[0].replace(/[A-Z]/g, m => "-" + m.toLowerCase());
      formData.append(`vehicle[${dashed}]`, data[1])
    }
  })

  const reasons:any = action.payload.reasons.map((reason:string)=>reason.toLocaleLowerCase().replaceAll(' ', ''))
  Object.entries(action.payload.additionalInformation).forEach((info)=>{
    const indexReason = reasons.indexOf(info[0].toLocaleLowerCase())
    if(info[1])
      formData.append(`description[${indexReason}]`, info[1])
  })
  // var uploadIndex = -1
  let uploadId = 0
  Object.entries(action.payload.uploads).forEach((upload)=>{
      upload[1].forEach((file, i) => {
        // uploadIndex = file && i===0 ? uploadIndex+1 : uploadIndex; 
        formData.append(`uploads[${uploadId}][file]`, file)
        if(i===0 && file){
          uploadId += 1
        }
      })
  })
  
  
  try {
    yield put(setReportBookingStatus(RequestStatusEnum.PENDING));
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    yield call(() =>
      axios.post(`${baseUrl}/bookings/${action.payload.bookingId}/report`,formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        }
      })
    );
    yield put(setReportBookingStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setReportBookingStatus(RequestStatusEnum.FAILED));
      yield put(setErrors({ ...err, ...err.response.data?.errors }));
    }
  }
}


type ReportBookingUrlPayload = {
  mode: string;
  bookingId: number;
};

export function* fetchReportBookingUrl(
  action: PayloadAction<ReportBookingUrlPayload>,
) {
  const api = authenticatedClient();
  try {
    const {data} = yield call(() =>
      api.get(`/bookings/${action.payload.bookingId}/report-link?mode=${action.payload.mode}`)
    );
    yield put(setReportBookingUrl(data));

  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

type CancellationFeesPayload = {
  bookingId: number;
};

export function* fetchCancellationFees(
  action: PayloadAction<CancellationFeesPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setCancellationFeesStatus(RequestStatusEnum.PENDING));

    const {data} = yield call(() =>
      api.get(`/bookings/${action.payload.bookingId}/cancellation-fees`)
    );
    yield put(setCancellationFees(data));
    yield put(setCancellationFeesStatus(RequestStatusEnum.SUCCESS));

  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setCancellationFeesStatus(RequestStatusEnum.FAILED));
    }
  }
}

type CancellationFeesV2Payload = {
  bookingId: number;
};

export function* fetchCancellationFeesV2(
  action: PayloadAction<CancellationFeesV2Payload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setCancellationFeesStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.get(`/bookings/${action.payload.bookingId}/cancellation-fee-details`),
    );
    yield put(setCancellationFees(data));
    yield put(setCancellationFeesStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setCancellationFeesStatus(RequestStatusEnum.FAILED));
    }
  }
}

type FetchAvailableVehiclesPayload = {
  bookingId: number;
  startsAt: string;
  endsAt: string;
  timezone: string;
};

export function* fetchAvailableVehicles(
  action: PayloadAction<FetchAvailableVehiclesPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setFetchAvailableVehiclesStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.get(
        `/bookings/${action.payload.bookingId}/available-vehicle-details?starts_at=${action.payload.startsAt}&ends_at=${action.payload.endsAt}`,
      ),
    );
    yield put(setFetchAvailableVehicles(data));
    yield put(setFetchAvailableVehiclesStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setFetchAvailableVehiclesStatus(RequestStatusEnum.FAILED));
    }
  }
}

type FetchRescheduleDetailsPayload = {
  bookingId: number;
};

export function* fetchRescheduleDetails(
  action: PayloadAction<FetchRescheduleDetailsPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setRescheduleDataStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(`/bookings/${action.payload.bookingId}/reschedule-details`),
    );
    yield put(setRescheduleData(data));
    yield put(setRescheduleDataStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRescheduleDataStatus(RequestStatusEnum.FAILED));
    }
  }
}

type UpdateBookingPaymentCardPayload = {
  bookingId: number;
  paymentAccountId: number;
};

export function* updateBookingPaymentCard(
  action: PayloadAction<UpdateBookingPaymentCardPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setUpdateBookingStatus(RequestStatusEnum.PENDING));
    yield put(setPendingCardUrl(true));
    yield call(() =>
      api.post(
        `/bookings/${action.payload.bookingId}/update-payment-method/${action.payload.paymentAccountId}`,
        { _method: 'PUT' },
      ),
    );
    yield call(fetchMe);
    yield put(setPendingCardUrl(false));
    yield put(setUpdateBookingStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setError(err.response));
    }
    yield put(setPendingCardUrl(false));
    yield put(setUpdateBookingStatus(null));
  }
}
