import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  createBooking,
  fetchBookings,
  fetchBookingsPast,
  fetchBookingsUpcoming,
  fetchInvoiceList,
  fetchVehicleEstimation,
  updateBookingStatus,
  fetchInvoiceDetail,
  fetchVehicleCategories,
  fetchLocationFormattedAddress,
  cancelBooking,
  cancelBookingV2,
  fetchBookingSummaryModify,
  updateBooking,
  updateBookingV2,
  updateBookingEstimate,
  fetchBookingEstimate,
  createBookingBusiness,
  fetchBookingsOngoing,
  reportBooking,
  fetchReportBookingUrl,
  reportBayTakenBooking,
  fetchCancellationFees,
  createBookingV2,
  createBookingBusinessV2,
  fetchAvailableVehicles,
  fetchCancellationFeesV2,
  fetchRescheduleDetails,
  fetchBookingsUpcomingV2,
  fetchBookingsOngoingV2,
  fetchBookingsPastV2,
  updateBookingPaymentCard,
} from './sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS, fetchBookings),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS_UPCOMING, fetchBookingsUpcoming),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS_PAST, fetchBookingsPast),
  takeLatest(SAGA_ACTIONS.UPDATE_BOOKING_STATUS, updateBookingStatus),
  takeLatest(SAGA_ACTIONS.CANCEL_BOOKING, cancelBooking),
  takeLatest(SAGA_ACTIONS.CANCEL_BOOKING_V2, cancelBookingV2),
  takeLatest(SAGA_ACTIONS.CREATE_BOOKING, createBooking),
  takeLatest(SAGA_ACTIONS.FETCH_VEHICLE_ESTIMATION, fetchVehicleEstimation),
  takeLatest(SAGA_ACTIONS.FETCH_INVOICE_LIST, fetchInvoiceList),
  takeLatest(SAGA_ACTIONS.FETCH_INVOICE_DETAILS, fetchInvoiceDetail),
  takeLatest(SAGA_ACTIONS.FETCH_VEHICLE_CATEGORIES, fetchVehicleCategories),
  takeLatest(
    SAGA_ACTIONS.FETCH_LOCATION_FORMATTED_ADDRESS,
    fetchLocationFormattedAddress,
  ),
  takeLatest(
    SAGA_ACTIONS.FETCH_BOOKING_SUMMARY_MODIFY,
    fetchBookingSummaryModify,
  ),
  takeLatest(SAGA_ACTIONS.UPDATE_BOOKING, updateBooking),
  takeLatest(SAGA_ACTIONS.UPDATE_BOOKING_V2, updateBookingV2),
  takeLatest(SAGA_ACTIONS.UPDATE_BOOKING_ESTIMATE, updateBookingEstimate),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKING_ESTIMATE, fetchBookingEstimate),
  takeLatest(SAGA_ACTIONS.CREATE_BOOKING_BUSINESS, createBookingBusiness),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS_ONGOING, fetchBookingsOngoing),
  takeLatest(SAGA_ACTIONS.REPORT_BOOKING, reportBooking),
  takeLatest(SAGA_ACTIONS.REPORT_BAY_TAKEN_BOOKING, reportBayTakenBooking),
  takeLatest(SAGA_ACTIONS.FETCH_REPORT_BOOKING_URL, fetchReportBookingUrl),
  takeLatest(SAGA_ACTIONS.FETCH_CANCELLATION_FEES, fetchCancellationFees),
  takeLatest(SAGA_ACTIONS.FETCH_CANCELLATION_FEES_V2, fetchCancellationFeesV2),
  takeLatest(SAGA_ACTIONS.CREATE_BOOKING_V2, createBookingV2),
  takeLatest(SAGA_ACTIONS.CREATE_BOOKING_BUSINESS_V2, createBookingBusinessV2),
  takeLatest(SAGA_ACTIONS.FETCH_AVAILABLE_VEHICLES, fetchAvailableVehicles),
  takeLatest(SAGA_ACTIONS.FETCH_RESCHEDULE_DETAILS, fetchRescheduleDetails),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS_UPCOMING_V2, fetchBookingsUpcomingV2),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS_ONGOING_V2, fetchBookingsOngoingV2),
  takeLatest(SAGA_ACTIONS.FETCH_BOOKINGS_PAST_V2, fetchBookingsPastV2),
  takeLatest(SAGA_ACTIONS.UPDATE_BOOKING_PAYMENT_CARD, updateBookingPaymentCard),
];

export default actions;
