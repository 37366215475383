import React, { useRef, useState } from 'react';
import { useClickAway, useEffectOnce } from 'react-use';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import classNames from 'classnames';

import flexicarLogo from 'assets/images/flexicar_logo.svg';
import { ReactComponent as IconFaq } from 'assets/images/dashboard_faq.svg';
import { ReactComponent as IconBooking } from 'assets/images/app_nav_booking.svg';
import { ReactComponent as IconAccount } from 'assets/images/app_nav_account.svg';
import { ReactComponent as IconTrips } from 'assets/images/app_nav_trips.svg';
import { ReactComponent as WalletIcon } from 'assets/images/wallet.svg';
import { IMAGE_URL } from 'common/constants';
import { RequestStatusEnum } from 'common/types';
import { useRedirectToNoAuth } from 'hooks/useRedirect';
import {
  selectLoginModalOpen,
  setDebugMode,
  setLoginModalOpen,
} from 'modules/auth/slice';
import {
  selectSwitchAccountStatus,
  selectUser,
  selectUserPhoto,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import useLogout from 'hooks/useLogout';
import LoginForm from 'features/LoginForm';
import Modal from 'components/Modal';

export const HomeLogo = () => {
  return (
    <Link to="/?reload=true">
      <div className="mx-auto w-[125px] md:ml-0 md:mr-auto">
        <img
          src={`${IMAGE_URL}${flexicarLogo}`}
          alt="logo"
          className="object-contain"
        />
      </div>
    </Link>
  );
};

export const GuestTopNav = () => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const isLoginModalOpen = useAppSelector(selectLoginModalOpen);
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [isPublicPage, setIsPublicPage] = useState(true);
  const modalRef = useRef(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  if (pathname === '/ocr/done' || pathname === '/ocr/loading') {
    setIsPublicPage(false);
  } else {
    setIsPublicPage(true);
  }


  useClickAway(modalRef, () => {
    setIsBurgerActive(false);
  });

  return (
    <div className="relative z-[100] block w-full">
      <nav
        className={classNames(
          'top-0 left-0 z-[100] flex h-[110px] w-full justify-between bg-[#F2F2F2] px-[20px] sm:px-[100px]',
          {
            'bg-white': isPublicPage,
          },
        )}
      >
        {!isPublicPage ? (
          <div className="grid w-[125px] place-items-center">
            <img
              src={`${IMAGE_URL}${flexicarLogo}`}
              alt="logo"
              className="object-contain"
            />
          </div>
        ) : (
          <div></div>
        )}

        <div
          className="hidden flex-col gap-[7px] self-center"
          onClick={() => setIsBurgerActive(!isBurgerActive)}
        >
          <span className="h-[3px] w-[35px] rounded-full bg-[#282828]"></span>
          <span className="h-[3px] w-[35px] rounded-full bg-[#282828]"></span>
          <span className="h-[3px] w-[35px] rounded-full bg-[#282828]"></span>
        </div>
        <ul
          className={classNames(
            'absolute top-[75px] left-0 hidden h-auto w-screen flex-col gap-[25px] self-center bg-white py-[25px] pl-[25px] text-sm font-bold shadow-md md:hidden md:h-[22px] md:flex-row md:justify-center md:gap-[40px] md:bg-transparent md:shadow-none',
            {
              '!flex': isBurgerActive,
            },
          )}
        >
          <li onClick={() => push('/register-v2')}>
            Sign Up
          </li>
          <li onClick={() => dispatch(setLoginModalOpen(true))}>
            Sign In
          </li>
        </ul>
        <div className="hidden w-fit items-center space-x-2 sm:space-x-5 md:flex">
          <button
            onClick={() => push("/register-v2?reload=true")}
            className="btn btn-primary h-[40px]  min-h-[40px] rounded-full border-transparent px-[15px] text-[12px] normal-case md:min-h-[50px] md:px-[35px] md:text-[14px]"
          >
            Sign Up
          </button>
          <button
            onClick={() => dispatch(setLoginModalOpen(true))}
            className="btn btn-primary h-[40px]  min-h-[40px] rounded-full border-transparent px-[15px] text-[12px] normal-case md:min-h-[50px] md:px-[35px] md:text-[14px]"
          >
            Sign In
          </button>
        </div>

        <Modal
          width={isMobile ? '' : 'w-[490px]'}
          maxWidth={isMobile ? '' : '490px'}
          hasCloseButton={false}
          isModalOpen={isLoginModalOpen}
          handleClose={() => dispatch(setLoginModalOpen(false))}
          bgColor="bg-flexi-white-1"
          px={isMobile ? 'px-[25px]' : '!px-[60px]'}
          mx={isMobile ? '30px' : '0px'}
        >
          <LoginForm />
        </Modal>
      </nav>
    </div>
  );
};

export const SignupTopNav = () => {
  const dispatch = useAppDispatch();

  const isLoginModalOpen = useAppSelector(selectLoginModalOpen);
  
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  return (
    <div className="!md:h-[110px] z-[100] ml-auto hidden w-full sm:block">
      <nav className="md:py-auto top-0 left-0 z-[100] flex w-full items-center justify-between bg-[#F2F2F2] py-[20px] px-[20px] md:h-[110px] md:px-[100px] ">
        <Link to="/?reload=true" className=" grid w-[125px] place-items-center">
          <img
            src={`${IMAGE_URL}${flexicarLogo}`}
            alt="logo"
            className="object-contain"
          />
        </Link>
        <button
          onClick={() => dispatch(setLoginModalOpen(true))}
          className="btn btn-primary rounded-full  border-transparent px-[35px] normal-case"
        >
          Sign In
        </button>
        <Modal
          width={isMobile ? '' : 'w-[490px]'}
          maxWidth={isMobile ? '' : '490px'}
          hasCloseButton={false}
          isModalOpen={isLoginModalOpen}
          handleClose={() => dispatch(setLoginModalOpen(false))}
          bgColor="bg-flexi-white-1"
          px={isMobile ? 'px-[25px]' : '!px-[60px]'}
          mx={isMobile ? '30px' : '0px'}
        >
          <LoginForm />
        </Modal>
      </nav>
    </div>
  );
};

export const AuthenticatedTopNav = () => {
  const user = useAppSelector(selectUser);
  const { pathname } = useLocation();
  const [profileDropDown, setProfileDropDown] = useState<boolean>(false);
  const { logout } = useLogout();
  const photo = useAppSelector(selectUserPhoto);
  useRedirectToNoAuth();

  return (
    <div className="relative z-[100] block w-full">
      <nav className=" top-0 left-0 z-[100] flex h-[110px] w-full justify-between bg-[#F2F2F2] px-[20px] sm:px-[100px] ">
        <div className=" grid w-[125px] place-items-center">
          <img
            src={`${IMAGE_URL}${flexicarLogo}`}
            alt="logo"
            className={classNames('object-contain', {
              'pointer-events-none': pathname !== '/app/booking',
            })}
          />
        </div>

        <div className="absolute right-[20px] top-[50%] flex translate-y-[-50%] items-center space-x-5 sm:right-[75px]">
          {user && (
            <div className="hidden pl-[15px] font-bold capitalize sm:flex">
              <p className="max-w-[75px] truncate">{user.firstname}</p>
              &nbsp;|&nbsp;
              <p>{user?.type?.name}</p>
            </div>
          )}
          <div
            className="relative flex cursor-default items-center gap-5 pl-4"
            onMouseOver={() => setProfileDropDown(true)}
            onMouseLeave={() => setProfileDropDown(false)}
          >
            <div className="flex cursor-default items-center gap-7">
              <div className="flex cursor-default items-center gap-5">
                {photo ? (
                  <img
                    src={photo}
                    className="block h-[40px] w-[40px] rounded-full border-[1px] border-flexi-black-f7 object-cover"
                  />
                ) : (
                  <span className="grid h-[40px] w-[40px] place-items-center rounded-full bg-flexi-orange-1 text-[16px] text-white ">
                    {user?.fullname[0]}
                  </span>
                )}
              </div>
            </div>
            <div
              className={classNames(
                'absolute top-[100%] left-[-110px] z-[9999999] flex h-0 w-full  min-w-[170px] flex-col items-start overflow-hidden rounded-md bg-transparent text-sm duration-[350ms]',
                {
                  '!h-[85px]': profileDropDown,
                },
              )}
            >
              <span className="absolute top-[15px] right-[15px] z-[1]   h-[20px] w-[20px] rotate-45 bg-[#F9F9F9]  shadow-[-1px_-1px_5px] shadow-[#000000]/[.07]" />
              <div
                className={classNames(
                  'z-[2] mt-[25px] flex min-h-[65px] w-full flex-col items-start justify-center rounded-lg bg-[#F9F9F9] shadow-lg',
                  {},
                )}
              >
                {/* Logout CTA */}
                <button
                  className=" flex h-fit w-auto items-center justify-center gap-2 px-3 font-bold text-zinc-600  hover:underline"
                  onClick={logout}
                >
                  <div className="grid h-auto w-auto place-items-center p-2">
                    <RiLogoutBoxRLine
                      style={{ fontSize: '15px', color: '' }}
                      className="text-[#282828]"
                    />
                  </div>
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export const ApprovedAuthenticatedTopNav = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const switchAccountStatus = useAppSelector(selectSwitchAccountStatus);
  const [profileDropDown, setProfileDropDown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout } = useLogout();
  const photo = useAppSelector(selectUserPhoto);
  const { isBusinessManagerAccount } = useBusiness();
  const { push } = useHistory();
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const isPersonalAccount =
    (user?.type.name.toLocaleLowerCase() === 'personal' ||
      user?.type.name.toLocaleLowerCase() === 'student') &&
    user.profiles.length === 1;
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const { pathname } = useLocation();

  const handleSwitchAccount = (id: number) => {
    dispatch({ type: ACTIONS.SWITCH_ACCOUNT, payload: { id } });
  };

  useEffectOnce(() => {
    if (photo !== undefined) dispatch({ type: ACTIONS.GET_PHOTO });
  });

  const handleLogout = () => {
    logout();
  };

  const handleDoubleClick = () => {
    dispatch(setDebugMode(true));
  };

  const modalRef = useRef(null);
  useClickAway(modalRef, () => {
    setIsBurgerActive(false);
  });

  return (
    <>
      {pathname !== '/app/account/invoices/invoice/' && (
        <div className="z-[60] block w-full sticky top-0">
          <nav
            ref={modalRef}
            className="overlay top-0 left-0 z-[10000] flex  w-full justify-between bg-[#F2F2F2] py-[20px]  px-[20px] align-middle text-[12px] md:min-h-[110px] md:py-[30px] md:px-[75px]"
          >
            <div
              className={classNames(
                'absolute grid h-[40px] w-[125px] cursor-pointer place-items-center self-center',
                {
                  'pointer-events-none': pathname === '/app/booking',
                },
              )}
              onClick={() => push('/app/booking')}
            >
              <img
                src={`${IMAGE_URL}${flexicarLogo}`}
                alt="logo"
                className="object-contain"
              />
            </div>
            <div
              className="ml-auto flex flex-col gap-[7px] self-center md:hidden"
              onClick={() => setIsBurgerActive(!isBurgerActive)}
            >
              <span className="h-[3px] w-[35px] rounded-full bg-[#282828]"></span>
              <span className="h-[3px] w-[35px] rounded-full bg-[#282828]"></span>
              <span className="h-[3px] w-[35px] rounded-full bg-[#282828]"></span>
            </div>
            <div
              className={classNames(
                'absolute right-[10%] top-[50%] z-[10000] mx-auto hidden h-auto w-[50vw] flex-col items-center gap-[25px] self-center bg-white py-[25px] px-[25px] text-sm font-bold shadow-md md:static md:flex md:h-[22px] md:flex-row md:justify-center md:gap-[40px] md:bg-transparent md:shadow-none',
                {
                  '!flex': isBurgerActive,
                },
              )}
            >
              {/* <NavLink
                to="/app/dashboard"
                className="flex items-center gap-2"
                activeClassName=" text-primary"
              >
                <IconMyFlexi />
                <AvailabilityIcon className="hidden" />
                <span>My Flexi</span>
              </NavLink> */}
              <div className="z-[1] flex h-auto w-auto flex-col gap-y-[25px] md:h-[50px] md:flex-row md:gap-x-[25px] md:bg-[#f2f2f2]">
                <NavLink
                  to="/app/booking"
                  className="flex items-center gap-2 text-[12px] text-zinc-600 md:text-black"
                  activeClassName="text-primary [&>.icon]:fill-flexi-orange-1"
                  onClick={() => setIsBurgerActive(false)}
                >
                  <IconBooking className="icon h-[20px] md:h-[30px]" />

                  <span>Book Now</span>
                </NavLink>
                <NavLink
                  to="/app/my-bookings"
                  className="flex items-center gap-2 text-[12px] text-zinc-600 md:text-black"
                  activeClassName="text-primary [&>.icon]:fill-flexi-orange-1"
                  onClick={() => setIsBurgerActive(false)}
                >
                  {' '}
                  <IconTrips className="icon md:hh-[30px] h-[20px]" />
                  <span>My Bookings</span>
                </NavLink>
                <NavLink
                  to="/app/account"
                  className="flex items-center gap-2 text-[12px] text-zinc-600 md:text-black"
                  activeClassName="text-primary [&>.icon]:fill-flexi-orange-1"
                  onClick={() => setIsBurgerActive(false)}
                >
                  <IconAccount className="icon md:hh-[30px] h-[20px]" />
                  <span>
                    {' '}
                    My {isBusinessManagerAccount ? 'Business' : 'Account'}
                  </span>
                </NavLink>
                <NavLink
                  to="/faq/main"
                  className={classNames("flex items-center gap-2 text-[12px] text-zinc-600 md:text-black", {
                    'text-primary [&>.icon]:fill-flexi-orange-1': pathname.includes('/faq')
                  })}
                  activeClassName="text-primary [&>.icon]:fill-flexi-orange-1"
                  onClick={() => setIsBurgerActive(false)}
                >
                  <IconFaq
                    fill='black'
                    className="icon h-[20px] md:h-[30px]"
                  />

                  <span> Faqs</span>
                </NavLink>
              </div>

              {(!isBusinessManagerAccount || !isPersonalAccount) && (
                <button
                  className="flex items-center gap-2 text-[12px] text-zinc-600 md:hidden md:text-black"
                  onClick={() => {
                    setIsModalOpen(true);
                    setIsBurgerActive(false);
                  }}
                >
                  <div className="grid h-auto w-auto place-items-center">
                    <HiOutlineSwitchHorizontal
                      style={{ fontSize: '20px' }}
                      className="h-[20px] text-black"
                    />
                  </div>
                  Switch Account
                </button>
              )}

              <button
                className="flex items-center gap-2 text-[12px] text-zinc-600 md:hidden md:text-black"
                onClick={() => {
                  handleLogout();
                  setIsBurgerActive(false);
                }}
              >
                <div className="grid h-auto w-auto place-items-center">
                  <RiLogoutBoxRLine
                    style={{ fontSize: '20px', color: '' }}
                    className="h-[20px] text-black"
                  />
                </div>
                Sign out
              </button>
            </div>

            <div
              className="absolute right-[75px] top-[50%] ml-auto hidden h-[50px] translate-y-[-50%] items-center gap-[16px] md:flex"
              onDoubleClick={handleDoubleClick}
            >
              <div className="hidden text-right lg:block">
                {user && (
                  <div className="hidden pl-[15px] text-[16px] font-bold capitalize xl:flex">
                    <p className="max-w-[75px] truncate">{user.firstname}</p>
                    &nbsp;|&nbsp;
                    <p>{user?.type?.name}</p>
                  </div>
                )}
                {user?.redirectTo === 'app' && (
                  <div className="relative mt-[3px] flex">
                    <article className=" ml-auto flex h-full items-center gap-2">
                      <WalletIcon />
                      <p className="text-[12px] font-bold">
                        ${user?.availableCredits.toFixed(2)}
                      </p>
                    </article>
                  </div>
                )}
              </div>
              <span className="hidden h-[50px] w-[1px] bg-gray-200 lg:block" />
              <div
                className="relative flex cursor-default items-center gap-5"
                onMouseOver={() => setProfileDropDown(true)}
                onMouseLeave={() => setProfileDropDown(false)}
              >
                <div
                  className={classNames(
                    'relative grid  place-items-center rounded-full bg-flexi-black-5 text-[60px] text-flexi-black-4',
                    {
                      '!bg-transparent shadow-[0_0_0_1px] shadow-flexi-orange-1':
                        photo,
                    },
                  )}
                >
                  {photo ? (
                    <img
                      src={photo}
                      className="block max-h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] rounded-full object-cover"
                    />
                  ) : (
                    <span className="grid max-h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] place-items-center rounded-full bg-flexi-orange-1 text-[16px] text-white ">
                      {user?.fullname[0]}
                    </span>
                  )}
                </div>
                <div
                  className={classNames(
                    'absolute top-[100%] left-[-110px] !z-[9999999] flex h-0 w-full  min-w-[170px] flex-col items-start overflow-hidden rounded-md bg-transparent text-sm duration-[350ms]',
                    {
                      '!h-[165px]': profileDropDown,
                      '!h-[85px]':
                        (isBusinessManagerAccount || isPersonalAccount) &&
                        profileDropDown,
                    },
                  )}
                >
                  <span className="absolute top-[15px] right-[15px] z-[1]   h-[20px] w-[20px] rotate-45 bg-[#F9F9F9]  shadow-[-1px_-1px_5px] shadow-[#000000]/[.07]" />
                  <div
                    className={classNames(
                      'z-[2] mt-[25px] flex min-h-[145px] w-full flex-col items-start justify-center rounded-lg bg-[#F9F9F9]  shadow-md',
                      {
                        '!min-h-[65px]':
                          !user ||
                          isBusinessManagerAccount ||
                          isPersonalAccount,
                      },
                    )}
                  >
                    {/* Switch account CTA */}
                    {user && !isBusinessManagerAccount && !isPersonalAccount && (
                      <button
                        className="mb-3 flex h-fit w-auto items-center justify-center gap-2 border-b-[1px] border-[#D8D8D8]/20 px-3 pb-3 font-bold text-zinc-600 hover:underline"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <div className="grid h-auto w-auto place-items-center  p-2">
                          <HiOutlineSwitchHorizontal
                            style={{ fontSize: '15px' }}
                            className="text-[#282828]"
                          />
                        </div>
                        Switch Account
                      </button>
                    )}

                    {/* Logout CTA */}
                    <button
                      className=" flex h-fit w-auto items-center justify-center gap-2 px-3 font-bold text-zinc-600  hover:underline"
                      onClick={handleLogout}
                    >
                      <div className="grid h-auto w-auto place-items-center p-2">
                        <RiLogoutBoxRLine
                          style={{ fontSize: '15px', color: '' }}
                          className="text-[#282828]"
                        />
                      </div>
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              width={isMobile ? '75%' : '600px'}
              maxWidth={isMobile ? '75%' : '600px'}
              isModalOpen={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              bgColor="bg-flexi-white-1"
              px={isMobile ? 'px-[25px]' : ''}
              mx={isMobile ? '30px' : ''}
            >
              <h1 className="mb-[40px] text-center text-[20px] font-bold md:text-[34px]">
                My Accounts
              </h1>
              {switchAccountStatus === RequestStatusEnum.PENDING}
              {/* {switchAccountStatus === RequestStatusEnum.PENDING ? (
              <PendingLoader />
            ) : ( */}
              <>
                <ul className="flex flex-col gap-[35px]">
                  {user?.profiles.map((profile, i) => (
                    <li
                      className={classNames(
                        'relative flex items-center gap-[20px]',
                        {
                          'cursor-default': profile.default,
                        },
                      )}
                      key={profile.id}
                      role="button"
                      onClick={() =>
                        profile.default !== 1 &&
                        switchAccountStatus !== RequestStatusEnum.PENDING &&
                        handleSwitchAccount(profile.id)
                      }
                    >
                      {!profile.default && (
                        <div className="absolute left-[50%] h-[125%] w-[115%] translate-x-[-50%] rounded-full border-[5px] border-transparent duration-200 hover:border-flexi-orange-2"></div>
                      )}
                      {!profile.default &&
                        switchAccountStatus === RequestStatusEnum.PENDING && (
                          <div className="absolute left-[50%] flex h-[125%] w-[115%] translate-x-[-50%] cursor-default items-center justify-center gap-x-[10px] self-center overflow-hidden  rounded-full duration-500">
                            <p className="z-[1] text-[14px] font-bold">
                              Switching
                            </p>
                            <span className="z-[1] h-[15px] w-[15px] animate-spin rounded-full border-[2px] border-flexi-orange-1 border-t-transparent " />
                            <div className="absolute z-[0] h-full w-full duration-500 ">
                              <span className="absolute z-[0] h-full w-full animate-pulse bg-gray-200/70 duration-500" />
                            </div>
                          </div>
                        )}
                      {photo ? (
                        <img
                          src={photo}
                          className="pointer-events-none grid h-[40px] w-[40px] place-items-center rounded-full bg-flexi-black-7 object-cover text-[40px] font-bold uppercase text-flexi-black-4 md:max-h-[80px] md:min-h-[80px] md:min-w-[80px] md:max-w-[80px]"
                        />
                      ) : (
                        <span className="pointer-events-none grid h-[40px] min-h-[40px] w-[40px] min-w-[40px] place-items-center rounded-full bg-flexi-black-7 text-[22px] font-bold uppercase text-flexi-black-4 md:h-[40px] md:max-h-[80px] md:min-h-[80px] md:w-[40px] md:min-w-[80px] md:max-w-[80px] md:text-[40px]">
                          {user?.firstname.charAt(0)}
                        </span>
                      )}
                      <div className="pointer-events-none flex w-[300px] flex-col gap-[2px] truncate overflow-ellipsis md:gap-[5px]">
                        <p className="text-[16px] font-bold md:text-[18px] ">
                          {user?.fullname}
                        </p>
                        {profile.type === 'business' ? (
                          <p className="truncate">{profile.refName}</p>
                        ) : (
                          <p className="text-[14px] capitalize md:text-[16px]">
                            {profile.type} account
                          </p>
                        )}
                      </div>
                      <div className="flex w-fit flex-1 justify-end">
                        {/* {profile.default === 1 ? (
                          <div className='h-[25px] w-[25px] grid relative place-items-center rounded-full overflow-hidden bg-[#dddddd]'>
                          <span className="z-[1] h-full w-full translate-y-[50%] bg-flexi-orange-1" />
                          <span className="z-[2] h-[35%] w-[35%] absolute rounded-full  bg-white" />
                          </div>
                        ) : (
                          <div className='h-[25px] w-[25px] grid relative place-items-center rounded-full overflow-hidden bg-[#dddddd]'>
                          <span className="z-[1] h-full w-full translate-y-[50%] bg-flexi-orange-1" />
                          <span className="z-[2] h-[35%] w-[35%] absolute rounded-full  bg-white" />
                          </div>
                        )} */}

                        <div className="relative grid h-[25px] w-[25px] place-items-center overflow-hidden rounded-full bg-[#dddddd]">
                          <span
                            className={classNames(
                              'z-[1] h-full w-full bg-flexi-orange-1 duration-[500ms]',
                              {
                                // '': prof
                                'ease-in': profile.default,
                                'ease-out ': !profile.default,
                                '!translate-y-[0%] ':
                                  profile.default && i === 0,
                                '!translate-y-[105%] ':
                                  !profile.default && i === 0,
                                '!translate-y-[-105%]  ':
                                  !profile.default && i === 1,
                              },
                            )}
                          />
                          <span className="absolute z-[2] h-[35%] w-[35%] rounded-full  bg-white" />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {user?.profiles.length === 1 && (
                  <div
                    className="mt-[40px] grid w-fit cursor-pointer  place-items-center self-center rounded-md font-bold"
                    onClick={() => {
                      setIsModalOpen(false);
                      push('/app/add-account');
                    }}
                  >
                    <p className="text-[14px] underline md:text-[16px]">
                      Add an account
                    </p>
                  </div>
                )}
              </>
              {/* )} */}
            </Modal>
          </nav>
        </div>
      )}
    </>
  );
};
