import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IBilling, RequestStatusEnum } from 'common/types';

type InitialState = {
  pendingCardUrl: boolean;
  pendingPayment: boolean;
  pendingBilling: boolean;
  windcaveCardUrl: string;
  windcaveCardStatus: RequestStatusEnum | null;
  windcavePaymentUrl: string;
  billing: IBilling | null;
  error: string | null;
  errorPromo: any;
  pendingPromo: boolean;
  promoSuccess: boolean;
  bookingPromo: string | null;
  applyPromoStatus: RequestStatusEnum | null;
  removePromoStatus: RequestStatusEnum | null;
};

const initialState: InitialState = {
  pendingCardUrl: false,
  pendingPayment: false,
  pendingBilling: false,
  windcaveCardUrl: '',
  windcaveCardStatus: null,
  windcavePaymentUrl: '',
  billing: null,
  error: null,
  errorPromo: null,
  pendingPromo: false,
  promoSuccess: false,
  bookingPromo: null,
  applyPromoStatus: null,
  removePromoStatus: null,
};

export const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    reset: () => initialState,
    setPendingCardUrl: (state, action: PayloadAction<boolean>) => {
      state.pendingCardUrl = action.payload;
    },
    setPendingPayment: (state, action: PayloadAction<boolean>) => {
      state.pendingPayment = action.payload;
    },
    setPendingBilling: (state, action: PayloadAction<boolean>) => {
      state.pendingBilling = action.payload;
    },
    setWindcaveCardUrl: (state, action: PayloadAction<string>) => {
      state.windcaveCardUrl = action.payload;
    },
    setWindcaveCardStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.windcaveCardStatus = action.payload;
    },
    setWindcavePaymentUrl: (state, action: PayloadAction<string>) => {
      state.windcavePaymentUrl = action.payload;
    },
    setBilling: (state, action: PayloadAction<IBilling | null>) => {
      state.billing = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setErrorPromo: (state, action: PayloadAction<any>) => {
      state.errorPromo = action.payload;
    },
    setPendingPromo: (state, action: PayloadAction<boolean>) => {
      state.pendingPromo = action.payload;
    },
    setPromoSuccess: (state, action: PayloadAction<boolean>) => {
      state.promoSuccess = action.payload;
    },
    setBookingPromo: (state, action: PayloadAction<string | null>) => {
      state.bookingPromo = action.payload;
    },
    setApplyPromoStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.applyPromoStatus = action.payload;
    },
    setRemovePromoStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.removePromoStatus = action.payload;
    },
  },
});

export const {
  reset,
  setPendingCardUrl,
  setPendingPayment,
  setPendingBilling,
  setWindcaveCardUrl,
  setWindcaveCardStatus,
  setWindcavePaymentUrl,
  setBilling,
  setError,
  setErrorPromo,
  setPendingPromo,
  setPromoSuccess,
  setBookingPromo,
  setApplyPromoStatus,
  setRemovePromoStatus
} = slice.actions;

export const selectPendingCardUrl = (state: RootState): boolean =>
  state.transaction.pendingCardUrl;
export const selectPendingPayment = (state: RootState): boolean =>
  state.transaction.pendingPayment;
export const selectPendingBilling = (state: RootState): boolean =>
  state.transaction.pendingBilling;
export const selectWindcaveCardUrl = (state: RootState): string =>
  state.transaction.windcaveCardUrl;
export const selectWindcaveCardStatus = (
  state: RootState,
): RequestStatusEnum | null => state.transaction.windcaveCardStatus;
export const selectWindcavePaymentUrl = (state: RootState): string =>
  state.transaction.windcavePaymentUrl;
export const selectBilling = (state: RootState): IBilling | null =>
  state.transaction.billing;
export const selectError = (state: RootState): string | null =>
  state.transaction.error;
export const selectErrorPromo = (state: RootState): any =>
  state.transaction.errorPromo;
export const selectPendingPromo = (state: RootState): boolean =>
  state.transaction.pendingPromo;
export const selectPromoSuccess = (state: RootState): boolean =>
  state.transaction.promoSuccess;
export const selectBookingPromo = (state: RootState): string | null =>
  state.transaction.bookingPromo;
export const selectApplyPromoStatus = (
  state: RootState,
): RequestStatusEnum | null => state.transaction.applyPromoStatus;
export const selectRemovePromoStatus = (
  state: RootState,
): RequestStatusEnum | null => state.transaction.removePromoStatus;
export default slice.reducer;
