import React, { Dispatch } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { IInvoice } from 'common/types';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useBusiness from 'hooks/useBusiness';
import BurgerNav from 'components/BurgerNav';
import CustomCheckBox from 'components/CustomCheckbox';

interface Props {
  invoices: IInvoice[];
  handleCheckBoxMulti: (rows: IInvoice[]) => void;
  handleCheckBoxSingle: (e: any, row: IInvoice) => void;
  selectedInvoices: IInvoice[];
  setBurgerActive: Dispatch<number>;
  burgerActive: number;
  paySingleInvoice: (row: IInvoice) => void;
}

const InvoicesTable = ({
  invoices,
  handleCheckBoxMulti,
  handleCheckBoxSingle,
  selectedInvoices,
  setBurgerActive,
  burgerActive,
  paySingleInvoice,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isBusinessManagerAccount } = useBusiness();

  const handleDownloadSingleInvoice = (id: number) => {
    dispatch({
      type: ACTIONS.DOWNLOAD_INVOICE,
      payload: { ids: [id] },
    });
  };

  return (
    <table className="w-full border-collapse">
      <thead className="hidden md:table-header-group">
        <tr className="border-b-[1px] border-[#E0E0E0] bg-white text-left">
          <th className="px-2 py-4">
            <CustomCheckBox titleElement={``}>
              <input
                type="checkbox"
                className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
                onChange={() => {
                  if (selectedInvoices.length === invoices?.length) {
                    handleCheckBoxMulti([]);
                  } else {
                    handleCheckBoxMulti(invoices);
                  }
                }}
                checked={selectedInvoices.length === invoices?.length}
                // @ts-ignore
                style={{ '--chkfg': '0', '--chkbg': '0' }}
              />
            </CustomCheckBox>
          </th>
          <th className="px-2 py-4">Invoice #</th>
          <th className="px-2 py-4">Description</th>
          <th className="px-2 py-4">Date</th>
          <th className="px-2 py-4">Amount</th>
          <th className="px-2 py-4">Status</th>
          <th className="px-4 py-4"></th>
        </tr>
      </thead>
      <tbody>
        {invoices &&
          invoices.map((row: IInvoice, index: number) => (
            <tr
              key={row.id}
              className={classNames(
                ' bg0white border-b-[1px] border-[#E0E0E0]',
                {
                  'bg-[#F7F7F7]': index % 2 === 0,
                },
              )}
            >
              <td className="px-2 py-6">
                <CustomCheckBox>
                  <input
                    type="checkbox"
                    className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
                    onChange={(e) => handleCheckBoxSingle(e, row)}
                    checked={selectedInvoices.includes(row)}
                    // @ts-ignore
                    style={{ '--chkfg': '0', '--chkbg': '0' }}
                  />
                </CustomCheckBox>
              </td>

              <td className="table-cell w-auto px-1 py-4 md:hidden">
                <p className="text-[14px] font-bold">{row.invoiceNumber}</p>
                <p className="text-[12px]">{row.description}</p>
                {isBusinessManagerAccount && (
                  <p className="text-[12px]">{row.driverName}</p>
                )}
                <p className="text-[12px]">
                  {moment(row.createdAt).format('DD/MM/YYYY')}
                </p>
              </td>
              <td className="table-cell px-1 py-4 md:hidden">
                <p className="text-right text-[14px] font-bold">
                  ${row.amount.toFixed(2)}
                </p>
                <p
                  className={classNames(
                    'text-right text-[10px] font-bold uppercase text-flexi-green-1',
                    {
                      'text-red-600':
                        row.statusDesc === 'UNPAID' ||
                        row.statusDesc === 'PARTIAL' ||
                        row.statusDesc === 'FAILED',
                    },
                  )}
                >
                  {row.statusDesc}
                </p>
              </td>

              <td className="hidden px-2 py-6 md:table-cell">
                {row.invoiceNumber}
              </td>

              <td className="hidden px-2 py-6 md:table-cell">
                <p>{row.description}</p>
                {isBusinessManagerAccount && (
                  <p className="text-[14px] text-[#7d7d7d]">{row.driverName}</p>
                )}
              </td>

              <td className="hidden px-2 py-6 md:table-cell ">
                {moment(row.createdAt).format('DD/MM/YYYY')}
              </td>

              <td className="hidden px-2 py-6 md:table-cell">
                ${row.amount.toFixed(2)}
              </td>

              <td className="hidden px-2 py-6 md:table-cell">
                <p
                  className={classNames(
                    'grid h-[20px] w-[70px] place-items-center rounded-full bg-zinc-200 text-[10px] font-bold uppercase text-flexi-green-1',
                    {
                      'text-red-600':
                        row.statusDesc === 'UNPAID' ||
                        row.statusDesc === 'PARTIAL' ||
                        row.statusDesc === 'FAILED',
                    },
                  )}
                >
                  {row.statusDesc}
                </p>
              </td>
              <td className="px-0 py-4">
                <div
                  className={classNames(
                    'relative z-[2] grid h-full w-[30px] place-items-center items-center justify-center md:w-[50px] ',
                  )}
                >
                  <BurgerNav
                    setBurgerActive={setBurgerActive}
                    burgerActive={burgerActive}
                    i={index}
                    extraClass="pointer-events-auto"
                  >
                    <p
                      className={classNames(
                        'w-full p-[15px] px-[0px] text-center hover:bg-flexi-black-7',
                      )}
                      onClick={() => handleDownloadSingleInvoice(row.id)}
                    >
                      Download
                    </p>
                    <p
                      className={classNames(
                        'w-full p-[15px] px-[0px] text-center hover:bg-flexi-black-7',
                        {
                          hidden:
                            row.statusDesc === 'PAID' ||
                            row.statusDesc === 'CANCELLED' ||
                            row.statusDesc === 'ISSUED',
                        },
                      )}
                      onClick={() => {
                        if (row) paySingleInvoice(row);
                      }}
                    >
                      Pay
                    </p>
                  </BurgerNav>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default InvoicesTable;
