import React from 'react';

import { setErrors } from 'modules/booking/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import OnboardingFormEntries from './OnboardingForm';
import OffboardingFormEntries from './OffboardingForm';

interface Props {
  offboarding: boolean;
}

type SavedValues = {
  categories: string[] | null;
  uploads: File[];
  remarks: string;
  bayisTaken: boolean;
  bayInfo: string;
  registrationPlate: string;
  parkingType: string;
  model: string;
  color: string;
  isParkingNearby: boolean;
  isUnderstood: boolean;
  detailedDescription: string;
  textValue: {};
};

const FormEntries = ({ offboarding }: Props) => {
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(location.search);

  const onSave = (values: SavedValues) => {
    const bookingId: any = params.get('booking_id') || 0;
    dispatch(setErrors(null));

    if (params.get('mode') === 'post') {
      const bayData = {
        reg: values.registrationPlate,
        make: values.model,
        color: values.color,
        info: values.bayInfo,
        parked: values.parkingType,
        detailedDescription: values.detailedDescription,
        isNearby: values.isParkingNearby ? 1 : 0,
      };
      const payload = {
        mode: params.get('mode'),
        bookingId: parseInt(bookingId),
        signature: params.get('signature'),
        reasons: values.categories,
        uploads: values.uploads,
        additionalInformation: values.textValue,
        vehicle: bayData,
      };
      dispatch({ type: ACTIONS.REPORT_BAY_TAKEN_BOOKING, payload });
    } else {
      const payload = {
        mode: params.get('mode'),
        bookingId: parseInt(bookingId),
        signature: params.get('signature'),
        reasons: values.categories,
        uploads: values.uploads,
        additionalInformation: values.textValue,
      };
      dispatch({ type: ACTIONS.REPORT_BOOKING, payload });
    }
  };

  return (
    <section className="flex h-screen flex-col p-[25px]">
      <p className="mb-[10px] text-[20px] font-bold">
        What do you need to report?
      </p>
      <p>Please choose from the following:</p>
      {/* <Header {...{ offboarding }} /> */}
      {offboarding ? (
        <OffboardingFormEntries {...{ onSave }} />
      ) : (
        <OnboardingFormEntries {...{ onSave }} />
      )}
    </section>
  );
};

export default FormEntries;
