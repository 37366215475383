import classNames from 'classnames';
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
// import {ReactComponent as IconClose} from 'assets/images/close.svg';
import iconClose from 'assets/images/close_modal.svg';
import { IMAGE_URL } from 'common/constants';
import useAppSelector from 'hooks/useAppSelector';
import { selectErrorModal, selectForOnboarding, selectLoginError } from 'modules/auth/slice';

interface Props {
  children: React.ReactNode;
  isModalOpen: boolean;
  handleClose: () => void;
  width?: string;
  maxWidth?: string;
  enableClickAway?: boolean;
  hasCloseButton?: boolean;
  bgColor?: string;
  px?: string;
  mx?: string;
  padding?: string;
  isErrorModal?: boolean;
  responsiveWidth?: string;
  otherClass?: string;
  otherClassEntire?: string;
  ignoreMaxWidth?: boolean;
}

const Modal = ({
  children,
  isModalOpen,
  handleClose,
  width = '800px',
  maxWidth = '800px',
  enableClickAway = true,
  hasCloseButton = true,
  bgColor,
  px = '120px',
  mx = '0px',
  padding = 'p-[60px]',
  responsiveWidth = '',
  isErrorModal,
  otherClass,
  otherClassEntire,
}: Props) => {
  const modalRef = useRef(null);
  const errorModal = useAppSelector(selectErrorModal);
  const forOnboarding = useAppSelector(selectForOnboarding);
  const isUserInactive = useAppSelector(selectLoginError);

  useClickAway(modalRef, () => {
    if ((enableClickAway && !errorModal && !forOnboarding && !isUserInactive) || isErrorModal) {
      handleClose();
    }
  });

  return (
    <section
      className={classNames(`modal z-[10000] ${otherClassEntire}`, {
        'modal-open': isModalOpen,
      })}
    >
      <div
      className={classNames(`
      modal-box relative flex flex-col ${bgColor} scrollbar overflow-auto rounded-[10px] md:px-[120px]
      [&>.title]:mb-[20px] [&>.title]:text-[20px] [&>.button]:border-none 
      [&>.title]:md:leading-[42px] [&>.title]:md:text-[34px] [&>.title]:font-[700] [&>.title]:text-center [&>.icon]:mb-[40px] [&>.icon]:self-center [&>.button]:mt-[40px] [&>.button]:bg-flexi-green-1 [&>.button]:text-white [&>.button]:md:text-[16px] [&>.button]:font-[700] [&>.button]:rounded-full [&>.button]:h-[60px] [&>.button:hover]:bg-flexi-green-2 mx-[${mx}] ${px} w-full !w-[${width}] !${responsiveWidth} ${padding} ${otherClass}`,{
        // '!px-[25px] !md:px-[120px]': px
      })}
        style={{
          maxWidth,
          // paddingRight: `${px}`,
          // paddingLeft: `${px}`,
        }}
        ref={modalRef}
      >
        {hasCloseButton && (
          <span
            className="absolute md:right-[33px] right-[25px] top-[30px] md:top-[40px] grid place-items-center"
            onClick={handleClose}
            role="button"
          >
            <span className='bg-no-repeat bg-center md:h-[25px] md:w-[25px] h-[20px] w-[20px]'
            style={{
              background: `url('${IMAGE_URL}${iconClose}')`,
              backgroundSize:'100%',
            }}
            />
          </span>
        )}
        {children}
      </div>
    </section>
  );
};

export default Modal;