import React from 'react';

interface Props {
  size?: number;
  my?: number;
}

const PendingLoader = ({ size = 100, my = 40 }: Props) => {
  return (
    <div className={`my-[${my}px] mx-auto grid place-items-center`}>
      <span
        className={`h-[${size}px] w-[${size}px] animate-spin rounded-full border-[5px] border-zinc-300 border-t-transparent`}
      ></span>
    </div>
  );
};

export default PendingLoader;
