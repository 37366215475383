import React, { Dispatch, useState } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import moment from 'moment';

import vehiclePlaceholder from 'assets/images/vehicle-placeholder.svg';
import { ReactComponent as IconStar } from 'assets/images/star.svg';
import { IMAGE_URL } from 'common/constants';
import { IBooking, IBookingVehicle } from 'common/types';
import { selectDebugMode } from 'modules/auth/slice';
import {
  selectLoadingBookingsUpcoming,
  setModifyBookingId,
} from 'modules/booking/slice';
import { selectFavoriteLocationList } from 'modules/favorites/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import FavoriteButton from 'components/FavoriteButton';
import BurgerNav from 'components/BurgerNav';

interface MyComponentProps {
  vehicle: IBookingVehicle;
}

interface Props {
  booking: IBooking;
  i: number;
  setBookingId: Dispatch<number>;
  setModifyModalOpen: Dispatch<boolean>;
  setIsCancelModalOpen: Dispatch<boolean>;
  setModifyBooking: Dispatch<IBooking | null>;
  setIsCantModifyModalOpen: Dispatch<boolean>;
}
function Upcoming({
  booking,
  i,
  setBookingId,
  setIsCancelModalOpen,
  setIsCantModifyModalOpen,
  setModifyBooking,
  setModifyModalOpen,
}: Props) {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { isBusinessManagerAccount } = useBusiness();
  const dbm = useAppSelector(selectDebugMode);
  const upcomingLoad = useAppSelector(selectLoadingBookingsUpcoming);
  const favoriteLocations = useAppSelector(selectFavoriteLocationList);
  const [burgerActive, setBurgerActive] = useState(-1);

  const handleRowClick = (id: number) => {
    dispatch(setModifyBookingId(id));
    push('/app/my-bookings/booking-details');
  };

  const handleReport = (booking: IBooking) => {
    const payload = {
      mode: 'pre',
      bookingId: booking.id,
    };
    dispatch({ type: ACTIONS.FETCH_REPORT_BOOKING_URL, payload });
  };

  const handleCancelBooking = (booking: IBooking) => {
    setBookingId(booking.id);
    dispatch({
      type: ACTIONS.FETCH_CANCELLATION_FEES_V2,
      payload: {
        bookingId: booking.id,
      },
    });
    setIsCancelModalOpen(true);
  };

  const handleModifyBooking = (booking: IBooking) => {
    setModifyModalOpen(true);
    setModifyBooking(booking);
    setBookingId(booking.id);
    dispatch({
      type: ACTIONS.FETCH_VEHICLE_AVAILABLE,
      payload: {
        vehicleId: booking?.vehicle?.id,
        startsAt: booking?.startsAt,
      },
    });
  };

  const VehicleImage: React.FC<MyComponentProps> = ({ vehicle }) => {
    const [vehicleImageError, setVehicleImageError] = React.useState(false);
    const [groupImageError, setGroupImageError] = React.useState(false);

    return (
      <div className="min-h-auto m-auto flex h-full items-center object-contain md:block md:h-[140px] md:w-[210px]">
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': !vehicleImageError,
          })}
          src={vehicle.vehicleImageUrl !== null ? vehicle.vehicleImageUrl : ''}
          alt={vehicle.name}
          onError={() => setVehicleImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && !groupImageError,
          })}
          src={
            vehicle.group.vehicleImage !== null
              ? vehicle.group.vehicleImage
              : ''
          }
          alt={vehicle.group.make}
          onError={() => setGroupImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && groupImageError,
          })}
          src={`${IMAGE_URL}${vehiclePlaceholder}`}
          alt="vehicle default image"
        />
      </div>
    );
  };

  return (
    <article
      className={classNames(
        'relative flex w-full cursor-pointer flex-row justify-start gap-3 p-3 py-[24px] duration-200 hover:shadow-[0_0_10px] hover:shadow-flexi-black-7 md:gap-[30px] md:px-[22px]',
        {
          'bg-zinc-100': i % 2 === 0,
          'md:gap-[60px]': !isBusinessManagerAccount,
          '!md:gap-[30px]': isBusinessManagerAccount,
        },
      )}
      key={i}
      onClick={() => handleRowClick(booking.id)}
    >
      {upcomingLoad.includes(booking.id) && (
        <span className="absolute left-0 top-0 z-[11] h-full w-full flex-1 animate-pulse bg-white/50" />
      )}
      <div className="w-[80px] object-contain sm:w-[120px] md:h-full md:w-fit">
        {booking?.vehicle && <VehicleImage vehicle={booking?.vehicle} />}
      </div>

      {isBusinessManagerAccount && booking?.driver && (
        <div className="hidden !w-[100px] flex-none flex-col md:flex">
          <p className="text-[10px] font-bold">Driver</p>
          <p className="line-clamp-2 mt-[2px] text-[18px] font-bold leading-[22px]">
            {booking.driver.name}
          </p>
        </div>
      )}

      <div className="hidden !w-[100px] flex-none flex-col md:flex">
        <p className="truncate text-[10px] font-bold">
          {booking.vehicle?.group?.make} {booking.vehicle?.group?.model}
        </p>
        <p className="truncate text-[18px] font-bold">
          {booking.vehicle?.name}
        </p>
        <p
          className={classNames(
            'mt-[8px] w-fit rounded-[13px] px-[8px] py-[4px] text-[12px] capitalize text-flexi-black-1',
            {
              'bg-[#A2D8CD]':
                booking.tripStatus.toLocaleLowerCase() === 'booked',
            },
          )}
        >
          {booking.tripStatus}
        </p>
      </div>
      <div className="flex flex-none flex-col md:!min-w-[130px] md:!max-w-[130px]">
        <div className="mb-3 flex flex-none flex-col md:hidden">
          <div className="flex items-center gap-3">
            <p className="max-w-[50px] truncate text-[14px] font-bold sm:max-w-[300px] sm:text-[16px]">
              {booking.vehicle?.name}
            </p>
            <p
              className={classNames(
                'w-fit rounded-[13px] px-2 py-1 text-[10px] capitalize text-flexi-black-1',
                {
                  'bg-[#A2D8CD]':
                    booking.tripStatus.toLocaleLowerCase() === 'booked',
                },
              )}
            >
              {booking.tripStatus}
            </p>
          </div>
          <p className="truncate text-[10px] font-bold">
            {booking.vehicle.group.make} {booking.vehicle.group.model}
          </p>
        </div>
        <div className="flex items-center gap-1 md:block">
          <p className="text-[10px] md:font-bold">
            Pick up<span className="md:hidden">:</span>
          </p>
          <p className="whitespace-nowrap text-[10px] font-bold md:mb-[10px] md:text-[14px] md:font-normal">
            {moment(booking.startsAt)
              .tz(booking.podTimeZone)
              .format('ddd DD/MM, h:mm')}
            <span className="text-[8px] font-bold">
              {moment(booking.startsAt).tz(booking.podTimeZone).format('A')}
            </span>
          </p>
        </div>
        <div className="flex items-center gap-1 md:block">
          <p className="text-[10px] md:font-bold">
            Drop off<span className="md:hidden">:</span>
          </p>
          <p className="whitespace-nowrap text-[10px] font-bold md:mb-[10px] md:text-[14px] md:font-normal">
            {moment(booking.endsAt)
              .tz(booking.podTimeZone)
              .format('ddd DD/MM, h:mm')}
            <span className="text-[8px] font-bold">
              {moment(booking.endsAt).tz(booking.podTimeZone).format('A')}
            </span>
          </p>
        </div>
      </div>
      <div className="hidden w-[230px] grow flex-col gap-[2px] md:flex">
        <p className="text-[10px] font-bold">Pickup Location</p>
        <div className="flex w-full gap-[10px]">
          {favoriteLocations.filter(
            (fav: any) => fav.id === booking.vehicle.id,
          ) && (
            <IconStar className="mt-[5px] min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
          )}
          <p className="truncate text-[18px] font-bold">
            {booking.vehicle.podName}
          </p>
        </div>
        <p className="line-clamp-3 text-[14px]">
          {booking.vehicle.podDescription}
        </p>
      </div>
      <div
        className="ml-auto grid w-fit place-items-center"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <BurgerNav
          setBurgerActive={setBurgerActive}
          burgerActive={burgerActive}
          i={i}
        >
          {dbm && (
            <p
              className="w-full p-[15px] pb-[10px] text-center hover:bg-flexi-black-7"
              onClick={() => handleReport(booking)}
            >
              Report
            </p>
          )}
          <p
            className="w-full p-[15px] pb-[10px] text-center hover:bg-flexi-black-7"
            onClick={() => {
              const dateNow = moment().tz(
                booking?.podTimeZone || 'Australia/Perth',
              );
              const bookingStart = moment(booking?.startsAt).tz(
                booking?.podTimeZone || 'Australia/Perth',
              );
              const minutesBeforeBookingStart = bookingStart.diff(
                dateNow,
                'minutes',
              );
              if (minutesBeforeBookingStart >= 15) {
                handleModifyBooking(booking);
              } else {
                setIsCantModifyModalOpen(true);
              }
            }}
          >
            Modify
          </p>
          <label
            htmlFor="cancel-booking-modal"
            className="w-full  cursor-pointer p-[15px] py-[10px] text-center hover:bg-flexi-black-7"
            onClick={() => handleCancelBooking(booking)}
          >
            Cancel
          </label>
          <div className="grid w-full place-items-center p-[15px] pt-[10px] text-center hover:bg-flexi-black-7">
            <FavoriteButton
              isFavorite={booking.podIsFavorite}
              podId={booking.podId}
              hasStar={false}
              extraClass="font-normal text-base normal-case"
              titleAdd="Set as Favorite"
              titleRemove="Remove as Favorite"
            />
          </div>
        </BurgerNav>
      </div>
    </article>
  );
}

export default Upcoming;
