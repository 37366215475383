import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import OtpInput from 'react-otp-input';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectDecryptToken,
  selectDecryptTokenError,
  selectDecryptTokenStatus,
  selectOtpError,
  selectPendingOtp,
  setDecryptTokenError,
  setOtpError,
} from 'modules/auth/slice';
// import { useLocation } from 'react-router-dom';
// import { useEffectOnce } from 'react-use';
import useQuery from 'hooks/useQuery';
import gsap from 'gsap';
import { useEffectOnce } from 'react-use';
import Modal from 'components/Modal';
// import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';
import { RequestStatusEnum } from 'common/types';
// import { getOtpSent } from 'common/helpers';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

const ForgotPasswordOTP = () => {
  const [otpCode, setOtpCode] = useState('');
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPendingOtp);
  const otpError = useAppSelector(selectOtpError);
  const [isOtpError, setIsOtpError] = useState(false);
  const { params } = useQuery<{ token?: string }>();
  const component = useRef<HTMLDivElement>(null);
  const email = useAppSelector(selectDecryptToken);
  const emailError = useAppSelector(selectDecryptTokenError);
  const emailStatus = useAppSelector(selectDecryptTokenStatus);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffectOnce(() => {
    if (params.token) {
      const payload = {
        token: params.token,
      };

      // if (getOtpSent() !== 'true') {
      // dispatch({
      //   type: ACTIONS.GET_RESET_OTP,
      //   payload,
      // });
      // }
      dispatch({
        type: ACTIONS.GET_DECRYPT_TOKEN,
        payload,
      });
    }
  });

  useEffect(() => {
    if (otpCode.length >= 6) {
      const payload = {
        email,
        code: otpCode,
      };
      dispatch({ type: ACTIONS.RESET_PASSWORD_OTP, payload });
    }
  }, [otpCode]);

  const handleResend = () => {
    if (seconds <= 1) {
      dispatch({
        type: ACTIONS.GET_RESET_OTP,
        payload: { token: params.token },
      });
    }
    setSeconds(20);
    setAlreadySent(true);
  };

  const [seconds, setSeconds] = useState(0);
  const [alreadySent, setAlreadySent] = useState(false);

  useEffect(() => {
    const mySecondInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(mySecondInterval);
    };
  });

  useEffect(() => {
    if (otpError) {
      setIsOtpError(true);
      dispatch(setOtpError(false));
      setOtpCode('');
    }
  }, [otpError]);

  return (
    <section
      ref={component}
      className="fadein m-auto mt-[100px] flex w-full max-w-[80vw] flex-col place-items-center content-center items-center md:max-w-[500px]"
    >
      <h2 className="font-primary mb-[20px] text-left text-[30px] font-[900] uppercase text-primary  md:text-[40px]">
        verify
      </h2>
      <p className="text-center text-[16px]">
        Enter the 6-digit code sent to your&nbsp;email.
      </p>

      <div className="relative mt-[50px] flex gap-5">
        <div className=" flex flex-row gap-5">
          <OtpInput
            value={otpCode}
            onChange={(otp: any) => {
              setOtpCode(otp);
              setIsOtpError(false);
            }}
            numInputs={6}
            isInputNum
            inputStyle="input input-bordered select-none text-[20px] md:text-[36px] font-[300] text-flexi-black-4 !w-[40px] px-0 md:!w-[55px] md:!h-[60px] mx-[5px] md:mx-[10px]"
            shouldAutoFocus
            isDisabled={emailStatus === RequestStatusEnum.PENDING}
          />
          {emailStatus === RequestStatusEnum.PENDING && (
            <span className="h-[25px] w-[25px] animate-spin self-center rounded-full border-[2px] border-flexi-black-3 border-t-transparent border-l-transparent" />
          )}
        </div>
        {isPending && (
          <span className="absolute right-[-35px] h-[25px] w-[25px] animate-spin self-center rounded-full border-[2px] border-flexi-black-3 border-t-transparent border-l-transparent" />
        )}
      </div>
      {isOtpError && (
        <p className="mt-[25px] self-center text-[12px] text-[#B71616]">
          Invalid code
        </p>
      )}
      {seconds > 0 ? (
        <p className="mt-[30px] cursor-default select-none text-[16px] font-bold text-flexi-black-4">
          Resend code{' '}
          <span className="text-flexi-black-1">in {seconds} sec.</span>
        </p>
      ) : (
        <p
          className="mt-[30px] cursor-pointer text-[16px] font-bold text-flexi-orange-1"
          onClick={handleResend}
        >
          {alreadySent ? 'Resend' : 'Send'} code
        </p>
      )}
      <Modal
        isModalOpen={emailError}
        handleClose={() => {
          dispatch(setDecryptTokenError(null));
        }}
        width="750px"
      >
        <LottieAnimation lottieFile={errorLottie} isStopped={!emailError} />
        <p className="title">Oops! Something went wrong.</p>
        {emailError?.errorCode?.message ? (
          <p className="text-center text-[18px]">
            {emailError.errorCode.message}
            <br />
            Please check your email for the latest OTP with the included link to
            reset your&nbsp;password.
          </p>
        ) : (
          <p>no</p>
        )}
        {/* {errorMessage instanceof String ? (
        <p className="text-center text-[18px]">{getErrorValue(errorMessage)}</p>
      ) : uniqueMessage.length ? (
        uniqueMessage.map((message, i) => {
          return (
            <p className="text-center text-[18px]" key={i}>
              {message}
            </p>
          );
        })
      ) : (
        <p className="text-center text-[18px]"></p>
      )} */}
      </Modal>
    </section>
  );
};

export default ForgotPasswordOTP;
