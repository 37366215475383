import React from 'react';
import Lottie from 'react-lottie';

interface Props {
  lottieFile: any;
  height?: number;
  width?: number;
  isStopped?: boolean;
}

const LottieAnimation = ({
  lottieFile,
  height = 200,
  width = 200,
  isStopped = false,
}: Props) => {
  const options = {
    loop: false,
    autoplay: true,
    animationData: lottieFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Lottie
      options={options}
      height={height}
      width={width}
      isStopped={isStopped}
    />
  );
};

export default LottieAnimation;
