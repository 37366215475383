import React, { Fragment } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { selectUserInfo } from 'modules/me/slice';
import moment from 'moment';

const DriverInformation = () => {
  const userInfo = useAppSelector(selectUserInfo);

  return (
    <article className="text-[16px] md:text-[16px] mb-[50px] grid grid-cols-1 md:grid-cols-2 md:gap-y-[20px]">
      <strong>Full Name</strong>
      <span className='capitalize'>{userInfo?.firstname?.toLocaleLowerCase()} {userInfo?.lastname?.toLocaleLowerCase()}</span>
      <strong className='mt-[20px] md:mt-0'>Residential&nbsp;Address</strong>
      <span>{userInfo?.licenseAddress}</span>
      <strong className='mt-[20px] md:mt-0'>Birthdate</strong>
      <span>{userInfo?.birthday.replace(/-/g,'/')}</span>
      <strong className='mt-[20px] md:mt-0'>License Number</strong>
      <span>{userInfo?.licenseNumber}</span>
      <strong className='mt-[20px] md:mt-0'>License Card Number</strong>
      <span>{userInfo?.licenseCardNumber}</span>
      <strong className='mt-[20px] md:mt-0'>Expiry Date</strong>
      <span>{moment(userInfo?.licenseExpiry,'DD/MM/YYYY').format('DD/MM/YYYY')}</span>
      <strong className="mt-[20px] md:mt-0 !w-[285px] flex-1">License Country of Issue</strong>
      <span>{userInfo?.licenseCountry}</span>
      {userInfo?.passportNumber && (
        <Fragment>
          <strong>Passport Number</strong>
          <span>{userInfo?.passportNumber}</span>
        </Fragment>
      )}
      {userInfo?.passportCountry && (
        <Fragment>
          <strong>Passport Country of Issue</strong>
          <span>{userInfo?.passportCountry}</span>
        </Fragment>
      )}
    </article>
  );
};

export default DriverInformation;
