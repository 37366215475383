import { getToken, removeDeviceId, removeToken } from 'common/helpers';
import {
  selectPaymentCards,
  selectUser,
  setCampusList,
  setCancelMembershipStatus,
  setPendingCampus,
  setPendingCancelMembership,
  setPendingChangeExcess,
  setChangeExcessStatus,
  setPendingChangePartner,
  setChangePartnerStatus,
  setChangeVffStatus,
  setUpdateUserPlanStatus,
  setDeletePaymentCardStatus,
  setPendingDeletePaymentCard,
  setChangePasswordStatus,
  setStoreContactStatus,
  setErrors,
  selectErrors,
  setUpdateLicenseAddressStatus,
  setUploadPhotoStatus,
  setUserPhoto,
  setPlans,
  setPlansStatus,
  setSwitchAccountStatus,
  setFirstLoginStatus,
  setWalletStatus,
  setWallet,
  setRemoveVffStatus,
  setVerifyReferrerStatus,
  setReferrerError,
  setUserPlanStatus,
  setOnlineQueryStatus,
  setLoggedInDevices,
  setOcrAttempt,
  setOcrRetryStatus,
  setOcrAttemptStatus,
  setOcrId,
  setUserAvailableCredits,
  setIsAnnualFeeNotPaid,
  setChangeCourseEndDateStatus,
  setChangeCourseEndDateError,
  setChangeCourseEndDateMessage,
} from 'modules/me/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { authenticatedClient, createUploadClient } from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import { push } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';
import {
  setDefaultPaymentCard,
  setPaymentCards,
  setPending,
  setPendingPaymentCards,
  setPendingExpiredCredits,
  setPendingUpdate,
  setPendingUserInfo,
  setPendingRewardPartners,
  setUser,
  setUserInfo,
  setUserPlan,
  setRewardPartners,
  setExpiredCredits,
  setUserStatus,
} from './slice';
import { IErrors, IPaymentCard, IUser, RequestStatusEnum } from 'common/types';
import { setError } from 'modules/auth/slice';
import { setQrCode, setQrCodeError } from 'modules/ocr/slice';

export const SAGA_ACTIONS = {
  FETCH_ME: 'FETCH_ME',
  WATCH_ME: 'WATCH_ME',
  FETCH_ME_OCR: 'FETCH_ME_OCR',
  RETRY_OCR: 'RETRY_OCR',
  FETCH_OCR_ATTEMPT: 'FETCH_OCR_ATTEMPT',
  FETCH_PAYMENT_CARDS: 'FETCH_PAYMENT_CARDS',
  FETCH_MY_INFO: 'FETCH_MY_INFO',
  FETCH_REWARD_PARTNERS: 'FETCH_REWARD_PARTNERS',
  UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
  UPDATE_LICENSE_ADDRESS: 'UPDATE_LICENSE_ADDRESS',
  CHOOSE_USER_PLAN: 'CHOOSE_USER_PLAN',
  UPDATE_USER_PLAN: 'UPDATE_USER_PLAN',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  FETCH_DEFAULT_PAYMENT_CARD: 'FETCH_DEFAULT_PAYMENT_CARD',
  UPDATE_DEFAULT_PAYMENT_CARD: 'UPDATE_DEFAULT_PAYMENT_CARD',
  DELETE_PAYMENT_CARD: 'DELETE_PAYMENT_CARD',
  FETCH_USER_INFO: 'FETCH_USER_INFO',
  FETCH_CAMPUS_LIST: 'FETCH_CAMPUS_LIST',
  FETCH_USER_PLAN: 'FETCH_USER_PLAN',
  FETCH_EXPIRED_CREDITS: 'FETCH_EXPIRED_CREDITS',
  CANCEL_MEMBERSHIP: 'CANCEL_MEMBERSHIP',
  CANCEL_MEMBERSHIP_DRIVER: 'CANCEL_MEMBERSHIP_DRIVER',
  CHANGE_EXCESS: 'CHANGE_EXCESS',
  CHANGE_PARTNER: 'CHANGE_PARTNER',
  CHANGE_VFF: 'CHANGE_VFF',
  REMOVE_VFF: 'REMOVE_VFF',
  STORE_CONTACT: 'STORE_CONTACT',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  GET_PHOTO: 'GET_PHOTO',
  GET_PLANS: 'GET_PLANS',
  GET_PLANS_V2: 'GET_PLANS_V2',
  SWITCH_ACCOUNT: 'SWITCH_ACCOUNT',
  FIRST_LOGIN: 'FIRST_LOGIN',
  FETCH_WALLET: 'FETCH_WALLET',
  VERIFY_REFERRER: 'VERIFY_REFERRER',
  SEND_SUPPORT_EMAIL: 'SEND_SUPPORT_EMAIL',
  FETCH_DEVICES: 'FETCH_DEVICES',
  LOGOUT_DEVICE: 'LOGOUT_DEVICE',
  FETCH_USER_AVAILABLE_CREDITS: 'FETCH_USER_AVAILABLE_CREDITS',
  UPDATE_USE_CREDITS: 'UPDATE_USE_CREDITS',
  UPDATE_COURSE_END_DATE: 'UPDATE_COURSE_END_DATE',
  ACCEPT_STUDENT_SWITCH_PERSONAL: 'ACCEPT_STUDENT_SWITCH_PERSONAL',
  QR_CODE_REVERIFICATION: 'QR_CODE_REVERIFICATION',
};

const api = authenticatedClient();

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* fetchMe ():any {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const user: IUser = yield select(selectUser);
    if(!user){
      yield put(setPending(true));
      yield put(setUserStatus(RequestStatusEnum.PENDING));
    }
    const { data } = yield call(() => api.get('/me', {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setUser(data));
    yield put(setUserStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setUserStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPending(false));
    // if (yield cancelled()) {
    //   cancelTokenSource.cancel();
    // }
  }
};

export function* fetchMeOcr() {
  try {
    const { data } = yield call(() => api.get('/me'));
    yield put(setUser(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

export function* retryOcr() {
  try {
    yield put(setOcrRetryStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.post('/me/ocr/retry'));
    yield put(setOcrId(data.ocr_id));
    yield put(setOcrRetryStatus(RequestStatusEnum.SUCCESS));
    yield put(push('/register-v2/ocr'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    yield put(setOcrRetryStatus(RequestStatusEnum.FAILED));
    }
  }
}
export function* fetchOcrAttempt() {
  try {
    yield put(setOcrAttemptStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get('/me/ocr/attempt/status'));
    yield put(setOcrAttempt(data));
    yield put(setOcrAttemptStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    yield put(setOcrAttemptStatus(RequestStatusEnum.ERROR));
    }
  }
}

export function* fetchPaymentCards() {
  try {
    yield put(setPendingPaymentCards(true));
    const { data } = yield call(() => api.get('/me/payment-cards'));
    yield put(setPaymentCards(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingPaymentCards(false));
  }
}

export function* fetchDefaultPaymentCard() {
  try {
    yield put(setPendingPaymentCards(true));
    yield call(fetchPaymentCards);
    const cards: IPaymentCard[] | [] = yield select(selectPaymentCards);

    if (cards.length) {
      const { data } = yield call(() => api.get('/me/payment-cards/default'));
      yield put(setDefaultPaymentCard(data));
    }
    // yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingPaymentCards(false));
  }
}

export function* fetchUserInfo() {
  try {
    yield put(setPendingUserInfo(true));
    const { data } = yield call(() => api.get('/me/info'));
    yield put(setUserInfo(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingUserInfo(false));
  }
}

export function* fetchCampusList() {
  try {
    yield put(setPendingCampus(true));
    const user: IUser | null = yield select(selectUser);

    if (user?.organisation) {
      const { data } = yield call(() =>
        api.get(`/universities/${user.organisation?.id}/campuses`),
      );
      yield put(setCampusList(data));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingCampus(false));
  }
}

export function* fetchUserPlan() {
  try {
    yield put(setPending(true));
    yield put(setUserPlanStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get('/me/plan'));
    yield put(setUserPlan(data));
    yield put(setUserPlanStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
    yield put(setUserPlanStatus(RequestStatusEnum.FAILED));

  } finally {
    yield put(setPending(false));
  }
}

export function* fetchRewardPartners() {
  try {
    yield put(setPendingRewardPartners(true));
    const { data } = yield call(() => api.get('/partners'));
    yield put(setRewardPartners(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingRewardPartners(false));
  }
}

export function* updateDefaultPaymentCard(action: PayloadAction<number>) {
  const api = authenticatedClient();
  try {
    const { data } = yield call(() =>
      api.post(`/accounts/${action.payload}`, { _method: 'PUT' }),
    );
    yield put(setPaymentCards(data));
    yield call(fetchDefaultPaymentCard);
    yield call(fetchMe);
  } catch (err) {
    const errors: IErrors | null = yield select(selectErrors);
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(
        setErrors({
          ...errors,
          paymentCard:
            err.response.data.errors[
              Object.keys(err.response.data.errors)[0]
            ][0],
        }),
      );
    }
  }
}

export function* deletePaymentCard(action: PayloadAction<number>) {
  const api = authenticatedClient();
  try {
    yield put(setPendingDeletePaymentCard(true));
    const { data } = yield call(() =>
      api.post(`/accounts/${action.payload}`, { _method: 'DELETE' }),
    );
    yield put(setDeletePaymentCardStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchMe);
    yield put(setPaymentCards(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      yield put(setError(err.response));
      yield put(setDeletePaymentCardStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingDeletePaymentCard(false));
  }
}

type UpdateUserDetailsPayload = {
  memberType: number;
  experienceYears: number;
  hasSuspended: number;
  hasConvicted: number;
  hasClaimsInsurance: number;
  vff_membership_id?: string | undefined;
  convictionDocs: File[];
  suspendedDocs: File[];
};

export function* updateUserDetails(
  action: PayloadAction<UpdateUserDetailsPayload>,
) {
  const user: IUser | null = yield select(selectUser);
  // const api = createUploadClient();
  try {
    // console.log('from saga:', payload)
    yield put(setPendingUpdate(true));
    yield call(() => api.post('/register/information', action.payload));
    if (user?.type.name.toLocaleLowerCase() === 'business' && user?.role === 'driver') {
      yield put(push('/register/done'));
    } else {
      yield put(push('/register/plan'));
    }
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingUpdate(false));
  }
}

type UpdateLicenseAddressPayload = {
  residentialAddress: string;
};

export function* updateLicenseAddress(
  action: PayloadAction<UpdateLicenseAddressPayload>,
) {
  try {
    yield put(setUpdateLicenseAddressStatus(RequestStatusEnum.PENDING));
    yield call(() => api.put('/me/change-license-address', action.payload));
    yield put(setUpdateLicenseAddressStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchUserInfo);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setUpdateLicenseAddressStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}

export function* getPlans() {
  const api = authenticatedClient();
  try {
    yield put(setPlansStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get(`/register/plans`));
    yield put(setPlans(data));
    yield put(setPlansStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPlansStatus(RequestStatusEnum.FAILED));
    }
  }
}

type GetPlansV2Payload = {
  isSenior: number;
};

export function* getPlansV2(action: PayloadAction<GetPlansV2Payload>) {
  const api = authenticatedClient();
  try {
    yield put(setPlansStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get(`/registerV2/plans`, { params: action.payload }));
    yield put(setPlans(data));
    yield put(setPlansStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setPlansStatus(RequestStatusEnum.FAILED));
    }
  }
}

type ChooseUserPlayPayload = {
  planId: number;
};

export function* chooseUserPlan(action: PayloadAction<ChooseUserPlayPayload>) {
  try {
    yield put(setPendingUpdate(true));
    yield call(() => api.post('/register/plan', action.payload));
    yield put(setUpdateUserPlanStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchMe);
    yield put(push('/register/payment'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setUpdateUserPlanStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingUpdate(false));
  }
}

type UpdateUserPlanPayload = {
  planId: number;
};

export function* updateUserPlan(action: PayloadAction<UpdateUserPlanPayload>) {
  try {
    yield put(setPendingUpdate(true));
    yield call(() => api.post('/me/change-plan', action.payload));
    yield put(setUpdateUserPlanStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchMe);
    yield call(fetchUserPlan);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setUpdateUserPlanStatus(RequestStatusEnum.FAILED));
      // toast.error(err.response.data?.error?.message);
    }
  } finally {
    yield put(setPendingUpdate(false));
  }
}

type ChangePasswordPayload = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

export function* changePassword(action: PayloadAction<ChangePasswordPayload>) {
  try {
    yield put(setChangePasswordStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/me/change-password', action.payload));
    yield put(setChangePasswordStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    const errors: IErrors | null = yield select(selectErrors);

    if (axios.isAxiosError(err) && err.response) {
      yield put(setChangePasswordStatus(RequestStatusEnum.FAILED));
      yield put(
        setErrors({
          ...errors,
          changePassword: err.response.data.errors[Object.keys(err.response.data.errors)[0]][0],
        }),
      );
    }
  }
}

export function* fetchExpiredCredits() {
  try {
    yield put(setPendingExpiredCredits(true));
    const { data } = yield call(() => api.get('/me/expired-credits'));
    yield put(setExpiredCredits(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingExpiredCredits(false));
  }
}

type CancelMembershipPayload = {
  reason: string;
};

export function* cancelMembership(
  action: PayloadAction<CancelMembershipPayload>,
) {
  const payload = {
    reason: action.payload.reason,
    token: getToken(),
  };
  try {
    yield put(setPendingCancelMembership(true));
    yield put(setCancelMembershipStatus(RequestStatusEnum.PENDING));
    yield call(() => api.delete('/me/cancel-membership', { data: payload }));
    yield put(setCancelMembershipStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      yield put(setCancelMembershipStatus(RequestStatusEnum.FAILED));
      yield put(setError(err.response));
    }
  } finally {
    yield put(setPendingCancelMembership(false));
  }
}


type CancelMembershipDriverPayload = {
  reason: string;
  token: string;
};

export function* cancelMembershipDriver(
  action: PayloadAction<CancelMembershipDriverPayload>,
) {
  const payload = {
    reason: action.payload.reason,
    token: action.payload.token,
  };
  try {
    yield put(setPendingCancelMembership(true));
    yield put(setCancelMembershipStatus(RequestStatusEnum.PENDING));
    yield call(() => api.delete('/me/cancel-membership', { data: payload }));
    yield put(setCancelMembershipStatus(RequestStatusEnum.SUCCESS));
    yield put(push('/app/account/cancel-membership/done'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      yield put(setCancelMembershipStatus(RequestStatusEnum.FAILED));
      yield put(setError(err.response));
    }
  } finally {
    yield put(setPendingCancelMembership(false));
  }
}


export function* changeExcess() {
  try {
    const user: IUser | null = yield select(selectUser);

    yield put(setPendingChangeExcess(true));
    const { data } = yield call(() => api.post('/me/change-excess'));
    yield put(setUser({ ...user, ...data }));
    yield put(setChangeExcessStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setChangeExcessStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingChangeExcess(false));
  }
}

type ChangePartnerPayload = {
  partner: string;
  partnerMembershipId: string;
};

export function* changePartner(action: PayloadAction<ChangePartnerPayload>) {
  try {
    yield put(setPendingChangePartner(true));
    yield call(() => api.post('/me/change-partner', action.payload));
    yield call(fetchMe);
    yield put(setChangePartnerStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setChangePartnerStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingChangePartner(false));
  }
}

type VffPayload = {
  vff_membership_id: string;
};

export function* changeVff(action: PayloadAction<VffPayload>) {
  try {
    yield put(setChangeVffStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/me/change-vff', action.payload));
    yield call(fetchMe);
    yield put(setChangeVffStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);

      yield put(setChangeVffStatus(RequestStatusEnum.FAILED));
      yield put(setError(err.response));
    }
  }
}

export function* removeVff() {
  try {
    yield put(setRemoveVffStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/me/remove-vff'));
    yield call(fetchMe);
    yield put(setRemoveVffStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      yield put(setRemoveVffStatus(RequestStatusEnum.FAILED));
      yield put(setError(err.response));
    }
  }
}

type StoreContactPayload = {
  subject: string;
  inquiry: string;
};

export function* storeContact(action: PayloadAction<StoreContactPayload>) {
  try {
    yield put(setStoreContactStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/support/contact', action.payload));
    yield put(setStoreContactStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setStoreContactStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}

export function* getPhoto() {
  try {
    const { data } = yield call(() => api.get('/me/photo'));
    yield put(setUserPhoto(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setUploadPhotoStatus(RequestStatusEnum.FAILED));
      if (err.response.status === 404) {
        yield put(setUserPhoto(undefined));
      }

      handleAPIError(err.response);
    }
  }
}

export function* uploadPhoto(action: PayloadAction<FormData>) {
  const api = createUploadClient();
  try {
    yield put(setUploadPhotoStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/me/photo/upload', action.payload));
    yield call(getPhoto);
    yield put(setUploadPhotoStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setUploadPhotoStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}

type SwitchAccountPayload = {
  id: number;
};

export function* switchAccount(action: PayloadAction<SwitchAccountPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setSwitchAccountStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/me/change-account`, action.payload));
    yield call(fetchMe);
    yield put(setSwitchAccountStatus(RequestStatusEnum.SUCCESS));
    if(window.location.pathname.includes('add-account/otp')){
      yield put(push('/app/add-account/payment'));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setSwitchAccountStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}

type FirstLoginPayload = {
  key: string;
};

export function* firstLogin(action: PayloadAction<FirstLoginPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setFirstLoginStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/me/action`, action.payload));
    yield call(fetchMe);
    yield put(setFirstLoginStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setFirstLoginStatus(RequestStatusEnum.FAILED));
    }
  }
}

export function* fetchWallet() {
  try {
    yield put(setWalletStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get('/me/wallet'));
    yield put(setWallet(data));
    yield put(setIsAnnualFeeNotPaid(data.hasOutstandingMembershipFee));
    yield put(setWalletStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setWalletStatus(RequestStatusEnum.FAILED));
    }
  }
}

type verifyReferrerPayload = {
  referrerEmail: string;
};

export function* verifyReferrer(action: PayloadAction<verifyReferrerPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setVerifyReferrerStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/register/verify/referrer`, action.payload));
    yield put(setVerifyReferrerStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // handleAPIError(err.response);
      yield put(setReferrerError(err.response.data?.message || err.response.data?.errors?.code[0]));
      yield put(setVerifyReferrerStatus(RequestStatusEnum.FAILED));
    }
  }
}

type sendSupportEmailPayload = {
  subject: string;
  message: string;
};

export function* sendSupportEmail(action: PayloadAction<sendSupportEmailPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setOnlineQueryStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/v2/me/send-support-email`, action.payload));
    yield put(setOnlineQueryStatus(RequestStatusEnum.SUCCESS));
    } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setOnlineQueryStatus(RequestStatusEnum.FAILED));
    }
  }
}

export function* fetchDevices() {
  try {
    const { data } = yield call(() => api.get('/me/logins'));
    yield put(setLoggedInDevices(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

type LogoutDevicePayload = {
  id: string;
};

export function* logoutDevice(
  action: PayloadAction<LogoutDevicePayload>,
  ) {
  const payload = {
    id: action.payload.id
  };
  try {
    if(action.payload.id !== null){
      yield call(() => api.delete(`/me/logins`, { data: payload }));
    }else{
      yield call(() => api.delete(`/me/logins`));
      removeToken();
      removeDeviceId();
      yield put(setLoggedInDevices(null));
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

export function* fetchUserAvailableCredits() {
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.get('/me/credits'));
    yield put(setUserAvailableCredits(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

type UpdateUseCreditsPayload = {
  isUseCredits: number;
};

export function* updateUseCredits(action: PayloadAction<UpdateUseCreditsPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    yield call(() => api.put('/me/credits', action.payload));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
    yield call(fetchMe);
  }
}

type UpdateCourseEndDatePayload = {
  courseEndDate: string;
};

export function* updateCourseEndDate(action: PayloadAction<UpdateCourseEndDatePayload>) {
  const api = authenticatedClient();
  try {
    yield put(setChangeCourseEndDateStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.put(`/me/change-course-end-date`, action.payload));
    yield put(setChangeCourseEndDateStatus(RequestStatusEnum.SUCCESS));
    yield put(setChangeCourseEndDateMessage(data.message));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setChangeCourseEndDateError(err.response));
      yield put(setChangeCourseEndDateStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield call(fetchMe);
  }
}

export function* acceptStudentSwitchPersonal() {
  const api = authenticatedClient();
  const payload = {
    token: getToken(),
  };
  try {
    yield put(setPendingUpdate(true));
    yield call(() => api.post(`/me/accept-student-switched-personal`, payload));
    const cancelTokenSource = axios.CancelToken.source();
    const { data } = yield call(() =>
      api.get('/me', {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setUser(data));
    yield put(setPendingUpdate(false));
    yield put(push('/app/account/plan-details'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // 
    }
  }
}

export function* qrCodeReverification() {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.post(`/me/ocr/reverify/web`));
    if (data.httpCode === 200) {
      try {
        const { data } = yield call(() =>
          api.get('/ocr/reverification/qr-code'),
        );
        yield put(setQrCode(data));
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          // handleAPIError(err.response);
          yield put(setQrCodeError(err.response));
        }
      } finally {
        const { data } = yield call(() => api.get('/me'));
        yield put(setUser(data));
        yield put(setPending(false));
      }
    };
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setPending(false));
      yield put(setError(err.response.data.message));
    }
  }
}