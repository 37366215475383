import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';

const useStudent = () => {
  const user = useAppSelector(selectUser);
  const isStudentAccount = user?.type.name.toLocaleLowerCase() === 'student';

  return {
    isStudentAccount,
  };
};

export default useStudent;
