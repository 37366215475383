import React, { useRef, useLayoutEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import gsap from 'gsap';

import approvedJson from 'assets/json/icon-verification-pending.json';
import declinedJson from 'assets/json/icon-verification-decline.json';
import { ReactComponent as Mail } from 'assets/images/envelope-solid.svg';
import { ReactComponent as Phone } from 'assets/images/phone-solid.svg';
import { RequestStatusEnum } from 'common/types';
import { selectPending, selectUser, selectUserStatus } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';

const SignUpDone = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { isBusinessDriverAccount } = useBusiness();
  const component = useRef<HTMLDivElement>(null);
  const isUserPending = useAppSelector(selectUserStatus);
  const userStatus = useAppSelector(selectUserStatus);
  const isPending = useAppSelector(selectPending);
  const user = useAppSelector(selectUser);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const approvedOptions = {
    loop: false,
    autoplay: true,
    animationData: approvedJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const declinedOptions = {
    loop: false,
    autoplay: true,
    animationData: declinedJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useLayoutEffect(() => {
    if (!isPending) {
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5,
      });
    }
  }, [isPending, user]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_ME });
  });

  return isPending && isUserPending !== RequestStatusEnum.SUCCESS ? (
    <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
      <PendingLoader size={100} />
    </section>
  ) : user?.status === 'incomplete' ? (
    <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
      <PendingLoader size={100} />
    </section>
  ) : (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-90px)] w-full max-w-[600px] flex-col items-start text-center md:min-h-[calc(100vh-388px)]"
    >
      {(user?.status === 'approved' ||
        user?.status === 'pending' ||
        user?.status === 'declined') && (
        <div className="flex h-[200px] w-full items-center justify-center">
          <Lottie
            options={
              user?.status === 'approved' || user?.status === 'pending'
                ? approvedOptions
                : declinedOptions
            }
            height={200}
            width={200}
          />
        </div>
      )}
      <h2 className="font-primary mb-[20px] mt-[20px] text-[28px] font-[900] uppercase leading-[29px] text-flexi-orange-1 md:mb-[40px] md:mt-0 md:text-[40px] md:leading-[50px] text-center">
        {user?.status === 'approved' || user?.status === 'pending' ? (
          isBusinessDriverAccount ? (
            <span>thank you, {user?.firstname}!</span>
          ) : (
            <span>
              your application{' '}
              <span className="whitespace-nowrap md:whitespace-normal">
                has been
              </span>{' '}
              submitted and your membership is being reviewed.
            </span>
          )
        ) : (
          user?.status === 'declined' && (
            <span>sorry. your application has been denied.</span>
          )
        )}
      </h2>

      <div className="mb-[40px] text-[14px] md:mb-[70px] md:text-[16px]">
        {user?.status === 'approved' || user?.status === 'pending' ? (
          isBusinessDriverAccount ? (
            <span>
              You have successfully created your business driver account.
              <br /> <br />
              Your membership is now being processed and we will let you know
              once you are good to go.
            </span>
          ) : (
            <span>
              {/* You have successfully created an account and your membership is
              now being reviewed by our&nbsp;team. <br /> <br /> */}
              We will let you know as soon as we are done, but in the meantime
              make sure you download the Flexicar app on your mobile and check
              out our video on&nbsp;
              <Link
                to="/how-it-works"
                target="_blank"
                className="whitespace-nowrap font-bold text-flexi-orange-1"
              >
                How Flexicar Works
              </Link>{' '}
              to help get you started.
            </span>
          )
        ) : (
          user?.status === 'declined' && (
            <div>
              <span>
                Unfortunately, the internal checks we run to approve your
                account has not been successful and we are unable to proceed
                with your application as per our&nbsp;
                <Link
                  to="/terms-and-conditions"
                  target="_blank"
                  className="whitespace-nowrap font-bold text-flexi-orange-1"
                >
                  Terms and Conditions
                </Link>
                .<br />
                <br />
                Any successful payments made has been refunded to the same
                credit card they were charged against.
                <br />
                <br />
                For membership information
                <br className="block md:hidden" />
                please contact us at:
                <br />
                <br />
              </span>
              <div className="mx-auto mb-[10px] flex w-fit items-center gap-[16px]">
                <Phone className="h-[14px] fill-[#FF7800]" />
                <a
                  href="tel:1300363780"
                  className="text-[14px] font-bold text-primary"
                >
                  1300 36 37 80
                </a>
              </div>
              <div className="mx-auto flex w-fit items-center gap-[16px]">
                <Mail className="h-[14px] fill-[#FF7800]" />
                <a
                  href="mailto:info@flexicar.com.au"
                  className="text-[14px] font-bold text-primary"
                >
                  info@flexicar.com.au
                </a>
              </div>
            </div>
          )
        )}
      </div>
      {user?.status === 'pending' ? (
        <button
          className={classNames(
            'btl-lg btn mx-auto mt-auto !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 md:!h-[60px]',
            {
              'loading bg-flexi-green-2':
                userStatus === RequestStatusEnum.PENDING,
            },
          )}
          onClick={() => {
            if (isMobile) {
              const payload = { push: '/our-cars' };
              dispatch({ type: ACTIONS.LOGOUT_ACCOUNT, payload });
            } else {
              dispatch({ type: ACTIONS.LOGOUT_ACCOUNT });
            }
          }}
        >
          See Flexicar Features
        </button>
      ) : user?.status === 'approved' ? (
        <button
          className={classNames(
            'btl-lg btn mx-auto mt-auto !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 md:!h-[60px]',
            {
              'loading bg-flexi-green-2':
                userStatus === RequestStatusEnum.PENDING,
            },
          )}
          onClick={() => {
            if (isMobile) {
              push('/onboarding');
            } else {
              push('/app/booking');
            }
          }}
        >
          {isMobile ? 'See Flexicar Features' : "Let's Start"}
        </button>
      ) : (
        user?.status === 'declined' && (
          <a
            href="mailto:info@flexicar.com.au"
            className="btl-lg btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2"
          >
            Contact Us
          </a>
        )
      )}
    </section>
  );
};

export default SignUpDone;
