import React from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  selectLoginModalOpen,
  setLoginModalOpen,
} from 'modules/auth/slice';
import Modal from 'components/Modal';
import useAppSelector from 'hooks/useAppSelector';
import { useMediaQuery } from 'react-responsive';
import LoginForm from 'features/LoginForm';

export const SignInButton = () => {
  const dispatch = useAppDispatch();
  const isLoginModalOpen = useAppSelector(selectLoginModalOpen);
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  return(
    <>
      <div className="hidden lg:block">
        <button
          onClick={() => dispatch(setLoginModalOpen(true))}
          className="!text-[14px] h-[40px] min-w-[100px] rounded-full bg-flexi-green-1 font-bold text-white"
        >
          Sign in
        </button>
      </div>
      <Modal
        width={isMobile ? '' : 'w-[490px]'}
        maxWidth={isMobile ? '' : '490px'}
        hasCloseButton={false}
        isModalOpen={isLoginModalOpen}
        handleClose={() => dispatch(setLoginModalOpen(false))}
        bgColor="bg-flexi-white-1"
        px={isMobile ? 'px-[25px]' : '!px-[60px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <LoginForm />
      </Modal>
    </>
  )
}

export default SignInButton;