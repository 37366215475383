import { call, put, cancelled } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';

import { handleAPIError } from 'common/errorHandler';
import { authenticatedClient } from 'app/apiClient';
import { RequestStatusEnum } from 'common/types';
import { fetchMe } from 'modules/me/sagas';
import {
  setBusinessDrivers,
  setFetchBusinessDriverStatus,
  setInviteDriverStatus,
  setRemoveDriverStatus,
  setBookingSettings,
  setNotificationSettings,
  setSaveSettingsStatus,
  setSettingsStatus,
  setCreatePersonalAccountDetailsStatus,
  setCreatePersonalAccountStatus,
  setCreatePersonalAccountCompleteStatus,
  setFetchBusinessDriverPhotoStatus,
  setBusinessDriverPhoto,
  setCreatePersonalAccountUpdateEmailStatus,
  setErrors,
  setFetchBusinessInviteesStatus,
  setBusinessInvitees,
  setVerifyInviteTokenStatus,
} from './slice';
import {
  setPendingCardUrl,
  setWindcaveCardStatus,
  setWindcaveCardUrl,
} from 'modules/transaction/slice';

export const SAGA_ACTIONS = {
  INVITE_DRIVERS: 'INVITE_DRIVERS',
  REMOVE_DRIVERS: 'REMOVE_DRIVERS',
  FETCH_BUSINESS_DRIVERS: 'FETCH_BUSINESS_DRIVERS',
  FETCH_BUSINESS_INVITEES: 'FETCH_BUSINESS_INVITEES',
  FETCH_BOOKING_SETTINGS: 'FETCH_BOOKING_SETTINGS',
  FETCH_NOTIFICATION_SETTINGS: 'FETCH_NOTIFICATION_SETTINGS',
  SAVE_BOOKING_SETTINGS: 'SAVE_BOOKING_SETTINGS',
  SAVE_NOTIFICATION_SETTINGS: 'SAVE_NOTIFICATION_SETTINGS',
  CREATE_PERSONAL_ACCOUNT_DETAILS: 'CREATE_PERSONAL_ACCOUNT_DETAILS',
  CREATE_PERSONAL_ACCOUNT: 'CREATE_PERSONAL_ACCOUNT',
  CREATE_PERSONAL_ACCOUNT_COMPLETE: 'CREATE_PERSONAL_ACCOUNT_COMPLETE',
  CREATE_PERSONAL_ACCOUNT_ADD_CARD: 'CREATE_PERSONAL_ACCOUNT_ADD_CARD',
  CREATE_PERSONAL_ACCOUNT_UPDATE_EMAIL: 'CREATE_PERSONAL_ACCOUNT_UPDATE_EMAIL',
  FETCH_BUSINESS_DRIVER_PHOTO: 'FETCH_BUSINESS_DRIVER_PHOTO',
  VERIFY_INVITE_TOKEN: 'VERIFY_INVITE_TOKEN',
};

const api = authenticatedClient();

export function* fetchBusinessDrivers() {
  try {
    yield put(setFetchBusinessDriverStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get('/business/drivers'));
    yield put(setFetchBusinessDriverStatus(RequestStatusEnum.SUCCESS));
    yield put(setBusinessDrivers(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setFetchBusinessDriverStatus(RequestStatusEnum.FAILED));
    }
  }
}

type BusinessInviteesPayload = {
  filter: string | null;
  limit: number;
  order: string | null;
  page: number;
  sort: string | null;
};

export function* fetchBusinessInvitees(
  action: PayloadAction<BusinessInviteesPayload>
) {
  try {
    yield put(setFetchBusinessInviteesStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(
        `/business/invitees?limit=${action.payload.limit}&page=${action.payload.page}${
          action.payload.filter ? `&filter=${action.payload.filter}` : ''
        }${
          action.payload.sort
            ? `&sort[${action.payload.order}]=${action.payload.sort}`
            : ''
        }`,
      ),
    );
    yield put(setFetchBusinessInviteesStatus(RequestStatusEnum.SUCCESS));
    yield put(setBusinessInvitees(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setFetchBusinessInviteesStatus(RequestStatusEnum.FAILED));
    }
  }
}

type InviteDriversPayload = {
  emails: string[];
};

export function* inviteDrivers(action: PayloadAction<InviteDriversPayload>) {
  try {
    yield put(setErrors(null));
    yield put(setInviteDriverStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/business/invite', action.payload));
    yield put(setInviteDriverStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.data.httpCode === 422) {
        yield put(setErrors(err.response.data));
      } else {
        handleAPIError(err.response);
      }
      yield put(setInviteDriverStatus(RequestStatusEnum.FAILED));
    }
  }
}

type RemoveDriversPayload = {
  ids: string[];
};

export function* removeDrivers(action: PayloadAction<RemoveDriversPayload>) {
  try {
    yield put(setRemoveDriverStatus(RequestStatusEnum.PENDING));
    yield call(() => api.delete(`/business/drivers`, { data: action.payload }));
    yield call(fetchBusinessDrivers);
    yield put(setRemoveDriverStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setRemoveDriverStatus(RequestStatusEnum.FAILED));
    }
  }
}

export function* fetchBookingSettings():any {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setSettingsStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get('/business/settings/bookings', {
      cancelToken: cancelTokenSource.token,
    }));
    yield put(setSettingsStatus(RequestStatusEnum.SUCCESS));
    yield put(setBookingSettings(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setSettingsStatus(RequestStatusEnum.FAILED));
    }
  }  finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type BookingSettingsPayload = {
  ids: string[];
};

export function* saveBookingSettings(
  action: PayloadAction<BookingSettingsPayload>,
) {
  try {
    yield put(setSaveSettingsStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/business/settings/bookings`, action.payload));
    yield put(setSaveSettingsStatus(RequestStatusEnum.SUCCESS));
    toast.success('Settings have been updated');
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setSaveSettingsStatus(RequestStatusEnum.FAILED));
      yield put(setErrors(err.response));
      // toast.error(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]);
    }
  }
}

export function* fetchNotificationSettings() {
  try {
    yield put(setSettingsStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get('/business/settings/notifications'),
    );
    yield put(setSettingsStatus(RequestStatusEnum.SUCCESS));
    yield put(setNotificationSettings(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setSettingsStatus(RequestStatusEnum.FAILED));
    }
  }
}

type NotificationSettingsPayload = {
  email: string[];
};

export function* saveNotificationSettings(
  action: PayloadAction<NotificationSettingsPayload>,
) {
  try {
    yield put(setSaveSettingsStatus(RequestStatusEnum.PENDING));
    yield call(() =>
      api.post(`/business/settings/notifications`, action.payload),
    );
    yield put(setSaveSettingsStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setSaveSettingsStatus(RequestStatusEnum.FAILED));
      yield put(setErrors(err.response));
    }
  }
}

export function* createPersonalAccountDetails() {
  try {
    yield put(setCreatePersonalAccountDetailsStatus(RequestStatusEnum.PENDING));
    yield call(() => api.get(`/business/drivers/register/details`));
    yield put(setCreatePersonalAccountDetailsStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(
        setCreatePersonalAccountDetailsStatus(RequestStatusEnum.FAILED),
      );
    }
  }
}

type CreatePersonalAccountPayload = {
  email?: string;
};

export function* createPersonalAccount(
  action: PayloadAction<CreatePersonalAccountPayload>,
) {
  try {
    yield put(setCreatePersonalAccountStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.post(`/business/drivers/register/account`, action.payload),
    );
    if (data.redirectTo === 'payment') {
      yield put(push('/app/add-account/payment'));
    } else {
      yield put(push('/app/add-account/payment'));
      // yield put(push('/app/add-account/otp'));
    }
    yield put(setCreatePersonalAccountStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setCreatePersonalAccountStatus(RequestStatusEnum.FAILED));
    }
  }
}

export function* createPersonalAccountComplete() {
  try {
    yield put(
      setCreatePersonalAccountCompleteStatus(RequestStatusEnum.PENDING),
    );
    yield call(() => api.post(`/business/drivers/register/complete`));
    yield put(push('/app/add-account/done'));
    yield put(
      setCreatePersonalAccountCompleteStatus(RequestStatusEnum.SUCCESS),
    );
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(
        setCreatePersonalAccountCompleteStatus(RequestStatusEnum.FAILED),
      );
    }
  }
}

export function* createPersonalAccountAddCard() {
  try {
    yield put(setPendingCardUrl(true));
    const { data } = yield call(() =>
      api.post(`/business/drivers/register/add-card`),
    );
    yield put(setWindcaveCardUrl(data.redirectTo));
    yield put(setWindcaveCardStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setWindcaveCardStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingCardUrl(false));
  }
}

type fetchDriverPhotoPayload = {
  driverId: number;
};

export function* fetchBusinessDriverPhoto(
  action: PayloadAction<fetchDriverPhotoPayload>,
) {
  try {
    yield put(setFetchBusinessDriverPhotoStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() => api.get(`/business/drivers/${action.payload.driverId}/photo`));
    yield put(setFetchBusinessDriverPhotoStatus(RequestStatusEnum.SUCCESS));

    yield put(setBusinessDriverPhoto(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setFetchBusinessDriverPhotoStatus(RequestStatusEnum.FAILED));
    }
  }
}

type CreatePersonalAccountUpdateEmailPayload = {
  email: string;
};

export function* createPersonalAccountUpdateEmail(action: PayloadAction<CreatePersonalAccountUpdateEmailPayload>) {
  try {
    yield put(setCreatePersonalAccountUpdateEmailStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post('/register/personal', action.payload));
    yield call(fetchMe);
    yield put(push('/register/otp'));
    yield put(setCreatePersonalAccountUpdateEmailStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    yield put(setCreatePersonalAccountUpdateEmailStatus(RequestStatusEnum.FAILED));
  }
  }
}

type VerifyInviteTokenPayload = {
  token: string;
};

export function* verifyInviteToken(
  action: PayloadAction<VerifyInviteTokenPayload>,
) {
  try {
    yield put(
      setVerifyInviteTokenStatus(RequestStatusEnum.PENDING),
    );
    yield call(() => api.post('/business/verify-invite-token', action.payload));
    yield put(setVerifyInviteTokenStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setErrors(err.response.data.errorCode.message));
      yield put(setVerifyInviteTokenStatus(RequestStatusEnum.FAILED));
    }
  }
}
