import React from 'react';
import { useUpdateEffect } from 'react-use';
import LottieAnimation from 'components/LottieAnimation';
import addedCardLottie from 'assets/json/icon-card-added.json';
import removeCardLottie from 'assets/json/icon-remove-card.json';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';
import { ReactComponent as IconPlus } from 'assets/images/plus.svg';
import { IMAGE_URL } from 'common/constants';
import { RequestStatusEnum } from 'common/types';
import { setWindcaveCardStatus } from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useWindcave from 'hooks/useWindcave';
import usePaymentMethod from 'hooks/usePaymentMethod';
import PendingLoader from 'components/PendingLoader';
import Modal from 'components/Modal';

interface Props {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSwitchAccount?: boolean;
}

const PaymentMethodModal = ({
  isModalOpen,
  setModalOpen,
  isSwitchAccount = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const { paymentCards, defaultPaymentCard } = usePaymentMethod();
  const [selectedCard, setSelectedCard] = React.useState<number>(0);
  const {
    windcaveCardStatus,
    addCardStatus,
    setAddCardStatus,
    isPendingAddCard,
    addCard,
    windcaveCardUrl,
  } = useWindcave();

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleChangePaymentMethod = () => {
    if (!isSwitchAccount) {
      dispatch({
        type: ACTIONS.UPDATE_DEFAULT_PAYMENT_CARD,
        payload: selectedCard,
      });
    }
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setAddCardStatus(null);
    dispatch(setWindcaveCardStatus(null));
  };

  useUpdateEffect(() => {
    if (defaultPaymentCard) {
      setSelectedCard(defaultPaymentCard?.id);
    }
  }, [defaultPaymentCard]);

  useUpdateEffect(() => {
    if (addCardStatus === RequestStatusEnum.CANCELLED) {
      setModalOpen(false);
    }
  }, [addCardStatus]);

  const renderModalContent = () => {
    if (isPendingAddCard) {
      return (
        <div>
          <PendingLoader />
          <p className="mt-[30px] text-center text-lg">
            Redirecting to Windcave...
          </p>
        </div>
      );
    }
    if (addCardStatus === RequestStatusEnum.SUCCESS) {
      return (
        <div className="flex flex-col items-center">
          <LottieAnimation lottieFile={addedCardLottie} isStopped={!isModalOpen} />
          <h1 className="title form-control !mb-[50px] text-center text-[20px] font-bold md:text-[34px]">
            Card Added Successfully!
          </h1>
          <button
            className={`btn max-h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 md:max-h-[60px]`}
            onClick={() => {
              dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
              handleModalClose();
            }}
          >
            Ok
          </button>
        </div>
      );
    }
    if (addCardStatus === RequestStatusEnum.FAILED) {
      return (
        <div className="flex flex-col items-center">
          <LottieAnimation lottieFile={removeCardLottie} isStopped={!isModalOpen} />
          <h1 className="title form-control !mb-[50px] text-center text-[20px] font-bold md:text-[34px]">
            Card Failed
          </h1>
          <p className="mb-[30px] text-center">
            The card number you&apos;ve entered is invalid. Please try another
            card
          </p>
          <button
            className={`btn max-h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 md:max-h-[60px]`}
            onClick={handleModalClose}
          >
            Try again
          </button>
        </div>
      );
    }
    if (windcaveCardStatus === RequestStatusEnum.SUCCESS) {
      return (
        <iframe
          src={windcaveCardUrl}
          className="h-[70vh] lg:h-[730px] w-auto lg:w-full mx-[-30px] mb-[-50px] lg:mx-0 lg:mb-0"
        />
      );
    }
    if (windcaveCardStatus === RequestStatusEnum.FAILED) {
      return (
        <div className="flex flex-col items-center gap-[20px]">
          <ErrorIcon className="icon" />
          <h1 className="text-center text-[20px] font-bold md:text-[34px]">
            Something went wrong.
          </h1>
          <p className="mb-[30px] text-center">
            There seems to be a problem.
            <br />
            Please try again later or contact support{' '}
            <span className="whitespace-nowrap">for assistance.</span>
          </p>
          <label
            className={`btn w-full rounded-full border-flexi-green-1 bg-flexi-green-1 text-[16px] capitalize`}
            onClick={handleModalClose}
          >
            try again
          </label>
        </div>
      );
    }
    return (
      <div className="mx-[20px] flex flex-col items-center md:mx-0">
        <h1 className="form-control mb-[40px] text-center text-[20px] font-bold md:text-3xl">
          Change Payment Method
        </h1>
        <ul className="flex w-full flex-col gap-5">
          {paymentCards?.map((card) => {
            let imageName;
            let isImageExist = true;
            try {
              imageName = require(`assets/images/card-${card?.type}.svg`);
              // do stuff
            } catch (ex) {
              isImageExist = false;
            }
            return (
              <li
                key={card?.id}
                onClick={() => setSelectedCard(card?.id)}
                className={classNames(
                  'flex w-full place-items-center justify-between rounded-md border-[1px] border-transparent p-5 shadow-[0_0_5px] shadow-flexi-white-2 duration-200 hover:shadow-flexi-black-4',
                  {
                    '!border-primary': selectedCard === card?.id,
                  },
                )}
                role="button"
              >
                <div className="flex gap-2 md:gap-3">
                  <p
                    style={{
                      backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                    className={classNames(
                      'grid w-[40px] place-items-center overflow-hidden bg-[center_left] bg-no-repeat text-center text-[10px] uppercase md:w-[55px]',
                      {
                        'border-[1px]': !isImageExist,
                      },
                    )}
                  >
                    {!isImageExist && card?.type}
                  </p>
                  <div className="flex flex-col">
                    <p className="w-[150px] text-[12px] capitalize md:w-[200px] md:text-[14px]">
                      <span className="pr-1 font-bold">●●●● ●●●● ●●●●</span>
                      {card?.cardNumber}
                    </p>

                    <p className="text-[10px] md:text-[14px]">
                      Expires on {card?.expiryMonth}/{card?.expiryYear}
                    </p>
                  </div>
                </div>
                {card?.isDefault ? (
                  <p className="hidden text-[12px] capitalize text-flexi-black-3/30 md:block md:text-[16px]">
                    default
                  </p>
                ) : null}
              </li>
            );
          })}

          <li
            role="button"
            className="grid h-[80px] w-full place-items-center  rounded-md border-[1px] border-transparent p-5 py-4 text-[35px] text-flexi-orange-1 shadow-[0_0_5px] shadow-flexi-white-2 duration-200 hover:shadow-flexi-black-4"
            onClick={addCard}
          >
            <IconPlus />
          </li>
        </ul>
        <div className="button mt-[50px] mb-5  flex w-full justify-center ">
          <label
            htmlFor="update-card-modal"
            className={`btn h-[60px] max-h-[50px] w-full rounded-full border-flexi-green-1 bg-flexi-green-1 px-10 text-[16px] capitalize hover:bg-flexi-green-2 md:max-h-[60px]`}
            onClick={handleChangePaymentMethod}
          >
            Confirm
          </label>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleClose={handleModalClose}
      px={
        windcaveCardStatus === RequestStatusEnum.SUCCESS
          ? 'px-[40px]'
          : isMobile
          ? 'px-[25px]'
          : 'px-[60px]'
      }
      mx={
        windcaveCardStatus === RequestStatusEnum.SUCCESS
          ? '15px'
          : isMobile
          ? '30px'
          : '60px'
      }
      padding="p-[60px]"
    >
      {renderModalContent()}
    </Modal>
  );
};

export default PaymentMethodModal;
