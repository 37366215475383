import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IInvoiceList, RequestStatusEnum } from 'common/types';

type InitialState = {
  pending: boolean;
  outstandingBalances: IInvoiceList | null;
  pendingPayOutstandingBalance: boolean;
  pendingPaySingleInvoice: boolean;
  paySelectedInvoiceStatus: RequestStatusEnum | null;
  outstandingBalanceStatus: RequestStatusEnum | null;
  paySingleInvoiceStatus: RequestStatusEnum | null;
  invoiceUrl: string | undefined;
};

const initialState: InitialState = {
  pending: false,
  outstandingBalances: null,
  pendingPayOutstandingBalance: false,
  pendingPaySingleInvoice: false,
  paySelectedInvoiceStatus: null,
  paySingleInvoiceStatus: null,
  outstandingBalanceStatus: null,
  invoiceUrl: undefined,
};

export const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    reset: () => initialState,
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setOutstandingBalances: (state, action: PayloadAction<IInvoiceList>) => {
      state.outstandingBalances = action.payload;
    },
    setPendingPayOutstandingBalance: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.pendingPayOutstandingBalance = action.payload;
    },
    setPendingPaySingleInvoice: (state, action: PayloadAction<boolean>) => {
      state.pendingPaySingleInvoice = action.payload;
    },
    setPaySelectedInvoiceStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.paySelectedInvoiceStatus = action.payload;
    },
    setOutstandingBalanceStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.outstandingBalanceStatus = action.payload;
    },
    setPaySingleInvoiceStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.paySingleInvoiceStatus = action.payload;
    },
    setInvoiceUrl: (state, action: PayloadAction<string | undefined>) => {
      state.invoiceUrl = action.payload;
    },
  },
});

export const {
  reset,
  setPending,
  setOutstandingBalances,
  setPendingPayOutstandingBalance,
  setPendingPaySingleInvoice,
  setPaySelectedInvoiceStatus,
  setInvoiceUrl,
  setOutstandingBalanceStatus,
  setPaySingleInvoiceStatus,
} = slice.actions;

export const selectPending = (state: RootState): boolean =>
  state.invoice.pending;
export const selectOutstandingBalances = (state: RootState): IInvoiceList | null =>
  state.invoice.outstandingBalances;
export const selectPendingPayOutstandingBalance = (state: RootState): boolean =>
  state.invoice.pendingPayOutstandingBalance;
export const selectPendingPaySingleInvoice = (state: RootState): boolean =>
  state.invoice.pendingPaySingleInvoice;
export const selectPaySelectedInvoiceStatus = (
  state: RootState,
): RequestStatusEnum | null => state.invoice.paySelectedInvoiceStatus;
export const selectOutstandingBalanceStatus = (
  state: RootState,
): RequestStatusEnum | null => state.invoice.outstandingBalanceStatus;
export const selectPaySingleInvoiceStatus = (
  state: RootState,
): RequestStatusEnum | null => state.invoice.paySingleInvoiceStatus;
export const selectInvoiceUrl = (state: RootState): string | undefined =>
  state.invoice.invoiceUrl;

export default slice.reducer;
