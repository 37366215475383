import React, { useEffect, useState } from 'react';
import PageLayout from 'components/layouts/PageLayout';
import { ApprovedAuthenticatedTopNav, GuestTopNav } from 'components/TopNav';
// import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import LoginForm from 'features/LoginForm';
import Booking from 'features/BookingOptimized';
import { useMediaQuery } from 'react-responsive';
import MobileHomePage from 'features/MobileHomePage';
import { getToken } from 'common/helpers';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import LogoutModal from 'components/LogoutModal';
import {
  selectPendingLogout,
  // setIsTokenExpired,
} from 'modules/auth/slice';
import useAppSelector from 'hooks/useAppSelector';
import {  useRedirectToRegistration } from 'hooks/useRedirect';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
// import ErrorModal from 'components/ErrorModal';
// import { createPortal } from 'react-dom';
// import { BottomSignIn } from 'components/HomeBottomSignIn';

const HomePage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const token = getToken();
  const dispatch = useAppDispatch();
  const isLogoutPending = useAppSelector(selectPendingLogout);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });
  useRedirectToRegistration()
  // const topNav = document.getElementById('topnav');
  // const bottomSignIn = document.getElementById('bottom_signin');

  useEffect(() => {
    if (token) {
      dispatch({ type: ACTIONS.FETCH_ME });
    }
  }, [token]);

  const { pathname } = useLocation();

  return (
    <PageLayout>
      <Helmet>
        <title>Book Now</title>
        <meta name="description" content="Flexicar Book Now" />
      </Helmet>
      {isMobile ? (
        <MobileHomePage />
      ) : (
        <>
          {/* {topNav ?
            createPortal(
              <>{token !== null ? <ApprovedAuthenticatedTopNav /> : <GuestTopNav />}</>,
              topNav)
          :<></>} */}
          {token ? <ApprovedAuthenticatedTopNav /> : 
          (token === null ) && pathname !== `/` ?
          <GuestTopNav /> : <></>}
          <Booking />
        </>
      )}
      <Modal
            width={isMobile ? '' : 'w-[490px]'}
            maxWidth={isMobile ? '' : '490px'}
            hasCloseButton={false}
            isModalOpen={isLoginModalOpen}
            handleClose={() => setIsLoginModalOpen(false)}
            bgColor="bg-flexi-white-1"
            px={isMobile ? 'px-[25px]' : '!px-[60px]'}
            mx={isMobile ? '30px' : '0px'}
          >
            <LoginForm />
          </Modal>
      {isLogoutPending && <LogoutModal isOpen={isLogoutPending} />}
      {/* <ErrorModal /> */}
    </PageLayout>
  );
};

export default HomePage;
