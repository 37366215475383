export interface CancelationReason {
  id: number;
  message: string;
}

export const CANCELLATION_REASONS: CancelationReason[] = [
  {
    id: 1,
    message: 'I now have access to a car',
  },
  {
    id: 2,
    message: 'I am transferring to a competitor service',
  },
  {
    id: 3,
    message: 'Prompted by the annual membership renewal fee',
  },
  {
    id: 4,
    message: 'I moved out of the city with no Flexicar near me',
  },
  {
    id: 5,
    message: "The Flexicar nearest to me isn't available when I need it",
  },
  {
    id: 6,
    message: 'I moved overseas',
  },
  {
    id: 7,
    message: 'I only joined as a temporary member',
  },
  {
    id: 8,
    message: "My financial situation has changed and I'm cutting costs",
  },
  {
    id: 9,
    message: "I'm just not using the service",
  },
  {
    id: 10,
    message: 'I bought a car',
  },
  {
    id: 11,
    message: 'Other (please specify below)',
  },
];
