import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import gsap from 'gsap';

import lottieSometimes from 'assets/json/icon-sometimes.json';
import lottieOften from 'assets/json/icon-often.json';
import lottieSr from 'assets/json/icon-sr.json';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';
import { getToken } from 'common/helpers';
import { IRegisterPlan, RequestStatusEnum } from 'common/types';
import {
  selectIsSenior,
  selectPlans,
  selectPlansStatus,
  selectUser,
} from 'modules/me/slice';
import { setErrorPromo } from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';
import Card from './Card';
import Inclusions from './Inclusions';
import ListAndRates from './ListAndRates';

const SignUpPlanPersonal = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const user = useAppSelector(selectUser);
  const isSenior = useAppSelector(selectIsSenior);
  const plans = useAppSelector(selectPlans);
  const plansStatus = useAppSelector(selectPlansStatus);
  // const isPending = useAppSelector(selectPendingUpdate);
  const token = getToken();
  const [planSelected, setPlanSelected] = useState<IRegisterPlan | null>(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const senior = {
    loop: false,
    autoplay: true,
    animationData: lottieSr,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const sometimes = {
    loop: false,
    autoplay: true,
    animationData: lottieSometimes,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const often = {
    loop: false,
    autoplay: true,
    animationData: lottieOften,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSelectPlan = (plan: number) => {
    const payload = { planId: plan };
    dispatch({ type: ACTIONS.REGISTER_PLAN_SELECTED_V2, payload });
  };

  useEffect(() => {
    if (user && token) {
      dispatch({
        type: ACTIONS.FETCH_VEHICLE_LIST,
        payload: {
          isSenior,
        },
      });
    }
  }, [user, token]);

  useEffectOnce(() => {
    if (token) {
      dispatch({
        type: ACTIONS.GET_PLANS_V2,
        payload: {
          isSenior,
        },
      });
    }
    dispatch(setErrorPromo(null));
  });

  useUpdateEffect(() => {
    setPlanSelected(plans[0]);
  }, [plans]);

  return (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-90px)] w-full max-w-[600px] flex-col pt-[42px]"
    >
      <button
        className="absolute flex h-[25px] w-[25px] items-center justify-center rounded-full bg-flexi-orange-1"
        onClick={() => push('/register-v2/plan')}
      >
        <ArrowIcon className="rotate-180 fill-white" />
      </button>
      {isMobile ? (
        <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-left md:text-[40px]">
          Choose the plan
          <br />
          that&apos;s right for you
        </h2>
      ) : (
        <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[50px] text-flexi-orange-1 md:mb-0 md:text-[28px]">
          select a plan
        </h2>
      )}

      {planSelected && plansStatus !== RequestStatusEnum.PENDING ? (
        <>
          <div className="flex flex-col items-start">
            {plans[0].name.toLowerCase() === 'senior' ? (
              <Lottie options={senior} height={200} width={200} />
            ) : (
              <>
                <div className="mx-auto">
                  <Lottie
                    options={
                      planSelected?.id === plans[0].id ? sometimes : often
                    }
                    height={200}
                    width={200}
                  />
                </div>
                <ul className="mx-auto mb-[25px] flex w-full gap-[20px] rounded-[10px] bg-white px-[8px] py-[7px]">
                  {plans.map((plan: IRegisterPlan) => {
                    return (
                      <li
                        key={plan.id}
                        className={classNames(
                          'w-full py-[13px] text-center text-[16px] font-bold',
                          {
                            'rounded-[10px] bg-flexi-orange-1 text-white':
                              planSelected?.id === plan.id,
                          },
                        )}
                        onClick={() => {
                          setPlanSelected(plan);
                        }}
                        role="button"
                      >
                        <p className="text-[14px] md:self-end md:text-[16px]">
                          {plan.name}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            <div className="w-full">
              <Card plan={planSelected} key={planSelected?.name} />
            </div>

            <ListAndRates />
            <Inclusions inclusions={planSelected?.inclusions} />
          </div>
          {isMobile ? (
            <div className="fixed bottom-0 ml-[-25px] w-[calc(100%)] rounded-t-[10px] bg-white px-[25px] shadow-lg md:block">
              <button
                onClick={() => handleSelectPlan(planSelected!.id)}
                className="btn btn-primary my-[25px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px] md:mt-[25px] md:h-[60px]"
              >
                Select Plan
              </button>
            </div>
          ) : (
            <button
              onClick={() => handleSelectPlan(planSelected!.id)}
              className="btn btn-primary mt-[70px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px] md:h-[60px]"
            >
              Select Plan
            </button>
          )}
        </>
      ) : (
        <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
          <PendingLoader size={100} />
        </section>
      )}
    </section>
  );
};

export default SignUpPlanPersonal;
