import React, { Dispatch, useEffect } from 'react';
import { InvalidText } from 'components/Typography';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectApplyPromoStatus,
  selectErrorPromo,
  selectRemovePromoStatus,
} from 'modules/transaction/slice';
// import { selectRewardPartners } from 'modules/me/slice';
import CustomInput from 'components/CustomInput';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { IBilling, RequestStatusEnum } from 'common/types';
import { ReactComponent as IconCheck } from 'assets/images/check.svg';
import {
  selectRegistrationVFF,
  selectUser,
  setRegistrationVFF,
} from 'modules/me/slice';

interface Props {
  vffMembershipId: string | undefined;
  setVffMembershipId: Dispatch<React.SetStateAction<string | undefined>>;
  accessCode: string | undefined;
  setAccessCode: Dispatch<React.SetStateAction<string | undefined>>;
  partnerMembershipId: string | undefined;
  setPartnerMembershipId: Dispatch<React.SetStateAction<string | undefined>>;
  partner: string | undefined;
  setPartner: Dispatch<React.SetStateAction<string | undefined>>;
  partnerMembershipError: string | null;
  setPartnerMembershipError: Dispatch<React.SetStateAction<string | null>>;
  billing: IBilling | null;
}

type FormValues = {
  code: string;
};

const validationSchema = Yup.object().shape({
  code: Yup.string(),
});

const PaymentPromotions = ({
  billing,
  vffMembershipId,
  setVffMembershipId,
  accessCode,
  setAccessCode,
}: 
Props) => {
  const dispatch = useAppDispatch();
  const errorPromo = useAppSelector(selectErrorPromo);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  // const partners = useAppSelector(selectRewardPartners);
  const applyPromoStatus = useAppSelector(selectApplyPromoStatus);
  const removePromoStatus = useAppSelector(selectRemovePromoStatus);
  const user = useAppSelector(selectUser);
  const registrationVFF = useAppSelector(selectRegistrationVFF);
  // const [isMembershipActive, setIsMembershipActive] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = ({ code }: FormValues) => {
    if (!isEmpty(code)) {
      const payload = {
        code: code.replaceAll(' ', ''),
        category: 'membership',
      };
      dispatch({
        type: ACTIONS.APPLY_PROMO,
        payload,
      });
    } else if (billing?.membershipFees.promo) {
      const payload = {
        code: billing?.membershipFees.promo.code,
        category: 'membership',
      };
      dispatch({
        type: ACTIONS.REMOVE_PROMO,
        payload,
      });
    }
  };

  const handleVFFBlur = (e: any) => {
    if (e.target.value) dispatch(setRegistrationVFF(e.target.value));
  };

  useEffect(() => {
    if (registrationVFF) setVffMembershipId(registrationVFF);
  }, [registrationVFF]);

  // const handleSelect = (e: any) => {
  //   const optionIndex = e.target.selectedIndex;
  //   setPartner(e.target.options[optionIndex].id);

  //   if (e.target.value === 'None') {
  //     setPartnerMembershipError(null);
  //     setPartnerMembershipId('');
  //     setIsMembershipActive(false);
  //   }
  // };

  // const validatePartnerMembership = () => {
  //   if (partner && !partnerMembershipId) {
  //     setPartnerMembershipError(
  //       'The Partner Membership ID field is required when partner is present.',
  //     );
  //   } else {
  //     setPartnerMembershipError(null);
  //   }
  // };

  return (
    <article className="">
      {!user?.organisation?.isPartner && (
        <form
          className="mb-[30px] flex items-start gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-control flex-1">
            <label className="mb-[15px] text-[16px] font-bold md:mb-[20px] md:text-[22px] md:font-normal">
              Promotions
            </label>
            <CustomInput
              title="Promo code"
              error={
                applyPromoStatus === RequestStatusEnum.FAILED ||
                removePromoStatus === RequestStatusEnum.FAILED ||
                errors?.code
              }
              disabled={
                applyPromoStatus === RequestStatusEnum.PENDING ||
                removePromoStatus === RequestStatusEnum.PENDING
              }
            >
              <input
                type="text"
                placeholder="promo code"
                className={classNames(
                  'peer input input-bordered w-full self-end disabled:border-zinc-300 disabled:text-zinc-300',
                  {
                    'border-red-500':
                      applyPromoStatus === RequestStatusEnum.FAILED ||
                      removePromoStatus === RequestStatusEnum.FAILED ||
                      errors?.code,
                    // 'disabled:!border-red-500': promoStatus || errors?.code,
                  },
                )}
                {...register('code')}
                autoComplete="off"
                disabled={
                  applyPromoStatus === RequestStatusEnum.PENDING ||
                  removePromoStatus === RequestStatusEnum.PENDING
                }
                defaultValue={billing?.membershipFees.promo?.code}
                onBlur={handleSubmit(onSubmit)}
              />
              {applyPromoStatus === RequestStatusEnum.PENDING && (
                <span className="absolute right-[15px] h-[20px]  w-[20px] animate-spin rounded-full border-[3px] border-t-transparent " />
              )}
              {removePromoStatus === RequestStatusEnum.PENDING && (
                <span className="absolute right-[15px] h-[20px]  w-[20px] animate-spin rounded-full border-[3px] border-t-transparent " />
              )}
              {applyPromoStatus === RequestStatusEnum.SUCCESS && (
                <div className="absolute right-[15px] grid h-[90%] w-[25px] place-items-center rounded-md bg-white">
                  <IconCheck className="fill-flexi-green-1" />
                </div>
              )}
              {removePromoStatus === RequestStatusEnum.SUCCESS && (
                <div className="absolute right-[15px] grid h-[90%] w-[25px] place-items-center rounded-md bg-white">
                  <IconCheck className="fill-flexi-green-1" />
                </div>
              )}
            </CustomInput>
            {applyPromoStatus === RequestStatusEnum.SUCCESS && (
              <p className="mt-[10px] ml-1 text-[12px]">Promo code applied</p>
            )}
            {removePromoStatus === RequestStatusEnum.SUCCESS && (
              <p className="mt-[10px] ml-1 text-[12px]">Promo code removed</p>
            )}
            {errors.code && <InvalidText>The selected code is invalid.</InvalidText>}
            {errorPromo && <InvalidText>The selected code is invalid.</InvalidText>}
          </div>
        </form>
      )}

      <div className="form-control mb-[30px] w-full gap-[15px] md:gap-[20px]">
        <label className="text-[20px] font-bold md:text-[22px] md:font-normal">
          Velocity Frequent Flyer
        </label>
        <CustomInput title="Velocity Number">
          <input
            placeholder="velocity number"
            type="text"
            className={classNames(
              'peer input input-bordered w-full focus:outline-none',
            )}
            // defaultValue={registrationVFF || vffMembershipId}
            value={vffMembershipId}
            onChange={(e) => setVffMembershipId(e.target.value)}
            onBlur={handleVFFBlur}
            autoComplete="off"
          />
        </CustomInput>
      </div>

      <div className="form-control w-full gap-[15px] md:gap-[20px]">
        <label className="text-[20px] font-bold md:text-[22px] md:font-normal">
          Access Code
        </label>
        <CustomInput title="Access Code">
          <input
            placeholder="velocity number"
            type="text"
            className={classNames(
              'peer input input-bordered w-full focus:outline-none',
            )}
            // defaultValue={registrationVFF || vffMembershipId}
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            // onBlur={handleAccessCodeBlur}
            autoComplete="off"
          />
        </CustomInput>
      </div>

      {/* <div className="form-control w-full ">
        <label className="mb-[20px] text-[22px]">Partner Promotions</label>
        <div className="relative flex gap-4">
          <select
            className="select input-bordered relative h-[59px] w-6/12 focus:outline-none"
            onChange={(e) => handleSelect(e)}
            defaultValue={undefined}
          >
            <option value={undefined}>None</option>
            {partners?.map((partner) => {
              return (
                <option id={`${partner.slug}`} key={partner.id}>
                  {partner.name}
                </option>
              );
            })}
          </select>

          <CustomInput
            title="Membership Number"
            handleActive={() => setIsMembershipActive(true)}
            error={partnerMembershipError}
            disabled={!partner}
          >
            <input
              value={partnerMembershipId}
              onChange={(e) => setPartnerMembershipId(e.target.value)}
              type="input"
              // disabled={!partner}
              className={classNames(
                '!disabled::bg-red-400 input input-bordered w-full focus:outline-none',
                {
                  'pointer-events-none': !partner,
                  'border-red-500': partnerMembershipError,
                },
              )}
              onBlur={(e) => {
                validatePartnerMembership();
                !e.target.value && setIsMembershipActive(false);
              }}
              onFocus={() => setIsMembershipActive(true)}
            />
          </CustomInput>
        </div>
        {partnerMembershipError && (
          <InvalidText>{partnerMembershipError}</InvalidText>
        )}
      </div> */}
    </article>
  );
};

export default PaymentPromotions;
