import React, { useState, useEffect } from 'react';

import {
  isSupportedCountry,
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from 'react-phone-number-input/input';
import classNames from 'classnames';
import * as Yup from 'yup';

import { ReactComponent as ArrowDown } from 'assets/images/arrow2.svg';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import { ICountryCode, RequestStatusEnum } from 'common/types';
import { selectUser } from 'modules/me/slice';
import {
  selectSendEnquiryPending,
  setSendEnquiryPending,
} from 'modules/public/slice';
import countries from 'common/countries';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';
import CustomInput from 'components/CustomInput';
import Modal from 'components/Modal';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

type FormValues = {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .typeError('Name is required')
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'Name can only contain Latin letters.',
    )
    .matches(/^\s*\S[\s\S]*$/, '* This field cannot contain only blankspaces')
    .max(100),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  subject: Yup.string()
    .required('Enquiry type is required')
    .typeError('Enquiry type is required'),
  message: Yup.string()
    .required('Message is required')
    .typeError('Message is required')
    .matches(/^\s*\S[\s\S]*$/, '* This field cannot contain only blankspaces'),
});

const ContactForm = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const sendEnquiryStatus = useAppSelector(selectSendEnquiryPending);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [phoneCode, setPhoneCode] = useState<any>('AU');
  const [phone, setPhone] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const enquiryOptions = [
    'Member Queries',
    'Booking Queries',
    'Invoice Queries',
    'Fines & Infringements Queries',
    'Other',
  ];

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const clearFormFields = () => {
    if (!user) {
      resetField('name');
      resetField('email');
    }

    resetField('phone');
    resetField('subject');
    resetField('message');
    setPhoneCode('AU');
    setPhone('');
    setSubmitClicked(false);
    setIsModalOpen(false);
    dispatch(setSendEnquiryPending(null));
  };

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  // Set form field value after user is loaded
  useEffect(() => {
    if (user) {
      setValue('name', user?.fullname);
      setValue('email', user?.email);
    }
  }, [user, setValue]);
  
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (isValidPhoneNumber(phone || `+61`, phoneCode)) {
      const payload = {
        ...values,
        phone: phone,
      };

      dispatch({
        type: ACTIONS.SEND_HELP_CENTER_MAIL,
        payload,
      });
    }
    setIsModalOpen(true);
  };

  const handleMobileSelectChange = (e: any) => {
    setPhoneCode(e.target.value);
    setPhone('');
  };

  const renderModalContent = () => {
    if (sendEnquiryStatus === RequestStatusEnum.PENDING) {
      return <PendingLoader />;
    }
    if (sendEnquiryStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <IconSuccess className="icon" />
          <h1 className="title form-control text-center text-[20px] font-bold md:text-4xl md:leading-[42px]">
            Your inquiry has been
            <br />
            successfully sent!
          </h1>
          <p className="!font-400 mt-[20px] text-center text-[16px] md:text-[16px]">
            We received your inquiry. We&apos;ll get back to you as soon as we
            can.
          </p>
          <button
            className={`btn mt-[40px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
            onClick={() => clearFormFields()}
          >
            Ok
          </button>
        </>
      );
    }
    if (sendEnquiryStatus === RequestStatusEnum.FAILED) {
      return (
        <>
          <LottieAnimation lottieFile={errorLottie} isStopped={!isModalOpen} />
          <h1 className="title form-control text-center text-[20px] font-bold md:text-4xl md:leading-[42px]">
            Failed to send!
          </h1>
          <p className="mt-[20px] text-center text-[16px] md:text-[16px]">
            Unable to send inquiry.
          </p>
          <button
            className={`btn mt-[40px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
            onClick={() => clearFormFields()}
          >
            Ok
          </button>
        </>
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[30px] text-[12px]"
      id="enquiry-form"
    >
      <p className="font-primary hidden text-[16px] font-bold uppercase">
        Email enquiry
      </p>
      <div className="grid grid-cols-2 gap-x-[20px] gap-y-[30px]">
        <CustomInput title="Name*" error={errors?.name}>
          <input
            type="text"
            defaultValue={user ? user.fullname : ''}
            className={classNames(
              'peer input input-bordered w-full pr-[50px]',
              {
                '!border-red-500': errors?.name,
              },
            )}
            {...register('name')}
            readOnly={
              user !== null ||
              sendEnquiryStatus === RequestStatusEnum.PENDING ||
              sendEnquiryStatus === RequestStatusEnum.SUCCESS
            }
          />
          {errors?.name && (
            <span className="absolute bottom-[-24px] left-[12px] text-[11.5px] font-normal text-red-500">
              {errors?.name.message}
            </span>
          )}
        </CustomInput>
        <CustomInput title="Email Address*" error={errors?.email}>
          <input
            type="text"
            defaultValue={user ? user.email : ''}
            className={classNames(
              'peer input input-bordered w-full pr-[50px]',
              {
                '!border-red-500': errors?.email,
              },
            )}
            {...register('email')}
            readOnly={
              user !== null ||
              sendEnquiryStatus === RequestStatusEnum.PENDING ||
              sendEnquiryStatus === RequestStatusEnum.SUCCESS
            }
          />
          {!isEmpty(errors.email) && (
            <span className="absolute bottom-[-24px] left-[12px] text-[11.5px] font-normal text-red-500">
              {errors?.email?.message}
            </span>
          )}
        </CustomInput>
        <CustomInput title="Enquiry Type*" error={errors?.subject}>
          <select
            className={classNames(
              'peer input input-bordered w-full pr-[50px] !text-[14px]',
              {
                '!border-red-500': errors?.subject,
              },
            )}
            defaultValue=""
            {...register('subject')}
            disabled={
              sendEnquiryStatus === RequestStatusEnum.PENDING ||
              sendEnquiryStatus === RequestStatusEnum.SUCCESS
            }
          >
            <option value="" hidden>
              Enquiry Type*
            </option>
            {enquiryOptions.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
          <ArrowDown className="pointer-events-none absolute right-[20px] top-[50%] h-[15px] translate-y-[-50%] -rotate-90" />
          {errors?.subject && (
            <span className="absolute bottom-[-24px] left-[12px] text-[11.5px] font-normal text-red-500">
              {errors?.subject.message}
            </span>
          )}
        </CustomInput>
        <CustomInput
          title="Phone Number*"
          error={
            !isValidPhoneNumber(phone || `+61`, phoneCode) && submitClicked
          }
          extraClass="md:!h-[70px]"
        >
          <div
            className={classNames(
              'peer input input-bordered w-full pr-[50px] !text-[14px] ',
              {
                '!border-red-500':
                  !isValidPhoneNumber(phone || `+61`, phoneCode) &&
                  submitClicked,
                'cursor-not-allowed bg-[#FAFAFA]':
                  sendEnquiryStatus === RequestStatusEnum.PENDING ||
                  sendEnquiryStatus === RequestStatusEnum.SUCCESS,
              },
            )}
          >
            <select
              onChange={handleMobileSelectChange}
              onClick={(e) => e.stopPropagation()}
              defaultValue="AU"
              className="h-full max-w-[15px] cursor-pointer bg-white outline-none disabled:cursor-not-allowed disabled:bg-[#FAFAFA]"
              disabled={
                sendEnquiryStatus === RequestStatusEnum.PENDING ||
                sendEnquiryStatus === RequestStatusEnum.SUCCESS
              }
            >
              {countries.map((country: ICountryCode, i: number) => {
                return (
                  isSupportedCountry(country.code) && (
                    <option
                      key={i}
                      value={country.code}
                      className="!mb-[5px] !pb-[5px] text-[14px]"
                    >
                      {country.name} &#91;+
                      {getCountryCallingCode(country.code)}
                      &#93;
                    </option>
                  )
                );
              })}
            </select>
            <ArrowDown className="pointer-events-none absolute left-[15px] top-[55%] !h-[15px] translate-y-[-50%] -rotate-90 bg-white" />
            <Input
              country={phoneCode}
              international
              withCountryCallingCode={true}
              value={phone}
              onChange={setPhone}
              placeholder="Phone Number"
              className="absolute top-[50%] ml-[10px] w-[80%] translate-y-[-30%] bg-transparent outline-none disabled:cursor-not-allowed"
              maxLength={
                isValidPhoneNumber(phone || `+61`, phoneCode)
                  ? phone.length
                  : 17
              }
              disabled={
                sendEnquiryStatus === RequestStatusEnum.PENDING ||
                sendEnquiryStatus === RequestStatusEnum.SUCCESS
              }
            />
          </div>
          {!isValidPhoneNumber(phone || `+61`, phoneCode) && submitClicked && (
            <span className="absolute bottom-[-24px] left-[12px] text-[11.5px] font-normal text-red-500 md:bottom-[-12px]">
              Please enter a valid phone number
            </span>
          )}
        </CustomInput>
      </div>
      <div className="relative h-[180px]">
        <CustomInput
          title="Inquiry/Message"
          extraClass="h-[150px] md:h-[150px] relative"
          error={errors?.message}
        >
          <textarea
            className={classNames(
              'scrollbar peer textarea textarea-bordered absolute top-[19px] h-[150px] w-full resize-none rounded-t-none border-t-transparent pt-[30px] font-medium leading-[1.429] focus:outline-0',
              {
                'border-red-500': errors?.message,
              },
            )}
            {...register('message')}
          ></textarea>
        </CustomInput>
        {errors?.message && (
          <span className="absolute left-[12px] top-[180px] text-[11.5px] font-normal text-red-500">
            {errors?.message.message}
          </span>
        )}
      </div>
      <div className="mt-[25px] flex flex-col items-center gap-[30px] md:flex-row">
        <button
          type="submit"
          onClick={() => setSubmitClicked(true)}
          className="btn h-[60px] max-h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 disabled:text-white md:max-h-[60px]"
          disabled={
            sendEnquiryStatus === RequestStatusEnum.PENDING ||
            sendEnquiryStatus === RequestStatusEnum.SUCCESS
          }
        >
          Submit
        </button>
      </div>
      {sendEnquiryStatus === RequestStatusEnum.SUCCESS && (
        <p className="text-primary">Enquiry successfully submitted!</p>
      )}
      <Modal
        isModalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          if (sendEnquiryStatus === RequestStatusEnum.SUCCESS) {
            clearFormFields();
          }
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        {renderModalContent()}
      </Modal>
    </form>
  );
};

export default ContactForm;
