import React, { useRef, useLayoutEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useJsApiLoader } from '@react-google-maps/api';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import gsap from 'gsap';

import { ReactComponent as IconError } from 'assets/images/error.svg';
import { GOOGLE_API } from 'common/constants';
import { RequestStatusEnum } from 'common/types';
import { useRedirectToSignUp } from 'hooks/useRedirect';
import { selectRegisterStatus } from 'modules/auth/slice';
import { selectUser } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import CustomInput from 'components/CustomInput';
import Modal from 'components/Modal';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import AustralianAddressAutoComplete from './AustralianAddress';

type FormValues = {
  localAddress: string;
  localAddressCountry: string;
};

const SignUpAddressVerification = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const registerStatus = useAppSelector(selectRegisterStatus);
  const [isDoItLaterModalOpen, setIsDoItLaterModalOpen] = useState(false);
  const [localAddressAutoLocation, setLocalAddressAutoLocation] = useState('');
  const [validLocalAddress, setValidLocalAddress] = useState('');
  const [validLocalAddressCountry, setValidLocalAddressCountry] = useState('');
  const [validLocalAddressPostCode, setValidLocalAddressPostCode] =
  useState('');
  const [libraries] = useState<any>(['places']);
  const component = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useRedirectToSignUp();

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API,
    libraries,
  });

  const { handleSubmit } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = () => {
    const payload = {
      address: validLocalAddress,
      country: validLocalAddressCountry,
      postCode: validLocalAddressPostCode,
    };
    if (registerStatus !== RequestStatusEnum.PENDING) {
      dispatch({ type: ACTIONS.VERIFY_ADDRESS, payload });
    }
  };

  const handleDoItLater = () => {
    if (user?.isPaymentSucceed) {
      if (isMobile) {
        const payload = { push: '/our-cars' };
        dispatch({ type: ACTIONS.LOGOUT_ACCOUNT, payload });
      } else {
        const payload = { push: '/logout' };
        dispatch({ type: ACTIONS.LOGOUT_ACCOUNT, payload });
      }
    } else {
      dispatch({ type: ACTIONS.VERIFY_ADDRESS });
    }
  };

  const handleAustraliaAddressAutocomplete = async (
    address: any,
    postalCode: string,
    country: string,
  ) => {
    setLocalAddressAutoLocation(address);
    setValidLocalAddress(address);
    setValidLocalAddressCountry(country);
    setValidLocalAddressPostCode(postalCode);
  };
  const handleAustraliaAddressChange = (e: any) => {
    setLocalAddressAutoLocation(e);
  };

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, [isMapLoaded]);

  return isMapLoaded ? (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-115px)] w-full max-w-[600px] flex-col md:min-h-[calc(100vh-388px)]"
    >
      <section className="relative m-auto mt-[-70px] min-h-[100vh] w-full max-w-[1000px] md:mt-0 md:flex md:min-h-full md:px-[20px]">
        <div className="flex min-h-[100vh] flex-auto items-start justify-center pt-[60px] md:mx-0 md:min-h-full md:justify-start">
          <div className="w-full">
            <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-[28px]">
              Address Verification
            </h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-[8px] flex min-h-[calc(100vh-115px)] grow flex-col gap-y-[4px] md:min-h-[calc(100vh-510px)]"
            >
              <div className="">
                <div className="mx-[15px] pb-[24px] pt-[8px] text-center text-[14px] leading-[20px]">
                  <p>
                    To be able to use this service, you need to provide an
                    australian residential address.&nbsp;
                    <br className="block md:hidden" />
                    PO Box or hotel addresses are not allowed.
                  </p>
                </div>

                <CustomInput
                  title="Australian Address"
                  // error={errors.localAddress}
                >
                  <AustralianAddressAutoComplete
                    autoLocation={localAddressAutoLocation}
                    handleSelectAutocomplete={
                      handleAustraliaAddressAutocomplete
                    }
                    onChange={handleAustraliaAddressChange}
                  />
                </CustomInput>
              </div>
              <div className="mt-auto grid grid-cols-1 gap-[30px]">
                <button
                  type="submit"
                  className={classNames(
                    'btn btn-primary h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]',
                    {
                      'loading bg-flexi-green-2':
                        registerStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                  disabled={validLocalAddress === ''}
                >
                  Confirm Address
                </button>
                <p
                  onClick={() => setIsDoItLaterModalOpen(true)}
                  className={classNames(
                    'cursor-pointer text-center text-[14px] font-bold capitalize text-primary md:text-[16px]',
                    {
                      'text-[#767676]':
                        registerStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                >
                  Do It Later
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Modal
        isModalOpen={isDoItLaterModalOpen}
        handleClose={() => setIsDoItLaterModalOpen(false)}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <>
          <IconError className="mb-[40px] self-center" />
          <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
            Are you sure you want to&nbsp;
            <span className="whitespace-nowrap">do this later?</span>
          </h1>

          <p className="text-center text-lg ">
            Skipping this step might delay your Flexicar account approval.
            However, if you&apos;re ready to submit this information, simply
            re-login through the Flexicar{' '}
            <span className="whitespace-nowrap">web or mobile app.</span>
          </p>

          <label
            htmlFor="reduce-excess-modal"
            className="button btn w-full cursor-pointer self-center rounded-full border-none bg-flexi-green-1 text-[16px] font-bold capitalize"
            onClick={handleDoItLater}
          >
            Confirm
          </label>
        </>
      </Modal>
    </section>
  ) : (
    <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
      <PendingLoader size={100} />
    </section>
  );
};

export default SignUpAddressVerification;
