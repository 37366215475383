export type IErrors = { [K: string]: string };

export enum OcrStatusEnum {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  COMPLETED_PASSED = 'COMPLETED_PASSED',
  COMPLETED_FLAGGED = 'COMPLETED_FLAGGED',
}

export enum BookingStatusEnum {
  CANCELLED = 0,
  COMPLETED = 1,
  BOOKED = 2,
  ONGOING = 3,
  EXTENDED = 4,
  NO_SHOW = 5,
  ONBOARDING = 7,
  OFFBOARDING = 8,
  REVIEW = 9,
  BILLING = 10,
}

export enum InvoiceStatusEnum {
  UNPAID = 0,
  PAID = 1,
  PARTIAL = 2,
}

export enum AccountTypeEnum {
  PERSONAL = 'personal',
  STUDENT = 'student',
  BUSINESS = 'business',
}

export enum RequestStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
  ERROR = 'error',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
}

export interface IUserType {
  id: number;
  name: AccountTypeEnum;
}

export interface IPlan {
  id: number;
  name: string;
  isAccepted?: boolean;
  isStudentSwitchedToPersonal?: boolean;
  message?: string;
}

export interface ICampus {
  id: number;
  name: string;
}
export type OrganisationType = {
  id: number;
  name: string;
};

export interface IOrganisation {
  campus: string;
  id: number;
  name: string;
  type: OrganisationType;
  isPartner: boolean;
  redirectTo: string;
  billingTo: string;
  maximumBookingDays: number;
}

export interface IUserPartner {
  id: number;
  isPartnerVerified: boolean;
  name: string;
  partnerMembershipId: string;
  url: any;
}

export interface IUserVff {
  isVffVerified: boolean;
  vffMembershipId: string;
}

export interface IUserProfiles {
  default: number;
  id: number;
  refId: number;
  refName: string;
  refType: string;
  type: string;
  isFirstLogin: boolean;
}

export interface ICourse {
  endDate: string;
  isCourseEndDateExpired?: boolean;
  isCourseEndDateExpiringInAWeek?: boolean;
  isNullCourseEndDate?: boolean;
  message?: string;
}

export enum UserRoleEnum {
  DRIVER = 'driver',
  MANAGER = 'manager',
}
export interface IUser {
  id: number;
  firstname: string;
  lastname: string;
  fullname: string;
  email: string;
  type: IUserType;
  plan: IPlan | null;
  birthday: null | string;
  ocrId: string;
  ocrTransactionUrl: string;
  contact: string;
  isMailSubscriber: boolean;
  isOcrVerified: boolean;
  isPaymentSucceed: boolean;
  isDetailsConfirmed: boolean;
  isLicenseExpired: boolean;
  isForReverification: boolean;
  canReverifyLicense: boolean;
  hasChosenPlan: boolean;
  isDriver: boolean;
  hasReduceExcessDamageCover: boolean;
  redirectTo: string;
  redirectToV2: string;
  redirectPreviousBusinessName: string | null;
  registrationVersion: number;
  lastLoginAt: string;
  registeredAt: string;
  organisation?: IOrganisation | null;
  isUseCredits: boolean;
  availableCredits: number;
  outstandingBalance: number;
  status: string;
  statusRemarks: string[];
  partner: IUserPartner | null;
  vff: IUserVff | null;
  profiles: IUserProfiles[];
  role: UserRoleEnum;
  statusKey: string;
  age: number | null;
  completedBookingsCount: number;
  course?: ICourse;
  twoYearReverificationFlag: boolean;
  twoYearReverificationOverdue: boolean;
  hasUnpaidInvoice: boolean;
  obsFromInvoice: number;
}

export interface ISms {
  phoneCode: string;
  phoneNumber: string;
}

export interface IOcrDocument {
  confirmedData: {
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string | null;
    dateOfBirth: string;
    dateOfExpiry: string;
    licenceNumber: string;
    passportNumber: string | null;
    cardNumber: string | null;
    idNumber: string | null;
    visaStartDate: string | null;
    fullAddress: string;
    region: string;
    country: string;
    postalCode: string;
  };
  calculatedData: {
    documentExpired: boolean;
    age: number;
    over18: boolean;
    over21: boolean;
  };
}

export interface IOcrTransaction {
  status: OcrStatusEnum;
  token: string;
  transactionId: string;
  results: {
    documents: IOcrDocument[];
  };
}

export interface IOcrAttempt {
  retriesRemaining: number;
  document: boolean;
  face: boolean;
  liveness: boolean;
  data: boolean;
  overallStatus: string;
}

export interface IMemberType {
  id: number;
  name: string;
  fees: number;
}

export interface IRates {
  slug: string;
  category: string;
  fees: string;
}
export interface ILatlng {
  latitude: string;
  longitude: string;
}

export interface IMapCenter {
  lng: number;
  lat: number;
}

export interface IPaymentCardStatus {
  code: string;
  isCardNameMismatch: boolean;
  message: string;
}
export interface IPaymentCard {
  id: number;
  cardNumber: string;
  cardHolderName: string;
  expiryMonth: string;
  expiryYear: string;
  type: string;
  isDefault: number;
  isExpired: number;
  isUpdateExpiredCard: string | null;
  status: IPaymentCardStatus | null;
}

export interface IFavoriteLocation {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
}

export interface IPromo {
  category: string;
  code: string;
  id: number;
  name: string;
  type: string;
  value: string;
  actualValue: number;
  creditValue: number;
}

export interface IBilling {
  membershipFees: {
    annualFee: number;
    fee: number;
    gst: number;
    subtotal: number;
    total: number;
    promo: IPromo | null;
  };
  planFees: {
    fee: number;
    gst: number;
    subtotal: number;
    total: number;
    promo: number;
  };
  promo: {
    actualValue: number;
    category: string;
    code: string;
    id: number;
    name: string;
    type: string;
    value: number;

  }
  total: number;
  gst: number;
  subtotal: number;
}

export interface IUserInfo {
  firstname: string;
  lastname: string;
  fullname: string;
  birthday: string;
  businessName: string;
  residentialAddress: string;
  cardNumber: string;
  licenseNumber: string;
  licenseCardNumber: string;
  licenseExpiry: string;
  licenseAddress: string;
  licenseStreet: string;
  licenseCity: string;
  licenseState: string;
  licenseZip: string;
  licenseCountry: string;
  passportNumber: number | null;
  passportCountry: string | null;
  experienceYears: string;
  hasSuspended: boolean;
  hasConvicted: boolean;
  convictedExplanation: string | null;
  hasConvictedFile: boolean;
  forAddressVerification: boolean;
  campus: string;
  university: string;
  code: string | null;
  city: string | null;
  zip: string | null;
  country: string | null;
  state: string | null;
  address: string | null;
  redirectPreviousBusinessName: string | null;
}

export type IBookingVehicle = {
  id: number;
  description: string;
  name: string;
  group: { make: string; model: string; vehicleImage: string };
  imageUrl: string;
  vehicleImageUrl: string;
  status: number;
  podName: string;
  podDescription: string;
};

export interface IBooking {
  id: number;
  startsAt: string;
  podTimeZone: string;
  endsAt: string;
  status: number;
  vehicleId: number;
  vehicleName: string;
  podId: number;
  podName: string;
  podDescription: string;
  podIsFavorite: boolean;
  invoiceIds: number[];
  invoiceId: number[];
  longitude: string;
  latitude: string;
  vehicle: IBookingVehicle;
  amount: number;
  driver: {
    email: string;
    name: string;
  }
  tripStatus: string;
  actualStartsAt: string;
  actualEndsAt: string;
  actualAmount: number;
}

export interface IBookingListPaginated {
  data: IBooking[] | null;
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  }
}

export interface IReportVehicle {
  reg: string;
  make: string;
  color: string;
  additionalInfo: string;
  parked: string;
  isNearby: number;
}
export interface IReportBookingUrl {
  issueUrl: string;
}

export type TCurrentPlan = {
  id: number;
  name: string;
  description: string | null;
  monthlyCredits: number;
  monthlyCreditsDate: string | null;
  monthlyCreditsDueDate: string | null;
  fee: number;
  annualFee: string;
  feeDueDate: string;
  type: number;
};

export type TCreditBreakdown = {
  nowCredits: number | null;
  expiresAt: string | null;
  createdAt: string | null;
  creditId: number;
};

export type TBusinessCreditBreakdown = {
  amount: number;
  expiry: string | null;
};

export type TBusinessRoles = {
  manager: TBusinessCreditBreakdown;
  driver: TBusinessCreditBreakdown;
};

export type TCredit = {
  amount: number;
  expiry: string | null;
  breakdown: TCreditBreakdown[];
  business: TBusinessRoles;
};

export type TAvailableCredits = {
  complimentaryCredits: TCredit;
  driving: TCredit;
  mandatory: TCredit;
  rollover: TCredit;
};

export type IUserCreditBreakdown = {
  amount: number;
  expiry: string | null;
};

export type IUserCreditsBreakdown = {
  complimentary: IUserCreditBreakdown;
  driving: IUserCreditBreakdown;
  mandatory: IUserCreditBreakdown;
  rollover: IUserCreditBreakdown;
};

export type IUserAvailableCredits = {
  availableCredits: number;
  credits: IUserCreditsBreakdown;
};

export interface IUserPlan {
  name: string;
  plan: TCurrentPlan;
  credits: TAvailableCredits;
  fee: number;
  annualFee: string;
}
export interface IExpiredCredits {
  id: string;
  mode: string;
  value: string;
  expiresAt: string;
  description: string;
  lastCredits?: number;
}

export interface IRewardPartner {
  id: number;
  name: string;
  slug: string;
  url: any;
}

export interface IInvoice {
  id: number;
  driverName: string;
  description: string;
  createdAt: string;
  amount: number;
  status: number;
  statusDesc: string;
  invoiceNumber: string;
  pdfLink: string;
  link: string;
  outstandingAmount: number;
}

export interface IInvoiceList {
  data: IInvoice[];
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
}

export interface IVehicleFilter {
  id: number;
  name: string;
  slug: string;
  type: string;
  status: boolean;
  pod: string;
}

export interface IVehicleCategories {
  id: number;
  name: string;
  slug: string;
}

export interface IVehicleRates {
  id: number;
  plan: any;
  rates: IRates[];
}

export interface IVehicleFeature {
  name: string;
  qty: number;
}

export interface IVehicleGroup {
  id: number;
  model: string;
  make: string;
  type: string;
  vehicleImage: string;
  category: IVehicleCategories | null;
}

export interface IRangeDriver {
  name: string;
  fullname: string;
  status: string;
  id: number;
}

export interface IRangesBooking {
  id: number;
  startsAt: string;
  endsAt: string;
  startDate: string;
  endDate: string;
  driver: IRangeDriver | null;
}
export interface IVehicleAvailabilityRange {
  date: string;
  value: string;
  bookings: IRangesBooking[];
}

export interface IVehicleAvailability {
  isAvailable: boolean;
  ranges: IVehicleAvailabilityRange[];
}

export interface IVehicleAvailable {
  date: string;
}
export interface IVehicleRate {
  currency: string;
  total: number;
}

export interface IVehiclePod {
  id: number;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  distance: number;
  maxDistance: number;
}

export interface IVehiclePodOptimized {
  id: number;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  maxDistance: number;
}

export interface IVehicle {
  id: number;
  name: string;
  numberPlate: string;
  imei: any;
  vin: any;
  latitude: string;
  longitude: string;
  status: number;
  features: IVehicleFeature[];
  group: IVehicleGroup;
  pod: IVehiclePod;
  distance: number;
  rates: IVehicleRate;
  availability: IVehicleAvailability;
}

export interface IVehicleOptimized {
  id: number;
  name: string;
  numberPlate: string;
  imei: any;
  vin: any;
  latitude: string;
  longitude: string;
  status: number;
  features: IVehicleFeature[];
  group: IVehicleGroup;
  pod: IVehiclePod;
  rates: IVehicleRate;
  availability: IVehicleAvailability;
}
export interface IBookVehicle {
  vehicleId: number;
  make: string;
  model: string;
  distance: number;
  capacity: number;
  doors: number;
  fuel: string;
  name: string;
  startTime: string;
  endTime: string;
  hub: string;
  numberPlate: string;
  latitude: string;
  longitude: string;
}

export interface IVehicleSummaryBreakdownsAddon {
  id: number;
  name: string;
  slug: string;
  value: number;
}

export interface IVehicleSummaryBreakdownsPromo {
  id: number;
  name: string;
  code: string;
  value: number;
  type: string;
  actualValue: number;
}

export interface IVehicleSummaryBreakdowns {
  duration: string;
  currency: string;
  estimateTotal: number;
  timeTotal: number;
  distanceTotal: number;
  excess: number;
  excessUnderAge: number;
  total: number;
  promo: IVehicleSummaryBreakdownsPromo | null;
  addons: IVehicleSummaryBreakdownsAddon[] | null;
  reduceExcessTotal: number;
  creditsUsed: number;
  amountPaid: number;
}

export interface IVehicleSummary {
  id: number;
  name: string;
  numberPlate: string;
  phoneNumber: string | null;
  imei: any;
  vin: any;
  fuelType: string;
  fuelCardPincode: string;
  latitude: string;
  longitude: string;
  status: number;
  features: IVehicleFeature[];
  group: IVehicleGroup;
  remarks: any;
  isPodFavorite: boolean;
  startsAt: string;
  endsAt: string;
  breakdowns: IVehicleSummaryBreakdowns;
  podName: string;
  podDescription: string;
  podLatitude: string;
  podLongitude: string;
  podId: number;
  isPrestige: boolean;
  unitNumber: string;
  make: string;
  model: string;
  imageUrl: string;
  vehicleImageUrl: string;
  seats: number;
  isAvailable: boolean;
  settings: {
    requireCostCentre: boolean;
  };
  paymentMethod: IPaymentCard;
}

export interface ISummaryDriver {
  email: string;
  name: string;
}
export interface IBookingSummaryPaymentMethod {
  memberId: number;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  expirYear: string;
  type: string;
}
export interface IBookingSummary {
  costCentre: string | null;
  amount: number;
  bookingReference: string | null;
  id: number;
  name: string;
  numberPlate: string;
  phoneNumber: string | null;
  imei: any;
  vin: any;
  latitude: string;
  longitude: string;
  status: number;
  features: IVehicleFeature[];
  group: IVehicleGroup;
  remarks: any;
  isPodFavorite: boolean;
  startsAt: string;
  endsAt: string;
  addons: [];
  extras: [];
  breakdowns: IVehicleSummaryBreakdowns;
  podName: string;
  podDescription: string;
  podId: number;
  rating: string;
  referenceNumber: string;
  vehicle: IVehicleSummary;
  vehicleId: number;
  driver: ISummaryDriver;
  driverId: number;
  paymentMethod: IBookingSummaryPaymentMethod;
  podTimeZone: string;
  actualStartsAt: string;
  actualEndsAt: string;
  tripStatus: string;
  actualAmount: number | null;
  paymentDeclined: {
    message: string;
  }
  paymentAccount: IPaymentCard;
}

export interface IPodVehicle {
  id: number;
  podId: number;
  name: string;
  latitude: string;
  longitude: string;
  status: number;
  features: IVehicleFeature[];
  make: string;
  model: string;
  imageUrl: string;
  isAvailable: boolean;
  isPrestige: boolean;
  distance: number | null;
  rates: IVehicleRate;
  availability: IVehicleAvailability;
  podName: string;
  podIsFavorite: boolean;
}

export interface IPodVehicleOptimized {
  id: number;
  podId: number;
  name: string;
  latitude: string;
  longitude: string;
  status: number;
  features: IVehicleFeature[];
  make: string;
  model: string;
  imageUrl: string;
  isAvailable: boolean;
  rates: IVehicleRate;
  availability: IVehicleAvailability;
  podName: string;
  podIsFavorite: boolean;
}

export interface IVehicleCalculatedRatesBreakdown {
  price: number;
  description: string;
  quantity: number;
  unit: string;
  subtotal: number;
}

export interface IVehicleCalculatedRatesDetails {
  duration: string;
  currency: string;
  estimatedTotal: number;
  timeTotal: number;
  total: number;
  chargeBreakdowns: IVehicleCalculatedRatesBreakdown[];
}

export interface IVehicleCalculatedRates {
  vehicleId: number;
  rates: IVehicleCalculatedRatesDetails[];
}

export interface IVehicleRatePer {
  timeRate: number;
  // costBreakMax: number;
}

export interface IVehicleListRates {
  vehicleId: number;
  rates: IVehicleRatePer[];
}

export interface ISeasonsRates {
  seasonId: number;
  timeRate: number;
}

export interface ICostBreakRates {
  costBreakId: number;
  seasons: ISeasonsRates[];
}

export interface IVehicleGroupRates {
  vehicleGroupId: number;
  costBreaks: ICostBreakRates[];
}

export interface IPodGroupRates {
  podGroupId: number;
  vehicleGroups: IVehicleGroupRates[];
}

export interface IVehicleCategoryRates {
  podGroups: IPodGroupRates[];
}

export interface IVehicleBookings {
  bookingId: number;
  endsAt: string;
  startsAt: string;
  driverId: number;
  organisationId: number;
  isMaintenance: number;
}

export interface IBookingData {
  endsAt: string | null;
  startsAt: string | null;
  driverId: number | null;
  organisationId: number | null;
  isMaintenance: number | null;
}

export interface IPodData {
  podId: number | null;
  isClosed: number | null;
  opensAt: string | null;
  closesAt: string | null;
}

export interface ITimelineData {
  basis: number;
  date: string | null;
  data: IBookingData | null;
  podData: IPodData | null;
}

export interface IVehicleAvailability2 {
  bookings: IVehicleBookings[];
}

export interface IVehicleSummaryMin {
  // id: string;
  availability: IVehicleAvailability2;
  podId: number | string;
  vehicleId: number | string;
}

export interface IPodVehicleBasic {
  id: number;
  podId: number;
  name: string;
  status: number;
  isAvailable: boolean;
  isPetFriendly: number;
  isMobility: number;
  imageUrl: string;
  vehicleImageUrl: string;
  vehicleGroupImage: string;
  model: string;
  make: string;
  category: number;
  vehicleGroupId: number;
  podName: string;
  podIsFavorite: boolean;
  rates: IVehicleRate;
  suburb: string;
  state: string;
}

export interface IPodOperation {
  podId: number,
  day: string,
  opensAt: string,
  closesAt: string,
  isClosed: number
}

export interface IPodId {
  id: number;
}

export interface IPodOptimized {
  id: number;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  vehicles: IPodVehicle[];
  podIsFavorite: boolean;
  timezone: string;
  offset: string;
}

export interface IPodAll {
  id: number;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  podIsFavorite: boolean;
  timezone: string;
  offset: string;
  podGroupId: number;
  operations: IPodOperation[];
  suburb: string;
  state: string;
}

export interface IPod {
  id: number;
  name: string;
  description: string;
  latitude: string;
  longitude: string;
  podDistance: number;
  vehicles: IPodVehicle[];
  podIsFavorite: boolean;
  timezone: string;
  offset: string;
}
export interface IOtpExpiry {
  expiredAt: string;
  lifetime: number;
}

export interface IMapBounds {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
}

export interface IRecentSearch {
  latitude: number;
  longitude: number;
  formattedAddress: string;
}

export interface IBookingPromo {
  applied: {
    actualValue: number;
    value: number;
  };
  code: string;
  type: string;
  name: string;
  category: string;
}

export interface IAbrLookup {
  abn: string;
  acn: string;
  businessName: string[];
  entityName: string;
}

export interface IBusinessInvitees {
  id: number;
  name: string;
  email: string;
  dateInvited: string;
  hasAccepted: boolean;
  isOnboarded: boolean;
}

export interface IBusinessInviteesPaginated {
  data: IBusinessInvitees[];
  currentPage: number;
  from: number;
  lastPage: number;
  perPage: number;
  to: number;
  total: number;
}

export interface IBusinessDrivers {
  id: number;
  name: string;
  email: string;
  dateInvited: string;
}

export interface IDriver {
  id: number;
  name: string;
  email: string;
  dateInvited: string;
}

export interface ICountryCode {
  name: string;
  code: any;
}

export interface ISettingsVehicleTypes {
  id: number;
  name: string;
  slug: string;
  type: string;
  status: boolean;
  pod: string;
}

export interface ISettingsBusinessHours {
  day: string;
  opensAt: string;
  closesAt: string;
  status: string;
}

export interface IBookingSettings {
  vehicleTypes: ISettingsVehicleTypes[];
  requireCostCentre: boolean;
  businessHours: ISettingsBusinessHours[];
}

export interface INotifs {
  name: string;
  slug: string;
}
export interface INotifications {
  notifications: INotifs[];
}

export interface IRegisterPlanInclusions {
  id: number;
  title: string;
  description: string;
  planId: number;
}
export interface IRegisterPlan {
  id: number;
  name: string;
  description: string;
  monthlyCredits: number;
  commitment: string;
  dailyNotes: string;
  hourlyNotes: string;
  disclaimer: string;
  inclusions: IRegisterPlanInclusions[];
}

export interface ITokenInfo {
  businessName: string;
  email: string;
}

export interface ICountry {
  name: string;
  countryCode: string;
  stateCode: string;
}

export interface ICancellationFees {
  message: string;
  amount: number;
  is_modified: boolean;
}

export interface IBroadcastSystem {
  id: number;
  statusDesc: string;
  endDate: string;
  title: string;
  description: string;
  isDismissible: number;
}
export type TBlockContentAccount =
  | 'personal'
  | 'business manager'
  | 'business driver'
  | 'student';
  
export interface IDynamicBlockContent {
  account_type: TBlockContentAccount[];
  description: string;
  icon: string;
  title: string;
  _group: string;
}

export interface IDynamicBlock {
  id: number;
  key: string;
  reactPage: string;
  createdAt: string;
  deletedAt?: string;
  updatedAt?: string;
  value: IDynamicBlockContent[];
}
export interface ILoggedInDevices {
  id: number;
  memberId: number;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  lastUsedAt: string;
}

export interface ILoggedInDevicesResponse {
  httpCode: number;
  message: string;
  logins: ILoggedInDevices[];
}

export interface IVideo {
  id: number;
  categoryId: number;
  title: string;
  youtubeId: string;
}

export interface IVideoCategory {
  id: number;
  icon: string;
  title: string;
}

export interface IContent {
  question: string;
  answer: string;
  callToAction: string | null;
  image: string | null;
  embedVideoUrl: string | null;
  isTopFive: boolean;
  categoryId: number;
  contentId: number;
}

export interface IHelpCenterCategory {
  categoryId: number;
  categoryName: string;
  content: IContent[];
}

export interface IPagesContentsCategories {
  id: number;
  icon: string;
  title: string;
}

export interface IPlanTypeDetails {
  id: number;
  name: string;
  isSenior: number;
  fees: number;
  commitment: string;
  dailyNotes: string;
  hourlyNotes: string;
  monthlyCredits: number;
}

export interface IPlanType {
  plan_types: IPlanTypeDetails[];
}

export interface IUniversities {
  id: number;
  code: string;
  name: string;
  campuses: ICampus[];
}

export interface ISignUpLicense {
  country: string;
  licenseNumber: string;
  licenseCardNumber?: string;
}

export interface ISignUpDriver {
  accountType: number;
  token: string;
}

export interface IRescheduleDetails {
  settingsRescheduleLimit: number;
  bookingRescheduledCount: number;
}