import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IOcrTransaction } from 'common/types';

type InitialState = {
  pending: boolean;
  pendingSms: boolean;
  ocrTransaction: IOcrTransaction | null;
  qrCode: any;
  ocrTransactionSuccess: boolean,
  qrCodeError: any;
};

const initialState: InitialState = {
  pending: false,
  pendingSms: false,
  ocrTransaction: null,
  qrCode: null,
  ocrTransactionSuccess: false,
  qrCodeError: null,
};

export const slice = createSlice({
  name: 'ocr',
  initialState,
  reducers: {
    reset: () => initialState,
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setPendingSms: (state, action: PayloadAction<boolean>) => {
      state.pendingSms = action.payload;
    },
    setOcrTransaction: (state, action: PayloadAction<IOcrTransaction>) => {
      state.ocrTransaction = action.payload;
    },
    setQrCode: (state, action: PayloadAction<any>) => {
      state.qrCode = action.payload;
    },
    setOcrTransactionSuccess: (state, action: PayloadAction<boolean>) => {
      state.ocrTransactionSuccess = action.payload;
    },
    setQrCodeError: (state, action: PayloadAction<any>) => {
      state.qrCodeError = action.payload;
    },
  },
});

export const {
  reset,
  setPending,
  setPendingSms,
  setOcrTransaction,
  setQrCode,
  setOcrTransactionSuccess,
  setQrCodeError,
} = slice.actions;

export const selectPending = (state: RootState): boolean => state.ocr.pending;
export const selectPendingSms = (state: RootState): boolean =>
  state.ocr.pendingSms;
export const selectOcrTransaction = (
  state: RootState,
): IOcrTransaction | null => state.ocr.ocrTransaction;
export const selectQrCode = (state: RootState): any => state.ocr.qrCode;
export const selectOcrTransactionSuccess = (
  state: RootState,
): boolean => state.ocr.ocrTransactionSuccess;
export const selectQrCodeError = (state: RootState): any => state.ocr.qrCodeError;

export default slice.reducer;
