import React, { ReactNode } from 'react';

export const InvalidText = ({ children }: { children: ReactNode }) => {
  return <p className="ml-1 mt-2 text-[12px] text-red-500 self-start">{children}</p>;
};

export const PageTitle = ({ children }: { children: ReactNode }) => {
  return (
    <h2 className="text-left font-primary text-[20px]  md:text-[40px] font-[900] uppercase  text-primary leading-[25px] md:leading-[50px]">
      {children}
    </h2>
  );
};

export const PageTitleBanner = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-primary py-[100px]">
      <h2 className="font-primary text-center font-[700] text-[36px] uppercase leading-[25px] text-white md:text-[50px] md:leading-[50px]">
        {children}
      </h2>
    </div>
  );
};

interface CenteredPageTitleProps {
  children: React.ReactNode;
  extraClass?: string;
}

export const CenteredPageTitle = ({
  children,
  extraClass,
}: CenteredPageTitleProps) => {
  return (
    <h2
      className={`font-primary ${extraClass} text-center text-[30px] leading-[28px] text-primary md:text-[48px] md:leading-[50px]`}
    >
      {children}
    </h2>
  );
};