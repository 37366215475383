import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';

const RegisterProofOfIdentity = () => {
  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);
  return (
    <section ref={component} className="fadein m-auto flex w-full max-w-[500px] flex-col items-center">
      <h2 className="mb-[50px] text-center text-4xl font-bold">
        Proof of Identity
      </h2>
      <p>
        Take a photo of your license &#40;front &amp; back&#41;, a selfie
        holding your license and any document that has your current addess
        &#40;e.g. Bill&#41;. Make sure that the photo is not blurred. Use the
        sample image below as a guide.
      </p>
      <span
        className="relative mt-14 mb-14 grid w-full place-items-center overflow-hidden bg-zinc-300 py-[25%] 
      before:absolute before:h-[300%] before:w-[1px] before:rotate-[64deg] before:bg-zinc-400 before:content-[''] 
      after:absolute after:h-[300%] after:w-[1px] after:rotate-[-64deg] after:bg-zinc-400 after:content-[''] 
      "
      />
      <div className="flex flex-col gap-10">
        {['Front of License', 'Back of License', 'Selfie with license'].map(
          (license: string, i: number) => {
            return (
              <div
                key={i}
                className="flex h-[200px] w-[500px] flex-col items-center justify-evenly border-2 border-dashed border-zinc-400 bg-zinc-100"
              >
                <h2 className=" text-center text-4xl font-bold ">{license}</h2>
                <span className="btn w-4/12 rounded-full bg-zinc-100 font-bold text-[#1F2937]">
                  Take a photo
                </span>
              </div>
            );
          },
        )}
      </div>
      <button className="btn mt-10 rounded-full px-10">Submit</button>
    </section>
  );
};

export default RegisterProofOfIdentity;
