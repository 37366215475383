import React, { useState } from 'react';
// import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectError,
  selectErrorModal,
  setError,
  setErrorModal,
} from 'modules/auth/slice';
import Modal from 'components/Modal';
import useAppDispatch from 'hooks/useAppDispatch';
import { getErrorValue } from 'common/helpers';
import { useUpdateEffect } from 'react-use';
import { selectErrors, setErrors } from 'modules/booking/slice';
import { selectErrors as selectBusinessErrors } from 'modules/business/slice';
import { useLocation } from 'react-router-dom';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

interface Props {
  error?: any;
  page?: string;
}

function ErrorModal({ error }: Props) {
  const errorModal = useAppSelector(selectErrorModal);
  const errorMessage =
    error ||
    useAppSelector(selectErrors || selectError || selectBusinessErrors);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [uniqueMessage, setUniqueMessage] = useState<any[]>([]);

  useUpdateEffect(() => {
    const ignorePath = !['/app/booking/summary'].includes(pathname);
    // console.log(errorMessage);

    // console.log('modal:',Object.values(errorMessage[Object.keys(errorMessage)[0]].errors).flat())
    if (errorMessage && ignorePath) {
      if (window.location.href.includes('account/settings')) {
        setUniqueMessage(
          Object.values(
            errorMessage[Object.keys(errorMessage)[0]].errors,
          ).flat(),
        );
        dispatch(setErrorModal(true));
      } else {
        const messages = [
          'the email has already been taken.',
          'invalid credentials.',
          'email does not exist.',
          'the token has been blacklisted',
        ];
        const ignoreMessage =
          messages.includes(getErrorValue(errorMessage)?.toLocaleLowerCase()) ||
          messages.includes(errorMessage.toLocaleLowerCase());
        if (!ignoreMessage) {
          dispatch(setErrorModal(true));
        }
        // console.log(errorMessage, ignoreMessage);
      }
    }
  }, [errorMessage]);

  const handleClose = () => {
    if (pathname !== '/app/booking/summary') {
      dispatch(setErrorModal(false));
      dispatch(setError(null));
      dispatch(setErrors(null));
      setUniqueMessage([]);
    }
  };

  return (
    <Modal
      isModalOpen={errorModal}
      handleClose={handleClose}
      isErrorModal={true}
    >
      <LottieAnimation lottieFile={errorLottie} isStopped={!errorModal} />
      <p className="title">Oops! Something went wrong.</p>
      {errorMessage instanceof String ? (
        <p className="text-center text-[18px]">{getErrorValue(errorMessage)}</p>
      ) : uniqueMessage.length ? (
        uniqueMessage.map((message, i) => {
          return (
            <p className="text-center text-[18px]" key={i}>
              {message}
            </p>
          );
        })
      ) : (
        <p className="text-center text-[18px]"></p>
      )}
      <p className="button btn capitalize" onClick={handleClose}>
        ok
      </p>
    </Modal>
  );
}

export default ErrorModal;
