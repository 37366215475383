import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import classNames from 'classnames';
import moment from 'moment';
import gsap from 'gsap';

import { ReactComponent as IconInvite } from 'assets/images/invite.svg';
import { ReactComponent as IconRemove } from 'assets/images/remove.svg';
import { ReactComponent as IconSearch } from 'assets/images/search2.svg';
import { IBusinessInvitees, RequestStatusEnum } from 'common/types';
import { PageTitle } from 'components/Typography';
import {
  setErrors,
  setInviteDriverStatus,
  setRemoveDriverStatus,
} from 'modules/business/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useBusiness from 'hooks/useBusiness';
import BurgerNav from 'components/BurgerNav';
import CustomCheckBox from 'components/CustomCheckbox';
import CustomInput from 'components/CustomInput';
import MyAccountNav from 'components/MyAccountNav';
import Pagination from 'components/Pagination/Pagination';
import ModalRemove from './ModalRemove';
import ModalInvite from './ModalInvite';

const MyDrivers = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const {
    businessInvitees,
    businessInviteesStatus,
    removeDriverStatus,
    inviteDriverStatus,
  } = useBusiness();
  const [selectedDrivers, setSelectedDrivers] = useState<IBusinessInvitees[]>(
    [],
  );
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [order, setOrder] = useState<string>('desc');
  const [currentSort, setCurrentSort] = useState<string>('date_invited');
  const [burgerActive, setBurgerActive] = useState<number>(-1);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffectOnce(() => {
    const payload = {
      limit: 10,
      page: 1,
      sort: currentSort,
      order,
    };
    dispatch({ type: ACTIONS.FETCH_BUSINESS_INVITEES, payload });
  });

  useEffect(() => {
    handleRequestInvitees(itemsPerPage, currentPage);
  }, [currentSort, order, searchFilter, removeDriverStatus]);

  const handleRequestInvitees = (limit: number, page: number) => {
    const payload = {
      page: page || 1,
      limit: limit,
      sort: currentSort,
      order,
      filter: searchFilter,
    };
    dispatch({ type: ACTIONS.FETCH_BUSINESS_INVITEES, payload });
  };

  const handleOpenRemoveModal = () => {
    setIsModalRemoveOpen(true);
  };

  const [isModalInviteOpen, setIsModalInviteOpen] = useState(false);

  const handleRemove = (data: any) => {
    const payload = {
      ids: [data.id],
    };
    dispatch({ type: ACTIONS.REMOVE_DRIVERS, payload });
  };
  const handleRemoveButton = () => {
    const ids: number[] = [];
    selectedDrivers.map((driver: IBusinessInvitees) => ids.push(driver.id));
    const payload = {
      ids,
    };
    dispatch({ type: ACTIONS.REMOVE_DRIVERS, payload });
  };

  const handleCheckBoxSingle = (e: any, row: IBusinessInvitees) => {
    const previousSelection = [...selectedDrivers];
    if (e.target.checked) {
      previousSelection.push(row);
    } else {
      const index = previousSelection.indexOf(row);
      if (index > -1) {
        previousSelection.splice(index, 1);
      }
    }
    setSelectedDrivers(previousSelection);
  };

  const handleCheckBoxMulti = (e: any) => {
    const previousSelection: IBusinessInvitees[] = [];
    if (e.target.checked && businessInvitees) {
      businessInvitees.data.map((driver) => previousSelection.push(driver));
    }
    setSelectedDrivers(previousSelection);
  };

  const handleBookDriver = (id: number) => {
    push({ pathname: `/app/booking`, search: `?id=${id}` });
  };

  const handleSort = (column: string) => {
    if (currentSort === column) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      setCurrentSort(column);
    }
  };

  return (
    <div
      ref={component}
      className="fadein relative mx-auto mt-[80px] mb-[50px] flex w-[84vw] flex-col md:mt-[100px] lg:mx-0 lg:w-[1000px]"
    >
      <Helmet>
        <title>My Account - Drivers</title>
        <meta
          name="description"
          content="My Flexicar Account - Business Drivers"
        />
      </Helmet>
      <MyAccountNav title="Drivers" />
      <div className="justify-between md:flex">
        <PageTitle>Drivers</PageTitle>
        <CustomInput
          title="Search"
          extraClass="w-full sm:w-fit mt-[15px] md:mt-0 "
        >
          <input
            type="text"
            placeholder="search"
            value={searchFilter}
            className={`peer input input-bordered w-full pr-[50px] sm:w-[300px]`}
            onChange={(e) => {
              setCurrentPage(1);
              setSearchFilter(e.target.value);
            }}
          />
          <span className="pointer-events-none absolute right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white">
            <IconSearch className="" />
          </span>
        </CustomInput>
      </div>
      <div className="mb-10 mt-[25px] gap-[20px] sm:flex md:mt-[50px]">
        <button
          className="mb-[15px] flex h-[50px] w-full place-items-center justify-center gap-[10px] bg-[#f2f2f2] font-bold text-primary sm:mb-0"
          onClick={() => setIsModalInviteOpen(true)}
        >
          <IconInvite className="fill-flexi-orange-1" />
          Invite
        </button>
        <button
          className="flex h-[50px] w-full place-items-center justify-center  gap-[10px] bg-[#f2f2f2] font-bold text-primary disabled:opacity-50"
          disabled={selectedDrivers.length === 0}
          onClick={handleOpenRemoveModal}
        >
          <IconRemove className="fill-flexi-orange-1" />
          Remove
        </button>
      </div>
      {businessInviteesStatus === RequestStatusEnum.PENDING &&
      businessInvitees &&
      businessInvitees.data.length === 0 ? (
        <div className="w-full py-[100px]">
          <AiOutlineLoading3Quarters className="m-auto h-[50px] w-[50px] animate-spin fill-flexi-orange-1" />
        </div>
      ) : businessInvitees && businessInvitees.data.length === 0 ? (
        <p className="pt-[100px] text-center">No members to display</p>
      ) : (
        <>
          <div
            className={classNames('', {
              'pointer-events-none opacity-50':
                removeDriverStatus === RequestStatusEnum.PENDING,
            })}
          >
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b-[1px] border-[#E0E0E0] bg-white text-left">
                  <th className="px-2 py-4">
                    <CustomCheckBox titleElement={``}>
                      <input
                        type="checkbox"
                        className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
                        onChange={(e) => handleCheckBoxMulti(e)}
                        checked={
                          selectedDrivers.length ===
                          businessInvitees?.data.length
                        }
                        // @ts-ignore
                        style={{ '--chkfg': '0', '--chkbg': '0' }}
                      />
                    </CustomCheckBox>
                  </th>
                  {isMobile ? (
                    <th
                      className="flex items-center gap-[15px] px-2 py-4"
                      onClick={() => handleSort('name')}
                    >
                      Name
                    </th>
                  ) : (
                    <>
                      <th
                        className="px-4 py-4"
                        onClick={() => handleSort('name')}
                      >
                        Name
                      </th>
                      <th
                        className="px-4 py-4"
                        onClick={() => handleSort('email')}
                      >
                        Email
                      </th>
                    </>
                  )}
                  {isMobile ? (
                    <th
                      className="px-2 py-4"
                      onClick={() => handleSort('date_invited')}
                    >
                      Date Invited
                    </th>
                  ) : (
                    <>
                      <th
                        className="px-4 py-4"
                        onClick={() => handleSort('date_invited')}
                      >
                        Date Invited
                      </th>
                      <th
                        className="px-4 py-4"
                        onClick={() => handleSort('has_accepted')}
                      >
                        Status
                      </th>
                    </>
                  )}
                  <th className="px-4 py-4"></th>
                </tr>
              </thead>
              <tbody>
                {businessInvitees &&
                  businessInvitees.data.map(
                    (row: IBusinessInvitees, index: number) => (
                      <tr
                        key={row.id}
                        className={classNames(
                          ' bg0white border-b-[1px] border-[#E0E0E0]',
                          {
                            'bg-[#F7F7F7]': index % 2 === 0,
                          },
                        )}
                      >
                        <td className="px-2 py-4">
                          <CustomCheckBox>
                            <input
                              type="checkbox"
                              className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px]  !bg-white"
                              onChange={(e) => handleCheckBoxSingle(e, row)}
                              checked={selectedDrivers.includes(row)}
                              // @ts-ignore
                              style={{ '--chkfg': '0', '--chkbg': '0' }}
                            />
                          </CustomCheckBox>
                        </td>
                        {isMobile ? (
                          <td className="max-w-[30vw] px-2 py-4 sm:max-w-[45vw]">
                            <p className="truncate text-[14px] font-bold">
                              {row.name}
                            </p>
                            <p className="truncate text-[12px]">{row.email}</p>
                          </td>
                        ) : (
                          <>
                            <td className="px-4 py-4">{row.name}</td>
                            <td className="max-w-[25vw] truncate px-4 py-4">
                              {row.email}
                            </td>
                          </>
                        )}
                        {isMobile ? (
                          <td className="px-2 py-4 text-[14px]">
                            <p>
                              {moment(row.dateInvited).format('DD/MM/YYYY')}
                            </p>
                            <p>
                              {!row.hasAccepted
                                ? 'Invited'
                                : row.isOnboarded
                                ? 'Onboarded'
                                : 'Onboarding'}
                            </p>
                          </td>
                        ) : (
                          <>
                            <td className="px-4 py-4">
                              {moment(row.dateInvited).format('DD/MM/YYYY')}
                            </td>
                            <td className="px-4 py-4">
                              {!row.hasAccepted
                                ? 'Invited'
                                : row.isOnboarded
                                ? 'Onboarded'
                                : 'Onboarding'}
                            </td>
                          </>
                        )}
                        <td className="px-0 py-4">
                          <div
                            className={classNames(
                              'relative z-[2] grid h-full w-[50px] place-items-center items-center justify-center ',
                            )}
                          >
                            <BurgerNav
                              setBurgerActive={setBurgerActive}
                              burgerActive={burgerActive}
                              i={index}
                              extraClass="pointer-events-auto"
                            >
                              <p
                                className={classNames(
                                  'w-full p-[15px] px-[0px] text-center hover:bg-flexi-black-7',
                                )}
                                onClick={() => handleBookDriver(row.id)}
                              >
                                Book Now
                              </p>

                              <p
                                className={classNames(
                                  'w-full p-[15px] px-[0px] text-center hover:bg-flexi-black-7',
                                  {
                                    loading:
                                      removeDriverStatus ===
                                      RequestStatusEnum.PENDING,
                                  },
                                )}
                                onClick={() => handleRemove(row)}
                              >
                                Remove
                              </p>
                            </BurgerNav>
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
              </tbody>
            </table>
          </div>
          {businessInvitees && (
            <Pagination
              currentPageData={businessInvitees.currentPage}
              status={businessInviteesStatus}
              from={businessInvitees.from}
              to={businessInvitees.to}
              totalItems={businessInvitees.total}
              totalPages={businessInvitees.lastPage}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              setItemsPerPage={setItemsPerPage}
              onPageChange={(limit: number, page: number) =>
                handleRequestInvitees(limit, page)
              }
              hideLimit={false}
            />
          )}
        </>
      )}

      <ModalInvite
        handleClose={() => {
          dispatch(setErrors(null));
          setIsModalInviteOpen(false);
          if (inviteDriverStatus === 'success') {
            dispatch(setInviteDriverStatus(null));
          }
        }}
        isModalOpen={isModalInviteOpen}
      />

      <ModalRemove
        handleClose={() => {
          setIsModalRemoveOpen(false);
          if (removeDriverStatus === RequestStatusEnum.SUCCESS) {
            dispatch(setRemoveDriverStatus(null));
            setSelectedDrivers([]);
          }
        }}
        isModalOpen={isModalRemoveOpen}
        handleRemove={handleRemoveButton}
        driverDatas={selectedDrivers}
      />
    </div>
  );
};

export default MyDrivers;
