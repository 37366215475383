import React, { useEffect, useState } from 'react';
import { IPaymentCard } from 'common/types';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectDefaultPaymentCard,
  selectPendingPaymentCards,
} from 'modules/me/slice';
import { selectPendingCardUrl } from 'modules/transaction/slice';
import PaymentMethodModal from 'components/PaymentMethodModal';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
// import bgSignup from 'assets/images/card-visa.svg';
import { IMAGE_URL } from 'common/constants';
import classNames from 'classnames';
import {ReactComponent as IconPlus} from 'assets/images/plus.svg'
interface Props {
  paymentCards?: IPaymentCard[] | [];
}

const PaymentMethod = ({ paymentCards }: Props) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const isPendingPaymentCards = useAppSelector(selectPendingPaymentCards);
  const isPendingCardUrl = useAppSelector(selectPendingCardUrl);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const cardTotal = paymentCards?.length || 0;
  const [imageName, setNewImage] = useState<string>('');
  const [isImageExist, setIsImageExist] = useState<boolean>(true);

  useEffect(() => {
    if (defaultPaymentCard)
      try {
        setNewImage(
          require(`assets/images/card-${defaultPaymentCard?.type}.svg`),
        );
        // do stuff
      } catch (ex) {
        setIsImageExist(false);
      }
  }, [defaultPaymentCard]);

  const addCard = () => {
    dispatch({ type: ACTIONS.FETCH_WINDCAVE_CARD_URL });
  };

  return (
    <article className="mb-[40px] md:mb-[70px]">
      <p className="mb-[20px] text-[18px] sm:text-[20px]">Payment Method</p>
      {isPendingPaymentCards && isPendingCardUrl ? (
        <div className="flex animate-pulse flex-col gap-4">
          <div className="h-[88px] w-full rounded bg-slate-100" />
          <div className="h-[30px] w-full rounded bg-slate-100" />
        </div>
      ) : cardTotal === 0 ? (
        <label
          role="button"
          className="grid h-[80px] w-full place-items-center  rounded-md border-[1px] border-transparent p-[20px] py-4 text-[35px] text-flexi-orange-1 shadow-[0_2px_9px] shadow-flexi-white-2 duration-200 hover:border-flexi-orange-1 hover:border-[1px]"
          onClick={() => {
            addCard();
            setModalOpen(true);
          }}
        >
          <IconPlus className='w-[20px] h-[20px]' />
        </label>
      ) : (
        <div
          className="flex w-full place-items-center justify-between rounded-md border-[1px] hover:border-primary p-[15px] md:p-5"
          role="button"
          onClick={() => setModalOpen(true)}
        >
          <div className="flex gap-2 sm:gap-3">
            <p
              style={{
                backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                backgroundSize: '100%'
              }}
              className={classNames(
                'grid w-[52px] md:w-[65px] place-items-center bg-[center_left] bg-no-repeat',
                {
                  'border-[1px]': !isImageExist,
                },
              )}
            >
              {!isImageExist && 'N/A'}
            </p>
            <div className="flex flex-col">
              <p className="text-[12px] md:text-[14px] w-[130px] sm:w-[200px] capitalize">
                <span className="font-bold">●●●● ●●●● ●●●● </span>
                {defaultPaymentCard?.cardNumber}
              </p>

              <p className='text-[10px] md:text-[14px]'>
                Expires on {defaultPaymentCard?.expiryMonth}/
                {defaultPaymentCard?.expiryYear}
              </p>
            </div>
          </div>
          <p className="text-[14px] md:text-[16px] capitalize text-flexi-black-3/30">default</p>
        </div>
      )}

      <PaymentMethodModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      />
    </article>
  );
};

export default PaymentMethod;
