import React, { Dispatch, useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { addDays, addHours } from 'date-fns';
import haversine from 'haversine-distance';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import * as _ from 'lodash';

import Iconhuman from 'assets/images/human.svg';
import IconArrow from 'assets/images/arrow_thin_orange.svg';
import vehiclePlaceholder from 'assets/images/vehicle-placeholder.svg';
import { ReactComponent as IconNight } from 'assets/images/mode_night.svg';
import { ReactComponent as IconLight } from 'assets/images/mode_light.svg';
import { ReactComponent as IconSuccessModal } from 'assets/images/success.svg';
import { ReactComponent as IconFailedModal } from 'assets/images/cancel_booking.svg';
import { IMAGE_URL } from 'common/constants';
import { formatDate } from 'common/helpers';
import { IPodAll, IVehicleSummary, RequestStatusEnum } from 'common/types';
import {
  selectBookingEstimateStatus,
  selectBookingSummaryModify,
  selectErrors,
  selectFetchAvailableVehicles,
  selectFetchAvailableVehiclesStatus,
  selectRescheduleDataStatus,
  selectUpdateBookingEstimateAmount,
  selectUpdateBookingStatus,
  selectrescheduleData,
  setEndDate,
  setRescheduleData,
  setRescheduleDataStatus,
  setStartDate,
  setUpdateBookingStatus,
} from 'modules/booking/slice';
import {
  selectPendingVehicleAvailable,
  selectVehicleAvailable,
  selectVehicleCategoryRates,
} from 'modules/vehicle/slice';
import { selectUser } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import Modal from 'components/Modal';
import PendingLoader from 'components/PendingLoader';
import HasUnpaidModal from 'components/HasUnpaidModal';

interface MyComponentProps {
  name: string;
  image: string;
  groupImage: string;
  make: string;
}

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<boolean>;
  bookingId: number | undefined;
  roundUpDate: Date;
  setIsCancelModalOpen: Dispatch<boolean>;
  refreshMode: string | null;
}

const ModalModify = ({
  isModalOpen,
  setIsModalOpen,
  bookingId,
  roundUpDate,
  setIsCancelModalOpen,
  refreshMode,
}: Props) => {
  const dispatch = useAppDispatch();
  const { podsAll, computedRateV2 } = useBooking({});
  const user = useAppSelector(selectUser);
  const errors = useAppSelector(selectErrors);
  const availability = useAppSelector(selectVehicleAvailable);
  const rescheduleData = useAppSelector(selectrescheduleData);
  const bookingSummary = useAppSelector(selectBookingSummaryModify);
  const estimateStatus = useAppSelector(selectBookingEstimateStatus);
  const rawCategoryRates = useAppSelector(selectVehicleCategoryRates);
  const updateBookingStatus = useAppSelector(selectUpdateBookingStatus);
  const availableVehicles = useAppSelector(selectFetchAvailableVehicles);
  const rescheduleDataStatus = useAppSelector(selectRescheduleDataStatus);
  const estimateAmount = useAppSelector(selectUpdateBookingEstimateAmount);
  const pendingAvailability = useAppSelector(selectPendingVehicleAvailable);
  const availableVehiclesStatus = useAppSelector(
    selectFetchAvailableVehiclesStatus,
  );
  const maxBookDays = user?.organisation?.maximumBookingDays || 5;
  const [endDateInt, setEndDateInt] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(0);
  const [pmam, setPmam] = useState('AM');
  const [startDateInt, setStartDateInt] = useState(new Date());
  const [selectedDatePickUp, setSelectedDatePickUp] = useState(new Date());
  const [minutePickUp, setMinutePickUp] = useState(0);
  const [hourPickUp, setHourPickUp] = useState(0);
  const [pmamPickUp, setPmamPickUp] = useState('AM');
  const [sortedVehicles, setSortedVehicles] = useState<IVehicleSummary[]>([]);
  const [newVehicleIdSelected, setNewVehicleIdSelected] = useState(0);
  const [isBrowsingVehicles, setIsBrowsingVehicles] = useState(false);
  const [isBookingEstimate, setIsBookingEstimate] = useState(false);
  const [availableDate, setAvailableDate] = useState(new Date());
  const [page, setPage] = useState('pickup');

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useEffect(() => {
    if (isModalOpen && bookingId) {
      const payload = {
        bookingId,
      };
      dispatch({
        type: ACTIONS.FETCH_RESCHEDULE_DETAILS,
        payload,
      });
      dispatch({
        type: ACTIONS.FETCH_BOOKING_SUMMARY_MODIFY,
        payload,
      });
    }
  }, [isModalOpen]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleDateChangePickup = (date: any) => {
    setSelectedDatePickUp(date);
  };

  useEffect(() => {
    setAvailableDate(addDays(selectedDatePickUp, maxBookDays));
  }, [availability, selectedDatePickUp]);

  useEffect(() => {
    if (bookingSummary) {
      const options = { timeZone: bookingSummary?.podTimeZone };
      const dateString = new Date(bookingSummary?.endsAt).toLocaleString(
        'en-US',
        options,
      );
      const newDate =
        endDateInt < addHours(selectedDatePickUp, 1)
          ? addHours(selectedDatePickUp, 1)
          : endDateInt > availableDate
          ? availableDate
          : new Date(dateString);
      setEndDateInt(newDate);
      setSelectedDate(
        selectedDate < addHours(selectedDatePickUp, 1)
          ? addHours(selectedDatePickUp, 1)
          : selectedDate > addDays(selectedDatePickUp, maxBookDays)
          ? addHours(selectedDatePickUp, 1)
          : selectedDate,
      );
      setHour(newDate.getHours() % 12);
      setMinute(newDate.getMinutes());
      setPmam(newDate.getHours() > 11 ? 'PM' : 'AM');
    }
  }, [
    availability,
    selectedDatePickUp,
    hourPickUp,
    minutePickUp,
    pmamPickUp,
    page,
  ]);

  useEffect(() => {
    if (bookingSummary) {
      const options = { timeZone: bookingSummary?.podTimeZone };
      const dateString = new Date(bookingSummary?.startsAt).toLocaleString(
        'en-US',
        options,
      );
      const newDate2 = new Date(dateString);
      setStartDateInt(newDate2);
      setSelectedDatePickUp(newDate2);
      setHourPickUp(newDate2.getHours() % 12);
      setMinutePickUp(newDate2.getMinutes());
      setPmamPickUp(newDate2.getHours() > 11 ? 'PM' : 'AM');
    }
  }, [bookingSummary, availability]);

  useEffect(() => {
    const convertedSelectDate = new Date(selectedDate);
    convertedSelectDate.setHours(pmam === 'AM' ? hour : hour + 12, minute);
    setSelectedDate(convertedSelectDate);
  }, [minute, hour, pmam]);

  useEffect(() => {
    if (selectedDate.getTime() < addHours(selectedDatePickUp, 1).getTime()) {
      setHour(addHours(selectedDatePickUp, 1).getHours() % 12);
      setMinute(addHours(selectedDatePickUp, 1).getMinutes());
      setPmam(addHours(selectedDatePickUp, 1).getHours() > 11 ? 'PM' : 'AM');
    }

    if (selectedDate.getTime() > availableDate.getTime()) {
      setHour(availableDate.getHours() % 12);
      setMinute(availableDate.getMinutes());
      setPmam(availableDate.getHours() > 11 ? 'PM' : 'AM');
    }
  }, [selectedDate, pmam]);

  useEffect(() => {
    const convertedSelectDate = new Date(selectedDatePickUp);
    convertedSelectDate.setHours(
      pmamPickUp === 'AM' ? hourPickUp : hourPickUp + 12,
      minutePickUp,
    );
    setSelectedDatePickUp(convertedSelectDate);
  }, [minutePickUp, hourPickUp, pmamPickUp]);

  useEffect(() => {
    if (selectedDatePickUp.getTime() < roundUpDate.getTime()) {
      setHourPickUp(roundUpDate.getHours() % 12);
      setMinutePickUp(roundUpDate.getMinutes());
      setPmamPickUp(roundUpDate.getHours() > 11 ? 'PM' : 'AM');
    }
  }, [selectedDatePickUp, pmamPickUp]);

  const handleClose = () => {
    if (bookingId && updateBookingStatus === RequestStatusEnum.SUCCESS) {
      const payload = {
        bookingId,
        noload: true,
      };
      dispatch({
        type: ACTIONS.FETCH_BOOKING_SUMMARY_MODIFY,
        payload,
      });
    }
    setIsBrowsingVehicles(false);
    setIsModalOpen(false);
    setPage('pickup');
    setIsBookingEstimate(false);
    dispatch(setUpdateBookingStatus(null));
    dispatch(setRescheduleData(null));
    dispatch(setRescheduleDataStatus(null));
    const payload = {
      page: 1,
    };
    dispatch({ type: ACTIONS.FETCH_BOOKINGS_UPCOMING_V2, payload });
  };

  const handleBookingEstimate = () => {
    const offset = moment()
      .tz(bookingSummary?.podTimeZone || 'Australia/Perth')
      .format('Z');
    const firstTwoChars = offset.replaceAll('+', '').slice(0, 2);
    const endsAt = moment(selectedDate)
      .subtract(firstTwoChars, 'hour')
      .format()
      .split('+')[0];
    const startsAt = moment(selectedDatePickUp)
      .subtract(firstTwoChars, 'hour')
      .format()
      .split('+')[0];
    if (bookingSummary) {
      dispatch({
        type: ACTIONS.FETCH_BOOKING_ESTIMATE,
        payload: {
          bookingId: bookingSummary?.id || bookingId,
          startsAt,
          endsAt,
          timezone: offset,
        },
      });
      setIsBookingEstimate(true);
    }
  };

  const handleConfirm = () => {
    setIsModalOpen(true);
    const endsAt = moment(selectedDate).format().split('+')[0];
    const startsAt = moment(selectedDatePickUp).format().split('+')[0];
    dispatch({
      type: ACTIONS.UPDATE_BOOKING_V2,
      payload: {
        bookingId: bookingSummary?.id,
        startsAt,
        endsAt,
        refreshMode,
      },
    });
  };

  const handleConfirmChangeVehicle = (vehicleId: number) => {
    setNewVehicleIdSelected(vehicleId);
    const endsAt = moment(selectedDate).format().split('+')[0];
    const startsAt = moment(selectedDatePickUp).format().split('+')[0];
    dispatch({
      type: ACTIONS.UPDATE_BOOKING_V2,
      payload: {
        bookingId: bookingSummary?.id,
        startsAt,
        endsAt,
        vehicle: vehicleId,
        refreshMode,
      },
    });
  };

  useEffect(() => {
    if (
      updateBookingStatus === RequestStatusEnum.SUCCESS ||
      updateBookingStatus === RequestStatusEnum.FAILED
    ) {
      setIsBrowsingVehicles(false);
      setIsBookingEstimate(false);
    }
  }, [updateBookingStatus]);

  const browseVehicles = () => {
    setIsBrowsingVehicles(true);
    setNewVehicleIdSelected(0);
    dispatch(setStartDate(selectedDatePickUp));
    dispatch(setEndDate(selectedDate));
    const endsAt = moment(selectedDate).format().split('+')[0];
    const startsAt = moment(selectedDatePickUp).format().split('+')[0];
    dispatch({
      type: ACTIONS.FETCH_AVAILABLE_VEHICLES,
      payload: {
        bookingId: bookingSummary?.id,
        startsAt,
        endsAt,
      },
    });
    dispatch({
      type: ACTIONS.GET_PODS_ALL,
    });
    dispatch({
      type: ACTIONS.GET_VEHICLE_CATEGORY_RATES,
      payload: {
        planId: user?.plan?.id || 1,
      },
    });
  };

  useEffect(() => {
    if (bookingSummary && availableVehicles && availableVehicles?.length > 0) {
      const sorted = _.sortBy(availableVehicles, [
        function (vehicle) {
          const vehicleLatLng = {
            lat: Number(vehicle.podLatitude),
            lng: Number(vehicle.podLongitude),
          };
          const calculatedDistance = haversine(
            {
              lat: Number(bookingSummary.vehicle.podLatitude),
              lng: Number(bookingSummary.vehicle.podLongitude),
            },
            vehicleLatLng,
          );

          return calculatedDistance;
        },
      ]);
      if (sorted.length > 0) setSortedVehicles(sorted);
    }
  }, [availableVehicles]);

  const VehicleImage: React.FC<MyComponentProps> = ({
    name,
    image,
    groupImage,
    make,
  }) => {
    const [vehicleImageError, setVehicleImageError] = React.useState(false);
    const [groupImageError, setGroupImageError] = React.useState(false);

    return (
      <div className="ml-auto w-full object-contain">
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': !vehicleImageError,
          })}
          src={image !== null ? image : ''}
          alt={name}
          onError={() => setVehicleImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && !groupImageError,
          })}
          src={groupImage !== null ? groupImage : ''}
          alt={make}
          onError={() => setGroupImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && groupImageError,
          })}
          src={`${IMAGE_URL}${vehiclePlaceholder}`}
          alt="vehicle default image"
        />
      </div>
    );
  };

  const renderModalContent = () => {
    if (rescheduleDataStatus === RequestStatusEnum.PENDING) {
      return (
        <section className="flex min-h-[375px] items-center justify-center">
          <PendingLoader size={100} />
        </section>
      );
    }
    if (rescheduleData && rescheduleData.settingsRescheduleLimit <= rescheduleData.bookingRescheduledCount) {
      return (
        <>
          <IconFailedModal className="icon" />
          <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
            Unable to Modify Booking
          </h1>
          <p className="text-center text-[18px]">
            Oops, you&apos;ve exceeded the modification limits for this booking.
            Please reach out to our member care team to{' '}
            <span className='whitespace-nowrap'>proceed further.</span>
          </p>
          <button
            className="button btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2"
            onClick={handleClose}
          >
            ok
          </button>
        </>
      );
    } else {

      if (updateBookingStatus === RequestStatusEnum.SUCCESS) {
        return (
          <>
            <IconSuccessModal className="icon" />
            <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
              Your Flexicar booking has&nbsp;
              <span className="whitespace-nowrap">been updated.</span>
            </h1>
  
            <button
              className="button btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2"
              onClick={handleClose}
            >
              ok
            </button>
          </>
        );
      }
      if (isBrowsingVehicles) {
        return (
          <>
            {availableVehiclesStatus === RequestStatusEnum.PENDING ? (
              <span className="my-[210px] min-h-[100px] min-w-[100px] animate-spin self-center rounded-full border-[5px] border-flexi-black-5 border-t-transparent" />
            ) : (
              <>
                {bookingSummary &&
                availableVehiclesStatus === RequestStatusEnum.SUCCESS &&
                sortedVehicles.length > 0 ? (
                  <>
                    <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
                      Browse Vehicles
                    </h1>
                    <div className="flex flex-col gap-[25px]">
                      {sortedVehicles?.map((vehicle: IVehicleSummary) => {
                        const vehicleLatLng = {
                          lat: Number(vehicle.podLatitude),
                          lng: Number(vehicle.podLongitude),
                        };
                        const calculatedDistance = haversine(
                          {
                            lat: Number(bookingSummary.vehicle.podLatitude),
                            lng: Number(bookingSummary.vehicle.podLongitude),
                          },
                          vehicleLatLng,
                        );
                        const podTarget: IPodAll | undefined = podsAll?.find(
                          (pod: IPodAll) => pod.id === vehicle.podId,
                        );
  
                        const rawRates = rawCategoryRates?.podGroups
                          .filter(
                            (child) => child.podGroupId === podTarget?.podGroupId,
                          )
                          .map((child) => ({
                            ...child,
                            vehicleGroups: child.vehicleGroups.filter(
                              (grandchild) =>
                                grandchild.vehicleGroupId === vehicle.group.id,
                            ),
                          }));
  
                        return (
                          <div
                            key={vehicle.id}
                            className={classNames('flex w-full', {
                              hidden: rawRates && !computedRateV2(rawRates),
                            })}
                          >
                            <div className="w-[120px]">
                              <VehicleImage
                                name={vehicle.name}
                                image={vehicle.imageUrl}
                                groupImage={vehicle.group.vehicleImage}
                                make={vehicle.group.make}
                              />
                            </div>
                            <div className="ml-[15px] mr-auto max-w-[300px]">
                              <p className="truncate font-bold">{vehicle.name}</p>
                              <p className="text-[12px]">
                                {vehicle.make}
                                {vehicle.model}
                              </p>
                              <p className="truncate text-[14px]">
                                {vehicle.podName}
                              </p>
                              <div className="flex items-center gap-[5px]">
                                <div
                                  className="h-[10px] w-[6px] bg-center bg-no-repeat"
                                  style={{
                                    backgroundImage: `url(${IMAGE_URL}${Iconhuman})`,
                                  }}
                                />
                                <p className="text-[12px]">
                                  {calculatedDistance < 1000 ? (
                                    <>{calculatedDistance.toFixed(0)} m</>
                                  ) : calculatedDistance >= 1000 ? (
                                    <>
                                      {(calculatedDistance / 1000).toFixed(1)} km
                                    </>
                                  ) : (
                                    <>{'-'}</>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="flex w-[115px] flex-col items-center gap-[10px]">
                              <strong>
                                {rawRates !== undefined ? (
                                  <>{computedRateV2(rawRates)?.toFixed(2)}</>
                                ) : (
                                  <>-</>
                                )}
                              </strong>
                              <button
                                className={classNames(
                                  'space-around btn-primary flex !h-[30px] w-full items-center justify-center gap-[10px] rounded-full text-[14px] font-bold disabled:!bg-gray-500',
                                  {
                                    'bg-gray-500':
                                      updateBookingStatus ===
                                      RequestStatusEnum.PENDING,
                                  },
                                )}
                                onClick={() =>
                                  handleConfirmChangeVehicle(vehicle.id)
                                }
                                disabled={
                                  updateBookingStatus ===
                                  RequestStatusEnum.PENDING
                                }
                              >
                                {newVehicleIdSelected === vehicle.id && (
                                  <div className="h-[10px] w-[10px] animate-spin self-center rounded-full border-[2px] border-flexi-black-5 border-t-transparent" />
                                )}
                                Select
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <IconFailedModal className="icon" />
                    <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
                      Unable to modify booking
                    </h1>
                    <p className="text-center text-[18px]">
                      There are no available vehicles at your selected time.
                      <br />
                      <br />
                      Please select a different time or you can cancel the{' '}
                      <span className="whitespace-nowrap">booking entirely.</span>
                    </p>
                    <button
                      className={classNames(
                        '!md:h-[60px] btn mt-[30px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2',
                      )}
                      onClick={() => {
                        dispatch(setUpdateBookingStatus(null));
                        setIsBookingEstimate(false);
                        setIsBrowsingVehicles(false);
                        setPage('pickup');
                      }}
                    >
                      Edit Time
                    </button>
                    <div className="flex w-full items-center py-[25px]">
                      <span
                        className="mx-auto cursor-pointer font-bold text-flexi-error"
                        onClick={() => {
                          setIsModalOpen(false);
                          setIsCancelModalOpen(true);
                        }}
                      >
                        Cancel Booking
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        );
      }
      if (updateBookingStatus === RequestStatusEnum.FAILED) {
        return (
          <>
            <IconFailedModal className="icon" />
            <h1 className="title form-control text-center text-[20px] font-bold md:text-[34px]">
              Unable to modify booking
            </h1>
            {errors &&
            errors.errorCode &&
            (errors.errorCode.code === 'preauth_failed' ||
              errors.errorCode.code === 'increment_auth_failed') ? (
              <>
                <p className="text-center text-[18px]">
                  Unfortunately we are unable to process your payment.&nbsp;
                  <span className="whitespace-nowrap">Please check:</span>
                  <br />- That you have entered your card details correctly.
                  <br />- That you have sufficient funds on your card.
                  <br />
                  Please try again to ensure that your booking is successful.
                </p>
                <button
                  className={classNames(
                    '!md:h-[60px] btn mt-[30px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2',
                    {
                      'loading bg-flexi-green-2':
                        availableVehiclesStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                  onClick={() => {
                    setIsModalOpen(false);
                    dispatch(setUpdateBookingStatus(null));
                  }}
                >
                  Ok
                </button>
                <div className="flex w-full items-center py-[25px]">
                  <span
                    className="mx-auto cursor-pointer font-bold text-flexi-error"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsCancelModalOpen(true);
                      dispatch(setUpdateBookingStatus(null));
                    }}
                  >
                    Cancel Booking
                  </span>
                </div>
              </>
            ) : errors &&
              errors.errorCode &&
              errors.errorCode.code === 'update_not_allowed' ? (
              <>
                <p className="text-center text-[18px]">
                  Booking modification for this car is not available at{' '}
                  <span className="whitespace-nowrap">this time.</span>
                  <br />
                  You may opt to cancel&nbsp;
                  <span className="whitespace-nowrap">this booking.</span>
                </p>
                <button
                  className={classNames(
                    '!md:h-[60px] btn mt-[30px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2',
                    {
                      'loading bg-flexi-green-2':
                        availableVehiclesStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                  onClick={() => {
                    setIsModalOpen(false);
                    setIsCancelModalOpen(true);
                    dispatch(setUpdateBookingStatus(null));
                  }}
                >
                  Cancel Booking
                </button>
              </>
            ) : (
              <>
                <p className="text-center text-[18px]">
                  Booking modification for this car is not available at this time.
                  <br />
                  <br />
                  Please select other vehicle options or you can cancel the{' '}
                  <span className="whitespace-nowrap">booking entirely.</span>
                </p>
                <button
                  className={classNames(
                    '!md:h-[60px] btn mt-[30px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2',
                    {
                      'loading bg-flexi-green-2':
                        availableVehiclesStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                  onClick={browseVehicles}
                >
                  Browse Vehicles
                </button>
                <div className="flex w-full items-center py-[25px]">
                  <span
                    className="mx-auto cursor-pointer font-bold text-flexi-error"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsCancelModalOpen(true);
                      dispatch(setUpdateBookingStatus(null));
                    }}
                  >
                    Cancel Booking
                  </span>
                </div>
              </>
            )}
          </>
        );
      }
      if (isBookingEstimate) {
        return (
          <>
            <h1 className="text-center text-[20px] font-bold capitalize md:text-[34px]">
              confirm booking modification
            </h1>
            <p className="mt-[20px] mb-[30px] text-center text-[16px] md:text-[18px]">
              You&apos;re about to modify your trip. Please confirm the new date
              and cost&nbsp;below:
            </p>
            <div className="mb-[30px] flex items-center justify-between gap-[10px] md:gap-[20px]">
              <div className="text-[14px] md:text-[16px]">
                <p className="mb-[10px] text-[16px] font-bold md:text-[18px]">
                  From
                </p>
                <p className="font-bold">
                  Pickup: {isMobile && <br />}
                  {moment(bookingSummary?.startsAt)
                    .tz(bookingSummary?.podTimeZone || 'Australia/Perth')
                    .format('MMMM DD | h:mm a')}
                </p>
                <p className="font-bold">
                  Return: {isMobile && <br />}
                  {moment(bookingSummary?.endsAt)
                    .tz(bookingSummary?.podTimeZone || 'Australia/Perth')
                    .format('MMMM DD | h:mm a')}
                </p>
                <p>Cost: ${bookingSummary?.amount?.toFixed(2)}</p>
              </div>
              <span
                className="block h-[15px] w-[15px] bg-center bg-no-repeat md:hidden"
                style={{
                  backgroundImage: `url(${IMAGE_URL}${IconArrow})`,
                  backgroundSize: 'contain',
                }}
              />
              <span
                className="hidden h-[25px] w-[25px] bg-center bg-no-repeat md:block"
                style={{
                  backgroundImage: `url(${IMAGE_URL}${IconArrow})`,
                  backgroundSize: 'contain',
                }}
              />
  
              <div className="text-[14px] md:text-[16px]">
                <p className="mb-[10px] text-[16px] font-bold md:text-[18px]">
                  To
                </p>
                <p className="font-bold">
                  Pickup: {isMobile && <br />}
                  {formatDate(
                    selectedDatePickUp.toISOString(),
                    'MMMM DD | h:mm a',
                  )}
                </p>
                <p className="font-bold">
                  Return: {isMobile && <br />}
                  {formatDate(selectedDate.toISOString(), 'MMMM DD | h:mm a')}
                </p>
                <p>
                  Cost:{' '}
                  <span
                    className={classNames({
                      'mx-[10px] inline-block h-[20px] w-[20px] animate-spin rounded-full border-[1px] border-flexi-black-9 border-t-transparent align-middle text-transparent':
                        estimateStatus === RequestStatusEnum.PENDING,
                    })}
                  >
                    ${estimateAmount?.toFixed(2)}
                  </span>{' '}
                </p>
              </div>
            </div>
            <button
              className={classNames(
                '!md:h-[60px] btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2',
                {
                  'loading bg-flexi-green-2':
                    updateBookingStatus === RequestStatusEnum.PENDING,
                },
              )}
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </>
        );
      }
  
      return (
        <section>
          <h1 className="text-center text-[20px] font-bold capitalize md:text-[34px]">
            booking modification
          </h1>
          {page === 'pickup' ? (
            <>
              <p className="my-[20px] text-center text-[14px] md:text-[16px]">
                Your booking starts{' '}
                <span className="font-bold ">
                  {moment
                    .utc(bookingSummary?.startsAt)
                    .tz(bookingSummary?.podTimeZone || 'Australia/Sydney')
                    .format('h:mm A')}
                </span>{' '}
                on the{' '}
                <span className="font-bold">
                  {moment
                    .localeData()
                    .ordinal(moment(bookingSummary?.startsAt).date())}{' '}
                  of {formatDate(bookingSummary?.startsAt, 'MMMM')}
                </span>
                . You may be able to change your pick-up from:
              </p>
              <p className="border-b-[1px] pb-[50px] text-center text-[18px] font-bold md:text-[26px]">
                {formatDate(roundUpDate.toISOString(), 'h:mm A')} on the{' '}
                {moment.localeData().ordinal(moment(roundUpDate).date())} of{' '}
                {formatDate(roundUpDate.toISOString(), 'MMMM')}
              </p>
              {pendingAvailability ? (
                <span className="my-[210px] min-h-[100px] min-w-[100px] animate-spin self-center rounded-full border-[5px] border-flexi-black-5 border-t-transparent" />
              ) : (
                <div
                  className={classNames('extend__container w-full', {
                    mobile: isMobile,
                  })}
                >
                  <DatePicker
                    selected={selectedDatePickUp}
                    onChange={handleDateChangePickup}
                    minDate={roundUpDate}
                    maxDate={addDays(roundUpDate, 365)}
                    dateFormat="MMMM d   |   h:mm aa"
                    inline
                  >
                    <section className="flex flex-col gap-3">
                      <div className="flex items-center gap-2">
                        {/* Hour */}
                        <div className="dropdown-top dropdown">
                          <label
                            tabIndex={0}
                            className="btn relative m-1 h-[75px] w-[70px] border-zinc-300 bg-white text-[30px] font-normal text-flexi-black-4 hover:bg-white"
                          >
                            {hourPickUp === 0 ? '12' : hourPickUp}
                          </label>
                          <ul
                            tabIndex={0}
                            className="scrollbar hide-scrollbar dropdown-content menu rounded-box flex max-h-[200px] w-full !flex-col-reverse overflow-auto bg-base-100 p-2 !text-center shadow"
                          >
                            {[...new Array(12)].map((_, i) => {
                              const date = new Date(startDateInt);
                              const firstDate = new Date(roundUpDate);
                              date.setHours(
                                pmamPickUp === 'AM' ? i : i + 12,
                                minutePickUp,
                              );
                              firstDate.setHours(
                                pmamPickUp === 'AM' ? i : i + 12,
                                minutePickUp,
                              );
                              const isHourPassed =
                                firstDate < roundUpDate &&
                                selectedDatePickUp.getDate() ===
                                  roundUpDate.getDate();
                              return (
                                <li
                                  key={i}
                                  className={classNames(
                                    'flex snap-start  items-center text-[20px]',
                                    {
                                      'pointer-events-none !text-zinc-200':
                                        isHourPassed,
                                    },
                                  )}
                                  style={{ order: i === 0 ? '1' : '0' }}
                                >
                                  <a
                                    onClick={() => {
                                      setHourPickUp(i);
                                    }}
                                  >
                                    {i === 0 ? '12' : i}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <p className="text-2xl">:</p>
                        {/* Minute */}
                        <div className="dropdown-top dropdown">
                          <label
                            tabIndex={0}
                            className="btn relative m-1 h-[75px] w-[70px] border-zinc-300 bg-white text-[30px] font-normal text-flexi-black-4 hover:bg-white"
                          >
                            {minutePickUp === 0 && '0'}
                            {minutePickUp}
                          </label>
                          <ul
                            tabIndex={0}
                            className="scrollbar hide-scrollbar dropdown-content menu rounded-box flex max-h-[200px] w-full !flex-col-reverse overflow-auto !rounded-tr-none bg-base-100 p-2 !text-center shadow"
                          >
                            {[...new Array(4)].map((_, i) => {
                              const date = new Date(startDateInt);
                              const firstDate = new Date(roundUpDate);
                              date.setHours(
                                pmamPickUp === 'AM'
                                  ? hourPickUp
                                  : hourPickUp + 12,
                                i * 15,
                              );
                              firstDate.setHours(
                                pmamPickUp === 'AM'
                                  ? hourPickUp
                                  : hourPickUp + 12,
                                i * 15,
                              );
                              const isHourPassed =
                                firstDate < roundUpDate &&
                                selectedDatePickUp.getDate() ===
                                  roundUpDate.getDate();
  
                              return (
                                <li
                                  key={i}
                                  className={classNames(
                                    'flex snap-start  items-center text-[20px]',
                                    {
                                      'pointer-events-none !text-zinc-200':
                                        isHourPassed,
                                    },
                                  )}
                                >
                                  <a onClick={() => setMinutePickUp(i * 15)}>
                                    {i === 0 && '0'}
                                    {i * 15}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/* PM & AM */}
                        <div className="ml-[15px] flex flex-col gap-5">
                          <IconLight
                            className={classNames(
                              'cursor-pointer fill-zinc-300',
                              {
                                '!fill-flexi-orange-1': pmamPickUp === 'AM',
                              },
                            )}
                            onClick={() =>
                              ((roundUpDate.getHours() <= 11 &&
                                roundUpDate.toDateString() ===
                                  selectedDatePickUp.toDateString()) ||
                                selectedDatePickUp.getDate() >
                                  roundUpDate.getDate()) &&
                              setPmamPickUp('AM')
                            }
                          />
                          <IconNight
                            className={classNames(
                              'cursor-pointer fill-zinc-300',
                              {
                                '!fill-flexi-black-2': pmamPickUp === 'PM',
                              },
                            )}
                            onClick={() =>
                              !(
                                roundUpDate.getHours() > 12 &&
                                roundUpDate.toDateString() ===
                                  selectedDatePickUp.toDateString()
                              ) && setPmamPickUp('PM')
                            }
                          />
                        </div>
                      </div>
                    </section>
                  </DatePicker>
                </div>
              )}
              <button
                className={`btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2`}
                onClick={() => {
                  setPage('return');
                  setSelectedDate(
                    selectedDate < addHours(selectedDatePickUp, 1)
                      ? addHours(selectedDatePickUp, 1)
                      : selectedDate > addDays(selectedDatePickUp, maxBookDays)
                      ? addHours(selectedDatePickUp, 1)
                      : selectedDate,
                  );
                }}
              >
                Confirm Pickup
              </button>
            </>
          ) : (
            <>
              <p className="my-[20px] text-center text-[14px] md:text-[16px]">
                Your booking ends{' '}
                <span className="font-bold ">
                  {moment
                    .utc(bookingSummary?.endsAt)
                    .tz(bookingSummary?.podTimeZone || 'Australia/Sydney')
                    .format('h:mm A')}
                </span>{' '}
                on the{' '}
                <span className="font-bold">
                  {moment
                    .localeData()
                    .ordinal(moment(bookingSummary?.endsAt).date())}{' '}
                  of {formatDate(bookingSummary?.endsAt, 'MMMM')}
                </span>
                . You may be able to change your drop off to:
              </p>
              <p className="border-b-[1px] pb-[50px] text-center text-[16px] font-bold md:text-[18px]">
                {formatDate(availableDate.toISOString(), 'h:mm A')} on the{' '}
                {moment.localeData().ordinal(moment(availableDate).date())} of{' '}
                {formatDate(availableDate.toISOString(), 'MMMM')}
              </p>
              {pendingAvailability ? (
                <span className="my-[210px] min-h-[100px] min-w-[100px] animate-spin self-center rounded-full border-[5px] border-flexi-black-5 border-t-transparent" />
              ) : (
                <div
                  className={classNames('extend__container w-full', {
                    mobile: isMobile,
                  })}
                >
                  <DatePicker
                    selected={
                      selectedDate < addHours(selectedDatePickUp, 1)
                        ? addHours(selectedDatePickUp, 1)
                        : selectedDate > addDays(selectedDatePickUp, maxBookDays)
                        ? addHours(selectedDatePickUp, 1)
                        : selectedDate
                    }
                    onChange={handleDateChange}
                    minDate={addHours(selectedDatePickUp, 1)}
                    maxDate={availableDate}
                    dateFormat="MMMM d   |   h:mm aa"
                    inline
                  >
                    <section className="flex flex-col gap-3">
                      <div className="flex items-center gap-2">
                        {/* Hour */}
                        <div className="dropdown-top dropdown">
                          <label
                            tabIndex={0}
                            className="btn relative m-1 h-[75px] w-[70px] border-zinc-300 bg-white text-[30px] font-normal text-flexi-black-4 hover:bg-white"
                          >
                            {hour === 0 ? '12' : hour}
                          </label>
                          <ul
                            tabIndex={0}
                            className="scrollbar hide-scrollbar dropdown-content menu rounded-box flex max-h-[200px] w-full !flex-col-reverse overflow-auto bg-base-100 p-2 !text-center shadow"
                          >
                            {[...new Array(12)].map((_, i) => {
                              const date = new Date(
                                addHours(selectedDatePickUp, 1),
                              );
                              const lastDate = new Date(availableDate);
                              date.setHours(pmam === 'AM' ? i : i + 12, minute);
                              lastDate.setHours(
                                pmam === 'AM' ? i : i + 12,
                                minute,
                              );
                              const isHourPassed =
                                addHours(selectedDatePickUp, 1) > date &&
                                selectedDate.getDate() ===
                                  addHours(selectedDatePickUp, 1).getDate();
                              const isHourExceeds =
                                availableDate < lastDate &&
                                selectedDate.getDate() ===
                                  availableDate.getDate();
                              return (
                                <li
                                  key={i}
                                  className={classNames(
                                    'flex snap-start items-center text-[20px]',
                                    {
                                      'pointer-events-none !text-zinc-200':
                                        isHourPassed || isHourExceeds,
                                    },
                                  )}
                                  style={{ order: i === 0 ? '1' : '0' }}
                                >
                                  <a
                                    onClick={() => {
                                      setHour(i);
                                    }}
                                  >
                                    {i === 0 ? '12' : i}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <p className="text-2xl">:</p>
                        {/* Minute */}
                        <div className="dropdown-top dropdown">
                          <label
                            tabIndex={0}
                            className="btn relative m-1 h-[75px] w-[70px] border-zinc-300 bg-white text-[30px] font-normal text-flexi-black-4 hover:bg-white"
                          >
                            {minute === 0 && '0'}
                            {minute}
                          </label>
                          <ul
                            tabIndex={0}
                            className="scrollbar hide-scrollbar dropdown-content menu rounded-box flex max-h-[200px] w-full !flex-col-reverse overflow-auto !rounded-tr-none bg-base-100 p-2 !text-center shadow"
                          >
                            {[...new Array(4)].map((_, i) => {
                              const date = new Date(
                                addHours(selectedDatePickUp, 1),
                              );
                              const lastDate = new Date(availableDate);
                              date.setHours(
                                pmam === 'AM' ? hour : hour + 12,
                                i * 15,
                              );
                              lastDate.setHours(
                                pmam === 'AM' ? hour : hour + 12,
                                i * 15,
                              );
                              const isHourPassed =
                                addHours(selectedDatePickUp, 1) > date &&
                                selectedDate.getDate() ===
                                  addHours(selectedDatePickUp, 1).getDate();
                              const isHourExceeds =
                                availableDate < lastDate &&
                                selectedDate.getDate() ===
                                  availableDate.getDate();
  
                              return (
                                <li
                                  key={i}
                                  className={classNames(
                                    'flex snap-start  items-center text-[20px]',
                                    {
                                      'pointer-events-none !text-zinc-200':
                                        isHourPassed || isHourExceeds,
                                    },
                                  )}
                                >
                                  <a onClick={() => setMinute(i * 15)}>
                                    {i === 0 && '0'}
                                    {i * 15}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/* PM & AM */}
                        <div className="ml-[15px] flex flex-col gap-5">
                          <IconLight
                            className={classNames(
                              'cursor-pointer fill-zinc-300',
                              {
                                '!fill-flexi-orange-1': pmam === 'AM',
                              },
                            )}
                            onClick={() =>
                              !(
                                availableDate.getHours() <= 12 &&
                                availableDate.toDateString() ===
                                  selectedDate.toDateString()
                              ) && setPmam('AM')
                            }
                          />
                          <IconNight
                            className={classNames(
                              'cursor-pointer fill-zinc-300',
                              {
                                '!fill-flexi-black-2': pmam === 'PM',
                              },
                            )}
                            onClick={() =>
                              !(
                                availableDate.getHours() <= 11 &&
                                availableDate.toDateString() ===
                                  selectedDate.toDateString()
                              ) && setPmam('PM')
                            }
                          />
                        </div>
                      </div>
                    </section>
                  </DatePicker>
                </div>
              )}
              <button
                className={`btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2`}
                onClick={handleBookingEstimate}
              >
                Confirm Return
              </button>
              <div className="flex w-full items-center py-[25px]">
                <span
                  className="mx-auto cursor-pointer text-[16px] font-bold capitalize text-primary"
                  onClick={() => setPage('pickup')}
                >
                  Go back to Pick-up
                </span>
              </div>
            </>
          )}
        </section>
      );
    }
  };

  return (
    <>
      {user?.hasUnpaidInvoice ? (
        <HasUnpaidModal
          isModalOpen={isModalOpen && user !== null}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          px={isMobile ? 'px-[25px]' : 'px-[120px]'}
          mx={isMobile ? '30px' : '0px'}
        />
      ) : (
        <Modal
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          px={isMobile ? 'px-[25px]' : 'px-[120px]'}
          mx={isMobile ? '30px' : '0px'}
        >
          {renderModalContent()}
        </Modal>
      )}
    </>
  );
};

export default ModalModify;
