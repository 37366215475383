import classNames from 'classnames';
import { IRegisterPlan, RequestStatusEnum } from 'common/types';
import Modal from 'components/Modal';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectPendingUpdate,
  selectUpdateUserPlanStatus,
  setUpdateUserPlanStatus,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { Dispatch } from 'react';
// import { ReactComponent as IconChangePlanModal } from 'assets/images/change_plan_modal.svg';
import { ReactComponent as IconSuccessModal } from 'assets/images/success.svg';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import LottieAnimation from 'components/LottieAnimation';
import changePlanLottie from 'assets/json/icon-change-plan.json';

interface Props {
  planSelected: IRegisterPlan | null;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<boolean>;
  currentPlan: string | null | undefined;
  isButtonDisabled: boolean;
  setIsButtonDisabled: Dispatch<boolean>;
}

function ChangePlanModal({
  currentPlan,
  planSelected,
  isModalOpen,
  setIsModalOpen,
  isButtonDisabled,
  setIsButtonDisabled
}: Props) {
  const updateUserPlanStatus = useAppSelector(selectUpdateUserPlanStatus);
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPendingUpdate);
  const { push } = useHistory();

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleModalClose = () => {
    // if (updateUserPlanStatus === RequestStatusEnum.SUCCESS)
    //   push('/app/account/plan-details');
    setIsModalOpen(false);
    dispatch(setUpdateUserPlanStatus(null));
  };

  const handleSelectPlan = (plan: number | undefined) => {
    setIsButtonDisabled(true);
    const payload = { planId: plan };
    dispatch({ type: ACTIONS.UPDATE_USER_PLAN, payload });
  };

  const renderUserPlanModalContent = () => {
    if (updateUserPlanStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <IconSuccessModal className="icon h-[85px] md:h-auto" />
          <h1 className="title form-control text-center text-[16px] md:text-3xl font-bold">
            Thank you!
          </h1>
          <p className='text-[16px] md:text-base text-center'>
            You have successfully changed your plan from{' '}
            <span className="capitalize">{currentPlan}</span> to{' '}
            <span className="capitalize">
              {planSelected?.id === 1
                ? 'sometimes'
                : planSelected?.id === 2
                ? 'often'
                : 'frequent'}
            </span>
            .
          </p>

          <button
            className="button btn flex h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize"
            onClick={() => {
              setIsModalOpen(false);
              push('/app/account/plan-details');
            }}
          >
            ok
          </button>
        </>
      );
    }

    return (
      <>
        <LottieAnimation
          lottieFile={changePlanLottie}
          isStopped={!isModalOpen}
        />
        <h1 className="title form-control text-center text-[16px] font-bold md:text-3xl">
          Are you sure you want to change your plan?
        </h1>
        <p className="text-center text-[14px] md:text-base">
          Please be advised that if the new plan you are changing to has prepaid
          driving credit, you will be charged immediately. Subsequent payments
          will be invoiced&nbsp;monthly.
        </p>
        <button
          className={classNames(
            'btn btn-primary mt-8 h-[50px] w-[calc(100vw-175px)] rounded-full capitalize disabled:text-white md:h-[60px] md:w-full',
            {
              loading: isPending,
            },
          )}
          onClick={() => handleSelectPlan(planSelected?.id)}
          disabled={isButtonDisabled || isPending}
        >
          Confirm
        </button>
      </>
    );
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleClose={() => {
        handleModalClose();
        if (updateUserPlanStatus === RequestStatusEnum.SUCCESS)
          push('/app/account/plan-details');
      }}
      px={isMobile ? 'px-[25px]' : 'px-[120px]'}
      mx={isMobile ? '30px' : '0px'}
    >
      {renderUserPlanModalContent()}
    </Modal>
  );
}

export default ChangePlanModal;
