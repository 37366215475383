import React, { useState, useRef, useLayoutEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { InvalidText } from 'components/Typography';
import useAppSelector from 'hooks/useAppSelector';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import CustomInput from 'components/CustomInput';
import classNames from 'classnames';
import { selectUserStatus } from 'modules/me/slice';
import { RequestStatusEnum } from 'common/types';
import gsap from 'gsap';

type FormValues = {
  email: string;
};

const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const pending = useAppSelector(selectUserStatus);

  const onSubmit: SubmitHandler<FormValues> = ({ email }) => {
    dispatch({
      type: ACTIONS.FORGOT_PASSWORD,
      payload: {email}
    });
  };

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  return (
    <section ref={component} className="fadein m-auto mt-[100px] flex w-full max-w-[80vw] md:max-w-[500px] flex-col items-center">
      <h2 className="font-primary mb-[20px] text-center text-[30px] md:text-[40px] font-[900] uppercase  text-primary">
        Forgot Password
      </h2>
      <p className="mb-[50px] text-center text-[16px] md:text-[18px] leading-[1.444]">
        Enter the email you signed up with and we will email you instructions on
        how to reset your&nbsp;password.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="form-control mb-5 w-full">
          <CustomInput
            title="Email Address"
            error={errors.email}
          >
            <input
              type="text"
              placeholder='email address'
              className={`peer input input-bordered w-full focus:outline-none ${
                errors?.email && 'border-red-500'
              }`}
              {...register('email', {
                required: { value: true, message: 'This field is required.' },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email format.',
                },
                onChange: async (e) => {
                  setEmail(e.target.value);
                },
              })}
            />
          </CustomInput>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <InvalidText>{message}</InvalidText>}
          />
        </div>
        <div className="mt-[70px] flex justify-center">
          <button
            type="submit"
            className={classNames(
              'btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] normal-case hover:bg-flexi-green-2 disabled:text-white',
              {
                loading: pending === RequestStatusEnum.PENDING,
                'pointer-events-none bg-zinc-200': errors.email,
              },
            )}
            disabled={pending === RequestStatusEnum.PENDING || !email}
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default ForgotPasswordForm;
