import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import StudentInformation from './StudentInformation';
import StudentExperience from './StudentExperience';
import { selectPending } from 'modules/ocr/slice';
import PendingLoader from 'components/PendingLoader';
import StudentEnrollment from './StudentEnrollment';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce } from 'react-use';
import useAppDispatch from 'hooks/useAppDispatch';
import { selectUser, selectUserInfo } from 'modules/me/slice';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import gsap from 'gsap';

export type FormValues = {
  experienceYears: number;
  hasSuspended: number;
  hasConvicted: number;
  hasClaimsInsurance: number;
  university: string;
  campus: string;
  suspendedExplanation: string;
  convictedExplanation: string;
  claimsInsuranceExplanation: string;
  hasConvictedFile: number;
  hasSuspendedFile: number;
  hasClaimsInsuranceFile: number;
  suspendedDocs: File;
  convictionDocs: File;
  claimsInsuranceDocs: File;
  courseEndDate: string;
};

const validationSchema = Yup.object().shape({
  university: Yup.string().required('This field is required.'),
  campus: Yup.string().required('This field is required.'),
  experienceYears: Yup.number()
    .required('This field is required.')
    .typeError('This field is required.'),
  suspendedExplanation: Yup.string().optional(),
  hasSuspended: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
  hasConvicted: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
  hasClaimsInsurance: Yup.number()
    .required('Please select Yes or No')
    .typeError('Please select Yes or No'),
  courseEndDate: Yup.string().required('This field is required.'),
});

const RegisterInformationStudent = () => {
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPending);
  const user = useAppSelector(selectUser);
  const userInfo = useAppSelector(selectUserInfo);
  const hasUniversity = user?.organisation?.isPartner ?? false;
  const [inputUniversity, setInputUniversity] = useState('')
  const [inputCampus, setInputCampus] = useState('')
  const [inputCourseDate, setInputCourseDate] = useState('')

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_USER_INFO });
  });

  useEffect(() => {
    if (userInfo?.university) {
      setInputUniversity(userInfo.university)
      setValue('university', userInfo.university || '');
      dispatch({ type: ACTIONS.FETCH_CAMPUS_LIST });
    }
  }, [userInfo?.university]);

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const payload = {
      memberType: user?.type.id,
      hasSeniorCard: 0,
      ...values,
    };
    dispatch({
      type: ACTIONS.UPDATE_USER_DETAILS,
      payload,
    });
  };

  return (
    <section ref={component} className="fadein flex w-full max-w-[700px] flex-col items-start">
      <h2 className="font-primary mb-[30px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:mb-[40px] md:text-[40px]">
        Student Information
      </h2>
      {isPending ? (
        <PendingLoader />
      ) : (
        <>
          <StudentInformation />
          <form onSubmit={handleSubmit(onSubmit)}>
            <StudentEnrollment
              register={register}
              errors={errors}
              hasUniversity={hasUniversity}
              enrollmentDetails={{inputUniversity,inputCampus,inputCourseDate}}
              enrollmentSet={{setInputUniversity, setInputCampus, setInputCourseDate}}
            />
            <StudentExperience register={register} errors={errors} setValue={setValue} 
              enrollmentDetails={{inputUniversity,inputCampus,inputCourseDate}} />
          </form>
        </>
      )}
    </section>
  );
};

export default RegisterInformationStudent;
