import { PayloadAction } from '@reduxjs/toolkit';
import api from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import { call, put } from 'redux-saga/effects';
import { setDynamicBlockContent } from './slice';
import { IDynamicBlock } from 'common/types';

export const SAGA_ACTIONS = {
  FETCH_DYNAMIC_BLOCK_CONTENT: 'FETCH_DYNAMIC_BLOCK_CONTENT',
};

type DynamicBlockContentPayload = {
  keys: string[];
};

export function* fetchDynamicBlockContent(
  action: PayloadAction<DynamicBlockContentPayload>,
) {
  const { keys } = action.payload;

  try {
    const { data } = yield call(() => api.get(`/frontend/contents`));

    const dynamicBlock = data.data.find((dynamicBlock: IDynamicBlock) => {
      if (
        dynamicBlock.reactPage === 'personal-details' &&
        keys[0] === 'account-details'
      ) {
        return dynamicBlock;
      }

      if (dynamicBlock.reactPage === keys[0]) {
        return dynamicBlock;
      }

      return null;
    });

    dynamicBlock.value = JSON.parse(dynamicBlock.value);

    yield put(setDynamicBlockContent(dynamicBlock));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}
