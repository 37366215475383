import React from 'react'
// import { ReactComponent as IconError } from 'assets/images/error.svg';
import { useEffectOnce } from 'react-use';
import useLogout from 'hooks/useLogout';
import LottieAnimation from 'components/LottieAnimation';
import errorLottie from 'assets/json/icon-error.json';

function CancelMembershipDone() {
  const { logout } = useLogout();

  useEffectOnce(()=>{
      setTimeout(() => {
        logout();
      }, 2000);
  })
  return (
    <article className="relative mt-[50px] mb-[100px] flex flex-col items-center gap-4 self-center px-[100px] md:mx-0 md:mt-[100px]">
      <LottieAnimation lottieFile={errorLottie} isStopped={false} />
      <h1 className="title mb-[30px] text-center text-[20px] font-bold md:text-[34px]">
        Your account has been cancelled
      </h1>
      <p className="mb-[50px]">You will now be logged out of the system.</p>
      <button
        className={`button btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize md:!h-[60px]`}
      >
        Ok
      </button>
    </article>
  );
}

export default CancelMembershipDone