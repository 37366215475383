import React from "react";
import Lottie from 'react-lottie';
import animationData from 'assets/json/icon-dont-forget.json';

interface Props {
  page: number;
}

const Page3 = ( { page } : Props ) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="flex flex-col">
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isPaused={page !== 3}
      />
      <div>
        <div className="mt-30px flex h-[195px] flex-col gap-[15px]">
          <p className="font-primary text-center text-[28px] font-[900] uppercase leading-[29px] text-primary">
            Don&apos;t forget!
          </p>
          <p className="text-[14px] leading-[20px] text-black">
            At the end of your trip, park your Flexicar back in its designated
            bay with at least 1/4 tank of fuel and don&apos;t forget to check
            for all your belongings.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Page3;