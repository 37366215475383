import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { Helmet } from 'react-helmet';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';

import { getToken } from 'common/helpers';
import { IPagesContentsCategories } from 'common/types';
import { ApprovedAuthenticatedTopNav } from 'components/TopNav';
import { selectEnvType, selectPagesContentsCategories, setPagesContentsCategories } from 'modules/public/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PageLayout from 'components/layouts/PageLayout';
import HelpfulVideosPage from 'features/HelpAndSupportPages/HelpfulVideosPage';
import ByCategory from 'features/HelpAndSupportPages/ByCategory';
import ContactUs from 'features/HelpAndSupportPages/ContactUs';
import TopFive from 'features/HelpAndSupportPages/TopFive';
import HowTo from 'features/HelpAndSupportPages/HowTo';
import Main from 'features/HelpAndSupportPages/Main';
import { selectUser } from 'modules/me/slice';

const HelpAndSupport = () => {
  const dispatch = useAppDispatch();
  const pagesContentsCategories = useAppSelector(selectPagesContentsCategories);
  const envType = useAppSelector(selectEnvType);
  const user = useAppSelector(selectUser);
  const token = getToken();

  useEffect(() => {
    if (token && !user) {
      dispatch({ type: ACTIONS.FETCH_ME });
    }
  }, [user, token])

  const getPagesContentsCategories = async () => {
    const db = getFirestore(firebaseApp);
    const collectionName = envType === 'staging' ? 'pagesContentsCategoriesStaging' : 'pagesContentsCategories';
    const collectionRef = collection(db, collectionName);

    const querySnapshot = await getDocs(collectionRef);

    const data: IPagesContentsCategories[] = [];

    querySnapshot.forEach(function (doc) {
      const parsedData = JSON.parse(JSON.stringify(doc.data()));
      data.push({
        id: Number(doc.id),
        icon: parsedData.icon,
        title: parsedData.title
      });
    });

    if (data.length > 0) {
      dispatch(setPagesContentsCategories(data));
    } else {
      dispatch(setPagesContentsCategories(null));
    }
  };

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.ENV_TYPE })
  });
  
  useEffect(() => {
    if (pagesContentsCategories === null && envType !== null) {
      getPagesContentsCategories();
    }
  }, [envType])
  

  return (
    <PageLayout>
      <Helmet>
        <title>Help & Support</title>
        <meta name="description" content="Flexicar - Help & Support" />
      </Helmet>
      <article className="">
        { token ? <ApprovedAuthenticatedTopNav /> : <></> }
        <Switch>
          <Route exact path="/faq/main">
            <PageLayout>
              <Main />
            </PageLayout>
          </Route>
          <Route exact path="/faq/top-five-questions">
            <PageLayout>
              <TopFive />
            </PageLayout>
          </Route>
          <Route exact path="/faq/helpful-videos">
            <PageLayout>
              <HelpfulVideosPage />
            </PageLayout>
          </Route>
          <Route exact path="/faq/category">
            <PageLayout>
              <ByCategory />
            </PageLayout>
          </Route>
          <Route exact path="/faq/how-to-use-app">
            <PageLayout>
              <HowTo />
            </PageLayout>
          </Route>
          <Route exact path="/faq/contact-us">
            <PageLayout>
              <ContactUs />
            </PageLayout>
          </Route>
        </Switch>
      </article>
    </PageLayout>
  );
};

export default HelpAndSupport;
