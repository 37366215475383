import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import useAppDispatch from "hooks/useAppDispatch";
import { ReactComponent as CloseIcon } from 'assets/images/close_modal.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/error_orange.svg';
import useAppSelector from "hooks/useAppSelector";
import { selectAnnouncement, selectAnnouncementStatus } from "modules/announcement/slice";
import ACTIONS from "modules/rootActions";
import classNames from "classnames";
import { RequestStatusEnum } from "common/types";

const BroadcastSystem = () => {
  const dispatch = useAppDispatch();
  const announcement = useAppSelector(selectAnnouncement);
  const announcementStatus = useAppSelector(selectAnnouncementStatus);
  const [openAnnouncement, setOpenAnnouncement] = useState(true);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_ANNOUNCEMENT,
    })
  });

  return (
    <section id="broadcastSystemSection" className={classNames("w-full h-auto bg-[#282828] text-white relative",
      {
        'hidden': !openAnnouncement || announcementStatus !== RequestStatusEnum.SUCCESS || announcement === null,
      }
    )}>
      <p className="m-auto pl-[38px] pr-[61px] py-[16px] md:py-[12px] text-[10px] leading-[16px] md:max-w-[90vw] text-left md:text-center">
        <ErrorIcon className="h-[14px] w-auto hidden md:inline pr-[8px] fill-[#FF7800]" />
        {announcement?.title &&
          <span className="font-bold capitalize">{announcement.title.toLowerCase()}:&nbsp;</span>
        }
        {announcement?.description &&
          <span>{announcement.description}</span>
        }
      </p>
      <ErrorIcon className="absolute block md:hidden left-[16px] top-[16.5px] h-[14px] w-auto" />
      {(announcement?.isDismissible === 1) &&
        <CloseIcon className="absolute right-[18px] md:right-[26px] top-[18px] md:top-[50%] md:translate-y-[-50%] h-[10px] w-[10px]" onClick={() => setOpenAnnouncement(false)}/>
      }
    </section>
  )
}

export default BroadcastSystem;