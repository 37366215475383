import { IPaymentCard } from 'common/types';
import { selectPaymentCards } from 'modules/me/slice';
import useAppSelector from './useAppSelector';

const usePaymentMethod = () => {
  const cards = useAppSelector(selectPaymentCards);
  const defaultPaymentCard = cards.find((card) => card.isDefault);
  if(defaultPaymentCard !== undefined) {
    const defaultPaymentCardIndex = cards.findIndex(
      (card) => card.isDefault === 1,
    );
    let sortedCards = [...cards];
  
    if (cards.length > 1) {
      sortedCards?.splice(defaultPaymentCardIndex, 1);
      sortedCards = [defaultPaymentCard as IPaymentCard, ...sortedCards];
    }
  
    const sortedPaymentCards = cards.length > 1 ? sortedCards : cards;
  
    return { paymentCards: sortedPaymentCards, defaultPaymentCard };
  } else {
    return { paymentCards: cards }
  }
};

export default usePaymentMethod;
