import React from 'react';
import { useHistory } from 'react-router-dom';

export const SignUpButton = () => {
  const { push } = useHistory()

  return (
    <>
      <div className="">
        <button
          onClick={() => push("/register-v2?reload=true")}
          className="!text-[14px] h-[40px] min-w-[100px] rounded-full bg-flexi-green-1 font-bold text-white"
        >
          Join Now
        </button>
      </div>
    </>
  )
}

export default SignUpButton;