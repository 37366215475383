import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment-timezone';
import classNames from 'classnames';

import carIconAvailable from 'assets/images/map-marker.svg';
import { GOOGLE_API, IMAGE_URL } from 'common/constants';
import { IVehicleSummary, RequestStatusEnum } from 'common/types';
import { mapStyles } from 'features/Booking/BookingMap/mapStyles';
import { selectUserPlan } from 'modules/me/slice';
import {
  selectBookingSummaryModify,
  selectBookingSummaryModifyStatus,
} from 'modules/booking/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import LoaderText from 'components/LoaderText';
import PendingLoader from 'components/PendingLoader';
import vehiclePlaceholder from 'assets/images/vehicle-placeholder.svg';

interface MyComponentProps {
  vehicle: IVehicleSummary;
}

const BookingInfo = () => {
  const dispatch = useAppDispatch();
  const { isBusinessManagerAccount } = useBusiness();
  const userPlan = useAppSelector(selectUserPlan);
  const bookingSummary = useAppSelector(selectBookingSummaryModify);
  const detailsPending = useAppSelector(selectBookingSummaryModifyStatus);
  const isDetailsPending = detailsPending === RequestStatusEnum.PENDING;
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [libraries] = useState<any>(['places']);
  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API,
    libraries,
  });

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: false,
    gestureHandling: 'none',
  };

  const getVehicleType = () => bookingSummary?.vehicle.group.category?.name;

  const getVehicleCapacity = () => bookingSummary?.vehicle?.seats;

  const getFuel = () => bookingSummary?.vehicle?.fuelType;

  const getFuelPin = () => bookingSummary?.vehicle?.fuelCardPincode;

  useEffect(() => {
    if (bookingSummary) {
      setCenter({
        lat: parseFloat(bookingSummary.vehicle.podLatitude),
        lng: parseFloat(bookingSummary.vehicle.podLongitude),
      });
    }
  }, [bookingSummary]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_USER_PLAN });
  });

  const getAddOns = () => {
    const addOns = bookingSummary?.addons;
    const mappedAddons = addOns?.map((addon: string) =>
      addon.split('-').join(' '),
    );
    return mappedAddons;
  };

  const getPromoCode = () => {
    if (bookingSummary?.breakdowns.promo)
      return bookingSummary?.breakdowns.promo?.code;
    else return '-';
  };

  const getBookingReference = () => {
    if (bookingSummary?.bookingReference)
      return bookingSummary?.bookingReference;
    else return '-';
  };

  const VehicleImage: React.FC<MyComponentProps> = ({ vehicle }) => {
    const [vehicleImageError, setVehicleImageError] = React.useState(false);
    const [groupImageError, setGroupImageError] = React.useState(false);

    return (
      <div className="ml-auto h-[170px] w-[180px] md:w-[255px]">
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': !vehicleImageError,
          })}
          src={vehicle.vehicleImageUrl !== null ? vehicle.vehicleImageUrl : ''}
          alt={vehicle.name}
          onError={() => setVehicleImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && !groupImageError,
          })}
          src={
            vehicle.group.vehicleImage !== null
              ? vehicle.group.vehicleImage
              : ''
          }
          alt={vehicle.group.make}
          onError={() => setGroupImageError(true)}
        />
        <img
          className={classNames('hidden h-full w-full object-contain', {
            '!block': vehicleImageError && groupImageError,
          })}
          src={`${IMAGE_URL}${vehiclePlaceholder}`}
          alt="vehicle default image"
        />
      </div>
    );
  };

  return (
    <section className="w-full lg:w-[500px]">
      <section className="flex w-full flex-col items-start">
        {isDetailsPending ? (
          <LoaderText height="170" width="250px" otherClass="mb-[50px]" />
        ) : (
          <div className="mb-[40px] flex w-full justify-between md:mb-[50px] ">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col">
                <p className="mr-[20px] text-left text-[20px] font-bold md:text-[26px]">
                  {bookingSummary?.vehicle?.name}
                </p>

                <p className="flex items-center gap-2 whitespace-nowrap fill-zinc-700 text-[16px] md:text-[16px]">
                  {bookingSummary?.vehicle?.group.make},{' '}
                  {bookingSummary?.vehicle?.group.model}
                </p>

                {bookingSummary && (
                  <p
                    className={classNames(
                      'mt-[8px] w-fit rounded-[13px] bg-[#D2D2D2] px-[8px] py-[4px] text-[12px] capitalize text-flexi-black-1',
                      {
                        '!bg-[#FFD597]':
                          moment().tz(bookingSummary.podTimeZone) >
                            moment(bookingSummary.endsAt).tz(
                              bookingSummary.podTimeZone,
                            ) &&
                          bookingSummary.tripStatus.toLocaleLowerCase() ===
                            'ongoing',
                        '!bg-[#A2D8CD]':
                          bookingSummary.tripStatus.toLocaleLowerCase() ===
                            'booked' ||
                          bookingSummary.tripStatus.toLocaleLowerCase() ===
                            'completed',
                        '!bg-[#F7B9B9]':
                          bookingSummary.tripStatus.toLocaleLowerCase() ===
                          'cancelled',
                        '!bg-[#D2D2D2]':
                          bookingSummary.tripStatus.toLocaleLowerCase() ===
                          'no show',
                      },
                    )}
                  >
                    {bookingSummary.tripStatus.toLocaleLowerCase() ===
                      'ongoing' &&
                    moment().tz(bookingSummary.podTimeZone) >
                      moment(bookingSummary.endsAt).tz(
                        bookingSummary.podTimeZone,
                      )
                      ? 'Extended'
                      : bookingSummary.tripStatus}
                  </p>
                )}
              </div>
            </div>
            <div className="h-full w-full object-contain">
              {bookingSummary?.vehicle && (
                <VehicleImage vehicle={bookingSummary?.vehicle} />
              )}
            </div>
          </div>
        )}

        <div className="mb-10 w-full">
          <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[20px] md:text-[22px]">
            Car information
          </p>

          <div className="grid grid-cols-2 gap-y-[25px] text-[16px] md:text-[16px] [&>p]:border-b-[1px] [&>p]:border-dotted">
            <p>Number of passengers</p>

            {isDetailsPending ? (
              <LoaderText
                height="25"
                width="35%"
                otherClass="justify-self-end"
                parentClass="border-b-[1px] border-dotted justify-end"
              />
            ) : (
              <p className="text-end font-bold capitalize">
                {getVehicleCapacity()}
              </p>
            )}
            <p>Fuel</p>

            {isDetailsPending ? (
              <LoaderText
                height="25"
                width="50%"
                otherClass="justify-self-end"
                parentClass="border-b-[1px] border-dotted justify-end"
              />
            ) : (
              <p className="text-end font-bold capitalize">{getFuel()}</p>
            )}
            <p>Type</p>

            {isDetailsPending ? (
              <LoaderText
                height="25"
                width="100%"
                otherClass="justify-self-end"
                parentClass="border-b-[1px] border-dotted justify-end"
              />
            ) : (
              <p className="text-end font-bold capitalize">
                {getVehicleType()}
              </p>
            )}
            <p>Fuel Pin</p>

            {isDetailsPending ? (
              <LoaderText
                height="25"
                width="100%"
                otherClass="justify-self-end"
                parentClass="border-b-[1px] border-dotted justify-end"
              />
            ) : (
              <p className="text-end font-bold capitalize">
                {getFuelPin() === '' || !getFuelPin() ? '-' : getFuelPin()}
              </p>
            )}
          </div>
        </div>

        <div className="mb-[20px] flex w-full flex-col gap-5 ">
          <div className="flex flex-col">
            <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[20px] md:text-[22px]">
              Booking Details
            </p>
            <div className="grid grid-cols-2 gap-y-[25px] text-[16px] md:text-[16px] [&>p]:border-b-[1px] [&>p]:border-dotted">
              <p>Booking #</p>
              {isDetailsPending ? (
                <LoaderText
                  height="25"
                  width="50%"
                  otherClass="justify-self-end"
                  parentClass="border-b-[1px] border-dotted justify-end"
                />
              ) : (
                <p className="text-end font-bold">
                  {bookingSummary?.referenceNumber}
                </p>
              )}
              {bookingSummary && (
                <>
                  <p>{bookingSummary.actualStartsAt && 'Actual '}Pick-up</p>

                  {bookingSummary.actualStartsAt && !isDetailsPending ? (
                    <p className="whitespace-nowrap text-right text-[14px] font-bold md:text-[16px]">
                      {moment(bookingSummary.actualStartsAt)
                        .tz(bookingSummary.podTimeZone)
                        .format('DD MMM | h:mm a')}
                      <span className="text-[10px]">
                        &nbsp;&nbsp;&#x28;
                        {moment(bookingSummary.actualStartsAt)
                          .tz(bookingSummary.podTimeZone)
                          .format('z')}
                        &#x29;
                      </span>
                    </p>
                  ) : !bookingSummary.actualStartsAt && !isDetailsPending ? (
                    <p className="whitespace-nowrap text-right text-[14px] font-bold md:text-[16px]">
                      {moment(bookingSummary.startsAt)
                        .tz(bookingSummary.podTimeZone)
                        .format('DD MMM | h:mm a')}
                      <span className="text-[10px]">
                        &nbsp;&nbsp;&#x28;
                        {moment(bookingSummary.startsAt)
                          .tz(bookingSummary.podTimeZone)
                          .format('z')}
                        &#x29;
                      </span>
                    </p>
                  ) : (
                    <LoaderText
                      height="25"
                      width="100%"
                      otherClass="justify-self-end"
                      parentClass="border-b-[1px] border-dotted justify-end"
                    />
                  )}

                  <p>{bookingSummary.actualEndsAt && 'Actual '}Drop-off</p>

                  {bookingSummary.actualEndsAt && !isDetailsPending ? (
                    <p className="whitespace-nowrap text-right text-[14px] font-bold md:text-[16px]">
                      {moment(bookingSummary.actualEndsAt)
                        .tz(bookingSummary.podTimeZone)
                        .format('DD MMM | h:mm a')}
                      <span className="text-[10px]">
                        &nbsp;&nbsp;&#x28;
                        {moment(bookingSummary.actualEndsAt)
                          .tz(bookingSummary.podTimeZone)
                          .format('z')}
                        &#x29;
                      </span>
                    </p>
                  ) : !bookingSummary.actualEndsAt && !isDetailsPending ? (
                    <p className="whitespace-nowrap text-right text-[14px] font-bold md:text-[16px]">
                      {moment(bookingSummary.endsAt)
                        .tz(bookingSummary.podTimeZone)
                        .format('DD MMM | h:mm a')}
                      <span className="text-[10px]">
                        &nbsp;&nbsp;&#x28;
                        {moment(bookingSummary.endsAt)
                          .tz(bookingSummary.podTimeZone)
                          .format('z')}
                        &#x29;
                      </span>
                    </p>
                  ) : (
                    <LoaderText
                      height="25"
                      width="100%"
                      otherClass="justify-self-end"
                      parentClass="border-b-[1px] border-dotted justify-end"
                    />
                  )}
                </>
              )}
              <p className=" border-b-transparent">Car location</p>

              {isDetailsPending ? (
                <LoaderText
                  height="25"
                  width="75%"
                  otherClass="justify-self-end"
                  parentClass="justify-end"
                />
              ) : (
                <p className="border-b-transparent text-end font-bold">
                  {bookingSummary?.vehicle.podName}
                </p>
              )}

              {isDetailsPending ? (
                <LoaderText
                  height="25"
                  width="100%"
                  otherClass="justify-self-end"
                />
              ) : (
                <p className="col-span-2 border-b-transparent text-[14px] md:text-[14px]">
                  {bookingSummary?.vehicle.podDescription}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="h-[197px] w-full bg-zinc-200 md:h-[300px] lg:w-[500px]">
          {mapLoadError ? (
            <section>Map cannot be loaded right now, sorry.</section>
          ) : isMapLoaded ? (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={15}
              center={center}
              options={options}
              clickableIcons={false}
            >
              <OverlayView
                position={center}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div className="relative grid h-[45px] w-[45px] place-items-center">
                  <img
                    src={`${IMAGE_URL}${carIconAvailable}`}
                    className="h-[45px] w-[45px]"
                    alt=""
                  />
                </div>
              </OverlayView>
            </GoogleMap>
          ) : (
            <PendingLoader />
          )}
        </div>

        {isBusinessManagerAccount && (
          <div className="mt-[40px] w-full md:mt-[50px]">
            <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[20px] md:text-[22px]">
              Driver Details
            </p>
            <div className="grid grid-cols-2 gap-y-[25px] text-[16px] md:text-[16px] [&>p]:border-b-[1px] [&>p]:border-dotted">
              <p>Name</p>
              <p className="text-end font-bold capitalize">
                {bookingSummary?.driver.name}
              </p>
              <p>Cost Centre or Department</p>
              {bookingSummary?.costCentre ? (
                <p className="text-end font-bold capitalize">
                  {bookingSummary?.costCentre}
                </p>
              ) : (
                <p className="text-end font-bold">-</p>
              )}
              <p>Booking Reference</p>
              <p className="text-end font-bold">{getBookingReference()}</p>
            </div>
          </div>
        )}

        <div className="mt-[40px] w-full md:mt-[50px]">
          <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[20px] md:text-[22px]">
            Inclusions / Exclusions
          </p>
          <div className="grid grid-cols-2 gap-y-[25px] text-[16px] md:text-[16px] [&>p]:border-b-[1px] [&>p]:border-dotted">
            <p>Your plan</p>
            <p className="text-end font-bold capitalize">
              flexi{' '}
              {userPlan?.name.toLocaleLowerCase() === 'personal'
                ? userPlan?.plan?.name
                : userPlan?.name}
            </p>
            <p>Add-ons</p>
            {bookingSummary?.addons ? (
              <p className="text-end font-bold capitalize">{getAddOns()}</p>
            ) : (
              <p className="text-end font-bold">No add-ons</p>
            )}
            <p>Promo Code</p>
            <p className="text-end font-bold">{getPromoCode()}</p>
            {!isBusinessManagerAccount && (
              <>
                <p>Booking Reference</p>
                <p className="text-end font-bold">{getBookingReference()}</p>
              </>
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default BookingInfo;
