import { takeLatest } from 'redux-saga/effects';
import {
  downloadInvoice,
  fetchOutstandingBalances,
  payOutstandingBalance,
  paySelectedInvoice,
  paySingleInvoice,
  SAGA_ACTIONS,
} from './sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.FETCH_OUSTANDING_BALANCES, fetchOutstandingBalances),
  takeLatest(SAGA_ACTIONS.PAY_OUTSTANDING_BALANCE, payOutstandingBalance),
  takeLatest(SAGA_ACTIONS.PAY_SINGLE_INVOICE, paySingleInvoice),
  takeLatest(SAGA_ACTIONS.PAY_SELECTED_INVOICE, paySelectedInvoice),
  takeLatest(SAGA_ACTIONS.DOWNLOAD_INVOICE, downloadInvoice),
];

export default actions;
