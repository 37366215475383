import React, { Dispatch, useEffect } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { RequestStatusEnum } from 'common/types';

interface Props {
  status: RequestStatusEnum | null;
  currentPageData: number;
  currentPage: number;
  from: number;
  hideLimit: boolean;
  itemsPerPage: number;
  to: number;
  totalItems: number;
  totalPages: number;
  onPageChange: (itemsPerPage: number, page: number) => void;
  setCurrentPage: Dispatch<number>;
  setItemsPerPage: Dispatch<number>;
}

const Pagination = ({
  status,
  currentPageData,
  currentPage,
  from,
  hideLimit,
  itemsPerPage,
  to,
  totalPages,
  totalItems,
  onPageChange,
  setItemsPerPage,
  setCurrentPage,
}: Props) => {
  const handleChange = (itemsPerPage: number, page: number) => {
    onPageChange(itemsPerPage, page);
  };

  useEffect(() => {
    setCurrentPage(currentPageData || 1);
  }, [currentPageData]);

  return (
    <div className="mt-4 flex items-center justify-between text-[12px] md:text-[16px]">
      {hideLimit ? (
        <>
          {status === RequestStatusEnum.PENDING && (
            <div className="flex items-center gap-4">
              <AiOutlineLoading3Quarters className="ml-4 h-[20px] w-[20px] animate-spin fill-flexi-orange-1" />
              Loading data...
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center">
          <span className="mr-2">Rows per page:</span>
          <select
            className="rounded-lg border px-3 py-1 text-center"
            value={itemsPerPage}
            onChange={(e: any) => {
              setItemsPerPage(parseInt(e.target.value));
              onPageChange(parseInt(e.target.value), currentPage);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={30}>30</option>
          </select>

          <span className="ml-4">{`${from}-${to} of ${totalItems}`}</span>
          {status === RequestStatusEnum.PENDING && (
            <div className="flex items-center gap-4">
              <AiOutlineLoading3Quarters className="ml-4 h-[20px] w-[20px] animate-spin fill-flexi-orange-1" />
              Loading data...
            </div>
          )}
        </div>
      )}
      <div className="ml-auto flex items-center gap-[25px] text-[24px] md:text-[30px]">
        <button
          className="disabled:text-[#c0c0c0]"
          onClick={() => handleChange(itemsPerPage, 1)}
          disabled={currentPage === 1}
        >
          &#171;
        </button>
        <button
          className="disabled:text-[#c0c0c0]"
          onClick={() => handleChange(itemsPerPage, currentPage - 1)}
          disabled={currentPage === 1}
        >
          &#8249;
        </button>
        <button
          className="disabled:text-[#c0c0c0]"
          onClick={() => handleChange(itemsPerPage, currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &#8250;
        </button>
        <button
          className="disabled:text-[#c0c0c0]"
          onClick={() => handleChange(itemsPerPage, totalPages)}
          disabled={currentPage === totalPages}
        >
          &#187;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
