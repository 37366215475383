import useAppSelector from 'hooks/useAppSelector';
import {
  selectPending,
  selectUser,
  selectUserInfo,
  selectUserPhoto,
} from 'modules/me/slice';
import React, { useRef, useLayoutEffect } from 'react';
import MyAccountNav from 'components/MyAccountNav';
import { PageTitle } from 'components/Typography';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { ReactComponent as IconCamera } from 'assets/images/camera.svg';
import { ReactComponent as IconEdit } from 'assets/images/edit.svg';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import classNames from 'classnames';
import COUNTRIES from 'common/countries';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';
import MyAccountBlock from 'components/MyAccountBlock';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { useLocation } from 'react-router-dom';

const MyAccountBusinessDetails = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isPending = useAppSelector(selectPending);
  const photo = useAppSelector(selectUserPhoto);
  const userInfo = useAppSelector(selectUserInfo);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const { pathname } = useLocation();
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const renderPending = ({ width }: { width: string }) => {
    return (
      <div className="flex-1 animate-pulse">
        <div className={`h-[25px] ${width} rounded bg-slate-100`} />
      </div>
    );
  };

  const handleUploadPhoto = () => {
    const formData = new FormData();
    formData.append('photo', acceptedFiles[0]);

    dispatch({ type: ACTIONS.UPLOAD_PHOTO, payload: formData });
  };

  const getCountryName = () => {
    const country = COUNTRIES.find(
      (country) =>
        userInfo?.country?.toLowerCase() === country.code.toLowerCase(),
    );
    return country?.name;
  };

  useUpdateEffect(() => {
    handleUploadPhoto();
  }, [acceptedFiles]);

  useEffectOnce(() => {
    if (photo !== undefined) dispatch({ type: ACTIONS.GET_PHOTO });
    dispatch({ type: ACTIONS.FETCH_USER_INFO });
  });

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  return (
    <section
      ref={component}
      className="fadein relative mx-[8vw] mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Account - Business Details</title>
        <meta
          name="description"
          content="My Flexicar Account - Business Details"
        />
      </Helmet>
      <MyAccountNav title="account details" />
      <div
        className="w-full justify-between gap-x-[100px] lg:flex"
        // style={{gridTemplateColumns:'1fr 1fr'}}
      >
        <article className="flex w-[84vw] flex-col lg:w-[500px]">
          <PageTitle>Account Details</PageTitle>
          <div className="mx-auto mb-[30px] mt-[30px] w-full items-center gap-[45px] border-t-[1px] pt-[50px] md:mx-0 md:flex">
            <div {...getRootProps()}>
              <input {...getInputProps()} className="hidden" />
              <div
                className={classNames(
                  'relative mx-auto mb-[25px] grid h-[120px] w-[120px] place-items-center rounded-full bg-flexi-black-5 text-[60px] text-flexi-black-4 md:mx-0 md:mb-[25px]',
                  {
                    '!bg-transparent shadow-[0_0_0_1px] shadow-flexi-orange-1':
                      photo,
                  },
                )}
                onClick={open}
                role="button"
              >
                {photo ? (
                  <img
                    src={photo}
                    className="block h-[120px] w-[120px] rounded-full object-cover"
                  />
                ) : (
                  user?.fullname[0]
                )}
                <div className="absolute bottom-0 right-0 grid h-[35px] w-[35px] cursor-pointer place-items-center rounded-full bg-flexi-orange-1">
                  {photo ? (
                    <IconEdit className="h-[16px] w-[16px] fill-white" />
                  ) : (
                    <IconCamera className="h-[16px] w-[16px]" />
                  )}
                </div>
              </div>
            </div>

            <div>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="mb-[10px] flex-auto text-[26px] font-bold leading-[31px]">
                  {user?.organisation?.name}
                </p>
              )}

              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto text-[16px]">
                  Joined {moment(user?.registeredAt).format('MMMM YYYY')}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-y-[20px]">
            <div className="flex">
              <p className="w-[200px] flex-none font-semibold">ABN or ACN</p>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto">{userInfo?.code}</p>
              )}
            </div>
            <div className="flex">
              <p className="w-[200px] flex-none font-semibold">
                Business Address
              </p>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto">{userInfo?.address}</p>
              )}
            </div>
            <div className="flex">
              <p className="w-[200px] flex-none font-semibold">City/Suburb</p>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto">{userInfo?.city}</p>
              )}
            </div>
            <div className="flex">
              <p className="w-[200px] flex-none font-semibold">Postcode</p>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto">{userInfo?.zip}</p>
              )}
            </div>
            <div className="flex">
              <p className="w-[200px] flex-none font-semibold">Country</p>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto">{getCountryName()}</p>
              )}
            </div>
            <div className="flex">
              <p className="w-[200px] flex-none font-semibold">State</p>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto">{userInfo?.state}</p>
              )}
            </div>
          </div>
        </article>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>
    </section>
  );
};

export default MyAccountBusinessDetails;
