import React, {
  useEffect,
  useLayoutEffect,
  Dispatch,
  useState,
  Suspense,
} from 'react';

import { toast } from 'react-toastify';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import haversine from 'haversine-distance';
import classNames from 'classnames';
import * as _ from 'lodash';
import gsap from 'gsap';

import CloseIcon from 'assets/images/close-white.svg';
import { ReactComponent as Arrow } from 'assets/images/arrow-down.svg';
import { DEFAULT_LNG_LAT, IMAGE_URL } from 'common/constants';
import { getPreviousUrl, getToken } from 'common/helpers';
import { IPodAll, IPodVehicleBasic, RequestStatusEnum } from 'common/types';
import { selectVehicleAvailabilityStatus } from 'modules/vehicle/slice';
import { selectUser } from 'modules/me/slice';
import {
  selectSelectedLocationCenter,
  selectUserCenter,
  setMapView,
} from 'modules/booking/slice';
import {
  selectFirstVisible,
  selectInitialPending,
  selectVehiclesInPodStatus,
  setSelectedPod2,
  setSelectedPodVehicle2,
  selectPodTimezone,
  selectMapAutoZoomEnd,
  setVisiblePodsId2,
  selectCurrentZoom,
} from 'modules/pods/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';
import CustomCheckBox from 'components/CustomCheckbox';
import LazyVehicleList from './LazyVehicleList';
import Header from '../BookingTimeline/Header';

interface Props {
  setIsPendingAccount: Dispatch<boolean>;
}

const BookingVehicleList = ({ setIsPendingAccount }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const {
    selectedPodVehicle2,
    vehiclesInPod,
    vehiclesAvailability,
    podsAll,
    startDate,
    showOnlyAvailableVehicle,
    handleShowOnlyAvailableVehicle,
    isMapView,
    pendingVehicleList,
  } = useBooking({});
  const previousUrl = getPreviousUrl();
  const timeZone = useAppSelector(selectPodTimezone);
  const selectedPodVehicleId2 = selectedPodVehicle2?.id;
  const userCenter = useAppSelector(selectUserCenter) || DEFAULT_LNG_LAT;
  const selectedLocationCenter = useAppSelector(selectSelectedLocationCenter);
  const vehicleCount = vehiclesInPod?.length || 0;
  const hasVehicles = vehicleCount > 0;
  const vehicleAvailabilityStatus = useAppSelector(selectVehicleAvailabilityStatus);
  const vehiclesInPodStatus = useAppSelector(selectVehiclesInPodStatus);
  const isPendingInitialPods = useAppSelector(selectInitialPending);
  const isPending = vehiclesInPodStatus === RequestStatusEnum.PENDING && !isPendingInitialPods;
  const firstVisible = useAppSelector(selectFirstVisible);
  const isAutoZoomDone = useAppSelector(selectMapAutoZoomEnd);
  const currentZoom = useAppSelector(selectCurrentZoom);
  const token = getToken();
  const [sortedVehicles, setSortedVehicles] = useState<IPodVehicleBasic[] | undefined>([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [viewTimeline, setViewTimeline] = useState(false);
  const [availableCount, setAvailableCount] = useState(0);

  const handleAvailabilityView = () => {
    if (token && user && vehiclesInPod && vehiclesInPod?.length > 0) {
      dispatch(setMapView(!isMapView));
    }
    if (!token && vehiclesInPod) {
      dispatch(setMapView(true));
    }

    if (vehiclesInPod && vehiclesInPod.length === 0)
      if (!isToastVisible) {
        if (vehiclesInPod) toast.info('Hold on while we search for vehicles.');
        else toast.error('No vehicles available within your search.');
        setIsToastVisible(true);
      }
  };

  const renderPendingLoader = () => {
    const pendingLoaderContent = [];
    const size = 10;
    for (let i = 1; i <= size; i += 1) {
      pendingLoaderContent.push(
        <div className="flex items-center gap-[10px]" key={i}>
          <div className={`h-[65px] w-[85%] bg-slate-100 lg:w-[70px]`} />
          <div className="flex flex-col gap-[10px]">
            <div className="h-[20px] w-[85%] bg-slate-100 lg:w-[90px]" />
            <div className="h-[10px] w-[85%] bg-slate-100 lg:w-[240px]" />
            <div className="h-[10px] w-[85%] bg-slate-100 lg:w-[50px]" />
          </div>
          <div className={`h-[30px] w-[80px] bg-slate-100`} />
        </div>,
      );
    }
    return (
      <div className="flex animate-pulse flex-col gap-[20px] py-[20px] px-[30px]">
        {pendingLoaderContent}
      </div>
    );
  };

  useEffect(() => {
    if (vehiclesInPod && vehiclesInPod.length < 1) {
      dispatch(setMapView(true));
    }
  }, [vehiclesInPod]);

  useEffect(() => {
    if (
      previousUrl !== '/app/booking/summary' &&
      vehiclesInPodStatus === RequestStatusEnum.SUCCESS &&
      vehiclesInPod &&
      vehiclesInPod?.length > 0
    ) {
      setSortedVehicles([]);
      const sorted = _.sortBy(vehiclesInPod, [
        function (o) {
          const podTarget = podsAll?.find((pod: IPodAll) => pod.id === o.podId);
          const podLatLng = {
            lat: Number(podTarget?.latitude),
            lng: Number(podTarget?.longitude),
          };
          const calculatedDistance = haversine(
            selectedLocationCenter === null ||
              selectedLocationCenter === undefined
              ? userCenter
              : selectedLocationCenter,
            podLatLng,
          );

          return calculatedDistance;
        },
      ]);
      if (sorted.length > 0) setSortedVehicles(sorted);
    }
  }, [
    vehiclesInPod,
    vehiclesInPodStatus,
    vehiclesAvailability,
    vehicleAvailabilityStatus,
    isAutoZoomDone,
  ]);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
    if (selectedPodVehicleId2 !== null) {
      const targetElem = document.querySelector('.selectedVehicle');
      if (targetElem !== null) {
        gsap.to('.vehicleListScroll', {
          scrollTo: targetElem,
          duration: 0.6,
          ease: 'Circ.easeOut',
        });
      }
    }
  }, [selectedPodVehicleId2, selectedPodVehicle2]);

  useEffect(() => {
    if (!isMapView && vehiclesInPod !== null) {
      setSelectedPodVehicle2(vehiclesInPod[0]);
    }
  }, [isMapView]);

  useEffect(() => {
    if (!isMapView) {
      const timeout = setTimeout(() => {
        setViewTimeline(true);
      }, 1200);
      return () => clearTimeout(timeout);
    }
    if (isMapView) {
      setViewTimeline(false);
    }
  }, [isMapView]);
  useEffect(() => {
    if (vehiclesInPod?.length === 0) {
      setSortedVehicles([]);
      dispatch(setVisiblePodsId2([]));
    }
  }, [vehiclesInPod]);

  useEffect(() => {
    if (showOnlyAvailableVehicle) {
      const availableOnly =
        document.getElementsByClassName('available-counter');
      setAvailableCount(availableOnly.length);
    } else {
      setAvailableCount(sortedVehicles?.length || 0);
    }
  }, [showOnlyAvailableVehicle, sortedVehicles]);

  return (
    <>
      {!isMapView &&
        (!viewTimeline ||
          vehicleAvailabilityStatus !== RequestStatusEnum.SUCCESS) && (
          <aside className="fixed left-[calc(50%+180px)] top-[40%] z-[999] flex h-[40px] w-[200px] items-center justify-center gap-[10px] rounded-[10px] bg-white shadow-lg !duration-500">
            <span className="text-[12px] font-[500]">
              Loading timeline data
            </span>
            <AiOutlineLoading3Quarters className="animate-spin fill-flexi-orange-1" />
          </aside>
        )}
      <div
        className={classNames(
          'opacity-1 fixed right-[40px] top-[168px] z-[999] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-primary shadow-[0_2px_4px_0_rgba(0,0,0,0.5)] delay-700 duration-700',
          {
            '!delay-0 !opacity-0 !duration-500': isMapView,
          },
        )}
        onClick={() => dispatch(setMapView(true))}
      >
        <img src={`${IMAGE_URL}${CloseIcon}`} />
      </div>
      <article
        className={classNames(
          'vehicle_list absolute left-0 top-0 z-[8] hidden h-full translate-x-[-100%] overflow-hidden bg-white pb-3 duration-500 lg:block lg:w-[470px]',
          {
            'translate-x-[0%]': firstVisible,
            '!w-full duration-700': !isMapView,
            '!hidden !translate-x-[-100%]': currentZoom && currentZoom < 14,
          },
        )}
      >
        {!isMapView && (
          <div className="absolute left-0 top-0 z-[-1] h-[calc(100vh-190px)] w-[470px] bg-white drop-shadow-lg"></div>
        )}
        <div
          className={classNames(
            'mx-[30px] flex h-[92px] max-w-[415px] justify-start border-b-[1px] border-b-[#282828] py-[35px]',
            {
              hidden: pendingVehicleList,
            },
          )}
        >
          <label className="label mr-auto cursor-pointer">
            <CustomCheckBox size={10}>
              <input
                type="checkbox"
                className="checkbox checkbox-xs h-[20px] w-[20px] rounded-md"
                checked={showOnlyAvailableVehicle}
                onChange={() => {
                  handleShowOnlyAvailableVehicle(!showOnlyAvailableVehicle);
                  dispatch(setSelectedPod2(null));
                  dispatch(setSelectedPodVehicle2(null));
                }}
                style={{
                  // @ts-ignore
                  '--chkfg': '0',
                  '--chkbg': '0',
                  backgroundColor: 'white',
                }}
              />
            </CustomCheckBox>
            <p className="overflow-hidden text-ellipsis text-[10px] capitalize leading-[16px] sm:text-[14px]">
              show available cars only
              <br className="h-[20px]" />
              <span className="overflow-hidden text-ellipsis text-[10px] leading-[12px] text-[#969696] sm:text-[12px]">
                {showOnlyAvailableVehicle ? (
                  <>Showing {availableCount} results</>
                ) : (
                  <>
                    Showing {hasVehicles ? sortedVehicles?.length : 0} results
                  </>
                )}
              </span>
              {}
            </p>
          </label>
          <div
            className={classNames(
              'absolute left-[340px] flex cursor-pointer flex-row gap-[12px]',
              {
                'pointer-events-none cursor-not-allowed':
                  !hasVehicles && isMapView,
                hidden: !user,
              },
            )}
            onClick={handleAvailabilityView}
          >
            {isMapView ? (
              <div className="flex gap-[12px]">
                <p
                  className={classNames('text-[14px] text-primary', {
                    '!text-flexi-black-4': !hasVehicles,
                  })}
                >
                  Availability
                </p>
                <Arrow
                  className={classNames(
                    'h-auto w-[20px] -rotate-90 fill-primary',
                    {
                      '!fill-flexi-black-4': !hasVehicles,
                    },
                  )}
                />
              </div>
            ) : (
              <div className="flex gap-[12px] pl-[40px]">
                <Arrow className="h-auto w-[20px] rotate-90 fill-primary" />
                <p className="text-[14px] text-primary">Map</p>
              </div>
            )}
          </div>
        </div>
        <div className="pointer-events-none absolute top-[0px] z-[-8888] bg-transparent">
          <Header />
        </div>
        {isPending ? (
          renderPendingLoader()
        ) : (
          <>
            {!pendingVehicleList && startDate && timeZone ? (
              <Suspense
                fallback={
                  <div className="!w-[470px] pt-[35px]">
                    <aside
                      className={classNames(
                        'mx-auto mb-[35px] flex h-[40px] w-fit items-center justify-center gap-[10px] rounded-[10px] bg-white px-[10px] shadow-lg !duration-500',
                      )}
                    >
                      <span className="px-[10px] text-[12px] font-[500]">
                        Searching Vehicles
                      </span>
                      <AiOutlineLoading3Quarters className="animate-spin fill-flexi-orange-1" />
                    </aside>
                    {Array.from({ length: 5 }, (_, i) => {
                      return (
                        <div
                          key={i}
                          className="!my-[25px] mx-auto flex h-[85px] w-fit flex-row items-center gap-[15px]"
                        >
                          <div className="h-[85px] min-w-[90px] bg-[#F2F2F2]" />
                          <div className="items-left flex h-full w-auto flex-col justify-between px-0 py-0">
                            <div className="flex flex-col gap-[5px]">
                              <div
                                className={`mb-[2px] h-[26px] w-[115px] animate-pulse bg-[#F2F2F2]`}
                              />
                              <div
                                className={`mb-[2px] h-[13px] w-[180px] animate-pulse bg-[#F2F2F2]`}
                              />
                              <div
                                className={`mb-[2px] h-[13px] w-[65px] animate-pulse bg-[#F2F2F2]`}
                              />
                            </div>
                          </div>
                          <div
                            className={`h-[40px] w-[102px] animate-pulse bg-[#F2F2F2]`}
                          />
                        </div>
                      );
                    })}
                  </div>
                }
              >
                {sortedVehicles && (
                  <LazyVehicleList
                    sortedVehicles={sortedVehicles}
                    timeZone={timeZone}
                    viewTimeline={viewTimeline}
                    setIsPendingAccount={setIsPendingAccount}
                  />
                )}
              </Suspense>
            ) : !pendingVehicleList && sortedVehicles?.length === 0 ? (
              <div className="px-[25px] py-10 sm:pr-[125px]">
                <p className="text text-[14px] font-bold sm:text-lg">
                  Sorry, There&apos;s no available vehicle within your search.
                </p>
              </div>
            ) : (
              <div className="!w-[470px] pt-[35px]">
                {Array.from({ length: 5 }, (_, i) => {
                  return (
                    <div
                      key={i}
                      className="!my-[25px] mx-auto flex h-[85px] w-fit flex-row items-center gap-[15px]"
                    >
                      <div className="h-[85px] min-w-[90px] bg-[#F2F2F2]" />
                      <div className="items-left flex h-full w-auto flex-col justify-between px-0 py-0">
                        <div className="flex flex-col gap-[5px]">
                          <div
                            className={`mb-[2px] h-[26px] w-[115px] animate-pulse bg-[#F2F2F2]`}
                          />
                          <div
                            className={`mb-[2px] h-[13px] w-[180px] animate-pulse bg-[#F2F2F2]`}
                          />
                          <div
                            className={`mb-[2px] h-[13px] w-[65px] animate-pulse bg-[#F2F2F2]`}
                          />
                        </div>
                      </div>
                      <div
                        className={`h-[40px] w-[102px] animate-pulse bg-[#F2F2F2]`}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </article>
    </>
  );
};

export default BookingVehicleList;
