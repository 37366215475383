import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import gsap from 'gsap';
import moment from 'moment';
import classNames from 'classnames';
import OtpInput from 'react-otp-input';

import {
  selectOtpError,
  selectOtpExpiry,
  selectPendingOtp,
  setOtpError,
} from 'modules/auth/slice';
import { selectUser } from 'modules/me/slice';
import { useRedirectToSignUp } from 'hooks/useRedirect';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

const RegisterOTP = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [otpCode, setOtpCode] = useState('');
  const isPending = useAppSelector(selectPendingOtp);
  const otpExpiry = useAppSelector(selectOtpExpiry);
  const [seconds, setSeconds] = useState(0);
  const [resendTries, setResendTries] = useState(0);
  const otpError = useAppSelector(selectOtpError);
  const component = useRef<HTMLDivElement>(null);

  useRedirectToSignUp();

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffect(() => {
    if (!isPending) {
      dispatch({ type: ACTIONS.FETCH_OTP_EXPIRY });
    }
  }, [isPending]);

  useEffect(() => {
    if (otpExpiry) {
      const date = moment().add(28, 'minutes');
      const millisecondsLeft =
        moment(otpExpiry.expiredAt).valueOf() - date.valueOf();
      const secondsLimit = moment(millisecondsLeft).seconds();
      const minutesLimit = Math.floor(millisecondsLeft / 1000 / 60);
      setSeconds(secondsLimit * (minutesLimit + 1));
    }
  }, [otpExpiry]);

  const handleResend = () => {
    setResendTries(resendTries + 1);
    dispatch({ type: ACTIONS.SEND_OTP });
  };

  useEffect(() => {
    if (otpCode.length >= 6) {
      const payload = { code: Number(otpCode) };
      dispatch({ type: ACTIONS.VERIFY_OTP_V2, payload });
    }
  }, [otpCode]);

  useEffect(() => {
    const mySecondInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(mySecondInterval);
    };
  });

  const [isOtpError, setIsOtpError] = useState(false);

  useEffect(() => {
    if (otpError) {
      setIsOtpError(true);
      dispatch(setOtpError(false));
      setOtpCode('');
    }
  }, [otpError]);

  return (
    <section
      ref={component}
      className="fadein flex min-h-[calc(100vh-90px)] w-full max-w-[600px] flex-col items-center pt-[42px] md:min-h-[calc(100vh-388px)]"
    >
      <h2 className="font-primary mx-auto mb-[20px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:text-[28px]">
        verify
      </h2>
      <p className="text-center text-[14px] md:text-[16px]">
        Enter the 6-digit code sent to <br className="block md:hidden" />
        {user?.contact ? user?.contact : 'your mobile number.'}
      </p>
      <div
        className="
        mt-[30px] flex md:mt-[50px]
        md:translate-x-[-7px]"
      >
        {!isPending ? (
          <OtpInput
            value={otpCode}
            onChange={(otp: any) => setOtpCode(otp)}
            numInputs={6}
            isInputNum
            inputStyle="
              input select-none input-bordered !font-[300]
              md:text-[36px] text-[30px]
              mr-[20px] md:px-[1rem] px-[0]
              border-flexi-black-7 text-flexi-black-3/60
              md:!w-[55px] md:!h-[60px] md:!mx-2
              !w-[45px] !h-[50px] !mx-[5px]"
            shouldAutoFocus
            isDisabled={isPending}
          />
        ) : (
          <span className="h-[25px] w-[25px] animate-spin self-center rounded-full border-[2px] border-flexi-black-3 border-l-transparent border-t-transparent" />
        )}
      </div>
      {isOtpError && (
        <p className="mt-[25px] text-[12px] text-[#B71616]">Invalid code</p>
      )}
      {seconds > 0 ? (
        <div className=" mt-auto flex w-full max-w-[420px] flex-col text-center text-[14px]">
          {seconds > 0 &&
            `Please wait 0${
              seconds > 60 ? Math.ceil((seconds - 59) / 60) : '0'
            }:${seconds < 10 || (seconds > 60 && seconds < 70) ? 0 : ''}${
              seconds > 60 ? seconds - 60 : seconds
            }s
          `}
          <p
            className={classNames(
              'pointer-events-auto mt-[8px] text-[16px] font-bold text-flexi-orange-1',
              {
                '!pointer-events-none !text-flexi-black-4': seconds > 1,
              },
            )}
            onClick={handleResend}
            role="button"
          >
            Resend Code
          </p>
        </div>
      ) : resendTries >= 2 ? (
        <div className=" mt-auto flex w-full max-w-[420px] flex-col text-center text-[14px]">
          <p>Still having problems?</p>
          <a
            href="tel:1300363780"
            className={classNames(
              'pointer-events-auto mt-[8px] text-[16px] font-bold text-flexi-orange-1',
              {
                '!pointer-events-none !text-flexi-black-4': seconds > 1,
              },
            )}
          >
            Contact Us
          </a>
        </div>
      ) : (
        <div className=" mt-auto flex w-full max-w-[420px] flex-col text-center text-[14px]">
          <p>Didn&apos;t receive your code?</p>
          <p
            className={classNames(
              'pointer-events-auto mt-[8px] text-[16px] font-bold text-flexi-orange-1',
              {
                '!pointer-events-none !text-flexi-black-4': seconds > 1,
              },
            )}
            onClick={handleResend}
            role="button"
          >
            Resend Code
          </p>{' '}
        </div>
      )}
    </section>
  );
};

export default RegisterOTP;
