import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Star } from 'assets/images/star.svg';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectFavoriteLocationListStatus,
  selectUpdateFavoriteLocationStatus,
  selectUpdateId,
  setUpdateId,
} from 'modules/favorites/slice';
import { RequestStatusEnum } from 'common/types';

interface Props {
  isFavorite: boolean | undefined;
  podId: number | undefined;
  hasText?: boolean;
  hasStar?: boolean;
  extraClass?: string;
  titleAdd?: string;
  titleRemove?: string;
  vehicleList?: any;
}

const FavoriteButton = ({
  isFavorite = false,
  podId = 0,
  hasText = true,
  hasStar = true,
  titleAdd,
  titleRemove,
}: Props) => {
  const dispatch = useAppDispatch();
  const [favorite, setFavorite] = useState(isFavorite);
  const updateFavoriteStatus = useAppSelector(
    selectUpdateFavoriteLocationStatus,
  );
  const favoriteListStatus = useAppSelector(selectFavoriteLocationListStatus);
  const updateId = useAppSelector(selectUpdateId);

  const addToFavoriteLocation = (id: number) => {
    dispatch({
      type: ACTIONS.ADD_FAVORITE_LOCATION,
      payload: {
        favorite: id,
      },
    });
    dispatch(setUpdateId(id.toString()));
    setFavorite(!favorite);
  };

  const removeFromFavoriteLocation = (id: number) => {
    dispatch(setUpdateId(id.toString()));
    dispatch({
      type: ACTIONS.REMOVE_FAVORITE_LOCATION,
      payload: {
        favorite: id,
      },
    });
    setFavorite(!favorite);
  };

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  return updateFavoriteStatus === RequestStatusEnum.PENDING ||
    favoriteListStatus === RequestStatusEnum.PENDING ? (
    podId.toString()=== updateId ? (
      <div className="flex items-center gap-1">
        <span className="h-[10px] w-[10px] animate-spin rounded-full  border-[1px] border-flexi-black-4 border-t-transparent" />
      </div>
    ) : favorite ? (
      <div
        className="flex cursor-pointer items-center gap-1"
        onClick={() => removeFromFavoriteLocation(podId)}
      >
        {hasStar && <Star fill="#F2841C" style={{ fontSize: '20px' }} />}
        {hasText && (
          <p className={`font-base text-sm`}>
            {titleRemove || 'Remove from Favorite Location'}
          </p>
        )}
      </div>
    ) : (
      <div
        className="flex cursor-pointer items-center gap-1"
        onClick={() => addToFavoriteLocation(podId)}
      >
        {hasStar && <Star fill="#d8d8d8" style={{ fontSize: '20px' }} />}
        {hasText && (
          <p
            className={`font-base text-center text-sm text-flexi-orange-1 `}
          >
            {titleAdd || 'Add as Favorite Location'}
          </p>
        )}
      </div>
    )
  ) : favorite ? (
    <div
      className="flex cursor-pointer items-center gap-1"
      onClick={() => removeFromFavoriteLocation(podId)}
    >
      {hasStar && <Star fill="#F2841C" style={{ fontSize: '20px' }} />}
      {hasText && (
        <p className={`font-base text-sm`}>
          {titleRemove || 'Remove from Favorite Location'}
        </p>
      )}
    </div>
  ) : (
    <div
      className="flex cursor-pointer items-center gap-1"
      onClick={() => addToFavoriteLocation(podId)}
    >
      {hasStar && <Star fill="#d8d8d8" style={{ fontSize: '20px' }} />}
      {hasText && (
        <p
          className={`font-base text-center text-sm text-flexi-orange-1 `}
        >
          {titleAdd || 'Add as Favorite Location'}
        </p>
      )}
    </div>
  );
};

export default FavoriteButton;
