import React, { Dispatch, SetStateAction, useState } from 'react';
import { ReactComponent as LogoBusiness } from 'assets/images/type-business.svg';
import { ReactComponent as LogoPersonal } from 'assets/images/type-personal.svg';
import { ReactComponent as LogoStudent } from 'assets/images/type-student.svg';
import classNames from 'classnames';

interface Props {
  accountType: number;
  setAccountType: Dispatch<SetStateAction<number>>;
}

const AccountTypeRadio = ({ accountType, setAccountType }: Props) => {
  const [beforeType, setbeforeType] = useState(1);

  return (
    <ul
      className={classNames(
        'flex justify-center gap-[25px] text-[16px] font-bold uppercase sm:justify-start sm:gap-[50px]',
        {
          '!hidden': accountType === 5,
        },
      )}
    >
      <li
        className={`relative flex items-center overflow-hidden px-[5px] pb-3 
        before:absolute before:bottom-0 before:left-0  before:bg-orange-500 before:duration-200
        ${
          accountType === 1
            ? 'text-primary  before:translate-x-[0%]'
            : 'text-[#BABABA]  before:translate-x-[105%]'
        } before:h-[5px] before:w-[100%]`}
      >
        <input
          type="radio"
          name="accountType"
          className="appearance-none"
          id="personal"
          checked={accountType === 1}
          onChange={() => {
            setAccountType(1);
            setbeforeType(1);
          }}
        />
        <label
          htmlFor="personal"
          className="flex cursor-pointer flex-col items-center gap-2 text-[10px] sm:flex-row sm:text-[16px]"
        >
          <LogoPersonal
            fill={accountType === 1 ? '#282828' : `#BABABA`}
            className="min-h-[20px] min-w-[20px] sm:min-h-[30px] sm:min-w-[30px]"
          />
          Personal
        </label>
      </li>
      <li
        className={`relative flex items-center  overflow-hidden px-[5px] pb-3
        before:absolute before:bottom-0 before:left-0 before:bg-orange-500 before:duration-200
        ${accountType !== 3 && 'before:bg-[white]'}
        ${
          accountType === 3
            ? 'text-primary before:translate-x-[0%]'
            : beforeType === 1
            ? 'text-[#BABABA] before:translate-x-[-105%]'
            : 'text-[#BABABA] before:translate-x-[105%]'
        } before:h-[5px] before:w-[100%]`}
      >
        <input
          type="radio"
          name="accountType"
          className="appearance-none"
          id="student"
          checked={accountType === 3}
          onChange={() => setAccountType(3)}
        />
        <label
          htmlFor="student"
          className="flex cursor-pointer flex-col items-center gap-2 text-[10px] sm:text-[16px] sm:flex-row"
        >
          <LogoStudent
            fill={accountType === 3 ? '#282828' : `#BABABA`}
            className="min-h-[20px] min-w-[20px] sm:min-h-[30px] sm:min-w-[30px]"
          />
          Student
        </label>
      </li>
      <li
        className={`relative flex items-center  overflow-hidden px-[5px] pb-3
        before:absolute before:bottom-0 before:left-0 before:bg-orange-500 before:duration-200
        ${
          accountType === 2
            ? 'text-primary  before:translate-x-[0%]'
            : 'text-[#BABABA]  before:translate-x-[-105%]'
        } before:h-[5px] before:w-[100%]`}
      >
        <input
          type="radio"
          name="accountType"
          className="appearance-none"
          id="business"
          checked={accountType === 2}
          onChange={() => {
            setAccountType(2);
            setbeforeType(2);
          }}
        />
        <label
          htmlFor="business"
          className="flex cursor-pointer flex-col items-center gap-2 text-[10px] sm:text-[16px] sm:flex-row"
        >
          <LogoBusiness
            fill={accountType === 2 ? '#282828' : `#BABABA`}
            className="min-h-[20px] min-w-[20px] sm:min-h-[30px] sm:min-w-[30px]"
          />
          Business
        </label>
      </li>

      <li className="relative hidden  items-center overflow-hidden px-[5px] pb-3 before:absolute before:bottom-0 before:left-0 before:bg-orange-500 before:duration-200">
        <input
          type="radio"
          name="accountType"
          className="appearance-none"
          id="business_driver"
          checked={accountType === 5}
          onChange={() => {
            setAccountType(5);
            setbeforeType(5);
          }}
        />
      </li>
    </ul>
  );
};

export default AccountTypeRadio;
