import { takeLatest } from 'redux-saga/effects';
import {
  fetchBusinessDrivers,
  fetchBusinessInvitees,
  inviteDrivers,
  removeDrivers,
  fetchBookingSettings,
  fetchNotificationSettings,
  saveBookingSettings,
  saveNotificationSettings,
  SAGA_ACTIONS,
  createPersonalAccount,
  createPersonalAccountDetails,
  createPersonalAccountAddCard,
  createPersonalAccountComplete,
  fetchBusinessDriverPhoto,
  createPersonalAccountUpdateEmail,
  verifyInviteToken,
} from './sagas';

const {
  INVITE_DRIVERS,
  FETCH_BUSINESS_DRIVERS,
  FETCH_BUSINESS_INVITEES,
  REMOVE_DRIVERS,
  FETCH_BOOKING_SETTINGS,
  FETCH_NOTIFICATION_SETTINGS,
  SAVE_BOOKING_SETTINGS,
  SAVE_NOTIFICATION_SETTINGS,
  CREATE_PERSONAL_ACCOUNT,
  CREATE_PERSONAL_ACCOUNT_DETAILS,
  CREATE_PERSONAL_ACCOUNT_COMPLETE,
  CREATE_PERSONAL_ACCOUNT_ADD_CARD,
  FETCH_BUSINESS_DRIVER_PHOTO,
  CREATE_PERSONAL_ACCOUNT_UPDATE_EMAIL,
  VERIFY_INVITE_TOKEN,
} = SAGA_ACTIONS;

const actions = [
  takeLatest(INVITE_DRIVERS, inviteDrivers),
  takeLatest(FETCH_BUSINESS_DRIVERS, fetchBusinessDrivers),
  takeLatest(FETCH_BUSINESS_INVITEES, fetchBusinessInvitees),
  takeLatest(REMOVE_DRIVERS, removeDrivers),
  takeLatest(FETCH_BOOKING_SETTINGS, fetchBookingSettings),
  takeLatest(FETCH_NOTIFICATION_SETTINGS, fetchNotificationSettings),
  takeLatest(SAVE_BOOKING_SETTINGS, saveBookingSettings),
  takeLatest(SAVE_NOTIFICATION_SETTINGS, saveNotificationSettings),
  takeLatest(CREATE_PERSONAL_ACCOUNT, createPersonalAccount),
  takeLatest(CREATE_PERSONAL_ACCOUNT_DETAILS, createPersonalAccountDetails),
  takeLatest(CREATE_PERSONAL_ACCOUNT_ADD_CARD, createPersonalAccountAddCard),
  takeLatest(CREATE_PERSONAL_ACCOUNT_COMPLETE, createPersonalAccountComplete),
  takeLatest(FETCH_BUSINESS_DRIVER_PHOTO, fetchBusinessDriverPhoto),
  takeLatest(
    CREATE_PERSONAL_ACCOUNT_UPDATE_EMAIL,
    createPersonalAccountUpdateEmail,
  ),
  takeLatest(VERIFY_INVITE_TOKEN, verifyInviteToken),
];

export default actions;
