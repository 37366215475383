import React from 'react';
import AppIcon from 'assets/images/ap_store.svg';
import GoogleIcon from 'assets/images/google_play.svg';
import { IMAGE_URL } from 'common/constants';
import { isAndroid, isIOS } from 'react-device-detect';
// import { useEffectOnce } from 'react-use';

function MobileHomePage() {

  // useEffectOnce(() => {
  //     window.location.replace('https://flexicarmobile.page.link/?link=https://www.flexicar.com.au&apn=com.flexicar.app&isi=6446107264&ibi=com.flexicar.app');
  // });

  return (
    <section className="flex flex-col items-center mb-[40px] py-[40px]">
      <h1 className="font-primary text-[20px] font-[900] uppercase text-[#FF7800]">
        get ready to drive!
      </h1>
      <p className="text-center mt-[10px] mb-[20px]">
        To access the application,
        <br/>
        simply download the app
        <br/>
        if you haven&apos;t already installed it.
      </p>
      {isAndroid ? (
        <a href="https://play.google.com/store/apps/details?id=com.flexicar.app">
          <p
            className="h-[40px] min-h-[40px] w-[145px] min-w-[145px] bg-center bg-no-repeat mb-[15px] rounded-md"
            style={{
              backgroundImage: `url(${IMAGE_URL}${GoogleIcon})`,
              backgroundSize: '100%',
              backgroundColor:'black'
            }}
          ></p>
        </a>
      ) : isIOS ? (
        <a href="https://apps.apple.com/au/app/flexicar-car-share/id6446107264">
          <p
            className="h-[40px] min-h-[40px] w-[145px] min-w-[145px]  bg-center bg-no-repeat mb-[15px] rounded-md"
            style={{
              backgroundImage: `url(${IMAGE_URL}${AppIcon})`,
              backgroundSize: '100%',
            }}
          ></p>
        </a>
      ) : (
        <>
          <p
            className="h-[40px] min-h-[40px] w-[145px] min-w-[145px]  bg-center bg-no-repeat mb-[15px] rounded-md"
            style={{
              backgroundImage: `url(${IMAGE_URL}${AppIcon})`,
              backgroundSize: '100%',
            }}
          ></p>
          <p
            className="h-[40px] min-h-[40px] w-[145px] min-w-[145px]  bg-center bg-no-repeat mb-[15px] rounded-md"
            style={{
              backgroundImage: `url(${IMAGE_URL}${GoogleIcon})`,
              backgroundSize: '100%',
              backgroundColor:'black'
            }}
            ></p>
        </>
      )}
        
    </section>
  );
}

export default MobileHomePage;
