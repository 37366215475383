import React, { useRef, useLayoutEffect } from 'react';
import MyAccountNav from 'components/MyAccountNav';
import { PageTitle } from 'components/Typography';
import ContactForm from './ContactForm';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';
import { useEffectOnce } from 'react-use';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import MyAccountBlock from 'components/MyAccountBlock';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { useLocation } from 'react-router-dom';

const SupportContactUs = () => {
  const dispatch = useAppDispatch();
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const { pathname } = useLocation();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  return (
    <section
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] md:mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Account - Support</title>
        <meta name="description" content="My Flexicar Account - Support" />
      </Helmet>
      <MyAccountNav title="support" />
      <div className="mb-[60px] gap-[100px] lg:flex">
        <section className='lg:max-w-[500px]'>
          <PageTitle>Support</PageTitle>
          <div className="mt-[10px] flex flex-col gap-4 border-t-[1px] md:mt-[30px]">
            <ContactForm />
          </div>
        </section>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>
    </section>
  );
};

export default SupportContactUs;
