import React from 'react';
import AppContent from 'components/layouts/AppContent';
import { Route, Switch, useLocation } from 'react-router-dom';
import MyAccountNavigator from 'features/MyAccountNavigator';
import MyAccountPaymentMethod from 'features/MyAccountPaymentMethod';
import MyPlanDetails from 'features/MyPlanDetails';
import MyAccountDriverDetails from 'features/MyAccountDriverDetails';
import MyAccountPersonalDetails from 'features/MyAccountPersonalDetails';
import ChangePassword from 'features/ChangePassword';
import MyAccountSettings from 'features/MyAccountSettings';
import MyPlanSelectionMobile from 'features/MyPlanSelectionMobile';
import MyAccountSupport from 'features/MyAccountSupport';
import MyAccountInvoices from 'features/MyAccountInvoices';
import MyAccountLoyaltyPointsRewards from 'features/MyAccountLoyaltyPointsRewards';
import MyDrivers from 'features/MyDrivers';
import MyAccountBusinessDetails from 'features/MyAccountBusinessDetails';
import useBusiness from 'hooks/useBusiness';
import MyAccountInvoice from 'features/MyAccountInvoice';
import CancelMembership from 'features/CancelMembership';
import CancelMembershipDone from 'features/CancelMembershipDone';

const MyAccountPage = () => {
  const { isBusinessManagerAccount } = useBusiness();
  const { pathname } = useLocation();

  return (
    <AppContent maxWidth={pathname === '/app/faqs' ? 'full' : '1000px'}>
      <Switch>
        <Route
          path={`/app/account/${
            isBusinessManagerAccount ? 'account-details' : 'personal-details'
          }`}
          exact
          // component={MyAccountPersonalDetails}
          component={
            isBusinessManagerAccount
              ? MyAccountBusinessDetails
              : MyAccountPersonalDetails
          }
        />
        <Route
          path={`/app/account/${
            isBusinessManagerAccount ? 'drivers' : 'driver-details'
          }`}
          exact
          component={
            isBusinessManagerAccount ? MyDrivers : MyAccountDriverDetails
          }
        />
        <Route
          path={`/app/account/${
            isBusinessManagerAccount ? 'settings' : 'loyalty-points'
          }`}
          exact
          component={
            isBusinessManagerAccount
              ? MyAccountSettings
              : MyAccountLoyaltyPointsRewards
          }
        />
        <Route
          path="/app/account/plan-details"
          exact
          component={MyPlanDetails}
        />
        <Route
          path="/app/account/plan-selection-mobile"
          exact
          component={MyPlanSelectionMobile}
        />
        <Route
          path="/app/account/cancel-membership"
          exact
          component={CancelMembership}
        />
        <Route
          path="/app/account/cancel-membership/done"
          exact
          component={CancelMembershipDone}
        />
        <Route
          path="/app/account/payment-method"
          exact
          component={MyAccountPaymentMethod}
        />
        <Route exact path="/app/account/security" component={ChangePassword} />
        <Route
          exact
          path="/app/account/invoices"
          component={MyAccountInvoices}
        />
        <Route
          exact
          path="/app/account/invoices/invoice"
          component={MyAccountInvoice}
        />
        <Route exact path="/app/account/support" component={MyAccountSupport} />
        <Route path="/app/account" component={MyAccountNavigator} />
      </Switch>
    </AppContent>
  );
};

export default MyAccountPage;
