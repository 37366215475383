import React, { useRef, useLayoutEffect, useEffect } from 'react';

import { useEffectOnce } from 'react-use';
import gsap from 'gsap';

import iconJson from 'assets/json/_icon-payment-failed.json';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';
import { removeToken } from 'common/helpers';
import { setSignUpDriver } from 'modules/auth/slice';
import { selectErrors, selectVerifyInviteTokenStatus } from 'modules/business/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import useQuery from 'hooks/useQuery';
import VerificationForm from './VerificationForm';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import { RequestStatusEnum } from 'common/types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Lottie from 'react-lottie';

const SignUpIDVerification = () => {
  const dispatch = useAppDispatch();
  const verifyInviteTokenStatus = useAppSelector(selectVerifyInviteTokenStatus);
  const errorMessage = useAppSelector(selectErrors);
  const component = useRef<HTMLDivElement>(null);
  const { params } = useQuery<{
    accountType?: string;
    token?: string;
    email?: string;
  }>();

  const iconJsonOptions = {
    loop: false,
    autoplay: true,
    animationData: iconJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffectOnce(() => {
    removeToken();
  });

  useEffect(() => {
    if (params && params.accountType && params.token) {
      const payload = {
        token: params.token,
      };
      dispatch({ type: ACTIONS.VERIFY_INVITE_TOKEN, payload });
      dispatch(
        setSignUpDriver({
          accountType: Number(params.accountType),
          token: params.token,
        }),
      );
    }
  }, []);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex w-full flex-col md:max-w-[600px]"
    >
      <section className="relative m-auto mt-[-70px] min-h-[100vh] w-full max-w-[1000px] md:mt-0 md:flex md:min-h-full md:px-[20px]">
        <div className="flex min-h-[100vh] flex-auto items-start justify-center pt-[60px] md:py-[60px] md:mx-0 md:min-h-[calc(100vh-388px)] md:justify-start">
          {verifyInviteTokenStatus === RequestStatusEnum.PENDING ? (
            <div className="absolute left-1/2 top-1/2 z-20 h-[50px] w-[50px] -translate-x-1/2 -translate-y-[calc(50%)]">
              <AiOutlineLoading3Quarters className="h-[50px] w-[50px] animate-spin fill-flexi-orange-1" />
            </div>
          ) : verifyInviteTokenStatus === RequestStatusEnum.FAILED ? (
            <div className="flex h-full w-full flex-col">
              <Lottie options={iconJsonOptions} height={200} width={200} />
              <h2 className="font-primary mb-[20px] mt-[20px] text-center text-[28px] font-[900] uppercase leading-[29px] text-flexi-orange-1 md:mb-[40px] md:mt-0 md:text-[40px] md:leading-[50px]">
                Sorry. Your invitation link has expired.
              </h2>
              <p className="mb-[40px] text-center text-[14px] md:mb-[70px] md:text-[16px]">
                {errorMessage}
              </p>
              <button
                className="btl-lg btn mx-auto !mt-auto !h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 md:!h-[60px]"
                onClick={() => {
                  window.location.replace('/');
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="h-full w-full">
              <div className="absolute flex h-[25px] w-[25px] items-center justify-center rounded-full bg-flexi-green-1">
                <a href="/register-v2">
                  <ArrowIcon className="rotate-180 fill-white" />
                </a>
              </div>
              <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-[28px]">
                ID Verification
              </h2>
              <VerificationForm />
            </div>
          )}
        </div>
      </section>
    </section>
  );
};

export default SignUpIDVerification;
