import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useEffectOnce, useUnmount, useUpdateEffect } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import gsap from 'gsap';

import { RequestStatusEnum } from 'common/types';
import { PageTitle } from 'components/Typography';
import { selectUser } from 'modules/me/slice';
import { selectEndDate, selectStartDate } from 'modules/booking/slice';
import {
  selectBookingTimer,
  selectSelectedVehicle,
  selectVehicleSummary,
  selectVehicleSummaryStatus,
  setBookingExpired,
} from 'modules/vehicle/slice';
import {
  reset as resetTransaction,
  selectBookingPromo,
} from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PaymentMethodModal from 'components/PaymentMethodModal';
import PendingLoader from 'components/PendingLoader';
import SummaryInvoice from './SummaryInvoice';
import PaymentModal from './PaymentModal';
import SummaryForm from './SummaryForm';
import BookingInfo from './BookingInfo';
import HasUnpaidModal from 'components/HasUnpaidModal';
import { setPreviousUrl } from 'common/helpers';

const BookingSummary = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const location = useLocation();

  const endDate = useAppSelector(selectEndDate) || new Date();
  const startDate = useAppSelector(selectStartDate) || new Date();
  const pendingStatus = useAppSelector(selectVehicleSummaryStatus);
  const selectedVehicle = useAppSelector(selectSelectedVehicle);
  const vehicleSummary = useAppSelector(selectVehicleSummary);
  const bookingPromo = useAppSelector(selectBookingPromo);
  const bookingTimer = useAppSelector(selectBookingTimer);
  const user = useAppSelector(selectUser);
  const [isHasFailedPaymentModalOpen, setHasFailedPaymentModalOpen] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isPaymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [toggleUseCredits, setToggleUseCredits] = useState(
    user?.isUseCredits || false
  );
  
  const component = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });


  useLayoutEffect(() => {
    if (component.current)
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5,
      });
  }, [pendingStatus]);

  useEffect(() => {
    const handlePopState = () => {
      push('/app/booking');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffectOnce(() => {
    setPreviousUrl(location.pathname);
    if (selectedVehicle) {
      dispatch({
        type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD,
      });
      dispatch({
        type: ACTIONS.FETCH_VEHICLE_SUMMARY,
        payload: {
          vehicleId: selectedVehicle,
          startsAt: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          endsAt: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          addons: user?.hasReduceExcessDamageCover
            ? 'standard-damage-cover'
            : undefined,
        },
      });
    }
    dispatch(setBookingExpired(false));
  });

  useUpdateEffect(() => {
    dispatch({
      type: ACTIONS.UPDATE_VEHICLE_SUMMARY_NOLOAD,
      payload: {
        vehicleId: selectedVehicle,
        startsAt: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        endsAt: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        addons: user?.hasReduceExcessDamageCover
          ? 'standard-damage-cover'
          : undefined,
        promo: bookingPromo,
      },
    });
  }, [user?.hasReduceExcessDamageCover, bookingPromo]);

  useEffectOnce(() => {
    if (!selectedVehicle) push('/app/booking');
  });

  useUnmount(() => {
    dispatch(resetTransaction());
  });

  useEffect(() => {
    const elapseTimer = moment(bookingTimer).add('10', 'minutes');
    const interval = setInterval(() => {
      if (
        new Date().getTime() > new Date(elapseTimer.toLocaleString()).getTime()
      ) {
        push('/app/booking');
        dispatch(setBookingExpired(true));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return pendingStatus === RequestStatusEnum.PENDING ? (
    <div className="py-[100px]">
      <PendingLoader />
    </div>
  ) : (
    <>
      <article
        ref={component}
        className="fadein hide-scrollbar relative mx-[25px] flex flex-col justify-center overflow-auto py-[100px] px-[30px] md:m-auto lg:flex-row lg:gap-[100px]"
      >
        <div className="flex max-w-[600px] flex-col gap-[40px] md:gap-[50px]">
          <PageTitle>Booking Details</PageTitle>
          <BookingInfo
            vehicleSummary={vehicleSummary}
            startDate={startDate}
            endDate={endDate}
          />
          <SummaryForm
            selectedVehicle={selectedVehicle}
            breakdownTotal={vehicleSummary?.breakdowns.total || 0}
            reduceExcessTotal={
              vehicleSummary?.breakdowns.reduceExcessTotal || 0
            }
            setPaymentModalOpen={setPaymentModalOpen}
            setPaymentMethodModalOpen={setPaymentMethodModalOpen}
            breakdowns={vehicleSummary?.breakdowns}
            requireCostCentre={vehicleSummary?.settings.requireCostCentre}
            toggleUseCredits={toggleUseCredits}
            setToggleUseCredits={setToggleUseCredits}
            setHasFailedPaymentModalOpen={setHasFailedPaymentModalOpen}
          />
        </div>

        <div className="sticky top-[-150px] lg:block">
          <SummaryInvoice
            breakdowns={vehicleSummary?.breakdowns}
            toggleUseCredits={toggleUseCredits}
          />
        </div>
        <PaymentMethodModal
          isModalOpen={isPaymentMethodModalOpen}
          setModalOpen={setPaymentMethodModalOpen}
        />
      </article>

      <PaymentModal
        isModalOpen={isPaymentModalOpen}
        setModalOpen={setPaymentModalOpen}
      />

      <HasUnpaidModal
        isModalOpen={isHasFailedPaymentModalOpen && user !== null}
        handleClose={() => {
          setHasFailedPaymentModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      />
    </>
  );
};

export default BookingSummary;
