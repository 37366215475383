import React, { Dispatch } from 'react';
// import PendingLoader from 'components/PendingLoader';
import useAppSelector from 'hooks/useAppSelector';
import { selectPendingPayment } from 'modules/transaction/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import {
  selectDefaultPaymentCard,
  selectPendingPaymentCards,
} from 'modules/me/slice';
import classNames from 'classnames';
import useBusiness from 'hooks/useBusiness';

interface Props {
  referralEmail: string | undefined;
  vffMembershipId: string | undefined;
  accessCode: string | undefined;
  partner: string | undefined;
  partnerMembershipId: string | undefined;
  hasReduceExcessDamageCover: string | undefined;
  referralEmailError: string | null;
  setPartnerMembershipError: Dispatch<React.SetStateAction<string | null>>;
}

const PayAndFinish = ({
  referralEmail,
  vffMembershipId,
  accessCode,
  partner,
  partnerMembershipId,
  hasReduceExcessDamageCover,
  referralEmailError,
  setPartnerMembershipError,
}: Props) => {
  const dispatch = useAppDispatch();
  const isPendingPayment = useAppSelector(selectPendingPayment);
  const isPendingDefaultPaymentCard = useAppSelector(selectPendingPaymentCards);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const { isBusinessManagerAccount } = useBusiness()

  const handlePayment = () => {
    const payload = {
      accessCode,
      referralEmail,
      vffMembershipId,
      partner,
      partnerMembershipId,
      hasReduceExcessDamageCover,
    };

    if (referralEmailError) return;
    if (partner && !partnerMembershipId) {
      setPartnerMembershipError(
        'The Partner Membership ID field is required when partner is present.',
      );
      return;
    }

    dispatch({ type: ACTIONS.REGISTER_PAYMENT, payload });
  };

  return (
    <button
      className={classNames(
        'btn btn-primary relative !h-[50px] w-full overflow-hidden rounded-full  capitalize hover:bg-flexi-green-2 disabled:text-white md:!h-[60px]',
        {
          loading: isPendingDefaultPaymentCard || isPendingPayment,
          '!bg-flexi-green-2': isPendingPayment,
        },
      )}
      disabled={!isBusinessManagerAccount  && (defaultPaymentCard === null || isPendingPayment)}
    >
      {!isPendingDefaultPaymentCard && !isPendingPayment && (
        <label
          className={classNames(
            ' absolute left-0 top-0 grid h-full w-full cursor-pointer items-center text-[16px] md:h-[60px] ',
            {
              loading: true,
            },
          )}
          htmlFor="payment-modal"
          onClick={handlePayment}
        >
          Pay &amp; Finish
        </label>
      )}
    </button>
  );
};

export default PayAndFinish;
