import { useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { RequestStatusEnum } from 'common/types';
import {
  selectPendingCardUrl,
  selectWindcaveCardStatus,
  selectWindcaveCardUrl,
} from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

export type MessageType = {
  status: string;
  type: string;
} | null;

const useWindcave = () => {
  const dispatch = useAppDispatch();
  const isPendingAddCard = useAppSelector(selectPendingCardUrl);
  const windcaveCardStatus = useAppSelector(selectWindcaveCardStatus);
  const windcaveCardUrl = useAppSelector(selectWindcaveCardUrl);
  const [message, setMessage] = useState<MessageType>(null);
  const [addCardStatus, setAddCardStatus] = useState<RequestStatusEnum | null>(
    null,
  );

  const windcaveMessage = (
    ev: MessageEvent<{ status: string; type: string }>,
  ) => {
    if (typeof ev.data !== 'object') return;
    if (!ev.data) return;
    setMessage(ev.data);
  };

  const addCard = () => {
    dispatch({ type: ACTIONS.FETCH_WINDCAVE_CARD_URL });
  };

  const createPersonalAccountAddCard = () => {
    dispatch({ type: ACTIONS.CREATE_PERSONAL_ACCOUNT_ADD_CARD });
  };

  useEffect(() => {
    if (message === null) {
      window.addEventListener('message', windcaveMessage, false);
    }
    return () => window.removeEventListener('message', windcaveMessage, false);
  }, []);

  useUpdateEffect(() => {
    if (message?.status === 'success' && message.type === 'validate') {
      setAddCardStatus(RequestStatusEnum.SUCCESS);
      dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
      dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
    } else if (message?.status === 'fail' && message.type === 'validate') {
      setAddCardStatus(RequestStatusEnum.FAILED);
    } else if (message?.status === 'cancel' && message.type === 'validate') {
      setAddCardStatus(RequestStatusEnum.CANCELLED);
    }
  }, [message]);

  return {
    message,
    setMessage,
    addCardStatus,
    setAddCardStatus,
    addCard,
    isPendingAddCard,
    windcaveCardStatus,
    windcaveCardUrl,
    createPersonalAccountAddCard,
  };
};

export default useWindcave;
