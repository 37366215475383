import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useLocation } from 'react-router-dom';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { useDropzone } from 'react-dropzone';
import { Helmet } from 'react-helmet';
import { addYears } from 'date-fns';
import Calendar from 'react-calendar';
import classNames from 'classnames';
import moment from 'moment';
import gsap from 'gsap';

import { ReactComponent as Arrow } from 'assets/images/arrow-down.svg';
import { ReactComponent as IconCamera } from 'assets/images/camera.svg';
import { ReactComponent as IconEdit } from 'assets/images/edit.svg';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import { PageTitle } from 'components/Typography';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { selectChangeCourseEndDateMessage, selectChangeCourseEndDateStatus, selectPending, selectUser, selectUserPhoto, setChangeCourseEndDateStatus } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useStudent from 'hooks/useStudent';
import MyAccountBlock from 'components/MyAccountBlock';
import MyAccountNav from 'components/MyAccountNav';
import Modal from 'components/Modal';
import { RequestStatusEnum } from 'common/types';

const MyAccountPersonalDetails = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isPending = useAppSelector(selectPending);
  const courseEndDateStatus = useAppSelector(selectChangeCourseEndDateStatus);
  const courseEndDateMessage = useAppSelector(selectChangeCourseEndDateMessage);
  console.log(courseEndDateMessage)
  const [courseEndDateModalOpen, setCourseEndDateModalOpen] = useState(false);
  const [selectedCourseEndDate, setSelectedCourseEndDate] = useState<
    object | null
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const photo = useAppSelector(selectUserPhoto);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const { pathname } = useLocation();
  const { isStudentAccount } = useStudent();
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const renderPending = ({ width }: { width: string }) => {
    return (
      <div className="flex-1 animate-pulse">
        <div className={`h-[25px] ${width} rounded bg-slate-100`} />
      </div>
    );
  };

  const handleUploadPhoto = () => {
    const formData = new FormData();
    formData.append('photo', acceptedFiles[0]);
    dispatch({ type: ACTIONS.UPLOAD_PHOTO, payload: formData });
  };

  const handleUpdateCourseEndDate = (selectedCourseEndDate: object) => {
    const payload = {
      courseEndDate: moment(selectedCourseEndDate).format('YYYY-MM-DD'),
    };
    dispatch({ type: ACTIONS.UPDATE_COURSE_END_DATE, payload });
  };

  useUpdateEffect(() => {
    handleUploadPhoto();
  }, [acceptedFiles]);

  useEffectOnce(() => {
    if (photo !== undefined) dispatch({ type: ACTIONS.GET_PHOTO });
  });

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  useEffect(() => {}, [user]);

  return (
    <section
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] md:mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Account - Personal Details</title>
        <meta
          name="description"
          content="My Flexicar Account - Personal Details"
        />
      </Helmet>
      <MyAccountNav title="personal details" />
      <div
        className="w-full justify-between gap-x-[100px] lg:flex"
        // style={{gridTemplateColumns:'1fr 1fr'}}
      >
        <article className="flex w-[84vw] flex-col lg:w-[500px]">
          <PageTitle>Personal Details</PageTitle>
          <div className="mx-auto mb-[30px] mt-[10px] w-full items-center gap-[45px] border-t-[1px] pt-[40px] md:mx-0 md:mt-[30px] md:flex md:pt-[50px]">
            <div {...getRootProps()}>
              <input {...getInputProps()} className="hidden" />
              <div
                className={classNames(
                  'relative mx-auto grid h-[120px] w-[120px] place-items-center rounded-full bg-flexi-black-5 text-[60px] text-flexi-black-4 md:mx-0',
                  {
                    '!bg-transparent shadow-[0_0_0_1px] shadow-flexi-orange-1':
                      photo,
                  },
                )}
                onClick={open}
                role="button"
              >
                {photo ? (
                  <img
                    src={photo}
                    className="block h-[120px] w-[120px] rounded-full object-cover"
                  />
                ) : (
                  user?.fullname[0]
                )}
                <div className="absolute bottom-0 right-0 grid h-[35px] w-[35px] cursor-pointer place-items-center rounded-full bg-flexi-orange-1">
                  {photo ? (
                    <IconEdit className="h-[16px] w-[16px] fill-white" />
                  ) : (
                    <IconCamera className="h-[16px] w-[16px]" />
                  )}
                </div>
              </div>
            </div>

            <div>
              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="mb-[10px] mt-2 flex-auto text-center text-[26px] font-bold leading-[31px] md:mt-0 md:text-left">
                  {user?.fullname}
                </p>
              )}

              {isPending ? (
                renderPending({ width: 'w-[200px]' })
              ) : (
                <p className="flex-auto text-center text-[16px] md:text-left">
                  Joined {moment(user?.registeredAt).format('MMMM YYYY')}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[20px] text-[16px] md:text-[16px]">
            <div className="gap-[55px] md:flex">
              <p className="w-[120px] font-bold">Email Address</p>
              {isPending ? (
                renderPending({ width: 'w-[150px]' })
              ) : (
                <p>{user?.email}</p>
              )}
            </div>
            <div className="gap-[50px] md:flex">
              <p className="w-[120px] font-bold">Mobile Number</p>
              {isPending ? (
                renderPending({ width: 'w-[150px]' })
              ) : (
                <p>{user?.contact}</p>
              )}
            </div>

            <div className="gap-[50px] md:flex">
              <p className="w-[120px] font-bold">Account Status</p>
              {isPending ? (
                renderPending({ width: 'w-[150px]' })
              ) : (
                <p className="capitalize">
                  {user?.status === 'approved' ? 'verified' : 'pending'}
                </p>
              )}
            </div>

            {isStudentAccount && (
              <div className="gap-[50px] md:flex">
                <p className="w-[120px] font-bold">University</p>
                {isPending ? (
                  renderPending({ width: 'w-[150px]' })
                ) : (
                  <p className="capitalize">{user?.organisation?.name}</p>
                )}
              </div>
            )}
            {isStudentAccount && (
              <div className="gap-[50px] md:flex">
                <p className="w-[120px] font-bold">Campus</p>
                {isPending ? (
                  renderPending({ width: 'w-[150px]' })
                ) : (
                  <p className="capitalize">{user?.organisation?.campus}</p>
                )}
              </div>
            )}
            {isStudentAccount && (
              <div className="gap-[50px] md:flex">
                <p className="w-[120px] font-bold">Course End Date</p>
                {isPending ? (
                  renderPending({ width: 'w-[150px]' })
                ) : (
                  <p className="capitalize">
                    {moment(new Date(user?.course?.endDate || '')).format(
                      'DD MMMM YYYY',
                    )}
                  </p>
                )}
                {(user?.course?.isNullCourseEndDate || user?.course?.isCourseEndDateExpired ||
                  user?.course?.isCourseEndDateExpiringInAWeek ||
                  moment(moment(user?.course?.endDate).subtract(7, 'days')).isSameOrBefore(new Date())) && (
                  <p
                    className="cursor-pointer font-bold text-primary"
                    onClick={() => setCourseEndDateModalOpen(true)}
                  >
                    Update
                  </p>
                )}
              </div>
            )}
          </div>
        </article>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>

      <Modal
        isModalOpen={courseEndDateModalOpen}
        handleClose={() => {
          dispatch(setChangeCourseEndDateStatus(null));
          setCourseEndDateModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        {courseEndDateStatus === RequestStatusEnum.SUCCESS ? (
          <>
            <IconSuccess className="icon" />
            <h1 className="mb-[30px] text-center text-[20px] font-bold lg:text-[34px]">
              New course date is set!
            </h1>
            {courseEndDateMessage &&
              <p className="text-center text-[14px] md:text-[18px]">{courseEndDateMessage}</p>
            }
            <button
              className="!lg:h-[60px] btn mt-[40px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold !capitalize text-white outline-none hover:bg-flexi-green-1"
              onClick={() => {
                dispatch(setChangeCourseEndDateStatus(null));
                setCourseEndDateModalOpen(false);
              }}
            >
              Ok
            </button>
          </>
        ) : (
          <>
            <h1 className="my-[20px] text-center text-[20px] font-bold lg:text-[34px]">
              Course End Date
            </h1>

            {courseEndDateStatus === RequestStatusEnum.PENDING ? (
              <AiOutlineLoading3Quarters className="mx-auto my-[80px] h-[60px] w-[60px] animate-spin fill-flexi-orange-1" />
            ) : (
              <Calendar
                className={classNames(
                  'mx-auto w-full border-0 text-[12px] sm:w-[360px] sm:text-[16px]',
                )}
                minDate={new Date()}
                maxDate={addYears(new Date(), 1)}
                prev2Label={null}
                next2Label={null}
                prevLabel={<Arrow className="mx-auto rotate-90" />}
                nextLabel={<Arrow className="mx-auto -rotate-90" />}
                onClickDay={(selectedDate) =>
                  setSelectedCourseEndDate(selectedDate)
                }
              />
            )}
            <button
              className={classNames(
                '!lg:h-[60px] btn mt-[40px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold !capitalize text-white outline-none hover:bg-flexi-green-1',
                {
                  loading: courseEndDateStatus === RequestStatusEnum.PENDING,
                },
              )}
              disabled={!selectedCourseEndDate}
              onClick={() => {
                if (selectedCourseEndDate)
                  handleUpdateCourseEndDate(selectedCourseEndDate);
              }}
            >
              Update
            </button>
          </>
        )}
      </Modal>
    </section>
  );
};

export default MyAccountPersonalDetails;
