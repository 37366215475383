export const API_URL = process.env.REACT_APP_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const GOOGLE_API = process.env.REACT_APP_GOOGLE_API || '';
// export const DEFAULT_LNG_LAT = { lat: 14.5354129, lng: 120.9810314 };
export const DEFAULT_LNG_LAT = { lat: -37.823573, lng: 144.965126 };

export const FIRESTORE_DRIVERS_COLLECTION_NAME = process.env.REACT_APP_FIRESTORE_DRIVERS_COLLECTION_NAME || '';
export const FIRESTORE_AVAILABILITIES_COLLECTION_NAME = process.env.REACT_APP_FIRESTORE_AVAILABILITIES_COLLECTION_NAME || '';

export interface IPlan {
  id: number;
  name: string;
  title: string;
  desc: string;
  items: string[];
}
export interface IPlans {
  [key: string]: IPlan[];
}

export const BOOKING_STATUS = {
  CANCELLED: 0,
  COMPLETED: 1,
  BOOKED: 2,
  ONGOING: 3,
  EXTENDED: 4,
  NO_SHOW: 5,
}

export const PLANS: IPlans = {
  personal: [
    {
      id: 1,
      name: 'sometimes',
      title: 'Flexi Sometimes',
      desc: "Perfect for a first time car sharer wanting to give it a try, a commitment-phobe or if you're just unsure how often you'll drive.",
      items: [
        'No monthly minimum costs',
        'From $11.00 an day* (includes 100kms. $0.39/km thereafter)',
        'From $79.00 a hour* (unlimited kms)',
      ],
    },
    {
      id: 2,
      name: 'often',
      title: 'Flexi Often',
      desc: 'Ideal for those in need of a second car during the week on a regular basis for errands or picking up the kids.',
      items: [
        '$75 pre-paid driving credit a month',
        'From $10.00 an hour* (unlimited kms)',
        'From $65.00 a day* (includes 100kms. $0.30/kms thereafter)',
      ],
    },
    {
      id: 3,
      name: 'frequent',
      title: 'Flexi Frequent',
      desc: 'Always on the road, whether for work or fun? This plan is the best value for money for you.',
      items: [
        '$150 pre-paid credit a month (pre-paid credit will roll over for one month)',
        'From $8.50 an hour* (unlimited kms)',
        'From $55.00 a day* (includes 100kms. $0.25/kms thereafter)',
      ],
    },
  ],
  business: [
    {
      id: 4,
      name: 'business',
      title: 'Flexi Business',
      desc: "Perfect for a first time car sharer wanting to give it a try, a commitment-phobe or if you're just unsure how often you'll drive.",
      items: [
        'No monthly minimum costs',
        'From $8.50 an hour* (unlimited kms)',
        'From $55.00 a day* (includes 100kms. $0.39/kms thereafter)',
      ],
    },
  ],
  student: [
    {
      id: 5,
      name: 'student',
      title: 'Flexi Student',
      desc: "Perfect when you're a student living on a tinned spaghetti. It's a 'casual' plan so if you don't drive for a while, you don't pay anything at all!",
      items: [
        '$35 a year returned in driving credit to your account',
        'From $8.50 an hour* (unlimited kms)',
        'From $55.00 a day* (includes 100kms. $0.39/km thereafter)',
      ],
    },
  ],
};
