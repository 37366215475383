import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import React from 'react';
import { useHistory } from 'react-router-dom';

function AddPaymentMethodSuccess() {
  const user = useAppSelector(selectUser);
  const { push } = useHistory();

  const handleRedirect = () => {
    push('app/booking');
  };

  return (
    <article className="relative mt-[50px] mb-[100px] flex flex-col items-center gap-4 self-center md:px-[100px] mx-[25px] md:mx-0 md:mt-[100px]">
      <h2 className="font-primary mb-[30px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:mb-[40px] md:text-[40px]">
        Thank you, {user?.firstname}!
      </h2>

      <p className="max-w-[850px] text-center">
      You have been added to the {user?.organisation?.name}, you are now ready to use the flexicar app.
      </p>
      <button
        onClick={handleRedirect}
        className="btn mt-[50px] h-[60px] w-[350px] max-w-[850px] rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2"
      >
        Continue
      </button>
    </article>
  );
}

export default AddPaymentMethodSuccess;
