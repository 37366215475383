import React from 'react';

const Guidelines = () => {
  return (
    <div className="flex flex-col gap-[16px] text-[16px] leading-[23px]">
      <p>
        Create your business account and invite your colleagues to become
        drivers immediately after signing up. It is completely free to create a
        business account and you only pay for the driving your drivers do.
      </p>
      <p>
        We recommend selecting a shared email (eg, info@mybusiness.com.au) for
        the business account so that other or new employees can continue
        managing the account in future.
      </p>
      <p>
        Learn more about{' '}
        <a href="/business" className="font-bold text-primary">
          Business account
        </a>
      </p>
    </div>
  );
};

export default Guidelines;
