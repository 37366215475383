import React, { useLayoutEffect, useRef } from 'react';
import { CenteredPageTitle } from 'components/Typography';
import ContactUsIcon from 'assets/images/contact_us_icon.svg';
import MemberManualIcon from 'assets/images/member_manual_icon.svg';
import HelpfulVideosIcon from 'assets/images/helpful_videos_icon.svg';
import { selectPublicPending } from 'modules/public/slice';
import useAppSelector from 'hooks/useAppSelector';
import { IMAGE_URL } from 'common/constants';
import { InView } from 'react-intersection-observer';
import gsap from 'gsap';

const QuickLinks = () => {
  const isPublicPending = useAppSelector(selectPublicPending);

  const links = [
    {
      title: 'Helpful Videos',
      icon: HelpfulVideosIcon,
      linkTo: '/faq/helpful-videos',
    },
    {
      title: 'Member Manual',
      icon: MemberManualIcon,
      linkTo: '/faq/main?goto=member-manual',
    },
    // {
    //   title: 'The Flexicar App',
    //   icon: FlexicarAppIcon,
    //   linkTo: '#',
    // },
    {
      title: 'Contact Us',
      icon: ContactUsIcon,
      linkTo: '/faq/contact-us',
    },
  ];

  const component = useRef<any | null>(null);

  useLayoutEffect(() => {
    if (!isPublicPending) {
      const floatupchildren = document.querySelectorAll('.floatup');

      if (floatupchildren.length > 0) {
        component.current = gsap.timeline().to(floatupchildren, {
          y: 0,
          alpha: 1,
          duration: 0.3,
          stagger: 0.15,
          ease: 'circ.out',
        });
      }
    }
  }, [isPublicPending]);

  return (
    <div className="mx-[-40px] bg-[#F2F2F2] px-[40px] py-[80px] md:mx-[-120px] md:px-[90px] md:py-[120px] xl:px-[120px]">
      <CenteredPageTitle extraClass="!text-flexi-black-1 mb-[60px] md:mb-[100px]">
        Quick Links
      </CenteredPageTitle>
      <InView
        as="div"
        threshold={0.33}
        className="flex flex-col items-center justify-between lg:flex-row"
      >
        {links.map((item) => {
          return (
            <a
              key={item.title}
              href={item.linkTo}
              className="floatup font-primary mb-[30px] flex w-full items-center justify-center !gap-[30px] rounded-[20px] border-[1px] border-flexi-black-4 bg-white px-[20px] py-[15px] text-[16px] transition duration-700 hover:border-flexi-orange-1 hover:bg-flexi-orange-6 lg:w-[25vw] lg:w-[25vw] lg:text-[20px] xl:px-[25px] xl:py-[30px] xl:text-[26px]"
            >
              <img
                src={`${IMAGE_URL}${item.icon}`}
                className="h-[30px] w-auto lg:h-[40px] xl:h-[60px]"
              />
              {item.title}
            </a>
          );
        })}
      </InView>
    </div>
  );
};

export default QuickLinks;
