import React, { useLayoutEffect, useRef, useEffect } from 'react';
import { IContent, RequestStatusEnum } from 'common/types';
import { PageTitleBanner } from 'components/Typography';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  selectEnvType,
  selectPublicPending,
  setPagesContents,
  setPublicPending,
} from 'modules/public/slice';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import MemberManual from './MemberManual';
import SearchQuery from './SearchQuery';
import QuickLinks from './QuickLinks';
import TopFive from './TopFive';
import FAQs from './FAQs';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';

const Main = () => {
  const dispatch = useAppDispatch();
  const isPublicPending = useAppSelector(selectPublicPending);
  const envType = useAppSelector(selectEnvType);
  const queryParameters = new URLSearchParams(location.search);
  const goto = queryParameters.get('goto');

  const getPagesContents = async () => {
    const db = getFirestore(firebaseApp);
    const collectionName =
      envType === 'staging'
        ? 'pagesContentsQuestionsStaging'
        : 'pagesContentsQuestions';
    try {
      dispatch(setPublicPending(RequestStatusEnum.PENDING));
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);
      const data: IContent[] = [];

      querySnapshot.forEach(function (doc) {
        const parsedData = JSON.parse(JSON.stringify(doc.data()));
        data.push({
          question: parsedData.question,
          answer: parsedData.answer,
          callToAction: parsedData.call_to_action,
          image: parsedData.photo,
          embedVideoUrl: parsedData.embed_video_url,
          isTopFive: parsedData.is_top_five || false,
          categoryId: parsedData.category_id,
          contentId: Number(doc.id),
        });
      });

      if (data.length > 0) {
        dispatch(setPagesContents(data));
      } else {
        dispatch(setPagesContents(null));
      }
    } catch (error) {
      dispatch(setPublicPending(RequestStatusEnum.FAILED));
    } finally {
      dispatch(setPublicPending(RequestStatusEnum.SUCCESS));
    }
  };

  useEffect(() => {
    getPagesContents();
  }, [envType]);

  const contentListRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
    if (goto !== null && isPublicPending === RequestStatusEnum.SUCCESS) {
      const targetElem = document.getElementById(goto);
      if (targetElem !== null) {
        gsap.to(window, {
          scrollTo: { y: `#${goto}`, offsetY: 50 },
          duration: 0.6,
          delay: 0.5,
          ease: 'Circ.easeOut',
        });
      }
    }
  }, [goto, isPublicPending]);

  return (
    <section ref={contentListRef}>
      <Helmet>
        <title>Help & Support Centre</title>
        <meta name="description" content={`Help & Support Centre`} />
      </Helmet>
      <PageTitleBanner>help & support</PageTitleBanner>
      <div className="mx-auto min-h-[100vh] px-[40px] pt-[120px] md:px-[120px]">
        <SearchQuery />
        <QuickLinks />
        <TopFive />
        <FAQs />
        <MemberManual />
      </div>
    </section>
  );
};

export default Main;
