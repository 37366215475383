import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchWindcaveCardUrl,
  fetchWindcavePaymentUrl,
  fetchBilling,
  applyPromo,
  registerPayment,
  verifyPromo,
  removePromo,
  registerPaymentV2,
} from './sagas';

const {
  FETCH_WINDCAVE_CARD_URL,
  FETCH_WINDCAVE_PAYMENT_URL,
  FETCH_BILLING,
  APPLY_PROMO,
  REGISTER_PAYMENT,
  VERIFY_PROMO,
  REMOVE_PROMO,
  REGISTER_PAYMENT_V2,
} = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_WINDCAVE_CARD_URL, fetchWindcaveCardUrl),
  takeLatest(FETCH_WINDCAVE_PAYMENT_URL, fetchWindcavePaymentUrl),
  takeLatest(FETCH_BILLING, fetchBilling),
  takeLatest(APPLY_PROMO, applyPromo),
  takeLatest(REGISTER_PAYMENT, registerPayment),
  takeLatest(VERIFY_PROMO, verifyPromo),
  takeLatest(REMOVE_PROMO, removePromo),
  takeLatest(REGISTER_PAYMENT_V2, registerPaymentV2),
];

export default actions;
