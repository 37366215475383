import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import AddOns from './AddOns';
import Cancellation from './Cancellation';
import Credits from './Credits';
import Plan from './Plan';
import { selectUser, selectUserInfo, selectUserPlan } from 'modules/me/slice';
import MyAccountNav from 'components/MyAccountNav';
import { PageTitle } from 'components/Typography';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import useBusiness from 'hooks/useBusiness';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { IUserProfiles } from 'common/types';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';
import { useEffectOnce } from 'react-use';
import MyAccountBlock from 'components/MyAccountBlock';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import PlanSelection from './PlanSelection';

const MyPlanDetails = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    isBusinessAccount,
    isBusinessDriverAccount,
    isBusinessManagerAccount,
  } = useBusiness();
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const userPlan = useAppSelector(selectUserPlan);
  const userInfo = useAppSelector(selectUserInfo);
  const user = useAppSelector(selectUser);
  const [hasPersonalAccount, setHasPersonalAccount] = useState(false);
  const [isPlanSelectionOpen, setIsPlanSelectionOpen] = useState(false);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_USER_PLAN });
    dispatch({ type: ACTIONS.FETCH_EXPIRED_CREDITS });
    if (isBusinessManagerAccount) dispatch({ type: ACTIONS.FETCH_USER_INFO });
  }, [user]);

  useEffect(() => {
    if (user) {
      const found = user.profiles.filter((i1: IUserProfiles) => {
        return i1.type.toLocaleLowerCase() === 'personal';
      });
      setHasPersonalAccount(found.length > 0);
    }
  }, [user]);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  return (
    <article
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] flex flex-col gap-4 md:mt-[100px] lg:mx-0 mb-[30px] md:mb-[100px]"
    >
      {!isPlanSelectionOpen ? (
        <div>
          <Helmet>
            <title>My Account - Plan Details</title>
            <meta
              name="description"
              content="My Flexicar Account - Plan Details"
            />
          </Helmet>
          <MyAccountNav title="Plan Details" />
          <div className="gap-[100px] lg:flex">
            <section className="flex w-[84vw] flex-col lg:max-w-[500px]">
              <PageTitle>Plan Details</PageTitle>

              <Plan
                plan={userPlan?.plan}
                name={userPlan?.name}
                accountType={user?.type}
                setIsPlanSelectionOpen={setIsPlanSelectionOpen}
              />
              {!isBusinessAccount && <Credits credits={userPlan?.credits} />}
              {!isBusinessDriverAccount ? (
                <>
                  <AddOns />
                  <div className="hidden md:block">
                    <Cancellation />
                  </div>
                </>
              ) : (
                <div className="mt-[20px]">
                  <ul className="mb-[50px] flex flex-col gap-[20px]">
                    <li>
                      <strong className="block text-[16px] font-bold">
                        Business Name
                      </strong>
                      <span>{userInfo?.businessName}</span>
                    </li>
                    <li>
                      <strong className="block text-[16px] font-bold">
                        Business Address
                      </strong>
                      <span>{userInfo?.residentialAddress}</span>
                    </li>
                    <li>
                      <strong className="block text-[16px] font-bold">
                        Business Country
                      </strong>
                      <span>{userInfo?.licenseCountry}</span>
                    </li>
                  </ul>
                  {!hasPersonalAccount && (
                    <>
                      <p className="mb-[25px]">
                        You are currently under a business plan.
                      </p>
                      <p>
                        If you wish to use Flexicar for your personal trips,{' '}
                        <span
                          className="cursor-pointer font-bold text-flexi-orange-1"
                          onClick={() => push('/app/add-account')}
                        >
                          click here
                        </span>{' '}
                        to create a personal account
                      </p>
                    </>
                  )}
                </div>
              )}
            </section>

            {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
            <div className="block md:hidden">
              <Cancellation />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>My Account - Plan Details</title>
            <meta
              name="description"
              content="My Flexicar Account - Plan Details"
            />
          </Helmet>
          <div className="absolute top-[-40px] flex items-center gap-[20px] text-[12px] md:top-[-60px] md:gap-[27px] md:text-[14px]">
            <NavLink to={`/app/account`}>
              <p>
                {user?.type.name.toLocaleLowerCase() === 'business'
                  ? 'My Business'
                  : 'Account'}
              </p>
            </NavLink>
            <IconArrow className="rotate-[-90deg] fill-flexi-black-3 opacity-20" />
            <p className="capitalize cursor-pointer" onClick={() => setIsPlanSelectionOpen(false)}>
              Plan Details
            </p>
            <IconArrow className="rotate-[-90deg] fill-flexi-black-3 opacity-20" />
            <p className="font-bold capitalize">Plan Selection</p>
          </div>
          <div className="gap-[100px] lg:flex">
            <section className="flex w-[84vw] flex-col lg:max-w-[500px]">
                <PageTitle>Plan Selection</PageTitle>
                <div>
                  <PlanSelection />
                </div>
            </section>

            {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
            <div className="block md:hidden">
              <Cancellation />
            </div>
          </div>
        </>
      )}
    </article>
  );
};

export default MyPlanDetails;
