import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectBusinessDrivers,
  selectBusinessInvitees,
  selectFetchDriverStatus,
  selectFetchInviteesStatus,
  selectInviteDriverStatus,
  selectRemoveDriversStatus,
} from 'modules/business/slice';
import { UserRoleEnum } from 'common/types';

const useBusiness = () => {
  const user = useAppSelector(selectUser);
  const isBusinessAccount = user?.type.name.toLocaleLowerCase().includes('business');
  const isBusinessManagerAccount =
    user?.type.name.toLocaleLowerCase().includes('business') && user?.role === UserRoleEnum.MANAGER;
  const isBusinessDriverAccount =
    user?.type.name.toLocaleLowerCase().includes('business') && user?.role === UserRoleEnum.DRIVER;
  const businessDrivers = useAppSelector(selectBusinessDrivers);
  const businessDriversStatus = useAppSelector(selectFetchDriverStatus);
  const businessInvitees = useAppSelector(selectBusinessInvitees);
  const businessInviteesStatus = useAppSelector(selectFetchInviteesStatus);
  const inviteDriverStatus = useAppSelector(selectInviteDriverStatus);
  const removeDriverStatus = useAppSelector(selectRemoveDriversStatus);

  return {
    isBusinessAccount,
    isBusinessDriverAccount,
    isBusinessManagerAccount,
    businessDrivers,
    businessDriversStatus,
    businessInvitees,
    businessInviteesStatus,
    inviteDriverStatus,
    removeDriverStatus,
  };
};

export default useBusiness;
