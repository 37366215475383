import React from 'react';
// import { IPlan } from 'common/constants';
import { ReactComponent as IconMonthZero } from 'assets/images/plan_monthly_zero.svg';
import { ReactComponent as IconMonth } from 'assets/images/plan_monthly.svg';
import { ReactComponent as IconHour } from 'assets/images/plan_hourly.svg';
import { ReactComponent as IconDay } from 'assets/images/plan_daily.svg';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import { Link } from 'react-router-dom';
import { IRegisterPlan } from 'common/types';
interface Props {
  plan: IRegisterPlan | null;
}

const Card = ({ plan }: Props) => {
  const user = useAppSelector(selectUser);

  return (
    <article className="flex flex-col items-start ">
      <div className="my-[25px] md:my-[50px] flex items-center gap-[10px] md:gap-[20px]">
        <h3 className="text-center text-[16px] md:text-[26px] font-[700] capitalize">Flexi {plan?.name.toLocaleLowerCase().replace(`${user?.type?.name}`,'')}</h3>
        {user?.plan?.id === plan?.id && (
          <p className="grid h-[20px] w-auto px-[10px] md:w-[102px] place-items-center rounded-full bg-flexi-black-5 text-[8px] md:text-[10px] font-bold uppercase">
            current plan
          </p>
        )}
      </div>
      <div className="flex items-center gap-x-[20px]">
        {
          plan?.name === 'sometimes' || plan?.name === 'business' ? (
            <IconMonthZero className="md:min-h-[40px] md:min-w-[40px] min-h-[20px] min-w-[20px] h-[20px] md:h-[40px] md:w-[40px] w-[20px]" />
          ) : (
            <IconMonth className="md:min-h-[40px] md:min-w-[40px] min-h-[20px] min-w-[20px] h-[20px] md:h-[40px] md:w-[40px] w-[20px]" />
          )
        }
        <div>
          <p className="mb-[10px] text-[16px] md:text-[20px] font-bold">
            {plan?.name === 'sometimes' || plan?.name === 'business'
              ? 'No Commitment'
              : 'Monthly Commitment'}
          </p>
          <p className="text-[14px] md:text-[16px] w-[calc(100vw-200px)] md:w-auto">{plan?.commitment}</p>
        </div>
      </div>
      
      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconDay className="md:min-h-[40px] md:min-w-[40px] min-h-[20px] min-w-[20px] h-[20px] md:h-[40px] md:w-[40px] w-[20px]" />
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[16px] md:text-[20px] font-bold">Daily Cost</p>
          <p className="text-[14px] md:text-[16px]">{plan?.dailyNotes}</p>
        </div>
      </div>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconHour className="md:min-h-[40px] md:min-w-[40px] min-h-[20px] min-w-[20px] h-[20px] md:h-[40px] md:w-[40px] w-[20px]" />
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[16px] md:text-[20px] font-bold">Hourly Cost</p>
          <p className="text-[14px] md:text-[16px]">{plan?.hourlyNotes}</p>
        </div>
      </div>

      <p className="my-[15px] md:my-[30px] rounded-[10px_10px_10px_10px] p-[10px] w-[calc(100vw-175px)] md:w-auto md:p-[20px] text-[10px] md:text-[12px] shadow-[0_2px_9px] shadow-flexi-white-2 ">
        {plan?.disclaimer}
        <Link className="font-bold text-flexi-orange-1" to="/terms-and-conditions" target="_blank">click here</Link>.
      </p>
    </article>
  );
};

export default Card;
