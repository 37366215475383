import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import auth from 'modules/auth/slice';
import me from 'modules/me/slice';
import ocr from 'modules/ocr/slice';
import transaction from 'modules/transaction/slice';
import vehicle from 'modules/vehicle/slice';
import booking from 'modules/booking/slice';
import favorites from 'modules/favorites/slice';
import pods from 'modules/pods/slice';
import invoice from 'modules/invoice/slice';
import business from 'modules/business/slice';
import announcement from 'modules/announcement/slice';
import frontend from 'modules/frontend/slice';
import publicData from 'modules/public/slice';
import { browserHistory } from './history';

export default combineReducers({
  auth,
  me,
  ocr,
  transaction,
  vehicle,
  booking,
  favorites,
  pods,
  invoice,
  business,
  announcement,
  frontend,
  publicData,
  router: connectRouter(browserHistory),
});
