import React from 'react';
import PageLayout from 'components/layouts/PageLayout';
import { GuestTopNav } from 'components/TopNav';
// import { createPortal } from 'react-dom';
import firebaseApp from 'app/firebaseApp';
import { getFirestore, doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';

const OcrLoadingPage = () => {
  const [value, loading, error] = useDocument(
    doc(getFirestore(firebaseApp), 'ocr', 'f00f412d3c0da26f66c3149eb8d8beb7'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  // const topNav = document.getElementById('topnav');

  return (
    <PageLayout>
      <GuestTopNav />
      {/* {topNav ?
        createPortal(
          <GuestTopNav/>,
          topNav)
      :<></>} */}
      <p>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Collection: Loading...</span>}
        {value && (
          <span>
            <span>Document: {JSON.stringify(value.data())}</span>
          </span>
        )}
      </p>
    </PageLayout>
  );
};

export default OcrLoadingPage;
