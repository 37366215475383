import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import gsap from 'gsap';

import ocrDeclinedJson from 'assets/json/icon-ocr-declined.json';
import cancelMembershipJson from 'assets/json/icon-cancel-membership.json';
import { ReactComponent as Mail } from 'assets/images/envelope-solid.svg';
import { ReactComponent as Phone } from 'assets/images/phone-solid.svg';
import { RequestStatusEnum } from 'common/types';
import {
  selectOcrAttempt,
  selectOcrAttemptStatus,
  selectOcrRetryStatus,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';

const SignUpVerificationFailed = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const component = useRef<HTMLDivElement>(null);
  const ocrAttempt = useAppSelector(selectOcrAttempt);
  const ocrAttemptStatus = useAppSelector(selectOcrAttemptStatus);
  const ocrRetryStatus = useAppSelector(selectOcrRetryStatus);
  const [timer, setTimer] = useState(59);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const ocrDeclinedJsonOptions = {
    loop: false,
    autoplay: true,
    animationData: ocrDeclinedJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const biometricsFailedJsonOptions = {
    loop: false,
    autoplay: true,
    animationData: cancelMembershipJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_OCR_ATTEMPT });
  });

  useEffect(() => {
    if (
      ocrAttemptStatus === RequestStatusEnum.SUCCESS &&
      ocrAttempt?.overallStatus === 'PENDING'
    ) {
      push(`/register-v2/ocr`);
    }
  }, [ocrAttempt, ocrAttemptStatus]);

  useEffect(() => {
    const mySecondInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(mySecondInterval);
    };
  });

  const handleOcrRetry = () => {
    dispatch({ type: ACTIONS.RETRY_OCR });
  };

  return (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-90px)] w-full max-w-[700px] flex-col pt-[43px] md:min-h-[calc(100vh-388px)]"
    >
      {ocrAttemptStatus === RequestStatusEnum.PENDING ? (
        <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
          <PendingLoader size={100} />
        </section>
      ) : ocrAttempt && ocrAttempt?.retriesRemaining > 0 ? (
        <>
          <div className="flex h-[200px] w-full items-center justify-center">
            {!ocrAttempt.face || !ocrAttempt.liveness ? (
              <div className="flex h-[200px] w-full items-center justify-center">
                <Lottie
                  options={biometricsFailedJsonOptions}
                  height={200}
                  width={200}
                />
              </div>
            ) : (
              <div className="flex h-[200px] w-full items-center justify-center">
                <Lottie
                  options={ocrDeclinedJsonOptions}
                  height={200}
                  width={200}
                />
              </div>
            )}
          </div>
          <h2 className="font-primary mb-[36px] max-w-[550px] text-center text-[30px] font-[900] uppercase leading-[32px] text-flexi-orange-1 md:text-[40px] md:leading-[50px]">
            sorry. your{' '}
            {!ocrAttempt.face || !ocrAttempt.liveness
              ? 'biometrics'
              : 'License ID'}{' '}
            failed.
          </h2>
          <div className="mb-[32px] grow md:grow-0">
            <p className="text-center text-[14px] md:text-[16px]">
              Unfortunately, there seem to be a problem with your{' '}
              {!ocrAttempt.face || !ocrAttempt.liveness
                ? 'biometrics'
                : 'License ID'}{' '}
              and we are unable to proceed with your application as per our{' '}
              <a
                href="/terms-and-conditions"
                className="whitespace-nowrap font-bold text-flexi-orange-1"
              >
                Terms & Conditions
              </a>
              . Please try again.
            </p>
          </div>
          <div className="mt-auto pt-[40px]">
            <p className="mb-[15px] text-center text-[14px] md:mb-[25px] md:text-[16px]">
              Please wait 00:{timer > 9 ? timer : `0${timer}`}s
            </p>
            <button
              className={classNames(
                'btn btn-primary h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]',
                {
                  loading: ocrRetryStatus === RequestStatusEnum.PENDING,
                },
              )}
              onClick={handleOcrRetry}
              disabled={timer > 0 || ocrAttempt.overallStatus === 'PENDING'}
            >
              Reverify{' '}
              {!ocrAttempt.face || !ocrAttempt.liveness
                ? 'biometrics'
                : 'License ID'}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex h-[200px] w-full items-center justify-center">
            <Lottie options={ocrDeclinedJsonOptions} height={200} width={200} />
          </div>
          <h2 className="font-primary mb-[36px] max-w-[550px] text-center text-[30px] font-[900] uppercase leading-[32px] text-flexi-orange-1 md:text-[40px] md:leading-[50px]">
            sorry. your application has been denied.
          </h2>
          <div className="mb-[32px] grow md:grow-0">
            <p className="text-center text-[14px] md:text-[16px]">
              Unfortunately, the internal checks we run to approve your account
              have not been successful and we are unable to proceed with your
              application as per our{' '}
              <a
                href="/terms-and-conditions"
                className="whitespace-nowrap font-bold text-flexi-orange-1"
              >
                Terms & Conditions
              </a>
              .
              <br />
              <br />
              Any successful payments made have been refunded to the same credit
              card they were charged against.
              <br />
              <br />
              For membership information
              <br className="block md:hidden" />
              please contact us at:
              <br />
              <br />
            </p>
            <div className="mx-auto flex w-fit items-center gap-[16px]">
              <Phone className="h-[14px] fill-[#FF7800]" />
              <a
                href="tel:1300363780"
                className="text-[14px] font-bold text-primary"
              >
                1300 36 37 80
              </a>
            </div>
            <div className="mx-auto flex w-fit items-center gap-[16px]">
              <Mail className="h-[14px] fill-[#FF7800]" />
              <a
                href="mailto:info@flexicar.com.au"
                className="text-[14px] font-bold text-primary"
              >
                info@flexicar.com.au
              </a>
            </div>
          </div>
          <div className="mt-auto pt-[40px]">
            <button
              className="btn btn-primary h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]"
              onClick={() => {
                if (isMobile) {
                  const payload = { push: '/our-cars' };
                  dispatch({ type: ACTIONS.LOGOUT_ACCOUNT, payload });
                } else {
                  dispatch({ type: ACTIONS.LOGOUT_ACCOUNT });
                }
              }}
            >
              Okay
            </button>
          </div>
        </>
      )}
    </section>
  );
};

export default SignUpVerificationFailed;
