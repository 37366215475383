import React, { useEffect, useState } from 'react';

import { addDays } from 'date-fns';
import classNames from 'classnames';
import moment from 'moment';

import { selectEndDate, selectStartDate } from 'modules/booking/slice';
import useAppSelector from 'hooks/useAppSelector';
import useBooking from 'hooks/useBooking';

function Header() {
  const { isMapView } = useBooking({});
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const dayDifference = moment(moment(endDate).startOf('day')).diff(moment(startDate).startOf('day'), 'days') + 1;
  const [dateArray, setDateArray] = useState<any>([]);
  const [selectedDuration, setSelectedDuration] = useState<number>(1);
  const [selectedDurationStart, setSelectedDurationStart] = useState<number>(0);

  useEffect(() => {
    if (startDate && endDate) {
        const finalLengthOfDates = dayDifference;
        const populatedDates = [...new Array(Math.abs(finalLengthOfDates))].map((_, i) => moment(addDays(startDate,i)).format('MMM/DD/YYYY'));
        setDateArray(populatedDates);
        const startOfStartDay = moment(startDate).set({'hour' : 0, 'minute' : 0});
        setSelectedDuration(moment(endDate).diff(startDate, 'minutes') / 60);
        setSelectedDurationStart(moment(startDate).diff(startOfStartDay, 'minutes') / 60);
    }

  }, [startDate, endDate])

  const getDate = (i: number) => {
      const newdate = dateArray[i];
      const [mm, dd, yyyy] = newdate.split('/');
      const dateFormat = [mm, dd, yyyy].join('/');
      const date = moment(dateFormat);
      const dayValue = date.format('DD');
      const day = date.format('ddd');
      return (
        <div>
          <p className="relative text-[10px] font-normal text-zinc-400 text-center">
            {day}
          </p>
          <p className="mt-[5px] relative text-[16px] text-center">
            {dayValue}
          </p>
          <div className='mt-[17.5px] w-[1px] h-[11px] bg-transparent mx-auto'>
          </div>
        </div>
      );
  };

  return (
    <section
      id="availability-header"
      className="!pointer-events-none flex h-[92px] w-[calc(100vw-495px)] translate-x-[495px] items-end border-b-[1px] border-zinc-500 bg-white text-center text-[11px] font-bold duration-200 before:absolute before:left-[-23.5px] before:h-full before:w-[50px] before:border-b-[1px] before:border-white before:bg-white before:shadow-[0_1px] before:shadow-zinc-500"
    >
      {dateArray?.map((_: any, i: number) => {
        return (
          <div
            key={i}
            className={classNames(`relative flex h-[25px] flex-col`)}
            style={{
              width: `${(window.innerWidth - 520) / dayDifference}px`,
            }}
          >
            <div className="sticky left-[0px] flex w-fit translate-x-[-50%] font-bold">
              <div className="absolute bottom-[-25px] left-[-7px] w-fit text-center">
                {getDate(i)}
              </div>
            </div>
            <div className="mx-auto mt-[20px] hidden h-[6px] w-[2px] bg-[#282828]" />
            <div className="border-r-[1px] absolute left-[calc(50%)] top-[100%] h-[100vh] w-[1px] border-dashed border-[#DADADA]" />
            <div className="absolute top-[100%] h-[100vh] w-[3px] border-r-[1px] border-dashed border-[#DADADA]" />
            <div
              className={classNames("absolute top-[8px] left-[2px] flex w-fit justify-between text-center", {
                hidden: dayDifference > 2
              })}
              style={{
                width: `${(window.innerWidth - 582) / dayDifference}px`,
              }}
            >
              {[...new Array(24)].map((_, j) => {
                const renderHour = j % 12;
                return (
                  <div
                    key={`header__${i}__hour__${j}`}
                    className="flex flex-col items-center justify-center"
                  >
                    <span
                      className={classNames(
                        'absolute top-[-12px] text-center text-[8px]',
                        {
                          'font-bold': renderHour === 0,
                          'text-primary':
                            moment(startDate).get('hour') === j ||
                            moment(endDate).get('hour') === j,
                        },
                      )}
                    >
                      {j === 0 ? '12AM' : j === 12 ? '12PM' : renderHour}
                    </span>
                    <div
                      className={classNames(
                        'mx-auto mt-[17.5px] h-[100vh] w-[1px] border-r-[1px] border-dashed border-[#DADADA]',
                        {
                          'border-transparent':
                            (renderHour !== 0 &&
                              renderHour !== 6 &&
                              dayDifference === 2) ||
                            (renderHour !== 0 && dayDifference > 2),
                        },
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {endDate && (
        <div className="absolute right-[16px] top-[19px]">
          <p className="relative text-center text-[10px] font-normal text-zinc-400">
            {moment(addDays(endDate, 1)).format('ddd')}
          </p>
          <p className="relative mt-[5px] text-center text-[16px]">
            {moment(addDays(endDate, 1)).format('DD')}
          </p>
        </div>
      )}
      <div className="absolute right-[23px] top-[100%] h-[100vh] w-[1px] border-r-[1px] border-dashed border-[#DADADA]" />
      <span
        className="absolute left-0 h-[5px] bg-flexi-orange-1"
        style={{
          left: `${
            1 +
            (selectedDurationStart * (window.innerWidth - 520)) /
              dayDifference /
              24
          }px`,
          width: `${
            ((window.innerWidth - 520) / dayDifference) *
            (selectedDuration / 24)
          }px`,
        }}
      />
      <span
        className={classNames(
          '!font-regular absolute bottom-[23px] left-0 h-[5px] whitespace-nowrap text-[8px] text-[#FF931E]',
          {
            'translate-x-[-75%]': selectedDuration < 1.5,
            'translate-x-[-50%]':
              selectedDuration >= 1.5 && selectedDuration < 2.25,
            '!text-white': isMapView,
            hidden: dayDifference < 3,
          },
        )}
        style={{
          left: `${
            1 +
            (selectedDurationStart * (window.innerWidth - 520)) /
              dayDifference /
              24
          }px`,
        }}
      >
        {moment(startDate).format('h:mm A')}
      </span>
      <span
        className={classNames(
          '!font-regular absolute bottom-[23px] left-0 h-[5px] translate-x-[-100%] whitespace-nowrap text-[8px] text-[#FF931E]',
          {
            'translate-x-[-25%]': selectedDuration < 1.5,
            'translate-x-[-50%]': selectedDuration < 2.25,
            hidden: dayDifference < 3,
          },
        )}
        style={{
          left: `${
            1 +
            ((selectedDuration + selectedDurationStart) *
              (window.innerWidth - 520)) /
              dayDifference /
              24
          }px`,
        }}
      >
        {moment(endDate).format('h:mm A')}
      </span>
      <div className="mr-auto mt-[17.5px] h-[11px] w-[1px] bg-[#282828]"></div>
    </section>
  );
}

export default Header;