import api from 'app/apiClient';
import { call, cancelled, put } from 'redux-saga/effects';
import { setAnnouncement, setAnnouncementStatus } from './slice';
import { RequestStatusEnum } from 'common/types';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';

export const SAGA_ACTIONS = {
  FETCH_ANNOUNCEMENT: 'FETCH_ANNOUNCEMENT',
}

export function* fetchAnnouncement ():any {
  try {
    const { data } = yield call(() =>
      api.get(`/announcement/get-running`)
    );
    yield put(setAnnouncement(data.data));
    yield put(setAnnouncementStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setAnnouncementStatus(RequestStatusEnum.FAILED));
    }
  } finally{
    if (yield cancelled()) {
      yield put(setAnnouncementStatus(RequestStatusEnum.FAILED));
    }
  }
}