import classNames from 'classnames';
import React from 'react';

interface Props {
  title?: string;
  children: React.ReactNode;
  className?: string;
  error?: any;
  disabled?: any;
  margin?: number;
  positionTitle?: string;
  width?: string;
  extraClass?: string;
}

const CustomInput = ({
  title,
  children,
  error,
  disabled=false,
  margin = 0,
  width,
  extraClass,
  positionTitle
}: Props) => {
  return (
    
    <div
      className={classNames(
        `[&>input]:text-[12px] [&>input]:md:text-[14px] [&>textarea]:!border-t-tranparent [&>input]:!border-t-tranparent relative flex h-[50px] w-full items-center
      !bg-transparent md:h-[60px] [&>input]:!self-end [&>input]:!outline-none [&>textarea]:pointer-events-auto [&>textarea]:!self-end  
      [&>select]:pointer-events-auto [&>select]:!self-end [&>select]:!outline-none [&>.react-tel-input]:pointer-events-auto [&>.react-tel-input]:!self-end
      [&>select:disabled]:border-flexi-black-7 [&>select:disabled]:!border-t-transparent [&>select:disabled]:!bg-white [&>input:disabled]:!border-flexi-black-7 [&>input:disabled]:!bg-white
      mt-[${
        margin + 0
      }px] w-[${width}px] ${extraClass} [&>*]:placeholder:text-transparent [&>*]:disabled:placeholder:!bg-red-400`,
        {
          'opacity-50 pointer-events-none': disabled,
        },
      )}
    >
      <p
        className={classNames(
          `pointer-events-none absolute left-0 top-0 h-[20px]  w-full rounded-tr-lg rounded-tl-lg !border-[1px] border-zinc-300 !border-b-transparent bg-white delay-[75ms]`,
          {
            '!border-red-500 !border-b-transparent': error,
          },
        )}
      ></p>
      {children}
      <label
        htmlFor={title}
        className={classNames(
          `pointer-events-none absolute left-[17px] top-[7px] z-[10] text-[9px] text-flexi-black-2 opacity-[.6] duration-200 md:top-[9px] 
          peer-placeholder-shown:top-[15px] peer-placeholder-shown:md:top-[18px] peer-placeholder-shown:text-[14px] peer-placeholder-shown:md:text-[16px] peer-focus:top-[7px] peer-focus:text-[9px] peer-focus:md:top-[9px] peer-focus:md:text-[9px]
          ${positionTitle}`
        )}
      >
        {title}
      </label>
    </div>
  );
};

export default CustomInput;
