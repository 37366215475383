import React, { useState, useRef, useLayoutEffect } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { useEffectOnce, useUnmount } from 'react-use';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import PaymentInfo from './PaymentInfo';
import PaymentMethod from './PaymentMethod';
import PaymentPromotions from './PaymentPromotions';
import { selectBilling, setApplyPromoStatus } from 'modules/transaction/slice';
import PayAndFinish from './PayAndFinish';
import {
  selectPaymentCards,
  selectUser,
  selectUserPlan,
  selectUserPlanStatus,
  selectUserStatus,
} from 'modules/me/slice';
import { Link } from 'react-router-dom';
import CustomCheckBox from 'components/CustomCheckbox';
import useBusiness from 'hooks/useBusiness';
import classNames from 'classnames';
import gsap from 'gsap';
import { RequestStatusEnum } from 'common/types';
import LoaderText from 'components/LoaderText';
import CustomInput from 'components/CustomInput';

const RegisterPayment = () => {
  const { isBusinessAccount } = useBusiness();
  const [referralEmail] = useState<string | undefined>('');
  const [vffMembershipId, setVffMembershipId] = useState<string | undefined>(
    '',
  );
  const [partnerMembershipId, setPartnerMembershipId] = useState<
    string | undefined
  >('');
  const [accessCode, setAccessCode] = useState<string | undefined>('');
  const [partner, setPartner] = useState<string | undefined>('');
  const [hasReduceExcessDamageCover, setHasReduceExcessDamageCover] = useState<
    string | undefined
  >(undefined);
  const [referralEmailError] = useState<string | null>(
    null,
  );
  const [partnerMembershipError, setPartnerMembershipError] = useState<
    string | null
  >(null);
  const dispatch = useAppDispatch();
  const billing = useAppSelector(selectBilling);
  const paymentCards = useAppSelector(selectPaymentCards);
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus);
  const isPersonalAccount = user?.type.name.toLocaleLowerCase() === 'personal';
  const userPlanStatus = useAppSelector(selectUserPlanStatus);

  const allStatusPending = userStatus === RequestStatusEnum.PENDING || userPlanStatus === RequestStatusEnum.PENDING

  const handleCheckBox = (e: any) => {
    setHasReduceExcessDamageCover(e.target.checked ? 'on' : '');
  };
  const userPlan = useAppSelector(selectUserPlan);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_BILLING });
    dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
    dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
    dispatch({ type: ACTIONS.FETCH_REWARD_PARTNERS });
    dispatch({ type: ACTIONS.FETCH_USER_PLAN });
  });

  useUnmount(() => {
    dispatch(setApplyPromoStatus(null));
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex w-full  max-w-[700px] flex-col items-center md:items-start"
    >
      <h2 className="font-primary mb-[30px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:mb-[40px] md:text-[40px]">
        Payment
      </h2>

      <div className="mb-[20px] flex w-full items-center justify-between  border-b-[1px] pb-[20px] md:mb-[50px] md:pb-[50px]">
        {allStatusPending ? (
          <LoaderText height="90" width='50%' />
        ) : (
          <div>
            <p
              className={classNames(
                'text-[16px] font-bold capitalize md:text-[30px]',
                {
                  'mb-[15px]':
                    !isBusinessAccount &&
                    user?.type.name.toLocaleLowerCase() !== 'student',
                },
              )}
            >
              {isPersonalAccount
                ? `${user?.type.name} - Flexi ${user?.plan?.name
                  .toLocaleLowerCase()
                  .replaceAll(`${user.type.name}`, '')}`
                : `Flexi ${user?.type.name}`}
            </p>
            {!isBusinessAccount &&
              user?.type.name.toLocaleLowerCase() !== 'student' && (
                <Link
                  to="/register/plan"
                  className="italize text-[16px] font-bold text-flexi-orange-1 md:text-[16px]"
                >
                  Change plan
                </Link>
              )}
          </div>
        )}
        {!allStatusPending && (
          <p className="self-start text-[40px] font-[400] tracking-[-3px] md:text-[60px]">
            ${userPlan?.plan.annualFee || userPlan?.plan.fee.toFixed()}
          </p>
        )}
      </div>
      <div className="mb-14 flex w-full gap-14 pb-[44px]">
        <div className="flex w-full flex-col">
          <div className="mb-[30px] flex flex-col items-start">
            <p className="mb-[20px] text-[20px] md:text-[22px]">Add-ons</p>
            <CustomCheckBox
              size={10}
              titleElement={
                <p className=" max-w-[70vw] text-[14px]">
                  Reduce the excess on your damage cover &#40;
                  <a
                    href="/terms-and-conditions"
                    target="__blank"
                    className="font-bold text-flexi-orange-1"
                  >
                    T&C&apos;s
                  </a>{' '}
                  and additional fees apply&#41;.
                </p>
              }
            >
              <input
                type="checkbox"
                className="checkbox h-[20px] w-[20px] rounded-[5px_5px_5px_5px] !bg-white"
                // @ts-ignore
                style={{ '--chkfg': '0', '--chkbg': '0' }}
                onChange={(e) => handleCheckBox(e)}
              />
            </CustomCheckBox>
          </div>
          {
            allStatusPending ?
              <LoaderText height='30px' width='100px' /> :
              !isBusinessAccount && (
                <>
                  <PaymentPromotions
                    vffMembershipId={vffMembershipId}
                    setVffMembershipId={setVffMembershipId}
                    partnerMembershipId={partnerMembershipId}
                    setPartnerMembershipId={setPartnerMembershipId}
                    accessCode={accessCode}
                    setAccessCode={setAccessCode}
                    partner={partner}
                    billing={billing}
                    setPartner={setPartner}
                    partnerMembershipError={partnerMembershipError}
                    setPartnerMembershipError={setPartnerMembershipError}
                  />
                  <div className="mb-8"></div> {/* Add space between components */}
                  <PaymentInfo billing={billing} />
                </>
              )
          }

          {
            isBusinessAccount &&
            <div className="form-control w-full gap-[15px] md:gap-[20px] mb-[30px]">
              <label className="text-[20px] font-bold md:text-[22px] md:font-normal">
                Access Code
              </label>
              <CustomInput title="Access Code">
                <input
                  placeholder="velocity number"
                  type="text"
                  className={classNames(
                    'peer input input-bordered w-full focus:outline-none',
                  )}
                  // defaultValue={registrationVFF || vffMembershipId}
                  value={accessCode}
                  onChange={(e) => setAccessCode(e.target.value)}
                  // onBlur={handleAccessCodeBlur}
                  autoComplete="off"
                />
              </CustomInput>
            </div>
          }
          <PaymentMethod paymentCards={paymentCards} />

          <PayAndFinish
            referralEmail={referralEmail}
            vffMembershipId={vffMembershipId}
            accessCode={accessCode}
            partner={partner}
            partnerMembershipId={partnerMembershipId}
            hasReduceExcessDamageCover={hasReduceExcessDamageCover}
            referralEmailError={referralEmailError}
            setPartnerMembershipError={setPartnerMembershipError}
          />
        </div>
      </div>
    </section>
  );
};

export default RegisterPayment;
