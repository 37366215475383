import React from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  setLoginModalOpen,
} from 'modules/auth/slice';

export const BottomSignIn = () => {
  const dispatch = useAppDispatch();

  return(
    <>
      <div className="block lg:hidden">
        <button
          onClick={() => dispatch(setLoginModalOpen(true))}
          className="!text-[14px] h-[40px] min-w-[100px] rounded-full bg-flexi-green-1 font-bold text-white"
        >
          Sign in
        </button>
      </div>
    </>
  )
}