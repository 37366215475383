import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
// import { Link } from 'react-router-dom';
import RadioGroup from './RadioGroup';
import DefaultForm from './DefaultForm';
import BusinessForm from './BusinessForm';
import useQuery from 'hooks/useQuery';
import { useEffectOnce } from 'react-use';
import { isEmpty } from 'lodash';
import useAppSelector from 'hooks/useAppSelector';
import { selectTokenInfo, setAbrLookup } from 'modules/auth/slice';
import { reloadPageOnce, removeToken } from 'common/helpers';
import gsap from 'gsap';
import useAppDispatch from 'hooks/useAppDispatch';

const RegisterForm = () => {
  const [accountType, setAccountType] = useState<number>(1);
  const { params } = useQuery<{ accountType?: string; token?: string }>();
  const tokenInfo = useAppSelector(selectTokenInfo);
  const isBusinessDriver = false;
  const dispatch = useAppDispatch();
  const component = useRef<HTMLDivElement>(null);
  reloadPageOnce()

  useEffect(() => {
    dispatch(setAbrLookup(null))
  }, [accountType])

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5
    });
  }, []);

  useEffectOnce(() => {
    removeToken();
    if (!isEmpty(params.accountType))
      setAccountType(Number(params.accountType));
    else setAccountType(1);
  });

  useEffect(() => {
    if (isBusinessDriver) {
      setAccountType(5);
    }
  }, []);

  return (
    <>
      <section ref={component} className="fadein relative m-auto md:ml-[50%] w-full max-w-[675px] rounded-3xl bg-flexi-white-1 p-[20px] sm:p-[80px] text-[16px] shadow-[0px_3px_10px_rgba(0,0,0,0.04)]">
        <h2 className="font-primary mb-[40px] md:mb-[50px] text-center md:text-left text-[30px] sm:text-[40px] md:text-[60px] font-[900] uppercase sm:leading-[70px] leading-[36px] text-flexi-orange-1">
          Sign up to Flexicar
        </h2>
        <RadioGroup accountType={accountType} setAccountType={setAccountType} />
        <div className="sm:my-[50px] my-[25px] text-[12px] sm:text-[16px]">
          {accountType === 1 && (
            <p className={`overflow-hidden`}>
              The annual fee will be returned as driving credit. <br />
              <br />
              You can choose a plan depending on your needs. Whether you&apos;re a
              first time car sharer or need a second car for a regular basis,
              errand or for&nbsp;fun.
              {/* <br /><br />
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
            voluptate illum rem minima ut nemo fugit impedit ducimus alias ullam
            id possimus perspiciatis, voluptas voluptatum!! */}
            </p>
          )}
          {accountType === 3 && (
            // <p className={` overflow-hidden duration-200`}>
            //   Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
            //   voluptate illum rem minima ut nemo fugit impedit ducimus alias ullam
            //   id possimus perspiciatis, voluptas voluptatum!
            // </p>
            <></>
          )}
          {accountType === 2 && (
            <p className={` overflow-hidden duration-200`}>
              Create your business account and invite your colleagues to
              become drivers immeadiately after signing up. It is completely free
              to create a business account and you only pay for the driving your
              drivers do. We recommend selecting a shared email &#40;eg,
              info@mybusiness.com.au&#41; for the business account so that other or new
              employees can continue managing the account in&nbsp;future.
            </p>
          )}
          {accountType === 5 && (
            <p className={` overflow-hidden duration-200`}>
              You are signing up as a business driver for{' '}
              <span className="font-bold">{tokenInfo?.businessName}</span>.
            </p>
          )}
        </div>
        {/* {accountType !== 5 && (
        <p className="mb-[30px] text-[12px] sm:text-[16px]">
          Learn more about{' '}
          <Link
            to={`/account-types#${accountType === 1
              ? 'personal'
              : accountType === 2
              ? 'business'
              : 'student'}`}
            className="font-extrabold text-flexi-orange-1"
          >
            {accountType === 1
              ? 'Personal'
              : accountType === 2
              ? 'Business'
              : 'Student'}{' '}
            accounts
          </Link>
        </p>
      )} */}
        {accountType === 2 ? (
          <BusinessForm accountType={accountType} />
        ) : (
          <DefaultForm accountType={accountType} token={params?.token} />
        )}
      </section>
    </>
  );
};

export default RegisterForm;
