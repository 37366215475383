import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IPod, IPodAll, IPodOptimized, IPodVehicle, IPodVehicleBasic, IPodVehicleOptimized, RequestStatusEnum } from 'common/types';

type InitialState = {
  initialPending: boolean;
  pendingNearestPods: boolean;
  nearestPods: IPod[] | null;
  nearestPodsVehicles: IPodVehicle[] | null;
  selectedPod: IPod | null;
  selectedPodVehicle: IPodVehicle | null;
  showOnlyAvailableVehicle: boolean;
  nearestPodsStatus: RequestStatusEnum | null;
  firstVisible: boolean;
  podTimezone: string | null;
  podOffset: string | null;
  podsAll: IPodAll[] | null;
  pendingPodsAll: boolean;
  podsAllStatus: RequestStatusEnum | null;
  visiblePods: IPodAll[] | null | undefined;
  visiblePodsId2: number[] | null;
  vehiclesInPod: IPodVehicleBasic[] | null;
  pendingVehiclesInPod: boolean;
  vehiclesInPodStatus: RequestStatusEnum | null;
  selectedPodOptimized: IPodOptimized | null;
  selectedPodVehicleOptimized: IPodVehicleOptimized | null;
  selectedPod2: IPodAll | null;
  selectedPodVehicle2: IPodVehicleBasic | null;
  mapAutoZoomEnd: boolean;
  currentZoom: number | null;
  availableCount: number[] | undefined;
  visiblePodsId: number[] | undefined;
  visibleVehiclesId2: number[] | undefined;
};

const initialState: InitialState = {
  initialPending: false,
  pendingNearestPods: false,
  nearestPods: null,
  nearestPodsVehicles: null,
  selectedPod: null,
  selectedPodVehicle: null,
  showOnlyAvailableVehicle: false,
  nearestPodsStatus: null,
  firstVisible: false,
  podTimezone: null,
  podOffset: null,
  podsAll: null,
  pendingPodsAll: false,
  podsAllStatus: null,
  visiblePods: null,
  visiblePodsId2: null,
  vehiclesInPod: null,
  pendingVehiclesInPod: false,
  selectedPodVehicle2: null,
  selectedPodOptimized: null,
  selectedPodVehicleOptimized: null,
  vehiclesInPodStatus: null,
  selectedPod2: null,
  mapAutoZoomEnd: false,
  currentZoom: null,
  availableCount: [],
  visiblePodsId: [],
  visibleVehiclesId2: [],
};

export const slice = createSlice({
  name: 'pods',
  initialState,
  reducers: {
    reset: () => initialState,
    setInitialPending: (state, action: PayloadAction<boolean>) => {
      state.initialPending = action.payload;
    },
    setPendingNearestPods: (state, action: PayloadAction<boolean>) => {
      state.pendingNearestPods = action.payload;
    },
    setNearestPods: (state, action: PayloadAction<IPod[] | null>) => {
      state.nearestPods = action.payload;
    },
    setNearestPodsVehicles: (
      state,
      action: PayloadAction<IPodVehicle[] | null>,
    ) => {
      state.nearestPodsVehicles = action.payload;
    },
    setSelectedPod: (state, action: PayloadAction<IPod | null>) => {
      state.selectedPod = action.payload;
      const selectedPodVehicleCount = action.payload?.vehicles?.length || 0;
      if (selectedPodVehicleCount > 0) {
        state.selectedPodVehicle = action.payload?.vehicles[0] || null;
      }
    },
    setSelectedPodVehicle: (
      state,
      action: PayloadAction<IPodVehicle | null>,
    ) => {
      state.selectedPodVehicle = action.payload;
    },
    setShowOnlyAvailableVehicle: (state, action: PayloadAction<boolean>) => {
      state.showOnlyAvailableVehicle = action.payload;
    },
    setNearestPodsStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.nearestPodsStatus = action.payload;
    },
    setFirstVisible: (state, action: PayloadAction<boolean>) => {
      state.firstVisible = action.payload;
    },
    setPodTimezone: (state, action: PayloadAction<string | null>) => {
      state.podTimezone = action.payload;
    },
    setPodOffset: (state, action: PayloadAction<string | null>) => {
      state.podOffset = action.payload;
    },
    setPodsAll: (state, action: PayloadAction<IPodAll[] | null>) => {
      state.podsAll = action.payload;
    },
    setPendingPodsAll: (state, action: PayloadAction<boolean>) => {
      state.pendingPodsAll = action.payload;
    },
    setPodsAllStatus: (state, action: PayloadAction<RequestStatusEnum>) => {
      state.podsAllStatus = action.payload;
    },
    setVisiblePods: (
      state,
      action: PayloadAction<IPodAll[] | null | undefined>,
    ) => {
      state.visiblePods = action.payload;
    },
    setVisiblePodsId2: (state, action: PayloadAction<number[] | null>) => {
      state.visiblePodsId2 = action.payload;
    },
    setVehiclesInPod: (
      state,
      action: PayloadAction<IPodVehicleBasic[] | null>,
    ) => {
      state.vehiclesInPod = action.payload;
    },
    setPendingVehiclesInPod: (state, action: PayloadAction<boolean>) => {
      state.pendingVehiclesInPod = action.payload;
    },
    setVehiclesInPodStatus: (
      state,
      action: PayloadAction<RequestStatusEnum>,
    ) => {
      state.vehiclesInPodStatus = action.payload;
    },
    setSelectedPodOptimized: (
      state,
      action: PayloadAction<IPodOptimized | null>,
    ) => {
      state.selectedPodOptimized = action.payload;
    },
    setSelectedPodVehicleOptimized: (
      state,
      action: PayloadAction<IPodVehicleOptimized | null>,
    ) => {
      state.selectedPodVehicleOptimized = action.payload;
    },
    setSelectedPod2: (state, action: PayloadAction<IPodAll | null>) => {
      state.selectedPod2 = action.payload;
    },
    setSelectedPodVehicle2: (
      state,
      action: PayloadAction<IPodVehicleBasic | null>,
    ) => {
      state.selectedPodVehicle2 = action.payload;
    },
    setMapAutoZoomEnd: (state, action: PayloadAction<boolean>) => {
      state.mapAutoZoomEnd = action.payload;
    },
    setCurrentZoom: (state, action: PayloadAction<number | null>) => {
      state.currentZoom = action.payload;
    },
    setAvailableCount: (state, action: PayloadAction<number[] | undefined>) => {
      state.availableCount = action.payload;
    },
    setVisiblePodsId: (state, action: PayloadAction<number[] | undefined>) => {
      state.visiblePodsId = action.payload;
    },
    setVisibleVehiclesId2: (
      state,
      action: PayloadAction<number[] | undefined>,
    ) => {
      state.visibleVehiclesId2 = action.payload;
    },
  },
});

export const {
  reset,
  setInitialPending,
  setPendingNearestPods,
  setNearestPods,
  setNearestPodsVehicles,
  setSelectedPod,
  setSelectedPodVehicle,
  setShowOnlyAvailableVehicle,
  setNearestPodsStatus,
  setFirstVisible,
  setPodTimezone,
  setPodOffset,
  setPodsAll,
  setPendingPodsAll,
  setPodsAllStatus,
  setVisiblePods,
  setVisiblePodsId2,
  setVehiclesInPod,
  setPendingVehiclesInPod,
  setVehiclesInPodStatus,
  setSelectedPodOptimized,
  setSelectedPodVehicleOptimized,
  setSelectedPod2,
  setSelectedPodVehicle2,
  setMapAutoZoomEnd,
  setCurrentZoom,
  setAvailableCount,
  setVisiblePodsId,
  setVisibleVehiclesId2,
} = slice.actions;

export const selectInitialPending = (state: RootState): boolean =>
  state.pods.initialPending;
export const selectPendingNearestPods = (state: RootState): boolean =>
  state.pods.pendingNearestPods;
export const selectNearestPods = (state: RootState): IPod[] | null =>
  state.pods.nearestPods;
export const selectNearestPodsVehicles = (
  state: RootState,
): IPodVehicle[] | null => state.pods.nearestPodsVehicles;
export const selectSelectedPod = (state: RootState): IPod | null =>
  state.pods.selectedPod;
export const selectSelectedPodVehicle = (
  state: RootState,
): IPodVehicle | null => state.pods.selectedPodVehicle;
export const selectShowOnlyAvailableVehicle = (state: RootState): boolean =>
  state.pods.showOnlyAvailableVehicle;
export const selectNearestPodsStatus = (
  state: RootState,
): RequestStatusEnum | null => state.pods.nearestPodsStatus;
export const selectFirstVisible = (
  state: RootState,
): boolean => state.pods.firstVisible;
export const selectPodTimezone = (
  state: RootState,
): string | null => state.pods.podTimezone;
export const selectPodOffset = (
  state: RootState,
): string | null => state.pods.podOffset;
export const selectPodsAll = (state: RootState): IPodAll[] | null =>
  state.pods.podsAll;
export const selectPendingNearestPodsAll = (state: RootState): boolean =>
  state.pods.pendingPodsAll;
export const selectPodsAllStatus = (
  state: RootState,
): RequestStatusEnum | null => state.pods.podsAllStatus;
export const selectVisiblePods = (state: RootState): IPodAll[] | null | undefined =>
  state.pods.visiblePods;
export const selectVisiblePodsId2 = (state: RootState): number[] | null =>
  state.pods.visiblePodsId2;
export const selectVehiclesInPod = (state: RootState): IPodVehicleBasic[] | null =>
  state.pods.vehiclesInPod;
export const selectPendingVehiclesInPod = (state: RootState): boolean =>
  state.pods.pendingVehiclesInPod;
export const selectVehiclesInPodStatus = (
  state: RootState,
): RequestStatusEnum | null => state.pods.vehiclesInPodStatus;
export const selectSelectedPodOptimized = (state: RootState): IPodOptimized | null =>
  state.pods.selectedPodOptimized;
export const selectSelectedPodVehicleOptimized = (state: RootState): IPodVehicleOptimized | null =>
  state.pods.selectedPodVehicleOptimized;
  export const selectSelectedPod2 = (state: RootState): IPodAll | null =>
  state.pods.selectedPod2;
export const selectSelectedPodVehicle2 = (state: RootState): IPodVehicleBasic | null =>
  state.pods.selectedPodVehicle2;
export const selectMapAutoZoomEnd = (state: RootState): boolean =>
  state.pods.mapAutoZoomEnd;
export const selectCurrentZoom = (state: RootState): number | null =>
  state.pods.currentZoom;
export const selectAvailableCount = (state: RootState): number[] |undefined =>
  state.pods.availableCount;
export const selectVisiblePodsId = (state: RootState): number[] | undefined =>
  state.pods.visiblePodsId;
export const selectVisibleVehiclesId2 = (state: RootState): number[] | undefined =>
  state.pods.visibleVehiclesId2;

export default slice.reducer;
