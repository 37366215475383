import React, { useEffect, useRef, useLayoutEffect } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import DriverInformation from './DriverInformation';
import DrivingExperience from './DrivingExperience';
import { selectPendingUserInfo } from 'modules/me/slice';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import gsap from 'gsap';

const RegisterInformationDriver = () => {
  const dispatch = useAppDispatch();
  const isPendingUserInfo = useAppSelector(selectPendingUserInfo);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_USER_INFO });
  },[]);

  return (
    <section ref={component} className="fadein flex w-full max-w-[700px] flex-col items-center md:items-start">
      <h2 className="text-center mb-[30px] md:mb-[40px] text-[20px] md:text-[40px] uppercase font-primary font-[900] text-flexi-orange-1">
        Driver Information
      </h2>
      {isPendingUserInfo ? (
        <PendingLoader />
      ) : (
        <>
          <DriverInformation />
          <DrivingExperience />
        </>
      )}
    </section>
  );
};

export default RegisterInformationDriver;
