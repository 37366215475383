import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import gsap from 'gsap';

import iconOften from 'assets/images/personal_often.svg';
import iconSometimes from 'assets/images/personal_sometimes.svg';
import { ReactComponent as IconSenior } from 'assets/images/senior-plan.svg';
import { IMAGE_URL } from 'common/constants';
import { getToken } from 'common/helpers';
import { IRegisterPlan, RequestStatusEnum } from 'common/types';
import { setErrorPromo } from 'modules/transaction/slice';
import {
  selectIsSenior,
  selectPlans,
  selectPlansStatus,
  selectUser,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';
import ChangePlanModal from './ChangePlanModal';
import ListAndRates from './ListAndRates';
import Inclusions from './Inclusions';
import Card from './Card';

const PlanSelection = () => {
  const dispatch = useAppDispatch();
  const token = getToken();
  const user = useAppSelector(selectUser);
  const plans = useAppSelector(selectPlans);
  const isSenior = useAppSelector(selectIsSenior);
  const plansStatus = useAppSelector(selectPlansStatus);
  const [planSelected, setPlanSelected] = useState<IRegisterPlan | null>(null);
  const [currentPlan, setCurrentPlan] = useState<string | null>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffect(() => {
    if (user) {
      setIsRendered(true);
    }
  }, [user]);

  useEffect(() => {
    if (isRendered) {
      setCurrentPlan(user?.plan?.name);
    }
  }, [isRendered]);

  useEffect(() => {
    if (user && token) {
      dispatch({
        type: ACTIONS.FETCH_VEHICLE_LIST_V2,
        payload: {
          isSenior,
        },
      });
    }
  }, [user, token]);

  useEffectOnce(() => {
    if (token) {
      dispatch({
        type: ACTIONS.GET_PLANS_V2,
        payload: {
          isSenior,
        },
      });
    }
    dispatch(setErrorPromo(null));
  });

  useUpdateEffect(() => {
    setPlanSelected(plans[0]);
  }, [plans]);

  return (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-90px)] w-full max-w-[600px] flex-col pt-[42px]"
    >
      <p className="text-[16px] md:text-[18px]">
        Choose the plan that&apos;s right for you
      </p>

      {planSelected && plansStatus !== RequestStatusEnum.PENDING ? (
        <>
          <div className="mb-[100px] flex flex-col items-start">
            {plans[0].name.toLowerCase() === 'senior' ? (
              <div className="mx-auto pb-[30px] pt-[25px]">
                <IconSenior />
              </div>
            ) : (
              <>
                <div className="mx-auto pb-[30px] pt-[25px]">
                  {planSelected?.id === plans[0].id ? (
                    <img
                      src={`${IMAGE_URL}${iconSometimes}`}
                      className="w-[120px]"
                    />
                  ) : (
                    <img
                      src={`${IMAGE_URL}${iconOften}`}
                      className="w-[120px]"
                    />
                  )}
                </div>
                <ul className="mx-auto mb-[25px] flex w-full gap-[20px] rounded-[10px] bg-white px-[8px] py-[7px] shadow-[0_2px_9px_0_rgba(0,0,0,0.1)]">
                  {plans.map((plan: IRegisterPlan) => {
                    return (
                      <li
                        key={plan.id}
                        className={classNames(
                          'w-full py-[13px] text-center text-[16px] font-bold',
                          {
                            'rounded-[10px] bg-flexi-orange-1 text-white':
                              planSelected?.id === plan.id,
                          },
                        )}
                        onClick={() => {
                          setPlanSelected(plan);
                        }}
                        role="button"
                      >
                        <p className="text-[14px] md:self-end md:text-[16px]">
                          {plan.name}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            <div className="w-full">
              <Card plan={planSelected} key={planSelected?.name} />
            </div>

            <ListAndRates />
            <Inclusions inclusions={planSelected?.inclusions} />
          </div>
          {isMobile ? (
            <div className="fixed bottom-0 ml-[-25px] w-[calc(100%)] rounded-t-[10px] bg-white px-[25px] shadow-lg md:block">
              <button
                onClick={() => setIsModalOpen(true)}
                className="btn btn-primary my-[25px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px] md:mt-[25px] md:h-[60px]"
                disabled={currentPlan === planSelected.name}
              >
                Select Plan
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIsModalOpen(true)}
              className="btn btn-primary mt-[70px] h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white sm:mt-[70px] md:h-[60px]"
              disabled={currentPlan === planSelected.name}
            >
              Select Plan
            </button>
          )}
          <ChangePlanModal
            planSelected={planSelected}
            currentPlan={currentPlan}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setIsButtonDisabled}
          />
        </>
      ) : (
        <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
          <PendingLoader size={100} />
        </section>
      )}
    </section>
  );
};

export default PlanSelection;
