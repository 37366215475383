import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IMAGE_URL } from 'common/constants';
import useWindcave from 'hooks/useWindcave';
import useAppDispatch from 'hooks/useAppDispatch';
import PaymentMethodModal from 'components/PaymentMethodModal';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce } from 'react-use';
import { ReactComponent as IconPlus } from 'assets/images/plus.svg';
import useAppSelector from 'hooks/useAppSelector';
import { selectDefaultPaymentCard, selectPaymentCards, selectPendingPaymentCards } from 'modules/me/slice';
import { useHistory } from 'react-router-dom';

function AddPaymentMethod() {
  //   const [cardId, setCardId] = useState<any>(null);
  //   const { paymentCards } = usePaymentMethod();
  const [modalOpen, setModalOpen] = useState(false);
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const { addCard } = useWindcave();
  const dispatch = useAppDispatch();
  const [imageName, setNewImage] = useState<string>('');
  const [isImageExist, setIsImageExist] = useState<boolean>(true);
  //   const [burgerActive, setBurgerActive] = useState(-1);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const paymentCards = useAppSelector(selectPaymentCards);
  const pendingPaymentCards = useAppSelector(selectPendingPaymentCards);
  const cardTotal = paymentCards?.length || 0;
  const { push } = useHistory();
  useEffect(() => {
    if (defaultPaymentCard)
      try {
        setNewImage(
          require(`assets/images/card-${defaultPaymentCard?.type}.svg`),
        );
        // do stuff
      } catch (ex) {
        setIsImageExist(false);
      }
  }, [defaultPaymentCard]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_DEFAULT_PAYMENT_CARD });
    dispatch({ type: ACTIONS.FETCH_WINDCAVE_CARD_URL });
    dispatch({ type: ACTIONS.FETCH_PAYMENT_CARDS });
  });

  const handleRedirect = () => {
    if (cardTotal !== 0) push('/app/add-payment-method-success');
  };

  return (
    <article className="relative mt-[50px] mb-[100px] flex flex-col items-center gap-4 self-center md:px-[100px] mx-[25px] md:mx-0 md:mt-[100px]">
      <h1 className="mb-[30px] text-center text-[20px]  font-bold md:text-[34px]">
        Add Payment Method
      </h1>
      <p className='text-center md:text-left text-[18px] mb-[50px]'>This business requires you to add your personal credit card for your personal bookings.</p>

      {cardTotal === 0 ? (
        <label
          role="button"
          className="grid h-[80px] w-[350px] max-w-[350px] place-items-center  rounded-md border-[1px] border-transparent p-[20px] py-4 text-[35px] text-flexi-orange-1 shadow-[0_2px_9px] shadow-flexi-white-2 duration-200 hover:border-[1px] hover:border-flexi-orange-1"
          onClick={() => {
            addCard();
            setModalOpen(true);
          }}
        >
          <IconPlus className="h-[20px] w-[20px]" />
        </label>
      ) : (
        <div
          className="flex w-[350px] max-w-[350px] place-items-center justify-center rounded-md border-[1px] hover:border-primary p-[15px] md:p-5"
          role="button"
          onClick={() => setModalOpen(true)}
        >
          <div className="flex gap-2 sm:gap-3">
            <p
              style={{
                backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                backgroundSize: '100%',
              }}
              className={classNames(
                'grid w-[52px] place-items-center bg-[center_left] bg-no-repeat md:w-[65px]',
                {
                  'border-[1px]': !isImageExist,
                },
              )}
            >
              {!isImageExist && 'N/A'}
            </p>
            <div className="flex flex-col">
              <p className="w-[130px] text-[12px] capitalize sm:w-[200px] md:text-[14px]">
                <span className="font-bold">●●●● ●●●● ●●●● </span>
                {defaultPaymentCard?.cardNumber}
              </p>

              <p className="text-[10px] md:text-[14px]">
                Expires on {defaultPaymentCard?.expiryMonth}/
                {defaultPaymentCard?.expiryYear}
              </p>
            </div>
          </div>
        </div>
      )}

      <button
        className="btn mt-[50px] h-[60px] w-[350px] max-w-[350px] rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2 disabled:text-white"
        onClick={handleRedirect}
        disabled={cardTotal === 0 || pendingPaymentCards}
      >
        finish
      </button>

      <PaymentMethodModal isModalOpen={modalOpen} setModalOpen={setModalOpen} />
    </article>
  );
}

export default AddPaymentMethod;
