import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchMe,
  updateUserDetails,
  updateUserPlan,
  fetchPaymentCards,
  fetchDefaultPaymentCard,
  updateDefaultPaymentCard,
  deletePaymentCard,
  fetchUserInfo,
  fetchCampusList,
  fetchUserPlan,
  changePassword,
  fetchRewardPartners,
  fetchExpiredCredits,
  updateLicenseAddress,
  cancelMembership,
  changeExcess,
  changePartner,
  changeVff,
  storeContact,
  uploadPhoto,
  getPhoto,
  chooseUserPlan,
  getPlans,
  getPlansV2,
  switchAccount,
  firstLogin,
  cancelMembershipDriver,
  fetchWallet,
  fetchMeOcr,
  removeVff,
  verifyReferrer,
  sendSupportEmail,
  fetchDevices,
  logoutDevice,
  retryOcr,
  fetchOcrAttempt,
  fetchUserAvailableCredits,
  updateUseCredits,
  updateCourseEndDate,
  acceptStudentSwitchPersonal,
  qrCodeReverification,
} from './sagas';

const {
  FETCH_ME,
  FETCH_ME_OCR,
  FETCH_PAYMENT_CARDS,
  FETCH_USER_INFO,
  FETCH_CAMPUS_LIST,
  UPDATE_USER_DETAILS,
  UPDATE_LICENSE_ADDRESS,
  CHOOSE_USER_PLAN,
  UPDATE_USER_PLAN,
  CHANGE_PASSWORD,
  FETCH_DEFAULT_PAYMENT_CARD,
  UPDATE_DEFAULT_PAYMENT_CARD,
  DELETE_PAYMENT_CARD,
  FETCH_USER_PLAN,
  FETCH_REWARD_PARTNERS,
  FETCH_EXPIRED_CREDITS,
  CANCEL_MEMBERSHIP,
  CHANGE_EXCESS,
  CHANGE_PARTNER,
  CHANGE_VFF,
  STORE_CONTACT,
  UPLOAD_PHOTO,
  GET_PHOTO,
  GET_PLANS,
  GET_PLANS_V2,
  SWITCH_ACCOUNT,
  FIRST_LOGIN,
  CANCEL_MEMBERSHIP_DRIVER,
  FETCH_WALLET,
  REMOVE_VFF,
  VERIFY_REFERRER,
  SEND_SUPPORT_EMAIL,
  FETCH_DEVICES,
  LOGOUT_DEVICE,
  RETRY_OCR,
  FETCH_OCR_ATTEMPT,
  FETCH_USER_AVAILABLE_CREDITS,
  UPDATE_USE_CREDITS,
  UPDATE_COURSE_END_DATE,
  ACCEPT_STUDENT_SWITCH_PERSONAL,
  QR_CODE_REVERIFICATION,
} = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_ME, fetchMe),
  takeLatest(FETCH_ME_OCR, fetchMeOcr),
  takeLatest(FETCH_PAYMENT_CARDS, fetchPaymentCards),
  takeLatest(FETCH_USER_INFO, fetchUserInfo),
  takeLatest(FETCH_CAMPUS_LIST, fetchCampusList),
  takeLatest(UPDATE_USER_DETAILS, updateUserDetails),
  takeLatest(UPDATE_LICENSE_ADDRESS, updateLicenseAddress),
  takeLatest(CHOOSE_USER_PLAN, chooseUserPlan),
  takeLatest(UPDATE_USER_PLAN, updateUserPlan),
  takeLatest(FETCH_DEFAULT_PAYMENT_CARD, fetchDefaultPaymentCard),
  takeLatest(UPDATE_DEFAULT_PAYMENT_CARD, updateDefaultPaymentCard),
  takeLatest(DELETE_PAYMENT_CARD, deletePaymentCard),
  takeLatest(FETCH_USER_PLAN, fetchUserPlan),
  takeLatest(CHANGE_PASSWORD, changePassword),
  takeLatest(FETCH_REWARD_PARTNERS, fetchRewardPartners),
  takeLatest(FETCH_EXPIRED_CREDITS, fetchExpiredCredits),
  takeLatest(CANCEL_MEMBERSHIP, cancelMembership),
  takeLatest(CANCEL_MEMBERSHIP_DRIVER, cancelMembershipDriver),
  takeLatest(CHANGE_EXCESS, changeExcess),
  takeLatest(CHANGE_PARTNER, changePartner),
  takeLatest(CHANGE_VFF, changeVff),
  takeLatest(STORE_CONTACT, storeContact),
  takeLatest(UPLOAD_PHOTO, uploadPhoto),
  takeLatest(GET_PHOTO, getPhoto),
  takeLatest(GET_PLANS, getPlans),
  takeLatest(GET_PLANS_V2, getPlansV2),
  takeLatest(SWITCH_ACCOUNT, switchAccount),
  takeLatest(FIRST_LOGIN, firstLogin),
  takeLatest(FETCH_WALLET, fetchWallet),
  takeLatest(REMOVE_VFF, removeVff),
  takeLatest(VERIFY_REFERRER, verifyReferrer),
  takeLatest(SEND_SUPPORT_EMAIL, sendSupportEmail),
  takeLatest(FETCH_DEVICES, fetchDevices),
  takeLatest(LOGOUT_DEVICE, logoutDevice),
  takeLatest(RETRY_OCR, retryOcr),
  takeLatest(FETCH_OCR_ATTEMPT, fetchOcrAttempt),
  takeLatest(FETCH_USER_AVAILABLE_CREDITS, fetchUserAvailableCredits),
  takeLatest(UPDATE_USE_CREDITS, updateUseCredits),
  takeLatest(UPDATE_COURSE_END_DATE, updateCourseEndDate),
  takeLatest(ACCEPT_STUDENT_SWITCH_PERSONAL, acceptStudentSwitchPersonal),
  takeLatest(QR_CODE_REVERIFICATION, qrCodeReverification),
];

export default actions;
