import AddAccountLayout from 'components/layouts/AddAccountLayout';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import gsap from 'gsap';
import { ReactComponent as Icon } from 'assets/images/success_2.svg';

function AddAccountDone() {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const user = useAppSelector(selectUser);
  const isBusinessRemoved = false;

  const handleClick = () => {
    dispatch({ type: ACTIONS.FETCH_ME });
    push('/app/booking');
  };

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <AddAccountLayout>
      <div
        ref={component}
        className="fadein relative w-full pt-[40px] md:max-w-[600px]"
      >
        <div className="flex min-h-[calc(100vh-115px)] flex-col items-center md:min-h-[calc(100vh-500px)]">
          <Icon className="h-auto w-[120px]" />
          <h1 className="font-primary mt-[30px] mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-[40px] md:leading-[50px]">
            {isBusinessRemoved ? (
              <p>your flexicar personal account is now ready to&nbsp;use!</p>
            ) : (
              <p>you&apos;ve created your personal&nbsp;account.</p>
            )}
          </h1>
          {!isBusinessRemoved && (
            <>
              <p className="mb-[20px] text-center text-[14px] md:text-[16px]">
                As a driver perks, you will now be using the{' '}
                <strong>{user?.organisation?.name}</strong>{' '}
                <span className="whitespace-nowrap">partner rate.</span>
              </p>
              <p className="text-center text-[14px] md:text-[16px]">
                If you will be removed from their list of drivers, you will eventually be required to <span className="whitespace-nowrap">select a plan.</span>
              </p>
            </>
          )}
          <button
            className="btn btn-primary mt-auto h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]"
            onClick={handleClick}
          >
            Ok
          </button>
        </div>
      </div>
    </AddAccountLayout>
  );
}

export default AddAccountDone;
