import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import {
  IAbrLookup,
  IOtpExpiry,
  IPlanType,
  IPlanTypeDetails,
  ISignUpDriver,
  ISignUpLicense,
  ITokenInfo,
  IUniversities,
  RequestStatusEnum,
} from 'common/types';

type InitialState = {
  pending: boolean;
  pendingLogout: boolean;
  pendingVerifyEmail: boolean;
  pendingVerifyLicense: boolean;
  pendingOtp: boolean;
  pendingOtpExpiry: boolean;
  otpError: boolean;
  isEmailVerified: boolean;
  isLicenseVerified: boolean;
  error: any;
  loginError: any;
  otpExpiry: IOtpExpiry | null;
  abrLookup: IAbrLookup | null;
  pendingAbr: boolean;
  abrLookupStatus: RequestStatusEnum | null;
  tokenInfo: ITokenInfo | null;
  isLoginModalOpen: boolean;
  isDebugMode: boolean;
  isTokenExpired: boolean;
  decryptToken: string;
  errorModal: boolean;
  registerStatus: RequestStatusEnum | null;
  verifyOtpStatus: RequestStatusEnum | null;
  decryptTokenStatus: RequestStatusEnum | null;
  forOnboarding: boolean;
  decryptTokenError: any;
  isBusinessNameVerified: boolean;
  pendingVerifyBusinessName: boolean;
  planType: IPlanTypeDetails | null;
  planTypes: IPlanType | null;
  universities: IUniversities[] | null;
  studentEmailUniversityId: number | null;
  signUpLicense: ISignUpLicense | null;
  signUpDriver: ISignUpDriver | null;
};

const initialState: InitialState = {
  pending: false,
  pendingLogout: false,
  pendingVerifyEmail: false,
  otpError: false,
  pendingVerifyLicense: false,
  pendingOtp: false,
  pendingOtpExpiry: false,
  isEmailVerified: false,
  isLicenseVerified: false,
  error: null,
  loginError: null,
  otpExpiry: null,
  abrLookup: null,
  pendingAbr: false,
  abrLookupStatus: null,
  tokenInfo: null,
  isLoginModalOpen: false,
  isDebugMode: false,
  isTokenExpired: false,
  decryptToken: '',
  errorModal: false,
  registerStatus: null,
  verifyOtpStatus: null,
  decryptTokenStatus: null,
  forOnboarding: false,
  decryptTokenError: null,
  isBusinessNameVerified: false,
  pendingVerifyBusinessName: false,
  planType: null,
  planTypes: null,
  universities: null,
  studentEmailUniversityId: null,
  signUpLicense: null,
  signUpDriver: null,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setRegisterStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.registerStatus = action.payload;
    },
    setPendingLogout: (state, action: PayloadAction<boolean>) => {
      state.pendingLogout = action.payload;
    },
    setPendingVerifyEmail: (state, action: PayloadAction<boolean>) => {
      state.pendingVerifyEmail = action.payload;
    },
    setPendingVerifyLicense: (state, action: PayloadAction<boolean>) => {
      state.pendingVerifyLicense = action.payload;
    },
    setPendingOtp: (state, action: PayloadAction<boolean>) => {
      state.pendingOtp = action.payload;
    },
    setOtpExpiry: (state, action: PayloadAction<IOtpExpiry>) => {
      state.otpExpiry = action.payload;
    },
    setPendingOtpExpiry: (state, action: PayloadAction<boolean>) => {
      state.pendingOtpExpiry = action.payload;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.isEmailVerified = action.payload;
    },
    setLicenseVerified: (state, action: PayloadAction<boolean>) => {
      state.isLicenseVerified = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setLoginError: (state, action: PayloadAction<any>) => {
      state.loginError = action.payload;
    },
    setAbrLookup: (state, action: PayloadAction<IAbrLookup | null>) => {
      state.abrLookup = action.payload;
    },
    setPendingAbr: (state, action: PayloadAction<boolean>) => {
      state.pendingAbr = action.payload;
    },
    setOtpError: (state, action: PayloadAction<boolean>) => {
      state.otpError = action.payload;
    },
    setAbrLookupStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.abrLookupStatus = action.payload;
    },
    setTokenInfo: (state, action: PayloadAction<ITokenInfo | null>) => {
      state.tokenInfo = action.payload;
    },
    setLoginModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginModalOpen = action.payload;
    },
    setDebugMode: (state, action: PayloadAction<boolean>) => {
      state.isDebugMode = action.payload;
    },
    setIsTokenExpired: (state, action: PayloadAction<boolean>) => {
      state.isTokenExpired = action.payload;
    },
    setDecryptToken: (state, action: PayloadAction<string>) => {
      state.decryptToken = action.payload;
    },
    setErrorModal: (state, action: PayloadAction<boolean>) => {
      state.errorModal = action.payload;
    },
    setVerifyOtpStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.verifyOtpStatus = action.payload;
    },
    setDecryptTokenStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.decryptTokenStatus = action.payload;
    },
    setForOnboarding: (state, action: PayloadAction<boolean>) => {
      state.forOnboarding = action.payload;
    },
    setDecryptTokenError: (state, action: PayloadAction<any>) => {
      state.decryptTokenError = action.payload;
    },
    setBusinessNameVerified: (state, action: PayloadAction<boolean>) => {
      state.isBusinessNameVerified = action.payload;
    },
    setPendingVerifyBusinessName: (state, action: PayloadAction<boolean>) => {
      state.pendingVerifyBusinessName = action.payload;
    },
    setPlanType: (state, action: PayloadAction<IPlanTypeDetails | null>) => {
      state.planType = action.payload;
    },
    setPlanTypes: (state, action: PayloadAction<IPlanType | null>) => {
      state.planTypes = action.payload;
    },
    setUniversities: (state, action: PayloadAction<IUniversities[] | null>) => {
      state.universities = action.payload;
    },
    setStudentEmailUniversityId: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.studentEmailUniversityId = action.payload;
    },
    setSignUpLicense: (state, action: PayloadAction<ISignUpLicense | null>) => {
      state.signUpLicense = action.payload;
    },
    setSignUpDriver: (state, action: PayloadAction<ISignUpDriver | null>) => {
      state.signUpDriver = action.payload;
    },
  },
});

export const {
  reset,
  setPending,
  setRegisterStatus,
  setPendingLogout,
  setPendingVerifyEmail,
  setPendingVerifyLicense,
  setPendingOtp,
  setEmailVerified,
  setLicenseVerified,
  setError,
  setLoginError,
  setOtpExpiry,
  setPendingOtpExpiry,
  setAbrLookup,
  setOtpError,
  setPendingAbr,
  setAbrLookupStatus,
  setTokenInfo,
  setLoginModalOpen,
  setDebugMode,
  setIsTokenExpired,
  setDecryptToken,
  setErrorModal,
  setVerifyOtpStatus,
  setForOnboarding,
  setDecryptTokenError,
  setDecryptTokenStatus,
  setBusinessNameVerified,
  setPendingVerifyBusinessName,
  setPlanType,
  setPlanTypes,
  setUniversities,
  setStudentEmailUniversityId,
  setSignUpLicense,
  setSignUpDriver,
} = slice.actions;

export const selectPending = (state: RootState): boolean => state.auth.pending;
export const selectRegisterStatus = (state: RootState): RequestStatusEnum | null => state.auth.registerStatus;
export const selectPendingLogout = (state: RootState): boolean =>
  state.auth.pendingLogout;
export const selectPendingVerifyEmail = (state: RootState): boolean =>
  state.auth.pendingVerifyEmail;
export const selectPendingVerifyLicense = (state: RootState): boolean =>
  state.auth.pendingVerifyLicense;
export const selectPendingOtp = (state: RootState): boolean =>
  state.auth.pendingOtp;
export const selectPendingOtpExpiry = (state: RootState): boolean =>
  state.auth.pendingOtpExpiry;
export const selectOtpExpiry = (state: RootState): IOtpExpiry | null =>
  state.auth.otpExpiry;
export const selectEmailVerified = (state: RootState): boolean =>
  state.auth.isEmailVerified;
export const selectLicenseVerified = (state: RootState): boolean =>
  state.auth.isLicenseVerified;
export const selectError = (state: RootState): any => state.auth.error;
export const selectLoginError = (state: RootState): any => state.auth.loginError;
export const selectAbrLookup = (state: RootState): IAbrLookup | null =>
  state.auth.abrLookup;
export const selectOtpError = (state: RootState): boolean =>
  state.auth.otpError;
export const selectPendingAbr = (state: RootState): boolean =>
  state.auth.pendingAbr;
export const selectAbrLookupStatus = (
  state: RootState,
): RequestStatusEnum | null => state.auth.abrLookupStatus;
export const selectTokenInfo = (state: RootState): ITokenInfo | null =>
  state.auth.tokenInfo;
export const selectLoginModalOpen = (state: RootState): boolean =>
  state.auth.isLoginModalOpen;
export const selectDebugMode = (state: RootState): boolean =>
  state.auth.isDebugMode;
export const selectIsTokenExpired = (state: RootState): boolean =>
  state.auth.isTokenExpired;
export const selectDecryptToken = (state: RootState): string =>
  state.auth.decryptToken;
export const selectErrorModal = (state: RootState): boolean =>
  state.auth.errorModal;
export const selectVerifyOtpStatus = (state: RootState): RequestStatusEnum | null => state.auth.verifyOtpStatus;
export const selectDecryptTokenStatus = (state: RootState): RequestStatusEnum | null => state.auth.decryptTokenStatus;
export const selectForOnboarding = (state: RootState): boolean => state.auth.forOnboarding;
export const selectDecryptTokenError = (state: RootState): any => state.auth.decryptTokenError;
export const selectBusinessNameVerified = (state: RootState): boolean =>
  state.auth.isBusinessNameVerified;
export const selectPendingVerifyBusinessName = (state: RootState): boolean =>
  state.auth.pendingVerifyBusinessName;
export const selectPlanType = (state: RootState): IPlanTypeDetails | null =>
  state.auth.planType;
export const selectPlanTypes = (state: RootState): IPlanType | null =>
  state.auth.planTypes;
export const selectUniversities = (state: RootState): IUniversities[] | null =>
  state.auth.universities;
export const selectStudentEmailUniversityId = (state: RootState): number | null =>
  state.auth.studentEmailUniversityId;
export const selectSignUpLicense = (state: RootState): ISignUpLicense | null =>
  state.auth.signUpLicense;
export const selectSignUpDriver = (state: RootState): ISignUpDriver | null =>
  state.auth.signUpDriver;

export default slice.reducer;
