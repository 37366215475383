import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as IconPlus } from 'assets/images/plus.svg';
import { IMAGE_URL } from 'common/constants';
import { IPaymentCard } from 'common/types';
import {
  selectDefaultPaymentCard,
  selectPendingPaymentCards,
} from 'modules/me/slice';
import { selectPendingCardUrl } from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PaymentMethodModal from 'components/PaymentMethodModal';
import PaymentMethodMessageModal from 'components/PaymentMethodMessageModal';

interface Props {
  paymentCards?: IPaymentCard[] | [];
}

const PaymentMethod = ({ paymentCards }: Props) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMessageModalOpen, setMessageModalOpen] = useState(false);
  const isPendingPaymentCards = useAppSelector(selectPendingPaymentCards);
  const isPendingCardUrl = useAppSelector(selectPendingCardUrl);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const cardTotal = paymentCards?.length || 0;
  const [imageName, setNewImage] = useState<string>('');
  const [isImageExist, setIsImageExist] = useState<boolean>(true);

  useEffect(() => {
    if (defaultPaymentCard?.status?.isCardNameMismatch) {
      setMessageModalOpen(true);
    }
    if (defaultPaymentCard)
      try {
        setNewImage(
          require(`assets/images/card-${defaultPaymentCard?.type}.svg`),
        );
        // do stuff
      } catch (ex) {
        setIsImageExist(false);
      }
  }, [defaultPaymentCard]);

  const addCard = () => {
    dispatch({ type: ACTIONS.FETCH_WINDCAVE_CARD_URL });
  };

  return (
    <article className="mb-[40px] md:mb-[70px]">
      <p className="mb-[5px] text-[16px] font-bold lg:mb-[10px] lg:text-[22px] lg:font-normal">
        Payment Method
      </p>{' '}
      <p className="mb-[25px] text-[14px]">
        Your payment method will be used for identification purposes. Make sure
        it matches the Full name you entered on your Flexicar account.
      </p>
      {isPendingPaymentCards && isPendingCardUrl ? (
        <div className="flex animate-pulse flex-col gap-4">
          <div className="h-[88px] w-full rounded bg-slate-100" />
          <div className="h-[30px] w-full rounded bg-slate-100" />
        </div>
      ) : cardTotal === 0 ? (
        <label
          role="button"
          className="lg:shadow-0 grid h-[80px] w-full place-items-center rounded-md border-[1px]  border-transparent bg-white p-[20px] py-4 text-[35px] text-flexi-orange-1 shadow-[0_2px_9px] shadow-flexi-white-2 duration-200 hover:border-[1px] hover:border-flexi-orange-1"
          onClick={() => {
            addCard();
            setModalOpen(true);
          }}
        >
          <IconPlus className="h-[20px] w-[20px]" />
        </label>
      ) : (
        <div
          className="lg:shadow-0 flex w-full place-items-center justify-between rounded-md border-[1px] border-primary bg-white p-[15px] shadow-md md:p-5"
          role="button"
          onClick={() => setModalOpen(true)}
        >
          <div className="flex gap-2 sm:gap-3">
            <p
              style={{
                backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                backgroundSize: '100%',
              }}
              className={classNames(
                'grid w-[52px] place-items-center bg-[center_left] bg-no-repeat md:w-[65px]',
                {
                  'border-[1px]': !isImageExist,
                },
              )}
            >
              {!isImageExist && 'N/A'}
            </p>
            <div className="flex flex-col">
              <p className="w-[130px] text-[12px] capitalize sm:w-[200px] md:text-[14px]">
                <span className="font-bold">●●●● ●●●● ●●●● </span>
                {defaultPaymentCard?.cardNumber}
              </p>

              <p className="text-[10px] md:text-[14px]">
                Expires on {defaultPaymentCard?.expiryMonth}/
                {defaultPaymentCard?.expiryYear}
              </p>
            </div>
          </div>
          <p className="text-[14px] capitalize text-flexi-black-3/30 md:text-[16px]">
            default
          </p>
        </div>
      )}
      <PaymentMethodModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      />
      <PaymentMethodMessageModal
        isModalOpen={isMessageModalOpen}
        handleClose={() => {
          setMessageModalOpen(false);
        }}
        setPaymentMethodModalOpen={setModalOpen}
        enableClickAway={false}
        hasCloseButton={false}
        cardStatus={defaultPaymentCard?.status}
      />
    </article>
  );
};

export default PaymentMethod;
