import PendingLoader from 'components/PendingLoader';
import React from 'react';

interface Props {
  isOpen: boolean;
}

const LogoutModal = ({ isOpen }: Props) => {
  return (
    <>
      <input
        type="checkbox"
        id="logout-modal"
        className="modal-toggle"
        defaultChecked={isOpen}
      />
      <label className="modal z-[100000]" htmlFor="logout-modal">
        <label htmlFor="" className="modal-box w-[450px] overflow-hidden">
          <div className="flex h-[300px] flex-col items-center justify-center gap-4">
            <PendingLoader size={100} />
            <h4 className="text-lg">Logging out of Flexicar...</h4>
          </div>
        </label>
      </label>
    </>
  );
};

export default LogoutModal;
