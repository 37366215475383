import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useClickAway } from 'react-use';
import classNames from 'classnames';

import iconClose from 'assets/images/close_modal.svg';
import { ReactComponent as FailedIcon } from 'assets/images/booking-failed.svg';
import { IMAGE_URL } from 'common/constants';
import { IPaymentCardStatus } from 'common/types';

interface Props {
  setPaymentMethodModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cardStatus?: IPaymentCardStatus | undefined | null;
  statusRemarks?: string[] | undefined | null;
  isModalOpen: boolean;
  handleClose: () => void;
  width?: string;
  maxWidth?: string;
  enableClickAway?: boolean;
  hasCloseButton?: boolean;
  px?: string;
  mx?: string;
  padding?: string;
}

const PaymentMethodMessageModal = ({
  setPaymentMethodModalOpen,
  cardStatus,
  statusRemarks,
  isModalOpen,
  handleClose,
  width = '800px',
  maxWidth = '800px',
  enableClickAway = true,
  hasCloseButton = true,
  px = '120px',
  mx = '0px',
  padding = 'p-[60px]',
}: Props) => {
  const { push } = useHistory();
  const modalRef = useRef(null);

  useClickAway(modalRef, () => {
    if (enableClickAway) handleClose();
  });

  return (
    <section
      className={classNames(`modal z-[10000]`, {
        'modal-open': isModalOpen,
      })}
    >
      <div
        className={`scrollbar modal-box relative flex flex-col overflow-auto rounded-[10px] md:px-[120px]
      [&>.title]:mb-[20px] [&>.title]:text-center [&>.title]:text-[20px] 
      [&>.title]:font-[700] [&>.title]:md:text-[34px] [&>.title]:md:leading-[42px] [&>.button]:mt-[40px] [&>.button]:h-[60px] [&>.button]:rounded-full [&>.button]:border-none [&>.button]:bg-flexi-green-1 [&>.button]:font-[700] [&>.button]:text-white [&>.button]:md:text-[16px] [&>.icon]:mb-[40px] [&>.icon]:self-center [&>.button:hover]:bg-flexi-green-2 mx-[${mx}] ${px} w-full !w-[${width}] ${padding}`}
        style={{
          maxWidth,
        }}
        ref={modalRef}
      >
        {hasCloseButton && (
          <span
            className="absolute right-[25px] top-[30px] grid place-items-center md:right-[33px] md:top-[40px]"
            onClick={handleClose}
            role="button"
          >
            <span
              className="h-[20px] w-[20px] bg-center bg-no-repeat md:h-[25px] md:w-[25px]"
              style={{
                background: `url('${IMAGE_URL}${iconClose}')`,
                backgroundSize: '100%',
              }}
            />
          </span>
        )}
        <FailedIcon className="icon" />
        <h1 className="mb-[20px] text-center text-[20px] font-bold lg:text-[34px]">
          {cardStatus?.code?.includes('mismatch') ||
          statusRemarks?.some(
            (remark) =>
              remark.toLocaleLowerCase().includes('card_name_mismatch') ||
              remark.toLocaleLowerCase().includes('no card on file') ||
              remark.toLocaleLowerCase().includes('cc_same_name'),
          )
            ? 'Invalid Payment Method'
            : 'Payment Failed'}
        </h1>
        {cardStatus?.message ? (
          <p className="mb-[20px] text-center text-[14px] md:mb-[40px] lg:text-[18px]">
            {cardStatus?.message}
          </p>
        ) : (
          <p className="mb-[20px] text-center text-[14px] md:mb-[40px] lg:text-[18px]">
            Booking is temporarily unavailable. Update your payment method <span className='whitespace-nowrap'>to proceed.</span>
          </p>
        )}
        {statusRemarks?.some(
          (remark) =>
            remark.toLocaleLowerCase().includes('card_name_mismatch') ||
            remark.toLocaleLowerCase().includes('no card on file') ||
            remark.toLocaleLowerCase().includes('cc_same_name'),
        ) ? (
          <button
            className="mt-[40px] lg:!h-[60px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold text-white outline-none hover:bg-flexi-green-1"
            onClick={() => {
              push('/app/account/payment-method');
            }}
          >
            Change Payment Method
          </button>
        ) : (
          <>
            <button
              className="lg:!h-[60px] !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold text-white outline-none hover:bg-flexi-green-1"
              onClick={handleClose}
            >
              Proceed
            </button>
            <p
              onClick={() => {
                setPaymentMethodModalOpen(true);
                handleClose();
              }}
              className="mt-[20px] cursor-pointer text-center text-[14px] font-bold capitalize text-primary md:mt-[30px] md:text-[16px]"
            >
              Change Payment Method
            </p>
          </>
        )}
      </div>
    </section>
  );
};

export default PaymentMethodMessageModal;
