import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  mailUnsubscribe,
  envType,
  sendHelpCenterMail,
} from '../public/sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.MAIL_UNSUBSCRIBE, mailUnsubscribe),
  takeLatest(SAGA_ACTIONS.ENV_TYPE, envType),
  takeLatest(SAGA_ACTIONS.SEND_HELP_CENTER_MAIL, sendHelpCenterMail),
];

export default actions;