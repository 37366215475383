import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CenteredPageTitle, PageTitleBanner } from 'components/Typography';
import { ReactComponent as ArrowDown } from 'assets/images/accordion-down.svg';
import { ReactComponent as ArrowFlat } from 'assets/images/accordion-flat.svg';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectEnvType,
  selectPagesContents,
  setPagesContents,
  setPublicPending,
} from 'modules/public/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import { IContent, RequestStatusEnum } from 'common/types';
import gsap from 'gsap';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const TopFive = () => {
  const dispatch = useAppDispatch();
  const pagesContents = useAppSelector(selectPagesContents);
  const envType = useAppSelector(selectEnvType);
  const [faqShow, setFaqShow] = useState(null);

  const getPagesContents = async () => {
    const db = getFirestore(firebaseApp);
    const collectionName =
      envType === 'staging'
        ? 'pagesContentsQuestionsStaging'
        : 'pagesContentsQuestions';
    try {
      dispatch(setPublicPending(RequestStatusEnum.PENDING));
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where('is_top_five', '==', true));

      const querySnapshot = await getDocs(q);

      const data: IContent[] = [];

      querySnapshot.forEach(function (doc) {
        const parsedData = JSON.parse(JSON.stringify(doc.data()));
        data.push({
          question: parsedData.question,
          answer: parsedData.answer,
          callToAction: parsedData.call_to_action,
          image: parsedData.photo,
          embedVideoUrl: parsedData.embed_video_url,
          isTopFive: parsedData.is_top_five || false,
          categoryId: parsedData.category_id,
          contentId: Number(doc.id),
        });
      });

      if (data.length > 0) {
        dispatch(setPagesContents(data));
      } else {
        dispatch(setPagesContents(null));
      }
    } catch (error) {
      dispatch(setPublicPending(RequestStatusEnum.FAILED));
    } finally {
      dispatch(setPublicPending(RequestStatusEnum.SUCCESS));
    }
  };

  useEffect(() => {
    getPagesContents();
  }, [envType]);

  const component = useRef<any | null>(null);

  useLayoutEffect(() => {
    if (pagesContents) {
      const floatupchildren = document.querySelectorAll('.floatup');

      if (floatupchildren.length > 0) {
        component.current = gsap.timeline().to(floatupchildren, {
          y: 0,
          alpha: 1,
          duration: 0.5,
          stagger: 0.05,
          ease: 'circ.out',
        });
      }
    }
  }, [pagesContents]);

  return (
    <section>
      <Helmet>
        <title>Top FIve Questions</title>
        <meta
          name="description"
          content={`Help & Support - Top Five Questions`}
        />
      </Helmet>
      <PageTitleBanner>help & support</PageTitleBanner>
      <div className="mx-auto min-h-[100vh] min-h-[calc(100vh-240px)] bg-[#F2F2F2] px-[40px] py-[120px] md:px-[120px]">
        <div className="child:text-center mx-auto mb-[60px]">
          <CenteredPageTitle>Top 5 Questions</CenteredPageTitle>
        </div>
        {pagesContents ? (
          <div className="mx-auto flex max-w-[1000px] flex-col gap-[30px]">
            {pagesContents?.map((faq, i: any) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    if (faqShow === i) setFaqShow(null);
                    else setFaqShow(i);
                  }}
                  className="floatup flex flex-col"
                >
                  <div className="relative flex items-center justify-center bg-white px-[45px] py-[40px] md:px-[100px]">
                    <p
                      onClick={() => {
                        if (faqShow === i) setFaqShow(null);
                        else setFaqShow(i);
                      }}
                      className="font-primary text-center text-[20px] text-primary md:text-[30px]"
                    >
                      {faq.question}
                    </p>
                    {faqShow === i ? (
                      <ArrowFlat
                        onClick={() => {
                          if (faqShow === i) setFaqShow(null);
                          else setFaqShow(i);
                        }}
                        className="absolute right-[15px] scale-[70%] md:right-[40px] md:scale-[100%]"
                      />
                    ) : (
                      <ArrowDown
                        onClick={() => {
                          if (faqShow === i) setFaqShow(null);
                          else setFaqShow(i);
                        }}
                        className="absolute right-[15px] scale-[70%] md:right-[40px] md:scale-[100%]"
                      />
                    )}
                  </div>
                  <div
                    className={`overflow-hidden bg-white px-[40px] text-center text-[18px] transition-[max-height] duration-500 md:px-[80px]
                      ${faqShow === i ? `max-h-fit` : 'max-h-[0px]'}`}
                  >
                    <p className="pb-[45px] md:pb-[60px]">
                      <span
                        className="text-[16px] md:text-[18px]"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      />
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <AiOutlineLoading3Quarters
            size="48px"
            className="mx-auto mt-[100px] w-[50px] animate-spin fill-flexi-orange-1"
          />
        )}
      </div>
    </section>
  );
};

export default TopFive;
