import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { Helmet } from 'react-helmet';

import { useMediaQuery } from 'react-responsive';
import debounce from 'lodash/debounce';
import gsap from 'gsap';

import { getIsCourseDueNotified } from 'common/helpers';
import { PageTitle } from 'components/Typography';
import {
  selectOngoingBookingListPaginated,
  selectPastBookingListPaginated,
  selectUpcomingBookingListPaginated,
  setCancelBookingStatus,
} from 'modules/booking/slice';
import { selectUser } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import CustomInput from 'components/CustomInput';
import PaymentMethodMessageModal from 'components/PaymentMethodMessageModal';
import LicenseExpiredNotificationModal from 'components/LicenseExpiredNotificationModal';
import License2YNotificationModal from 'components/License2YNotificationModal';
import HasUnpaidModal from 'components/HasUnpaidModal';
import CourseEndDateModal from 'components/CourseEndDateModal';
import SearchField from './SearchField';
import TripsList from './TripsList';

const MyBookings = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const bookingListUpcomingPaginated = useAppSelector(
    selectUpcomingBookingListPaginated,
  );
  const bookingListOngoingPaginated = useAppSelector(
    selectOngoingBookingListPaginated,
  );
  const bookingListPastPaginated = useAppSelector(
    selectPastBookingListPaginated,
  );
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [licenseExpiredModalOpen, setLicenseExpiredModalOpen] = useState(false);
  const [license2YModalOpen, setLicense2YModalOpen] = useState(false);
  const [isCourseReminderModalOpen, setIsCourseReminderModalOpen] =
    useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isHasUnpaidModalOpen, setHasUnpaidModalOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useEffectOnce(() => {
    if (
      (getIsCourseDueNotified() !== 'notified' &&
        (user?.course?.isCourseEndDateExpiringInAWeek ||
          user?.course?.isCourseEndDateExpired)) ||
      (user?.plan?.isStudentSwitchedToPersonal && !user.plan.isAccepted) ||
      user?.course?.isNullCourseEndDate
    ) {
      setIsCourseReminderModalOpen(true);
    } else if (user?.twoYearReverificationOverdue) {
      setLicense2YModalOpen(true);
    } else if (user?.isLicenseExpired) {
      setLicenseExpiredModalOpen(true);
    } else if (
      user?.status.toLocaleLowerCase() === 'pending' &&
      user?.statusRemarks?.some(
        (remark) =>
          remark.toLocaleLowerCase().includes('card_name_mismatch') ||
          remark.toLocaleLowerCase().includes('no card on file') ||
          remark.toLocaleLowerCase().includes('cc_same_name'),
      )
    ) {
      setPaymentMethodModalOpen(true);
    } else {
      if (user && !user.hasUnpaidInvoice) {
        const payload = {
          page: 1,
        };
        dispatch({ type: ACTIONS.FETCH_BOOKINGS_UPCOMING_V2, payload });
        dispatch({ type: ACTIONS.FETCH_BOOKINGS_ONGOING_V2, payload });
        dispatch({ type: ACTIONS.FETCH_BOOKINGS_PAST_V2, payload });
        dispatch(setCancelBookingStatus(null));
      }
    }
  });

  const handleSearch = useCallback(
    debounce((e) => {
        const payload = {
          page: 1,
          search: e.target.value.toLowerCase()
        };
        dispatch({ type: ACTIONS.FETCH_BOOKINGS_UPCOMING_V2, payload });
        dispatch({ type: ACTIONS.FETCH_BOOKINGS_ONGOING_V2, payload });
        dispatch({ type: ACTIONS.FETCH_BOOKINGS_PAST_V2, payload });
        setSearchValue(e.target.value.toLowerCase());
    }, 2000),
    [],
  );

  useEffect(() => {
    if (user && user.hasUnpaidInvoice) {
      setHasUnpaidModalOpen(true);
    }
  }, [user]);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <article
      ref={component}
      className="fadein mx-[25px] mt-[40px] pb-[100px] md:mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Bookings</title>
        <meta name="description" content="My Flexicar Bookings" />
      </Helmet>
      <div className="relative mb-10 w-full items-center justify-between md:flex">
        <PageTitle>My Bookings</PageTitle>

        <CustomInput
          title="Search"
          extraClass="!w-[300px] mt-[15px] md:mt-0 relative"
        >
          <SearchField handleSearch={handleSearch} />
        </CustomInput>
      </div>
      <TripsList
        bookingList={bookingListOngoingPaginated}
        listType="ongoing"
        searchValue={searchValue}
      />
      <TripsList
        bookingList={bookingListUpcomingPaginated}
        listType="upcoming"
        searchValue={searchValue}
      />
      <TripsList
        bookingList={bookingListPastPaginated}
        listType="past"
        searchValue={searchValue}
      />

      <PaymentMethodMessageModal
        setPaymentMethodModalOpen={setPaymentMethodModalOpen}
        statusRemarks={user?.statusRemarks}
        isModalOpen={paymentMethodModalOpen}
        handleClose={() => {
          setPaymentMethodModalOpen(false);
        }}
        hasCloseButton={false}
        enableClickAway={false}
      />
      <LicenseExpiredNotificationModal
        isModalOpen={licenseExpiredModalOpen}
        handleClose={() => {
          if (!user?.isLicenseExpired) setLicenseExpiredModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
        hasCloseButton={!user?.isLicenseExpired}
      />
      <License2YNotificationModal
        isModalOpen={license2YModalOpen}
        hasCloseButton={!user?.twoYearReverificationOverdue}
        handleClose={() => {
          if (!user?.twoYearReverificationOverdue) setLicense2YModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      />
      <HasUnpaidModal
        isModalOpen={isHasUnpaidModalOpen && user !== null}
        handleClose={() => {
          if (!user?.hasUnpaidInvoice) setHasUnpaidModalOpen(false);
        }}
        hasCloseButton={!user?.hasUnpaidInvoice}
      />
      <CourseEndDateModal
        isModalOpen={isCourseReminderModalOpen}
        hasCloseButton={!user?.course?.isNullCourseEndDate}
        enableClickAway={!user?.course?.isNullCourseEndDate}
        handleClose={() => {
          setIsCourseReminderModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      />
    </article>
  );
};

export default MyBookings;
