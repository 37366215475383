import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IDynamicBlock } from 'common/types';

type InitialState = {
  dynamicBlockContent: IDynamicBlock | undefined;
};

const initialState: InitialState = {
  dynamicBlockContent: undefined,
};

export const slice = createSlice({
  name: 'frontend',
  initialState,
  reducers: {
    reset: () => initialState,
    setDynamicBlockContent: (state, action: PayloadAction<IDynamicBlock | undefined>) => {
      state.dynamicBlockContent = action.payload;
    },
  },
});

export const {
  reset,
  setDynamicBlockContent
} = slice.actions;

export const selectDynamicBlockContent = (state: RootState): IDynamicBlock | undefined =>
  state.frontend.dynamicBlockContent;

export default slice.reducer;
