import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import gsap from 'gsap';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';
import { removeToken } from 'common/helpers';
import { selectSignUpDriver, selectSignUpLicense } from 'modules/auth/slice';
import useAppSelector from 'hooks/useAppSelector';
import DefaultForm from './DefaultForm';

const SignUpForm = () => {
  const licenseDetails = useAppSelector(selectSignUpLicense);
  const component = useRef<HTMLDivElement>(null);
  const { push } = useHistory();
  const signUpDriver = useAppSelector(selectSignUpDriver);

  useEffectOnce(() => {
    removeToken();
  });

  useEffect(() => {
    if (licenseDetails === null) push('/register-v2/id-verification');
  }, []);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex w-full flex-col md:max-w-[600px]"
    >
      <section className="relative m-auto mt-[-70px] min-h-[100vh] w-full max-w-[1000px] md:mt-0 md:flex md:px-[20px]">
        <div className="mt-[15px] flex min-h-[calc(100vh)] flex-auto items-start justify-center pt-[45px] md:mx-0 md:justify-start">
          <div className="w-full">
            <div className="absolute flex h-[25px] w-[25px] items-center justify-center rounded-full bg-flexi-green-1">
              <a href="/register-v2/id-verification">
                <ArrowIcon className="rotate-180 fill-white" />
              </a>
            </div>
            <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:mb-[36px] md:text-[28px]">
              Personal details
            </h2>
            <DefaultForm
              accountType={signUpDriver ? signUpDriver.accountType : 1}
              token={signUpDriver?.token}
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default SignUpForm;
