import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { PageTitle } from 'components/Typography';
import { ReactComponent as IconSometimes } from 'assets/images/personal_sometimes.svg';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectPendingUpdate,
  selectPlans,
  selectPlansStatus,
  selectUser,
  selectUserStatus,
  setUpdateUserPlanStatus,
} from 'modules/me/slice';
import Card from './Card';
import ListAndRates from './ListAndRates';
import Included from './Included';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import ChangePlanModal from './ChangePlanModal';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { IRegisterPlan, RequestStatusEnum } from 'common/types';
import PendingLoader from 'features/RegisterVerification/PendingLoader';
import { PLANS } from 'common/constants';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';

function MyPlanSelection() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus);
  const PLAN_TYPE = user?.type?.name;
  const [planSelected, setPlanSelected] = useState<IRegisterPlan | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<string | null>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const plans = useAppSelector(selectPlans);
  const plansStatus = useAppSelector(selectPlansStatus);
  const isPending = useAppSelector(selectPendingUpdate);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
      if(!pageStatus()){
        gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
      }
  }, [plansStatus, userStatus]);

  const getPlan = () => {
    if (PLAN_TYPE) {
      return PLANS[PLAN_TYPE]?.find((i) => i.name === user?.plan?.name);
    }
  };
  const plan = getPlan();

  useEffect(() => {
    if (user) {
      setIsRendered(true);
    }
  }, [user]);

  useEffect(() => {
    if (isRendered) {
      setCurrentPlan(user?.plan?.name);
    }
  }, [isRendered]);

  useEffect(() => {
    if (PLAN_TYPE === 'personal') {
      dispatch({ type: ACTIONS.FETCH_VEHICLE_LIST });
    }
  }, [PLAN_TYPE]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.GET_PLANS });

    dispatch(setUpdateUserPlanStatus(null));
  });
  useUpdateEffect(() => {
    if (plan) setPlanSelected(plans[plan.id - 1]);
    else setPlanSelected(plans[0]);
  }, [plan, plans]);

  const pageStatus = () => {
    return (
      plansStatus === RequestStatusEnum.PENDING ||
      userStatus === RequestStatusEnum.PENDING
    );
  };

  useEffect(() => {
    if (!isModalOpen) {
      setIsButtonDisabled(false)
    }
  }, [isModalOpen])

  return pageStatus() ? (
    PendingLoader()
  ) : (
    <article ref={component} className="fadein relative mx-[10px] mt-[50px] mb-[100px] flex flex-col gap-4 md:mx-0 md:mt-[100px]">    
      <Helmet>
        <title>Plan Details - Plan Selection</title>
        <meta name="description" content="My Flexicar Account - Plan Details - Plan Selection" />
      </Helmet>
      <div className="flex gap-[25px] md:gap-[100px]">
        <section
          className={classNames(
            'min-w-auto relative flex h-fit flex-col gap-[10px]  rounded-[10px_10px_10px_10px] bg-flexi-black-6 p-[50px] px-[15px] before:absolute before:right-0 before:top-0 before:h-[60px] before:w-[5px] before:bg-flexi-green-1 before:duration-200 md:min-w-[400px] md:p-[60px] md:before:h-[143px]',
            {
              'before:top-[43px] md:before:top-[60px]': planSelected?.id === 1,
              'before:top-[142px] md:before:top-[245px]':
                planSelected?.id === 2,
              'before:top-[243px] md:before:top-[435px] md:before:h-[120px]':
                planSelected?.id === 3,
            },
          )}
        >
          {plans?.map((plan: IRegisterPlan) => {
            return (
              <div
                key={plan.id}
                className="mb-[20px] flex cursor-pointer flex-col items-center gap-[10px] border-b-[1px] border-flexi-black-7 pb-[20px] md:items-start"
                onClick={() => setPlanSelected(plan)}
              >
                <IconSometimes className="h-[20px] md:h-[30px]" />
                <p className="text-[16px] font-bold capitalize md:text-[16px]">
                  {plan.name.toLocaleLowerCase().replace(`${user?.type?.name}`,'')}
                </p>
                <p className="hidden text-[14px] md:flex">{plan.description}</p>
              </div>
            );
          })}
        </section>
        <section className="flex  md:min-w-[500px] flex-col items-start">
          <PageTitle>select a plan</PageTitle>
          <div className="flex justify-center gap-5">
            <Card plan={planSelected} key={planSelected?.id} />
          </div>

          <ListAndRates />
          <Included inclusions={planSelected?.inclusions} />

          <button
            onClick={() => setIsModalOpen(true)}
            className={classNames(
              'btn btn-primary mt-8 h-[50px] md:h-[60px] w-[calc(100vw-175px)] rounded-full capitalize disabled:text-white md:w-full',
            )}
            disabled={user?.plan?.id === planSelected?.id || isPending}
            // disabled={planSelected === user?.plan?.id}
          >
            Change Plan
          </button>
        </section>
      </div>
      {/* if (updateUserPlanStatus === RequestStatusEnum.SUCCESS) */}

      <ChangePlanModal
          planSelected={planSelected}
          currentPlan={currentPlan}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isButtonDisabled={isButtonDisabled}
          setIsButtonDisabled={setIsButtonDisabled}
      />
    </article>
  );
}

export default MyPlanSelection;
