import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AppContent from 'components/layouts/AppContent';
import BookingDetails from 'features/BookingDetails';
import MyBookings from 'features/MyBookings';

const MyTripsPage = () => {

  return (
    <AppContent>
      <Switch>
        <Route exact path="/app/my-bookings" component={MyBookings} />
        <Route
          exact
          path={`/app/my-bookings/booking-details`}
        >
          <BookingDetails/>
        </Route>
      </Switch>
    </AppContent>
  );
};

export default MyTripsPage;
