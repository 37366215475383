import { SAGA_ACTIONS as AUTH_ACTIONS } from 'modules/auth/sagas';
import { SAGA_ACTIONS as ME_ACTIONS } from 'modules/me/sagas';
import { SAGA_ACTIONS as VEHICLE_ACTIONS } from 'modules/vehicle/sagas';
import { SAGA_ACTIONS as OCR_ACTIONS } from 'modules/ocr/sagas';
import { SAGA_ACTIONS as TRANSACTION_ACTIONS } from 'modules/transaction/sagas';
import { SAGA_ACTIONS as BOOKING_ACTIONS } from 'modules/booking/sagas';
import { SAGA_ACTIONS as FAVORITES_ACTIONS } from 'modules/favorites/sagas';
import { SAGA_ACTIONS as PODS_ACTIONS } from 'modules/pods/sagas';
import { SAGA_ACTIONS as INVOICE_ACTIONS } from 'modules/invoice/sagas';
import { SAGA_ACTIONS as BUSINESS_ACTIONS } from 'modules/business/sagas';
import { SAGA_ACTIONS as ANNOUNCEMENT_ACTIONS } from 'modules/announcement/sagas';
import { SAGA_ACTIONS as PUBLIC_ACTIONS } from 'modules/public/sagas';
import { SAGA_ACTIONS as FRONTEND_ACTIONS } from 'modules/frontend/sagas';

const ACTIONS = {
  ...AUTH_ACTIONS,
  ...ME_ACTIONS,
  ...VEHICLE_ACTIONS,
  ...OCR_ACTIONS,
  ...TRANSACTION_ACTIONS,
  ...BOOKING_ACTIONS,
  ...FAVORITES_ACTIONS,
  ...PODS_ACTIONS,
  ...INVOICE_ACTIONS,
  ...BUSINESS_ACTIONS,
  ...ANNOUNCEMENT_ACTIONS,
  ...PUBLIC_ACTIONS,
  ...FRONTEND_ACTIONS,
};

export default ACTIONS;
