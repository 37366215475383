import React from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { setLoginModalOpen } from 'modules/auth/slice';

export const SignInLink = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="block lg:hidden">
        <button
          onClick={() => dispatch(setLoginModalOpen(true))}
          className="text-[12px] font-bold text-[#FF7800]"
        >
          Sign in
        </button>
      </div>
    </>
  );
};
