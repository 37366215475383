import ReportIssuesLayout from 'components/layouts/ReportIssuesLayout';
import React from 'react';

const MobileOcrPendingPage = () => {
  return (
    <ReportIssuesLayout>
      <section className="flex w-full max-w-[600px] flex-col">
        <h2 className="font-primary mb-[36px] text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[40px]">
          Your Application is&nbsp;Pending
        </h2>
        <p className="mb-[16px] text-[18px]">
          Feel free to contact us at t: 1300 36 37 80 or info@flexicar.com.au
          for any&nbsp;concerns.
        </p>
      </section>
    </ReportIssuesLayout>
  );
};

export default MobileOcrPendingPage;
