import React, { useState, useRef, useLayoutEffect } from 'react';

import { useMediaQuery } from 'react-responsive';
import Lottie from 'react-lottie';
import gsap from 'gsap';

import animationData from 'assets/json/icon-reset-pw-success.json';
import { selectPending } from 'modules/auth/slice';
import useAppSelector from 'hooks/useAppSelector';
import LoginForm from 'features/LoginForm';
import Modal from 'components/Modal';

const OptimizedForgotPasswordConfirmSuccess = () => {
  const isPending = useAppSelector(selectPending);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 425px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative m-auto mt-[85px] flex min-h-[calc(100vh-125px)] w-full max-w-[80vw] flex-col items-center md:mt-[100px] md:max-w-[500px]"
    >
      <div className="grow md:grow-0">
        <div className="flex h-[200px] w-full items-center">
          <Lottie options={defaultOptions} height={120} width={120} />
        </div>
        <h2 className="font-primary mb-[15px] mt-[20px] text-center text-[30px] font-[900] uppercase text-primary md:mb-[18px] md:text-[40px]">
          great!
        </h2>
        <p className="mb-[70px] text-center text-[16px] md:text-[18px]">
          You have successfully reset your password.&nbsp;
          <br className="hidden sm:block" />
          Please use your new password{' '}
          <span className="whitespace-nowrap">when signing in</span>.
        </p>
      </div>
      <button
        className={`btn btn-primary h-[60px] w-full rounded-full text-[16px] capitalize ${
          isPending ? 'loading' : ''
        }`}
        onClick={() => {
          if (isMobile) window.location.href = '/';
          else setIsModalOpen(true);
        }}
      >
        Return to Sign In
      </button>

      <Modal
        width={isMobile ? '' : 'w-[490px]'}
        maxWidth={isMobile ? '' : 'w-[490px]'}
        hasCloseButton={false}
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        bgColor="bg-flexi-white-1"
        px={isMobile ? 'px-[25px]' : '!px-[60px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <LoginForm />
      </Modal>
    </section>
  );
};

export default OptimizedForgotPasswordConfirmSuccess;
