import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import { useLocation } from 'react-router-dom';
import useBusiness from 'hooks/useBusiness';
import { useMediaQuery } from 'react-responsive';

const SignupStepper = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const { isBusinessDriverAccount, isBusinessManagerAccount } = useBusiness();
  const [level, setLevel] = useState(0);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [stepper, setStepper] = useState<string[]>([
    'create an account',
    'email verification',
    'id verification',
    'your information',
    'plan selection',
    'payment',
    'done',
  ]);

  useEffect(() => {
    if (user?.redirectPreviousBusinessName) {
      setStepper([
        'create an account',
        'update email',
        'email verification',
        'plan selection',
        'payment',
        'done',
      ]);
      setLevel(
        pathname === '/register/create-account'
          ? 0
          : pathname === '/register/update-email'
          ? 1
          : pathname === '/register/otp'
          ? 2
          : pathname === '/register/plan'
          ? 3
          : pathname === '/register/payment'
          ? 4
          : 5,
      );
    } else if (isBusinessManagerAccount) {
      setStepper([
        'create an account',
        'mobile verification',
        'plan details',
        'payment',
        'done',
      ]);
      setLevel(
        pathname === '/register/create-account' ||
          pathname === '/register-business'
          ? 0
          : pathname === '/register/otp' ||
            pathname === '/register-business/otp'
          ? 1
          : pathname === '/register/plan' ||
            pathname === '/register-business/plan'
          ? 2
          : pathname === '/register/payment' ||
            pathname === '/register-business/payment'
          ? 3
          : 4,
      );
    } else if (isBusinessDriverAccount) {
      setStepper([
        'create an account',
        'email verification',
        'id verification',
        'your information',
        'done',
      ]);

      setLevel(
        pathname === '/register/create-account'
          ? 0
          : pathname === '/register/otp'
          ? 1
          : pathname === '/register/verification' ||
            pathname === '/register/declined'
          ? 2
          : pathname === '/register/information'
          ? 3
          : 4,
      );
    } else {
      setLevel(
        pathname === '/register/create-account' ||
          pathname === '/register-v2' ||
          pathname === '/register-v2/id-verification'
          ? 0
          : pathname === '/register/otp' || pathname === '/register-v2/form'
          ? 1
          : pathname === '/register/verification' ||
            pathname === '/register/declined' ||
            pathname === '/register-v2/otp'
          ? 2
          : pathname === '/register/information' ||
            pathname === '/register-v2/ocr' ||
            pathname === '/register-v2/verify-failed' ||
            pathname === '/register-v2/address-verification'
          ? 3
          : pathname === '/register/plan' ||
            pathname === '/register-v2/plan' ||
            pathname === '/register-v2/plan-personal' ||
            pathname === '/register-v2/plan-student'
          ? 4
          : pathname === '/register/payment' ||
            pathname === '/register-v2/payment'
          ? 5
          : 6,
      );
    }
  }, [user, location]);

  return isMobile ? (
    <div className="fixed left-0 z-[10] flex w-screen gap-1">
      {[...new Array(stepper.length - 1)].map((_, i) => {
        return (
          <div
            key={i}
            className={classNames('h-[5px] w-full', {
              'bg-flexi-orange-1': level >= i+1,
              'bg-flexi-black-6 ': level < i+1 || pathname === '/register-v2',
            })}
          ></div>
        );
      })}
    </div>
  ) : (
    <div className="w-full gap-1 flex">
      {[...new Array(stepper.length - 1)].map((_, i) => {
        return (
          <div
            key={i}
            className={classNames('h-[5px] w-full', {
              'bg-flexi-orange-1': level >= i+1,
              'bg-flexi-black-6 ': level < i+1 || pathname === '/register-v2',
            })}
          ></div>
        );
      })}
    </div>
  );
};

export default SignupStepper;
