import React, { useEffect, useState } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import { useEffectOnce } from 'react-use';
import BookingFields from './BookingFields';
import BookingMap from './BookingMap';
import { GOOGLE_API } from 'common/constants';
import { useJsApiLoader } from '@react-google-maps/api';
import useFavorites from 'hooks/useFavorites';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectFirstLoginStatus,
  selectIsAnnualFeeNotPaid,
  selectUser,
} from 'modules/me/slice';
import { useMediaQuery } from 'react-responsive';
import Modal from 'components/Modal';
import {
  setApplyPromoStatus,
  setBookingPromo,
} from 'modules/transaction/slice';
import {
  getToken,
  reloadPageOnce,
  getIsNotified,
  setIsNotified,
  getIsCourseDueNotified,
  getIsIdVerificationNotified,
  setIsIdVerificationNotified,
} from 'common/helpers';
import useBusiness from 'hooks/useBusiness';
import { RequestStatusEnum } from 'common/types';
import { Helmet } from 'react-helmet';
import { ReactComponent as Filter } from 'assets/images/filter.svg';

import classNames from 'classnames';
import LottieAnimation from 'components/LottieAnimation';
import welcomeLottie from 'assets/json/icon-welcome-flexi.json';
import AnnualModal from 'components/AnnualModal';
import PaymentMethodMessageModal from 'components/PaymentMethodMessageModal';
import LicenseExpiredNotificationModal from 'components/LicenseExpiredNotificationModal';
import License2YNotificationModal from 'components/License2YNotificationModal';
import HasUnpaidModal from 'components/HasUnpaidModal';
import CourseEndDateModal from 'components/CourseEndDateModal';

const Booking = () => {
  const dispatch = useAppDispatch();
  const { fetchFavorites } = useFavorites();
  const [libraries] = useState<any>(['places']);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAnnualModalOpen, setIsAnnualModalOpen] = useState(false);
  const [licenseExpiredModalOpen, setLicenseExpiredModalOpen] = useState(false);
  const [license2YModalOpen, setLicense2YModalOpen] = useState(false);
  const user = useAppSelector(selectUser);
  const hasOutstandingMembershipFee = useAppSelector(selectIsAnnualFeeNotPaid);
  const { isBusinessDriverAccount } = useBusiness();
  const loginStatus = useAppSelector(selectFirstLoginStatus);
  const token = getToken();
  reloadPageOnce();
  const [openModal, setOpenModal] = useState(false);
  const [isHasUnpaidModalOpen, setHasUnpaidModalOpen] = useState(false);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [isCourseReminderModalOpen, setIsCourseReminderModalOpen] =
    useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const reload = queryParameters.get('reload');

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const isFirstLogin = () => {
    if (isBusinessDriverAccount) {
      const findBusinessProfile = user?.profiles.find(
        (profile) => profile.type.toLocaleLowerCase() === 'business',
      );
      const firstLogin = findBusinessProfile?.isFirstLogin;
      return firstLogin;
    }
  };
  const handleClose = () => {
    if (isFirstLogin()) {
      const payload = {
        key: 'is_first_login',
      };
      if (loginStatus !== RequestStatusEnum.PENDING)
        dispatch({ type: ACTIONS.FIRST_LOGIN, payload });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (user && isFirstLogin()) {
      setModalOpen(true);
    } else if (
      user?.twoYearReverificationOverdue &&
      user?.isForReverification
    ) {
      setLicense2YModalOpen(true);
    } else if (
      getIsIdVerificationNotified() !== 'notified' &&
      !user?.twoYearReverificationFlag &&
      user?.isForReverification
    ) {
      setLicenseExpiredModalOpen(true);
    } else if (
      user?.isLicenseExpired &&
      user?.isForReverification
    ) {
      setLicenseExpiredModalOpen(true);
    } else if (
      user?.status.toLocaleLowerCase() === 'pending' &&
      user?.statusRemarks?.some(
        (remark) =>
          remark.toLocaleLowerCase().includes('card_name_mismatch') ||
          remark.toLocaleLowerCase().includes('no card on file') ||
          remark.toLocaleLowerCase().includes('cc_same_name'),
      )
    ) {
      setPaymentMethodModalOpen(true);
    } else if (user && user.hasUnpaidInvoice) {
      setHasUnpaidModalOpen(true);
    }
  }, [user]);

  useEffect(() => {
    if (
      (getIsCourseDueNotified() !== 'notified' &&
        (user?.course?.isCourseEndDateExpiringInAWeek ||
          user?.course?.isCourseEndDateExpired)) ||
      (user?.plan?.isStudentSwitchedToPersonal && !user.plan.isAccepted) || (user?.course?.isNullCourseEndDate)
    ) {
      setIsCourseReminderModalOpen(true);
    }
  }, [user]);

  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API,
    libraries,
  });

  const [isReloading, setIsReloading] = useState(false);

  useEffectOnce(() => {
    if (token) {
      fetchFavorites();
      dispatch({ type: ACTIONS.FETCH_VEHICLE_FILTERS_AUTH });
      dispatch({ type: ACTIONS.FETCH_WALLET });
    } else {
      dispatch({ type: ACTIONS.FETCH_VEHICLE_FILTERS });
    }
    if (reload) {
      setIsReloading(true);
    }
    dispatch(setApplyPromoStatus(null));
    dispatch(setBookingPromo(null));
  });

  useEffect(() => {
    if (hasOutstandingMembershipFee && getIsNotified() !== 'notified') {
      setIsAnnualModalOpen(true);
    }
  }, [hasOutstandingMembershipFee]);

  return (
    <section className="relative">
      <Helmet>
        <title>Book Now</title>
        <meta name="description" content="Flexicar Book Now" />
      </Helmet>

      {isReloading ? (
        <div className="absolute top-0 left-0 z-[100] h-[100%] w-[100vw] animate-pulse bg-gray-300 duration-500">
          {' '}
        </div>
      ) : (
        <div>
          <Filter
            className="pointer-events-auto absolute right-[20px] top-[20px] z-[99] block h-[40px] w-[40px] rounded-[5px] bg-[#F2F2F2] p-[8px] lg:hidden"
            onClick={() => setOpenModal(true)}
          />
          <div
            className={classNames('absolute block lg:hidden', {
              'opacity-0 duration-200': !openModal,
              'z-[99] opacity-100 duration-200': openModal,
            })}
          >
            <BookingFields isMapLoaded={isMapLoaded} />
          </div>
          <div className="hidden lg:block">
            <BookingFields isMapLoaded={isMapLoaded} />
          </div>
          <div onClick={() => setOpenModal(false)}>
            <BookingMap isMapLoaded={isMapLoaded} mapLoadError={mapLoadError} />
          </div>
          <Modal
            isModalOpen={modalOpen}
            handleClose={handleClose}
            px={isMobile ? 'px-[25px]' : 'px-[120px]'}
            mx={isMobile ? '30px' : '0px'}
          >
            <LottieAnimation
              lottieFile={welcomeLottie}
              isStopped={!modalOpen}
            />
            <h1 className="my-[20px] text-center text-[20px] font-bold lg:text-[34px]">
              Welcome to Flexi Business
            </h1>
            <p className="mb-[40px] text-center text-[14px] lg:text-[18px]">
              You&apos;ve been added to {user?.organisation?.name} business
              account as a&nbsp;driver.
            </p>
            <button
              className="!lg:h-[60px] btn !h-[50px] w-full rounded-full border-none bg-flexi-green-1 font-bold text-white outline-none hover:bg-flexi-green-1"
              onClick={handleClose}
            >
              Ok
            </button>
          </Modal>
          <AnnualModal
            isModalOpen={isAnnualModalOpen}
            handleClose={() => {
              setIsNotified();
              setIsAnnualModalOpen(false);
            }}
          />
          <PaymentMethodMessageModal
            setPaymentMethodModalOpen={setPaymentMethodModalOpen}
            statusRemarks={user?.statusRemarks}
            isModalOpen={paymentMethodModalOpen}
            handleClose={() => {
              setPaymentMethodModalOpen(false);
            }}
            hasCloseButton={false}
            enableClickAway={false}
          />
          <CourseEndDateModal
            isModalOpen={isCourseReminderModalOpen}
            hasCloseButton={!user?.course?.isNullCourseEndDate}
            enableClickAway={!user?.course?.isNullCourseEndDate}
            handleClose={() => {
              setIsCourseReminderModalOpen(false);
            }}
            px={isMobile ? 'px-[25px]' : 'px-[120px]'}
            mx={isMobile ? '30px' : '0px'}
          />
          <LicenseExpiredNotificationModal
            isModalOpen={licenseExpiredModalOpen}
            handleClose={() => {
              if (user?.isLicenseExpired) {
                setLicenseExpiredModalOpen(true);
              } else {
                setLicenseExpiredModalOpen(false);
                setIsIdVerificationNotified();
              }
            }}
            px={isMobile ? 'px-[25px]' : 'px-[120px]'}
            mx={isMobile ? '30px' : '0px'}
            hasCloseButton={!user?.isLicenseExpired}
          />
          <License2YNotificationModal
            isModalOpen={license2YModalOpen}
            hasCloseButton={!user?.twoYearReverificationOverdue}
            handleClose={() => {
              if (!user?.twoYearReverificationOverdue)
                setLicense2YModalOpen(false);
            }}
            px={isMobile ? 'px-[25px]' : 'px-[120px]'}
            mx={isMobile ? '30px' : '0px'}
          />
          <HasUnpaidModal
            isModalOpen={isHasUnpaidModalOpen && token !== null}
            handleClose={() => {
              if (!user?.hasUnpaidInvoice) setHasUnpaidModalOpen(false);
            }}
            hasCloseButton={!user?.hasUnpaidInvoice}
          />
        </div>
      )}
    </section>
  );
};

export default Booking;
