import { PayloadAction } from '@reduxjs/toolkit';
import { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import { removeInvoiceNo, setInvoiceBlob } from 'common/helpers';
import { RequestStatusEnum } from 'common/types';
import { fetchMe } from 'modules/me/sagas';
import { toast } from 'react-toastify';
import { put, call } from 'redux-saga/effects';
import {
  setPending,
  setOutstandingBalances,
  setPendingPayOutstandingBalance,
  setPendingPaySingleInvoice,
  setPaySingleInvoiceStatus,
  setInvoiceUrl,
  setOutstandingBalanceStatus,
} from './slice';

export const SAGA_ACTIONS = {
  FETCH_OUSTANDING_BALANCES: 'FETCH_OUSTANDING_BALANCES',
  PAY_OUTSTANDING_BALANCE: 'PAY_OUTSTANDING_BALANCE',
  PAY_SINGLE_INVOICE: 'PAY_SINGLE_INVOICE',
  PAY_SELECTED_INVOICE: 'PAY_SELECTED_INVOICE',
  DOWNLOAD_INVOICE: 'DOWNLOAD_INVOICE',
};

type FetchOutstandingBalancePayload = {
  endDate?: string | null;
  search?: string | null;
  limit?: number;
  page?: number;
  startDate?: string | null;
};

export function* fetchOutstandingBalances(
  action: PayloadAction<FetchOutstandingBalancePayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    yield put(setOutstandingBalanceStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.get(
        `/invoices/outstanding?limit=${action.payload.limit}&page=${
          action.payload.page
        }${action.payload.search ? `&search=${action.payload.search}` : ''}${
          action.payload.startDate
            ? `&start_date=${action.payload.startDate}`
            : ''
        }${
          action.payload.endDate ? `&end_date=${action.payload.endDate}` : ''
        }`,
      ),
    );
    yield put(setOutstandingBalances(data));
    yield put(setOutstandingBalanceStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setOutstandingBalanceStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPending(false));
  }
}

export function* payOutstandingBalance() {
  const api = authenticatedClient();
  try {
    yield put(setPendingPayOutstandingBalance(true));
    yield call(() => api.post(`/invoices/payment`));
    yield call(fetchMe);
    toast.success('Oustanding balance has been paid.');
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      toast.error(err.response.data?.message);
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingPayOutstandingBalance(false));
  }
}

interface PaySingleInvoicePayload {
  invoiceId: number;
}

export function* paySingleInvoice(
  action: PayloadAction<PaySingleInvoicePayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setPendingPaySingleInvoice(true));
    yield put(setPaySingleInvoiceStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/invoices/${action.payload.invoiceId}/payment`));
    yield put(setPaySingleInvoiceStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchMe);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setPaySingleInvoiceStatus(RequestStatusEnum.FAILED));
      // handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingPaySingleInvoice(false));
  }
}

interface PaySelectedInvoicePayload {
  ids: number[];
}

export function* paySelectedInvoice(
  action: PayloadAction<PaySelectedInvoicePayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setPaySingleInvoiceStatus(RequestStatusEnum.PENDING));
    yield call(() => api.post(`/invoices/payment`, action.payload));
    yield put(setPaySingleInvoiceStatus(RequestStatusEnum.SUCCESS));
    yield call(fetchMe);
    // toast.success('Oustanding balance has been paid.');
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // toast.error(err.response.data?.message);
      yield put(setPaySingleInvoiceStatus(RequestStatusEnum.FAILED));
      // handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingPaySingleInvoice(false));
  }
}

interface DownloadMultipleInvoicePayload {
  ids: number[];
}

export function* downloadInvoice(
  action: PayloadAction<DownloadMultipleInvoicePayload>,
): any {
  const api = authenticatedClient();
  try {
    const res = yield call(() =>
      api.post(`/invoices/download`, action.payload, { responseType: 'blob' }),
    );
    const invoiceUrl = URL.createObjectURL(res.data);
    yield put(setInvoiceUrl(invoiceUrl));
    setInvoiceBlob(invoiceUrl);
    window.open(`/app/account/invoices/invoice/`, '_blank');
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      toast.error(err.response.data?.message);
      handleAPIError(err.response);
    }
  } finally {
    removeInvoiceNo();
  }
}
