import React, { useEffect, useRef } from 'react';
import { CenteredPageTitle, PageTitleBanner } from 'components/Typography';
import { IContent, RequestStatusEnum } from 'common/types';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { setLoginModalOpen } from 'modules/auth/slice';
import {
  selectEnvType,
  selectPagesContents,
  selectPagesContentsCategories,
  setPagesContents,
  setPublicPending,
} from 'modules/public/slice';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const ByCategory = () => {
  const dispatch = useAppDispatch();
  const queryParameters = new URLSearchParams(location.search);
  const pagesContentsCategories = useAppSelector(selectPagesContentsCategories);
  const pagesContents = useAppSelector(selectPagesContents);
  const envType = useAppSelector(selectEnvType);
  const categoryId = Number(queryParameters.get('cid'));
  const category = pagesContentsCategories?.find(
    (category) => category.id === categoryId,
  );
  const targetContent = queryParameters.get('ref');

  const getPagesContents = async () => {
    const db = getFirestore(firebaseApp);
    const collectionName =
      envType === 'staging'
        ? 'pagesContentsQuestionsStaging'
        : 'pagesContentsQuestions';
    try {
      dispatch(setPublicPending(RequestStatusEnum.PENDING));
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where('category_id', '==', categoryId));

      const querySnapshot = await getDocs(q);

      const data: IContent[] = [];

      querySnapshot.forEach(function (doc) {
        const parsedData = JSON.parse(JSON.stringify(doc.data()));
        data.push({
          question: parsedData.question,
          answer: parsedData.answer,
          callToAction: parsedData.call_to_action,
          image: parsedData.photo,
          embedVideoUrl: parsedData.embed_video_url,
          isTopFive: parsedData.is_top_five || false,
          categoryId: parsedData.category_id,
          contentId: Number(doc.id),
        });
      });

      if (data.length > 0) {
        dispatch(setPagesContents(data));
      } else {
        dispatch(setPagesContents(null));
      }
    } catch (error) {
      dispatch(setPublicPending(RequestStatusEnum.FAILED));
    } finally {
      dispatch(setPublicPending(RequestStatusEnum.SUCCESS));
    }
  };

  useEffect(() => {
    if (pagesContentsCategories === null && envType !== null) {
      getPagesContents();
    }
  }, [envType]);

  const contentListRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      gsap.registerPlugin(ScrollToPlugin);
      if (pagesContents !== null && targetContent !== null) {
        const targetElem = document.getElementById(targetContent);
        if (targetElem !== null) {
          gsap.to(window, {
            scrollTo: { y: `#${targetContent}`, offsetY: 50 },
            duration: 0.6,
            ease: 'Circ.easeOut',
          });
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [pagesContents, targetContent]);

  const handleImageError = (e: any) => {
    e.target.classList = 'hidden';
  };

  return (
    <section ref={contentListRef}>
      <Helmet>
        <title>{category?.title}</title>
        <meta
          name="description"
          content={`Help & Support - ${category?.title}`}
        />
      </Helmet>
      <PageTitleBanner>help & support</PageTitleBanner>
      {category && pagesContents ? (
        <div className="mx-auto px-[40px] py-[120px] md:px-[120px]">
          <div className="child:answer-center mx-auto mb-[100px]">
            <CenteredPageTitle>{category?.title}</CenteredPageTitle>
          </div>
          <ul className="mx-auto flex flex max-w-[1000px] flex-col gap-[100px]">
            {pagesContents?.map((content) => {
              const video: string | null = content.embedVideoUrl?.includes(
                'https://www.youtube.com/watch?v=',
              )
                ? content.embedVideoUrl.replace(
                    'https://www.youtube.com/watch?v=',
                    'https://www.youtube.com/embed/',
                  )
                : content.embedVideoUrl || null;
              return (
                <li
                  key={`content-${content.contentId}`}
                  id={`content-${content.contentId}`}
                  className="w-full"
                >
                  <p className="font-primary mb-[30px] text-[20px] font-bold">
                    {content.question}
                  </p>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{ __html: content.answer }}
                    />
                  </p>
                  {content.image &&
                    content.image !==
                      'https://flexicar-web-media.s3.ap-southeast-2.amazonaws.com/media' && (
                      <img
                        src={content.image}
                        alt={content.question}
                        className="mx-auto mt-[40px] w-[70%]"
                        onError={handleImageError}
                      />
                    )}
                  {video && (
                    <div className="mt-[40px] w-full px-[50px]">
                      <iframe
                        src={video}
                        allow="accelerometer; autoplay; encrypted-media;"
                        className="aspect-video h-auto w-[100%]"
                      ></iframe>
                    </div>
                  )}
                  {content.callToAction === 'sign_up' ? (
                    <a
                      href='/register-v2'
                      className="btn mt-[40px] h-[30px] min-w-[300px] max-w-[350px] rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2"
                    >
                      Sign Up
                    </a>
                  ) : content.callToAction === 'sign_in' ? (
                    <button
                      onClick={() => dispatch(setLoginModalOpen(true))}
                      className="btn mt-[40px] h-[30px] min-w-[300px] max-w-[350px] rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2"
                    >
                      Sign in
                    </button>
                  ) : content.callToAction === 'pricing' ? (
                    <a
                      href="/pricing"
                      className="btn mt-[40px] h-[30px] min-w-[300px] max-w-[350px] rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2"
                    >
                      Pricing
                    </a>
                  ) : (
                    <></>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="min-h-[100vh]">
          <AiOutlineLoading3Quarters
            size="48px"
            className="mx-auto mt-[120px] w-[50px] animate-spin fill-flexi-orange-1"
          />
        </div>
      )}
    </section>
  );
};

export default ByCategory;
