import React from 'react';
import { CenteredPageTitle } from 'components/Typography';
import { ReactComponent as Icon } from 'assets/images/member_manual_icon.svg';

const MemberManual = () => {
  return (
    <div
      id="member-manual"
      className="mx-[-40px] flex flex-col items-center bg-white px-[40px] py-[120px] md:mx-[-120px] lg:px-[120px]"
    >
      <Icon className="h-[120px] w-auto" />
      <CenteredPageTitle extraClass="!text-flexi-black-1 my-[30px] md:my-[50px]">
        Member Manual
      </CenteredPageTitle>
      <p className="text-center text-[20px] leading-[30px] md:text-[30px] md:leading-[40px]">
        All the important information you need to know&nbsp;
        <br className="hidden md:block" />
        about being a <span className="whitespace-nowrap">Flexicar Member</span>
      </p>
      <a
        href="https://flexicar-web-media.s3.ap-southeast-2.amazonaws.com/media/members%20manual/latest_member_manual.pdf"
        target="blank"
        className="btn-primary btn mt-[60px] w-fit rounded-full bg-flexi-green-1 px-[40px] py-[10px] text-center text-[12px] font-bold text-white sm:text-[14px] md:px-[80px]"
      >
        Download Now
      </a>
    </div>
  );
};

export default MemberManual;
