import React, { useEffect, useRef, useLayoutEffect } from 'react';

import gsap from 'gsap';

import { useRedirectToRegistration } from 'hooks/useRedirect';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';

const RegisterDeclined = () => {
  const dispatch = useAppDispatch();

  useRedirectToRegistration();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH_ME });
  }, []);

  return (
    <section
      ref={component}
      className="fadein flex w-full max-w-[600px] flex-col items-center lg:items-start"
    >
      <h2 className="font-primary mb-[20px] text-center text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[40px] lg:text-left">
        Your application is
        <br />
        under review.
      </h2>
      <p className="text-center text-[16px] lg:text-left">
        Unfortunately, the initial checks we run to approve your account have
        not been successful and we will need to do additional checks for you to
        proceed. Please check again later.
      </p>
    </section>
  );
};

export default RegisterDeclined;
