import React from 'react';

import { IVehicleSummaryBreakdowns } from 'common/types';
import { selectUserAvailableCredits } from 'modules/me/slice';
import { selectBookingPromo } from 'modules/transaction/slice';
import useAppSelector from 'hooks/useAppSelector';

interface Props {
  breakdowns: IVehicleSummaryBreakdowns | undefined;
  toggleUseCredits: boolean;
}

const SummaryInvoice = ({ breakdowns, toggleUseCredits }: Props) => {
  const bookingPromo = useAppSelector(selectBookingPromo);
  const userCredits = useAppSelector(selectUserAvailableCredits);

  return (
    <div className="sticky top-[50px] mt-[50px] h-fit place-items-center rounded-[10px] bg-flexi-white-1 p-[30px] sm:p-[45px] lg:mt-[100px] lg:w-auto lg:p-[60px]">
      <h5 className="col-span-2 mb-[40px] text-center text-[24px] font-bold sm:text-[26px]">
        Booking Summary
      </h5>
      <ul className="flex flex-col gap-[20px] text-[16px]">
        <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
          <small className="block text-[16px]">Estimated Cost</small>
          <span className="text-[16px]">
            ${breakdowns?.estimateTotal.toFixed(2)}
          </span>
        </li>
        {breakdowns?.addons && (
          <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
            <div>
              <p className="block text-[16px]">Add-ons</p>
              <p className="text-[12px] font-bold">
                {breakdowns.addons.map((addon) => addon.name).join(', ')}
              </p>
            </div>
            <span className="text-[16px]">
              $
              {breakdowns.addons
                .reduce((sum, a) => sum + a.value, 0)
                .toFixed(2)}
            </span>
          </li>
        )}
        {bookingPromo && (
          <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
            <div>
              <p className="block text-[16px]">Promotions</p>
              <p className="text-[12px] font-bold">{breakdowns?.promo?.name}</p>
            </div>
            <span className="text-[16px]">
              -${breakdowns?.promo?.actualValue.toFixed(2)}
            </span>
          </li>
        )}
        {toggleUseCredits &&
          userCredits?.availableCredits && userCredits?.availableCredits > 0 ? (
            <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
              <small className="block text-[16px]">Credits</small>
              <span className="text-[16px]">
                $
                {toggleUseCredits && breakdowns?.total
                  ? userCredits &&
                    userCredits?.availableCredits < breakdowns?.total
                    ? userCredits?.availableCredits.toFixed(2)
                    : breakdowns?.total.toFixed(2)
                  : (0).toFixed(2)}
              </span>
            </li>
          ): <></>}
        {toggleUseCredits && breakdowns?.total ? (
          userCredits &&
          userCredits?.availableCredits < breakdowns?.total && (
            <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
              <small className="block text-[16px]">Payment Method</small>
              <span className="text-[16px]">
                $
                {(breakdowns?.total - userCredits?.availableCredits).toFixed(2)}
              </span>
            </li>
          )
        ) : (
          <li className="flex items-center justify-between gap-4 border-b-[1px] border-dotted border-flexi-black-6 pb-[.5px] text-sm">
            <small className="block text-[16px]">Payment Method</small>
            <span className="text-[16px]">
              ${breakdowns?.total?.toFixed(2)}
            </span>
          </li>
        )}
      </ul>
      <div className="mt-[20px] flex justify-between">
        <div>
          <p className="text-[20px] font-bold sm:text-[22px]">Total</p>
          <p className="text-[10px]">GST Included</p>
        </div>
        <p className="self-center text-[24px] font-bold sm:text-[26px]">
          ${breakdowns?.total?.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default SummaryInvoice;
