import classNames from 'classnames';
import { IRegisterPlanInclusions } from 'common/types';
import React from 'react';

interface Props {
  inclusions: IRegisterPlanInclusions[] | undefined;
}

const Inclusions = ({ inclusions }: Props) => {
  return (
    <article>
      <h2 className="mb-[30px] text-center md:text-left text-[20px] md:text-3xl font-bold">What&apos;s included?</h2>
      <div className="flex flex-col justify-between">
        {inclusions?.map((inclusion, i) => {
          return (

          // <div key className="collapse">
          //   <input type="checkbox" className="peer" /> 
          //   <div className="collapse-title bg-primary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content">
          //   {inclusion.title}
          //   </div>
          //   <div className="collapse-content bg-primary text-primary-content peer-checked:bg-secondary peer-checked:text-secondary-content"> 
          //     <p>{inclusion.description}</p>
          //   </div>
          // </div>
            <div
              key={inclusion.id}
              tabIndex={0}
              className={classNames(
                'collapse-arrow collapse border-base-300 bg-base-100 pr-0 !outline-none',
                {
                  'mb-[30px]': i !== inclusions.length - 1,
                },
              )}
            >
             <input type="checkbox" className="peer absolute" /> 
              <div className="collapse-title !min-h-0 border-b-[1px] pl-0 pt-0 text-[16px] md:text-[16px] font-bold after:!right-[0.5rem] after:!top-0 after:!shadow-[2px_2px] after:!shadow-flexi-orange-1">
                {inclusion.title}
              </div>
              <div className="collapse-content bg-flexi-black-8 px-[20px]">
              <div className="pt-[20px] text-[14px]" dangerouslySetInnerHTML={{__html: inclusion.description}} />
                {/* <p className="pt-[20px] text-[14px]">{inclusion.description}</p> */}
              </div>
            </div>
          );
        })}
      </div>
    </article>
  );
};

export default Inclusions;
