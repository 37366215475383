import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Onboarding from 'features/Onboarding';
import { Helmet } from 'react-helmet';

const OnboardingPage = () => {

  return (
    <main className='h-full w-full fixed top-0'>
      <Helmet>
        <title>Onboarding</title>
        <meta name="Onboarding" content="Welcome to Flexicar" />
      </Helmet>
      <article className="pt-[110px] pb-[40px] px-[30px] h-full bg-[#F2F2F2]">
        <Switch>
          <Route exact path="/onboarding">
            <Onboarding />
          </Route>
        </Switch>
      </article>
    </main>
  )
}

export default OnboardingPage;