
  
export const emailNotifs = [
    {
        name: 'all invoices',
        slug:'invoices'
    },
    {
        name: 'trip confirmation',
        slug:'trip_confirmation'
    },
    {
        name: 'modified trip details',
        slug:'edit_trip_details'
    },
    {   
        name: 'cancelled trips',
        slug:'cancelled_trips'
    },
];  
  
export interface IHours {
    id:number;
    day: string;
    opensAt: string;
    closesAt: string;
    status: number;
};
  