import React from 'react';
import MyAccountNav from 'components/MyAccountNav';
import { PageTitle } from 'components/Typography';
import { getInvoiceBlob } from 'common/helpers';
import { Helmet } from 'react-helmet';

const MyAccountInvoice = () => {
  const invoiceUrl = getInvoiceBlob();
  return invoiceUrl ? (
    <iframe
      src={invoiceUrl}
      className="fixed top-0 left-0 z-[1000] h-[100vh] w-[100vw]"
    />
  ) : (
    <article className="relative mt-[100px]">
      <Helmet>
        <title>My Account - Invoice</title>
        <meta name="description" content="My Flexicar Account - invoice" />
      </Helmet>
      <MyAccountNav title="invoices" />
      <PageTitle>invoices</PageTitle>
      <h2>Invoice not found.</h2>
    </article>
  );
};

export default MyAccountInvoice;
