import React, { Dispatch, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { RequestStatusEnum } from 'common/types';
import { selectUser } from 'modules/me/slice';
import {
  selectBookingSummaryModify,
  selectCancelBookingStatus,
  selectCancellationFees,
  selectCancellationFeesStatus,
  selectPendingCancelBooking,
} from 'modules/booking/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PendingLoader from 'components/PendingLoader';
import LottieAnimation from 'components/LottieAnimation';
import successLottie from 'assets/json/icon-booking-success.json';
import cancelLottie from 'assets/json/icon-booking-cancel.json';
import Modal from 'components/Modal';
import HasUnpaidModal from 'components/HasUnpaidModal';

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  setIsModalOpen: Dispatch<boolean>;
  bookingId: number | undefined;
}

function ModalCancel({ isModalOpen, handleClose, bookingId, setIsModalOpen }: Props) {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const cancellationFeesStatus = useAppSelector(selectCancellationFeesStatus);
  const cancelBookingStatus = useAppSelector(selectCancelBookingStatus);
  const bookingSummary = useAppSelector(selectBookingSummaryModify);
  const pendingCancel = useAppSelector(selectPendingCancelBooking);
  const cancellationFees = useAppSelector(selectCancellationFees);
  const user = useAppSelector(selectUser);
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const handleCancelBooking = () => {
    const payload = {
      bookingId: bookingSummary?.id || bookingId,
      fetchList: pathname.includes('my-bookings')
    };
    dispatch({ type: ACTIONS.CANCEL_BOOKING_V2, payload });
  };

  useEffect(() => {
    if (bookingSummary) {
      dispatch({
        type: ACTIONS.FETCH_CANCELLATION_FEES_V2,
        payload: {
          bookingId: bookingSummary?.id,
        },
      });
    }
  }, [bookingSummary]);

  const renderModalContent = () => {
    if (cancelBookingStatus === RequestStatusEnum.PENDING) {
      return (
        <div className="flex h-[300px] flex-col items-center justify-center gap-4">
          <PendingLoader size={100} />
          <h4 className="text-lg">Processing your booking cancellation...</h4>
        </div>
      );
    }
    if (cancelBookingStatus === RequestStatusEnum.SUCCESS) {
      return (
        <section className="flex flex-col items-center self-center">
          <LottieAnimation
            lottieFile={successLottie}
            isStopped={!isModalOpen}
          />
          <h1 className="mb-[20px] text-center text-[20px] font-bold md:text-[34px]">
            You have successfully cancelled <span className="whitespace-nowrap">your booking.</span>
          </h1>
          <button
            className={`btn mt-[40px] h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize`}
            onClick={() => (window.parent.location.href = '/app/my-bookings')}
          >
            Ok
          </button>
        </section>
      );
    }
    if (cancelBookingStatus === RequestStatusEnum.FAILED) {
      return (
        <>
          <LottieAnimation lottieFile={cancelLottie} isStopped={!isModalOpen} />
          <h1 className="title">Failed to cancel your booking.</h1>
          <p className="text-center text-[16px] md:text-[18px]">
            Maximum cancellation retries reached. To cancel this booking, please
            contact customer care.
          </p>
          <label
            htmlFor="cancel-booking-modal"
            className={`button btn btn-primary max-h-[50px] w-full rounded-full text-[16px] capitalize md:max-h-[60px]`}
            onClick={() => push('/app/my-bookings')}
          >
            Ok
          </label>
        </>
      );
    }
    return (
      <>
        <LottieAnimation lottieFile={successLottie} isStopped={!isModalOpen} />
        <h1 className="mb-[20px] text-center text-[20px] font-bold md:text-[34px]">
          Are you sure you want to cancel your booking?
        </h1>

        {cancellationFeesStatus === RequestStatusEnum.PENDING ? (
          <div className="flex items-center justify-center">
            <span
              className={classNames(
                'mx-[10px] inline-block h-[30px] w-[30px] animate-spin rounded-full border-[1px] border-flexi-black-9 border-t-transparent text-center align-middle',
              )}
            />
          </div>
        ) : (
          cancellationFees && (
              <div
                className="text-center text-[16px] md:text-[18px]"
              dangerouslySetInnerHTML={{
                __html: cancellationFees.message,
              }}
            />
          )
        )}

        <div className="button">
          <button
            type="submit"
            className={classNames(
              `btn h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize`,
              {
                loading: pendingCancel,
              },
            )}
            onClick={handleCancelBooking}
          >
            Cancel Booking
          </button>
        </div>
      </>
    );
  };

  return (
    <>
    {user?.hasUnpaidInvoice ? (
        <HasUnpaidModal
          isModalOpen={isModalOpen && user !== null}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          px={isMobile ? 'px-[25px]' : 'px-[120px]'}
          mx={isMobile ? '30px' : '0px'}
        />
      ) : (
    <Modal
      isModalOpen={isModalOpen}
      handleClose={() => { handleClose() }}
      px={isMobile ? 'px-[25px]' : 'px-[120px]'}
      mx={isMobile ? '30px' : '0px'}
    >
      {renderModalContent()}
    </Modal>
      )}
        </>
  );
}

export default ModalCancel;
