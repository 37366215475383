/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ReactComponent as IconMonthZero } from 'assets/images/plan_monthly_zero.svg';
import { ReactComponent as IconMonth } from 'assets/images/plan_monthly.svg';
import { ReactComponent as IconHour } from 'assets/images/plan_hourly.svg';
import { ReactComponent as IconDay } from 'assets/images/plan_daily.svg';
import { Link } from 'react-router-dom';
import { IRegisterPlan } from 'common/types';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
interface Props {
  plan: IRegisterPlan | null;
  isPersonalAccount: boolean;
}

const Card = ({ plan, isPersonalAccount }: Props) => {
  const user = useAppSelector(selectUser)
  return (
    <article className="flex flex-col items-start">
      {isPersonalAccount && (
        <h3 className="mb-[20px] mt-0 text-center text-[26px] font-[700] capitalize">
          Flexi {plan?.name.toLocaleLowerCase().split(' ').includes('default') ? user?.type.name : plan?.name}
        </h3>
      )}
      <p className="md:h-[40px] text-[16px] max-w-[600px]">{plan?.description}.</p>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        {plan?.name === 'sometimes' || plan?.name === 'business' ? (
          <IconMonthZero className="min-h-[40px] min-w-[40px]" />
        ) : (
          <IconMonth className="min-h-[40px] min-w-[40px]" />
        )}
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[18px] md:text-[20px] font-bold">
            {plan?.name === 'sometimes' || plan?.name === 'business'
              ? 'No Commitment'
              : 'Monthly Commitment'}
          </p>
          <p className="text-[16px] md:text-[16px]">{plan?.commitment}</p>
        </div>
      </div>
      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconDay className="min-h-[40px] min-w-[40px]" />
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[18px] md:text-[20px] font-bold">Daily Cost</p>
          <p className="text-[16px] md:text-[16px]">{plan?.dailyNotes}</p>
        </div>
      </div>

      <div className="mt-[30px] flex items-center gap-x-[20px]">
        <IconHour className="min-h-[40px] min-w-[40px]" />
        <div>
          <p className="mb-[2px] md:mb-[10px] text-[18px] md:text-[20px] font-bold">Hourly Cost</p>
          <p className="text-[16px] md:text-[16px]">{plan?.hourlyNotes}</p>
        </div>
      </div>

      <p className="my-[30px] rounded-[10px_10px_10px_10px] p-[30px] text-[12px] md:text-[12px] tracking-[.2px] shadow-[0_2px_9px] shadow-flexi-white-2 ">
        {plan?.disclaimer}
        <Link
          className="ml-[3px] font-bold text-flexi-orange-1"
          to="/terms-and-conditions"
          target="_blank"
        >
          click here
        </Link>
        .
      </p>
    </article>
  );
};

export default Card;
