import React, { useCallback, useMemo } from 'react';

import { IDynamicBlock, TBlockContentAccount } from 'common/types';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';

interface MyAccountBlockProps {
  data: IDynamicBlock;
}

function handleUserType(
  userType: any,
  contentAccountType: TBlockContentAccount[],
  isDriver: boolean | undefined,
): boolean {
  if (!Array.isArray(contentAccountType)) {
    return false;
  }

  const userSpecificAccountType =
    userType === 'business' && isDriver
      ? 'business driver'
      : userType === 'business'
      ? 'business manager'
      : userType;

  return contentAccountType.includes(userSpecificAccountType);
}

const MyAccountBlock = ({ data }: MyAccountBlockProps) => {
  const user = useAppSelector(selectUser);

  const isAuthorized = useCallback(
    (contentAccountType: TBlockContentAccount[]) => {
      return handleUserType(
        user?.type.name.toLocaleLowerCase(),
        contentAccountType,
        user?.isDriver,
      );
    },
    [user?.type.name, data?.value],
  );

  // Check content length for specific account type
  const contentLength = useMemo(
    () =>
      data?.value.filter((content) => isAuthorized(content.account_type))
        .length,
    [user, data?.value],
  );

  return (
    <section
      className={`myAccountBlock my-[50px] flex h-fit max-w-[400px] flex-col gap-[10px] rounded-[10px_10px_10px_10px] p-[30px] md:min-h-[293px] md:min-w-[400px] md:p-[60px] lg:mt-0 ${
        contentLength === 0 ? '' : `bg-flexi-black-5`
      }`}
    >
      {data?.value.map((content, index) => {
        return isAuthorized(content.account_type) ? (
          <div
            className="mb-[10px] break-words border-b-[1px] border-flexi-black-7 pb-[20px] last:mb-[0px] last:border-b-[0px] last:pb-0"
            key={content.title + index}
          >
            {content.icon ? (
              <img
                className="h-[40px] w-[40px]"
                src={content.icon}
                alt="icon"
              />
            ) : null}
            <p className="mb-[10px] mt-[10px] text-[16px] font-bold md:text-[18px]">
              {content.title}
            </p>
            <div
              className="dynamic-content-container"
              dangerouslySetInnerHTML={{
                __html: content.description,
              }}
            />
          </div>
        ) : null;
      })}
    </section>
  );
};

export default MyAccountBlock;
