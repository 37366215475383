import React, { useEffect, useRef, useState } from 'react';
import { useEffectOnce, useClickAway, useUpdateEffect } from 'react-use';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as Arrow } from 'assets/images/arrow-down.svg';
import { selectPodTimezone } from 'modules/pods/slice';
import { selectEndDate, selectStartDate, selectTentativeEndDate, selectTentativeStartDate, setEndDate, setStartDate, setTentativeEndDate, setTentativeStartDate } from 'modules/booking/slice';
import { selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import useBusiness from 'hooks/useBusiness';
import useBooking from 'hooks/useBooking';
import ACTIONS from 'modules/rootActions';
import { addMinutes, addDays } from 'date-fns';
import classNames from 'classnames';
import moment from 'moment';
import Calendar from 'react-calendar';

const CalendarField = () => {
  const dispatch = useAppDispatch();
  const { isBusinessManagerAccount } = useBusiness();
  const { isPublic } = useBooking({});
  const podTimezone = useAppSelector(selectPodTimezone);
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const user = useAppSelector(selectUser);
  const maxBookDays = user?.organisation?.maximumBookingDays || 5;
  const [selector, setSelector] = useState<number>(0); // 0-pick-up; 1-return
  const [openPicker, setOpenPicker] = useState(false);
  const dropdownRef = useRef(null);

  const roundUpDate = () => {
    const date = addMinutes(new Date(), 15);
    const roundUpTo = (roundTo: number) => (x: any) =>
      Math.ceil(x / roundTo) * roundTo;
    const roundUpTo15Minutes = roundUpTo(1000 * 60 * 15);
    const rounded = roundUpTo15Minutes(date);
    if (podTimezone) {
      const options = { timeZone: podTimezone };
      const dateString = date.toLocaleString('en-US', options);
      const podRounded = roundUpTo15Minutes(new Date(dateString));
      return new Date(podRounded);
    }
    return new Date(rounded);
  };
  
  const tentativeStartDate = useAppSelector(selectTentativeStartDate) || roundUpDate();
  const tentativeEndDate = useAppSelector(selectTentativeEndDate) || addMinutes(roundUpDate(), 60);
  const [pickupTime, setPickupTime] = useState(roundUpDate().getHours()*60/15 + roundUpDate().getMinutes()/15);
  const [returnTime, setReturnTime] = useState(roundUpDate().getHours()*60/15 + roundUpDate().getMinutes()/15 + 4);
  const [minimum, setMinimum] = useState(roundUpDate());
  const [maximum, setMaximum] = useState(addDays(roundUpDate(), 365));

  useEffectOnce(() => {
    dispatch(setStartDate(tentativeStartDate));
    dispatch(setEndDate(tentativeEndDate));

    if (!isPublic && isBusinessManagerAccount)
      dispatch({ type: ACTIONS.FETCH_BOOKING_SETTINGS });
  });

  useClickAway(dropdownRef, () => {
    setOpenPicker(false);
  });

  const handleDateChange = (date: Date[]) => {
    if (moment(date[0]).startOf('day').add(pickupTime * 15, 'minutes').toDate() < roundUpDate()) {
      dispatch(setTentativeStartDate(roundUpDate()));
      dispatch(setTentativeEndDate(
        moment(date[1])
          .startOf('day')
          .add(returnTime * 15, 'minutes')
          .toDate(),
      ));
    } else {
      dispatch(setTentativeStartDate(
        moment(date[0])
          .startOf('day')
          .add(pickupTime * 15, 'minutes')
          .toDate(),
      ));
      dispatch(setTentativeEndDate(
        moment(date[1])
          .startOf('day')
          .add(returnTime * 15, 'minutes')
          .toDate(),
      ));
    }
  };

  const handleUpdateDates = () => {
    if (selector === 0) {
      dispatch(setStartDate(tentativeStartDate));
      dispatch(setEndDate(tentativeEndDate));
    } else {
      dispatch(
        setTentativeStartDate(
          moment(minimum)
            .startOf('day')
            .add(pickupTime * 15, 'minutes')
            .toDate(),
        ),
      );
      dispatch(
        setTentativeEndDate(
          moment(minimum)
            .startOf('day')
            .add(returnTime * 15, 'minutes')
            .toDate(),
        ),
      );
      dispatch(
        setStartDate(
          moment(minimum)
            .startOf('day')
            .add(pickupTime * 15, 'minutes')
            .toDate(),
        ),
      );
      dispatch(
        setEndDate(
          moment(minimum)
            .startOf('day')
            .add(returnTime * 15, 'minutes')
            .toDate(),
        ),
      );
    }
    setOpenPicker(false);
  };

  useUpdateEffect(() => {
    if (tentativeStartDate) {
      const roundUpStartDate = addMinutes(tentativeStartDate, 45);
      const isStartLessThanEndDate =
        roundUpStartDate.getTime() < tentativeEndDate.getTime();
      const isEndMoreThanAllowedDays =
        addDays(tentativeStartDate, maxBookDays).getTime() > tentativeEndDate.getTime();

      if (!isEndMoreThanAllowedDays) {
        dispatch(setTentativeEndDate(addDays(tentativeStartDate, maxBookDays)));
      }
      if (!isStartLessThanEndDate) {
        dispatch(setTentativeEndDate(addMinutes(tentativeStartDate, 60)));
      }
    }
  }, [tentativeStartDate]);
  
  useUpdateEffect(() => {
    if (tentativeStartDate) {
      const newDate = moment(tentativeStartDate)
        .startOf('day')
        .add(pickupTime * 15, 'minutes')
        .toDate();
      dispatch(setTentativeStartDate(newDate));
    }
  }, [pickupTime]);

  useUpdateEffect(() => {
    if (tentativeEndDate) {
      const newDate = moment(tentativeEndDate)
        .startOf('day')
        .add(returnTime * 15, 'minutes')
        .toDate();
      dispatch(setTentativeEndDate(newDate));
    }
  }, [returnTime]);

  useUpdateEffect(() => {
    if (!openPicker) {
    setMinimum(roundUpDate());
    setMaximum(addDays(roundUpDate(), 365));
    setSelector(0);
    }
  }, [openPicker])

  useUpdateEffect(() => {
    if (moment(startDate) < moment(roundUpDate())) {
      dispatch(setTentativeStartDate(roundUpDate()));
      dispatch(setStartDate(roundUpDate()));
      setPickupTime(
        roundUpDate().getHours() * 4 + roundUpDate().getMinutes() / 15,
      );
      if (moment(endDate) < moment(addMinutes(roundUpDate(), 60))) {
        dispatch(setTentativeEndDate(addMinutes(roundUpDate(), 60)));
        dispatch(setEndDate(addMinutes(roundUpDate(), 60)));
        setReturnTime(
          addMinutes(roundUpDate(), 60).getHours() * 4 +
          addMinutes(roundUpDate(), 60).getMinutes() / 15,
        );
      }
      if (moment(endDate) > moment(addDays(roundUpDate(), maxBookDays))) {
        dispatch(setTentativeEndDate(addDays(roundUpDate(), maxBookDays)));
        dispatch(setEndDate(addDays(roundUpDate(), maxBookDays)));
      }
    }
  }, [podTimezone]);

  useEffect(() => {
    if (moment(tentativeEndDate) < moment(tentativeStartDate).add(60, 'minutes')) {
      setReturnTime(pickupTime + 4);
    }
  }, [tentativeStartDate])

  return (
    <div className="flex w-full flex-auto items-center border-b-2 border-solid border-zinc-200 pb-[13px] lg:w-fit">
      <div
        className="mt-[14px] flex items-center"
        onClick={() => {
          setOpenPicker(true);
        }}
      >
        <CalendarIcon className="my-auto w-[25px]" />
        <div className="selected-dates my-auto w-fit text-[14px] font-[500]">
          <span>{moment(startDate).format('DD MMM')}</span>
          <span className="mx-[10px]">|</span>
          <span>{moment(startDate).format('h:mm A')}</span>
          <span className="mx-[15px]">-</span>
          <span>{moment(endDate).format('DD MMM')}</span>
          <span className="mx-[10px]">|</span>
          <span>{moment(endDate).format('h:mm A')}</span>
        </div>
      </div>
      {openPicker && (
        <div
          ref={dropdownRef}
          className="absolute left-[50%] top-[150px] z-10 w-fit translate-x-[-50%] bg-white px-[50px] py-[40px] shadow-lg lg:top-[80px]"
        >
          <div className="flex flex-row-reverse gap-[40px]">
            <div className="flex lg:min-w-[250px] flex-col items-center justify-center gap-[40px]">
              <section className="flex flex-col gap-3">
                <p className="mx-auto pl-1 text-[15px] font-bold lg:mx-0">
                  Pick-up time
                </p>
                <div className="mx-auto flex items-center gap-2 lg:mx-0">
                  <div className="dropdown">
                    <label
                      tabIndex={0}
                      className="btn relative m-1 h-[50px] w-[134px] border-zinc-300 bg-white !text-[16px] font-normal text-flexi-black-2 hover:bg-white"
                    >
                      {moment()
                        .startOf('day')
                        .add(pickupTime * 15, 'minutes')
                        .format('hh:mm A')}
                    </label>
                    <ul
                      tabIndex={0}
                      className="scrollbar hide-scrollbar dropdown-content menu rounded-box grid  max-h-[225px] w-full grid-cols-1 overflow-auto !rounded-tr-none bg-base-100 p-2 !text-center shadow !flex-col"
                    >
                      {[...new Array(96)].map((_, i) => {
                        let date = new Date();

                        if (podTimezone) {
                          date = new Date(
                            new Date().toLocaleString('en-US', {
                              timeZone: podTimezone,
                            }),
                          );
                        }

                        const isMinuteBefore =
                          moment(roundUpDate()) >
                            moment(date)
                              .startOf('day')
                              .add(i * 15, 'minutes') &&
                          date.getDate() === tentativeStartDate.getDate();

                        return (
                          <li
                            key={i}
                            className={classNames(
                              'flex items-center text-[16px]',
                              {
                                'pointer-events-none hidden !text-zinc-200':
                                  isMinuteBefore,
                                'mx-[12px] rounded-[7.5px] bg-flexi-black-5':
                                  pickupTime === i,
                              },
                            )}
                          >
                            <a onClick={() => setPickupTime(i)}>
                              {moment()
                                .startOf('day')
                                .add(i * 15, 'minutes')
                                .format('hh:mm A')}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </section>
              <section className="flex flex-col gap-3">
                <p className="mx-auto pl-1 text-[15px] font-bold lg:mx-0">
                  Return time
                </p>
                <div className="mx-auto flex items-center gap-2 lg:mx-0">
                  <div className="dropdown">
                    <label
                      tabIndex={0}
                      className="btn relative m-1 h-[50px] w-[134px] border-zinc-300 bg-white !text-[16px] font-normal text-flexi-black-2 hover:bg-white"
                    >
                      {moment()
                        .startOf('day')
                        .add(returnTime * 15, 'minutes')
                        .format('hh:mm A')}
                    </label>
                    <ul
                      tabIndex={0}
                      className="scrollbar hide-scrollbar dropdown-content menu rounded-box grid  max-h-[225px] w-full grid-cols-1 overflow-auto !rounded-tr-none bg-base-100 p-2 !text-center shadow !flex-col"
                    >
                      {[...new Array(96)].map((_, i) => {
                        let date = new Date();

                        if (podTimezone) {
                          date = new Date(
                            new Date().toLocaleString('en-US', {
                              timeZone: podTimezone,
                            }),
                          );
                        }

                        const isMinuteBefore =
                          selector === 0 &&
                          tentativeStartDate.getDate() ===
                            tentativeEndDate.getDate() &&
                          moment(date)
                            .startOf('day')
                            .add(pickupTime * 15 + 60, 'minutes') >
                            moment(date)
                              .startOf('day')
                              .add(i * 15, 'minutes');

                        const isMinuteAfter =
                          (selector === 0 &&
                            moment(date)
                              .startOf('day')
                              .add(pickupTime * 15, 'minutes') <
                              moment(date)
                                .startOf('day')
                                .add(i * 15, 'minutes') &&
                            tentativeEndDate.getDate() ===
                              addDays(
                                tentativeStartDate,
                                maxBookDays,
                              ).getDate()) ||
                          (selector === 1 &&
                            moment(date)
                              .startOf('day')
                              .add(pickupTime * 15 + 60, 'minutes') >
                              moment(date)
                                .startOf('day')
                                .add(i * 15, 'minutes'));

                        return (
                          <li
                            key={i}
                            className={classNames(
                              'flex items-center text-[16px]',
                              {
                                'pointer-events-none hidden !text-zinc-200':
                                  isMinuteAfter || isMinuteBefore,
                                'mx-[12px] rounded-[7.5px] bg-flexi-black-5':
                                  returnTime === i,
                              },
                            )}
                          >
                            <a onClick={() => setReturnTime(i)}>
                              {moment()
                                .startOf('day')
                                .add(i * 15, 'minutes')
                                .format('hh:mm A')}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </section>
              <button
                className="btn-primary btn h-[45px] min-h-[45px] min-w-[134px] rounded-full px-7 normal-case disabled:text-white lg:mt-0 lg:flex lg:w-auto"
                onClick={handleUpdateDates}
              >
                Apply
              </button>
            </div>

            <div className="mx-[20px] w-[0.2px] bg-[#d7d7d7]"></div>

            <Calendar
              className={classNames('mx-auto w-[360px] border-0')}
              selectRange
              defaultActiveStartDate={tentativeStartDate || undefined}
              defaultValue={[tentativeStartDate, tentativeEndDate]}
              minDate={minimum}
              maxDate={maximum}
              prev2Label={null}
              next2Label={null}
              prevLabel={<Arrow className="mx-auto rotate-90" />}
              nextLabel={<Arrow className="mx-auto -rotate-90" />}
              onClickDay={(selectedDate) => {
                if (selector === 0) {
                  setMinimum(selectedDate);
                  setMaximum(addDays(selectedDate, maxBookDays));
                  setSelector(1);
                } else {
                  setMinimum(roundUpDate());
                  setMaximum(addDays(roundUpDate(), 365));
                  setSelector(0);
                }
              }}
              onChange={(value) => {
                const obj = JSON.parse(JSON.stringify(value));
                handleDateChange(obj);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CalendarField;