import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Link } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import moment from 'moment';

import { ReactComponent as Wing } from 'assets/images/wing.svg';
import { ReactComponent as IconPlus } from 'assets/images/plus.svg';
import { ReactComponent as IconCheck } from 'assets/images/check.svg';
import { ReactComponent as Credits } from 'assets/images/credits.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { RequestStatusEnum, IVehicleSummaryBreakdowns } from 'common/types';
import { IMAGE_URL } from 'common/constants';
import { InvalidText } from 'components/Typography';
import {
  selectDefaultPaymentCard,
  selectUser,
  selectUserAvailableCredits,
} from 'modules/me/slice';
import { selectVehicleSummary } from 'modules/vehicle/slice';
import {
  selectEndDate,
  selectModifyBookingId,
  selectPendingCreateBooking,
  selectStartDate,
} from 'modules/booking/slice';
import {
  selectApplyPromoStatus,
  selectErrorPromo,
  selectPendingPromo,
  selectPromoSuccess,
  setApplyPromoStatus,
  setBookingPromo,
  setErrorPromo,
} from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import CustomInput from 'components/CustomInput';
import SummaryInvoice from './SummaryInvoice';

interface Props {
  breakdowns: IVehicleSummaryBreakdowns | undefined;
  breakdownTotal: number;
  selectedVehicle: number | null;
  reduceExcessTotal: number;
  requireCostCentre: boolean | undefined;
  setHasFailedPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethodModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleUseCredits: boolean;
  setToggleUseCredits: React.Dispatch<React.SetStateAction<boolean>>;
}

const SummaryForm = ({
  breakdowns,
  selectedVehicle,
  breakdownTotal,
  reduceExcessTotal,
  setHasFailedPaymentModalOpen,
  setPaymentModalOpen,
  setPaymentMethodModalOpen,
  requireCostCentre,
  toggleUseCredits,
  setToggleUseCredits,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    businessDrivers,
    isBusinessAccount,
    isBusinessManagerAccount,
    isBusinessDriverAccount,
  } = useBusiness();
  const user = useAppSelector(selectUser);
  const userCredits = useAppSelector(selectUserAvailableCredits);
  const errorPromo = useAppSelector(selectErrorPromo);
  const bookingId = useAppSelector(selectModifyBookingId);
  const successPromo = useAppSelector(selectPromoSuccess);
  const isPendingPromo = useAppSelector(selectPendingPromo);
  const vehicleSummary = useAppSelector(selectVehicleSummary);
  const isPending = useAppSelector(selectPendingCreateBooking);
  const today = new Date();
  const endDate = useAppSelector(selectEndDate) || new Date();
  const startDate = useAppSelector(selectStartDate) || new Date();
  const applyPromoStatus = useAppSelector(selectApplyPromoStatus);
  const defaultPaymentCard = useAppSelector(selectDefaultPaymentCard);
  const [isImageExist, setIsImageExist] = useState<boolean>(true);
  const [bookingReference, setBookingReference] = useState('');
  const [imageName, setNewImage] = useState<string>('');
  const [costCentre, setCostCentre] = useState('');
  const [driver, setDriver] = useState<number>(0);
  const [promoCode, setPromoCode] = useState('');

  const params = new URLSearchParams(location.search);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_USER_AVAILABLE_CREDITS });
    if (isBusinessManagerAccount) {
      dispatch({ type: ACTIONS.FETCH_BUSINESS_DRIVERS });
      dispatch({ type: ACTIONS.FETCH_BOOKING_SETTINGS });
    }
  });

  useEffect(() => {
    if (defaultPaymentCard)
      try {
        setNewImage(
          require(`assets/images/card-${defaultPaymentCard?.type}.svg`),
        );
      } catch (ex) {
        setIsImageExist(false);
      }
  }, [defaultPaymentCard]);

  const handlePromoCode = () => {
    if (promoCode !== '') {
      const payload = {
        code: promoCode,
        category: 'booking',
        amount: breakdownTotal,
        startsAt: JSON.stringify(startDate).slice(1, 20),
        endsAt: JSON.stringify(endDate).slice(1, 20),
        podId: vehicleSummary?.podId,
        vehicleId: vehicleSummary?.id,
      };
      dispatch({ type: ACTIONS.VERIFY_PROMO, payload });
    } else {
      dispatch(setErrorPromo(null));
      dispatch(setBookingPromo(null));
      dispatch(setApplyPromoStatus(null));
    }
  };

  const handleChangeExcess = () => {
    dispatch({ type: ACTIONS.CHANGE_EXCESS });
  };
  const [costCentreError, setCostCentreError] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const code = successPromo && promoCode !== '' ? promoCode : undefined;
    const reference = isEmpty(bookingReference) ? undefined : bookingReference;
    if (user?.hasUnpaidInvoice) {
      setHasFailedPaymentModalOpen(true);
    } else if (
      isBusinessDriverAccount &&
      requireCostCentre &&
      costCentre === ''
    ) {
      setCostCentreError(true);
    } else {
      setCostCentreError(false);
      if (isBusinessManagerAccount) {
        if (driver !== 0) {
          setPaymentModalOpen(true);
          dispatch({
            type: ACTIONS.CREATE_BOOKING_BUSINESS_V2,
            payload: {
              vehicleId: selectedVehicle,
              startsAt: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
              endsAt: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
              promoCode: code,
              bookingReference: reference,
              bookingId,
              driverId: driver,
              costCentre: costCentre !== '' ? costCentre : undefined,
              addons: breakdowns?.addons
                ? breakdowns?.addons.map((addons) => addons.slug)
                : null,
              source: 'Web',
              isUseCredits: toggleUseCredits ? 1 : 0,
              creditsAmount: toggleUseCredits
                ? userCredits && userCredits?.availableCredits < breakdownTotal
                  ? userCredits?.availableCredits
                  : breakdownTotal
                : 0,
            },
          });
        } else {
          toast.error('Please select a driver');
        }
      } else {
        setPaymentModalOpen(true);
        dispatch({
          type: ACTIONS.CREATE_BOOKING_V2,
          payload: {
            vehicleId: selectedVehicle,
            startsAt: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            endsAt: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            promoCode: code,
            bookingReference: reference,
            bookingId,
            costCentre: costCentre !== '' ? costCentre : undefined,
            addons: breakdowns?.addons
              ? breakdowns?.addons.map((addons) => addons.slug)
              : null,
            source: 'Web',
            isUseCredits: toggleUseCredits ? 1 : 0,
            creditsAmount: toggleUseCredits
              ? userCredits && userCredits?.availableCredits < breakdownTotal
                ? userCredits?.availableCredits
                : breakdownTotal
              : 0,
          },
        });
      }
    }
  };

  useEffectOnce(() => {
    if (params.get('id')) {
      setDriver(Number(params.get('id')));
    }
  });

  return (
    <form onSubmit={onSubmit} className="">
      {isBusinessManagerAccount && (
        <div className="flex flex-col">
          <p className="mb-[30px] border-b-[1px] border-[#282828] pb-[10px] text-[22px]">
            Driver Details
          </p>
          <div className="relative mb-[30px] flex h-full w-full items-center">
            <select
              className="select select-bordered h-[60px] w-full"
              value={driver}
              onChange={(e) => setDriver(Number(e.target.value))}
            >
              <option disabled value={0}>
                Select a driver
              </option>
              {businessDrivers?.map((driver) => (
                <option
                  value={driver.id}
                  className="text-zinc-800"
                  key={driver.id}
                >
                  {driver?.name}
                </option>
              ))}
            </select>
            <span className="pointer-events-none absolute right-[1px] grid  h-[90%] w-[40px] place-items-center rounded-lg border-transparent bg-white">
              <IconArrow className=" fill-flexi-orange-1" />
            </span>
          </div>
        </div>
      )}
      {isBusinessAccount && (
        <div className="form-control mb-[30px] w-full flex-1">
          <p className="mb-[20px] text-[22px]">Cost Centre</p>
          <input
            type="text"
            className={`input input-bordered h-[55px] w-full`}
            onChange={(e) => setCostCentre(e.target.value)}
          />

          {costCentreError && <InvalidText>This field is required</InvalidText>}
        </div>
      )}

      <div
        className={classNames('form-control mb-[30px] w-full  flex-1 ', {
          '!mb-[50px] border-b-[1px] border-[#282828] pb-[50px]':
            isBusinessManagerAccount,
        })}
      >
        <p className="mb-[20px] text-[20px] sm:text-[22px]">
          Booking Reference
        </p>
        <input
          type="text"
          className={`input input-bordered h-[55px] w-full`}
          value={bookingReference}
          onChange={(e) => setBookingReference(e.target.value)}
        />
      </div>

      {((isBusinessDriverAccount && user?.organisation?.billingTo === '0') ||
        !isBusinessDriverAccount) && (
        <>
          <div>
            <p className=" mb-[20px] text-[20px] sm:text-[22px]">Add-ons</p>
            <ul className="mb-[30px] flex gap-4">
              <li
                className={classNames(
                  'flex h-[73px] w-full items-center justify-between rounded-lg border-[1px] border-transparent p-[15px] px-[20px] text-xs shadow-[0_2px_9px] shadow-[#000000]/10',
                  {
                    'border-primary': user?.hasReduceExcessDamageCover,
                  },
                )}
                role="button"
                onClick={handleChangeExcess}
              >
                <div className="flex gap-[15px] sm:gap-[30px]">
                  <Wing />
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-[16px] font-bold sm:text-[16px]">
                      Excess Reduction
                    </p>
                    <p className="text-[10px]">Damage cover</p>
                  </div>
                </div>
                <p className="text-[22px]">${reduceExcessTotal.toFixed(2)}</p>
              </li>
            </ul>
          </div>
          <div className="form-control mb-[30px] w-full flex-1 ">
            <p className="mb-[20px] text-[20px] sm:text-[22px]">Promo Code</p>

            <CustomInput
              title="Promo Code"
              error={errorPromo && !isPendingPromo}
            >
              <input
                type="text"
                placeholder="promo code"
                className={classNames('peer input input-bordered w-full', {
                  'border-red-500': errorPromo,
                  'disabled:!border-red-500': isPendingPromo && errorPromo,
                })}
                defaultValue={breakdowns?.promo?.code}
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                onBlur={() => {
                  handlePromoCode();
                }}
                disabled={isPendingPromo}
              />

              {isPendingPromo && (
                <span className="absolute right-[15px] h-[20px]  w-[20px] animate-spin rounded-full border-[3px] border-t-transparent " />
              )}

              {applyPromoStatus === RequestStatusEnum.SUCCESS && (
                <div className="absolute right-[15px] grid h-[90%] w-[25px] place-items-center rounded-md bg-white">
                  <IconCheck className="fill-flexi-green-1" />
                </div>
              )}
            </CustomInput>

            {applyPromoStatus === RequestStatusEnum.SUCCESS && (
              <p className="mt-[10px] ml-1 text-[12px]">Promocode Applied</p>
            )}
            <div>
              {errorPromo && (
                <InvalidText>The selected code is invalid.</InvalidText>
              )}
            </div>
          </div>
        </>
      )}

      {(isBusinessDriverAccount && user?.organisation?.billingTo === '0') ||
      (isBusinessManagerAccount && user?.organisation?.billingTo === '1') ||
      !isBusinessAccount ? (
        <div className="mt-8 flex flex-col">
          <h4 className="mb-[20px] text-[20px] sm:text-[22px]">
            Payment Method
          </h4>

          {defaultPaymentCard === null ? (
            <Link
              to="/app/account/payment-method"
              role="button"
              className="grid h-[80px] w-full place-items-center  rounded-md border-[1px] border-transparent p-[20px] py-4 text-[35px] text-flexi-orange-1 shadow-[0_2px_9px] shadow-flexi-white-2 duration-200 hover:border-[1px] hover:border-flexi-orange-1"
            >
              <IconPlus className="h-[20px] w-[20px]" />
            </Link>
          ) : (
            <div
              className="flex w-full place-items-center justify-between rounded-md border-[1px] py-5 px-[20px] shadow-[0_2px_9px] shadow-[#000000]/10 hover:border-primary sm:px-[30px]"
              role="button"
              onClick={() => setPaymentMethodModalOpen(true)}
            >
              <div className="flex gap-2 sm:gap-3">
                <p
                  style={{
                    backgroundImage: `url('${IMAGE_URL}${imageName}')`,
                    backgroundSize: '100%',
                  }}
                  className={classNames(
                    'grid w-[36px] place-items-center overflow-hidden bg-[center_left] bg-no-repeat text-center text-[10px] uppercase sm:w-[65px]',
                    {
                      'border-[1px]': !isImageExist,
                    },
                  )}
                >
                  {!isImageExist && defaultPaymentCard.type}
                </p>
                <div className="flex flex-col">
                  <p className="w-[130px] text-[14px] sm:w-[200px] sm:text-base sm:text-[14px]">
                    <span>●●●● ●●●● ●●●● </span>
                    {defaultPaymentCard.cardNumber}
                  </p>

                  <p className="text-[12px] sm:text-[14px]">
                    Expires on {defaultPaymentCard.expiryMonth}/
                    {defaultPaymentCard.expiryYear}
                  </p>
                </div>
              </div>
              <p className="text-[13px] capitalize text-flexi-black-4 sm:text-[16px]">
                default
              </p>
            </div>
          )}

          {userCredits?.availableCredits && userCredits?.availableCredits > 0 ? (
            <div
              className={classNames(
                'mt-[24px] w-full rounded-md border-[1px] py-5 px-[20px] shadow-[0_2px_9px] shadow-[#000000]/10 hover:border-primary sm:px-[30px]',
                {
                  'border-primary': toggleUseCredits,
                },
              )}
              role="button"
              onClick={() => {
                if (userCredits?.availableCredits > 0)
                  setToggleUseCredits(!toggleUseCredits);
              }}
            >
              <div
                className={classNames('flex items-center gap-2 sm:gap-3', {
                  'text-[#D7D7D7]': userCredits?.availableCredits <= 0,
                })}
              >
                <Credits
                  className={classNames(
                    'mr-[15px] h-[40px] w-[40px] fill-black',
                    {
                      'fill-[#D7D7D7]': userCredits?.availableCredits <= 0,
                    },
                  )}
                />
                <div>
                  <p className="font-bold">Use My Credits</p>
                  <p className="text-[12px]">Available credits as of {moment(today.toDateString()).format("DD/MM/YYYY")}</p>
                </div>
                <div className="ml-auto">
                  <p className="font-bold">${userCredits?.availableCredits}</p>
                </div>
              </div>
            </div>
          ) : <></>}
        </div>
      ) : (
        <></>
      )}

      <div className="lg:hidden">
        <SummaryInvoice
          breakdowns={breakdowns}
          toggleUseCredits={toggleUseCredits}
        />
      </div>

      <p className="mt-[50px] text-[16px]">
        By confirming, you are agreeing to our{' '}
        <Link
          className="font-bold text-flexi-orange-1"
          to="/terms-and-conditions"
          target="_blank"
        >
          Terms and Conditions
        </Link>
        .
      </p>
      <div className="mt-[70px] flex w-full">
        <button
          type="submit"
          className={classNames(
            'btn btn-primary btn-md relative h-[60px] w-full overflow-hidden rounded-full text-[16px] capitalize disabled:text-white',
            {
              loading: isPending,
            },
          )}
          disabled={
            isPending ||
            (defaultPaymentCard === null &&
              isBusinessDriverAccount &&
              user?.organisation?.billingTo === '0')
          }
        >
          Confirm &amp; Pay
        </button>
      </div>
    </form>
  );
};

export default SummaryForm;
