import { useHistory } from 'react-router-dom';
import { removeToken } from 'common/helpers';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from './useAppDispatch';
import { reset as resetMe } from 'modules/me/slice';
import { reset as resetOcr } from 'modules/ocr/slice';
import { reset as resetTransaction } from 'modules/transaction/slice';
import { reset as resetBooking } from 'modules/booking/slice';
import { reset as resetPods } from 'modules/pods/slice';
import { reset as resetAuth } from 'modules/auth/slice';
import { selectVehicleFilterList, setSelectedVehicleFilters } from 'modules/vehicle/slice';
import useAppSelector from './useAppSelector';
import { setBookingSettings } from 'modules/business/slice';

const useLogout = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const vehicleFilters = useAppSelector(selectVehicleFilterList);

  const logout = () => {
    dispatch({
      type: ACTIONS.LOGOUT_ACCOUNT,
    });
    dispatch(setSelectedVehicleFilters(vehicleFilters || []));
    dispatch(setBookingSettings(null));
    dispatch({type:ACTIONS.FETCH_VEHICLE_FILTERS});
  };

  const logoutApiless = () => {
    dispatch({
      type: ACTIONS.LOGOUT_ACCOUNT_APILESS,
    });
    dispatch(setSelectedVehicleFilters(vehicleFilters || []));
    dispatch(setBookingSettings(null));
    dispatch({type:ACTIONS.FETCH_VEHICLE_FILTERS});
  };
  

  const onSuccessLogout = () => {
    removeToken();
    push('/');
    dispatch(setBookingSettings(null));
    dispatch(setSelectedVehicleFilters(vehicleFilters || []));
    dispatch(resetMe())
    dispatch(resetOcr())
    dispatch(resetTransaction())
    dispatch(resetAuth())
    dispatch(resetBooking())
    dispatch(resetPods())
  };

  return { logout, logoutApiless, onSuccessLogout };
};

export default useLogout;

