import React from 'react';

interface Props {
  children: React.ReactNode;
}

const ReportIssuesLayout = ({ children }: Props) => {
  return <div className="max-w-[768px] m-auto w-full min-h-full ">{children}</div>;
};

export default ReportIssuesLayout;
