import React, { Dispatch, useState } from 'react';
import Card from './Card';
import { PLANS, IPlan } from 'common/constants';
import ListAndRates from './ListAndRates';
import Included from './Included';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';

interface Props {
  setIsChangePlanModal: Dispatch<boolean>;
  setIsConfirmChangePlanModal: Dispatch<boolean>;
  isConfirmChangePlanModal: boolean;
}

const PlanModal = ({
  setIsChangePlanModal,
  setIsConfirmChangePlanModal,
  isConfirmChangePlanModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const [planSelected, setPlanSelected] = useState<number>();

  const handleConfirm = () => {
    const payload = { planId: planSelected };
    dispatch({ type: ACTIONS.UPDATE_USER_PLAN, payload });
    setIsChangePlanModal(false);
  };

  return isConfirmChangePlanModal ? (
    <section className="relative m-auto  flex w-full max-w-[1200px] flex-col p-[75px] ">
      <h2 className="mb-[25px] text-center text-4xl font-bold">Change plan</h2>
      <p className="mb-[50px] max-w-[500px] self-center text-center text-sm">
        When you change plans, if the new plan has prepaid driving credits, you
        will be charged immediately. Subsequent payments are invoiced monthly.
      </p>
      <div>
        <div className="flex justify-center gap-5">
          {PLANS.personal.map((plan: IPlan) => (
            <Card
              plan={plan}
              key={plan.name}
              setIsConfirmChangePlanModal={setIsConfirmChangePlanModal}
              setPlanSelected={setPlanSelected}
            />
          ))}
        </div>
        <ListAndRates />
        <Included />
      </div>
    </section>
  ) : (
    <section className="relative m-auto  flex w-full max-w-[750px] flex-col items-center p-[75px] text-center ">
      <h2 className="mb-[50px] text-center text-4xl font-bold">
        Are you sure you want to change your plan?
      </h2>
      <p>
        By clicking the confirm button below, your annual/monthly fee might
        change based on the selected plan.
      </p>
      <label
        htmlFor=""
        className="btn m-2 mt-10 w-[150px] rounded-full"
        onClick={handleConfirm}
      >
        Confirm
      </label>
      <label
        htmlFor="change-plan-modal"
        className="cursor-pointer font-bold underline"
        onClick={() => setIsConfirmChangePlanModal(true)}
      >
        Cancel
      </label>
    </section>
  );
};

export default PlanModal;
