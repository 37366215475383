import React, { useState } from 'react';

import { addMinutes } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { ReactComponent as IconFailed } from 'assets/images/cancel_booking.svg';
import {
  IBooking,
  IBookingListPaginated,
  RequestStatusEnum,
} from 'common/types';
import {
  selectBookingOngoingStatus,
  selectBookingPastStatus,
  selectBookingUpcomingStatus,
} from 'modules/booking/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import CancelModal from '../BookingDetails/ModalCancel';
import ModalModify from '../BookingDetails/ModalModify';
import Modal from 'components/Modal';
import Pagination from 'components/Pagination/Pagination';
import Ongoing from './TripsView/Ongoing';
import Upcoming from './TripsView/Upcoming';
import Past from './TripsView/Past';

interface Props {
  bookingList: IBookingListPaginated | null;
  listType: string;
  searchValue: string;
}

function TripsList({ bookingList, listType, searchValue }: Props) {
  const dispatch = useAppDispatch();
  const bookingStatus = useAppSelector(
    listType === 'ongoing'
      ? selectBookingOngoingStatus
      : listType === 'upcoming'
      ? selectBookingUpcomingStatus
      : selectBookingPastStatus,
  );
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingId, setBookingId] = useState(0);
  const [isModifyModalOpen, setModifyModalOpen] = React.useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
  const [modifyBooking, setModifyBooking] = React.useState<IBooking | null>(
    null,
  );
  const [isCantModifyModalOpen, setIsCantModifyModalOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const roundUpDate = () => {
    const date = addMinutes(new Date(), 15);
    const roundUpTo = (roundTo: number) => (x: any) =>
      Math.ceil(x / roundTo) * roundTo;
    const roundUpTo15Minutes = roundUpTo(1000 * 60 * 15);
    const rounded = roundUpTo15Minutes(date);
    if (modifyBooking?.podTimeZone) {
      const options = { timeZone: modifyBooking?.podTimeZone };
      const dateString = date.toLocaleString('en-US', options);
      const podRounded = roundUpTo15Minutes(new Date(dateString));
      return new Date(podRounded);
    }
    return new Date(rounded);
  };

  const handleRequestBookings = (limit: number, page: number) => {
    const payload = {
      page: page || 1,
      limit: limit || 5,
      search: searchValue
    };
    if (listType === 'upcoming') {
      dispatch({ type: ACTIONS.FETCH_BOOKINGS_UPCOMING_V2, payload });
    } else if (listType === 'ongoing') {
      dispatch({ type: ACTIONS.FETCH_BOOKINGS_ONGOING_V2, payload });
    } else if (listType === 'past') {
      dispatch({ type: ACTIONS.FETCH_BOOKINGS_PAST_V2, payload });
    }
  };

  return (
    <section
      className={classNames('pb-[25px] sm:pb-[50px]', {
        'mb-[25px] sm:mb-[50px]':
          bookingList && bookingList?.data?.length !== 0,
      })}
    >
      <h1 className="border-b-[1px] pb-[10px] text-[18px] capitalize lg:text-[22px]">
        {bookingStatus !== RequestStatusEnum.PENDING &&
          bookingList &&
          bookingList?.data?.length === 0 &&
          'No'}{' '}
        {listType}
      </h1>
      <div
        className={classNames({
          'mt-[50px]': bookingList && bookingList?.data?.length !== 0,
        })}
      >
        {bookingList?.data && bookingStatus === RequestStatusEnum.SUCCESS ? (
          bookingList?.data.map((booking: IBooking, i: number) => {
            return (
              <div key={`${listType}-${i}`}>
                {listType === 'ongoing' ? (
                  <Ongoing booking={booking} i={i} />
                ) : listType === 'upcoming' ? (
                  <Upcoming
                    booking={booking}
                    i={i}
                    setBookingId={setBookingId}
                    setIsCancelModalOpen={setIsCancelModalOpen}
                    setIsCantModifyModalOpen={setIsCantModifyModalOpen}
                    setModifyBooking={setModifyBooking}
                    setModifyModalOpen={setModifyModalOpen}
                  />
                ) : listType === 'past' ? (
                  <Past booking={booking} i={i} />
                ) : (
                  <></>
                )}
              </div>
            );
          })
        ) : bookingStatus === RequestStatusEnum.PENDING ? (
          <div className="h-[100px] w-[100px] animate-spin rounded-full border-[5px] border-zinc-400 border-t-transparent border-b-transparent"></div>
        ) : (
          bookingStatus === RequestStatusEnum.FAILED && (
            <div className="">Failed to load past bookings.</div>
          )
        )}
        {bookingList && bookingList.meta.total > 0 && (
          <Pagination
            currentPageData={bookingList.meta.currentPage}
            status={bookingStatus}
            from={bookingList.meta.from}
            to={bookingList.meta.to}
            totalItems={bookingList.meta.total}
            totalPages={bookingList.meta.lastPage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setItemsPerPage}
            onPageChange={(limit: number, page: number) =>
              handleRequestBookings(limit, page)
            }
            hideLimit={false}
          />
        )}
      </div>
      {isCancelModalOpen && (
        <CancelModal
          bookingId={bookingId}
          isModalOpen={isCancelModalOpen}
          setIsModalOpen={setIsCancelModalOpen}
          handleClose={() => setIsCancelModalOpen(false)}
        />
      )}
      {isModifyModalOpen && (
        <ModalModify
          isModalOpen={isModifyModalOpen}
          setIsModalOpen={setModifyModalOpen}
          bookingId={bookingId}
          refreshMode={'upcoming'}
          roundUpDate={new Date(roundUpDate())}
          setIsCancelModalOpen={setIsCancelModalOpen}
        />
      )}
      <Modal
        isModalOpen={isCantModifyModalOpen}
        handleClose={() => setIsCantModifyModalOpen(false)}
        px={isMobile ? '!px-[50px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      >
        <IconFailed className="icon mx-auto mb-[30px]" />
        <p className="title">Unable to modify booking</p>
        <p className="text-center text-[18px]">
          Booking changes are only allowed up until 15 minutes before pickup{' '}
          <br />
          Your booking will start soon. Please rebook using the mobile app.
        </p>
        <p
          className="button btn capitalize"
          onClick={() => setIsCantModifyModalOpen(false)}
        >
          Ok
        </p>
        <div className="flex w-full items-center py-[25px]">
          <span
            className="text-flexi-errorS mx-auto cursor-pointer font-bold"
            onClick={() => {
              setIsCantModifyModalOpen(false);
              setIsCancelModalOpen(true);
            }}
          >
            Cancel Booking
          </span>
        </div>
      </Modal>
    </section>
  );
}

export default TripsList;
