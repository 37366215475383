import { takeLatest } from 'redux-saga/effects';
import {
  fetchVehicleList,
  fetchVehicleListV2,
  fetchPublicVehicleList,
  fetchNearestVehicles,
  SAGA_ACTIONS,
  fetchVehicleSummary,
  fetchVehicleFilters,
  fetchVehicleAvailable,
  updateVehicleSummary,
  fetchVehicleFiltersAuth,
  updateVehicleSummaryNoload,
  getVehicleAvailability,
  getVehicleCategoryRates,
  getVehicleRates,
  getVehicleCalculatedRates,
} from './sagas';

const {
  FETCH_VEHICLE_LIST,
  FETCH_VEHICLE_LIST_V2,
  FETCH_PUBLIC_VEHICLE_LIST,
  FETCH_NEAREST_VEHICLES,
  FETCH_VEHICLE_SUMMARY,
  FETCH_VEHICLE_FILTERS,
  FETCH_VEHICLE_FILTERS_AUTH,
  FETCH_VEHICLE_AVAILABLE,
  UPDATE_VEHICLE_SUMMARY,
  UPDATE_VEHICLE_SUMMARY_NOLOAD,
  GET_VEHICLE_AVAILABILITY,
  GET_VEHICLE_CATEGORY_RATES,
  GET_VEHICLE_RATES,
  GET_VEHICLE_CALCULATED_RATES,
} = SAGA_ACTIONS;

const actions = [
  takeLatest(FETCH_VEHICLE_LIST, fetchVehicleList),
  takeLatest(FETCH_VEHICLE_LIST_V2, fetchVehicleListV2),
  takeLatest(FETCH_PUBLIC_VEHICLE_LIST, fetchPublicVehicleList),
  takeLatest(FETCH_NEAREST_VEHICLES, fetchNearestVehicles),
  takeLatest(FETCH_VEHICLE_SUMMARY, fetchVehicleSummary),
  takeLatest(FETCH_VEHICLE_FILTERS, fetchVehicleFilters),
  takeLatest(FETCH_VEHICLE_FILTERS_AUTH, fetchVehicleFiltersAuth),
  takeLatest(FETCH_VEHICLE_AVAILABLE, fetchVehicleAvailable),
  takeLatest(UPDATE_VEHICLE_SUMMARY, updateVehicleSummary),
  takeLatest(UPDATE_VEHICLE_SUMMARY_NOLOAD, updateVehicleSummaryNoload),
  takeLatest(GET_VEHICLE_AVAILABILITY, getVehicleAvailability),
  takeLatest(GET_VEHICLE_CATEGORY_RATES, getVehicleCategoryRates),
  takeLatest(GET_VEHICLE_RATES, getVehicleRates),
  takeLatest(GET_VEHICLE_CALCULATED_RATES, getVehicleCalculatedRates),
];

export default actions;
