/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { validateEmail } from 'common/helpers';
import { RequestStatusEnum } from 'common/types';
import CustomInput from 'components/CustomInput';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { selectCreatePersonalAccountUpdateEmailStatus } from 'modules/business/slice';
import { selectPending } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { useState, useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';

function RegisterUpdateEmail() {
  const dispatch = useAppDispatch();
  const isPendingUser = useAppSelector(selectPending);
  const [email, setEmail] = useState('');
  const updateEmailStatus = useAppSelector(selectCreatePersonalAccountUpdateEmailStatus)
  //   const [isError, setError] = useState(false);
  //   useEffect(() => {
  //   }, [email]);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  const handleClick = () => {
    if (validateEmail(email)) {
      const payload = { email };
      dispatch({
        type: ACTIONS.CREATE_PERSONAL_ACCOUNT_UPDATE_EMAIL,
        payload,
      });
    }
  };

  return (
    <section ref={component} className="fadein flex w-full max-w-[700px] flex-col items-start">
      <h1 className="font-primary mb-[20px] text-[30px] md:text-[40px] font-[900] uppercase text-primary">
        email address
      </h1>
      <p className="mb-[30px]">
        Add your personal email address. Your business email address can no
        longer be&nbsp;used.
      </p>
      {isPendingUser ? (
        <PendingLoader />
      ) : (
        <>
          <CustomInput
            title="Email Address"
            margin={20}
            //   error={isError}
          >
            <input
              type={'text'}
              placeholder="email address"
              className={classNames('peer input input-bordered w-full pr-[50px]', {
                //   'border-red-500': isError,
              })}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </CustomInput>
          {/* {isError && <InvalidText>Invalid email.</InvalidText>} */}
          <button
            className={classNames(
              'btn mt-[70px] !h-[50px] md:!h-[60px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2',
              {
                'loading bg-flexi-green-2':
                updateEmailStatus === RequestStatusEnum.PENDING,
              },
            )}
            onClick={handleClick}
          >
            Next
          </button>
        </>
      )}
    </section>
  );
}

export default RegisterUpdateEmail;
