import React from 'react';
import AppContent from 'components/layouts/AppContent';
import MyDrivers from 'features/MyDrivers';

const MyDriversPage = () => {
  return (
    <AppContent>
      <MyDrivers />
    </AppContent>
  );
};

export default MyDriversPage;
