import React, { Dispatch, useState } from 'react';
import { IUserType, RequestStatusEnum, TCurrentPlan } from 'common/types';
import moment from 'moment';
import PlanModal from './PlanModal';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectPendingUpdate,
  selectUser,
  selectUserPlanStatus,
  selectUserStatus,
} from 'modules/me/slice';
import useBusiness from 'hooks/useBusiness';
import classNames from 'classnames';
import LoaderText from 'components/LoaderText';

interface Props {
  plan: TCurrentPlan | undefined;
  name: string | undefined;
  accountType: IUserType | undefined;
  setIsPlanSelectionOpen: Dispatch<boolean>;
}

const Plan = ({ plan, name, accountType, setIsPlanSelectionOpen }: Props) => {
  const isPending = useAppSelector(selectPendingUpdate);
  const [isChangePlanModal, setIsChangePlanModal] = useState<boolean>(true);
  const [isConfirmChangePlanModal, setIsConfirmChangePlanModal] =
    useState<boolean>(true);
  const isPersonalAccount =
    accountType?.name.toLocaleLowerCase() === 'personal';
  const { isBusinessAccount } = useBusiness();
  const user = useAppSelector(selectUser);
  const userPlanStatus = useAppSelector(selectUserPlanStatus);
  const userStatus = useAppSelector(selectUserStatus);
  const planStatusPending =
    userPlanStatus === RequestStatusEnum.PENDING ||
    userStatus === RequestStatusEnum.PENDING;

  const findBusinessProfile = user?.profiles.find(
    (profile) => profile.type.toLocaleLowerCase() === 'business',
  );
  const hideAnnualFee =
    plan?.type === 2 && isPersonalAccount && findBusinessProfile !== null;

  const hasBusinessAccount = () => {
    return user?.profiles.find(
      (profile) => profile.type.toLocaleLowerCase() === 'business',
    );
  };

  const modalClose = () => {
    setIsChangePlanModal(true);
    setIsConfirmChangePlanModal(true);
  };

  return (
    <article
      className={classNames(
        'mt-[10px] flex flex-col gap-4 border-t-[1px] pt-[40px] md:mt-[30px] md:pt-[50px]',
        {
          '!mt-0 !border-none': isBusinessAccount,
        },
      )}
    >
      <div className="flex">
        <div className="flex flex-auto items-center justify-between gap-5 capitalize">
          <div className="flex flex-col gap-[2px]">
            {planStatusPending ? (
              <LoaderText height="27" />
            ) : (
              <p
                className={classNames(
                  'flex gap-[3px] text-[16px] font-bold md:text-[18px] md:font-normal',
                  {
                    '!text-[26px] font-bold': isBusinessAccount,
                  },
                )}
              >
                {hasBusinessAccount()
                  ? 'Flexi - Business'
                  : `Flexi - ${name} ${isPersonalAccount ? plan?.name : ''}`}
              </p>
            )}
            <p
              className={classNames(
                'text-[12px] text-flexi-black-2 opacity-[.75]',
                {
                  '!text-[16px]': isBusinessAccount,
                },
              )}
            >
              current plan
            </p>
          </div>
          {isPersonalAccount && !hasBusinessAccount() && (
            <label
              className="cursor-pointer text-right text-[12px] font-bold text-primary md:text-sm"
              onClick={() => setIsPlanSelectionOpen(true)}
            >
              change plan
            </label>
          )}
        </div>
      </div>
      {!isBusinessAccount && (
        <>
          {!hideAnnualFee &&
            (planStatusPending ? (
              <LoaderText height="45" width="100%" />
            ) : (
              <div className="flex flex-auto items-center justify-between gap-5 capitalize">
                <div className="flex flex-col gap-[2px]">
                  <p className="text-[16px] font-bold md:text-[16px] md:font-normal">
                    Annual Member Fee
                  </p>
                  {plan?.feeDueDate && (
                    <p className="flex-none text-[12px] text-flexi-black-2 opacity-[.75]">
                      Due on: {moment(plan?.feeDueDate).format('DD MMMM YYYY')}
                    </p>
                  )}
                </div>

                <div className="flex text-[22px] font-bold">
                  <p>${plan?.fee}</p>
                </div>
              </div>
            ))}
          {planStatusPending ? (
            <LoaderText height="45" width="100%" />
          ) : (
            <div className="flex flex-auto items-center justify-between gap-5 capitalize ">
              <div className="flex flex-col gap-[2px]">
                <p className="text-[16px] font-bold md:text-[16px] md:font-normal">
                  Monthly Prepaid Credit
                </p>
                {plan?.monthlyCreditsDueDate && (
                  <p className="flex-none text-[12px] text-flexi-black-2 opacity-[.75]">
                    Due on:{' '}
                    {moment(plan?.monthlyCreditsDueDate).format('DD MMMM YYYY')}
                  </p>
                )}
              </div>
              <div className="flex text-[22px] font-bold">
                <p>${plan?.monthlyCredits}</p>
              </div>
            </div>
          )}
        </>
      )}
      {isPersonalAccount && (
        <>
          <input
            type="checkbox"
            id="change-plan-modal"
            className="modal-toggle"
          />
          <label
            className="modal"
            htmlFor={`${!isPending && 'change-plan-modal'}`}
            onClick={() => !isPending && modalClose()}
          >
            <label
              htmlFor=""
              className={`scrollbar modal-box min-w-fit p-0`}
              onClick={(e) => e.stopPropagation()}
            >
              {!isPending && (
                <label
                  htmlFor="change-plan-modal"
                  className="btn btn-circle btn-sm absolute right-2 top-2 z-[1]"
                  onClick={modalClose}
                >
                  ✕
                </label>
              )}
              {isChangePlanModal ? (
                <PlanModal
                  setIsChangePlanModal={setIsChangePlanModal}
                  setIsConfirmChangePlanModal={setIsConfirmChangePlanModal}
                  isConfirmChangePlanModal={isConfirmChangePlanModal}
                />
              ) : isPending ? (
                <div className="flex justify-center gap-5 p-[100px] ">
                  {
                    <p
                      className={`h-[100px] w-[100px] animate-spin rounded-full border-[10px] border-b-zinc-500 text-2xl font-bold `}
                    ></p>
                  }
                </div>
              ) : (
                <div className="flex flex-col p-[75px]">
                  <h1 className="form-control mt-5 mb-10 text-center text-4xl font-bold">
                    Thank you!
                  </h1>
                  <p className="mb-5 ">
                    You have successfully change your plan from{' '}
                    <span className="font-bold">{'current_plan'}</span> to{' '}
                    <span className="font-bold">{'new_plan'}</span>
                  </p>

                  <label
                    htmlFor="change-plan-modal"
                    className="btn cursor-pointer self-center rounded-full px-10 font-bold underline"
                    onClick={modalClose}
                  >
                    Ok
                  </label>
                </div>
              )}
            </label>
          </label>
        </>
      )}
    </article>
  );
};

export default Plan;
