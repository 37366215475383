import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { useJsApiLoader } from '@react-google-maps/api';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import classNames from 'classnames';
import moment from 'moment';
import gsap from 'gsap';

import { ReactComponent as ErrorIcon } from 'assets/images/booking-failed.svg';
import { PageTitle } from 'components/Typography';
import { GOOGLE_API } from 'common/constants';
import { selectQrCodeError, setQrCodeError } from 'modules/ocr/slice';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import {
  selectPending,
  selectPendingUserInfo,
  selectUser,
  selectUserInfo,
} from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import MyAccountBlock from 'components/MyAccountBlock';
import MyAccountNav from 'components/MyAccountNav';
import Modal from 'components/Modal';
import VerificationContent from './VerificationContent';
import FieldAutoComplete from './FieldAutoComplete';
import Guidelines from './Guidelines';
import { selectError, setError } from 'modules/auth/slice';

const MyAccountDriverDetails = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const user = useAppSelector(selectUser);
  const userInfo = useAppSelector(selectUserInfo);
  const isPendingQr = useAppSelector(selectPending);
  const QrCodeError = useAppSelector(selectQrCodeError);
  const reverificationError = useAppSelector(selectError);
  const isPending = useAppSelector(selectPendingUserInfo);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const [licenseExpiryDateFormat, setLicenseExpiryDateFormat] = useState('');
  const [isUpdatingLicense, setIsUpdatingLicense] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [autoLocation, setAutoLocation] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [libraries] = useState<any>(['places']);
  const [address, setAddress] = useState('');

  const component = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  useEffect(() => {
    if (userInfo?.licenseExpiry) {
      const yyyy = userInfo?.licenseExpiry.substring(6);
      const mm = userInfo?.licenseExpiry.substring(3, 5);
      const DD = userInfo?.licenseExpiry.substring(0, 2);
      const dd = Number(DD?.substring(0, 2));
      setLicenseExpiryDateFormat([mm, dd, yyyy].join('/'));
    }
  }, [isPending]);

  useEffect(() => {
    if (userInfo) setAutoLocation(userInfo?.residentialAddress);
  }, [userInfo]);

  const renderPending = ({
    width,
    height,
  }: {
    height?: string;
    width: string;
  }) => {
    return (
      <div className="flex-1 animate-pulse">
        <div
          className={`h-[${height || '25px'}] ${width} rounded bg-slate-100`}
        />
      </div>
    );
  };

  const handleSave = () => {
    if (address) {
      dispatch({
        type: ACTIONS.UPDATE_LICENSE_ADDRESS,
        payload: { residentialAddress: address },
      });
      setIsEditing(false);
      setAddress('');
    } else {
      setErrorVisible(true);
    }
  };

  useEffect(() => {
    if (errorVisible) {
      toast.error('Please select from the list of address');
      setTimeout(() => {
        setErrorVisible(false);
      }, 5000);
    }
  }, [errorVisible]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_USER_INFO });
  });

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API,
    libraries,
  });

  const handleSelectAutocomplete = async (value: any) => {
    setAutoLocation(value);
    setAddress(value);
  };

  const handleChange = (e: any) => {
    setAutoLocation(e);
  };

  useLayoutEffect(() => {
    if (isMapLoaded) {
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5,
      });
    }
  }, [isMapLoaded]);

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  return isMapLoaded ? (
    <section
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] md:mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Account - Driver Details</title>
        <meta
          name="description"
          content="My Flexicar Account - Driver Details"
        />
      </Helmet>
      <MyAccountNav title="driver details" />
      <div className="gap-[100px] lg:flex">
        <section className="w-full lg:w-[500px]">
          <PageTitle>Driver Details</PageTitle>
          <div className="mt-[10px] flex gap-x-[40px] border-t-[1px] border-flexi-black-7/50 pt-[40px] text-[16px] md:mt-[30px] md:pt-[50px] md:text-[18px]">
            <table cellPadding={0} className="">
              <tbody>
                <tr>
                  <td className="w-[240px] pb-[20px] align-top font-bold">
                    Driver&apos;s License Number
                    {isPending ? (
                      renderPending({
                        width: 'w-[200px] block sm:hidden',
                        height: '20px',
                      })
                    ) : (
                      <>
                        <p className="block flex-1 font-normal sm:hidden">
                          {userInfo?.licenseNumber}
                        </p>
                        <p
                          className={classNames(
                            'block w-fit cursor-pointer select-none font-bold text-primary sm:hidden',
                            {
                              hidden: !user?.twoYearReverificationFlag,
                            },
                          )}
                          onClick={() => {
                            setIsUpdatingLicense(true);
                            dispatch({ type: ACTIONS.QR_CODE_REVERIFICATION });
                          }}
                        >
                          Update
                        </p>
                      </>
                    )}
                  </td>
                  <td className="hidden pb-[20px] sm:block">
                    {isPending ? (
                      renderPending({ width: 'w-[200px]' })
                    ) : (
                      <p className="flex">
                        <span>{userInfo?.licenseNumber}</span>
                        <span
                          className={classNames(
                            'ml-auto w-fit cursor-pointer select-none font-bold text-primary',
                            {
                              hidden: !user?.twoYearReverificationFlag,
                            },
                          )}
                          onClick={() => {
                            setIsUpdatingLicense(true);
                            dispatch({ type: ACTIONS.QR_CODE_REVERIFICATION });
                          }}
                        >
                          Update
                        </span>
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="w-[240px] pb-[20px] align-top font-bold">
                    License Card Number
                    {isPending ? (
                      renderPending({
                        width: 'w-[200px] block sm:hidden',
                        height: '20px',
                      })
                    ) : (
                      <p className="block flex-1 font-normal sm:hidden">
                        {userInfo?.licenseCardNumber}
                      </p>
                    )}
                  </td>
                  <td className="hidden pb-[20px] sm:block">
                    {isPending ? (
                      renderPending({ width: 'w-[200px]' })
                    ) : (
                      <p className="flex">
                        <span>{userInfo?.licenseCardNumber}</span>
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="pb-[20px] align-top font-bold">
                    Expiry Date
                    {isPending ? (
                      renderPending({
                        width: 'w-[150px] block sm:hidden',
                        height: '20px',
                      })
                    ) : (
                      <>
                        <p className="block flex-1 font-normal sm:hidden">
                          {licenseExpiryDateFormat !== '' &&
                            moment(licenseExpiryDateFormat).format(
                              'DD MMMM YYYY',
                            )}
                        </p>
                        <p
                          className={classNames(
                            'block w-fit cursor-pointer select-none font-bold text-primary sm:hidden',
                            {
                              hidden:
                                !user?.canReverifyLicense ||
                                user?.twoYearReverificationFlag,
                            },
                          )}
                          onClick={() => {
                            setIsUpdatingLicense(true);
                            dispatch({ type: ACTIONS.QR_CODE_REVERIFICATION });
                          }}
                        >
                          Update
                        </p>
                      </>
                    )}
                  </td>
                  <td className="hidden w-full pb-[20px] sm:block">
                    {isPending ? (
                      renderPending({ width: 'w-[150px]' })
                    ) : (
                      <p className="flex">
                        <span>
                          {licenseExpiryDateFormat !== '' &&
                            moment(licenseExpiryDateFormat).format(
                              'DD MMMM YYYY',
                            )}
                        </span>
                        <span
                          className={classNames(
                            'ml-auto w-fit cursor-pointer select-none font-bold text-primary',
                            {
                              hidden:
                                !user?.canReverifyLicense ||
                                user?.twoYearReverificationFlag,
                            },
                          )}
                          onClick={() => {
                            setIsUpdatingLicense(true);
                            dispatch({ type: ACTIONS.QR_CODE_REVERIFICATION });
                          }}
                        >
                          Update
                        </span>
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="pb-[20px] align-top font-bold">
                    License Country of Issue
                    {isPending ? (
                      renderPending({
                        width: 'w-[150px] block sm:hidden',
                        height: '20px',
                      })
                    ) : (
                      <p className="block flex-1 font-normal sm:hidden">
                        {userInfo?.licenseCountry}
                      </p>
                    )}
                  </td>
                  <td className="hidden pb-[20px] sm:block">
                    {isPending ? (
                      renderPending({ width: 'w-[150px]' })
                    ) : (
                      <p className="flex-1">{userInfo?.licenseCountry}</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="pb-[30px] align-top font-bold">
                    Residential Address
                    {isPending ? (
                      renderPending({
                        width: 'w-2/4 block sm:hidden',
                        height: '60px',
                      })
                    ) : isEditing ? (
                      <div className="block sm:hidden">
                        <FieldAutoComplete
                          autoLocation={autoLocation}
                          handleSelectAutocomplete={handleSelectAutocomplete}
                          onChange={handleChange}
                        />
                      </div>
                    ) : (
                      <p className=" block font-normal sm:hidden">
                        {userInfo?.residentialAddress}
                      </p>
                    )}
                  </td>

                  <td className="hidden pb-[20px] sm:block">
                    {isPending ? (
                      renderPending({
                        width: 'w-2/4 hidden sm:block',
                        height: '60px',
                      })
                    ) : isEditing ? (
                      <div className="hidden sm:block">
                        <FieldAutoComplete
                          autoLocation={autoLocation}
                          handleSelectAutocomplete={handleSelectAutocomplete}
                          onChange={handleChange}
                        />
                      </div>
                    ) : (
                      <p className=" hidden font-normal sm:block">
                        {userInfo?.residentialAddress}
                      </p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="font-bold md:pb-[20px]">
                    <div className="block sm:hidden">
                      {isEditing ? (
                        <p
                          className="w-fit cursor-pointer select-none font-bold text-primary"
                          onClick={handleSave}
                        >
                          Save Changes
                        </p>
                      ) : (
                        <p
                          className="w-fit cursor-pointer select-none font-bold text-primary"
                          onClick={() => setIsEditing(true)}
                        >
                          Edit
                        </p>
                      )}
                    </div>
                  </td>
                  <td className="hidden pb-[20px] sm:block">
                    {isEditing ? (
                      <p
                        className="w-fit cursor-pointer select-none font-bold text-primary"
                        onClick={handleSave}
                      >
                        Save Changes
                      </p>
                    ) : (
                      <p
                        className="w-fit cursor-pointer select-none font-bold text-primary"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>
      <Modal
        isModalOpen={isUpdatingLicense}
        handleClose={() => {
          setIsUpdatingLicense(false);
          dispatch(setQrCodeError(null));
          dispatch(setError(null));
        }}
        px={isMobile ? 'px-[25px]' : ''}
        mx={isMobile ? '30px' : ''}
      >
        <>
          {isPendingQr ? (
            <div className="flex min-h-[30vh] flex-col">
              <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
                ID VERIFICATION
              </h1>
              <AiOutlineLoading3Quarters className=" m-auto h-[50px] w-[50px] animate-spin fill-primary" />
            </div>
          ) : reverificationError ? (
            <>
              <ErrorIcon className="icon" />
              <h1 className="mb-[20px] text-center text-[20px] font-bold lg:text-[34px]">
                {reverificationError}
              </h1>
              <button
                className="button rounded-full border-none bg-flexi-green-1 hover:bg-flexi-green-2"
                onClick={() => {
                  setIsUpdatingLicense(false);
                  dispatch(setQrCodeError(null));
                  dispatch(setError(null));
                }}
              >
                OK
              </button>
            </>
          ) : QrCodeError || !user?.ocrId ? (
            <>
              <ErrorIcon className="icon" />
              <h1 className="mb-[20px] text-center text-[20px] font-bold lg:text-[34px]">
                Unable to update license expiry
              </h1>
              <p className="text-center text-[14px] lg:text-[18px]">
                Your request to update your license might have expired. For
                membership information please contact us at:
                <br />
                <br />
                <a href="tel:1300363780" className="font-bold text-primary">
                  1300 36 37 80
                </a>
                <br />
                <a
                  href="mailto:info@flexicar.com.au"
                  className="font-bold text-primary"
                >
                  info@flexicar.com.au
                </a>
              </p>
              <button
                className="button rounded-full border-none bg-flexi-green-1 hover:bg-flexi-green-2"
                onClick={() => push('/app/account/support')}
              >
                Contact Us
              </button>
            </>
          ) : (
            <>
              <h1 className="title text-center text-[20px] font-bold md:text-[34px]">
                ID VERIFICATION
              </h1>
              <div className="mt-[20px]">
                <Guidelines />
                {isMobile ? (
                  <a href={`https://staging.hertz.idkit.co/${user?.ocrId}`}>
                    <button className="h-[50px] w-full rounded-full bg-flexi-green-1 font-bold text-white md:h-[60px]">
                      Proceed
                    </button>
                  </a>
                ) : (
                  <VerificationContent pending={isPendingQr} />
                )}
              </div>
            </>
          )}
        </>
      </Modal>
    </section>
  ) : (
    <section></section>
  );
};

export default MyAccountDriverDetails;
