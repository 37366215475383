import React from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { selectPending, selectUser } from 'modules/me/slice';
import { IBilling } from 'common/types';
import { selectPendingBilling } from 'modules/transaction/slice';
import { isEmpty } from 'lodash';

interface Props {
  billing: IBilling | null;
}

const PaymentInfo = ({ billing }: Props) => {
  const user = useAppSelector(selectUser);
  const isPendingTransaction = useAppSelector(selectPendingBilling);
  const isPendingMe = useAppSelector(selectPending);
  const isPending = isPendingTransaction || isPendingMe;

  const renderLoader = () => {
    return (
      <div className="flex w-full animate-pulse flex-col items-center gap-4">
        <div className="h-[30px] w-3/4 rounded bg-slate-100" />
        <div className="h-[30px] w-2/4 rounded bg-slate-100" />
        <div className="h-[60px] w-2/4 rounded bg-slate-100" />
        <div className="h-[30px] w-2/4 rounded bg-slate-100" />
        <div className="h-[20px] w-1/4 rounded bg-slate-100" />
        <div className="flex w-full justify-between">
          <div className="h-[20px] w-1/4 rounded bg-slate-100" />
          <div className="h-[20px] w-1/4 rounded bg-slate-100" />
        </div>
        <div className="flex w-full justify-between">
          <div className="h-[20px] w-1/4 rounded bg-slate-100" />
          <div className="h-[20px] w-1/4 rounded bg-slate-100" />
        </div>
      </div>
    );
  };

  return (
    <div className="mb-[40px] flex flex-col content-center items-center rounded-md bg-flexi-white-1 p-[20px] text-[20px] md:mb-[50px] md:p-[60px]">
      {isPending ? (
        renderLoader()
      ) : (
        <>
          <p className="mb-[30px] text-[18px] font-bold capitalize md:text-[20px]">
            plan summary
          </p>
          <div className="flex w-full flex-col justify-between gap-4 text-sm capitalize">
            <div className="flex items-center justify-between gap-4 text-sm">
              <p>{user?.type?.name} Annual Plan</p>
              <span className="block h-[1px] w-full flex-1 border-b-[1px]  border-dotted border-flexi-black-10/50" />

              <p className="text-right">
                ${billing?.membershipFees.fee.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 text-sm">
              <p>Monthly Prepaid Credits &#40;{user?.plan?.name}&#41;</p>
              <span className="block h-[1px] w-full flex-1 border-b-[1px]  border-dotted border-flexi-black-10/50" />
              <p className="text-right">${billing?.planFees.fee.toFixed(2)}</p>
            </div>
            {!isEmpty(billing?.membershipFees?.promo) && (
              <div className="flex items-center justify-between gap-4 text-sm">
              <div className="flex flex-col">
                <p className="">Promotions</p>
                <p className="text-[12px]">
                  &#40;{billing?.membershipFees.promo?.name}&#41;
                </p>
              </div>
              <span className="block h-[1px] w-full flex-1 border-b-[1px]  border-dotted border-flexi-black-10/50" />
              <p className="text-right ">
                {billing?.membershipFees?.promo?.type !== 'join_credit' &&
                  '-'}
                $
                {Number(billing?.membershipFees?.promo?.actualValue).toFixed(
                  2,
                )}
              </p>
            </div>
            )}
            <div className="flex items-center justify-between gap-4 text-sm">
              <p className="font-semibold">
                Total{' '}
                <span className="font-normal">&#40;GST Included&#41;</span>
              </p>
              <span className="block h-[1px] w-full flex-1 border-b-[1px]  border-dotted border-flexi-black-10/50" />
              <p className="text-right font-semibold">
                ${Number(billing?.total).toFixed(2)}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentInfo;
