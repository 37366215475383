import MyAccountNav from 'components/MyAccountNav';
import { PageTitle } from 'components/Typography';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import { INotifs, IVehicleFilter, RequestStatusEnum } from 'common/types';
import CustomCheckBox from 'components/CustomCheckbox';
import classNames from 'classnames';
import { emailNotifs, IHours } from './constant';
import MyAccountBlock from 'components/MyAccountBlock';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { useLocation } from 'react-router-dom';
import {
  selectBookingSettings,
  selectErrors,
  selectNotificationSettings,
  selectSaveSettingsStatus,
  selectSettingsStatus,
} from 'modules/business/slice';
// import { leadingZero } from 'common/helpers';
import PendingLoader from 'features/RegisterVerification/PendingLoader';
import { leadingZero, roundToQuarter } from 'common/helpers';
import { useEffectOnce } from 'react-use';
import gsap from 'gsap';
import ErrorModal from 'components/ErrorModal';
import { setErrorModal } from 'modules/auth/slice';
import { Helmet } from 'react-helmet';

const MyAccountSettings = () => {
  const dispatch = useAppDispatch();
  const [toggleCostCentre, setToggleCostCentre] = useState(false);
  const [toggleEmailNotifs, setToggleEmailNotifs] = useState<any>([]);
  const [dropDownHours, setDropDownHours] = useState<IHours[]>([
    {
      id: 0,
      day: 'monday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
    {
      id: 1,
      day: 'tuesday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
    {
      id: 2,
      day: 'wednesday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
    {
      id: 3,
      day: 'thursday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
    {
      id: 4,
      day: 'friday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
    {
      id: 5,
      day: 'saturday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
    {
      id: 6,
      day: 'sunday',
      opensAt: '00:00',
      closesAt: '23:45',
      status: 2,
    },
  ]);
  const [toggleVehicle, setToggleVehicle] = useState<string[]>([]);
  const bookingSettings = useAppSelector(selectBookingSettings);
  const notificationSettings = useAppSelector(selectNotificationSettings);
  const pendingStatus = useAppSelector(selectSettingsStatus);
  const pendingSaveStatus = useAppSelector(selectSaveSettingsStatus);
  const error = useAppSelector(selectErrors);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const { pathname } = useLocation();

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_BOOKING_SETTINGS });
    dispatch({ type: ACTIONS.FETCH_NOTIFICATION_SETTINGS });
    dispatch(setErrorModal(false));
  });
  
  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  useEffect(() => {
    if (bookingSettings) {
      const newToggleVehicle: string[] = [];
      bookingSettings.vehicleTypes.map(
        (vehicle: IVehicleFilter) =>
          vehicle.status && newToggleVehicle.push(vehicle.slug),
      );
      const newDropDownHours: IHours[] = [];
      bookingSettings.businessHours?.map((businessHour, i) => {
        const opensAt = businessHour.opensAt;
        const closesArray = businessHour.closesAt.split(':');
        const closesAt = `${closesArray[0]}:${leadingZero(
          roundToQuarter(parseInt(closesArray[1])),
          2,
        )}`;
        const status =
          parseInt(businessHour.status) === 0
            ? 0
            : opensAt === '00:00' && closesAt === '23:45'
            ? 2
            : parseInt(businessHour.status);
        return newDropDownHours.push({
          id: i,
          day: businessHour.day,
          opensAt,
          closesAt,
          status,
        });
      });
      setToggleVehicle(newToggleVehicle);
      setToggleCostCentre(bookingSettings.requireCostCentre);
      if (newDropDownHours.length > 0) setDropDownHours(newDropDownHours);
    }
  }, [bookingSettings]);

  useEffect(() => {
    if (notificationSettings?.notifications) {
      setToggleEmailNotifs(notificationSettings.notifications);
    }
  }, [notificationSettings]);

  const handleCheckboxChange = (e: any, slug: string) => {
    const newToggleVehicle: string[] = [...toggleVehicle];
    if (e.target.checked) {
      newToggleVehicle.push(slug);
    } else {
      const index = newToggleVehicle.indexOf(slug);
      if (index > -1) {
        newToggleVehicle.splice(index, 1);
      }
    }
    setToggleVehicle(newToggleVehicle);
  };

  const handleToggleEmailNotifs = (slug: string) => {
    const newToggleNotifs = [...toggleEmailNotifs];
    if (!newToggleNotifs.includes(slug)) {
      newToggleNotifs.push(slug);
    } else {
      const index = newToggleNotifs.indexOf(slug);
      if (index > -1) {
        newToggleNotifs.splice(index, 1);
      }
    }
    setToggleEmailNotifs(newToggleNotifs);
  };

  const handleNewDropDownHours = (
    time: string,
    object: any,
    route?: string,
  ) => {
    const newObject = [...dropDownHours];
    let [hour, minute, day] = time.split(/[:\s]/g);
    const id = object.id;
    hour =
      day === 'am' && hour === '12'
        ? '00'
        : day === 'pm'
        ? (parseInt(hour) + 12).toString()
        : hour;
    if (time === 'closed') {
      newObject[id].status = 0;
    } else if (time === 'wholeday') {
      newObject[id].status = 2;
    } else {
      // if (
      //   (route === 'from' &&
      //     time === '00:00 am' &&
      //     object.closesAt === '23:45') ||
      //   (route === 'to' && time === '11:45 pm' && object.opensAt === '00:00')
      // ) {
      //   newObject[id].status = 2;
      // } else {
      newObject[id].status = 1;
      if (route === 'from') newObject[id].opensAt = `${hour}:${minute}`;
      else newObject[id].closesAt = `${hour}:${minute}`;
      // }
    }
    if (
      newObject[id].status !== 0 &&
      newObject[id].opensAt === '00:00' &&
      newObject[id].closesAt === '23:45'
    ) {
      newObject[id].status = 2;
    }
    setDropDownHours(newObject);
  };

  const handleSaveSettings = () => {
    const businessHours: any = [];
    dropDownHours.map((dropDown: IHours) => {
      const status02 = dropDown.status === 0 || dropDown.status === 2;
      return businessHours.push({
        day: dropDown.day,
        opensAt: status02 ? `00:00` : `${dropDown.opensAt}`,
        closesAt: status02 ? `23:45` : `${dropDown.closesAt}`,
        status: dropDown.status !== 2 ? dropDown.status : 1,
      });
    });

    const payload = {
      vehicleTypes: toggleVehicle,
      requireCostCentre: toggleCostCentre ? '1' : '0',
      businessHours,
    };
    const payload2 = {
      email: toggleEmailNotifs,
    };

    dispatch({ type: ACTIONS.SAVE_BOOKING_SETTINGS, payload });
    dispatch({ type: ACTIONS.SAVE_NOTIFICATION_SETTINGS, payload: payload2 });
  };

  const hourOptionStatus = (
    status: number,
    direct: string,
    closeArray: any,
    openArray: any,
  ) => {
    const hour = direct === 'left' ? openArray[0] : closeArray[0];
    const minute = direct === 'left' ? openArray[1] : closeArray[1];
    const openTime = openArray.join(':');
    const closeTime = closeArray.join(':');

    return status === 0
      ? 'closed'
      : (openTime === '00:00' && closeTime === '23:45') || status === 2
      ? 'wholeday'
      : `${
          parseInt(hour) === 0
            ? '12'
            : `${parseInt(hour) % 12 < 10 ? '0' : ''}${parseInt(hour) % 12}`
        }:${minute}${parseInt(hour) < 12 ? ' am' : ' pm'}`;
  };

  return (
    <section
      ref={component}
      className="fadein relative mx-[25px] mt-[100px] lg:mx-0"
      // onClick={() => setToggleHours([])}
    >
      <Helmet>
        <title>My Account - Settings and Notifications</title>
        <meta
          name="description"
          content="My Flexicar Account - Settings and Notifications"
        />
      </Helmet>
      <MyAccountNav title="settings &amp; notifications" />
      <div className="w-full justify-between gap-x-[100px] md:flex">
        <article className="flex flex-col md:min-w-[500px]">
          <PageTitle>settings &amp; notifications</PageTitle>

          {pendingStatus === RequestStatusEnum.PENDING ? (
            PendingLoader()
          ) : (
            <>
              <p className="mb-[30px] mt-[25px] border-b-[1px] border-b-[#282828] pb-[10px] text-[20px] md:mt-[50px] md:text-[22px]">
                Vehicle Types
              </p>
              <p className="text-[16px] md:text-[16px]">
                Select which vehicle types your drivers can book and hours they
                can&nbsp;rent.
              </p>
              <div className="mt-[30px] grid grid-cols-2 gap-y-[15px] text-[16px] md:text-[16px]">
                {bookingSettings?.vehicleTypes.map(
                  (vehicle: IVehicleFilter) => {
                    return (
                      <CustomCheckBox
                        key={vehicle.slug}
                        title={vehicle.name}
                        size={10}
                        isCapitalize={true}
                        titleClass={`${
                          (vehicle.name === 'suv' ||
                            vehicle.name === 'ev' ||
                            vehicle.name === 'ute') &&
                          'uppercase'
                        }`}
                      >
                        <input
                          type="checkbox"
                          id={`${vehicle.slug}`}
                          name={`${vehicle.slug}`}
                          className="checkbox checkbox-xs h-[20px] w-[20px] rounded-md"
                          defaultChecked={vehicle.status}
                          onChange={(e) =>
                            handleCheckboxChange(e, vehicle.slug)
                          }
                          style={{
                            // @ts-ignore
                            '--chkfg': '0',
                            '--chkbg': '0',
                            backgroundColor: 'white',
                          }}
                        />
                      </CustomCheckBox>
                    );
                  },
                )}
              </div>

              <p className="mb-[30px] mt-[25px] border-b-[1px] border-b-[#282828] pb-[10px] text-[20px] md:mt-[50px] md:text-[22px]">
                Driver Requirement
              </p>
              <div className="flex justify-between gap-[15px] text-[16px] md:gap-0 md:text-[16px]">
                <p>Cost Centre or Department required for&nbsp;drivers</p>
                <span
                  className={classNames(
                    `relative flex h-[30px] w-[55px] cursor-pointer items-center rounded-full bg-[#dbdbdb] duration-200
                    before:absolute before:left-[10%] before:h-[22px] before:w-[22px] before:rounded-full before:bg-[#b2b2b2] before:duration-200`,
                    {
                      '!bg-flexi-orange-2 before:!left-[calc(90%-22px)] before:!bg-flexi-orange-1':
                        toggleCostCentre,
                    },
                  )}
                  onClick={() => setToggleCostCentre(!toggleCostCentre)}
                />
              </div>

              <p className="mb-[30px] mt-[25px] border-b-[1px] border-b-[#282828] pb-[10px] text-[20px] md:mt-[50px] md:text-[22px]">
                Business Hours
              </p>
              <p className="mb-[30px]">
                Select the days and times your employees can book.
              </p>
              <div className="flex justify-between">
                <table className="">
                  <tbody>
                    {dropDownHours.map((item: IHours, i: number) => {
                      const closeArray = item.closesAt.split(':');
                      const openArray = item.opensAt.split(':');

                      return (
                        <tr className="flex items-center pb-[20px]" key={i}>
                          <td className="w-[75px] text-[16px] capitalize md:w-[135px] md:text-[16px]">
                            {item.day}
                          </td>
                          <td>
                            {/* <div
                              className="relative !items-start"
                              onClick={(e) =>
                                handleToggleDropDownHours(e, i, 0)
                              }
                            > */}
                            {/* className="inline-flex shrink-0 roudned-md relative h-[35px] w-[35px] max-h-[35px] max-w-[100px] justify-start border-[1px] border-zinc-300 bg-white pl-[15px] !text-left text-[14px] font-normal text-flexi-black-4 hover:bg-white md:max-h-[60px]  md:max-w-[160px] md:h-[60px]  md:w-[160px] md:pl-[25px] md:text-[14px]"                           > */}

                            <label
                              tabIndex={0}
                              className=" relative flex items-center rounded-lg border-[1px] hover:border-flexi-black-4"
                            >
                              <IconArrow className="pointer-events-none absolute right-[15px] h-[10px] w-[10px] fill-flexi-orange-1 peer-focus:translate-x-[50px] md:right-[18px] md:h-[14px] md:w-[14px]" />
                              <select
                                className="peer h-[60px] w-[160px] cursor-pointer rounded-lg px-[25px] uppercase"
                                onChange={(e) =>
                                  handleNewDropDownHours(
                                    e.target.value,
                                    item,
                                    'from',
                                  )
                                }
                                value={hourOptionStatus(
                                  item.status,
                                  'left',
                                  closeArray,
                                  openArray,
                                )}
                              >
                                <option value="closed">closed</option>
                                <option value="wholeday">whole day</option>
                                {[...new Array(96)].map((_, j) => {
                                  const time = `${
                                    Math.floor(j / 4) % 12 < 10 &&
                                    !(Math.floor(j / 4) % 12 === 0)
                                      ? '0'
                                      : ''
                                  }${
                                    Math.floor(j / 4) % 12 === 0
                                      ? '12'
                                      : Math.floor(j / 4) % 12
                                  }:${j % 4 === 0 ? '0' : ''}${(j % 4) * 15}${
                                    j < 96 / 2 ? ' am' : ' pm'
                                  }`;
                                  return (
                                    <option value={`${time}`} key={j}>
                                      {time}
                                    </option>
                                  );
                                })}
                              </select>
                            </label>
                          </td>
                          {item.status === 1 && (
                            <>
                              <td className="w-[45px] text-center text-[16px] md:text-[16px]">
                                to
                              </td>
                              <td>
                                <label
                                  tabIndex={0}
                                  className=" relative flex items-center rounded-lg border-[1px] hover:border-flexi-black-4"
                                >
                                  <IconArrow className="pointer-events-none absolute right-[15px] h-[10px] w-[10px] fill-flexi-orange-1 peer-focus:translate-x-[50px] md:right-[18px] md:h-[14px] md:w-[14px]" />
                                  <select
                                    className="peer h-[60px] w-[160px] cursor-pointer rounded-lg px-[25px] uppercase"
                                    onChange={(e) =>
                                      handleNewDropDownHours(
                                        e.target.value,
                                        item,
                                        'to',
                                      )
                                    }
                                    // defaultValue="09:30 am"
                                    // defaultValue={hourOptionStatus(
                                    //   item.status,
                                    //   'right',
                                    //   closeArray,
                                    //   openArray,
                                    // )}

                                    value={hourOptionStatus(
                                      item.status,
                                      'right',
                                      closeArray,
                                      openArray,
                                    )}
                                    // defaultValue={`00:00 am`}
                                  >
                                    <option value="closed">closed</option>
                                    <option value="wholeday">whole day</option>
                                    {/* <option value="allday">all day</option> */}
                                    {[...new Array(96)].map((_, j) => {
                                      const time = `${
                                        Math.floor(j / 4) % 12 < 10 &&
                                        !(Math.floor(j / 4) % 12 === 0)
                                          ? '0'
                                          : ''
                                      }${
                                        Math.floor(j / 4) % 12 === 0
                                          ? '12'
                                          : Math.floor(j / 4) % 12
                                      }:${j % 4 === 0 ? '0' : ''}${
                                        (j % 4) * 15
                                      }${j < 96 / 2 ? ' am' : ' pm'}`;
                                      return (
                                        <option value={`${time}`} key={j}>
                                          {time}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </label>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <p className="mb-[30px] mt-[50px] border-b-[1px] border-b-[#282828] pb-[10px] text-[20px] md:text-[22px]">
                Email Notifications
              </p>
              <div className="flex flex-col gap-y-[30px] capitalize">
                {emailNotifs.map((notif: INotifs, i: number) => {
                  return (
                    <div
                      className="flex justify-between border-b-[1px] border-b-[#979797]/10 pb-[10px]"
                      key={i}
                    >
                      <p className="text-[16px] md:text-[16px]">{notif.name}</p>
                      <span
                        className={classNames(
                          `relative flex h-[30px] w-[55px] cursor-pointer items-center rounded-full bg-[#dbdbdb] duration-200 
                          before:absolute before:left-[10%] before:h-[22px] before:w-[22px] before:rounded-full before:bg-[#b2b2b2] before:duration-200`,
                          {
                            '!bg-flexi-orange-2 before:!left-[calc(90%-22px)] before:!bg-flexi-orange-1':
                              toggleEmailNotifs?.includes(notif.slug),
                          },
                        )}
                        onClick={() => handleToggleEmailNotifs(notif.slug)}
                      />
                    </div>
                  );
                })}
              </div>
              <div>
                <button
                  onClick={handleSaveSettings}
                  className={classNames(
                    'btn-primary btn my-[70px] !h-[50px] w-full rounded-full capitalize disabled:text-white md:!h-[60px]',
                    {
                      loading: pendingSaveStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                  disabled={pendingSaveStatus === RequestStatusEnum.PENDING}
                >
                  save changes
                </button>
              </div>
            </>
          )}
        </article>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}
      </div>
      <ErrorModal error={error} page={'settings'} />
    </section>
  );
};

export default MyAccountSettings;
