import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import {
  IVehicleRates,
  IVehicle,
  IVehicleSummary,
  IVehicleFilter,
  IVehicleAvailable,
  RequestStatusEnum,
  IVehicleSummaryMin,
  IVehicleListRates,
  IVehicleCalculatedRates,
  IVehicleCategoryRates,
} from 'common/types';

type InitialState = {
  pending: boolean;
  pendingVehicleAvailable: boolean;
  vehicleListHourly: IVehicleRates | null;
  vehicleListDaily: IVehicleRates | null;
  nearestVehicles: IVehicle[] | null;
  selectedVehicle: number | null;
  vehicleSummary: IVehicleSummary | null;
  pendingVehicleFilters: boolean;
  vehicleFilterList: IVehicleFilter[] | null;
  selectedVehicleFilters: IVehicleFilter[] | [];
  vehicleAvailable: IVehicleAvailable | null;
  extendingTime: Date | null;
  bookingTimer: Date | null;
  bookingExpired: boolean;
  vehicleSummaryStatus: RequestStatusEnum | null;
  vehicleAvailability: IVehicleSummaryMin[] | null;
  vehicleAvailabilityPending: boolean;
  vehicleAvailabilityStatus: RequestStatusEnum | null;
  visibleVehiclesId: number[] | null;
  vehicleRates: IVehicleListRates[] | null;
  vehicleRatesPending: boolean;
  vehicleRatesStatus: RequestStatusEnum | null;
  vehicleCalculatedRates: IVehicleCalculatedRates[] | null;
  vehicleCalculatedRatesPending: boolean;
  vehicleCalculatedRatesStatus: RequestStatusEnum | null;
  vehicleCategoryRates: IVehicleCategoryRates | null;
  vehicleDataFirestore: IVehicleSummaryMin[] | [];
};

const initialState: InitialState = {
  pending: false,
  pendingVehicleAvailable: false,
  vehicleListHourly: null,
  vehicleListDaily: null,
  nearestVehicles: null,
  selectedVehicle: null,
  vehicleSummary: null,
  pendingVehicleFilters: false,
  vehicleFilterList: null,
  selectedVehicleFilters: [],
  vehicleAvailable: null,
  extendingTime: null,
  bookingTimer: null,
  bookingExpired: false,
  vehicleSummaryStatus: null,
  vehicleAvailability: null,
  vehicleAvailabilityPending: false,
  vehicleAvailabilityStatus: null,
  visibleVehiclesId: null,
  vehicleRates: null,
  vehicleRatesPending: false,
  vehicleRatesStatus: null,
  vehicleCalculatedRates: null,
  vehicleCalculatedRatesPending: false,
  vehicleCalculatedRatesStatus: null,
  vehicleCategoryRates: null,
  vehicleDataFirestore: [],
};

export const slice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    reset: () => initialState,
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setPendingVehicleAvailable: (state, action: PayloadAction<boolean>) => {
      state.pendingVehicleAvailable = action.payload;
    },
    setVehicleListHourly: (state, action: PayloadAction<IVehicleRates>) => {
      state.vehicleListHourly = action.payload;
    },
    setVehicleListDaily: (state, action: PayloadAction<IVehicleRates>) => {
      state.vehicleListDaily = action.payload;
    },
    setNearestVehicles: (state, action: PayloadAction<IVehicle[] | null>) => {
      state.nearestVehicles = action.payload;
    },
    setSelectedVehicle: (state, action: PayloadAction<number | null>) => {
      state.selectedVehicle = action.payload;
    },
    setVehicleSummary: (state, action: PayloadAction<IVehicleSummary>) => {
      state.vehicleSummary = action.payload;
    },
    setPendingVehicleFilters: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setVehicleFilterList: (
      state,
      action: PayloadAction<IVehicleFilter[] | null>,
    ) => {
      state.vehicleFilterList = action.payload;
    },
    setSelectedVehicleFilters: (
      state,
      action: PayloadAction<IVehicleFilter[] | []>,
    ) => {
      state.selectedVehicleFilters = action.payload;
    },

    setVehicleAvailable: (
      state,
      action: PayloadAction<IVehicleAvailable | null>,
    ) => {
      state.vehicleAvailable = action.payload;
    },

    setExtendingTime: (state, action: PayloadAction<Date | null>) => {
      state.extendingTime = action.payload;
    },
    setBookingTimer: (state, action: PayloadAction<Date | null>) => {
      state.bookingTimer = action.payload;
    },
    setBookingExpired: (state, action: PayloadAction<boolean>) => {
      state.bookingExpired = action.payload;
    },
    setVehicleSummaryStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.vehicleSummaryStatus = action.payload;
    },
    setVehicleAvailability: (
      state,
      action: PayloadAction<IVehicleSummaryMin[] | null>,
    ) => {
      state.vehicleAvailability = action.payload;
    },
    setVehicleAvailabilityPending: (state, action: PayloadAction<boolean>) => {
      state.vehicleAvailabilityPending = action.payload;
    },
    setVehicleAvailabilityStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.vehicleAvailabilityStatus = action.payload;
    },
    setVisibleVehiclesId: (state, action: PayloadAction<number[] | null>) => {
      state.visibleVehiclesId = action.payload;
    },
    setVehicleRates: (
      state,
      action: PayloadAction<IVehicleListRates[] | null>,
    ) => {
      state.vehicleRates = action.payload;
    },
    setVehicleRatesPending: (state, action: PayloadAction<boolean>) => {
      state.vehicleRatesPending = action.payload;
    },
    setVehicleRatesStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.vehicleRatesStatus = action.payload;
    },
    setVehicleCalculatedRates: (
      state,
      action: PayloadAction<IVehicleCalculatedRates[] | null>,
    ) => {
      state.vehicleCalculatedRates = action.payload;
    },
    setVehicleCalculatedRatesPending: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.vehicleCalculatedRatesPending = action.payload;
    },
    setVehicleCalculatedRatesStatus: (
      state,
      action: PayloadAction<RequestStatusEnum | null>,
    ) => {
      state.vehicleCalculatedRatesStatus = action.payload;
    },
    setVehicleCategoryRates: (
      state,
      action: PayloadAction<IVehicleCategoryRates | null>,
    ) => {
      state.vehicleCategoryRates = action.payload;
    },
    setVehicleDataFirestore: (
      state,
      action: PayloadAction<IVehicleSummaryMin[] | []>,
    ) => {
      state.vehicleDataFirestore = action.payload;
    },
  },
});

export const {
  reset,
  setPending,
  setPendingVehicleAvailable,
  setVehicleListHourly,
  setVehicleListDaily,
  setNearestVehicles,
  setSelectedVehicle,
  setVehicleSummary,
  setPendingVehicleFilters,
  setVehicleFilterList,
  setSelectedVehicleFilters,
  setVehicleAvailable,
  setExtendingTime,
  setBookingTimer,
  setBookingExpired,
  setVehicleSummaryStatus,
  setVehicleAvailability,
  setVehicleAvailabilityPending,
  setVehicleAvailabilityStatus,
  setVisibleVehiclesId,
  setVehicleRates,
  setVehicleRatesPending,
  setVehicleRatesStatus,
  setVehicleCalculatedRates,
  setVehicleCalculatedRatesPending,
  setVehicleCalculatedRatesStatus,
  setVehicleCategoryRates,
  setVehicleDataFirestore,
} = slice.actions;

export const selectPending = (state: RootState): boolean =>
  state.vehicle.pending;
export const selectPendingVehicleAvailable = (state: RootState): boolean =>
  state.vehicle.pendingVehicleAvailable;
export const selectVehicleListHourly = (
  state: RootState,
): IVehicleRates | null => state.vehicle.vehicleListHourly;
export const selectVehicleListDaily = (
  state: RootState,
): IVehicleRates | null => state.vehicle.vehicleListDaily;
export const selectNearestVehicles = (state: RootState): IVehicle[] | null =>
  state.vehicle.nearestVehicles;
export const selectSelectedVehicle = (state: RootState): number | null =>
  state.vehicle.selectedVehicle;
export const selectVehicleSummary = (
  state: RootState,
): IVehicleSummary | null => state.vehicle.vehicleSummary;
export const selectPendingVehicleFilters = (state: RootState): boolean =>
  state.vehicle.pendingVehicleFilters;
export const selectVehicleFilterList = (
  state: RootState,
): IVehicleFilter[] | null => state.vehicle.vehicleFilterList;
export const selectSelectedVehicleFilters = (
  state: RootState,
): IVehicleFilter[] | [] => state.vehicle.selectedVehicleFilters;
export const selectVehicleAvailable = (
  state: RootState,
): IVehicleAvailable | null => state.vehicle.vehicleAvailable;
export const selectExtendingTime = (state: RootState): Date | null =>
  state.vehicle.extendingTime;
export const selectBookingTimer = (state: RootState): Date | null =>
  state.vehicle.bookingTimer;
export const selectBookingExpired = (state: RootState): boolean =>
  state.vehicle.bookingExpired;
export const selectVehicleSummaryStatus = (
  state: RootState,
): RequestStatusEnum | null => state.vehicle.vehicleSummaryStatus;
export const selectVehicleAvailability = (state: RootState): IVehicleSummaryMin[] | null =>
  state.vehicle.vehicleAvailability;
export const selectVehicleAvailabilityPending = (state: RootState): boolean =>
  state.vehicle.vehicleAvailabilityPending;
export const selectVehicleAvailabilityStatus = (
  state: RootState,
): RequestStatusEnum | null => state.vehicle.vehicleAvailabilityStatus;
export const selectVisibleVehiclesId = (state: RootState): number[] | null =>
  state.vehicle.visibleVehiclesId;
export const selectVehicleRates = (state: RootState): IVehicleListRates[] | null =>
  state.vehicle.vehicleRates;
export const selectVehicleRatesPending = (state: RootState): boolean =>
  state.vehicle.vehicleRatesPending;
export const selectVehicleRatesStatus = (
  state: RootState,
): RequestStatusEnum | null => state.vehicle.vehicleRatesStatus;
export const selectVehicleCalculatedRates = (state: RootState): IVehicleCalculatedRates[] | null =>
  state.vehicle.vehicleCalculatedRates;
export const selectVehicleCalculatedRatesPending = (state: RootState): boolean =>
  state.vehicle.vehicleCalculatedRatesPending;
export const selectVehicleCalculatedRatesStatus = (
  state: RootState,
): RequestStatusEnum | null => state.vehicle.vehicleCalculatedRatesStatus;
export const selectVehicleCategoryRates = (state: RootState): IVehicleCategoryRates | null =>
  state.vehicle.vehicleCategoryRates;
export const selectVehicleDataFirestore = (
  state: RootState,
): IVehicleSummaryMin[] | [] => state.vehicle.vehicleDataFirestore;

export default slice.reducer;
