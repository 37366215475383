import React, { Dispatch } from 'react';

import { useDropzone } from 'react-dropzone';
import { GrUpload } from 'react-icons/gr';

interface Props {
  uploads: any;
  setUploads: any;
  textValue: any;
  category: string;
  setTextValue: Dispatch<any>;
}

const ImageUpload = ({
  uploads,
  setUploads,
  category,
  textValue,
  setTextValue,
}: Props) => {
  const toCamelCase = () => {
    return category
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase(),
      )
      .replace(/\s+/g, '');
  };

  const onDrop = (acceptedFiles: any) => {
    setUploads({ ...uploads, [toCamelCase()]: [...acceptedFiles] });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleTextChange = (e: any) => {
    setTextValue({ ...textValue, [toCamelCase()]: e.target.value });
  };

  return (
    <div className="flex flex-col ">
      <div className="mb-[20px] flex w-full">
        <textarea
          placeholder="Additional Information (Optional)"
          className="flex h-[120px] w-full rounded-lg border border-solid border-gray-300 p-4 text-[14px] focus:outline-0"
          name="remarks"
          id="remarks"
          value={textValue[category]}
          onChange={handleTextChange}
        ></textarea>
      </div>
      <div
        {...getRootProps()}
        className="flex h-[130px] cursor-pointer items-center justify-center rounded-lg bg-white p-[20px] shadow-md"
      >
        <div className="flex h-full w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-[#EAEAEA]">
          <input {...getInputProps()} type='file' accept='image/*' />
          <div className="flex items-center justify-center">
            <GrUpload />
          </div>
          <div className="flex items-center justify-center pt-[15px] text-[12px]">
            <p>Upload Photos (optional)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
