import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { removeDeviceId, removeToken } from 'common/helpers';
import { selectIsUnsubscribeButtonEnabled } from 'modules/public/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import PageLayout from 'components/layouts/PageLayout';

interface iReason {
  display: string;
  value: string;
}

const UnsubscribePage = () => {
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(location.search);
  const isUnsubscribeButtonEnabled = useAppSelector(selectIsUnsubscribeButtonEnabled);
  const [selectedReason, setSelectedReason] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);

  const unsubscribeReasons: iReason[] = [
    {
      display: 'I no longer want to receive these emails',
      value: 'I no longer want to receive these emails',
    },
    {
      display: 'I never signed up for this mailing list',
      value: 'I never signed up for this mailing list',
    },
    {
      display: 'The emails are inappropriate',
      value: 'The emails are inappropriate',
    },
    {
      display: 'The emails are spam and should be reported',
      value: 'The emails are spam and should be reported',
    },
    {
      display: 'Other (Fill in reason below)',
      value: 'other',
    },
  ];

  const handleClick = () => {
    const payload = {
      subscriptioncode: params.get('subscriptioncode'),
      reason:
        selectedReason === 'other' ? selectedReason + ": " + message : selectedReason,
    };
    dispatch({ type: ACTIONS.MAIL_UNSUBSCRIBE, payload })
    setSubmitted(true);
  }

  useEffectOnce(() => {
    const payload = {
      subscriptioncode: params.get('subscriptioncode'),
    };
    dispatch({ type: ACTIONS.MAIL_UNSUBSCRIBE, payload });
  })

  window.onbeforeunload = () => {
    removeToken();
    removeDeviceId();
  }

  return (
    <PageLayout>
      <article className="m-auto w-full max-w-[800px] px-[30px] py-[100px] md:w-[80%]">
        <h2 className="font-900 font-primary mb-[32px] text-center text-[54px] text-primary">
          Unsubscribe Successful
        </h2>
        <p className="text-[24px]">
          You will no longer receive email marketing from this list. If you have
          a moment, please let us know why you unsubscribe:
        </p>
        <div className="mb-[30px] flex w-full max-w-[700ox] flex-col gap-[45px] py-[20px] text-[20px] md:py-[50px]">
          {unsubscribeReasons.map((reason: iReason, index: number) => {
            return (
              <div
                key={index}
                className="mb-[0.125rem] block flex min-h-[1.5rem] items-center pl-[1.5rem]"
              >
                <input
                  className="border-neutral-300 relative float-left -ml-[1.5rem] mr-1 h-[40px] w-[40px] appearance-none rounded-full border-2 border-solid before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[37px] checked:after:w-[37px] checked:after:rounded-full checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)]"
                  type="radio"
                  name="flexRadioDefault"
                  id={`reason-${index}`}
                  onSelect={() => setSelectedReason(reason.value)}
                  onFocus={() => setSelectedReason(reason.value)}
                />
                <label
                  className="inline-block pl-[15px] hover:cursor-pointer"
                  htmlFor={`reason-${index}`}
                >
                  {reason.display}
                </label>
              </div>
            );
          })}

          {selectedReason.toLocaleLowerCase() === 'other' && (
            <textarea
              id="other_reason"
              rows={10}
              className="w-full rounded-[20px] border-2 border-flexi-black-4 p-[15px]"
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          )}

          <button
            onClick={() => handleClick()}
            className="btn btn-primary max-w-[180px] rounded-full"
            disabled={
              selectedReason === null ||
              (selectedReason.toLocaleLowerCase() === 'other' &&
                message === '') ||
              isSubmitted ||
              !isUnsubscribeButtonEnabled
            }
          >
            Submit
          </button>
        </div>
      </article>
    </PageLayout>
  );
};

export default UnsubscribePage;
