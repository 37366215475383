/* eslint-disable no-console */
import React from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { 
  // selectPendingSms,
  selectQrCode
} from 'modules/ocr/slice';
// import PhoneInput, { CountryData }  from 'react-phone-input-2';
// import { selectUser } from 'modules/me/slice';
// import useAppDispatch from 'hooks/useAppDispatch';
// import ACTIONS from 'modules/rootActions';
import { IMAGE_URL } from 'common/constants';
import checkIcon from 'assets/images/check.svg';
// import arrowIcon from 'assets/images/arrow-right.svg';
// import classNames from 'classnames';
import { ReactComponent as IconScan } from 'assets/images/scan.svg';
// import CustomInput from 'components/CustomInput';
// import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';

const VerificationContent = () => {
  // const dispatch = useAppDispatch();
  const qrCode = useAppSelector(selectQrCode);
  // const user = useAppSelector(selectUser);
  // const isPendingSms = useAppSelector(selectPendingSms);
  // const [phoneCode, setPhoneCode] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');

  // const handleChange = (value: string, country: CountryData | any) => {
  //   setPhoneCode(country.dialCode);
  //   setPhoneNumber(value.replace(country.dialCode, ''));
  // };

  // const handleSubmit = () => {
  //   const payload = {
  //     phoneCode: '+' + phoneCode,
  //     phoneNumber,
  //   };
  //   dispatch({ type: ACTIONS.SEND_SMS, payload });
  // };

  return (
    <div className="">
      <p className="mb-[16px] text-[18px]">
        To join Flexicar you will need to:
      </p>
      <ul className="mb-[27px] flex flex-col gap-y-[15px] text-[16px] text-sm">
        <li className="flex items-center gap-x-[15px] translate-x-[-10px]">
          <span
            style={{
              maskImage: `url(${IMAGE_URL}${checkIcon})`,
              WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
              maskRepeat: 'no-repeat',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center right',
            }}
            className="h-[25px] w-[25px] bg-flexi-orange-1"
          />
          Be over 18 years old
        </li>
        <li className="flex items-center gap-x-[15px] translate-x-[-10px]">
          <span
            style={{
              maskImage: `url(${IMAGE_URL}${checkIcon})`,
              WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
              maskRepeat: 'no-repeat',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center right',
            }}
            className="h-[25px] w-[25px] bg-flexi-orange-1"
          />
          Have no major violations against your driving record in the past 3
          years
        </li>
        <li className="flex items-center gap-x-[15px] translate-x-[-10px]">
          <span
            style={{
              maskImage: `url(${IMAGE_URL}${checkIcon})`,
              WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
              maskRepeat: 'no-repeat',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center right',
            }}
            className="h-[25px] w-[25px] bg-flexi-orange-1"
          />
          Have 1 or more years of independent driving experience
        </li>
      </ul>

      <p className="mb-[16px] text-[18px]">
        To successfully complete the verification process make sure you:
      </p>
      <ul className="mb-[40px] flex flex-col gap-y-[15px] text-[16px]">
        <li className="flex items-center gap-x-[15px] translate-x-[-10px]">
          <span
            style={{
              maskImage: `url(${IMAGE_URL}${checkIcon})`,
              WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
              maskRepeat: 'no-repeat',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center right',
            }}
            className="h-[25px] w-[25px] bg-flexi-orange-1"
          />
          Have your drivers licence on hand
        </li>
        <li className="flex items-center gap-x-[15px] translate-x-[-10px]">
          <span
            style={{
              maskImage: `url(${IMAGE_URL}${checkIcon})`,
              WebkitMaskImage: `url(${IMAGE_URL}${checkIcon})`,
              maskRepeat: 'no-repeat',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center right',
            }}
            className="h-[25px] w-[25px] bg-flexi-orange-1"
          />
          Are in a well lit place
        </li>
      </ul>
      <div className="flex gap-x-[30px]">
        <figure className="  mb-10 block w-[200px] border-[1px] border-[#DDD] p-7">
          <div id="qr-code" dangerouslySetInnerHTML={{ __html: qrCode }}></div>
        </figure>
        <div className="flex w-[370px] flex-col items-center">
          <p className="mb-[23px] text-center text-[18px] font-[400]">
            Use your mobile phone camera to scan the QR code to begin the
            identity verification process.
          </p>
          <IconScan />
        </div>
      </div>
      <p className="mb-5 text-[18px]">
        Or check your email for further instructions.
      </p>
      {/* <p className="mb-5 text-[18px]">
        Or enter your mobile number below to start the process via SMS
      </p>

      <div className="mb-10 flex">
      <CustomInput
        title="Mobile"
        positionTitle="translate-x-[50px]"
        margin={20}
        width='300'
      >
        
        <PhoneInput
          value={user?.contact}
          country={'au'}
          inputClass="!w-[300px] !h-[48px] rounded-[7px_7px_7px_7px] indent-[18px]"
          buttonClass="!rounded-l-lg"
          containerClass="!w-[220px]"
          preferredCountries={['au', 'nz', 'us']}
          onChange={(value, country) => {
            handleChange(value, country);
          }}
          onMount={(value, country) => {
            handleChange(value, country);
          }}
        />
        <button
          onClick={handleSubmit}
          className={classNames(
            'grid w-[35px] absolute right-0 h-full translate-y-[-5px] place-items-center overflow-hidden ',
            {
              'pointer-events-none': isPendingSms,
            },
          )}
        >
          <span
            style={{
              maskImage: `url(${IMAGE_URL}${arrowIcon})`,
              WebkitMaskImage: `url(${IMAGE_URL}${arrowIcon})`,
              maskRepeat: 'no-repeat',
              WebkitMaskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center left',
            }}
            className={classNames(
              'h-[25px] w-[25px] bg-flexi-orange-1 duration-500',
              {
                'translate-x-[50px]': isPendingSms,
              },
            )}
          />
        </button>
        </CustomInput>
      </div> */}
    </div>
  );
};

export default VerificationContent;
