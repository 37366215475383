import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ForgotPasswordForm from 'features/ForgotPasswordForm';
// import { useRedirectToApp } from 'hooks/useRedirect';
import ForgotPasswordOTP from 'features/ForgotPasswordOTP';
import { SignupTopNav } from 'components/TopNav';
// import ForgotPasswordSuccess from 'features/ForgotPasswordSuccess';
import ForgotPasswordOTPSuccess from 'features/ForgotPasswordOTPSuccess';
import ForgotPasswordConfirmSuccess from 'features/ForgotPasswordConfirmSuccess';
import ForgotPasswordConfirm from 'features/ForgotPasswordConfirm';
import ForgotPasswordCheckEmail from 'features/ForgotPasswordCheckEmail';
import { reloadPageOnce } from 'common/helpers';
import { Helmet } from 'react-helmet';
// import { createPortal } from 'react-dom';

const AuthPage = () => {
  // useRedirectToApp();
  reloadPageOnce()

  // const topNav = document.getElementById('topnav');
  
  return (
    <div className="absolute grid w-full">
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="Flexicar Forgot Passwords" />
      </Helmet>
      {!window.location.href.includes('/login') && <SignupTopNav />}
      {/* {!window.location.href.includes('/login') ?
        <></> :
        <>
        {topNav ?
          createPortal(
            <SignupTopNav/>,
            topNav)
        :<></>}
        </>
      } */}
      <Switch>
        <Route
          exact
          path="/auth/forgot-password"
          component={ForgotPasswordForm}
        />
        <Route
          exact
          path="/auth/forgot-password-check-mail"
          component={ForgotPasswordCheckEmail}
        />
        <Route
          exact
          path="/auth/forgot-password-otp"
          component={ForgotPasswordOTP}
        />
        <Route
          exact
          path="/auth/forgot-password-otp-success"
          component={ForgotPasswordOTPSuccess}
        />
        <Route
          exact
          path="/auth/forgot-password-confirm"
          component={ForgotPasswordConfirm}
        />
        <Route
          exact
          path="/auth/forgot-password-confirm-success"
          component={ForgotPasswordConfirmSuccess}
        />
        {/* <Route
          exact
          path="/auth/forgot-password-success"
          component={ForgotPasswordSuccess}
        /> */}
      </Switch>
    </div>
  );
};

export default AuthPage;
