import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import React, { useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import gsap from 'gsap';
import { useRedirectToRegistration } from 'hooks/useRedirect';

function RegisterCreateAccount() {
  const { push } = useHistory();
  const user = useAppSelector(selectUser)

  const component = useRef<HTMLDivElement>(null);
  useRedirectToRegistration();

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  return (
    <section ref={component} className="fadein flex w-full max-w-[700px] flex-col items-start">
      <h1 className="font-primary mb-[20px] text-[40px] font-[900] uppercase text-primary">
        {user?.redirectPreviousBusinessName} removed you as a&nbsp;driver
      </h1>
      <p>
        Your business driver profile has been removed. If you would like to
        continue using Flexicar for personal use, we will require your email
        address, plan selection and credit&nbsp;card.
      </p>
      <button
        className="w- btn mt-[60px] !h-[50px] md:!h-[60px] w-[200px] rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2"
        onClick={() => push('/register/update-email')}
      >
        continue
      </button>
    </section>
  );
}

export default RegisterCreateAccount;
