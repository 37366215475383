import React, { useRef, useState, useLayoutEffect } from 'react';
import 'themes/explore.css';
import { IMAGE_URL } from 'common/constants';
import banner from 'assets/images/explore/banner.png';
import driverTipsIcon from 'assets/images/explore/driver-tips-icon.svg';
import newsFeatured from 'assets/images/explore/news-featured.jpg';
import newsIcon from 'assets/images/explore/news-icon.svg';
import news01 from 'assets/images/explore/news01.jpg';
import news02 from 'assets/images/explore/news02.jpg';
import news03 from 'assets/images/explore/news03.jpg';
// import search from 'assets/images/explore/search.svg';
import tips01 from 'assets/images/explore/staff-tips01.jpg';
import tips02 from 'assets/images/explore/staff-tips02.jpg';
import tips03 from 'assets/images/explore/staff-tips03.jpg';
import tips04 from 'assets/images/explore/staff-tips04.jpg';
import gsap from 'gsap';

const ExplorePage = () => {

  const [state, setState] = useState({ current: 0, next: 1 });
  const [userDetected, setUserDetected] = useState(false);

  // START CODE GSAP ANIMATION
  let timer : any = null;
  const elems = useRef<any>([]);
  const timeline = gsap.timeline({
    defaults: {
      duration: 0.75,
      ease: "expo.inOut"
    },
    paused: true
  });

  // Automates sliding every five seconds
  const activateTimer = () => {
    timer = setTimeout(() => {
      stepForward();
      // console.log('stepForward')
    }, 5000);
  };

  const calculateIndexs = (index : any) => {
    if (index === gallery.length - 1) {
      setState({ current: index, next: 0 });
    } else {
      setState({ current: index, next: index + 1 });
    }
  };

  const flowUp = (onComplete : any) => {
    timeline
      .fromTo(elems.current[0], { x: "0%", opacity: 1}, { x: "-100%", ease: "expo.inOut", opacity: 1, duration: 0.75})
      .fromTo(elems.current[1], { y: "-100%", x: "0%", opacity: 1}, { y: "-100%", x: "-100%", ease: "expo.inOut", opacity: 1, onComplete}, "-=0.75")
      .play();

      // console.log(state);
  };

  const fadeOut = (onComplete: any) => {
    timeline
      .to(elems.current[0], {
        duration: 0.75,
        opacity: 0,
        onComplete
      })
      .to(elems.current[0], { opacity: 1 })
      .play();
  };

  const handleChange = (index : any) => {
    if (index !== state.current) {
      clearTimeout(timer);
      setUserDetected(true);
      fadeOut(() => calculateIndexs(index));
    }
  };

  const stepForward = () => {
    setUserDetected(false);
    flowUp(() => {
      calculateIndexs(state.next)
    });
  };

  useLayoutEffect(() => {
    const image1 = !!elems.current[0] && elems.current[0];
    const image2 = !!elems.current[1] && elems.current[1];

    activateTimer();

    gsap.set(image2, { x: "0%", opacity: 1});
    if (userDetected) {
      gsap.set(image1, { x: "0%", opacity: 1});
    } else {
      gsap.set(image1, { x: "0%", opacity: 1});
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  // Banners here
  const gallery = [
    {
      cover:
        `${IMAGE_URL}${banner}`
    },
    {
      cover:
        `${IMAGE_URL}${newsFeatured}`
    },
    {
      cover:
        `${IMAGE_URL}${news03}`
    }
  ];

  return (
    <>
    <div className="explore-container">
      {/* <section className="section-search">
        <div className=" relative flex">
          <input type="input" placeholder="Search" />
          <span className="absolute right-[10px] grid h-full w-[40px]  place-items-center">
            <img src={`${IMAGE_URL}${search}`} alt="" />
          </span>
        </div>
      </section> */}
      <section className='for-gsap-animation'>
        <div className="album-container px-[25px]">
          <div className="images-container relative w-full !h-[180px] overflow-hidden">
            <div className="image w-full !h-[180px] overflow-hidden rounded-[10px]" ref={(elem : any) => (elems.current[0] = elem)}>
              <img
                src={gallery[state.current].cover}
                alt=""
                />
            </div>
            <div className="image w-full !h-[180px] overflow-hidden rounded-[10px] absolute left-[100%]" ref={(elem) => (elems.current[1] = elem)}>
              <img
                src={gallery[state.next].cover}
                alt=""
                />
            </div>
          </div>
          <div className="stripes">
            {gallery.map((_item, index) =>
              index === state.current ? (
                <span
                  key={`stripe${index}`}
                  onClick={() => handleChange(index)}
                  style={{ opacity: 1 }}
                ></span>
              ) : (
                <span
                  key={`stripe${index}`}
                  onClick={() => handleChange(index)}
                  style={{ opacity: 0.2 }}
                ></span>
              )
            )}
          </div>
        </div>
      </section>
      <section className="section-news">
        <div className="heading-wrap">
          <div className="icon-wrap">
            <img src={`${IMAGE_URL}${newsIcon}`} alt="" />
          </div>
          <div className="title-wrap">
            <h4 className="subheading">News</h4>
          </div>
        </div>

        <div className="featured-wrap">
          <div className="img-wrap">
            <img src={`${IMAGE_URL}${newsFeatured}`} alt="" />
          </div>
          <div className="content-wrapper">
            <div className="heading-wrapper">
              <div className="heading">
                <h4 className="subheading02">Flexicar in Tassie</h4>
              </div>
              <div className="cat-tag">
                <p className="extra-small">FEATURED</p>
              </div>
            </div>
            <div className="description-wrap">
              <p className="medium">
                Hello Tassie! We’re ready to get you driving!
              </p>
            </div>
          </div>
        </div>

        <div className="news-article-grid-wrapper">
          <div className="news-article-grid">
            <div className="news-article-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${news01}`} alt="" />
              </div>
              <div className="content-wrap">
                <div className="title">
                  <h4 className="medium-title">
                    Plan Your Next Trip with Flexicar
                  </h4>
                </div>
                <div className="date-wrap">
                  <p className="date">August 20, 2021</p>
                </div>
              </div>
            </div>

            <div className="news-article-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${news02}`} alt="" />
              </div>
              <div className="content-wrap">
                <div className="title">
                  <h4 className="medium-title">
                    Need To Move? We can help!
                  </h4>
                </div>
                <div className="date-wrap">
                  <p className="date">August 20, 2021</p>
                </div>
              </div>
            </div>

            <div className="news-article-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${news03}`} alt="" />
              </div>
              <div className="content-wrap">
                <div className="title">
                  <h4 className="medium-title">
                    Top 5 Destinations When in Perth
                  </h4>
                </div>
                <div className="date-wrap">
                  <p className="date">August 20, 2021</p>
                </div>
              </div>
            </div>
          </div>

          <div className="button-wrapper">
            <button>View all Flexi News</button>
          </div>
        </div>
      </section>
      <section className="section-staff-tips">
        <div className="heading-wrap">
          <div className="icon-wrap">
            <img src={`${IMAGE_URL}${driverTipsIcon}`} alt="" />
          </div>
          <div className="title-wrap">
            <h4 className="subheading">Staff Tips</h4>
          </div>
        </div>

        <div className="staff-grid-wrapper">
          <div className="staff-grid">
            <div className="staff-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${tips01}`} alt="" />
              </div>
              <div className="title-wrap">
                <h4 className="small-title">
                  Flexicar is the best partner for your business. Here’s why
                </h4>
              </div>
            </div>

            <div className="staff-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${tips02}`} alt="" />
              </div>
              <div className="title-wrap">
                <h4 className="small-title">
                  4 Reasons why Flexicar is the leading car rental app here
                  in Australia
                </h4>
              </div>
            </div>

            <div className="staff-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${tips03}`} alt="" />
              </div>
              <div className="title-wrap">
                <h4 className="small-title">
                  Best Advice for new Flexicar App users
                </h4>
              </div>
            </div>

            <div className="staff-wrap">
              <div className="img-wrap">
                <img src={`${IMAGE_URL}${tips04}`} alt="" />
              </div>
              <div className="title-wrap">
                <h4 className="small-title">
                  Connect your card or e-wallet to Flexicar in 3 easy steps.
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="button-wrapper">
          <button>View all Staff Tips</button>
        </div>
      </section>
    </div>
    {/* )} */}
    </>
  );
};

export default ExplorePage;
