import classNames from 'classnames';
import { InvalidText } from 'components/Typography';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { selectCancelMembershipStatus } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { useState } from 'react';
import { CANCELLATION_REASONS } from './constants';
import { ReactComponent as IconCancelMembership } from 'assets/images/cancel_membership.svg';
import { RequestStatusEnum } from 'common/types';
import { getToken } from 'common/helpers';
import { Helmet } from 'react-helmet';

function CancelMembership() {
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState<string>('');
  const [isError, setError] = useState(false);
  const [isReasonOther, setReasonOther] = useState(false);
  const cancelMembershipStatus = useAppSelector(selectCancelMembershipStatus);
  const token = getToken();

  const handleReasonChange = (value: string) => {
    if (value === 'Other (please specify below)') {
      setReasonOther(true);
      setError(false);
      setReason('');
    } else {
      setReasonOther(false);
      setReason(value);
      setError(false);
    }
  };

  const handleCancelMembership = () => {
    if (reason === '') {
      setError(true);
    } else {
      const payload = {
        reason,
        token,
      };
      dispatch({ type: ACTIONS.CANCEL_MEMBERSHIP_DRIVER, payload });
      setError(false);
    }
  };

  return (
    <article className="relative self-center mt-[50px] mb-[100px] px-[100px] flex flex-col items-center gap-4 md:mx-0 md:mt-[100px]">
      <Helmet>
        <title>My Account - Cancel Membership</title>
        <meta name="description" content="My Flexicar Account - Cancel Membership" />
      </Helmet>
      <IconCancelMembership className="mb-[40px]" />
      <h1 className="mb-[30px] text-center text-[20px]  font-bold md:text-[34px]">
        Are you sure you want to cancel your membership?
      </h1>
      <p className="mb-[40px] text-center text-[16px] md:text-[18px]">
        By clicking the confirm button below, your membership will be cancelled.
        You will be automatically logged out, you won&apos;t be able to login
        again, and you will no longer be able to use the service. If it&apos;s
        time to say goodbye, we understand. However, if you need to use Flexicar
        again simply re-join through either the Flexicar web or mobile&nbsp;app.
      </p>
      <div className="w-full flex-col ">
        <select
          className={classNames('select select-bordered w-full', {
            //   'border-red-500': isError && !isReasonOther,
          })}
          onChange={(e) => {
            handleReasonChange(e.target.value);
          }}
          defaultValue={0}
        >
          <option disabled value={0}>
            Your reason for cancelling
          </option>
          {CANCELLATION_REASONS.map((i) => {
            return (
              <option key={i.id} value={i.message}>
                {i.message}
              </option>
            );
          })}
        </select>
        {isReasonOther && (
          <textarea
            className={classNames(
              'textarea textarea-bordered mt-[10px] w-full resize-none leading-[1.429]',
              {
                'border-red-500': isError,
              },
            )}
            placeholder="Enter your cancellation reason..."
            onChange={(e) => setReason(e.target.value)}
          />
        )}
        {isError ? <InvalidText>This field is required.</InvalidText> : null}
      </div>

      <div className="button mt-[50px] flex w-full justify-center">
        <button
          className={classNames(
            `btn h-[50px] w-full rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2 md:!h-[60px]`,
            {
              'loading bg-flexi-green-2':
                cancelMembershipStatus === RequestStatusEnum.PENDING,
            },
          )}
          onClick={handleCancelMembership}
        >
          Confirm
        </button>
      </div>
    </article>
  );
}

export default CancelMembership;
