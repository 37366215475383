import { initializeApp } from "firebase/app"
// import { getAuth, signInAnonymously } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyCfFmoiNBkaZBd0t7IQ8tHJE5N2UCAfhNc",
    authDomain: "flexicar-191705.firebaseapp.com",
    projectId: "flexicar-191705",
    storageBucket: "flexicar-191705.appspot.com",
    messagingSenderId: "963694821739",
    appId: "1:963694821739:web:c7c247e14b996a98144c2e",
    measurementId: "G-YL3QPKM8SN"
}

const firebaseApp = initializeApp(firebaseConfig)

// const auth = getAuth();
// signInAnonymously(auth)
//   .then(() => {
//     // Signed in..
//   })
//   .catch((error) => {
//     // const errorCode = error.code;
//     // const errorMessage = error.message;
//     // ...
//   })

export default firebaseApp