import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  fetchDynamicBlockContent
} from './sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT, fetchDynamicBlockContent)
];

export default actions;
