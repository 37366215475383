import { getToken } from 'common/helpers';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { selectUser, selectUserStatus, setUserStatus } from 'modules/me/slice';
import useAppSelector from './useAppSelector';
import { useEffect } from 'react';
import { RequestStatusEnum } from 'common/types';
import useAppDispatch from './useAppDispatch';

export const useRedirectToApp = () => {
  const { push } = useHistory();

  useEffectOnce(() => {
    const token = getToken();

    if (token) {
      push(`/app/booking?reload=true`);
    }
  });
};

export const useRedirectToRegistration = () => {
  const dispatch = useAppDispatch();
  const { push, location } = useHistory();
  const token = getToken();
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus)

  useEffect(() => {
    if (!token) {
      push('/');
    } else if (
      token &&
      userStatus === RequestStatusEnum.SUCCESS &&
      user?.registrationVersion === 2 &&
      user?.type.id === 2 &&
      user.role === 'manager'
    ) {
      if (user?.redirectToV2 === 'otp-v2') {
        push('/register-business/otp');
      } else if (user?.redirectToV2 === 'plan-v2') {
        push('/register-business/plan');
      } else if (user?.redirectToV2 === 'payment-v2') {
        push('/register-v2/payment');
      } else if (user?.redirectToV2 === 'done-v2') {
        push('/register-v2/done');
      } else if (user?.redirectToV2 === 'app') {
        if (
          (location.pathname === '/' ||
            location.pathname.includes('reload=true')) &&
          !location.pathname.includes('app/booking')
        )
          push(`/app/booking?reload=true`);
        else push(`${location.pathname}`);
      } else if (user?.redirectToV2 === 'add-card-onboarding') {
        push('/app/add-payment-method');
      } else if (user?.redirectTo === 'information-v2') {
        push('/register/information');
      }
      dispatch(setUserStatus(null));
    } else if (
      token &&
      userStatus === RequestStatusEnum.SUCCESS &&
      user?.registrationVersion === 2
    ) {
      if (user?.redirectToV2 === 'declined-v2') {
        push('/register-v2/verify-failed');
      } else if (user?.redirectToV2 === 'otp-v2') {
        push('/register-v2/otp');
      } else if (user?.redirectToV2 === 'id-verification-v2') {
        push('/register-v2/otp-verified');
      } else if (user?.redirectToV2 === 'ocr-verification-v2') {
        push('/register-v2/ocr');
      } else if (user?.redirectToV2 === 'address-verification-v2') {
        push('/register-v2/address-verification');
      } else if (user?.redirectToV2 === 'plan-v2') {
        push('/register-v2/plan');
      } else if (user?.redirectToV2 === 'payment-v2') {
        push('/register-v2/payment');
      } else if (user?.redirectToV2 === 'done-v2') {
        push('/register-v2/done');
      } else if (user?.redirectToV2 === 'app') {
        if (
          (location.pathname === '/' ||
            location.pathname.includes('reload=true')) &&
          !location.pathname.includes('app/booking')
        )
          push(`/app/booking?reload=true`);
        else push(`${location.pathname}`);
      } else if (user?.redirectToV2 === 'add-card-onboarding') {
        push('/app/add-payment-method');
      } else if (user?.redirectTo === 'information-v2') {
        push('/register/information');
      }
      dispatch(setUserStatus(null));
    } else if (
      token &&
      userStatus === RequestStatusEnum.SUCCESS
    ) {
      if (user?.redirectTo === 'declined') {
        push('/register/declined');
      } else if (user?.redirectTo === 'otp') {
        push('/register/otp');
      } else if (user?.redirectTo === 'verification') {
        push('/register/verification');
      } else if (user?.redirectTo === 'information') {
        push('/register/information');
      } else if (user?.redirectTo === 'plan') {
        push('/register/plan');
      } else if (user?.redirectTo === 'payment') {
        push('/register/payment');
      } else if (user?.redirectTo === 'done') {
        push('/register/done');
      } else if (user?.redirectTo === 'register-personal') {
        push('/register/create-account');
      } else if (user?.redirectTo === 'app') {
        if (
          (location.pathname === '/' ||
            location.pathname.includes('reload=true')) &&
          !location.pathname.includes('app/booking')
        )
          push(`/app/booking?reload=true`);
        else push(`${location.pathname}`);
      } else if (user?.redirectTo === 'add-card-onboarding') {
        push('/app/add-payment-method');
      }
      dispatch(setUserStatus(null));
    }
  }, [token, user, userStatus]);

};

export const useRedirectToSignUp = () => {
  const dispatch = useAppDispatch();
  const { push, location } = useHistory();
  const token = getToken();
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus);
  useEffect(() => {
    if (!token) {
      push('/');
    } else if (token && userStatus === RequestStatusEnum.SUCCESS && user?.registrationVersion === 2 && user?.type.id === 2 && user.role === 'manager') {
      if (user?.redirectToV2 === 'otp-v2') {
        push('/register-business/otp');
      } else if (user?.redirectToV2 === 'plan-v2') {
        push('/register-business/plan');
      } else if (user?.redirectToV2 === 'payment-v2') {
        push('/register-v2/payment');
      } else if (user?.redirectToV2 === 'done-v2') {
        push('/register-v2/done');
      } else if (user?.redirectToV2 === 'app') {
        if ((location.pathname === '/' || location.pathname.includes('reload=true')) && !location.pathname.includes('app/booking'))
          push(`/app/booking?reload=true`);
        else
          push(`${location.pathname}`);;
      } else if (user?.redirectToV2 === 'add-card-onboarding') {
        push('/app/add-payment-method');
      } else if (user?.redirectTo === 'information-v2') {
        push('/register/information');
      }
      dispatch(setUserStatus(null))
    } else if (
      token &&
      userStatus === RequestStatusEnum.SUCCESS &&
      user?.registrationVersion === 2 &&
      user?.type.id === 2 &&
      user.role === 'driver'
    ) {
      if (user?.redirectToV2 === 'declined-v2') {
        push('/register-v2/verify-failed');
      } else if (user?.redirectToV2 === 'otp-v2') {
        push('/register-v2/otp');
      } else if (user?.redirectToV2 === 'id-verification-v2') {
        push('/register-v2/otp-verified');
      } else if (user?.redirectToV2 === 'ocr-verification-v2') {
        push('/register-v2/ocr');
      } else if (user?.redirectToV2 === 'done-v2') {
        push('/register-v2/done');
      } else if (user?.redirectToV2 === 'app') {
        if (
          (location.pathname === '/' ||
            location.pathname.includes('reload=true')) &&
          !location.pathname.includes('app/booking')
        )
          push(`/app/booking?reload=true`);
        else push(`${location.pathname}`);
      } else if (user?.redirectToV2 === 'add-card-onboarding') {
        push('/app/add-payment-method');
      } else if (user?.redirectTo === 'information-v2') {
        push('/register/information');
      } else if (
        token &&
        userStatus === RequestStatusEnum.SUCCESS &&
        user?.registrationVersion === 2 &&
        user?.type.id !== 2
      ) {
        if (user?.redirectToV2 === 'declined-v2') {
          push('/register-v2/verify-failed');
        } else if (user?.redirectToV2 === 'otp-v2') {
          push('/register-v2/otp');
        } else if (user?.redirectToV2 === 'id-verification-v2') {
          push('/register-v2/otp-verified');
        } else if (user?.redirectToV2 === 'ocr-verification-v2') {
          push('/register-v2/ocr');
        } else if (user?.redirectToV2 === 'address-verification-v2') {
          push('/register-v2/address-verification');
        } else if (user?.redirectToV2 === 'plan-v2') {
          push('/register-v2/plan');
        } else if (user?.redirectToV2 === 'payment-v2') {
          push('/register-v2/payment');
        } else if (user?.redirectToV2 === 'done-v2') {
          push('/register-v2/done');
        } else if (user?.redirectToV2 === 'app') {
          if (
            (location.pathname === '/' ||
              location.pathname.includes('reload=true')) &&
            !location.pathname.includes('app/booking')
          )
            push(`/app/booking?reload=true`);
          else push(`${location.pathname}`);
        } else if (user?.redirectToV2 === 'add-card-onboarding') {
          push('/app/add-payment-method');
        } else if (user?.redirectTo === 'information-v2') {
          push('/register/information');
        }
        dispatch(setUserStatus(null));
      }
      dispatch(setUserStatus(null));
    }
  }, [token, user, userStatus]);
};


export const useRedirectToNoAuth = () => {
  const { push } = useHistory();
  const token = getToken();
  useEffect(() => {
    if (!token) push('/');
  }, [token]);
};
