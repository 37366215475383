import React from 'react';

import ReportIssuesLayout from 'components/layouts/ReportIssuesLayout';
import ReportIssues from 'features/ReportIssues';

const ReportIssuesPage = () => {

  return (
    <ReportIssuesLayout>
      <ReportIssues />
    </ReportIssuesLayout>
  );
};

export default ReportIssuesPage;
