import { toast } from 'react-toastify';
import { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import { call, put } from 'redux-saga/effects';
import {
  setOcrTransaction,
  setPending,
  setPendingSms,
  setQrCode,
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISms } from 'common/types';
import { API_URL } from 'common/constants';

export const SAGA_ACTIONS = {
  FETCH_OCR_TRANSACTION: 'FETCH_OCR_TRANSACTION',
  FETCH_OCR_TRANSACTION_SUCCESS: 'FETCH_OCR_TRANSACTION_SUCCESS',
  FETCH_QR_CODE: 'FETCH_QR_CODE',
  SEND_SMS: 'SEND_SMS',
};

type UpdateUserDetailsPayload = {
  transactionId: number;
};


export function* fetchOcrTransactionSuccess(
  action: PayloadAction<UpdateUserDetailsPayload>,

) {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.get(`/ocr/transaction/${action.payload.transactionId}/success`));
    yield put(setOcrTransaction(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

export function* fetchOcrTransaction() {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.get('/ocr/transaction'));
    yield put(setOcrTransaction(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

export function* fetchQrCode() {
  const api = authenticatedClient();
  try {
    yield put(setPending(true));
    const { data } = yield call(() => api.get('/ocr/qr-code'));
    yield put(setQrCode(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

export function* sendSms(action: PayloadAction<ISms>) {
  const api = authenticatedClient();
  try {
    yield put(setPendingSms(true));
    yield call(() => api.post(`${API_URL}/ocr/sendSms`, action.payload));
    toast.success('SMS sent.');
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingSms(false));
  }
}
