import React from 'react';
import Booking from 'features/BookingOptimized';
import { Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const StandAloneBookingMap = () => {

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  
  return (
    <Switch>
      <Route exact path="/map">
        {isMobile ? (
          <div className="bg-flexi-orange-2 py-[40px] px-[25px] md:p-[40px]">
            <p className='text-primary font-primary font-[900] text-[18px] md:text-[28px] mb-[30px] text-center'>
              GET READY TO DRIVE!
            </p>
            <p className="m-auto text-center text-[14px] sm:text-[16px] md:text-[18px]">
              To access the application,
              <br />
              simply download the app
              <br />
              if you haven&apos;t already installed it.
            </p>
          </div>
        ) : (
          <Booking />
        )}
      </Route>
    </Switch>
  );
};

export default StandAloneBookingMap;
