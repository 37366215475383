import { takeLatest } from 'redux-saga/effects';
import {
  SAGA_ACTIONS,
  removeFavoriteLocation,
  fetchFavoriteLocations,
  addFavoriteLocation,
} from './sagas';

const actions = [
  takeLatest(SAGA_ACTIONS.FETCH_FAVORITE_LOCATIONS, fetchFavoriteLocations),
  takeLatest(SAGA_ACTIONS.ADD_FAVORITE_LOCATION, addFavoriteLocation),
  takeLatest(SAGA_ACTIONS.REMOVE_FAVORITE_LOCATION, removeFavoriteLocation),
];

export default actions;
