import React, { Dispatch, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as Yup from 'yup';

import { ReactComponent as IconCheck } from 'assets/images/check.svg';
import { IBilling, RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import {
  selectRegistrationVFF,
  selectUser,
  setRegistrationVFF,
} from 'modules/me/slice';
import {
  selectApplyPromoStatus,
  selectErrorPromo,
  selectRemovePromoStatus,
} from 'modules/transaction/slice';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import CustomInput from 'components/CustomInputSignUp';

interface Props {
  vffMembershipId: string | undefined;
  setVffMembershipId: Dispatch<React.SetStateAction<string | undefined>>;
  accessCode: string | undefined;
  setAccessCode: Dispatch<React.SetStateAction<string | undefined>>;
  partnerMembershipId: string | undefined;
  setPartnerMembershipId: Dispatch<React.SetStateAction<string | undefined>>;
  partner: string | undefined;
  setPartner: Dispatch<React.SetStateAction<string | undefined>>;
  partnerMembershipError: string | null;
  setPartnerMembershipError: Dispatch<React.SetStateAction<string | null>>;
  billing: IBilling | null;
}

type FormValues = {
  code: string;
};

const validationSchema = Yup.object().shape({
  code: Yup.string(),
});

const PaymentPromotions = ({
  billing,
  vffMembershipId,
  setVffMembershipId,
  accessCode,
  setAccessCode,
}: Props) => {
  const dispatch = useAppDispatch();
  const errorPromo = useAppSelector(selectErrorPromo);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const applyPromoStatus = useAppSelector(selectApplyPromoStatus);
  const removePromoStatus = useAppSelector(selectRemovePromoStatus);
  const user = useAppSelector(selectUser);
  const registrationVFF = useAppSelector(selectRegistrationVFF);

  const onSubmit: SubmitHandler<FormValues> = ({ code }: FormValues) => {
    if (!isEmpty(code)) {
      const payload = {
        code: code.replaceAll(' ', ''),
        category: 'membership',
      };
      dispatch({
        type: ACTIONS.APPLY_PROMO,
        payload,
      });
    } else if (billing?.membershipFees.promo) {
      const payload = {
        code: billing?.membershipFees.promo.code,
        category: 'membership',
      };
      dispatch({
        type: ACTIONS.REMOVE_PROMO,
        payload,
      });
    }
  };

  const handleVFFBlur = (e: any) => {
    if (e.target.value) dispatch(setRegistrationVFF(e.target.value));
  };

  useEffect(() => {
    if (registrationVFF) setVffMembershipId(registrationVFF);
  }, [registrationVFF]);

  return (
    <article className="">
      {!user?.organisation?.isPartner && (
        <form
          className="mb-[30px] flex items-start gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-control flex-1">
            <label className="mb-[15px] text-[16px] font-bold lg:text-[22px] lg:font-normal">
              Promotions
            </label>
            <CustomInput
              title="Promo Code"
              error={
                applyPromoStatus === RequestStatusEnum.FAILED ||
                removePromoStatus === RequestStatusEnum.FAILED ||
                errors?.code
              }
              disabled={
                applyPromoStatus === RequestStatusEnum.PENDING ||
                removePromoStatus === RequestStatusEnum.PENDING
              }
            >
              <input
                type="text"
                placeholder="promo code"
                className={classNames(
                  'peer input input-bordered w-full self-end disabled:border-zinc-300 disabled:text-zinc-300',
                  {
                    'border-red-500':
                      applyPromoStatus === RequestStatusEnum.FAILED ||
                      removePromoStatus === RequestStatusEnum.FAILED ||
                      errors?.code,
                    // 'disabled:!border-red-500': promoStatus || errors?.code,
                  },
                )}
                {...register('code')}
                autoComplete="off"
                disabled={
                  applyPromoStatus === RequestStatusEnum.PENDING ||
                  removePromoStatus === RequestStatusEnum.PENDING
                }
                defaultValue={billing?.membershipFees.promo?.code}
                onBlur={handleSubmit(onSubmit)}
              />
              {applyPromoStatus === RequestStatusEnum.PENDING && (
                <span className="absolute right-[15px] h-[20px]  w-[20px] animate-spin rounded-full border-[3px] border-t-transparent " />
              )}
              {removePromoStatus === RequestStatusEnum.PENDING && (
                <span className="absolute right-[15px] h-[20px]  w-[20px] animate-spin rounded-full border-[3px] border-t-transparent " />
              )}
              {applyPromoStatus === RequestStatusEnum.SUCCESS && (
                <div className="absolute right-[15px] grid h-[90%] w-[25px] place-items-center rounded-md bg-white">
                  <IconCheck className="fill-flexi-green-1" />
                </div>
              )}
              {removePromoStatus === RequestStatusEnum.SUCCESS && (
                <div className="absolute right-[15px] grid h-[90%] w-[25px] place-items-center rounded-md bg-white">
                  <IconCheck className="fill-flexi-green-1" />
                </div>
              )}
            </CustomInput>
            {applyPromoStatus === RequestStatusEnum.SUCCESS && (
              <p className="mt-[10px] ml-1 text-[12px]">Promo code applied</p>
            )}
            {removePromoStatus === RequestStatusEnum.SUCCESS && (
              <p className="mt-[10px] ml-1 text-[12px]">Promo code removed</p>
            )}
            {errors.code && (
              <InvalidText>The selected code is invalid.</InvalidText>
            )}
            {errorPromo && (
              <InvalidText>The selected code is invalid.</InvalidText>
            )}
          </div>
        </form>
      )}

      <div className="form-control mb-[30px] w-full gap-[15px] lg:gap-[20px]">
        <label className="text-[16px] font-bold lg:text-[22px] lg:font-normal">
          Velocity Frequent Flyer
        </label>
        <CustomInput title="Membership Number">
          <input
            placeholder="velocity number"
            type="text"
            className={classNames(
              'peer input input-bordered w-full focus:outline-none',
            )}
            // defaultValue={registrationVFF || vffMembershipId}
            value={vffMembershipId}
            onChange={(e) => setVffMembershipId(e.target.value)}
            onBlur={handleVFFBlur}
            autoComplete="off"
          />
        </CustomInput>
      </div>

      <div className="form-control w-full gap-[15px] lg:gap-[20px]">
        <label className="text-[16px] font-bold lg:text-[22px] lg:font-normal">
          Access Code
        </label>
        <CustomInput title="Access Code">
          <input
            placeholder="velocity number"
            type="text"
            className={classNames(
              'peer input input-bordered w-full focus:outline-none',
            )}
            // defaultValue={registrationVFF || vffMembershipId}
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            // onBlur={handleAccessCodeBlur}
            autoComplete="off"
          />
        </CustomInput>
      </div>
    </article>
  );
};

export default PaymentPromotions;
