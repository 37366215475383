import classNames from 'classnames';
import { RequestStatusEnum } from 'common/types';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import { selectPending, selectUser, selectUserStatus } from 'modules/me/slice';
import ACTIONS from 'modules/rootActions';
import React, { useRef, useLayoutEffect } from 'react';
// import RegisterIndicator from 'features/RegisterIndicator';
import { Link, useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import gsap from 'gsap';
import { useMediaQuery } from 'react-responsive';

const RegisterThankYou = () => {
  const { push } = useHistory();
  const isPending = useAppSelector(selectPending);
  const { isBusinessDriverAccount } = useBusiness();
  
  
  const user = useAppSelector(selectUser);
  const userStatus = useAppSelector(selectUserStatus);

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if(!isPending){
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
    }
  }, [isPending]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_ME });
  });

  return isPending ? (
    <PendingLoader />
  ) : (
    <section
      ref={component}
      className="fadein relative flex w-full max-w-[700px] flex-col items-start text-center md:text-left"
    >
      {/* <RegisterIndicator /> */}
      <h2 className="font-primary mt-[20px] mb-[20px] text-[30px] font-[900] uppercase leading-[36px] text-flexi-orange-1 md:text-[40px] md:leading-normal lg:mt-0">
        {user?.status ? (
          isBusinessDriverAccount ? (
            <span>thank you, {user?.firstname}!</span>
          ) : (
            <span>your application has been&nbsp;submitted!</span>
          )
        ) : (
          <span>
            {/* sorry. your application
            <br />
            has been denied. */}
          </span>
        )}
      </h2>

      <p className="mb-[40px] text-[16px] md:mb-[70px] md:text-[16px]">
        {user?.status ? (
          isBusinessDriverAccount ? (
            <span>
              You have successfully created your business driver account.
              <br /> <br />
              Your membership is now being processed and we will let you know
              once you are good to go.
            </span>
          ) : (
            <span>
              You have successfully created an account and your membership is
              now being reviewed by our&nbsp;team. <br /> <br />
              We will let you know as soon as we are done, but in the meantime
              make sure you download the Flexicar app on your mobile and check
              out our video on
              <br />
              <Link
                to="/how-it-works"
                target="_blank"
                className="font-bold text-flexi-orange-1"
              >
                How Flexicar Works
              </Link>{' '}
              to help get you started.
            </span>
          )
        ) : (
          <span>
            {/* Unfortunately, the internal checks we run to approve your account
            has not been successful and we are unable to proceed with your
            application as per our
            <br />
            <Link
              to="/terms-and-conditions"
              target='_blank'
              className="font-bold text-flexi-orange-1"
            >
              Terms and Conditions
            </Link>
            .<br />
            <br />
            Any successful payments made has been refunded to the same credit
            card they were charged against. */}
          </span>
        )}
      </p>
      {user?.status === 'approved' ? (
        <button
          className={classNames(
            'btl-lg btn mx-auto !h-[50px] w-[250px] rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2 md:!h-[60px] lg:mx-0',
            {
              'loading bg-flexi-green-2':
                userStatus === RequestStatusEnum.PENDING,
            },
          )}
          // onClick={() => push('/app/booking')}
          onClick={() => {
            if (isMobile) {
              push('/onboarding');
            } else {
              push('/app/booking');
            }
          }}
        >
          See Flexicar Features
        </button>
      ) : (
        <Link
          to="/faq/main"
          className="btl-lg btn h-[60px] w-[200px] rounded-full border-none bg-flexi-green-1 text-[16px] capitalize hover:bg-flexi-green-2"
        >
          See FAQ&apos;s
        </Link>
      )}
    </section>
  );
};

export default RegisterThankYou;
