import { takeLatest } from 'redux-saga/effects';
import {
  getNearestPods,
  getNearestPodsPublic,
  getAllPods,
  getPodsVehicle,
  SAGA_ACTIONS
} from './sagas';

const {
  GET_NEAREST_PODS,
  GET_NEAREST_PODS_PUBLIC,
  GET_PODS_ALL,
  GET_PODS_VEHICLE
} = SAGA_ACTIONS;

const actions = [
  takeLatest(GET_NEAREST_PODS, getNearestPods),
  takeLatest(GET_NEAREST_PODS_PUBLIC, getNearestPodsPublic),
  takeLatest(GET_PODS_ALL, getAllPods),
  takeLatest(GET_PODS_VEHICLE, getPodsVehicle),
];

export default actions;
