import React, { useEffect } from 'react';
import PageLayout from 'components/layouts/PageLayout';
import AppLayout from 'components/layouts/AppLayout';
import { ApprovedAuthenticatedTopNav } from 'components/TopNav';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
// import BookingPage from 'pages/BookingPage';
import BookingPageOptimized from 'pages/BookingPageOptimized';
import MyTripsPage from 'pages/MyTripsPage';
import MyAccountPage from 'pages/MyAccountPage';
import { useEffectOnce } from 'react-use';
import ACTIONS from 'modules/rootActions';
import useAppDispatch from 'hooks/useAppDispatch';
import MyDriversPage from 'pages/MyDriversPage';
import LogoutModal from 'components/LogoutModal';
import useAppSelector from 'hooks/useAppSelector';
import { selectPendingLogout } from 'modules/auth/slice';
import { useRedirectToRegistration } from 'hooks/useRedirect';
import MyPlanSelection from 'features/MyPlanSelection';
import MyPlanSelectionMobile from 'features/MyPlanSelectionMobile';
import BookingDetails from 'features/BookingDetails';
import AddAccountPage from 'pages/AddAccountPage';
import CancelMembership from 'features/CancelMembership';
import CancelMembershipDone from 'features/CancelMembershipDone';
// import HowItWorks from 'features/HowItWorks';
import { selectUser } from 'modules/me/slice';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import useLogout from 'hooks/useLogout';
import AddPaymentMethod from 'features/AddPaymentMethod';
import AddPaymentMethodDeclined from 'features/AddPaymentMethodDeclined';
import AddPaymentMethodSuccess from 'features/AddPaymentMethodSuccess';
import BroadcastSystem from 'components/BroadcastSystem';
import { removePreviousUrl } from 'common/helpers';

const AppPage = () => {
  const dispatch = useAppDispatch();
  const isLogoutPending = useAppSelector(selectPendingLogout);
  const user = useAppSelector(selectUser);
  const { logoutApiless } = useLogout();
  const { pathname } = useLocation();

  useRedirectToRegistration();

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_ME });
  });
  
  const [value] = useDocument(
    user?.statusKey
    ? doc(getFirestore(firebaseApp), 'deactivateUser', user.statusKey)
    : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    if (!pathname.includes('/app/booking'))
      removePreviousUrl();
  }, [pathname])
  
  useEffect(() => {
    const firebaseUserStatus =
      value &&
      JSON.stringify(value.data()) &&
      Object.values(JSON.parse(JSON.stringify(value?.data())))[0];
    if (
      firebaseUserStatus === 'deactivated'
    ) {
      logoutApiless();
    }
  }, [value]);

  useEffect(() => {
    if (user?.status === 'pending') {
      // logoutApiless();
    }
  }, [user])

  return (
    <PageLayout>
      {pathname !== '/app/account/plan-selection-mobile' && (
        <>
          <BroadcastSystem />
          <ApprovedAuthenticatedTopNav />
        </>
      )}
      <AppLayout>
        <Switch>
          {/* <Route path="/app/how-flexicar-works" component={HowItWorks} /> */}

          <Route path="/app/booking" component={BookingPageOptimized} />
          {/* <Route path="/app/booking" component={BookingPage} /> */}
          <Route path="/app/my-bookings" component={MyTripsPage} />
          <Route path="/app/drivers" component={MyDriversPage} />
          <Route path="/app/account" component={MyAccountPage} />
          <Route path="/app/add-account" component={AddAccountPage} />
          <Route path="/app/add-payment-method" component={AddPaymentMethod} />
          <Route
            path="/app/add-payment-method-declined"
            component={AddPaymentMethodDeclined}
          />
          <Route
            path="/app/add-payment-method-success"
            component={AddPaymentMethodSuccess}
          />
          <Route
            path="/app/account/plan-details/plan-selection"
            component={MyPlanSelection}
          />
          <Route
            path="/app/account/plan-selection-mobile"
            component={MyPlanSelectionMobile}
          />
          <Route
            path="/app/account/cancel-membership"
            component={CancelMembership}
          />
          <Route
            path="/app/account/cancel-membership/done"
            component={CancelMembershipDone}
          />
          <Route
            path="/app/my-bookings/booking-details"
            component={BookingDetails}
          />
          {/* <Route exact path="/app/faqs" component={MyAccountFaqs} /> */}
          <Redirect from="/app" to="/app/booking" />
        </Switch>
        {isLogoutPending && <LogoutModal isOpen={isLogoutPending} />}
      </AppLayout>
    </PageLayout>
  );
};

export default AppPage;
