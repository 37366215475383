/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useLayoutEffect } from 'react';
import { InvalidText, PageTitle } from 'components/Typography';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useAppDispatch from 'hooks/useAppDispatch';
import ACTIONS from 'modules/rootActions';
import { isEmpty } from 'lodash';
import MyAccountNav from 'components/MyAccountNav';
import CustomInput from 'components/CustomInput';
import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import { ReactComponent as IconFailed } from 'assets/images/failed.svg';
import { ReactComponent as IconPerson } from 'assets/images/drivers.svg';
import Modal from 'components/Modal';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import {
  selectChangePasswordStatus,
  selectErrors,
  selectLoggedInDevices,
  setErrors,
} from 'modules/me/slice';
import { ILoggedInDevices, RequestStatusEnum } from 'common/types';
import PendingLoader from 'components/PendingLoader';
import { useEffectOnce, useUnmount, useUpdateEffect } from 'react-use';
import useLogout from 'hooks/useLogout';
import { useMediaQuery } from 'react-responsive';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';
import MyAccountBlock from 'components/MyAccountBlock';
import { selectDynamicBlockContent } from 'modules/frontend/slice';
import { useLocation } from 'react-router-dom';
import { AiOutlineMobile, AiOutlineDesktop, AiOutlineTablet } from 'react-icons/ai';
import { getDeviceId } from 'common/helpers';
import moment from 'moment';

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  oldPassword: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('This field is required'),
  password: Yup.string()
    .required('')
    .matches(/\d/, '')
    .matches(/[a-z]/, '')
    .matches(/[A-Z]/, '')
    .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
    .min(8),
});

const Security = () => {
  const passwordError = useAppSelector(selectErrors);
  const dispatch = useAppDispatch();
  const changePasswordStatus = useAppSelector(selectChangePasswordStatus);
  const [passCVisible, setPassCVisible] = useState(false);
  const [passNVisible, setPassNVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sessionModal, setSessionModal] = useState(false);
  const { logout } = useLogout();
  const loggedInDevicesInfo = useAppSelector(selectLoggedInDevices);
  const logins = loggedInDevicesInfo?.logins
  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<FormValues>(formOptions);
  const dynamicContent = useAppSelector(selectDynamicBlockContent);
  const { pathname } = useLocation();

  const oldPassword = useWatch({ control, name: 'oldPassword' });
  const password = useWatch({ control, name: 'password' });

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(setErrors({}));
  };

  const handleSessionModalClose = () => {
    setSessionModal(false);
  };

  // const isMobile = useMediaQuery({
  //   query: '(max-width: 500px)',
  // });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const payload = {
      ...values,
      passwordConfirmation: values.password,
    };
    dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload });
  };

  // useEffectOnce(() => {
  //   dispatch(setErrors({}));
  //   clearErrors();
  // });

  useUnmount(() => {
    dispatch(setErrors({}));
    clearErrors();
  });

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_DEVICES });
  })

  const logoutDevice = (login: ILoggedInDevices | null) => {
    dispatch({
      type: ACTIONS.LOGOUT_DEVICE,
      payload: {
        id: login?.id.toString() || null
      }
    });
    if(login?.deviceId === getDeviceId()){
      dispatch(logout);
    }
    if(login !== null && login?.deviceId !== getDeviceId()){
      const timeout = setTimeout(() => {
        window.location.reload();
      }, 2000);
      return () => clearTimeout(timeout);
    }
    if(login === null){
      const timeout = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }

  useUpdateEffect(() => {
    if (changePasswordStatus === RequestStatusEnum.SUCCESS) {
      setIsModalOpen(true);
      setTimeout(() => {
        logoutDevice(null);
        // logout();
      }, 6000);
    }
  }, [changePasswordStatus]);

  const renderModalContent = () => {
    if (changePasswordStatus === RequestStatusEnum.SUCCESS) {
      return (
        <>
          <IconSuccess className="icon" />
          <h1 className="title">
            Your password has been successfully changed.
          </h1>
          <p className="mt-[20px] text-center text-[14px] md:text-[18px]">
            You will now be log out of the system for security purposes.
          </p>
          <button
            className={`button btn mt-[40px] max-h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize md:max-h-[60px]`}
            onClick={() => {
              logout();
              logoutDevice(null);
            }}
          >
            Ok
          </button>
        </>
      );
    }
    if (changePasswordStatus === RequestStatusEnum.FAILED) {
      return (
        <>
          <IconFailed className="icon" />
          <h1 className="title">Failed to change password!</h1>
          {/* <p className="mt-[20px]">{changePasswordError?.changePassword}</p> */}
          <button
            className={`button btn mt-[40px] max-h-[50px] w-full rounded-full border-none bg-flexi-green-1 text-[16px] capitalize md:max-h-[60px]`}
            onClick={handleModalClose}
          >
            Ok
          </button>
        </>
      );
    }
    return <PendingLoader />;
  };

  useEffectOnce(() => {
    dispatch({
      type: ACTIONS.FETCH_DYNAMIC_BLOCK_CONTENT,
      payload: {
        keys: [pathname.split('app/account/')[1]],
      },
    });
  });

  return (
    <section
      ref={component}
      className="fadein relative mx-[25px] mt-[80px] md:mt-[100px] lg:mx-0"
    >
      <Helmet>
        <title>My Account - Security</title>
        <meta
          name="description"
          content="My Flexicar Account - Security - Change Password"
        />
      </Helmet>
      <MyAccountNav title="security" />
      <div className="w-full justify-between gap-[100px] lg:flex">
        <div className="gap-[100px] lg:flex">
          <article className=" flex w-[84vw] flex-col sm:w-[500px]">
            <PageTitle>Security</PageTitle>
            <div className="mt-[10px] flex flex-col gap-4 border-t-[1px] pt-[40px] md:mt-[30px] md:pt-[50px]">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="relative">
                    <CustomInput
                      title="Current Password"
                      error={
                        errors?.oldPassword ||
                        changePasswordStatus === RequestStatusEnum.FAILED
                      }
                    >
                      <input
                        type={passCVisible ? 'text' : 'password'}
                        placeholder="current password"
                        className={`peer input-bordered input w-full pr-[50px]
                        ${
                          (errors?.oldPassword ||
                            changePasswordStatus ===
                              RequestStatusEnum.FAILED) &&
                          'border-red-500'
                        }`}
                        {...register('oldPassword')}
                      />
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setPassCVisible(!passCVisible);
                        }}
                        className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
                      >
                        {passCVisible ? (
                          <IconPasswordShow className=" scale-[1.7] fill-flexi-orange-1" />
                        ) : (
                          <IconPasswordHide className=" scale-[1.2] fill-flexi-black-6" />
                        )}
                      </span>
                    </CustomInput>
                  </div>
                </div>

                <div>
                  <div className="relative">
                    <CustomInput
                      title="New Password"
                      error={errors?.password}
                      margin={20}
                    >
                      <input
                        placeholder="new password"
                        type={passNVisible ? 'text' : 'password'}
                        className={`peer input-bordered input w-full pr-[50px]
                        ${errors?.password && 'border-red-500'}`}
                        {...register('password')}
                      />
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setPassNVisible(!passNVisible);
                        }}
                        className="pointer-events-auto absolute right-1 grid h-[90%] w-[40px] cursor-pointer place-items-center rounded-lg bg-transparent"
                      >
                        {passNVisible ? (
                          <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
                        ) : (
                          <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
                        )}
                      </span>
                    </CustomInput>
                  </div>

                  {!isEmpty(errors.password) && (
                    <div className=" my-2 text-[12px] text-red-500">
                      {password ? (
                        <ul className="relative ml-5 list-disc">
                          <li
                            className={classNames('', {
                              hidden: password.length >= 8,
                            })}
                          >
                            <span className="absolute left-[-5px]">
                              Must be at least 8 characters
                            </span>
                          </li>
                          <li
                            className={classNames('', {
                              hidden: password.match(/\d/),
                            })}
                          >
                            <span className="absolute left-[-5px]">
                              Must contain at least 1 number
                            </span>
                          </li>
                          <li
                            className={classNames('', {
                              hidden:
                                password.match(/[a-z]/) &&
                                password.match(/[a-z]/) &&
                                password.match(
                                  /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
                                ),
                            })}
                          >
                            <span className="absolute left-[-5px]">
                              Must contain lowercase, uppercase, and a special
                              character
                            </span>
                          </li>
                        </ul>
                      ) : (
                        <p>This field is required</p>
                      )}
                    </div>
                  )}
                  {/* {passwordError.changePassword && (
                    <InvalidText>{passwordError.changePassword}</InvalidText>
                  )} */}
                  <InvalidText>{passwordError.changePassword}</InvalidText>
                </div>

                <button
                  type="submit"
                  className={classNames(
                    'btn mt-[50px] h-[40px] w-full rounded-full border-none bg-flexi-green-1 capitalize hover:bg-flexi-green-2 disabled:text-white md:h-[60px]',
                    {
                      'loading !bg-flexi-green-2':
                        changePasswordStatus === RequestStatusEnum.PENDING,
                    },
                  )}
                  disabled={!password || !oldPassword}
                >
                  Update
                </button>
              </form>
            <hr className="my-[50px]" />

            <div className="mb-[100px]">
              <p className="mb-[10px] text-[26px] font-bold">Your Devices</p>
              { !logins && <p className="color-[#898989] text-[18px] uppercase">no active sessions</p> }
              {
                logins?.map((login:any) => {
                  const lastLogin = moment(login.lastUsedAt).fromNow();
                  return(
                    <div key={login.id}>
                      <div className="flex justify-center gap-[28px] border-b py-[20px]">
                        <div className="flex items-center justify-center">
                          { login.deviceType === 'mobile' ?
                            <AiOutlineMobile className="text-2xl" />
                            :
                            login.deviceType === 'tablet' ?
                            <AiOutlineTablet className="text-2xl" />
                            :
                            <AiOutlineDesktop className="text-2xl" />
                          }
                        </div>
                        <div className="flex-1">
                          <p className="text-[18px] font-bold">
                            {login.deviceName === null ? 'Unknown Device' : login.deviceName}
                          </p>
                          <div className="flex flex-row text-[14px]">
                            <p>{login.deviceName === null ? '' : <>Flexicar App&nbsp;-&nbsp;</>}</p>
                            <p>{lastLogin}</p>
                            { login.deviceId === getDeviceId() ?
                              <p>&nbsp;-&nbsp;
                                <span className="text-primary">Active now</span>
                              </p>
                              :
                              <></>
                            }
                          </div>
                        </div>
                        <button
                          onClick={() => logoutDevice(login)}
                          className="text-[16px] text-primary"
                        >
                          Log Out
                        </button>
                      </div>
                    </div>
                  )
                })
              }
              <button
                className="mt-8 text-[16px] text-primary"
                onClick={() => setSessionModal(true)}
              >
                <b>Log Out Of All Sessions</b>
              </button>
            </div>

            <Modal
              isModalOpen={isModalOpen}
              handleClose={handleModalClose}
              px={isMobile ? '!px-[50px]' : 'px-[120px]'}
              mx={isMobile ? '30px' : '0px'}
            >
              {renderModalContent()}
            </Modal>

        {dynamicContent ? <MyAccountBlock data={dynamicContent} /> : null}

            <Modal
              isModalOpen={sessionModal}
              handleClose={handleSessionModalClose}
              px={isMobile ? '!px-[50px]' : 'px-[120px]'}
              mx={isMobile ? '30px' : '0px'}
            >
              <IconSuccess className="icon mx-auto mb-[30px]" />
              <p className="title">
                Log Out Of All Sessions
              </p>
              <p className="text-center text-[18px]">
                This will log you out of Flexicar from every device you’re
                logged in on. <br />
                If you didn&apos;t log in on any of these devices, we can help you<br/>secure your account.
              </p>
              <p
                className="button btn capitalize"
                onClick={() => logoutDevice(null)}
              >
                Log Out
              </p>
            </Modal>
          </div>
        </article>

        <div className="h-fit w-full max-w-[400px] rounded-[10px] bg-[#F2F2F2] px-[60px] pt-[38.97px] pb-[50px]">
          <IconPerson className="mb-[28.97px]" />
          <b className="mb-[10px] block">View recent login activity</b>
          <p>
            Here are all the devices that are currently logged in with your
            Flexicar account. If you see an entry you don’t recognize, log out
            of that device and change your password immediately.
          </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Security;