import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import firebaseApp from 'app/firebaseApp';
import classNames from 'classnames';
import gsap from 'gsap';

import { getToken } from 'common/helpers';
import { RequestStatusEnum } from 'common/types';
import { selectUser, selectUserStatus } from 'modules/me/slice';
import { selectPending as selectPendingOcr } from 'modules/ocr/slice';
import ACTIONS from 'modules/rootActions';
import PendingLoader from 'components/PendingLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import Guidelines from './Guidelines';
import VerificationContent from './VerificationContent';

const SignUpOCR = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const component = useRef<HTMLDivElement>(null);
  const isPendingOcr = useAppSelector(selectPendingOcr);
  const userStatus = useAppSelector(selectUserStatus);
  const user = useAppSelector(selectUser);
  const token = getToken();

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [value] = useDocument(
    user?.ocrId ? doc(getFirestore(firebaseApp), 'ocr', user.ocrId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    /* eslint-disable */
    console.log('val data:', value);
    console.log('ocr id:', user?.ocrId);
    if (value) {
      console.log('ocr meta:', value.data());
    }
    /* eslint-enable */

    if (value && value.data()?.redirectTo) {
      const val = value.data();
      const redirect = val && val.redirectTo;
      if (redirect === 'app') {
        push(`/app/booking`);
      } else if (redirect === 'declined-v2') {
        push(`/register-v2/verify-failed`);
      } else if (redirect === 'address-verification-v2') {
        push(`/register-v2/address-verification`);
      } else if (redirect === 'plan-v2') {
        push(`/register-v2/plan`);
      } else if (redirect === 'payment-v2') {
        push(`/register-v2/payment`);
      } else if (redirect === 'verify-done') {
        push(`/register-v2/done`);
      } else if (redirect === 'ocr-verification-v2') {
        if (isMobile && user && user.ocrId) {
          const timeoutId = setTimeout(() => {
            window.location.replace(user?.ocrTransactionUrl);
          }, 12000);
          return () => clearTimeout(timeoutId);
        }
      } else if (user) {
        if (user.redirectToV2 === 'app') {
          push(`/app/booking`);
        } else if (user.redirectToV2 === 'declined-v2') {
          push(`/register-v2/verify-failed`);
        } else if (user.redirectToV2 === 'address-verification-v2') {
          push(`/register-v2/address-verification`);
        } else if (user.redirectToV2 === 'plan-v2') {
          push(`/register-v2/plan`);
        } else if (user.redirectToV2 === 'payment-v2') {
          push(`/register-v2/payment`);
        } else if (user.redirectToV2 === 'verify-done') {
          push(`/register-v2/done`);
        } else if (user.redirectToV2 === 'ocr-verification-v2') {
          if (isMobile && user.ocrId) {
            const timeoutId = setTimeout(() => {
              window.location.replace(user?.ocrTransactionUrl);
            }, 12000);
            return () => clearTimeout(timeoutId);
          }
        }
      }
    }
  }, [user, value]);

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.FETCH_ME });
  });

  useEffect(() => {
    if (!isMobile) dispatch({ type: ACTIONS.FETCH_QR_CODE });
  }, [user]);

  return (
    <section
      ref={component}
      className="fadein flex min-h-[calc(100vh-90px)] w-full max-w-[600px] flex-col pt-[45px] md:min-h-[calc(100vh-388px)]"
    >
      {!token && isMobile ? (
        <p className="absolute left-1/2 top-1/2 z-20 w-3/4 -translate-x-1/2 -translate-y-[calc(50%)] text-center text-[14px]">
          Verification is complete. If not redirected soon, please return to the
          app or your original browser to{' '}
          <span className="whitespace-nowrap">continue registration.</span>
        </p>
      ) : userStatus === RequestStatusEnum.PENDING ? (
        <div className="absolute left-1/2 top-1/2 z-20 h-[50px] w-[50px] -translate-x-1/2 -translate-y-[calc(50%)]">
          <AiOutlineLoading3Quarters className="h-[50px] w-[50px] animate-spin fill-flexi-orange-1" />
        </div>
      ) : (
        <>
          {!isMobile && (
            <h2 className="font-primary mb-[36px] text-center text-[30px] font-[900] uppercase text-flexi-orange-1 md:text-[28px]">
              verification
            </h2>
          )}
          {isPendingOcr ? (
            <section className="flex min-h-[calc(100vh-90px)] items-center justify-center md:min-h-[calc(100vh-388px)]">
              <PendingLoader size={100} />
            </section>
          ) : (
            <div className=" h-full w-full">
              <div className="relative h-full min-h-[calc(100vh-90px)] w-full">
                <div
                  className={classNames(
                    'absolute left-1/2 top-1/2 z-20 h-[50px] w-[50px] -translate-x-1/2 -translate-y-[calc(50%+50px)]',
                    { hidden: !isMobile },
                  )}
                >
                  <AiOutlineLoading3Quarters className="h-[50px] w-[50px] animate-spin fill-flexi-orange-1" />
                </div>
                <div className={classNames({ hidden: isMobile })}>
                  <Guidelines />
                  <VerificationContent />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default SignUpOCR;
