import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { isEmpty, omitBy } from 'lodash';
import axios from 'axios';

import { IUser, RequestStatusEnum } from 'common/types';
import { handleAPIError } from 'common/errorHandler';
import { authenticatedClient } from 'app/apiClient';
import { selectUser } from 'modules/me/slice';
import {
  setBilling,
  setBookingPromo,
  setError,
  setErrorPromo,
  setPendingBilling,
  setPendingCardUrl,
  setPendingPayment,
  setPendingPromo,
  setPromoSuccess,
  setWindcaveCardStatus,
  setWindcaveCardUrl,
  setWindcavePaymentUrl,
  setApplyPromoStatus,
  setRemovePromoStatus,
} from './slice';

export const SAGA_ACTIONS = {
  FETCH_WINDCAVE_CARD_URL: 'FETCH_WINDCAVE_CARD_URL',
  FETCH_WINDCAVE_PAYMENT_URL: 'FETCH_WINDCAVE_PAYMENT_URL',
  REGISTER_PAYMENT: 'REGISTER_PAYMENT',
  FETCH_BILLING: 'FETCH_BILLING',
  APPLY_PROMO: 'APPLY_PROMO',
  VERIFY_PROMO: 'VERIFY_PROMO',
  REMOVE_PROMO: 'REMOVE_PROMO',
  REGISTER_PAYMENT_V2: 'REGISTER_PAYMENT_V2',
};

export function* fetchWindcaveCardUrl() {
  const api = authenticatedClient();
  try {
    yield put(setPendingCardUrl(true));
    const { data } = yield call(() => api.post('/register/payment/add-card'));
    yield put(setWindcaveCardUrl(data.redirectTo));
    yield put(setWindcaveCardStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setWindcaveCardStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPendingCardUrl(false));
  }
}

export function* fetchWindcavePaymentUrl() {
  const api = authenticatedClient();
  try {
    yield put(setPendingPayment(true));
    const { data } = yield call(() => api.post('/register/payment'));
    yield put(setWindcavePaymentUrl(data.redirectTo));
    yield put(setError(null));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setError(err.response.data.error));
    }
  } finally {
    yield put(setPendingPayment(false));
  }
}

type RegisterPaymentPayload = {
  referralEmail?: string | undefined;
  vffMembershipId?: string | undefined;
  partner?: string | undefined;
  partnerMembershipId?: string | undefined;
  hasReduceExcessDamageCover?: string | undefined;
};

export function* registerPayment(
  action: PayloadAction<RegisterPaymentPayload>,
) {
  const api = authenticatedClient();
  const payloadOmitted = omitBy(action.payload, isEmpty);
  const user: IUser | null = yield select(selectUser);

  try {
    yield put(setPendingPayment(true));
    yield call(() => api.post('/register/payment', payloadOmitted));
    yield put(push('/register/done'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      if(err.response.data?.message === "Plan has already been paid." && user?.redirectTo === 'app'){
        yield put(push('/app/booking'))
      }
    }
  } finally {
    yield put(setPendingPayment(false));
  }
}

export function* fetchBilling() {
  const api = authenticatedClient();
  try {
    yield put(setPendingBilling(true));
    const { data } = yield call(() => api.get('/transaction/billing'));
    yield put(setBilling(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingBilling(false));
  }
}

type ApplyPromoPayload = {
  code: string;
  category: string;
};

export function* applyPromo(action: PayloadAction<ApplyPromoPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setRemovePromoStatus(null));
    yield put(setErrorPromo(null));
    yield put(setApplyPromoStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.post('/promos/apply', action.payload),
    );
    yield put(setBilling(data));
    yield put(setApplyPromoStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setErrorPromo(err.response.data?.message || err.response.data?.errors?.code[0]));
    }
    yield put(setApplyPromoStatus(RequestStatusEnum.FAILED));
  } 
}


export function* removePromo(action: PayloadAction<ApplyPromoPayload>) {
  const api = authenticatedClient();
  try {
    yield put(setApplyPromoStatus(null));
    yield put(setErrorPromo(null));
    yield put(setRemovePromoStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.post('/promos/remove', action.payload),
    );
    yield put(setBilling(data));
    yield put(setRemovePromoStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setErrorPromo(err.response.data?.message || err.response.data?.errors?.code[0]));
    }
    yield put(setRemovePromoStatus(RequestStatusEnum.FAILED));
  } 
}

type VerifyPromoPayload = {
  code: string;
  category: string;
  amount: number;
  startsAt: string;
  endsAt: string;
};

export function* verifyPromo(action: PayloadAction<VerifyPromoPayload>): any {
  const api = authenticatedClient();
  try {
    yield put(setApplyPromoStatus(null));
    yield put(setPendingPromo(true));
    yield call(() => api.post('/promos/verify', action.payload));
    yield put(setErrorPromo(null));
    yield put(setPromoSuccess(true));
    yield put(setBookingPromo(action.payload.code));
    yield put(setApplyPromoStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // yield put(setErrorPromo('The selected code is invalid.'));
      yield put(setPromoSuccess(false));
      yield put(setBookingPromo(null));
      yield put(setErrorPromo(err.response.data?.message || err.response.data?.errors?.code[0]));

      yield put(setApplyPromoStatus(RequestStatusEnum.FAILED));
      // handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingPromo(false));
  }
}

type RegisterPaymentPayloadV2 = {
  referralEmail?: string | undefined;
  vffMembershipId?: string | undefined;
  partner?: string | undefined;
  partnerMembershipId?: string | undefined;
  hasReduceExcessDamageCover?: string | undefined;
};

export function* registerPaymentV2(
  action: PayloadAction<RegisterPaymentPayloadV2>,
) {
  const api = authenticatedClient();
  const payloadOmitted = omitBy(action.payload, isEmpty);
  const user: IUser | null = yield select(selectUser);

  try {
    yield put(setPendingPayment(true));
    yield call(() => api.post('/register/payment', payloadOmitted));
    yield put(push('/register-v2/done'));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      if(err.response.data?.message === "Plan has already been paid." && user?.redirectTo === 'app'){
        yield put(push('/app/booking'))
      }
    }
  } finally {
    yield put(setPendingPayment(false));
  }
}