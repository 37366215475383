import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import classNames from 'classnames';
import CustomCheckBox from 'components/CustomCheckbox';
import { InvalidText } from 'components/Typography';
import CustomInput from 'components/CustomInput';
import ImageUpload from './ImageUpload';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import useAppSelector from 'hooks/useAppSelector';
import { selectErrors, selectReportBookingStatus, setErrors } from 'modules/booking/slice';
import { RequestStatusEnum } from 'common/types';
import { BsImage } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import useAppDispatch from 'hooks/useAppDispatch';

interface Props {
  onSave: any;
}

type FormValues = {
  categories: string[];
  parkingType: string;
  isParkingType: boolean;
  registrationPlate: string;
  model: string;
  color: string;
  detailedDescription: string;
  isParkingNearby: boolean;
  isUnderstood: boolean;
  bayIsTaken: boolean;
  bayInfo: string;
  otherInfo: string;
};

const validationSchema = yup.object().shape({
  bayIsTaken: yup.boolean().optional(),
  remarks: yup.string().optional(),
  images: yup.object().optional(),
  isParkingType: yup.boolean(),
  detailedDescription: yup.string().when('bayIsTaken', {
    is: true,
    then: yup.string().required('This field is required.'),
  }),
  parkingType: yup.string().when('bayIsTaken', {
    is: true,
    then: yup.string().required('This field is required.'),
  }),
  // .required('This field is required.'),
  registrationPlate: yup.string().when('bayIsTaken', {
    is: true,
    then: yup.string().required('This field is required.'),
  }),
  model: yup.string().when('bayIsTaken', {
    is: true,
    then: yup.string().required('This field is required.'),
  }),
  color: yup.string().when('bayIsTaken', {
    is: true,
    then: yup.string().required('This field is required.'),
  }),
  isParkingNearby: yup.boolean(),
  isUnderstood: yup.boolean().when('bayIsTaken', {
    is: true,
    then: yup.boolean().required('This field is required.'),
  }),

  // .required('field required'),
  // isParkingNearby: yup.boolean().required('This parker field is required.'),
  // isUnderstood: yup.boolean().required('This field is required.'),
});

const OffboardingForm = ({ onSave }: Props) => {
  const categories = [
    'Bay Is Taken',
    'Cleanliness',
    'Mechanical',
    'Maintenance',
    'Low Fuel',
    'Missing Cards',
    'Other',
  ];
  const dispatch = useAppDispatch();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  // const [parkingType, setParkingType] = useState(true);
  const [isParkingNearby, setisParkingNearby] = useState(false);
  // const [parkingNearbyError, setParkingNearbyError] = useState(false);
  const [isUnderstood, setisUnderstood] = useState(false);
  const [understoodError, setUnderstoodError] = useState(false);
  const reportStatus = useAppSelector(selectReportBookingStatus);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: { parkingType: 'On-Street Bay', isParkingType: true, bayIsTaken: false },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormValues>(formOptions);
  const reportErrors = useAppSelector(selectErrors);
  // const [detailedDescription, setDetailedDescription] = useState('')

  const handleCheckboxSelected = (category: string, evt: any) => {
    if (evt.target.checked) {
      dispatch(setErrors(null))
      if (category.toLocaleLowerCase() === 'bay is taken') {
        setValue('bayIsTaken', true);
      }
      setSelectedCategories((prevState: string[]) => {
        return [...prevState, ...[category]];
      });
      return;
    } else {
      if (category.toLocaleLowerCase() === 'bay is taken') {
        setValue('bayIsTaken', false);
      }
    }
    setSelectedCategories((prevState: string[]) =>
      prevState.filter((item) => item !== category),
    );

  };


  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    return onSave({
      ...data,
      categories: selectedCategories,
      uploads,
      textValue,
      // detailedDescription
    });
  };

  const [textValue, setTextValue] = useState<any>({
    bayIsTaken: '',
    cleanliness: '',
    mechanical: '',
    maintenance: '',
    lowFuel: '',
    missingCards: '',
    other: '',
  });

  const [uploads, setUploads] = useState<any>({
    bayIsTaken: [],
    cleanliness: [],
    mechanical: [],
    maintenance: [],
    lowFuel: [],
    missingCards: [],
    other: [],
  });

  const submitButtonClick = () => {
    if (selectedCategories.includes('Bay Is Taken'))
      setUnderstoodError(!isUnderstood);
  };

  const handleSelectChange = () => {
    // if (e.target.value === 'On-Street Bay') {
    //   setParkingType(true);
    //   setValue('isParkingType', true);
    // } else {
    //   setParkingType(false);
    //   setValue('isParkingType', false);
    // }
    setisParkingNearby(false);
    setisUnderstood(false);
    clearErrors();

    setValue('isUnderstood', false);
    setValue('isParkingNearby', false);
  };

  const toCamelCase = (category: string) => {
    return category
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase(),
      )
      .replace(/\s+/g, '');
  };

  const handleImageRemove = (imageIndex: number, category: string) => {
    const imageFilter = uploads[toCamelCase(category)].filter(
      (_: any, index: number) => index !== imageIndex,
    );

    setUploads({ ...uploads, [toCamelCase(category)]: imageFilter });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full w-full flex-col "
    >
      <div>
        {categories &&
          categories.length &&
          categories.map((category: any, index) => {
            return (
              <div key={index} className="my-[20px] border-b-[1px] pb-[20px] ">
                <CustomCheckBox
                  fill="fill-flexi-black-2"
                  title={index === 0 ? 'Bay is taken' : category}
                  size={14}
                  isCapitalize={false}
                >
                  <input
                    {...register('categories')}
                    type="checkbox"
                    id={`categories[${index}]`}
                    name="categories"
                    className="checkbox checkbox-xs h-[25px] w-[25px] rounded-md"
                    value={category}
                    onChange={(e) => handleCheckboxSelected(category, e)}
                    style={{
                      // @ts-ignore
                      '--chkfg': '0',
                      '--chkbg': '0',
                      backgroundColor: 'white',
                    }}
                  />
                </CustomCheckBox>
                {category === 'Bay Is Taken' &&
                  selectedCategories &&
                  selectedCategories.includes('Bay Is Taken') ? (
                  <div className="my-5 flex w-full flex-col rounded-md bg-gray-100 p-[30px] text-[14px]">
                    <div className="pb-[20px]">
                      <p>
                        If you are not able to return the Flexicar to its
                        designated bay, please read and acknowledge that you
                        have followed the&nbsp;below.
                        <br />
                        <br />
                        You will then need to complete our parking&nbsp;form.
                      </p>
                    </div>

                    <div>
                      <p className="pb-[15px] text-[14px] font-semibold">
                        Is your Flexicar pod On-Street or Off-Street?
                      </p>
                      <div className="relative flex items-center">
                        <select
                          {...register('parkingType')}
                          id="parkingType"
                          name="parkingType"
                          className="h-14 w-full rounded-lg border border-solid border-gray-300 bg-white px-3 text-[14px] focus:outline-0"
                          defaultValue="On-Street Bay"
                          onChange={handleSelectChange}
                        >
                          {/* <option value="" disabled>
                            No item selected
                          </option> */}
                          <option value="On-Street Bay">On-Street Bay</option>
                          <option value="Off-Street Bay">Off-Street Bay</option>
                        </select>
                        <span className="pointer-events-none absolute  right-1 grid h-[90%] w-[40px] place-items-center rounded-lg bg-white">
                          <IconArrow className="min-h-[14px] min-w-[14px] fill-flexi-orange-1" />
                        </span>
                      </div>
                      {errors?.parkingType && (
                        <InvalidText>
                          {errors?.parkingType?.message}
                        </InvalidText>
                      )}
                    </div>

                    <div className="pt-[15px]">
                      {/* {parkingType ? (
                        <p>
                          Find an alternative bay within the same car park in an
                          unreserved&nbsp;bay.
                        </p>
                      ) : ( */}
                      <p>
                        Find an alternative on-street legal parking&nbsp;bay.
                      </p>
                      {/* )} */}
                    </div>

                    {/* {parkingType ? ( */}
                    <>
                      <p>
                        <br />A legal parking bay is a 1P, 2P etc without any
                        other restrictions at any day or time.
                        <br />
                        <br />
                        No Standing, No Stopping, No Parking, Permit zones,
                        Disabled zones and parks which become Clearways at any
                        time are NOT legal parks.
                        <br />
                        <br />
                        On-stree vehicles cannot be left in off-stree car
                        parks, other car share bays &#40;including our
                        own&#41; or metered bays.
                        <br />
                        <br />
                        Please park the Flexicar as close as possible to its
                        designated bay.
                      </p>

                      <div className="my-[30px] flex w-full flex-col">
                        <CustomCheckBox
                          fill="fill-flexi-black-2"
                          size={14}
                          titleElement={
                            <p>
                              I have read and understood the
                              above&nbsp;information.
                            </p>
                          }
                          isCapitalize={false}
                          titleClass="w-fit text-[12px]"
                        >
                          <input
                            {...register('isUnderstood')}
                            type="checkbox"
                            id={`isUnderstood`}
                            name={`isUnderstood`}
                            className="checkbox checkbox-xs min-h-[25px] min-w-[25px] rounded-md"
                            checked={isUnderstood}
                            onChange={(e) =>
                              setisUnderstood(e.target.checked)
                            }
                            style={{
                              // @ts-ignore
                              '--chkfg': '0',
                              '--chkbg': '0',
                              backgroundColor: 'white',
                            }}
                          />
                        </CustomCheckBox>
                        {/* <InvalidText>
                            {errors?.isUnderstood?.message}
                          </InvalidText> */}

                        {understoodError && (
                          <InvalidText>This field is required.</InvalidText>
                        )}
                      </div>
                    </>
                    {/* ) 
                    // : (
                    //   <div className="my-[30px] flex w-full flex-col">
                    //     <CustomCheckBox
                    //       title={
                    //         'I have parked the car as close as possible to its designated bay.'
                    //       }
                    //       size={10}
                    //       isCapitalize={false}
                    //       titleClass="w-fit text-[12px]"
                    //     >
                    //       <input
                    //         {...register('isParkingNearby', {})}
                    //         onChange={(e) =>
                    //           setisParkingNearby(e.target.checked)
                    //         }
                    //         type="checkbox"
                    //         id={`isParkingNearby`}
                    //         name={`isParkingNearby`}
                    //         className="checkbox checkbox-xs min-h-[25px] min-w-[25px] rounded-md"
                    //         style={{
                    //           // @ts-ignore
                    //           '--chkfg': '0',
                    //           '--chkbg': '0',
                    //           backgroundColor: 'white',
                    //         }}
                    //       />
                    //     </CustomCheckBox>
                    //     {/* <InvalidText>
                    //       {errors?.isParkingNearby?.message}
                    //     </InvalidText> */}
                    {/* //     {parkingNearbyError && ( */}
                    {/* //       <InvalidText>This field is required.</InvalidText>
                    //     )}
                    //   </div> */}
                    {/* // )
                    } */}
                    {(isParkingNearby || isUnderstood) && (
                      <>
                        <div>
                          <p className="pb-[10px] text-[16px] font-semibold">
                            Offending Vehicle
                          </p>
                          <p>
                            Please provide details of the offending vehicle in
                            your Flexicar bay. If you are unable to park your
                            Flexicar in its bay for other reasons &#40;eg.
                            Construction&#41; please provide the details in the
                            Additional information section.
                          </p>
                        </div>

                        <div className="flex w-full flex-col">
                          <CustomInput title="Registration Plate" margin={20}>
                            <input
                              placeholder="registration plane"
                              {...register('registrationPlate')}
                              type="text"
                              className={classNames(
                                'peer input input-bordered w-full pr-[50px]',
                              )}
                            />
                          </CustomInput>
                          <InvalidText>
                            {errors?.registrationPlate?.message}
                          </InvalidText>
                        </div>

                        <div className="flex w-full flex-col">
                          <CustomInput title="Make & Model" margin={20}>
                            <input
                              {...register('model')}
                              placeholder="model"
                              type={'text'}
                              className={classNames(
                                'peer input input-bordered w-full pr-[50px]',
                              )}
                            />
                          </CustomInput>
                          <InvalidText>{errors?.model?.message}</InvalidText>
                        </div>

                        <div className="flex w-full flex-col">
                          <CustomInput title="Color" margin={20}>
                            <input
                              {...register('color')}
                              placeholder="color"
                              type={'text'}
                              className={classNames(
                                'peer input input-bordered w-full pr-[50px]',
                              )}
                            />
                          </CustomInput>
                          <InvalidText>{errors?.color?.message}</InvalidText>
                        </div>

                        <div className="my-[20px] flex w-full flex-col">
                          <textarea
                            {...register('detailedDescription')}
                            placeholder="Detailed description of where you have parked your Flexicar"
                            // name="detailDescription"
                            // id="detailDescription"

                            // value={detailedDescription}
                            // onChange={(e)=>setDetailedDescription(e.target.value)}
                            className="flex h-[120px] w-full rounded-lg border border-solid border-gray-300 p-4 text-[14px] focus:outline-0"
                          />
                          <InvalidText>{errors?.detailedDescription?.message}</InvalidText>

                        </div>

                        {/* <div className="my-5 flex w-full flex-col">
                          <textarea
                            {...register('bayInfo')}
                            placeholder="Additional Information (optional)"
                            className="flex w-full rounded-lg border border-solid border-gray-300 p-4 text-[14px] focus:outline-0"
                            name="bayInfo"
                            id="bayInfo"
                            rows={5}
                          ></textarea>
                          <InvalidText>{errors?.bayInfo?.message}</InvalidText>
                        </div> */}

                        <div className="pb-[30px]">
                          <ImageUpload
                            {...{ uploads, setUploads, category: 'bayIsTaken' }}
                            textValue={textValue}
                            setTextValue={setTextValue}
                          />
                        </div>
                      </>
                    )}

                    <div className="flex w-full">
                      <p className="text-[10px]">
                        If the above information is not clear or you are
                        uncertain if you are parked legally, please call us on{' '}
                        <span className="text-flexi-orange-1">
                          1300 363 780
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {category !== 'Bay Is Taken' &&
                  selectedCategories.includes(category) && (
                    <section className="my-[20px] rounded-[10px] bg-[#F9F9F9] p-[30px]">
                      <ImageUpload
                        {...{ uploads, setUploads, category }}
                        textValue={textValue}
                        setTextValue={setTextValue}
                      />
                    </section>
                  )}
                {selectedCategories.includes(category) &&
                  uploads[toCamelCase(category)].length > 0 && (
                    <div className="mb-[10px] rounded-lg bg-white p-[15px] py-[5px] shadow-[0_2px_9px_0] shadow-[#000000]/5">
                      {uploads[toCamelCase(category)].map(
                        (upload: any, index: number) => {
                          return (
                            <div
                              className={classNames(
                                'flex h-fit max-h-[70px] w-full items-center justify-between py-[15px]',
                                {
                                  'border-b-[1px]':
                                    index !==
                                    uploads[toCamelCase(category)].length - 1,
                                },
                              )}
                              key={index}
                            >
                              <div>
                                <BsImage style={{ fontSize: '30px' }} />
                              </div>
                              <div className="flex w-[calc(100vw-200px)] flex-1 flex-col px-[10px]">
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-[14px] font-bold">
                                  {upload.name}
                                </p>
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-[10px]">
                                  {Math.round(
                                    ((upload.size || 0) / 1024) * 100,
                                  ) / 100}{' '}
                                  kb
                                </p>
                              </div>
                              <div>
                                <MdClose
                                  onClick={() =>
                                    handleImageRemove(index, category)
                                  }
                                />
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
              </div>
            );
          })}
      </div>
      <InvalidText>
        {reportErrors?.response?.data?.errors?.reasons[0] &&
          'Please select at least one item.'}
      </InvalidText>

      <div className="mt-5 flex w-full flex-1 flex-col-reverse self-end pb-[30px]">
        <button
          type="submit"
          className={classNames(
            'btn btn-primary btn-md min-h-[50px] w-full rounded-full capitalize',
            {
              'loading bg-flexi-green-2':
                reportStatus === RequestStatusEnum.PENDING,
            },
          )}
          disabled={false}
          onClick={submitButtonClick}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default OffboardingForm;
