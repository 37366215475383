import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

interface Props {
  children: ReactNode;
  maxWidth?: string;
}

const AppContent = ({ children, maxWidth = '1000px' }: Props) => {
  const { pathname } = useLocation();

  return (
    <section
      className={classNames(`m-auto w-full max-w-[${maxWidth}]`, {
        'max-w-[100vw] lg:max-w-[1000px] ': pathname === '/app/my-bookings',
      })}
    >
      {children}
    </section>
  );
};

export default AppContent;
