import React from "react";
import Lottie from 'react-lottie';
import animationData from 'assets/json/icon-key.json';

interface Props {
  page: number;
}

const Page2 = ( { page } : Props ) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="flex flex-col">
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isPaused={page !== 2}
      />
      <div>
        <div className="mt-30px flex h-[195px] flex-col gap-[15px]">
          <p className="font-primary text-center text-[28px] font-[900] uppercase leading-[29px] text-primary">
            Ready to start driving?
          </p>
          <p className="text-[14px] leading-[20px] text-black">
            Use the app to lock and unlock your Flexicar throughout the entire
            booking. If a physical car key is required, you will find it in the
            driver&apos;s side pocket.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Page2;