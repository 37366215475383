import React, { useState, useRef, useLayoutEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import CustomInput from 'components/CustomInput';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { isEmpty } from 'lodash';
import { selectPending } from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { getResetPasswordEmail } from 'common/helpers';
import { ReactComponent as IconPasswordShow } from 'assets/images/password_show.svg';
import { ReactComponent as IconPasswordHide } from 'assets/images/password_hide.svg';
import gsap from 'gsap';

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  password: string;
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('')
    .matches(/\d/, '')
    .matches(/[a-z]/, '')
    .matches(/[A-Z]/, '')
    .matches(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/, '')
    .min(8),
});

const ForgotPasswordConfirm = () => {
  const dispatch = useAppDispatch();
  const isPending = useAppSelector(selectPending);
  const [password, setPassword] = useState('');
  const email = getResetPasswordEmail();
  const [passVisible, setPassVisible] = useState(false);

  const component = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {  
      gsap.to(component.current, {
        alpha: 1,
        duration: 0.5
      });
  }, []);

  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    const payload = {
      ...values,
      email,
    };
    dispatch({ type: ACTIONS.RESET_PASSWORD, payload });
  };

  return (
    <section ref={component} className="fadein m-auto mt-[100px] flex w-full max-w-[80vw] md:max-w-[500px] flex-col place-items-center content-center items-center">
      <h2 className="font-primary mb-[20px] text-center text-[30px] md:text-[40px] font-[900] uppercase  text-primary">
        reset password
      </h2>
      <p className="mb-[50px] text-[16px] md:text-[18px] text-center">Enter your new password&nbsp;below.</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col place-items-center items-center"
      >
        {/* Password */}
        <div className="flex w-full gap-5">
          <div className="form-control mb-[70px] flex-1">
            <CustomInput
              title="New Password"
            >
              <input
                type={passVisible ? 'text' : 'password'}
                placeholder='new password'
                className={`peer input input-bordered w-full focus:outline-none pr-[50px]`}
                {...register('password')}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setPassVisible(!passVisible);
                }}
                className="pointer-events-auto cursor-pointer absolute right-1 top-[50%] grid h-[90%] w-[40px] translate-y-[-50%] place-items-center rounded-lg  bg-transparent"
              >
                {passVisible ? (
                  <IconPasswordShow className="scale-[1.7] fill-flexi-orange-1" />
                ) : (
                  <IconPasswordHide className="scale-[1.2] fill-flexi-black-6" />
                )}
              </span>
            </CustomInput>

            {!isEmpty(errors.password) && (
              <div className=" my-2 text-[12px] text-red-500">
                {password ? (
                  <ul className="relative ml-5 list-disc">
                    <li className={`${password.length >= 8 && 'hidden'} `}>
                      <span className="absolute left-[-5px]">
                        Must be at least 8 characters
                      </span>
                    </li>
                    <li className={`${password.match(/\d/) && 'hidden'}`}>
                      <span className="absolute left-[-5px]">
                        Must contain at least 1 number
                      </span>
                    </li>
                    <li
                      className={`${
                        password.match(/[a-z]/) &&
                        password.match(/[a-z]/) &&
                        password.match(
                          /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
                        ) &&
                        'hidden'
                      }`}
                    >
                      <span className="absolute left-[-5px]">
                        Must contain lowercase, uppercase, and a special&nbsp;character
                      </span>
                    </li>
                  </ul>
                ) : (
                  <p>This field is required</p>
                )}
              </div>
            )}
          </div>
        </div>
        <button
          className={classNames(
            'btn btn-primary max-h-[50px] md:max-h-[60px] w-full rounded-full border-none text-[16px] capitalize',
            {
              loading: isPending,
              'pointer-events-none bg-zinc-200': !password,
            },
          )}
        >
          reset password
        </button>
      </form>
    </section>
  );
};

export default ForgotPasswordConfirm;
