import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconStepper1 } from 'assets/images/signup-step-1.svg';
import { ReactComponent as IconStepper2 } from 'assets/images/signup-step-2.svg';
import { ReactComponent as IconStepper3 } from 'assets/images/signup-step-3.svg';
import { ReactComponent as IconStepper4 } from 'assets/images/signup-step-4.svg';
import { ReactComponent as IconStepper5 } from 'assets/images/signup-step-5.svg';
import { ReactComponent as IconStepper6 } from 'assets/images/signup-step-6.svg';
import { ReactComponent as IconStepper7 } from 'assets/images/signup-step-7.svg';
import useAppSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/me/slice';
import { useLocation } from 'react-router-dom';
import useBusiness from 'hooks/useBusiness';

const SignupNav = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const { isBusinessDriverAccount, isBusinessManagerAccount } = useBusiness();
  const [level, setLevel] = useState(0);

  const [stepper, setStepper] = useState<string[]>([
    'create an account',
    'email verification',
    'id verification',
    'your information',
    'plan selection',
    'payment',
    'done',
  ]);

  useEffect(() => {
    if (user?.redirectPreviousBusinessName) {
      setStepper([
        'create an account',
        'update email',
        'email verification',
        'plan selection',
        'payment',
        'done',
      ]);
      setLevel(
        pathname === '/register/create-account'
          ? 0
          : pathname === '/register/update-email'
          ? 1
          : pathname === '/register/otp'
          ? 2
          : pathname === '/register/plan'
          ? 3
          : pathname === '/register/payment'
          ? 4
          : 5,
      );
    } else if (isBusinessManagerAccount) {
      setStepper([
        'create an account',
        'mobile verification',
        'plan details',
        'payment',
        'done',
      ]);
      setLevel(
        pathname === '/register/create-account' ||
          pathname === '/register-business'
          ? 0
          : pathname === '/register/otp' ||
            pathname === '/register-business/otp'
          ? 1
          : pathname === '/register/plan' ||
            pathname === '/register-business/plan'
          ? 2
          : pathname === '/register/payment' ||
            pathname === '/register-business/payment'
          ? 3
          : 4,
      );
    } else if (isBusinessDriverAccount) {
      setStepper([
        'create an account',
        'email verification',
        'id verification',
        'your information',
        'done',
      ]);

      setLevel(
        pathname === '/register/create-account'
          ? 0
          : pathname === '/register/otp'
          ? 1
          : ((pathname === '/register/verification') || (pathname === '/register/declined'))
          ? 2
          : pathname === '/register/information'
          ? 3
          : 4,
      );
    } else {
      setLevel(
        pathname === '/register/create-account' ||
        pathname === '/register-v2'
          ? 0
          : pathname === '/register/otp' ||
          pathname === '/register-v2/otp'
          ? 1
          : pathname === '/register/verification' ||
          pathname === '/register/declined' ||
          pathname === '/register-v2/verification'
          ? 2
          : pathname === '/register/information' ||
          pathname === '/register-v2/ocr' ||
          pathname === '/register-v2/verify-failed'
          ? 3
          : pathname === '/register/plan'||
          pathname === '/register-v2/plan' ||
          pathname === '/register-v2/plan-personal' ||
          pathname === '/register-v2/plan-student'
          ? 4
          : pathname === '/register/payment' ||
          pathname === '/register-v2/payment'
          ? 5
          : 6,
      );
    }
  }, [user, location]);

  return (
    <div>
      <div className="fixed left-0 z-[10] flex w-screen gap-1 lg:hidden">
        {[...new Array(stepper.length - 1)].map((_, i) => {
          return (
            <div
              key={i}
              className={classNames('h-[5px] w-full', {
                'bg-flexi-orange-1': level >= i,
                'bg-flexi-black-6 ': level < i || pathname === '/register-v2',
              })}
            ></div>
          );
        })}
      </div>
      <aside className="sticky top-[100px] left-0 z-[10] hidden h-[calc(100vh-100px)] w-[275px] lg:block ">
        <ul className="flex flex-col gap-5 fill-flexi-black-6 py-[100px] text-[16px] font-bold uppercase text-flexi-black-5">
          {stepper.map((step, i, arr) => {
            return (
              <li
                key={i}
                className={classNames(
                  'relative flex items-center  gap-3 py-5 text-zinc-200',
                  {
                    'fill-flexi-black-1 !text-flexi-black-1': level > i,
                    'fill-flexi-orange-1 !text-flexi-orange-1': level === i,
                    'pt-0': i === 0,
                  },
                )}
              >
                {i === 0 ? (
                  <IconStepper1 />
                ) : i === 1 ? (
                  <IconStepper2 />
                ) : i === 2 ? (
                  <IconStepper3 />
                ) : i === 3 ? (
                  <IconStepper4 />
                ) : i === 4 ? (
                  <IconStepper5 />
                ) : i === 5 ? (
                  <IconStepper6 />
                ) : (
                  <IconStepper7 />
                )}
                <span>{step}</span>
                <span
                  className={classNames(
                    'absolute left-[10px] bottom-[-35px] h-[50px] w-[2px] bg-zinc-200',
                    {
                      '!bg-flexi-black-3': level > i,
                      hidden: arr.length - 1 === i,
                    },
                  )}
                />
              </li>
            );
          })}
        </ul>
      </aside>
    </div>
  );
};

export default SignupNav;
