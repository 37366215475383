import { selectUser } from 'modules/me/slice';
import api, { authenticatedClient } from 'app/apiClient';
import axios from 'axios';
import { handleAPIError } from 'common/errorHandler';
import { call, put, select, cancelled } from 'redux-saga/effects';
import {
  setPending,
  setPendingVehicleAvailable,
  setVehicleListHourly,
  setVehicleListDaily,
  setNearestVehicles,
  setVehicleSummary,
  setPendingVehicleFilters,
  setVehicleFilterList,
  setVehicleAvailable,
  setVehicleSummaryStatus,
  setVehicleAvailabilityPending,
  setVehicleAvailabilityStatus,
  setVehicleAvailability,
  setVehicleRatesPending,
  setVehicleRatesStatus,
  setVehicleRates,
  setVehicleCalculatedRatesPending,
  setVehicleCalculatedRatesStatus,
  setVehicleCategoryRates,
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from 'common/constants';
import { RequestStatusEnum } from 'common/types';

export const SAGA_ACTIONS = {
  FETCH_VEHICLE_LIST: 'FETCH_VEHICLE_LIST',
  FETCH_VEHICLE_LIST_V2: 'FETCH_VEHICLE_LIST_V2',
  FETCH_PUBLIC_VEHICLE_LIST: 'FETCH_PUBLIC_VEHICLE_LIST',
  FETCH_NEAREST_VEHICLES: 'FETCH_NEAREST_VEHICLES',
  FETCH_VEHICLE_SUMMARY: 'FETCH_VEHICLE_SUMMARY',
  FETCH_VEHICLE_FILTERS: 'FETCH_VEHICLE_FILTERS',
  FETCH_VEHICLE_FILTERS_AUTH: 'FETCH_VEHICLE_FILTERS_AUTH',
  FETCH_VEHICLE_AVAILABLE: 'FETCH_VEHICLE_AVAILABLE',
  UPDATE_VEHICLE_SUMMARY: 'UPDATE_VEHICLE_SUMMARY',
  UPDATE_VEHICLE_SUMMARY_NOLOAD: 'UPDATE_VEHICLE_SUMMARY_NOLOAD',
  GET_VEHICLE_AVAILABILITY: 'GET_VEHICLE_AVAILABILITY',
  GET_VEHICLE_CATEGORY_RATES: 'GET_VEHICLE_CATEGORY_RATES',
  GET_VEHICLE_RATES: 'GET_VEHICLE_RATES',
  GET_VEHICLE_CALCULATED_RATES: 'GET_VEHICLE_CALCULATED_RATES',
};

export function* fetchVehicleList() {
  try {
    yield put(setPending(true));
    const { type } = yield select(selectUser);

    const { data: hourlyData } = yield call(() =>
      api.get(`/member/types/${type.id}/vehicles?mode=hourly`),
    );
    const { data: dailyData } = yield call(() =>
      api.get(`/member/types/${type.id}/vehicles?mode=daily`),
    );

    yield put(setVehicleListHourly(hourlyData));
    yield put(setVehicleListDaily(dailyData));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

type FetchVehicleListV2Payload = {
  isSenior: number;
};

export function* fetchVehicleListV2(
  action: PayloadAction<FetchVehicleListV2Payload>,
) {
  try {
    yield put(setPending(true));
    const { type } = yield select(selectUser);

    const { data: hourlyData } = yield call(() =>
      api.get(
        `/member/types/${type.id}/vehiclesv2?mode=hourly&is_senior=${action.payload.isSenior || 0}`,
      ),
    );
    const { data: dailyData } = yield call(() =>
      api.get(
        `/member/types/${type.id}/vehiclesv2?mode=daily&is_senior=${action.payload.isSenior || 0}`,
      ),
    );

    yield put(setVehicleListHourly(hourlyData));
    yield put(setVehicleListDaily(dailyData));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

type VehicleAvailabilityPayload = {
  vehicleId: number;
  startsAt: string;
};

export function* fetchVehicleAvailable(
  action: PayloadAction<VehicleAvailabilityPayload>,
) {
  const api = authenticatedClient();
  try {
    yield put(setPendingVehicleAvailable(true));
    const { data } = yield call(() =>
      api.post(`/vehicles/${action.payload.vehicleId}/availability`, {
        startsAt: action.payload.startsAt,
      }),
    );
    yield put(setVehicleAvailable(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingVehicleAvailable(false));
  }
}

type PlanIdPayload = {
  id: number;
};

export function* fetchPublicVehicleList(action: PayloadAction<PlanIdPayload>) {
  try {
    yield put(setPending(true));
    const { data: hourlyData } = yield call(() =>
      api.get(`/member/types/${action.payload.id}/vehicles?mode=hourly`),
    );
    const { data: dailyData } = yield call(() =>
      api.get(`/member/types/${action.payload.id}/vehicles?mode=daily`),
    );
    yield put(setVehicleListHourly(hourlyData));
    yield put(setVehicleListDaily(dailyData));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

type FetchNearestVehiclesPayload = {
  latitude: number;
  longitude: number;
  startsAt?: string;
  endsAt?: string;
};

export function* fetchNearestVehicles(
  action: PayloadAction<FetchNearestVehiclesPayload>,
) {
  const api = authenticatedClient();
  const { latitude, longitude, startsAt, endsAt } = action.payload;
  try {
    yield put(setPending(true));
    const { data } = yield call(() =>
      api.get(
        `${API_URL}/vehicles/nearest?latitude=${latitude}&longitude=${longitude}&starts_at=${startsAt}&ends_at=${endsAt}`,
      ),
    );
    yield put(setNearestVehicles(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPending(false));
  }
}

type FetchVehicleSummaryPayload = {
  vehicleId: number;
  startsAt: string;
  endsAt: string;
  addons?: string;
  promo?: string;
};

export function* fetchVehicleSummary(
  action: PayloadAction<FetchVehicleSummaryPayload>,
) {
  const api = authenticatedClient();
  const { vehicleId, startsAt, endsAt, addons, promo } = action.payload;
  try {
    yield put(setVehicleSummaryStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(
        `${API_URL}/vehicles/${vehicleId}/summary?starts_at=${startsAt}&ends_at=${endsAt}&addons=${addons}&promo=${promo}`,
      ),
    );
    yield put(setVehicleSummary(data));
    yield put(setVehicleSummaryStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setVehicleSummaryStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setPending(false));
  }
}

type UpdateVehicleSummaryPayload = {
  vehicleId: number;
  startsAt: string;
  endsAt: string;
  addons?: string;
  promo?: string;
};

export function* updateVehicleSummary(
  action: PayloadAction<UpdateVehicleSummaryPayload>,
) {
  const api = authenticatedClient();
  const { vehicleId, startsAt, endsAt, addons, promo } = action.payload;
  try {
    yield put(setVehicleSummaryStatus(RequestStatusEnum.PENDING));
    const { data } = yield call(() =>
      api.get(
        `${API_URL}/vehicles/${vehicleId}/summary?starts_at=${startsAt}&ends_at=${endsAt}&addons=${addons}&promo=${promo}`,
      ),
    );
    yield put(setVehicleSummaryStatus(RequestStatusEnum.SUCCESS));
    yield put(setVehicleSummary(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      yield put(setVehicleSummaryStatus(RequestStatusEnum.FAILED));
      handleAPIError(err.response);
    }
  }
}
export function* updateVehicleSummaryNoload(
  action: PayloadAction<UpdateVehicleSummaryPayload>,
) {
  const api = authenticatedClient();
  const { vehicleId, startsAt, endsAt, addons, promo } = action.payload;
  try {
    const { data } = yield call(() =>
      api.get(
        `${API_URL}/vehicles/${vehicleId}/summary?starts_at=${startsAt}&ends_at=${endsAt}&addons=${addons}&promo=${promo}`,
      ),
    );
    yield put(setVehicleSummary(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  }
}

export function* fetchVehicleFilters() {
  const api = authenticatedClient();
  try {
    yield put(setPendingVehicleFilters(true));
    const { data } = yield call(() => api.get(`/vehicles/filters`));
    yield put(setVehicleFilterList(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingVehicleFilters(false));
  }
}

export function* fetchVehicleFiltersAuth(): any {
  const api = authenticatedClient();
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(setPendingVehicleFilters(true));
    const { data } = yield call(() =>
      api.get(`/vehicles/filters/auth`, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setVehicleFilterList(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    yield put(setPendingVehicleFilters(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

type GetVehicleAvailabilityPayload = {
  startsAt?: string;
  endsAt?: string;
  vehicles: number[];
};

export function* getVehicleAvailability(
  action: PayloadAction<GetVehicleAvailabilityPayload>,
): any {
  const { startsAt, endsAt, vehicles } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    vehicles,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setVehicleAvailabilityPending(true));
    yield put(setVehicleAvailabilityStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/pods/v2/vehicle-availability`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setVehicleAvailabilityPending(true));
    yield put(setVehicleAvailabilityStatus(RequestStatusEnum.SUCCESS));

    yield put(setVehicleAvailability(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setVehicleAvailabilityStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setVehicleAvailabilityPending(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

// one time call rates
type GetVehicleCategoryRatesPayload = {
  planId: number;
};

export function* getVehicleCategoryRates(
  action: PayloadAction<GetVehicleCategoryRatesPayload>,
): any {
  const { planId } = action.payload;

  const payload = {
    planId,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    const { data } = yield call(() =>
      api.post(`/pods/v2/vehicle-rates-by-plan`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setVehicleCategoryRates(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
    }
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

// rates computation at FE
type GetVehicleRatesPayload = {
  vehicles: number[];
  planId: number;
};

export function* getVehicleRates(
  action: PayloadAction<GetVehicleRatesPayload>,
): any {
  const { vehicles, planId } = action.payload;

  const payload = {
    vehicles,
    planId,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setVehicleRatesPending(true));
    yield put(setVehicleRatesStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/pods/v2/vehicle-rates`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setVehicleRates(data));
    yield put(setVehicleRatesPending(true));
    yield put(setVehicleRatesStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setVehicleRatesStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setVehicleRatesPending(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

// rates computation at BE
type GetCalculatedVehicleRatesPayload = {
  startsAt?: string;
  endsAt?: string;
  vehicles: number[];
};

export function* getVehicleCalculatedRates(
  action: PayloadAction<GetCalculatedVehicleRatesPayload>,
): any {
  const { startsAt, endsAt, vehicles } = action.payload;
  const payload = {
    startsAt,
    endsAt,
    vehicles,
  };
  const cancelTokenSource = axios.CancelToken.source();

  try {
    yield put(setVehicleCalculatedRatesPending(true));
    yield put(setVehicleCalculatedRatesStatus(RequestStatusEnum.PENDING));

    const { data } = yield call(() =>
      api.post(`/pods/v2/vehicle-calculated-rates`, payload, {
        cancelToken: cancelTokenSource.token,
      }),
    );
    yield put(setVehicleCalculatedRatesPending(data));
    yield put(setVehicleCalculatedRatesPending(true));
    yield put(setVehicleCalculatedRatesStatus(RequestStatusEnum.SUCCESS));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      handleAPIError(err.response);
      yield put(setVehicleCalculatedRatesStatus(RequestStatusEnum.FAILED));
    }
  } finally {
    yield put(setVehicleCalculatedRatesPending(false));
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}
