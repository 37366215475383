import SignupNav from 'components/SignupNav';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
}
const RegisterLayout = ({ children }: Props) => {
  const { pathname } = useLocation();

  return (
    <section
      className={classNames("relative mt-[-70px] lg:mt-0 m-auto lg:flex w-full min-h-[100vh] max-w-[1000px] lg:px-[20px] bg-[#F2F2F2] lg:bg-white",
      {
        '!bg-white': pathname.includes('register-business')
      }
    )}>
      <div className=''>
        <SignupNav />
      </div>
      <div className="min-h-[calc(100vh-200px)] flex flex-auto items-start justify-center lg:justify-start pb-[25px] md:pb-[100px] pt-[45px] md:pt-[100px] mx-[25px] lg:mx-0"> 
        {children}
      </div>
    </section>
  );
};

export default RegisterLayout;
