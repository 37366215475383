import React from 'react';
import { Link } from 'react-router-dom';

function AddPaymentMethodDeclined() {
  return (
    <article className="relative mt-[50px] mb-[100px] flex flex-col items-center gap-4 self-center px-[100px] md:mx-0 md:mt-[100px]">
      <h2 className="font-primary mb-[30px] text-center text-[20px] font-[900] uppercase text-flexi-orange-1 md:mb-[40px] md:text-[40px]">
        sorry. your account has been denied.
      </h2>

      {/* <p className="max-w-[850px] text-center">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam
        voluptas dolorem quae ipsa molestias voluptatum sint dicta quo excepturi
        accusantium assumenda dolorum animi deserunt temporibus exercitationem
        ab vitae iste cum repudiandae incidunt, quibusdam possimus at&nbsp;placeat!
        <br />
        <br />
        Explicabo eum corporis, ullam impedit molestiae aliquid. Sunt suscipit
        quo hic minus at amet dolor cumque ad, nisi nostrum rem aliquid ut quis
        fuga eius porro. Excepturi veritatis cumque, cupiditate nemo facilis
        veniam&nbsp;vel.
      </p> */}
      <Link to="/app/account/faqs" className='btn rounded-full bg-flexi-green-1 border-none h-[60px] w-full max-w-[850px] mt-[50px] capitalize hover:bg-flexi-green-2'>
        See our FAQ
      </Link>
    </article>
  );
}

export default AddPaymentMethodDeclined;
