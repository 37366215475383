import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useClickAway, useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import {
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { isEmpty } from 'lodash';
import { addYears } from 'date-fns';
import { FcCheckmark } from 'react-icons/fc';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';
import Calendar from 'react-calendar';
import classNames from 'classnames';
import moment from 'moment';
import * as Yup from 'yup';
import gsap from 'gsap';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-right.svg';
import { ReactComponent as IconArrow } from 'assets/images/arrow-down.svg';
import { ReactComponent as IconCalendar } from 'assets/images/calendar.svg';
import { ICampus, RequestStatusEnum } from 'common/types';
import { InvalidText } from 'components/Typography';
import {
  selectEmailVerified,
  selectError,
  selectPendingVerifyEmail,
  selectPlanType,
  selectRegisterStatus,
  selectStudentEmailUniversityId,
  selectUniversities,
  setEmailVerified,
  setError,
  setStudentEmailUniversityId,
} from 'modules/auth/slice';
import ACTIONS from 'modules/rootActions';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import CustomInput from 'components/CustomInputSignUp';

type FormOptionsMode = 'onBlur' | 'onChange' | 'onSubmit';

type FormValues = {
  campus: string;
  courseEndDate: string;
  universityEmail: string;
  universityId: number;
};

const validationSchema = Yup.object().shape({
  universityEmail: Yup.string()
  .required('Email is required')
    .email('Invalid email format'),
});

const SignUpPlanStudent = () => {
  const dispatch = useAppDispatch();
  const registerStatus = useAppSelector(selectRegisterStatus);
  const isPendingVerifyEmail = useAppSelector(selectPendingVerifyEmail);
  const isEmailVerified = useAppSelector(selectEmailVerified);
  const selectedUniversityId =
    useAppSelector(selectStudentEmailUniversityId) || 1;
  const universities = useAppSelector(selectUniversities);
  const planType = useAppSelector(selectPlanType);
  const error = useAppSelector(selectError);
  const [renderedUniversity, setRenderedUniversity] = useState('');
  const [campuses, setCampuses] = useState<ICampus[] | []>([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);
  const component = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef(null);
  const { push } = useHistory();

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const formOptions = {
    mode: 'onBlur' as FormOptionsMode,
    reValidateMode: 'onChange' as FormOptionsMode,
    resolver: yupResolver(validationSchema),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<FormValues>(formOptions);

  const isErrorEmail =
    errors?.universityEmail || error?.email || error === 'email_invalid';

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    if (registerStatus !== RequestStatusEnum.PENDING) {
      const payload = {
        ...values,
        typeId: planType?.id,
        universityId: selectedUniversityId,
        courseEndDate: selectedDate,
      };
      dispatch({ type: ACTIONS.REGISTER_PLAN_TYPE_SELECTED_V2, payload });
    }
  };

  useEffectOnce(() => {
    dispatch({ type: ACTIONS.GET_UNIVERSITIES_V2 });
    dispatch(setError(null));
    dispatch(setEmailVerified(false));
    dispatch(setStudentEmailUniversityId(1));
  });

  useEffect(() => {
    if (selectedUniversityId) {
      const university = universities?.find(
        (university) => university.id === selectedUniversityId,
      );
      if (university?.name) setRenderedUniversity(university?.name);
      if (university?.campuses) setCampuses(university?.campuses);
    }
  }, [selectedUniversityId]);

  useEffect(() => {
    dispatch({ type: ACTIONS.GET_PLANS_V2 });
  }, [calendarOpen, selectedDate, campuses, selectedUniversityId]);

  useClickAway(dropdownRef, () => {
    setCalendarOpen(false);
  });

  useLayoutEffect(() => {
    gsap.to(component.current, {
      alpha: 1,
      duration: 0.5,
    });
  }, []);

  return (
    <section
      ref={component}
      className="fadein relative flex min-h-[calc(100vh-90px)] w-full max-w-[600px] flex-col pt-[43px] md:min-h-[calc(100vh-388px)]"
    >
      <button
        className="absolute flex h-[25px] w-[25px] items-center justify-center rounded-full bg-flexi-green-1"
        onClick={() => {
          push('/register-v2/plan');
        }}
      >
        <ArrowIcon className="rotate-180 fill-white" />
      </button>
      {isMobile ? (
        <h2 className="font-primary mb-[24px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:text-left md:text-[40px]">
          Choose the plan
          <br />
          that&apos;s right for you
        </h2>
      ) : (
        <h2 className="font-primary mb-[30px] text-center text-[20px] font-[900] uppercase leading-[26px] text-flexi-orange-1 md:text-[28px]">
          School details
        </h2>
      )}
      <p className="mb-[32px] text-center text-[16px] md:mb-[36px] md:text-[16px]">
        To access this plan, you may need to show proof of study
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-[8px] flex min-h-[calc(100vh-556px)] grow flex-col gap-y-[4px] md:grow-0"
      >
        <div className="flex w-full flex-1 flex-col items-center">
          <CustomInput title={'University Email'} error={isErrorEmail}>
            <input
              id={'University Email'}
              type="text"
              placeholder="email"
              className={classNames(
                'peer input input-bordered w-full pr-[50px] pt-[10px] !text-[14px]',
                {
                  'border-red-500': isErrorEmail,
                },
              )}
              {...register('universityEmail', {
                onBlur: async (e) => {
                  const result = await trigger('universityEmail');
                  if (result) {
                    dispatch({
                      type: ACTIONS.VERIFY_EMAIL_V2,
                      payload: { email: e.target.value, typeId: planType?.id },
                    });
                  }
                },
              })}
            />
            <div className="absolute right-[15px] top-[50%] grid  translate-y-[-50%] place-items-center">
              {isPendingVerifyEmail && (
                <span className="h-5 w-5 animate-spin rounded-full border-[2px] border-zinc-300 border-t-transparent" />
              )}
              {isEmailVerified && <FcCheckmark />}
            </div>
          </CustomInput>
          {/* </div> */}
          {!isEmpty(errors.universityEmail) && (
            <InvalidText>{errors.universityEmail?.message}</InvalidText>
          )}
          {error === 'email_invalid' && (
            <InvalidText>Invalid school email</InvalidText>
          )}
          {error?.email && <InvalidText>{error?.email}</InvalidText>}

          <CustomInput
            margin={20}
            title="University"
            error={errors?.universityId}
            className="relative"
          >
            <input
              className={classNames(
                'peer input input-bordered w-full pt-[10px] text-[14px]',
                {
                  'border-red-500': errors?.universityId,
                },
              )}
              value={renderedUniversity}
              onChange={(e) => setRenderedUniversity(e.target.value)}
              disabled={selectedUniversityId !== 1}
            />
          </CustomInput>
          {!isEmpty(errors.universityId) && (
            <InvalidText>{errors.universityId?.message}</InvalidText>
          )}

          <CustomInput
            margin={20}
            title="Campus"
            error={errors?.campus}
            className="relative"
          >
            <IconArrow
              className={classNames(
                'absolute right-[15px] h-[14px] w-[14px] fill-flexi-orange-1',
                {
                  hidden: campuses.length === 0,
                },
              )}
            />
            <input
              className={classNames(
                'peer input input-bordered w-full pt-[10px] text-[14px]',
                {
                  'border-red-500': errors?.campus,
                  hidden: campuses.length > 0,
                },
              )}
              {...register('campus')}
            />
            <select
              className={classNames(
                'peer input input-bordered w-full pt-[10px] !text-[14px]',
                {
                  'border-red-500': errors?.campus,
                  hidden: campuses.length === 0,
                },
              )}
              {...register('campus')}
              defaultValue={''}
              disabled={campuses.length === 0}
            >
              <option value={0} disabled className="hidden">
                Select Campus
              </option>
              {campuses.map((campus) => {
                return (
                  <option key={campus.id} value={campus.name}>
                    {campus.name}
                  </option>
                );
              })}
            </select>
          </CustomInput>
          {!isEmpty(errors.campus) && (
            <InvalidText>{errors.campus?.message}</InvalidText>
          )}

          <CustomInput
            margin={20}
            title="Course End Date"
            error={errors?.courseEndDate}
            className="relative"
          >
            <IconCalendar className="pointer-events-none absolute right-[15px] h-[14px] w-[14px] fill-flexi-orange-1" />
            <input
              type="text"
              value={selectedDate}
              className={classNames(
                'peer input input-bordered w-full pr-[50px] pt-[10px] !text-[14px]',
                {
                  'border-red-500': errors?.courseEndDate,
                },
              )}
              contentEditable={false}
              onClick={() => {
                setCalendarOpen(true);
              }}
            />

            {calendarOpen && (
              <div
                className="registration__calendar absolute bottom-[60px] z-[999] w-[calc(100vw-50px)] max-w-[350px] rounded-[10px] border-[1px] border-zinc-300 bg-white p-[15px] !text-center !text-[12px] shadow-md md:w-[300px]"
                ref={dropdownRef}
              >
                <Calendar
                  className={classNames('mx-auto border-0')}
                  minDate={new Date()}
                  maxDate={addYears(new Date(), 3)}
                  prev2Label={null}
                  next2Label={null}
                  prevLabel={
                    <IconArrow className="mr-[15px] rotate-90 fill-flexi-orange-1" />
                  }
                  nextLabel={
                    <IconArrow className="ml-[15px] -rotate-90 fill-flexi-orange-1" />
                  }
                  tileClassName={'my-[10px] disabled:text-[#aeaeae]'}
                  onChange={(value) => {
                    const obj = JSON.parse(JSON.stringify(value));
                    setSelectedDate(moment(obj).format('D MMMM YYYY'));
                    setCalendarOpen(false);
                  }}
                  returnValue={'start'}
                />
              </div>
            )}
          </CustomInput>
          {!isEmpty(errors.courseEndDate) && (
            <InvalidText>{errors.courseEndDate?.message}</InvalidText>
          )}
        </div>

        <div className="mt-auto pt-[40px]">
          <button
            type="submit"
            className={classNames(
              'btn btn-primary h-[50px] w-full rounded-full text-[16px] capitalize disabled:text-white md:h-[60px]',
              {
                'loading bg-flexi-green-2':
                  registerStatus === RequestStatusEnum.PENDING,
              },
            )}
            disabled={isPendingVerifyEmail || !selectedDate || !isEmpty(errors)}
          >
            Continue
          </button>
        </div>
      </form>
    </section>
  );
};

export default SignUpPlanStudent;
