import React from "react";
import Lottie from 'react-lottie';
import animationData from 'assets/json/icon-welcome-to-flexi.json';

interface Props {
  page: number;
}

const Page1 = ( { page } : Props ) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="flex flex-col">
      <Lottie 
        options={defaultOptions}
        height={200}
        width={200}
        isPaused={page !== 1}
      />
      <div>
        <div className="mt-30px flex flex-col gap-[15px] h-[195px]">
          <p className="text-center font-primary text-[28px] font-[900] uppercase text-primary leading-[29px]">
            Welcome to flexicar
          </p>
          <p className="text-[14px] leading-[20px] text-black">
            We have vehicles to fit every occassion. From a small hatch to zip around town, 8 seaters to fit the whole crew to vans perfect for moving day.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Page1;