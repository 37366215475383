import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import gsap from 'gsap';

import { ReactComponent as IconPersonalDetails } from 'assets/images/dashboard_personal_details.svg';
import { ReactComponent as IconDriverDetails } from 'assets/images/dashboard_driver_details.svg';
import { ReactComponent as IconPaymentMethod } from 'assets/images/dashboard_payment_method.svg';
import { ReactComponent as IconPlanDetails } from 'assets/images/dashboard_plan_details.svg';
import { ReactComponent as IconSecurity } from 'assets/images/dashboard_security.svg';
import { ReactComponent as IconInvoices } from 'assets/images/dashboard_invoices.svg';
import { ReactComponent as IconRewards } from 'assets/images/dashboard_rewards.svg';
import { ReactComponent as IconSupport } from 'assets/images/dashboard_support.svg';
import { ReactComponent as IconSettings } from 'assets/images/settings.svg';
import { ReactComponent as IconDrivers } from 'assets/images/drivers.svg';
import { PageTitle } from 'components/Typography';
import { getIsCourseDueNotified } from 'common/helpers';
import { selectPending, selectUser } from 'modules/me/slice';
import useAppSelector from 'hooks/useAppSelector';
import useBusiness from 'hooks/useBusiness';
import PendingLoader from 'components/PendingLoader';
import PaymentMethodMessageModal from 'components/PaymentMethodMessageModal';
import LicenseExpiredNotificationModal from 'components/LicenseExpiredNotificationModal';
import License2YNotificationModal from 'components/License2YNotificationModal';
import HasUnpaidModal from 'components/HasUnpaidModal';
import CourseEndDateModal from 'components/CourseEndDateModal';

function MyAccountNavigator() {
  const {
    isBusinessAccount,
    isBusinessManagerAccount,
    isBusinessDriverAccount,
  } = useBusiness();
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [licenseExpiredModalOpen, setLicenseExpiredModalOpen] =
    useState(false);
  const [license2YModalOpen, setLicense2YModalOpen] = useState(false);
  const [isCourseReminderModalOpen, setIsCourseReminderModalOpen] =
    useState(false);
  
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });
  const user = useAppSelector(selectUser);
  const isPendingUser = useAppSelector(selectPending);
  const [isHasUnpaidModalOpen, setHasUnpaidModalOpen] = useState(false);
  
  const component = useRef<any | null>(null);

  useEffect(() => {
    if (user && user.hasUnpaidInvoice) {
      setHasUnpaidModalOpen(true);
    }
  }, [user]);

  useLayoutEffect(() => {
    component.current = gsap.timeline().to('.fadein_main', {
      alpha: 1,
      duration: 0.5,
    });

    if (!isPendingUser) {
      const floatupchildren = document.querySelectorAll('.floatup');

      if (floatupchildren.length > 0) {
        component.current = gsap.timeline().to(floatupchildren, {
          y: 0,
          alpha: 1,
          duration: 0.2,
          stagger: 0.05,
          ease: 'circ.out',
        });
      }
    }
  }, [isPendingUser, user]);

  useEffectOnce(() => {
    if (
      (getIsCourseDueNotified() !== 'notified' &&
        (user?.course?.isCourseEndDateExpiringInAWeek ||
          user?.course?.isCourseEndDateExpired)) ||
      (user?.plan?.isStudentSwitchedToPersonal && !user.plan.isAccepted) ||
      user?.course?.isNullCourseEndDate
    ) {
      setIsCourseReminderModalOpen(true);
    } else if (user?.twoYearReverificationOverdue) {
      setLicense2YModalOpen(true);
    } else if (user?.isLicenseExpired) {
      setLicenseExpiredModalOpen(true);
    } else if (
      user?.status.toLocaleLowerCase() === 'pending' &&
      user?.statusRemarks?.some(
        (remark) =>
          remark.toLocaleLowerCase().includes('card_name_mismatch') ||
          remark.toLocaleLowerCase().includes('no card on file') ||
          remark.toLocaleLowerCase().includes('cc_same_name'),
      )
    ) {
      setPaymentMethodModalOpen(true);
    } else if (
      user?.status.toLocaleLowerCase() === 'pending' &&
      user?.statusRemarks?.some(
        (remark) =>
          remark.toLocaleLowerCase().includes('card_name_mismatch') ||
          remark.toLocaleLowerCase().includes('no card on file') ||
          remark.toLocaleLowerCase().includes('cc_same_name'),
      )
    ) {
      setPaymentMethodModalOpen(true);
    }
  })

  return (
    <div
      ref={component}
      className="fadein_main py-[40px] px-[25px] md:py-[100px] lg:px-0"
    >
      <Helmet>
        <title>My Account</title>
        <meta name="description" content="My Flexicar Account" />
      </Helmet>

      <PageTitle>
        My {isBusinessManagerAccount ? 'business' : 'account'}
      </PageTitle>

      <p className="mt-[20px] pb-[25px] text-[16px] md:pb-[50px] md:text-[18px]">
        An all in one place where you can manage your account and get help with
        your bookings or app related concerns.
      </p>
      {isPendingUser ? (
        <PendingLoader />
      ) : (
        <div className="grid w-[84vw] grid-cols-1 flex-wrap gap-[20px] overflow-visible pb-2 sm:grid-cols-2 md:grid-cols-3 lg:w-[1000px] [&>a:hover]:border-flexi-orange-1 [&>a]:border-[1px] [&>a]:border-transparent ">
          <NavLink
            to={`/app/account/${
              isBusinessManagerAccount ? 'account-details' : 'personal-details'
            }`}
            className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
          >
            <div className="my-auto pr-[3px] md:my-0 md:pr-0">
              <IconPersonalDetails />
            </div>
            <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
              <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                {isBusinessAccount ? 'account' : 'personal'} details
              </p>
              <p className="text-[14px] md:text-[14px]">
                Review your {isBusinessManagerAccount ? 'business' : 'personal'}{' '}
                information.
              </p>
            </div>
          </NavLink>
          {isBusinessManagerAccount ? (
            <NavLink
              to={`/app/account/drivers`}
              className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
            >
              <div className="my-auto pr-[3px] md:my-0 md:pr-0">
                <IconDrivers />
              </div>
              <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
                <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                  Drivers
                </p>
                <p className="text-[14px] md:text-[14px]">
                  View or modify the list of drivers.
                </p>
              </div>
            </NavLink>
          ) : (
            <>
              <NavLink
                to={`/app/account/driver-details`}
                className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
              >
                <div className="my-auto pr-[3px] md:my-0 md:pr-0">
                  <IconDriverDetails />
                </div>
                <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
                  <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                    driver&apos;s details
                  </p>
                  <p className="text-[14px] md:text-[14px]">
                    View or modify your driver&apos;s license information.
                  </p>
                </div>
              </NavLink>

              <NavLink
                to={`/app/account/loyalty-points`}
                className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
              >
                <div className="my-auto pr-[3px] md:my-0 md:pr-0">
                  <IconRewards />
                </div>
                <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
                  <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                    loyalty points &amp; rewards
                  </p>
                  <p className="text-[14px] md:text-[14px]">
                    Check or change your rewards partner.
                  </p>
                </div>
              </NavLink>
            </>
          )}
          <NavLink
            to={`/app/account/plan-details`}
            className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
          >
            <div className="my-auto pr-[3px] md:my-0 md:pr-0">
              <IconPlanDetails />
            </div>
            <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
              <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                plan details
              </p>
              <p className="text-[14px] md:text-[14px]">
                See your current plan and{' '}
                {isBusinessAccount
                  ? 'option for you add-ons'
                  : 'your available credits'}
                .
              </p>
            </div>
          </NavLink>
          {(!isBusinessAccount ||
            (isBusinessManagerAccount &&
              user?.organisation?.billingTo === '1') ||
            (isBusinessDriverAccount &&
              user?.organisation?.billingTo === '0')) && (
            <NavLink
              to={`/app/account/payment-method`}
              className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
            >
              <div className="my-auto pr-[3px] md:my-0 md:pr-0">
                <IconPaymentMethod />
              </div>
              <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
                <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                  payment methods
                </p>
                <p className="text-[14px] md:text-[14px]">
                  View, edit, add, or remove a payment method from your account.
                </p>
              </div>
            </NavLink>
          )}
          {isBusinessManagerAccount && (
            <NavLink
              to={`/app/account/settings`}
              className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
            >
              <div className="my-auto pr-[3px] md:my-0 md:pr-0">
                <IconSettings />
              </div>
              <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
                <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                  Settings and Notifications
                </p>
                <p className="text-[14px] md:text-[14px]">
                  Select which vehicle types your drivers can book, hours they
                  can rent and the type of notifications you receive.
                </p>
              </div>
            </NavLink>
          )}
          <NavLink
            to={`/app/account/security`}
            className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
          >
            <div className="my-auto pr-[3px] md:my-0 md:pr-0">
              <IconSecurity />
            </div>
            <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
              <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                security
              </p>
              <p className="text-[14px] md:text-[14px]">
                Protect your account by updating your log-in&nbsp;details.
              </p>
            </div>
          </NavLink>
          {(!isBusinessAccount ||
            (isBusinessManagerAccount &&
              user?.organisation?.billingTo === '1') ||
            (isBusinessDriverAccount &&
              user?.organisation?.billingTo === '0')) && (
            <NavLink
              to={`/app/account/invoices`}
              className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
            >
              <div className="my-auto pr-[3px] md:my-0 md:pr-0">
                <IconInvoices />
              </div>
              <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
                <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                  invoices
                </p>
                <p className="text-[14px] md:text-[14px]">
                  Manage your account balance and view your transaction history.
                </p>
              </div>
            </NavLink>
          )}
          <NavLink
            to={`/faq/contact-us`}
            className="floatup mx-auto flex min-h-[100px] w-full cursor-pointer select-none gap-[10px] rounded-[8px_8px_8px_8px] p-[20px] shadow-[0_2px_9px] shadow-[#00000010] duration-200 md:min-h-[160px] md:flex-col lg:mx-0 lg:w-[320px]"
          >
            <div className="my-auto pr-[3px] md:my-0 md:pr-0">
              <IconSupport />
            </div>
            <div className="my-auto flex flex-col md:my-0 md:gap-[10px]">
              <p className="text-[20px] font-bold capitalize md:mt-[10px] md:text-[22px]">
                support
              </p>
              <p className="text-[14px] md:text-[14px]">
                Get in touch and let us know how we can help you.
              </p>
            </div>
          </NavLink>
        </div>
      )}
      <PaymentMethodMessageModal
        setPaymentMethodModalOpen={setPaymentMethodModalOpen}
        statusRemarks={user?.statusRemarks}
        isModalOpen={paymentMethodModalOpen}
        handleClose={() => {
          setPaymentMethodModalOpen(false);
        }}
        hasCloseButton={false}
        enableClickAway={false}
      />
      <LicenseExpiredNotificationModal
        isModalOpen={licenseExpiredModalOpen}
        handleClose={() => {
          if (!user?.isLicenseExpired) setLicenseExpiredModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
        hasCloseButton={!user?.isLicenseExpired}
      />
      <License2YNotificationModal
        isModalOpen={license2YModalOpen}
        hasCloseButton={!user?.twoYearReverificationOverdue}
        handleClose={() => {
          if (!user?.twoYearReverificationOverdue) setLicense2YModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      />
      <HasUnpaidModal
        isModalOpen={isHasUnpaidModalOpen && user !== null}
        handleClose={() => {
          if (!user?.hasUnpaidInvoice) setHasUnpaidModalOpen(false);
        }}
        hasCloseButton={!user?.hasUnpaidInvoice}
      />
      <CourseEndDateModal
        isModalOpen={isCourseReminderModalOpen}
        hasCloseButton={!user?.course?.isNullCourseEndDate}
        enableClickAway={!user?.course?.isNullCourseEndDate}
        handleClose={() => {
          setIsCourseReminderModalOpen(false);
        }}
        px={isMobile ? 'px-[25px]' : 'px-[120px]'}
        mx={isMobile ? '30px' : '0px'}
      />
    </div>
  );
}

export default MyAccountNavigator;
