import React, { Fragment } from 'react';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { RequestStatusEnum } from 'common/types';
import Modal from 'components/Modal';
import { ReactComponent as IconSuccess } from 'assets/images/success.svg';
import useBusiness from 'hooks/useBusiness';
import LottieAnimation from 'components/LottieAnimation';
import removeDriverLottie from 'assets/json/icon-remove-driver.json';

interface Props {
  handleClose: () => void;
  isModalOpen: boolean;
  handleRemove: () => void;
  driverDatas: Object[];
}

function ModalRemove({
  handleClose,
  handleRemove,
  isModalOpen,
  driverDatas,
}: Props) {
  const { removeDriverStatus } = useBusiness();

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const renderModalContent = () => {
    if (removeDriverStatus === RequestStatusEnum.SUCCESS) {
      return (
        <Fragment>
          <IconSuccess className="icon" />
          <h1 className="form-control text-center text-[20px] font-bold md:text-[34px]">
            Driver
            {driverDatas.length > 1 && 's'} removed successfully
          </h1>
          <p
            className={classNames(
              `btn btn-primary mt-[50px] h-[60px] w-full rounded-full text-[16px] capitalize`,
            )}
            onClick={handleClose}
          >
            Ok
          </p>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <LottieAnimation
          lottieFile={removeDriverLottie}
          isStopped={!isModalOpen}
        />
        <h1 className="form-control mb-[20px] text-center text-[20px] font-bold md:text-[34px]">
          Remove Driver
        </h1>
        <p className="self-center text-center text-[16px] md:text-[18px]">
          You are about to remove the following driver
          {driverDatas.length > 1 && 's'}:
        </p>
        <p className="self-center text-center text-[16px] md:text-[18px]">
          By doing so, they will be removed from your drivers list and we will
          send them an email about this action.
        </p>

        <p className="mt-[20px] self-center text-center text-[16px] md:text-[18px]">
          Do you want to proceed?
        </p>
        <div className="scrollbar mt-[30px] grid max-h-[250px] w-full grid-cols-3 self-center overflow-y-auto md:grid-cols-2 [&>p:nth-child(4n+3)]:bg-white [&>p:nth-child(4n+4)]:bg-white">
          {driverDatas.map((data: any, i: number) => {
            return (
              <Fragment key={i}>
                <p className="bg-[#f2f2f2] p-[10px] text-[12px] md:p-[20px] md:text-[16px]">
                  {data.name}
                </p>
                <p className="col-span-2 bg-[#f2f2f2] p-[10px] text-[12px] md:col-span-1 md:p-[20px] md:text-[16px] truncate">
                  {data.email}
                </p>
              </Fragment>
            );
          })}
        </div>

        <div className="mt-[50px] flex justify-center">
          <p
            className={classNames(
              `btn btn-primary h-[60px] w-full rounded-full text-[16px] capitalize hover:bg-flexi-green-2`,
              {
                'loading bg-flexi-green-2':
                  removeDriverStatus === RequestStatusEnum.PENDING,
              },
            )}
            onClick={handleRemove}
          >
            Remove
          </p>
        </div>
      </Fragment>
    );
  };

  return (
    <Modal
      handleClose={handleClose}
      isModalOpen={isModalOpen}
      px={isMobile ? 'px-[25px]' : 'px-[120px]'}
      mx={isMobile ? '30px' : '0px'}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default ModalRemove;
